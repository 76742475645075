import React, { createContext, useState } from 'react';

const TabContext = createContext();

const TabProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState('');
  const [tabList, setTabList] = useState([]);
  const [path, setPath] = useState(null);

  const handleTabChangeValue = (tabName) => {
    setActiveTab(tabName);
  };
  const handleTabListArray = (array)=>{
    setTabList(array)
  }

  const value = {
    activeTab,
    handleTabChangeValue,
    handleTabListArray,
    tabList,
    path,
    setPath
  };

  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
};

export { TabContext, TabProvider };