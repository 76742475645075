import React from "react";
import { Select } from "antd";

const SearchSelectBox = ({ name, value, onChange, data, extraStyle, customWidth }) => {

    const selectStyle = {
        width: customWidth ? customWidth : "188px",
        height: "33px",
        /* UI Properties */
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        borderRadius: "3px",
    };

    return (
        <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.children ?? '').includes(input.toUpperCase())}
            value={value}
            name={name}
            style={{ ...selectStyle, ...extraStyle }}
            onChange={(value) => onChange(value, name)}
        >
            {data?.map(({ id, value }) => {
                return <option key={id} value={id}>
                    {value}
                </option>
            }
            )}
        </Select>
    );
};

export default SearchSelectBox;
