import {useReducer, useState} from 'react'
import TransactionContext from './Transaction-Context';

function transactionReducer (state, action) {
    switch (action.type) {
        case "NEW":
            return {
                action: "NEW", 
                data: action.data
            };
        case "CHANGE":
            return {
                action: "CHANGE", 
                data: action.data
            };
        case "REMOVE":
            return {
                action: "REMOVE", 
                data: action.data
            };
        default:
            return state;
    }
}


const TransactionProvider = (props) => {
    const [depositCounter, setDepositcounter] = useState(0);
    const [withdrawCounter, setWithdrawCounter] = useState(0);
    const [socketTransactionObject, setTransactionObject] = useReducer(transactionReducer,null);


    const TransactionValues = {
        depositCounter,
        withdrawCounter,
        setDepositcounter,
        setWithdrawCounter,
        socketTransactionObject,
        setTransactionObject
    }

    return (
        <TransactionContext.Provider value={TransactionValues}>
            {props.children}
        </TransactionContext.Provider>
    )
}

export default TransactionProvider

