import { Table, Modal, Dropdown, Tag, message, Switch } from 'antd';
import { useContext, useState } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import AdminChangeRole from '../../../components/forms/AdminChangeRole';
import ResetPwdForm from '../../../components/forms/ResetPwdForm';
import { CheckCircleOutlined } from '@ant-design/icons';
import { IoMenu } from 'react-icons/io5';
import ChangeStatusModal from '../../../components/ChangeStatusModal/ChangeStatusModal';
import PutApi from '../../../helps/contexts/Api/PutApi';
import AdminEditForm from '../AdminEdit/AdminEditForm';
import { convertUTCToLocalTime } from '../../../helps/HelperFunctions';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';

const UserListTable = ({ data, loading, getUsers, setUserData }) => {
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();

  const lang = ctx.lang;

  //states
  const [visible, setVisible] = useState(false);
  const [rowdata, setRowdata] = useState({});
  const [changerole, setChangeRole] = useState(false);
  const [EditAdmin, setEditAdmin] = useState(false);
  const [changestatus, setChangeStatus] = useState(false);
  const [resetpwd, setResetpwd] = useState(false);
  const [status, setStatus] = useState(1);
  const userid = rowdata.userid;
  const id = rowdata.id;
  const [currentSelectedRole, setCurrentSelectedRole] = useState('');

  const toggleStatus = async (id) => {
    toggleInLocal(id);
    let url = `/admin/status/toggle/${id}`;
    PutApi.PutApiRequest(url, {}, {})
      .then((res) => {
        if (!res || !res.status) {
          toggleInLocal(id);
        }
      })
      .catch((e) => {
        toggleInLocal(id);
      });
  };

  const toggleInLocal = (id) => {
    setUserData((prevData) =>
      prevData.map((record) => {
        if (record.id === id) {
          record.status = !record.status;
        }
        return record;
      })
    );
  };

  //on form submit request to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      setStatus(!status);

      const path = `/admin/status/toggle/${id}`;
      // sending request
      const res = await PutApi.PutApiRequest(path);
      //if success
      if (res && res.status == true) {
        message.success(lang.label_statusupdation);
        handleCancel();
        getUsers();
      }
    } catch (e) {
      message.error(e.message);
    }
  };
  //menu items in table
  const items = [
    ...(hasPermission('Create Admin')
      ? [
          {
            key: 1,
            label: lang.label_changerole
          }
        ]
      : []),
    // {
    //   key: 2,
    //   label: lang.label_changestatus
    // },
    ...(hasPermission('Create Admin')
      ? [
          {
            key: 3,
            label: lang.label_resetpwd
          }
        ]
      : []),

    ...(hasPermission('Edit User')
      ? [
          {
            key: 4,
            label: lang.label_editAdminUser
          }
        ]
      : [])
  ];
  //onclick of option in table
  const onClick = ({ key }) => {
    if (Number(key) === 1) {
      handleChangeRole();
    } else if (Number(key) === 2) {
      handleChangeStatus();
    } else if (Number(key) === 3) {
      handleResetPwd();
    } else if (Number(key) === 4) {
      handleEditAdmin();
    }
  };
  // columns of table
  const columns = [
    {
      title: ` ${lang.label_username}`,
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: `${lang.label_registerip}/${lang.label_registertime}`,
      dataIndex: ['registerip', 'registertime'],
      key: 'registerip',
      render: (time, row) => (
        <a href="#" style={{ color: '#2A93F0' }} title={row['registerip']}>
          {row['registerip']}
          <br />
          <span style={{ color: 'black' }}>{convertUTCToLocalTime(row['registertime'])}</span>
        </a>
      )
    },

    {
      title: `${lang.label_loginip}/${lang.label_logintime}`,
      dataIndex: ['loginip', 'logintime'],
      key: 'loginip',
      render: (time, row) => (
        <>
          {row?.created_by_admin ? (
            '-'
          ) : (
            <a href="#" style={{ color: '#2A93F0' }} title={row['loginip']}>
              {row['loginip']}
              <br />
              <span style={{ color: 'black' }}>{convertUTCToLocalTime(row['logintime'])}</span>
            </a>
          )}
        </>
      )
    },
    {
      title: `${lang.label_role}`,
      dataIndex: 'role',
      key: 'role'
    },
    {
      title: lang.label_status,
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => (
        // record.status === 1 ? (
        //   <Tag color="success" icon={<CheckCircleOutlined />}>
        //     {lang.label_active}
        //   </Tag>
        // ) : record.status === 0 ? (
        //   <Tag color="error">{lang.label_inactive}</Tag>
        // ) : (
        //   ''
        // )
        <Switch
          disabled={!hasPermission('Create Admin')}
          checked={record.status}
          onClick={() => toggleStatus(record.id)}
        />
      )
    }
  ];

  if (hasPermission('Edit User') || hasPermission('Create Admin')) {
    columns.push({
      title: lang.label_action,
      key: 'action',
      render: (_, record) => {
        return (
          <Dropdown
            placement="bottom"
            menu={{
              items,
              onClick
            }}>
            <a href="#" style={{ color: 'black' }} onClick={(e) => e.preventDefault()}>
              <IoMenu />
            </a>
          </Dropdown>
        );
      }
    });
  }
  //for closing the popup
  const handleCancel = () => {
    setEditAdmin(false);
    setVisible(false);
  };

  //for onclick of options in table

  const handleChangeRole = () => {
    setChangeRole(true);
    setChangeStatus(false);
    setResetpwd(false);
    setEditAdmin(false);
    setVisible(true);
  };

  const handleChangeStatus = () => {
    setChangeRole(false);
    setChangeStatus(true);
    setResetpwd(false);
    setEditAdmin(false);
    setVisible(true);
  };

  const handleResetPwd = () => {
    setChangeRole(false);
    setChangeStatus(false);
    setResetpwd(true);
    setEditAdmin(false);
    setVisible(true);
  };

  const handleEditAdmin = () => {
    setChangeRole(false);
    setChangeStatus(false);
    setResetpwd(false);
    setEditAdmin(true);
    setVisible(true);
  };
  return (
    <>
      <Table
        size="small"
        style={{ width: '100%' }}
        columns={columns}
        dataSource={data}
        bordered
        pagination={false}
        loading={loading}
        onRow={(record) => {
          return {
            onClick: () => {
              setRowdata(record);
            }
          };
        }}
      />
      <div>
        {(changerole || changestatus || resetpwd || EditAdmin) && (
          <Modal
            closeIcon={null}
            title={
              changerole
                ? lang.label_changerole
                : changestatus
                ? lang.label_changestatus
                : resetpwd
                ? lang.label_resetpwd
                : 'Edit Admin User'
            }
            open={visible}
            onCancel={handleCancel}
            footer={null}
            width={changerole ? 400 : changestatus ? 500 : resetpwd ? 700 : 700}
            color={'#004A7F'}>
            {changerole ? (
              <AdminChangeRole rowdata={rowdata} onCancel={handleCancel} getUsers={getUsers} />
            ) : changestatus ? (
              <ChangeStatusModal
                rowdata={rowdata}
                onCancel={handleCancel}
                onSubmit={onSubmit}
                text={'Are you Sure You want to change the status of user?'}
              />
            ) : resetpwd ? (
              <ResetPwdForm rowdata={rowdata} onCancel={handleCancel} getUsers={getUsers} />
            ) : EditAdmin ? (
              <AdminEditForm
                rowdata={rowdata}
                onCancel={handleCancel}
                getUsers={getUsers}
                setEditAdmin={setEditAdmin}
              />
            ) : (
              ''
            )}
          </Modal>
        )}
      </div>
    </>
  );
};

export default UserListTable;
