import SubHeading from "../../../../components/HelperComponents/SubHeading";
import InputField from "../../../../components/HelperComponents/InputField";
import styles from "./CreateModal.module.css";
import FileUpload from "../../../../components/HelperComponents/FileUpload";
import CustomButton from "../../../../components/HelperComponents/CustomButton";
import Loader from "../../../../components/HelperComponents/Loader";

import React, { useContext, useEffect } from "react";
import LangContext from "../../../../helps/contexts/lang-context";
import UploadWithLabel from "../../../../components/HelperComponents/UploadWithLabel";
const CreateModal = ({
  errors,
  type,
  updateFunc,
  item,
  formData,
  handleInputChange,
  handleCloseModal,
  submitFunc,
  setFormData,
  submitLoading,
  setItem,
}) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  console.log('creaate nodal rerender')

  useEffect(() => {
    console.log('item,', item)
    if (item) {
      const platformData = {

        name: item?.name,
        icon: item?.icon_image,
        platform_code: item?.platform_code,

      }

      setFormData(platformData)
    }
  }, [item])

  return (
    <div className={styles.wrapper}>
      <div className={styles.flexColumn} style={{ marginTop: "20px" }}>
        <SubHeading className={styles.heading}>
          {lang.label_platformcode}
          <span className={styles.redSteric}> *</span>
        </SubHeading>
        <InputField
          extraStyle={{
            width: "100%",
          }}
          placeHolder="Platform Code..."
          type="text"
          name="platform_code"
          value={
            formData.platform_code
          }
          onChange={handleInputChange}
        />
        {errors?.platformCodeError && (
          <p className="error">{errors?.platformCodeError}</p>
        )}

      </div>

      <div className={styles.flexColumn} style={{ marginTop: "20px" }}>
        <SubHeading className={styles.heading}>
          {lang.label_platformname}
          <span className={styles.redSteric}> *</span>
        </SubHeading>
        <InputField
          extraStyle={{
            width: "100%",
          }}
          placeHolder="Platform Name..."
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
        />
        {errors?.nameError && <p className="error">{errors?.nameError}</p>}
      </div>

      <div className={styles.flexColumn} style={{ marginTop: "15px" }}>
        {/* <SubHeading onClick={handleInputChange} className={styles.heading}>
          {lang.label_platformicon}
          <span className={styles.redSteric}> *</span>
        </SubHeading>
        <FileUpload
          placeHolder="Upload Icon..."
          formData={formData}
          value={formData.icon}
          setFormData={setFormData}
          item={item}
          setItem={setItem}
        /> */}
        {console.log('item is+++', item)}
        <UploadWithLabel
          name="game_platforms"
          label={
            <SubHeading
              onClick={handleInputChange}
              className={styles.heading}>
              {lang.label_platformicon}
              <span className={styles.redSteric}> *</span>
            </SubHeading>
          }
          initialImageUrl={formData?.icon || null}
          onChange={val => {
            console.log('val ', val)
            setFormData({ ...formData, icon: val })
          }}
        />
        {errors?.imageError && <p className="error">{errors?.imageError}</p>}
      </div>
      <div>
        <div
          className={styles.flexStyle}
          style={{
            marginTop: "30px",
            justifyContent: "flex-end",
          }}
        >
          {submitLoading ? (
            <Loader />
          ) : (
            <CustomButton
              type={styles.mainBg}
              onClick={type === "update" ? updateFunc : submitFunc}
              text={type === "update" ? lang.label_update : lang.label_submit}
            />
          )}
          <CustomButton
            type={styles.whiteBg}
            onClick={handleCloseModal}
            text={lang.label_cancel}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateModal;
