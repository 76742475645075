import React, { useContext, useEffect, useState } from 'react';
import { Input, Row, Col, Button, message, Tooltip, Radio, Select } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import PutApi from '../../../helps/contexts/Api/PutApi';
import PostApi from '../../../helps/contexts/Api/PostApi';
import { InfoCircleOutlined } from '@ant-design/icons';

const AddNewTag = ({ record, onClose }) => {
  // console.log(record);
  const [tagName, setTagName] = useState('');
  const [description, setDescription] = useState('');
  const [remark, setRemark] = useState('');
  const [domainBinding, setDomainBinding] = useState('no');
  const [domainName, setDomainName] = useState('');
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { TextArea } = Input;

  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);

  useEffect(() => {
    if (record) {
      setTagName(record?.name || '');
      setDescription(record?.description || '');
      setRemark(record?.remark || '');
      setDomainName(record.domain || '');
      if (record.domain) {
        setDomainBinding('yes');
        setIsUpdateDisabled(true);
      } else {
        setIsUpdateDisabled(false);
        setDomainBinding('no');
      }
    } else {
      setTagName('');
      setDescription('');
      setRemark('');
      setDomainBinding('no');
      setDomainName('');
      setIsUpdateDisabled(false);
    }
  }, [record]);

  //  validation of domain
  // const isValidDomain = (domain) => {
  //   const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)?[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
  //   return domainRegex.test(domain);
  // };

  //   for update of Tag
  const updateTag = async (id, tagData) => {
    try {
      const path = `/admin/tags/${id}`;
      const res = await PutApi.PutApiRequest(path, tagData);

      if (res && res.status === true) {
        message.success('Tag updated successfully');
        return true;
      } else {
        message.error('Failed to update tag');
        return false;
      }
    } catch (error) {
      message.error(error.data.errors.name[0] || 'Failed to update tag');
      return false;
    }
  };

  //   For create Tag
  const createTag = async (tagData) => {
    try {
      const path = '/admin/tags';
      const res = await PostApi.PostApiRequest5(path, tagData);
      if (res && res?.data?.status === true) {
        message.success('Tag created successfully');
        return true;
      } else {
        message.error('Failed to create tag');
        return false;
      }
    } catch (error) {
      message.error(error.data.errors.name[0] || 'Failed to created tag');
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validation domain name
    // if (domainBinding === 'yes' && !isValidDomain(domainName)) {
    //   message.error('Please enter a valid domain name');
    //   return;
    // }

    const tagData = {
      name: tagName,
      description: description,
      remark: remark,
      domain: domainName
    };

    try {
      let check;
      if (record) {
        // update
        check = await updateTag(record.id, tagData);
      } else {
        // create
        check = await createTag(tagData);
      }
      if (check) {
        setTagName('');
        setDescription('');
        setRemark('');
        setDomainBinding('no');
        setDomainName('');
        onClose();
      }
    } catch (error) {
      message.error('An error occurred');
    }
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          minHeight: '350px',
          height: '100%',
          justifyContent: 'space-evenly'
        }}>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              width: '100%',
              height: '100%',
              justifyContent: 'space-around'
            }}>
            <Row>
              <Col span={24}>
                <span style={{ color: 'red', marginRight: '2px' }}>*</span>
                <label>Tag Name</label>
                <br />
                <span style={{ height: '15px' }}>{''}</span>
                <Input
                  placeholder="Enter Tag Name"
                  value={tagName}
                  required
                  style={{ width: '100%', borderRadius: '1px', marginTop: '10px' }}
                  onChange={(e) => setTagName(e.target.value)}
                />
                <div style={{ marginTop: '10px' }}>
                  <span style={{ color: 'red', marginRight: '2px' }}>*</span>
                  <label>Description</label>
                  <br />
                  <span style={{ height: '15px' }}>{''}</span>
                  <TextArea
                    type="text"
                    style={{ width: '100%', borderRadius: '3px', marginTop: '10px', height: '70%' }}
                    value={description}
                    required
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <label>Remark</label>
                  <br />
                  <span style={{ height: '15px' }}>{''}</span>
                  <TextArea
                    type="text"
                    style={{ width: '100%', borderRadius: '3px', marginTop: '10px', height: '70%' }}
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                  />
                </div>
                <div>
                  <div style={{ marginTop: '10px' }}>
                    <label>
                      Domain binding{' '}
                      <Tooltip
                        color="white"
                        title={
                          <span style={{ color: 'black' }}>
                            Players registered from this domain will automatically be tagged.
                          </span>
                        }
                        overlayInnerStyle={{ backgroundColor: 'white' }}>
                        <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                      </Tooltip>
                    </label>
                    <br />
                    <Radio.Group
                      disabled={isUpdateDisabled ? true : false}
                      onChange={(e) => setDomainBinding(e.target.value)}
                      value={domainBinding}>
                      <Radio value={'no'}>No</Radio>
                      <Radio value={'yes'}>Yes</Radio>
                    </Radio.Group>
                  </div>
                  {domainBinding === 'yes' && (
                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                      <label style={{ paddingTop: '10px', minWidth: '70px' }}>https://</label>

                      <Input
                        disabled={isUpdateDisabled ? true : false}
                        placeholder="Please enter the domain name"
                        value={domainName}
                        style={{ width: '100%', borderRadius: '1px', marginTop: '10px' }}
                        onChange={(e) => setDomainName(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <br />
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
              <Button
                style={{
                  borderRadius: '3px',
                  backgroundColor: '#004A7F',
                  color: 'white'
                  // position: 'absolute',
                  // right: '38%',
                  // top: "80%",
                  // bottom: '15px',
                  // margin: '-1%'
                }}
                htmlType="submit">
                {record ? lang.label_update : lang.label_submit}
              </Button>
              <Button
                style={{
                  borderRadius: '3px',
                  color: '#004A7F'
                  // position: 'absolute',
                  // right: '50%',
                  // bottom: '15px',
                  // margin: '-1%'
                }}
                onClick={() => {
                  onClose();
                }}>
                {lang.label_cancel}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddNewTag;
