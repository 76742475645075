import axios from "axios";
import { constants } from './constants';
import { Navigate } from "react-router-dom";



const DeleteApiRequest = async (path) => {
  const url = `${constants.BASE_URL1}` + `${path}`;
  const token1 = localStorage.getItem("token");

  return await axios.delete(url, {

    headers: { Authorization: `Bearer ${token1}` },
  })
    .then((response) => {
      if (response?.status === 401) {
        localStorage.clear();
        return <Navigate to="/" replace />;
      } else if (response?.status === 200) {
        return response.data;
      } else return null;
    })
    .catch((e) => {
      if (e.response.request.status === 401) {
        localStorage.clear();
        return <Navigate to="/" replace />;
      }

      return Promise.reject(e.response);
    });
};

const DeleteApiRequest1 = async (path, payload, extraHeaders = null, extraConfig = null) => {
  const url = `${constants.BASE_URL1}${path}`;
  const token1 = localStorage.getItem('token');
  
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token1}`,
        ...extraHeaders
      },
      data: payload, // Attach payload here
      ...extraConfig
    });

    if (response?.status === 401) {
      localStorage.clear();
      return <Navigate to="/" replace />; // Assuming you are using React Router for navigation
    } else if (response?.status === 200) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    if (error.response?.status === 401) {
      localStorage.clear();
      return <Navigate to="/" replace />; // Again, assuming React Router
    }

    return Promise.reject(error.response);
  }
};

export default {
  DeleteApiRequest,
  DeleteApiRequest1
};
