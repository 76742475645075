import '../../styles/CardStyles.css';
import { useContext, useState, useEffect } from 'react';
import LangContext from '../../helps/contexts/lang-context';
import { Row, Col, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { dailyReport, monthlyTransaction, dailyTransaction } from '../../helps/contexts/Api/api';
import Blue from '../../images/Blue.png';
import Green from '../../images/Green.png';
import Red from '../../images/Red.png';
import Yellow from '../../images/Yellow.png';
// import CardComp from "./Card/CardComp";
import CustomChart from './Charts/CustomChart';
import Balance from './Balance/Balance';
import UserList from './UserList/UserList';
import HeaderCardDetails from '../../components/HeaderDetailCard';
import QuickFilter from './QuickFilter/QuickFilter';
import ProgressSection from './ProgressSection/ProgressSection';
import useCheckPermission from '../../helps/hooks/useCheckPermission';
import { TabContext } from '../../helps/contexts/TabContext';
const DashboardPage = () => {
  const Navigate = useNavigate();
  const { hasPermission } = useCheckPermission();

  const [totalDeposit, setTotalDeposit] = useState(null);
  const [totalWithdraw, setTotalWithdraw] = useState(null);
  const [days, setDays] = useState(0);
  const [userDays, setUserDays] = useState(0);
  const {path} = useContext(TabContext);

  const [profitData, setProfitData] = useState([]);
  const [depositData, setDepositData] = useState([]);
  const [withdrawData, setWithdrawData] = useState([]);
  const [dailyTransactionResponse, setDailyTransactionResponse] = useState({});
  const [monthlyTransactionResponse, setMonthlyTransactionResponse] = useState({});

  let _ = require('lodash');

  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const getTotalStats = async () => {
    try {
      const token = localStorage.getItem('token');
      const res = await dailyReport(token);
      if (res.status === 200) {
        const today = new Date().toISOString()?.split('T')[0];

        const filteredDeposits = res.data.depositPerDay.filter((obj) => obj.date === today).map((obj) => obj.deposit);
        const filteredWithdraws = res.data.totalWithdrawsPerDay
          .filter((obj) => obj.date === today)
          .map((obj) => obj.withdraw);
        // const filteredDeposits = [200,900,8000,14000,1000]
        // const filteredWithdraws = [980,700,500,200]

        const totalDepsitAmount = filteredDeposits.reduce((accumulator, currentValue) => accumulator + currentValue);
        const totalWithdrawAmount = filteredWithdraws.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );

        const profitCalc = filteredDeposits.map((deposit, index) => {
          return deposit - (filteredWithdraws[index] ? filteredWithdraws[index] : 0);
        });
        setDepositData(filteredDeposits);
        setWithdrawData(filteredWithdraws);

        setTotalDeposit(totalDepsitAmount);
        setTotalWithdraw(totalWithdrawAmount);
        // setProfitData(profitCalc)
        setProfitData(totalDepsitAmount - totalWithdrawAmount);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
  };

  const getMonthlyTransaction = async () => {
    try {
      const token = localStorage.getItem('token');
      const res = await monthlyTransaction(token);
      if (res.status === 200) {
        setMonthlyTransactionResponse(res.data);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
  };

  const getDailyTransaction = async () => {
    try {
      const token = localStorage.getItem('token');
      const res = await dailyTransaction(token);
      if (res.status === 200) {
        setDailyTransactionResponse(res.data);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
  };

  useEffect(() => {
    getTotalStats();
    getMonthlyTransaction();
    getDailyTransaction();
  }, []);
  // const dataArray = [0,40000, 60000, 20000, 1000000, 900000 ,20000,20000,20000 ,50000,100000, 10000 , 1000000,500000,100000,40000,200000,20000];

  useEffect(()=>{
    if(path==='/dashboard'){
      getTotalStats();
    getMonthlyTransaction();
    getDailyTransaction();
    }
  },[path])

  return (
    <>
      <HeaderCardDetails />
      {hasPermission('View Dashboard') && (
        <div
          style={{
            padding:"20px",
            display: 'flex',
            flexDirection: 'column',
            gap: '15px'
          }}
          className='chart-wrapper'
          >
          <Row gutter={15}>
            <QuickFilter />
            <ProgressSection />
          </Row>
          <Row gutter={16} span={20} style={{maxHeight:"710px" , boxSizing:'border-box'}}>
            <Col span={18} style={{maxHeight:'710px'}}>
              {!_.isEmpty(monthlyTransactionResponse) && !_.isEmpty(dailyTransactionResponse) && (
                <CustomChart
                  days={days}
                  setDays={setDays}
                  monthlyTransactionResponse={monthlyTransactionResponse}
                  dailyTransactionResponse={dailyTransactionResponse}
                />
              )}
            </Col>
            <Col span={6} style={{ maxHeight: '710px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px'
                }}>
                <UserList days={userDays} setDays={setUserDays} />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default DashboardPage;


