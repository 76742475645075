import { Input, Button, Row, Col, Select, DatePicker, message, Upload, Switch, Table } from 'antd';
import LangContext from '../../helps/contexts/lang-context';
import { useContext, useState, useRef, useEffect,useMemo } from 'react';
import PostApi from '../../helps/contexts/Api/PostApi';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Editor } from '@tinymce/tinymce-react';
import GetApi from '../../helps/contexts/Api/GetApi';
import styles from '../../pages/Promotions/Promotions.module.css';
import { useNavigate } from 'react-router-dom';
import UploadWithLabel from '../HelperComponents/UploadWithLabel';
import SubHeading from '../HelperComponents/SubHeading';
import MultiSelectComp from '../HelperComponents/MultiSelect';
import SwitchWithLabel from '../HelperComponents/SwitchWithLabel';
import RichTextEditor from '../HelperComponents/RichTextEditor';
import JoditEditor from 'jodit-react';
import { Jodit } from 'jodit';

const { TextArea } = Input;

const AddNewPromotionForm = ({
  setFormErrors,
  formErrors,
  setFormData,
  onChangeStatus,
  // onChangeCountry,
  onChangeCategory,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onChange,
  onChange1,
  formData,
  handleCancel,
  getPromotions,
  currencyData,
  // countryData,
  promoCategoryData,
  // promoTypeData,
  onChangeCurrency
  // onChangePromotionType,
  // setCondition,
  // condition,
  // setRewards,
  // rewards,
  // onChangeConditionType,
  // conditionsTypeData,
  // rewardsTypeData,
  // onChangeRewardsType,
  // onChangeRewardsLevelId,
  // rewardsLevelsData,
  // setConditionArray,
  // conditionArray,
  // setRewardsArray,
  // rewardsArray
}) => {
  // form states
  const editorRef = useRef();
  const divRef = useRef(null);
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const nav = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dateNotValid, setDateNotValid] = useState(false);
  // const [conditionErrorMsg, setConditionErrorMsg] = useState(false);
  // const [rewardsErrorMsg, setRewardsErrorMsg] = useState(false);

  const buttons= [
    'source', '|',
    'bold',
    'strikethrough',
    'underline',
    'italic', '|',
    'ul',
    'ol', '|',
    'outdent', 'indent',  '|',
    'font',
    'fontsize',
    'brush',
    'paragraph', '|',
    'image',
    'video',
    'table',
    'link', '|',
    'align', 'undo', 'redo', '|',
    'hr',
    'eraser',
    'copyformat', '|',
    'symbol',
    'fullsize',
    'print',
    'about'
]

 
  const config = useMemo(
    () => ({
    readonly: false,
    placeholder: '',
    defaultActionOnPaste: 'insert_as_html',
    // defaultLineHeight: 1.5,
     enter: 'div',
    // enter: 'P',
    statusbar: false,
    height: 350,
    // sizeLG: 1000,
    // sizeMD: 700,
    // sizeSM: 600,
    addNewLine: false,
    // toolbarAdaptive: false,
    buttons : buttons,
    uploader: {
      insertImageAsBase64URI: true,
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif', 'svg', 'webp'],
     // url: 'https://xdsoft.net/jodit/connector/index.php?action=fileUpload', // Your file upload URL
    },

   
    }),
    [],
   );

  const validateForm = () => {
    const errors = {
      title: !formData.title,
      sub_title : !formData.sub_title,
      image: !formData.image,
      desktopImage: !formData.desktopImage,
      startDate: !startDate,
      // type: !formData.type,
      // endDate: !endDate,
      // claimableLimit: !formData.claimableLimit,
      status: formData.status === null,
      // remark: !formData.remark,
      // currency: !formData.currency,
      category: !formData.promoCategory.length
    };

    setFormErrors(errors);

    return Object.values(errors).every((error) => !error);
  };
  // const resetCondition = () => {
  //   setCondition({
  //     withdraw: true,
  //     type: 2,
  //     to_reach: true,
  //     sing_up_days: '',
  //     amountLimits: '',
  //     turnover_limit: '',
  //     win_loss_limit: '',
  //     valid_bet_amount: ''
  //   });

  //   setConditionError({
  //     sing_up_days: false,
  //     amountLimits: false,
  //     turnover_limit: false,
  //     win_loss_limit: false,
  //     valid_bet_amount: false,
  //   })
  // };

  // const resetRewards = () => {
  //   setRewards({
  //     amount: '',
  //     turnover_multiplier: '',
  //     probability: '',
  //     type: '',
  //     id: '',
  //     image: '',
  //     desktop_image:'',
  //     remarks: ''
  //   });

  //   setRewardsError({
  //     turnover_multiplier: false,
  //     type:false,
  //     level_id: false,
  //   })
  // }

  const onSubmit = async (e) => {
    //preventing multiple call api with same data

    if (isSubmitting) {
      return;
    }

    try {
      e.preventDefault();

      setIsSubmitting(true);

      if (!validateForm()) {
        // message.error(lang.label_formvalidationerror);
        return;
      }
      // if (!conditionArray.length) {
      //   setConditionErrorMsg(true);
      //   return;
      // }
      // if (!rewardsArray.length) {
      //   setRewardsErrorMsg(true);
      //   return
      // }

      if(formData?.endTimeParam){
      if (formData.startTimeParam > formData.endTimeParam) {
        setDateNotValid(true);
        return;
      } else if (formData.endTimeParam < formData.startTimeParam) {
        setDateNotValid(true);
        return;
      }
    }
      const path = `/admin/promotions/store`;
      // sending request
      const res = await PostApi.PostApiRequest(path, {
        title: formData?.title,
        sub_title : formData?.sub_title,
        status: formData?.status,
        // type: formData?.type,
        // is_auto: formData?.isAuto,
        // claimable_limit: formData?.claimableLimit,
        currency: formData?.currency,
        promotion_category_ids: formData?.promoCategory,
        image: formData?.image,
        desktop_image: formData?.desktopImage,
        start_date: formData?.startTimeParam ? formData?.startTimeParam : '',
        end_date: formData?.endTimeParam ? formData?.endTimeParam : '',
        body: formData?.remark
        // conditions: conditionArray,
        // rewards: rewardsArray
      });

      //if success
      if (res?.status === 200) {
        setFormData({
          title: '',
          sub_title : '',
          status: 1,
          image: null,
          desktopImage: null,
          // claimableLimit: null,
          // isAuto: false,
          // type: '',
          // startDate:'',
          // endDate:'',
          startTimeParam: '',
          endTimeParam: '',
          remark: '',
          currency: '',
          promoCategory: []
          // conditions: [],
        });
        // setConditionArray([]);
        // setRewardsArray([]);
        setStartDate('');
        setEndDate('');
        setFormErrors({
          title: false,
          sub_title : false,
          image: false,
          // currency: false,
          // type: false,
          desktopImage: false,
          startDate: false,
          endDate: false,
          status: false,
          // isAuto: false,
          // country: false,
          currency: '',
          category: false,
          remark: false
        });
        message.success(lang.label_promotionaddsuccess);
        handleCancel();
        getPromotions();
        setDateNotValid(false);
      }
    } catch (e) {
      if (e?.status === 422) {
        message.error(lang.label_promotionaddfail);
        Object.values(e.data.message).map((errors) => message.error(errors));
      }
    } finally {
      // Reset the submitting state after API call is complete
      setIsSubmitting(false);
    }
  };
  function onOk(value) {}

  const dummyRequest = (file, onSuccess) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleEditorChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      remark: value
    }));
  };

  const [rewardsError, setRewardsError] = useState({
    turnover_multiplier: false,
    type: false,
    level_id: false
  });

  // const rewardsErrorFunc = (rewards) => {
  //   return new Promise((resolve) => {
  //     const newRewardError = { ...rewardsError };
  //     Object.keys(rewards).forEach(key => {
  //       if (key === 'turnover_multiplier') {
  //         rewards[key].length ? newRewardError.turnover_multiplier = false : newRewardError.turnover_multiplier = true;
  //       }

  //       if (key === 'type') {
  //         !rewards[key]  ? newRewardError.type = true : newRewardError.type = false;
  //       }
  //       if (key === 'level_id') {
  //         !rewards[key] ? newRewardError.level_id = true : newRewardError.level_id = false;
  //       }
  //     });
  //     setRewardsError(newRewardError);
  //     resolve(newRewardError);
  //   });
  // };
  // const handleRewardsDelete = (index) => {
  //   setRewardsArray(prevArray => prevArray.filter((_, i) => i !== index));
  // };

  // const columnsRewards = [
  //   {
  //     title: 'Amount',
  //     dataIndex: 'amount',
  //     key: 'amount',
  //   },
  //   {
  //     title: 'Turnover Miltiplier',
  //     dataIndex: 'turnover_multiplier',
  //     key: 'turnover_multiplier',
  //   },
  //   {
  //     title: 'Type',
  //     dataIndex: 'type',
  //     key: 'type'
  //   },
  //   {
  //     title: 'Level ID',
  //     dataIndex: 'level_id',
  //     key: 'level_id'
  //   },
  //   {
  //     title: 'Probability',
  //     dataIndex: 'probability',
  //     key: 'probability'
  //   },
  //   {
  //     title: 'Image',
  //     dataIndex: 'image',
  //     key: 'image',
  //     render: (record) => (
  //       <img
  //         style={{ width: "50px", height: "50px" }}
  //         src={record}
  //         alt="image"
  //       ></img>
  //     ),
  //   },
  //   {
  //     title: 'Remarks',
  //     dataIndex: 'remarks',
  //     key: 'remarks',
  //     render: (text) => (
  //       <p>{text.length > 25 ? `${text.substring(0, 25)}...` : text}</p>
  //     ),
  //   },
  //   {
  //     title: 'Action',
  //     key: 'action',
  //     render: (text, record, index) => (
  //       <DeleteOutlined onClick={() => handleRewardsDelete(index)} />
  //     ),
  //   },
  // ];

  // const onSubmitRewards = async () => {

  //   const rewardsErrors = await rewardsErrorFunc(rewards);
  //   if (!rewardsErrors.turnover_multiplier) {
  //     setRewardsArray(prevArray => [
  //       ...prevArray,
  //       {
  //         amount: rewards.amount,
  //         type:rewards.type,
  //         turnover_multiplier: rewards.turnover_multiplier,
  //         level_id: rewards.level_id,
  //         image: rewards.image,
  //         remarks: rewards.remarks,
  //       }
  //     ]);

  //     setRewards({
  //       amount: '',
  //       turnover_multiplier: '',
  //       type: '',
  //       level_id: '',
  //       image: '',
  //       remarks: ''
  //     });
  //     setRewardsErrorMsg(false);
  //   }

  // }

  // const [conditionError, setConditionError] = useState({
  //   // type: false,
  //   sing_up_days: false,
  //   amountLimits: false,
  //   turnover_limit: false,
  //   win_loss_limit: false,
  //   valid_bet_amount: false,
  // });

  // const conditionErrorFunc = (condition) => {
  //   return new Promise((resolve) => {
  //     const newConditionError = { ...conditionError };
  //     Object.keys(condition).forEach(key => {
  //       // if (key === 'type') {
  //       //   !condition[key]  ? newConditionError.type = true : newConditionError.type = false;

  //       // }
  //       if (key === 'sing_up_days') {
  //         condition[key].length ? newConditionError.sing_up_days = false : newConditionError.sing_up_days = true;
  //       }
  //       if (key === 'amountLimits') {
  //         condition[key].length ? newConditionError.amountLimits = false : newConditionError.amountLimits = true;
  //       }
  //       if (key === 'turnover_limit') {
  //         condition[key].length ? newConditionError.turnover_limit = false : newConditionError.turnover_limit = true;
  //       }
  //       if (key === 'win_loss_limit') {
  //         condition[key].length ? newConditionError.win_loss_limit = false : newConditionError.win_loss_limit = true;
  //       }
  //       if (key === 'valid_bet_amount') {
  //         condition[key].length ? newConditionError.valid_bet_amount = false : newConditionError.valid_bet_amount = true;
  //       }
  //     });
  //     setConditionError(newConditionError);
  //     resolve(newConditionError);
  //   });
  // };

  // const handleConditionDelete = (index) => {
  //   setConditionArray(prevArray => prevArray.filter((_, i) => i !== index));
  // };

  // const columns = [
  //   {
  //     title: 'Withdraw',
  //     dataIndex: 'is_withdraw',
  //     key: 'is_withdraw',
  //     render: (record) => (
  //       record === true ? 'True' : 'False'
  //     ),
  //   },
  //   // {
  //   //   title: 'Type',
  //   //   dataIndex: 'type',
  //   //   key: 'type'
  //   // },
  //   {
  //     title: 'To Reach',
  //     dataIndex: 'to_reach',
  //     key: 'to_reach',
  //     render: (record) => (
  //       record === true ? 'True' : 'False'
  //     ),
  //   },
  //   {
  //     title: 'Signup Days',
  //     dataIndex: 'sing_up_days',
  //     key: 'sing_up_days'
  //   },
  //   {
  //     title: 'Amount Limits',
  //     dataIndex: 'amountLimits',
  //     key: 'amountLimits'
  //   },
  //   {
  //     title: 'Turnover Limits',
  //     dataIndex: 'turnover_limit',
  //     key: 'turnover_limit'
  //   },
  //   {
  //     title: 'Winloss Limits',
  //     dataIndex: 'win_loss_limit',
  //     key: 'win_loss_limit'
  //   },
  //   {
  //     title: 'Valid Bet Amount',
  //     dataIndex: 'valid_bet_amount',
  //     key: 'valid_bet_amount'
  //   },
  //   {
  //     title: 'Action',
  //     key: 'action',
  //     render: (text, record, index) => (
  //       <DeleteOutlined onClick={() => handleConditionDelete(index)} />
  //     ),
  //   },
  // ];

  // const onSubmitConditions = async () => {

  //   const conditionErrors = await conditionErrorFunc(condition);
  //   if (
  //     !conditionErrors.sing_up_days &&
  //     !conditionErrors.amountLimits &&
  //     !conditionErrors.turnover_limit &&
  //     !conditionErrors.win_loss_limit &&
  //     !conditionErrors.valid_bet_amount) {
  //     setConditionArray(prevArray => [
  //       ...prevArray,
  //       {
  //         is_withdraw: condition.is_withdraw,
  //         type: condition.type,
  //         to_reach: condition.to_reach,
  //         sing_up_days: condition.sing_up_days,
  //         amountLimits: condition.amountLimits,
  //         turnover_limit: condition.turnover_limit,
  //         win_loss_limit: condition.win_loss_limit,
  //         valid_bet_amount: condition.valid_bet_amount
  //       }
  //     ]);

  //     setCondition({
  //       withdraw: true,
  //       type: 2,
  //       to_reach: true,
  //       sing_up_days: '',
  //       amountLimits: '',
  //       turnover_limit: '',
  //       win_loss_limit: '',
  //       valid_bet_amount: ''
  //     });

  //     setConditionErrorMsg(false);
  //   }

  // }

  return (
    <div
      style={{
        width: '97%',
        height: '85%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: '3%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '-6%'
          }}>
          <Row>
            <Col span={22}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_title}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <Input
                required
                style={{
                  width: '100%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                name="title"
                value={formData?.title}
              />
            </Col>
            <Col span={22}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_subtitle}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <Input
                required
                style={{
                  width: '100%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                onChange={(e) => setFormData({ ...formData, sub_title: e.target.value })}
                name="subtitle"
                value={formData?.sub_title}
              />
            </Col>
            <Col span={22}>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div className={styles.flexColumn} style={{ marginTop: '15px' }}>
                  <UploadWithLabel
                    name="promotions"
                    label={
                      <SubHeading className={styles.heading}>
                        {lang.label_image}
                        <span className={styles.redSteric}> *</span>
                      </SubHeading>
                    }
                    initialImageUrl={formData?.image || null}
                    onChange={(value) => setFormData({ ...formData, image: value })}
                  />
                  {formErrors?.image && <p className="error">Image is required</p>}
                </div>
                <div className={styles.flexColumn} style={{ marginTop: '15px' }}>
                  <UploadWithLabel
                    name="promotions"
                    label={
                      <SubHeading className={styles.heading}>
                        {lang.label_desktopImage}
                        <span className={styles.redSteric}> *</span>
                      </SubHeading>
                    }
                    initialImageUrl={formData?.desktopImage || null}
                    onChange={(value) => setFormData({ ...formData, desktopImage: value })}
                  />
                  {formErrors?.desktopImage && <p className="error">Desktop Image is required</p>}
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            {/* <Col span={12}>
              <label>{lang.label_country}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />
              <Select
                required
                onChange={onChangeCountry}
                placeholder="Select Country"
                value={formData && formData?.country}
                style={{
                  width: '184%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                options={countryData}
              />
              {formErrors.country && <span style={{ color: 'red' }}>country is required</span>}
            </Col> */}
            <Col span={12}>
              <label>{lang.label_currency}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />
              <Select
                required
                onChange={onChangeCurrency}
                placeholder="Select Currency"
                value={formData && formData?.currency || undefined}
                style={{
                  width: '184%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                options={currencyData}
              />
              {formErrors.currency && <span style={{ color: 'red' }}>currency is required</span>}
            </Col>
          </Row>

          <Row>
            <Col span={22}>
              <label>{lang.label_promotion_category_id}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <MultiSelectComp
                name="categories"
                style={{
                  width: '184%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                options={promoCategoryData.filter((category) => category.value !== 0)}
                extraStyle={{
                  width: '100%',
                  height: '40px'
                }}
                value={formData?.promoCategory}
                handleChange={onChangeCategory}
              />
              {formErrors.category && <span style={{ color: 'red' }}>category is required</span>}
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <label>{lang.label_status}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />
              <Select
                required
                onChange={onChangeStatus}
                value={formData?.status}
                style={{
                  width: '184%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                options={[
                  {
                    key: 1,
                    value: 1,
                    label: 'Visible'
                  },
                  {
                    key: 0,
                    value: 0,
                    label: 'Hidden'
                  }
                ]}
              />
              {formErrors.status && <span style={{ color: 'red' }}>status is required</span>}
            </Col>
            <Col span={22}>
              <label>{lang.label_starteddate}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <DatePicker
                required
                onOk={onOk}
                placeholder="Select Time"
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                onChange={onChange}
                style={{
                  height: '30px',
                  width: '100%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                value={startDate && startDate}
              />
              {formErrors.startDate && <span style={{ color: 'red' }}>Start Date is required</span>}
              {dateNotValid && <span style={{ color: 'red' }}>Please check the date</span>}
            </Col>
          </Row>

          <Row>
            <Col span={22}>
              <label>{lang.label_expirydate}</label>
              {/* <span style={{ color: 'red' }}>*</span> */}
              <br />
              <DatePicker
                required
                onOk={onOk}
                onChange={onChange1}
                placeholder="Select Time"
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                style={{
                  height: '30px',
                  width: '100%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                value={endDate && endDate}
              />
              {/* {formErrors.endDate && <span style={{ color: 'red' }}>End Date is required</span>} */}
              {dateNotValid && <span style={{ color: 'red' }}>Please check the date</span>}
            </Col>
          </Row>

          <Row>
            <Col span={22}>
              <label>{lang.label_remark}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <>
                {/* <TextArea
                  showCount
                  maxLength={100}
                  onChange={onChangeRemark}
                  style={{
                    width: "100%",
                    height: "100px",
                    borderRadius: "3px",
                    marginBottom: "5px",
                    marginTop: "5px",
                  }}
                  value={remark}
                /> */}

                {/* <Editor
                  apiKey="ud5rtcrk9pwxbwx1bza0kdc97yl36hjtwjmwopxwzvuu70gx"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  value={formData?.remark}
                  onEditorChange={(e, editor) => setFormData({ ...formData, remark: e })}
                  init={{
                    menubar: true,
                    height: 400,
                    selector: 'textarea#local-upload',
                    plugins: 'image code'
                    // toolbar: 'image code',

                    // toolbar: 'image',
                  }}
                /> */}

                <JoditEditor
               
                  ref={(evt, editor) => (editorRef.current = editor)}
                  value={formData?.remark}
                  config={config}
                  tabIndex={1} 
                  //onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  onBlur={handleEditorChange}
                />

                {/* <RichTextEditor value={formData?.remark} onChange={handleEditorChange} /> */}

                {/* <TextArea
                  rows={10}
                  placeholder=""
                  onChange={(e) => setFormData({ ...formData, remark: e.target.value })}
                /> */}
                {/* {formErrors.remark && (
                  <span style={{color:'red'}}>Remark is required</span>
                )} */}
              </>
            </Col>
          </Row>
          <br />
          <br />
          <br />

          <div style={{marginTop : '5px'}}>
            {' '}
            <Button
              style={{
                borderRadius: '3px',
                backgroundColor: '#004A7F',
                color: 'white',
                position: 'absolute',
                right: '25%',
                top: '93%'
              }}
              htmlType="submit">
              {lang.label_submit}
            </Button>
            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                position: 'absolute',
                right: '8%',
                top: '93%'
              }}
              onClick={() => {
                setFormData({
                  title: '',
                  sub_title : '',
                  status: 1,
                  image: null,
                  desktopImage: null,
                  // startDate: '',
                  // endDate: '',
                  startTimeParam: '',
                  endTimeParam: '',
                  remark: '',
                  // country: '',
                  promoCategory: []
                });
                setStartDate('');
                setEndDate('');
                handleCancel();
              }}>
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewPromotionForm;
