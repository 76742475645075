import React, { useContext, useEffect, useRef, useState } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import styles from './TagAdjustmentFilters.module.css';
import Heading from '../../../components/HelperComponents/Heading';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import { Button, Input, InputNumber, Select } from 'antd';
import dayjs, { utc } from 'dayjs';
import { DatePicker } from 'antd';
import GetApi from '../../../helps/contexts/Api/GetApi';
import SearchIcon from "../../../images/Search.png";
import ResetIcon from "../../../images/Reset.png";
const { RangePicker } = DatePicker;
dayjs.extend(utc);

export default function TagAdjustmentFilters({ setFilters, onRefresh }) {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [formData, setFormData] = useState({
    before_modify: null,
    after_modify: null,
  });
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const selectRef = useRef();
  const formatDate = (dayjsObj) => {
    return dayjs.utc(dayjsObj).format('YYYY-MM-DD HH:mm:ss');
  };
  const onReset = () => {
    onRefresh();
    setFormData({
      before_modify: null,
      after_modify: null,
    });
    setPage(1);
  };

  const onSubmit = () => {
    setFilters(formData);
    setPage(1);
  };
console.log(formData);
  const fetchTagNameList = async (page) => {
    try {
      const path = `/admin/tags?page=${page}`;
      const response = await GetApi.sendApiRequest(path, {});
      console.log(response);
      if (response.status) {
        const newOptions = response?.tags?.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setOptions((prevOptions) => [...prevOptions, ...newOptions]);
        setHasMore(response?.tags?.total > 10);
      }
    } catch (error) {
      console.error('Failed to fetch tags', error);
    }
  };

  useEffect(() => {
    fetchTagNameList(page);
  }, [page]);

  const handlePopupScroll = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };


  const inputStyle = {
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const selectStyle = {
    height: '33px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '3px'
  };


  return (
    <div className={styles.container}>
      <div className={styles.filterUpperContainer}>
        <Heading>{lang.label_filters}</Heading>
      </div>
      <div className={styles.filterLowerContainer}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            paddingRight: '14px',
            flexWrap: 'wrap'
          }}>
          {/* <div>
            <div className={styles.flexStyle}>
              <div
                style={{
                  minWidth: '50px'
                }}>
                <SubHeading
                  extraStyle={{
                    fontSize: '12px',
                    fontWeight: 500
                  }}>
                  {lang.label_tag_type}
                </SubHeading>
              </div>
              <Select
                placeholder="Select type"
                style={{ width: 150, ...selectStyle }}
                // allowClear
                // defaultValue={'tag_name'}
                value={formData?.key}
                onChange={(val) => setFormData((prevData) => ({ ...prevData, key: val, search: '' }))}
                // onClear={() => setFormData((prevData) => ({ ...prevData, category_id: null }))}
                options={[
                  {
                    value: 'tag_name',
                    label: 'Tag Name'
                  },
                  {
                    value: 'updated_by',
                    label: 'Updated By'
                  }
                ]}
              />
            </div>
          </div> */}

          {/* <div> */}
            <div className={styles.flexStyle}>
              <div
                style={{
                 width:"100%"
                }}>
                <SubHeading
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500
                  }}>
                  {lang.label_modify_account}
                </SubHeading>
              </div>
              <Input
                style={{
                  width: '200px',
                  ...inputStyle
                }}
                placeholder="Enter text"
                value={formData?.modify_account}
                onChange={(e) => setFormData((prevData) => ({ ...prevData, modify_account: e.currentTarget.value }))}
              />
            </div>
          {/* </div> */}
          <div>
            <div className={styles.flexStyle}>
              <div
                style={{
                  width:"100%"
                 }}>
                <SubHeading
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500
                  }}>
                  {lang.label_updating_time}
                </SubHeading>
              </div>
              <RangePicker
                value={formData ? formData.dates : []}
                showTime={{ defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss'),] }}
                onChange={(x) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    start_date: x ? formatDate(x[0]) : null,
                    end_date: x ? formatDate(x[1]) : null,
                    dates: x
                  }));
                }}
              />
            </div>
          </div>
          <div>
            <div className={styles.flexStyle}>
              <div
                 style={{
                  width:"100%"
                 }}>
                <SubHeading
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500
                  }}>
                  {lang.label_operate_by}
                </SubHeading>
              </div>
              <Input
               min={0}
               style={{ width: 200, ...inputStyle }}
                placeholder="Enter text"
                value={formData?.operate_by}
                onChange={(e) => setFormData((prevData) => ({ ...prevData, operate_by: e.currentTarget.value }))}
              />
            </div>
          </div>

          <div>
            <div className={styles.flexStyle}>
              <div
                 style={{
                  width:"100%"
                 }}>
                <SubHeading
                  extraStyle={{
                    fontSize: '14px',
                  fontWeight: 500
                  }}>
                  {lang.label_before_modify_tags}
                </SubHeading>
              </div>
              <Select
                placeholder="Click to Select"
                style={{ width: 150, ...selectStyle }}
                allowClear
                // defaultValue={'tag_name'}
                value={formData?.before_modify}
                onChange={(val) => setFormData((prevData) => ({ ...prevData, before_modify: val }))}
                // onClear={() => setFormData((prevData) => ({ ...prevData, category_id: null }))}
                options={options}
                onPopupScroll={handlePopupScroll}
              />
            </div>
          </div>

          <div>
            <div className={styles.flexStyle}>
              <div
                 style={{
                  width:"100%"
                 }}>
                <SubHeading
                  extraStyle={{
                    fontSize: '14px',
                  fontWeight: 500
                  }}>
                  {lang.label_after_modify_tags}
                </SubHeading>
              </div>
              <Select
                placeholder="Click to Select"
                style={{ width: 150, ...selectStyle }}
                allowClear
                // defaultValue={'tag_name'}
                value={formData?.after_modify}
                onChange={(val) => setFormData((prevData) => ({ ...prevData, after_modify: val }))}
                // onClear={() => setFormData((prevData) => ({ ...prevData, category_id: null }))}
                options={options}
                onPopupScroll={handlePopupScroll}
              />
            </div>
          </div>

          <div className={styles.flexStyle}
           style={{
            marginTop : "22px"
           }}
          >
            <Button
              style={{
                // borderRadius: "3px",
                // padding: "6px",
                // width: "84px",
                // marginRight: '0.5rem',
                // marginLeft: '0.5rem',
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'space-evenly',
                backgroundColor: '#004A7F',
                color: 'white',
                width: '125px',
                border: '1px solid grey',
              }}
              className="btnStyle"
              onClick={onSubmit}>
                <img src={SearchIcon} alt='search' />
              {lang.label_search}
            </Button>
          </div>
          <div className={styles.flexStyle}
            style={{
              marginTop : "22px"
             }}
          >
            <Button
              onClick={onReset}
              type="secondary"
              defaultHoverColor = '#004a7f'
              style={{
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'space-evenly',
                borderRadius: '3px',
                padding: '6px',
                border: '1px solid grey',
                width: '84px'
              }}
              className="btnReset">
                <img src={ResetIcon} alt='reset' />
              {lang.label_reset}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
