import React, { useContext, useState } from 'react';
import './TopbarStyle.css';
import LanguageSwitcher from './LanguageSwitcher';
import UserImage from '../../images/pic.jpg';
import { Dropdown, Menu, Modal, message } from 'antd';
import Activity from '../../images/Activity.png';
import Deposit from '../../images/Deposit.png';
import Withdraw from '../../images/Withdraw.png';
import DepositSound from '../../images/DepositSound.png';
import withdrawSound from '../../images/withdrawSound.png';
import Users from '../../images/Users.png';
import { LogoutOutlined } from '@ant-design/icons';
import { UserContext } from '../../helps/contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import NotificationContext from '../../helps/contexts/Notification-Context';
import SubHeading from '../HelperComponents/SubHeading';
import MiniText from '../HelperComponents/MiniText';
import { BsQrCode } from 'react-icons/bs';
import moment from 'moment';
import GetApi from '../../helps/contexts/Api/GetApi.js';
import TwofaModal from '../2FA/TwofaModal.js';
import { TabContext } from '../../helps/contexts/TabContext.js';
import MarqueeText from '../HelperComponents/MarqueeText.js';

const Topbar = () => {
  const { user, updateUser, updateSound, isDepositSoundAllowed, isWithdrawSoundAllowed } = useContext(UserContext);
  const { setPath } = useContext(TabContext);
  const [visible, setVisible] = useState(false);
  const [enable, setEnable] = useState(false);
  const [disable, setDisable] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    updateUser(null);
    setPath(null);
    navigate('/login');
  };

  const enable2FA = async (e) => {
    try {
      const path = `/admin/create/2fa`;
      // sending request
      const res = await GetApi.sendApiRequest(path);
      setData(res);
      //if success
      if (res && res.status == true) {
        message.success('Please scan QR code and enter otp');
        setVisible(true);
      }
      // else if (user?.is_2fa_enabled == 1) {
      //   setData([])
      //   message.info("Please enter password to disable Twofa")
      //   setVisible(true);

      // }
    } catch (error) {
      if (error) {
        message.error('some error ');
      }
    }
  };
  const disable2FA = async () => {
    try {
      setData([]);
      message.info('Please enter password to disable 2FA');
      setVisible(true);
    } catch (error) {
      if (error) {
        message.error('some error');
      }
    }
  };
  const handleNavigate = (isWithdraw) => {
    if (isWithdraw === 0) {
      navigate('/transaction-management/deposit');
    } else {
      navigate('/transaction-management/withdraw');
    }
  };
  const handleCancel = () => {
    setVisible(false);
    setDisable(false);
    setEnable(false);
  };

  const {
    setDepositcounter,
    setWithdrawCounter,
    depositCounter,
    withdrawCounter,
    userCounter,
    depositMessageArray,
    withdrawMessageArray,
    userMessageArray,
    withdrawCounterRef,
    depositCounterRef
  } = useContext(NotificationContext);

  const withdrawMenu = (
    <Menu>
      <Menu.Item key="logout" onClick={() => handleNavigate(1)}>
        {withdrawMessageArray.length > 0 ? (
          withdrawMessageArray.map((singleMes, i) => {
            var localTime = moment.utc(singleMes?.created_at).local();
            var localTimeString = localTime.format('YYYY-MM-DD HH:mm:ss');

            return (
              <div key={i} className="flexStyleGraph" style={{ marginTop: '20px', gap: '20px' }}>
                <div className="userImageWrapper">
                  <img
                    src={singleMes?.player?.user?.profile_pic ? singleMes?.player?.user?.profile_pic : UserImage}
                    alt="user picture"
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                  <SubHeading
                    extraStyle={{
                      fontWeight: 'medium',
                      fontSize: '16px',
                      lineHeight: '21px',
                      fontFamily: 'Roboto',
                      letterSpacing: '0px',
                      color: '#001F3E'
                    }}>
                    {`${singleMes?.player?.user?.user_name} withdraw ${singleMes?.amount} ${singleMes?.player?.currency_name} `}
                  </SubHeading>
                  <MiniText
                    extraStyle={{
                      fontWeight: 'normal',
                      fontSize: '12px',
                      lineHeight: '16px',
                      fontFamily: 'Roboto',
                      letterSpacing: '0px',
                      color: '#9A9A9A'
                    }}>
                    {localTimeString}
                  </MiniText>
                </div>
              </div>
            );
          })
        ) : (
          <p> No Data</p>
        )}
      </Menu.Item>
    </Menu>
  );
  const depositMenu = (
    <Menu>
      <Menu.Item key="logout" onClick={() => handleNavigate(0)}>
        {depositMessageArray.length > 0 ? (
          depositMessageArray.map((singleMes, i) => {
            var localTime = moment.utc(singleMes?.created_at).local();
            var localTimeString = localTime.format('YYYY-MM-DD HH:mm:ss');

            return (
              <div key={i} className="flexStyleGraph" style={{ marginTop: '20px', gap: '20px' }}>
                <div className="userImageWrapper">
                  <img
                    src={singleMes?.player?.user?.profile_pic ? singleMes?.player?.user?.profile_pic : UserImage}
                    alt="user picture1"
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                  <SubHeading
                    extraStyle={{
                      fontWeight: 'medium',
                      fontSize: '16px',
                      lineHeight: '21px',
                      fontFamily: 'Roboto',
                      letterSpacing: '0px',
                      color: '#001F3E'
                    }}>
                    {`${singleMes?.player?.user?.user_name} deposit ${singleMes?.amount} ${singleMes?.player?.currency_name} `}
                  </SubHeading>
                  <MiniText
                    extraStyle={{
                      fontWeight: 'normal',
                      fontSize: '12px',
                      lineHeight: '16px',
                      fontFamily: 'Roboto',
                      letterSpacing: '0px',
                      color: '#9A9A9A'
                    }}>
                    {localTimeString}
                  </MiniText>
                </div>
              </div>
            );
          })
        ) : (
          <p>No Data</p>
        )}
      </Menu.Item>
    </Menu>
  );
  const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout}>
        <LogoutOutlined /> Logout
      </Menu.Item>
      {user?.is_2fa_enabled == 0 ? (
        <Menu.Item
          key="2fa"
          onClick={() => {
            setEnable(true);
            enable2FA();
          }}>
          <BsQrCode /> {''}
          QR code to Enable 2fa
        </Menu.Item>
      ) : (
        user?.is_2fa_enabled == 1 && (
          <Menu.Item
            key="2fa disable"
            onClick={() => {
              setDisable(true);
              disable2FA();
            }}>
            <BsQrCode /> {''}
            Disable 2FA
          </Menu.Item>
        )
      )}
    </Menu>
  );
  return (
    <>
      {/* <div>
        <MarqueeText />
      </div> */}
      <div className="sidemenu-bar">
        <div className="sidemenu-items">
          <div className="listImageWrapper">
            <img src={Activity} alt="Activity Image1" />
          </div>
          <div className="depositImageWrapper">
            {Number(depositCounterRef.current) > 0 && (
              <div className="notifyDiv greenDiv">{depositCounterRef.current}</div>
            )}
            {/* <Dropdown 
     overlay={depositMenu}
     trigger={["click"]} 
     > */}
            {isDepositSoundAllowed ? (
              <img src={DepositSound} alt="Deposit Image1" onClick={() => updateSound('deposit')} />
            ) : (
              <img src={Deposit} alt="Deposit Image1" onClick={() => updateSound('deposit')} />
            )}
            {/* </Dropdown> */}
          </div>
          <div className="withdrawImageWrapper">
            {Number(withdrawCounterRef.current) > 0 && (
              <div className="notifyDiv redDiv">{withdrawCounterRef.current}</div>
            )}
            {/* <Dropdown 
           overlay={withdrawMenu} 
          trigger={["click"]}
            > */}

            {isWithdrawSoundAllowed ? (
              <img src={withdrawSound} alt="WithdrawSound Image1" onClick={() => updateSound('withdraw')} />
            ) : (
              <img c src={Withdraw} alt="Withdraw Image1" onClick={() => updateSound('withdraw')} />
            )}
            {/* </Dropdown> */}
          </div>
          <div className="personImageWrapper">
            {userCounter > 0 && <div className="notifyDiv orangeDiv">{userCounter}</div>}
            <img src={Users} alt="Users Image1" />
          </div>
          <div className="language-switcher">
            <LanguageSwitcher />
          </div>
          <Dropdown overlay={menu} trigger={['click']}>
            <div className="user-profile">
              <div className="userImageWrapper">
                <img src={UserImage} alt="user picture1" />
              </div>
              <div className="user-detail">
                <p className="nameText" style={{ width: '160px' }}>
                  {user?.username}
                </p>
                <p className="designationText">{user?.role}</p>
              </div>
            </div>
          </Dropdown>
        </div>
        {(enable || disable) && (
          <Modal
            closeIcon={null}
            title={'2FA QRCODE'}
            open={visible}
            footer={null}
            width={disable ? 400 : 250}
            closable={true}
            onCancel={handleCancel}>
            <TwofaModal
              handleCancel={handleCancel}
              data={data}
              enable={enable}
              setEnable={setEnable}
              disable={disable}
              setDisable={setDisable}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default Topbar;
