import React from 'react';
import AgentNotificationTable from './AgentNotificationTable';
import react, { useEffect, useState } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { Card, Row, Col, DatePicker, Button, message, Input, Modal, Pagination } from 'antd';

import LangContext from '../../../helps/contexts/lang-context';
import { useContext } from 'react';
import GetApi from '../../../helps/contexts/Api/GetApi';
import '../../../styles/ButtonStyle.css';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Spacer from '../../../components/HelperComponents/Spacer';
import { convertLocalTimeToUTC, convertUTCToLocalTime } from '../../../helps/HelperFunctions';
import { TabContext } from '../../../helps/contexts/TabContext';
import AddAgentNotification from './AddAgentNotification';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import SearchIcon from "../../../images/Search.png";
import ResetIcon from "../../../images/Reset.png";
import dayjs from 'dayjs';

const datePickerStyle = {
  width: '190px',
  height: '35px',
  background: ' #FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #D9D9D9',
  borderRadius: '3px'
};
const iconStyle = { color: '#004A7F' };
const inputStyle = {
  width: '190px',
  height: '35px',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #D9D9D9',
  borderRadius: '3px'
};

const AgentNotification = () => {
  const nav = useNavigate();
  //States
  const { hasPermission } = useCheckPermission();
  const [inputSearchValue, setInputSearchValue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [inputIpValue, setInputIpValue] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [startTimeParam, setStartTimeParam] = useState('');
  const [endTimeParam, setendTimeParam] = useState('');
  const [agentName, setAgentName] = useState('');
  const [createdby, setcreatedby] = useState('');
  const [currentpage, setCurrentpage] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [data, setData] = useState(null);
  //Language translation
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { path } = useContext(TabContext);

  const onReset = async () => {
    setLoading(true);
    setActivepage1(1);
    setStartDate('');
    setEndDate('');
    setAgentName('');
    setcreatedby('');
    try {
      const path = `/admin/agent/notification`;
      const params = {};

      setLoading(true);

      const res = await GetApi.sendApiRequest(path, null, null);
      if (res && res.data && res.data?.data.length) {
        const finalArray = res.data?.data.map((notify, index) => ({
          key: index,
          id: notify.id,
          agent_id: notify?.agent_notifications,
          notification: notify.description,
          notificationTitle: notify.title,
          agent: notify?.agent_notifications,
          read: notify?.agent_notifications,
          createdby: notify.created_by?.user_name,
          createdat: convertUTCToLocalTime(notify.created_at)
        }));
        setData(finalArray);
        setCurrentpage(res?.data?.current_page);
        setTotal(res?.data?.total);
        setPerpage(res?.data?.per_page);
        setNextPage(res?.data?.next_page_url);
      } else {
        setData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };

  const getNotification = async (customPage = null) => {
    setLoading(true);
    try {
      const path = `/admin/agent/notification`;
      const params = {};
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      if (startDate) params['from_date'] = convertLocalTimeToUTC(startTimeParam);
      if (endDate) params['to_date'] = convertLocalTimeToUTC(endTimeParam);
      if (agentName) params['agent_name'] = agentName;
      if (createdby) params['created_by'] = createdby;

      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.data && res?.data?.data?.length) {
        const finalArray = res.data?.data.map((notify, index) => ({
          key: index,
          id: notify.id,
          agent_id: notify?.agent_notifications,
          notification: notify.description,
          notificationTitle: notify.title,
          agent: notify?.agent_notifications,
          read: notify?.agent_notifications,
          createdby: notify.created_by?.user_name,
          createdat: convertUTCToLocalTime(notify.created_at)
        }));
        setData(finalArray);
        setCurrentpage(res?.data?.current_page);
        setTotal(res?.data?.total);
        setPerpage(res?.data?.per_page);
        setNextPage(res?.data?.next_page_url);
      } else {
        setData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };
  useEffect(() => {
    getNotification();
  }, []);

  useEffect(() => {
    if (path === '/agent-management/agent-notification') {
      getNotification();
    }
  }, [path]);
  const onChangepage = (page) => {
    setActivepage1(page);
    getNotification(page);
  };
  //on submit of filters on table values
  const onSubmit = (e) => {
    e.preventDefault();
    setActivepage1(1);
    getNotification();
  };
  // Add agent notification popup
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  return (
    <>
      <HeaderCardDetails />
      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div>
          <Card
            title="Filters"
            style={{
              borderRadius: '3px'
            }}>
            <div>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center'
                }}>
                <Col
                  span={18}
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <label className="filterTest" style={{ marginRight: '0.3rem' }}>
                    {lang.label_time}
                  </label>

                  <DatePicker
                    placeholder={lang.label_selecttime}
                    showTime ={{
                      defaultValue :dayjs('00:00:00', 'HH:mm:ss'),
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                    style={datePickerStyle}
                    suffixIcon={<CalendarOutlined style={iconStyle} />}
                    //getting input value for start date
                    value={startDate}
                    onChange={(value, dateString) => {
                      setStartDate(value);
                      setStartTimeParam(dateString);
                    }}
                  />
                  <span
                    style={{
                      color: 'black',
                      fontSize: '33px'
                    }}>
                    -
                  </span>
                  <DatePicker
                    placeholder={lang.label_selecttime}
                    showTime ={{
                      defaultValue :dayjs('23:59:59', 'HH:mm:ss'),
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                    suffixIcon={<CalendarOutlined style={iconStyle} />}
                    style={datePickerStyle}
                    value={endDate}
                    //getting input value for end date
                    onChange={(value, dateString) => {
                      setEndDate(value);
                      setendTimeParam(dateString);
                    }}
                  />

                  <label className="filterTest" style={{ marginLeft: '0.5rem', marginRight: '0.3rem' }}>
                    {lang.label_agent}
                  </label>
                  <Input
                    //getting input value for search
                    value={agentName}
                    style={inputStyle}
                    onChange={(e) => {
                      setAgentName(e.target.value);
                    }}
                  />

                  <label className="filterTest" style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>
                    {lang.label_createdby}
                  </label>
                  <Input
                    //getting input value for ip
                    value={createdby}
                    style={inputStyle}
                    onChange={(e) => {
                      setcreatedby(e.target.value);
                    }}
                  />

                  <Button
                    htmlType="submit"
                    type="primary"
                    className="btnStyle"
                    style={{
                      display : 'flex',
                   alignItems : 'center',
                    justifyContent : 'space-evenly',
                      margin: '0.5rem'
                    }}>
                      <img src={SearchIcon} alt='search' />
                    {lang.label_search}
                  </Button>

                  <Button
                    className="btnReset"
                    //handling reset
                    style={{
                      display : 'flex',
                alignItems : 'center',
                justifyContent : 'space-evenly',
                    }}
                    onClick={() => {
                      onReset();
                    }}>
                       <img src={ResetIcon} alt='reset' />
                    {lang.label_reset}
                  </Button>
                </Col>

                <Col
                  span={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                  }}>
                  {hasPermission('Create Agent Notification') && (
                    <Button type="secondary" className="btnStyle" onClick={showModal}>
                      {lang.label_addnotification}
                    </Button>
                  )}

                  <div>
                    <Modal
                      title="Add Message"
                      open={visible}
                      onCancel={handleCancel}
                      footer={null}
                      width={400}
                      closable={true}>
                      <AddAgentNotification rowdata={data} onCancel={handleCancel} getNotification={getNotification} />
                    </Modal>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <Spacer />
          <Spacer />
          <AgentNotificationTable data={data} loading={loading} getNotification={getNotification} />
          <br />
          <Pagination
            defaultCurrent={1}
            currentPage={activepage}
            onChange={onChangepage}
            total={total}
            responsive={true}
            pageSize={perpage}
          />
        </div>
      </form>
    </>
  );
};

export default AgentNotification;

const res = {
  status: true,
  data: {
    current_page: 1,
    data: [
      {
        id: 3,
        title: 'TestinDDD the Notifications 123',
        description: 'This is for Buffer This is for Buffer This is for Buffer This is for Buffer',
        created_at: '2024-06-01 16:01:47',
        created_by: {
          id: 9,
          user_id: 9,
          user: {
            id: 9,
            user_name: 'harry'
          }
        },
        player_notifications: [
          {
            id: 4,
            notification_id: 3,
            player_id: 78,
            is_read: 0,
            created_at: '2024-06-01T10:31:47.000000Z',
            updated_at: '2024-06-01T10:31:47.000000Z',
            player: {
              id: 78,
              user_id: 109,
              language_name: null,
              wallet: {
                id: 78,
                player_id: 78,
                base_balance: '0.00000000',
                locked_base_balance: null,
                currency: 2,
                deleted_at: null,
                created_at: '2024-04-11T16:05:02.000000Z',
                updated_at: '2024-04-11T16:05:02.000000Z',
                currency_name: 'PHP',
                balance: 0,
                locked_balance: null
              },
              user: {
                id: 109,
                user_name: 'alibaba123'
              }
            }
          },
          {
            id: 5,
            notification_id: 3,
            player_id: 7,
            is_read: 0,
            created_at: '2024-06-01T10:31:47.000000Z',
            updated_at: '2024-06-01T10:31:47.000000Z',
            player: {
              id: 7,
              user_id: 22,
              language_name: null,
              wallet: {
                id: 7,
                player_id: 7,
                base_balance: '101.84142456',
                locked_base_balance: null,
                currency: 2,
                deleted_at: null,
                created_at: '2024-02-29T18:29:59.000000Z',
                updated_at: '2024-05-06T16:41:13.000000Z',
                currency_name: 'PHP',
                balance: 5657.85692,
                locked_balance: null
              },
              user: {
                id: 22,
                user_name: 'gameplayer0007'
              }
            }
          },
          {
            id: 6,
            notification_id: 3,
            player_id: 9,
            is_read: 0,
            created_at: '2024-06-01T10:31:47.000000Z',
            updated_at: '2024-06-01T10:31:47.000000Z',
            player: {
              id: 9,
              user_id: 24,
              language_name: null,
              wallet: {
                id: 9,
                player_id: 9,
                base_balance: '97.20000000',
                locked_base_balance: null,
                currency: 2,
                deleted_at: null,
                created_at: '2024-02-29T18:29:59.000000Z',
                updated_at: '2024-02-29T18:29:59.000000Z',
                currency_name: 'PHP',
                balance: 5400,
                locked_balance: null
              },
              user: {
                id: 24,
                user_name: 'gameplayer0009'
              }
            }
          }
        ]
      },
      {
        id: 2,
        title: 'Testin the Notifications 123',
        description: 'This is for Buffer This is for Buffer This is for Buffer This is for Buffer',
        created_at: '2024-06-01 16:01:30',
        created_by: {
          id: 9,
          user_id: 9,
          user: {
            id: 9,
            user_name: 'harry'
          }
        },
        player_notifications: [
          {
            id: 2,
            notification_id: 2,
            player_id: 78,
            is_read: 0,
            created_at: '2024-06-01T10:31:30.000000Z',
            updated_at: '2024-06-01T10:31:30.000000Z',
            player: {
              id: 78,
              user_id: 109,
              language_name: null,
              wallet: {
                id: 78,
                player_id: 78,
                base_balance: '0.00000000',
                locked_base_balance: null,
                currency: 2,
                deleted_at: null,
                created_at: '2024-04-11T16:05:02.000000Z',
                updated_at: '2024-04-11T16:05:02.000000Z',
                currency_name: 'PHP',
                balance: 0,
                locked_balance: null
              },
              user: {
                id: 109,
                user_name: 'alibaba123'
              }
            }
          },
          {
            id: 3,
            notification_id: 2,
            player_id: 7,
            is_read: 0,
            created_at: '2024-06-01T10:31:30.000000Z',
            updated_at: '2024-06-01T10:31:30.000000Z',
            player: {
              id: 7,
              user_id: 22,
              language_name: null,
              wallet: {
                id: 7,
                player_id: 7,
                base_balance: '101.84142456',
                locked_base_balance: null,
                currency: 2,
                deleted_at: null,
                created_at: '2024-02-29T18:29:59.000000Z',
                updated_at: '2024-05-06T16:41:13.000000Z',
                currency_name: 'PHP',
                balance: 5657.85692,
                locked_balance: null
              },
              user: {
                id: 22,
                user_name: 'gameplayer0007'
              }
            }
          }
        ]
      }
    ],
    first_page_url: 'http://127.0.0.1:8000/api/admin/notifications?page=1',
    from: 1,
    last_page: 1,
    last_page_url: 'http://127.0.0.1:8000/api/admin/notifications?page=1',
    links: [
      {
        url: null,
        label: '&laquo; Previous',
        active: false
      },
      {
        url: 'http://127.0.0.1:8000/api/admin/notifications?page=1',
        label: '1',
        active: true
      },
      {
        url: null,
        label: 'Next &raquo;',
        active: false
      }
    ],
    next_page_url: null,
    path: 'http://127.0.0.1:8000/api/admin/notifications',
    per_page: 10,
    prev_page_url: null,
    to: 3,
    total: 3
  },
  message: 'Notifications retrieved successfully'
};
