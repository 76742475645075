import React from 'react';
import styles from './SettingsTable.module.css';

import { AiOutlineDelete } from 'react-icons/ai';
import { Image, Table, Button, Typography } from 'antd';
import ViewImg from '../../../src/images/View.png';
import { useNavigate } from 'react-router-dom';
import { convertUTCToLocalTime } from '../../helps/HelperFunctions';
import { MdEdit } from 'react-icons/md';
import useCheckPermission from '../../helps/hooks/useCheckPermission';

const { Paragraph, Text } = Typography;

export const SettingsTable = ({ data, loading, deleteSettings, handleEditButton }) => {
  const { hasPermission } = useCheckPermission();
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text) => <Paragraph ellipsis={{ rows: 2 }} style={{fontSize:"14px", color:"#858585"}}>{text}</Paragraph>
    }
  ];
  if (hasPermission('Create Setting')) {
    columns.push({
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <span style={{ display: 'flex', flexDirection: 'row', columnGap: '5px' }}>
          {' '}
          {}{' '}
          <Button
            onClick={() => {
              handleEditButton(record);
              //   setVisible(!visible);
              //   setIsedit(true);
              //   setDelete(false);
              //   setRowData({ ...record });
              //   setStatus(record.status);
            }}
            className={styles.editButton}
            style={{ width: '30px', height: '30px' }}>
            <MdEdit size={15} style={{ marginLeft: '-6px' }} />
          </Button>
          <Button
            onClick={() => {
              deleteSettings(record.id);
              //   setVisible(true);
              //   setIsedit(false);
              //   setDelete(true);
            }}
            className={styles.deleteButton}
            style={{ width: '30px', height: '30px' }}>
            <AiOutlineDelete size={15} style={{ marginLeft: '-8px' }} />
          </Button>
        </span>
      )
    });
  }

  const testData = [
    {
      key: 1,
      value: ''
    },
    {
      key: 2,
      value: ''
    }
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      bordered
      dataSource={data ? data : testData}
      pagination={false}
      size="small"
    />
  );
};

export default SettingsTable;
