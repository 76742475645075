import React from 'react';
import styles from './CardComp.module.css';
import { Col } from 'antd';
import AddCommasAndLength from '../../../helps/AddCommasAndLength';
import { Span } from '@tiptap/pm/changeset';
import UpImg from '../../../images/HomePage/arrow-narrow-up.png';
import DownImg from '../../../images/HomePage/arrow-narrow-down.png';

const CardComp = ({ item, index }) => {
  const variationClass = item?.variation >= 0 ? styles.increase : styles.decrease;
  const variationImg = item?.variation >= 0 ? UpImg : DownImg;

  return (
    <Col  lg={8} sm={12} xs={24}>
      <div className={styles.cardCompOverlay}>
        <div className={styles.nameSection}>
          <div className={styles.valueAndVariation}>
            <div className={styles.itemName}> {item && item.name} </div>
            {/* <div> */}
              {index < 2 && item.variation !== null && item.variation !== undefined && (
                <div className={variationClass}>
                  <img
                    src={variationImg}
                    style={{ marginTop: '-3px', opacity: 0.6 }}
                    alt={item.variation >= 0 ? 'Arrow Up' : 'Arrow Down'}
                  />
                  {item && AddCommasAndLength(item.variation)}%
                </div>
              )}
            {/* </div> */}
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.itemValue}>{item && AddCommasAndLength(item.value)}</div>
            <div className={styles.ImgContainer}>
              <img className={styles.Icon} src={item && item.img} alt={item && item.name} />
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default CardComp;
