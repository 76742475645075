import react, { useEffect, useState } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { Card, Row, Col, DatePicker, Button, message, Input, ConfigProvider, Pagination, Select } from 'antd';

import { useContext } from 'react';

// import '../../../styles/ButtonStyle.css';

import { useLocation, useNavigate } from 'react-router-dom';
import styles from './AgentPositionReport.module.css';
import SearchIcon from '../../../../images/Search.png';
import ResetIcon from '../../../../images/Reset.png';
import dayjs from 'dayjs';
import AgentPositionReportTable from './AgentPositionReportTable';
import LangContext from '../../../../helps/contexts/lang-context';
import GetApi from '../../../../helps/contexts/Api/GetApi';
import HeaderCardDetails from '../../../../components/HeaderDetailCard';
import Spacer from '../../../../components/HelperComponents/Spacer';
import { convertLocalTimeToUTC } from '../../../../helps/HelperFunctions';
import { TabContext } from '../../../../helps/contexts/TabContext';
import SubHeading from '../../../../components/HelperComponents/SubHeading';
import Heading from '../../../../components/HelperComponents/Heading';

const datePickerStyle = {
  width: '190px',
  height: '35px',
  background: ' #FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #D9D9D9',
  borderRadius: '3px'
};
const iconStyle = { color: '#004A7F' };
const inputStyle = {
  width: '190px',
  height: '35px',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #D9D9D9',
  borderRadius: '3px'
};

const selectStyle = {
  width: '200px',
  height: '35px',
  /* UI Properties */
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  borderRadius: '3px'
};

const AgentPositionReport = () => {
  const nav = useNavigate();
  let { state } = useLocation();
  //States
  const [loginHistoryTable, setLoginHistoryTable] = useState('');
  const [inputSearchValue, setInputSearchValue] = useState(state?.name ? state?.name : '');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [inputIpValue, setInputIpValue] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activepage, setActivepage] = useState(1);
  const [startTimeParam, setStartTimeParam] = useState('');
  const [endTimeParam, setendTimeParam] = useState('');
  const { path } = useContext(TabContext);

  //   new filter
  const [agentId, setAgentId] = useState('');
  const [agentLevel, setAgentLevel] = useState(null);
  const [settleCycle, setSettleCycle] = useState(null);
  const [searchBy, setSearchBy] = useState(null);

  //Language translation
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  // Fetching login history api
  const getLoginHistory = async (customPage = null) => {
    try {
      const path = '/admin/players/login-history';
      const params = {};
      setLoading(true);

      //setting parameters to send for search results
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      if (startDate) {
        params['start_time'] = convertLocalTimeToUTC(startTimeParam);
      }
      if (endDate) params['end_time'] = convertLocalTimeToUTC(endTimeParam);
      if (inputIpValue) params['ip'] = inputIpValue;
      if (inputSearchValue) params['search'] = inputSearchValue;

      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.data && res.data.length) {
        const finalArray = res.data.map((loginData) => {
          return {
            key: loginData.id ? loginData.id : '',
            id: loginData.user_id ? loginData.user_id : '',
            name: loginData.user ? loginData.user.user_name : '',
            ip: loginData.ip ? loginData.ip : '',
            device: loginData.device_type ? loginData.device_type : '',
            browser: loginData.browser_type ? loginData.browser_type : '',
            time: loginData.created_at ? loginData.created_at : '',
            remark: loginData.note ? loginData.note : ''
          };
        });
        setLoginHistoryTable(finalArray);
        setTotal(res.total);
      } else {
        setLoginHistoryTable([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };

  // Fetching login history api
  const getLoginHistoryWithoutParams = async (customPage = null) => {
    const path = '/admin/players/login-history';
    setLoading(true);
    //sending api request
    const res = await GetApi.sendApiRequest(path, null, null);
    if (res && res.data && res.data.length) {
      const finalArray = res.data.map((loginData) => ({
        key: loginData.id ? loginData.id : '',
        id: loginData.user_id ? loginData.user_id : '',
        name: loginData.user ? loginData.user.user_name : '',
        ip: loginData.ip ? loginData.ip : '',
        device: loginData.device_type ? loginData.device_type : '',
        browser: loginData.browser_type ? loginData.browser_type : '',
        time: loginData.created_at ? loginData.created_at : '',
        remark: loginData.note ? loginData.note : ''
      }));
      setLoginHistoryTable(finalArray);
      setTotal(res.total);
    } else {
      setLoginHistoryTable([]);
      setTotal(0);
    }
    setLoading(false);
  };

  // handling submit for the form to get search results
  const sendSearchFilters = async (e) => {
    e.preventDefault();
    getLoginHistory();
    setActivepage(1);
  };

  useEffect(() => {
    getLoginHistory();
  }, []);

  useEffect(() => {
    if (path === '/player-management/login-history') {
      getLoginHistory();
    }
  }, [path]);
  return (
    <div>
      {' '}
      <HeaderCardDetails />
      <form onSubmit={sendSearchFilters} style={{ padding: '20px' }}>
        <div>
          <div className={styles.container}>
            <div
              className={styles.filterUpperContainer}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: '3px'
              }}>
              <Heading>{lang.label_filters}</Heading>
            </div>
            <div className={styles.filterLowerContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  width: '100%',
                  paddingRight: '14px',
                  flexWrap: 'wrap'
                }}>
                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_time}{' '}
                  </SubHeading>
                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-around',
                      alignItems: 'center'
                    }}>
                    <DatePicker
                      //getting input value for start date
                      placeholder={lang.label_selectdate}
                      showTime={{
                        defaultValue: dayjs('00:00:00', 'HH:mm:ss')
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      onChange={(value, dateString) => {
                        setStartDate(value);
                        setStartTimeParam(dateString);
                      }}
                      value={startDate}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    />
                    <span
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        marginLeft: '1%',
                        marginRight: '1%'
                      }}>
                      -
                    </span>
                    <DatePicker
                      //getting input value for start date
                      placeholder={lang.label_selectdate}
                      showTime={{
                        defaultValue: dayjs('23:59:59', 'HH:mm:ss')
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      // onChange={onChange1}
                      // value={endDate}
                      value={endDate}
                      onChange={(value, dateString) => {
                        setEndDate(value);
                        setendTimeParam(dateString);
                      }}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    />
                  </div>
                </div>

                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {`Search by`}
                  </SubHeading>
                  <Select
                    defaultValue={null} // Set a specific non-null default value
                    value={searchBy}
                    style={selectStyle}
                    onChange={(value) => {
                      setSearchBy(value);
                    }}
                    options={[
                      {
                        value: null,
                        label: 'Agent level'
                      },
                      {
                        value: 1,
                        label: 'Agent ID'
                      }
                    ]}
                  />
                </div>

                {/* Conditional Rendering Based on Selected Value */}
                {searchBy === null && (
                  <div className={styles.flexStyle}>
                    <SubHeading
                      extraStyle={{
                        fontSize: '14px',
                        fontWeight: 500
                      }}>
                      {'Agent Level'}
                    </SubHeading>
                    <Select
                      defaultValue={null}
                      value={agentLevel}
                      style={selectStyle}
                      onChange={(value) => {
                        setAgentLevel(value);
                      }}
                      options={[
                        {
                          value: null,
                          label: 'All'
                        },
                        {
                          value: 1,
                          label: 'CM'
                        },
                        {
                          value: 3,
                          label: 'EA'
                        },
                        {
                          value: 4,
                          label: 'MA'
                        },
                        {
                          value: 5,
                          label: 'SA'
                        },
                        {
                          value: 6,
                          label: 'JA'
                        },
                        {
                          value: 7,
                          label: 'AG'
                        }
                      ]}
                    />
                  </div>
                )}

                {searchBy === 1 && (
                  <div className={styles.flexStyle} style={{ display: 'flex', gap: '0px' }}>
                    <SubHeading
                      extraStyle={{
                        fontSize: '14px',
                        fontWeight: 500
                      }}>
                      {'Agent ID'}
                    </SubHeading>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                      <Input
                        style={inputStyle}
                        placeholder="please key in text"
                        onChange={(e) => {
                          setAgentId(e.target.value);
                        }}
                        value={agentId}
                      />
                    </div>
                  </div>
                )}

                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    Settle Cycle
                  </SubHeading>
                  <Select
                    defaultValue={null}
                    value={settleCycle}
                    style={selectStyle}
                    onChange={(value) => {
                      setSettleCycle(value);
                    }}
                    options={[
                      {
                        value: null,
                        label: 'Weekly'
                      },
                      {
                        value: 1,
                        label: 'Monthly'
                      }
                    ]}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    marginTop: '20px',
                    marginLeft: '10px',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                  }}>
                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1'
                    }}
                    htmlType="submit"
                    className="btnStyle"
                    // type="primary"
                  >
                    <img src={SearchIcon} alt="search" />
                    {lang.label_search}
                  </Button>

                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      borderRadius: '3px',
                      padding: '6px',
                      border: '1px solid grey',
                      width: '84px'
                    }}
                    className="btnReset"
                    htmlType="reset"
                    onClick={() => {
                      setActivepage(1);
                      setSearchBy(null);
                      setSettleCycle(null);
                      setAgentLevel(null);
                      setAgentId('');
                      setStartDate('');
                      setEndDate('');
                      getLoginHistoryWithoutParams();
                    }}>
                    <img src={ResetIcon} alt="reset" />
                    {lang.label_reset}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <Spacer />
          <Spacer />
          <AgentPositionReportTable data={loginHistoryTable} loading={loading} />
          <Spacer />
          <Pagination
            showSizeChanger={false}
            defaultCurrent={1}
            pageSize={10}
            total={total}
            defaultPage={1}
            current={activepage}
            onChange={(page) => {
              setActivepage(page);
              getLoginHistory(page);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default AgentPositionReport;
