import { useState, useEffect, useContext } from 'react';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import { Tag, Table, Button, Popover, Spin, message } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { Link, useNavigate } from 'react-router-dom';
import { convertUTCToLocalTime, toFixedFunc } from '../../../helps/HelperFunctions';
import GetApi from '../../../helps/contexts/Api/GetApi';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import PutApi from '../../../helps/contexts/Api/PutApi';
import FinanceApproveDepositModal from './Components/FinanceApproveDepositModal';
import PaginationComp from '../../../components/HelperComponents/PaginationComp';
import TransactionFilters from './Components/TransactionFilters';
import TransactionContext from '../../../helps/contexts/Transaction-Context';
import { FaCopy } from 'react-icons/fa';
import DepositPopup from './Popups/DepositPopup/DepositPopup';
import DepositSumTable from './DepositSumTable';
import Spacer from '../../../components/HelperComponents/Spacer';
import { TabContext } from '../../../helps/contexts/TabContext';
import { LoadingOutlined } from '@ant-design/icons';
import { exportReportDeposit1, exportReportDeposit2 } from '../../../helps/contexts/Api/downloadFile';

const Deposit = () => {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filterData, setFilterData] = useState({ status: ['3', '4', '5', '6'] });
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [faModalOpen, setFaModalOpen] = useState(false);
  const [activeTransaction, setActiveTransaction] = useState(null);
  const [loginUser, setLoginUser] = useState();
  const [totalDeposit, setTotalDeposit] = useState([]);
  const { path } = useContext(TabContext);
  const [loadingData, setLoadingData] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0);
  const [downloadedFiles, setDownloadedFiles] = useState(0);
  const downloadedFileIds = new Set();

  const Navigate = useNavigate();
  const { hasPermission } = useCheckPermission();

  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { socketTransactionObject } = useContext(TransactionContext);

  const [popoverOpen, setPopoverOpen] = useState(null);

  const handleOpenChange = (open, record, value) => {
    const textToCopy = value;

    navigator.clipboard.writeText(textToCopy);

    setPopoverOpen(open ? record.id : null);
  };

  const hide = () => {
    setPopoverOpen(null);
  };

  const [popupId, setPopupId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (id) => {
    setIsModalOpen(true);
    setPopupId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setPopupId(null);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setPopupId(null);
  };

  const exportData = async (payload) => {
    const params = {
      export_type: 0,
      export: 1,
      agent_code: payload.agent_code,
      from_date: payload.from_date,
      to_date: payload.to_date,
      max_amount: payload.max_amount,
      min_amount: payload.min_amount,
      user_name: payload.user_name,
      payment_method_id: payload.payment_method_id,
      reference_no: payload.reference_no,
      status: payload.status,
      transaction_id: payload.transaction_id
    };

    try {
      setLoadingData(true);
      setDownloadedFiles(0);
      const response = await exportReportDeposit1(params);

      if (response?.data?.job_id) {
        const jobId = response.data.job_id;
        const totalFileCount = response?.data?.progress?.total_file_count;
        setTotalFiles(totalFileCount);

        const pollExportStatus = async (jobId) => {
          try {
            const statusResponse = await exportReportDeposit2(jobId);
            const files = statusResponse?.data?.file || [];

            files.forEach((file) => {
              if (!downloadedFileIds.has(file.file_id)) {
                const base64String = file?.file_content;
                const fileName = file?.file_name + (file.file_type.includes('csv') ? '.csv' : '.xlsx');
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: file.file_type });

                const link = document.createElement('a');
                const blobUrl = URL.createObjectURL(blob);
                link.href = blobUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);

                downloadedFileIds.add(file.file_id);

                setDownloadedFiles((prev) => prev + 1);
              }
            });

            if (statusResponse?.data?.progress?.is_all_file_generated) {
              setLoadingData(false);
              message.success(`Export completed. ${totalFileCount} files downloaded.`);
            } else {
              setTimeout(() => pollExportStatus(jobId), 2000);
            }
          } catch (error) {
            setLoadingData(false);
            message.error('Failed to check export status.');
          }
        };

        pollExportStatus(jobId);
      } else {
        setLoadingData(false);
        message.error('Failed to initiate export.');
      }
    } catch (error) {
      setLoadingData(false);
      message.error('Failed to initiate export.');
    }
  };

  useEffect(() => {
    if (socketTransactionObject && !socketTransactionObject.data.isWithdraw) {
      if (socketTransactionObject.action === 'NEW' && currentPage === 1) {
        const index = transactions.findIndex((item) => item.id === socketTransactionObject.data.id);

        if (index === -1) {
          setTransactions((prevTxns) => [socketTransactionObject.data, ...prevTxns]);
        }
      } else if (socketTransactionObject.action === 'CHANGE') {
        setTransactions((prevTxns) =>
          prevTxns.map((txn) => {
            if (txn.id === socketTransactionObject.data.id) {
              let updatedTransaction = { ...socketTransactionObject.data };
              if (!updatedTransaction.player) {
                updatedTransaction.player = {};
              }
              updatedTransaction.player.agent = txn?.player?.agent;
              return updatedTransaction;
            }
            return txn;
          })
        );
      } else if (socketTransactionObject.action === 'REMOVE') {
        const index = transactions.findIndex((item) => item.id === socketTransactionObject.data.id);

        if (index > -1) {
          const newTransactionList = JSON.parse(JSON.stringify(transactions));
          newTransactionList.splice(index, 1);
          setTransactions(newTransactionList);
        }
      }
    }
  }, [socketTransactionObject]);

  const getPaymentMethods = async () => {
    try {
      setLoading(true);
      const path = '/payment/method/drop/down';
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, {});
      if (res && res.length) {
        setPaymentMethods(
          // res.map((pm) => ({ ...pm, label: pm.public_name?.en_public_name || pm.account_name, value: pm.id }))
          res.map((pm) => ({ ...pm, label: pm.internal_name || pm.public_name?.en_public_name, value: pm.id }))
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
    setLoading(false);
  };

  function convertToTwoDecimalPlaces(numberString) {
    let number = parseFloat(numberString);
    let formattedNumber = number.toFixed(2);
    return formattedNumber;
  }

  const getTransactions = async () => {
    setLoading(true);
    try {
      const path = '/admin/transactions';
      const finalData = filterData;

      const params = { isWithdraw: 0, ...finalData, page: currentPage };

      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.total_amount) {
        const finalArray = res?.total_amount.map((item, index) => ({
          key: index,
          currencyName: item ? item?.currency_name : '-',
          totalDeposit: item ? convertToTwoDecimalPlaces(item?.amount) : 0
        }));
        setTotalDeposit(finalArray);
      }
      if (res && res.transactions && res.transactions.data && Array.isArray(res.transactions.data)) {
        setLoading(false);
        setTransactions(res.transactions.data);
        setTotalLength(res.transactions.total);
        setPageSize(res.transactions.per_page);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
    setLoading(false);
  };

  // console.log(transactions)

  useEffect(() => {
    getTransactions();
  }, [filterData, currentPage]);

  useEffect(() => {
    getPaymentMethods();
    setLoginUser(localStorage.getItem('user_id'));
  }, []);

  useEffect(() => {
    if (path === '/transaction-management/deposit') {
      getTransactions();
    }
  }, [path]);

  const makeStatusBadge = (row) => {
    let color = 'success';
    if (row.status_name === 'pending') {
      color = 'processing';
    } else if (row.status_name === 'rejected') {
      color = 'red';
    } else if (row.status_name === 'processing') {
      color = 'geekblue';
    } else if (row.status_name === 'Failed') {
      color = 'red';
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {(() => {
          if (row.is_deposit_transaction?.is_first === 1) {
            return (
              <Tag bordered={false} color={'volcano'}>
                First
              </Tag>
            );
          }
        })()}
        <Tag bordered={false} color={color}>
          {row.status_name}
        </Tag>
      </div>
    );
  };

  const depositActions = (transaction) => {
    if (!transaction || !transaction.is_deposit_transaction) {
      return 'ERROR';
    }
    if (transaction.status === 1 || transaction.status === 2) {
      return '-';
    }
    // if (transaction.action_by == null) {
    return faLockButton(transaction);
    // }
  };

  const faLockButton = (transaction) => {
    const depositData = transaction.is_deposit_transaction;
    // console.log(depositData)
    if (!depositData.fa_locked_by && hasPermission('Finance Approve/Reject Deposit')) {
      return (
        <Button onClick={() => lockFAToggle(transaction.id, 1)} type="primary" icon={<LockOutlined />}>
          {lang.label_lock}
        </Button>
      );
    } else if (!depositData.fa_locked_by && !hasPermission('Finance Approve/Reject Deposit')) {
      return <>{lang.label_no_permission}</>;
    } else if (depositData.fa_locked_by && depositData.fa_locked_by.id == loginUser) {
      return (
        <div>
          <Button
            type="primary"
            onClick={() => {
              setActiveTransaction(transaction);
              setFaModalOpen(true);
            }}>
            {lang.label_fa_verify}
          </Button>
          <Button onClick={() => lockFAToggle(transaction.id, 0)} icon={<UnlockOutlined />}>
            {lang.label_unlock}
          </Button>
        </div>
      );
    } else if (depositData.fa_locked_by && depositData.fa_locked_by.id !== loginUser) {
      return `${lang.label_locked_by} ${depositData.fa_locked_by.name}`;
    }
  };

  const lockFAToggle = async (id, isLock) => {
    // setFALockState(id, isLock);
    setLoading(true);
    const payload = {
      is_fa_locked: isLock
    };

    try {
      const path = `/admin/deposit/${id}/fa/lock/unlock`;
      //sending api request
      const res = await PutApi.PutApiRequest(path, payload);
      if ((!res || !res.status) && res.faLockedBy) {
        setFALockStateById(id, true, res.faLockedBy);
      } else {
        setFALockState(id, isLock);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Navigate('/login');
      } else {
        setFALockState(id, !isLock);
      }
    }
    setLoading(false);
  };

  const setFALockState = (id, isLock) => {
    setTransactions((prevTxns) =>
      prevTxns.map((txn) => {
        if (txn.id === id) {
          if (txn.is_deposit_transaction) {
            txn.is_deposit_transaction.fa_locked_by = isLock ? { id: loginUser } : null;
          } else {
            txn.is_deposit_transaction = { fa_locked_by: isLock ? { id: loginUser } : null };
          }
        }
        return txn;
      })
    );
  };

  const setFALockStateById = (id, isLock, lockedBy) => {
    setTransactions((prevTxns) =>
      prevTxns.map((txn) => {
        if (txn.id === id) {
          if (txn.is_deposit_transaction) {
            txn.is_deposit_transaction.fa_locked_by = isLock ? { id: lockedBy.id, name: lockedBy.name } : null;
          } else {
            txn.is_deposit_transaction = { fa_locked_by: isLock ? lockedBy : null };
          }
        }
        return txn;
      })
    );
  };

  const onChangePage = (page) => {
    setCurrentPage(page);
    // setIsPageChange(true);
  };

  const paginationConfig = {
    pageSize: pageSize ? pageSize : 1,
    total: totalLength ? totalLength : 0,
    showSizeChanger: false,
    showQuickJumper: false,
    current: currentPage,
    showTotal: (total) => `Total ${total} items`,
    onChange: onChangePage,
    itemRender: (current, type, originalElement) => {
      if (type === 'prev') {
        return <span className="pagination-item">Previous</span>;
      }
      if (type === 'next') {
        return <span className="pagination-item">Next</span>;
      }
      return originalElement;
    }
  };

  const columns = [
    {
      title: lang.label_id,
      dataIndex: 'id',
      key: 'uniqueId',
      width: 20,
      fixed: 'left',
      render: (_, record) => {
        return (
          <Button
            style={{
              padding: '0px 0px'
            }}
            type="link"
            onClick={() => showModal(record?.id)}>
            {record?.id}
          </Button>
        );
      }
    },
    {
      title: lang.label_username,
      dataIndex: 'username',
      key: 'username',
      fixed: 'left',
      render: (_, record) => {
        return (
          <Link style={{ color: '#1677ff' }} to={`/player-management/player/${record?.player_id}/view`}>
            {record?.player?.user?.user_name}
          </Link>
        );
      },
      width: 100
    },
    {
      title: lang.label_referenceid,
      dataIndex: 'is_deposit_transaction',
      key: 'reference_no',
      render: (_, record) => {
        return (
          <div>
            {record?.is_deposit_transaction?.reference_no || '-'}{' '}
            {record?.is_deposit_transaction?.reference_no ? (
              <Popover
                content={
                  <div style={{ cursor: 'default' }} onClick={hide}>
                    Copied Successfully!
                  </div>
                }
                // title="Copied Successfully!"
                trigger="click"
                open={popoverOpen === record.id}
                onOpenChange={(open) =>
                  handleOpenChange(open, record, record?.is_deposit_transaction?.reference_no ?? '-')
                }>
                <Button
                  style={{
                    padding: '0px 1px'
                  }}
                  type="link">
                  <FaCopy color="gray" />
                </Button>
              </Popover>
            ) : null}
          </div>
        );
      }
    },
    {
      title: lang.label_transfer_no,
      dataIndex: 'is_deposit_transaction',
      key: 'transfer_no',
      render: (_, record) => {
        return record?.is_deposit_transaction?.deposit_transaction_no || '-';
      },
      width: 100
    },
    {
      title: lang.label_agentid,
      dataIndex: 'player',
      key: 'agentId',
      render: (_, record) => {
        return record?.player?.agent?.unique_code || '-';
      },
      width: 100
    },
    {
      title: lang.label_amount,
      dataIndex: 'amount',
      key: 'amount',
      render: (record) => toFixedFunc(record, process.env.REACT_APP_Decimel_Points) || '-',
      width: 100
    },
    {
      title: lang.label_Currency,
      dataIndex: 'currency_name',
      key: 'currency_name',
      width: 50
    },
    {
      title: lang.label_company_payment_method,
      dataIndex: 'payment_method',
      key: 'companyPaymentMethod',
      render: (item) => <div>{item?.internal_name || '-'}</div>,
      width: 100
    },
    {
      title: lang.label_createdtime,
      dataIndex: 'created_at',
      key: 'createdTime',
      render: (item) => {
        return convertUTCToLocalTime(item);
      },
      width: 100
    },
    {
      title: lang.label_approved_time,
      dataIndex: 'approved_time',
      key: 'approved_time',
      render: (item) => {
        return item ? convertUTCToLocalTime(item) : '-';
      },
      width: 100
    },
    {
      title: lang.label_status,
      dataIndex: 'status_name',
      key: 'status',
      render: (record, row) => makeStatusBadge(row)
    },
    {
      title: lang.label_actions,
      key: 'actions',
      dataIndex: 'actions',
      render: (item, row) => depositActions(row),
      fixed: 'right',
      width: 150
    }
  ];
  // console.log(transactions)

  const onRefresh = () => {
    getPaymentMethods();
    getTransactions();
  };
  return (
    <div>
      <HeaderCardDetails />
      <div style={{ padding: '20px' }}>
        <TransactionFilters
          setData={(data) => {
            setCurrentPage(1);
            setFilterData(data);
          }}
          paymentMethods={paymentMethods}
          onRefresh={onRefresh}
          popUp={true}
          exportButton={
            <>
              {' '}
              {loadingData ? (
                <div style={{ paddingRight: '40px', display: 'flex', alignItems: 'center' }}>
                  <Spin indicator={<LoadingOutlined spin />} size="large" />
                  {/* <div style={{ marginLeft: '10px', fontWeight: '500', fontSize: '16px' }}>
                {downloadedFiles} of {totalFiles} Files Downloaded
              </div> */}
                </div>
              ) : (
                <div>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="btnStyle"
                    style={{
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1',
                      marginRight: '15px'
                    }}
                    onClick={() => exportData(filterData)}>
                    Export
                  </Button>
                </div>
              )}
            </>
          }
        />
        <br />
        <Table
          scroll={{
            x: 1800
          }}
          columns={columns}
          loading={loading}
          dataSource={transactions}
          bordered
          pagination={false}
          rowKey={'id'}
        />
        {transactions?.length > 0 && <PaginationComp paginationConfig={paginationConfig} />}
        <Spacer />
        <Spacer />
        <DepositSumTable data={totalDeposit} loading={loading} />
        <FinanceApproveDepositModal
          modalOpen={faModalOpen}
          onCloseClicked={() => setFaModalOpen(false)}
          onActionClicked={() => {
            setFaModalOpen(false);
            getTransactions();
          }}
          transaction={activeTransaction}
          paymentMethods={paymentMethods}
        />
        <DepositPopup handleCancel={handleCancel} handleOk={handleOk} isModalOpen={isModalOpen} popupId={popupId} />
      </div>
    </div>
  );
};

export default Deposit;
