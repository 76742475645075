import React, { useState, useRef,useCallback,useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = ({ value, onChange }) => {
  const quillRef = useRef();
  const [showSourceCode, setShowSourceCode] = useState(false);

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    // When a file is selected
    input.onchange = () => {
      const file = input.files[0];
      const reader = new FileReader();

      // Read the selected file as a data URL
      reader.onload = () => {
        const imageUrl = reader.result;
        const quillEditor = quillRef.current.getEditor();

        // Get the current selection range and insert the image at that index
        const range = quillEditor.getSelection(true);
        quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
      };

      reader.readAsDataURL(file);
    };
  }, []);

//   const modules = {
//     toolbar: {
//       container: [
//         [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
//         [{ 'list': 'ordered'}, { 'list': 'bullet' }],
//         ['bold', 'italic', 'underline'],
//         [{ 'color': [] }, { 'background': [] }],
//         [{ 'align': [] }],
//         ['link', 'image', 'video'],
//         ['clean']
//       ],
//       handlers: {
//         image: imageHandler
//       }
//     },
//   };

const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ["bold", "italic", "underline", "blockquote"],
          [{ color: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    [imageHandler]
  );

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "clean",
  ];

//   const formats = [
//     'header', 'font', 'size',
//     'bold', 'italic', 'underline', 'strike', 'blockquote',
//     'list', 'bullet', 'indent',
//     'link', 'image', 'video', 'color', 'background', 'align'
//   ];

const toggleSourceCode = () => {
    setShowSourceCode(!showSourceCode);
  };

  return (
    <div>
       <div>
      <button onClick={toggleSourceCode}>
        {showSourceCode ? 'Show Editor' : 'Show Source Code'}
      </button>
      {showSourceCode ? (
        <textarea
          style={{ width: '100%', height: '200px' }}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      ) : (
        <ReactQuill
          ref={quillRef}
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
        />
      )}
    </div>
    </div>
  );
};

export default RichTextEditor;
