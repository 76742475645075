import {  Table } from "antd";
import LangContext from "../../../helps/contexts/lang-context";
import { useContext, useState } from "react";
import CustomBadge from "../../../components/HelperComponents/CustomBadge";
import { convertUTCToLocalTime, toFixedFunc } from "../../../helps/HelperFunctions";

const BankMessagesTable = ({ data, loading }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [rowdata, setRowdata] = useState({});

  const addCommasToNumber = val => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

  // Edit player popup

  const columns = [
    {
      title: lang.label_message,
      dataIndex: "message",
      key: "message",
      width: "10%",
    },
    {
      title: lang.label_bankname,
      dataIndex: "bankname",
      key: "bankname",
    },
    {
      title: lang.label_amount,
      dataIndex: "amount",
      key: "amount",
      render: (record) => toFixedFunc(record, process.env.REACT_APP_Decimel_Points)
    },
    {
      title: lang.label_note,
      dataIndex: "note",
      key: "note ",
    },

    {
      title: lang.label_incomingdate,
      dataIndex: "incomingdate",
      key: "incomingdate",
      render: (time, row) => (
        <a title={row["incomingdate"]} >
          <span style={{ color: "black" }}>
            {convertUTCToLocalTime(row["incomingdate"]) }
          </span>
        </a>
      ),
    },
    {
      title: lang.label_status,
      dataIndex: "status",
      key: "status",
      render: (_, record) =>
       
      // <Badge
      // status="success"
      //   count={show ?lang.label_active : 0}
      //   showZero
      //   color="#52c41a"
      // />
      <CustomBadge
      text={record?.status === 1 ? "Approved" :record?.status === 2 ? "Rejected":record?.status === 'pending' ? "Pending" :record?.status === 4 ? "Processing":""}
      bgColor={
        record?.status === 1 ? "#E7F9E7" :record?.status === 2 ? "#FFE9E9":record?.status === 'pending'  ? "#F1F1F1" :record?.status === 4 ? "#F6AC00":""
      }
      color={
        record?.status === 1 ? "#148F14" :record?.status === 2 ? "#FF0000":record?.status === 'pending'  ? "#707070" :record?.status === 4 ? "#FF5B00":""
      }
    />
    },
  ];

  return (
    <div>
      <Table
        size="small"
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
        scroll={{ x: "scroll" }}
        style={{ width: "100%",  overflow: "auto" }}
        bordered
        onRow={(record) => {
          return {
            onClick: () => {
              setRowdata(record);
            },
          };
        }}
      />
    </div>
  );
};
export default BankMessagesTable;
