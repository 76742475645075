import { Table, Tag } from "antd";

import LangContext from "../../../helps/contexts/lang-context";
import { useContext, useState } from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { convertUTCToLocalTime } from "../../../helps/HelperFunctions";
const GameAccessHistoryTable = ({data,loading}) => {
 
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const[rowdata,setRowdata]=useState({})

  // Edit player popup

 
  const columns = [
    {
      title: lang.label_playername,
      dataIndex: "playername",
      key: "playername",
      
    },
    
    {
        title: lang.label_platformname,
        dataIndex: "platformname",
        key: "platformname",
        
      },
      {
        title: lang.label_gamename,
        dataIndex: "gamename",
        key: "gamename",
        render:(record)=>{
          const name = record && JSON.parse(record)
         return(<a style={{color:"grey"}}>{name &&  name['en']}</a>)
         
        }
        
      },
    {
      title: lang.label_gamecategoryname,
      dataIndex: "gamecategoryname",
      key: "gamecategoryname",
      render:(records)=>{
        return  records.map((record)=>{
            const name = record && JSON.parse(record.name)
            return (<div style={{ color: "grey" }}>{name && name['en']}</div>)
          })
       }
    },
    {
        title: lang.label_remark,
        dataIndex: "remark",
        key: "remark",
        
      },
   
   
   
    {
      title: lang.label_status,
      dataIndex: "status",
      key: "status",
      render: (_, record) =>
      record.status === 1 ? 
      (<Tag color="success" icon={<CheckCircleOutlined />}>
    Success
    </Tag>)
       : record.status=== 2 ? 
       (<Tag color="error"icon={<CloseCircleOutlined />}>
    Failure
     </Tag>) :""
           
    
    },
   
    {
        title: lang.label_date,
        dataIndex: "date",
        key: "date",
      render: (record) => <span>{convertUTCToLocalTime(record)}</span>
      },

    
  ]

  return (
    <div>
      <Table
       style={{width:"100%",height:"auto"}}
       size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        bordered
        onRow={(record) => {
          return {
            onClick: () => {
              setRowdata(record);
             
            },
          };
        }}
      />
      
    </div>
  );
};
export default GameAccessHistoryTable;
