import { Button, message } from "antd";
import PutApi from "../../../../../helps/contexts/Api/PutApi";
import LangContext from "../../../../../helps/contexts/lang-context";
import { useContext, useState, useEffect } from "react";

function EnableBank({ cancelEnableBank, rowData, fetchUserBankData }) {
  const [bankid, setBankid] = useState("");
  //Language translation
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  // Sending enable request to api
  const submitEnableBank = async (e) => {
    try {
      const path = `/user/bank/status/toggle/${bankid}`;
      const res = await PutApi.PutApiRequest(path, {});

      //if success
      if (res && res.status == true) {
        message.success(lang.label_bankenablesuccess);
        cancelEnableBank(); 
        fetchUserBankData()
      }
    } catch (e) {
      message.error(lang.label_bankenablefail);
    }
  };

  useEffect(() => {
    if (rowData && rowData.key) {
      setBankid(rowData.key);
    } else {
      setBankid("");
    }
  }, []);

  return (
    <form
      style={{ height: "70px" }}
      onSubmit={(e) => {
        e.preventDefault();
        submitEnableBank();
      }}
    >
      {lang.label_enablebankinfo}
      <span
        style={{
          position: "absolute",
          right: "2.4%",
          bottom: "0",
          marginBottom: "0.5rem",
        }}
      >
        <Button
          htmlType="submit"
          type="primary"
          style={{
            marginRight: "0.5rem",background:"#004a7f",borderRadius:"2.5px"
          }}
        >
          {lang.label_enablebank}
        </Button>
        <Button    style={{
           borderRadius:"2.5px"
          }}onClick={cancelEnableBank}>{lang.label_cancel}</Button>
      </span>
    </form>
  );
}

export default EnableBank;
