import { Card, Row, Col, DatePicker, Button, Input, Pagination, Modal } from 'antd';
import { useContext, useState, useEffect } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import BankMessagesTable from './BankMessagesTable';
import GetApi from '../../../helps/contexts/Api/GetApi';
import '../../../styles/ButtonStyle.css';
import CreateMessage from './CreateMessage';
import { CalendarOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import moment from 'moment';
import { convertLocalTimeToUTC, convertUTCToLocalTime } from '../../../helps/HelperFunctions';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';

const BankMessages = () => {
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();

  const lang = ctx.lang;
  const nav = useNavigate();

  //styles
  const datePickerStyle = {
    width: '185px',
    height: '35px',
    background: ' #FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const iconStyle = { color: '#004A7F' };
  const inputStyle = {
    width: '190px',
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  //states

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchtext, setSearchtext] = useState('');
  const [startTimeParam, setStartTimeParam] = useState('');
  const [endTimeParam, setEndTimeParam] = useState('');
  const [bankmessagesData, setBankmessagesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [visible, setVisible] = useState(false);
  const [isadd, setIsadd] = useState(false);
  useEffect(() => {
    getBankmessages();
  }, []);

  //date picker startdate
  const onChange = (value, dateString) => {
    setStartDate(value);
    setStartTimeParam(dateString);
  };
  //date picker start date completed
  //date picker end date

  const onChange1 = (value, dateString) => {
    setEndDate(value);
    setEndTimeParam(dateString);
  };
  //onreset
  const onReset = async () => {
    setLoading(true);
    setStartDate('');
    setEndDate('');
    setSearchtext(null);
    setActivepage1(1);

    const path = `/admin/bank_messages/all`;

    const res = await GetApi.sendApiRequest(path, null, null);

    if (res && res.data && res.data.length) {
      const finalArray = res.data.map((bank, index) => ({
        key: index,
        message: bank && bank.content,
        bankname: bank && bank.company_bank.bank_code,
        amount: bank && bank.amount,
        note: bank && bank.note ? bank.note : '-',
        incomingdate:
          bank && bank.created_at ? (
            <span>
              {convertUTCToLocalTime(bank.created_at)}
              {/* moment.utc(bank.created_at, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss") */}
            </span>
          ) : (
            '-'
          ),
        transactionid: bank && bank.transaction_id,
        status: bank && bank.transaction ? bank.transaction.status : '-'
      }));
      setBankmessagesData(finalArray);
      setCurrentpage(res.current_page);
      setTotal(res.total);
      setPerpage(res.per_page);
      setNextPage(res.next_page_url);
    } else {
      setBankmessagesData([]);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    setIsadd(false);
    setVisible(false);
  };

  const handleOk = () => {
    setVisible(false);
  };

  //on submit of filters on table values
  const onSubmit = (e) => {
    e.preventDefault();
    setActivepage1(1);
    getBankmessages();
  };

  const getBankmessages = async (customPage = null) => {
    try {
      const path = `/admin/bank_messages/all`;
      const params = {};
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      if (startDate) params['from_time'] = convertLocalTimeToUTC(startTimeParam);
      if (endDate) params['to_time'] = convertLocalTimeToUTC(endTimeParam);
      if (searchtext) params['search'] = searchtext;
      setLoading(true);
      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.data && res.data.length) {
        const finalArray = res.data.map((bank, index) => ({
          key: index,
          message: bank && bank.content,
          bankname: bank && bank.company_bank.bank_code,
          amount: bank && bank.amount,
          note: bank && bank.note ? bank.note : '-',
          incomingdate:
            bank && bank.created_at ? (
              <span>
                {convertUTCToLocalTime(bank.created_at)}
                {/* moment.utc(bank.created_at, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss") */}
              </span>
            ) : (
              '-'
            ),
          transactionid: bank && bank.transaction_id,
          status: bank && bank.transaction ? bank.transaction.status : '-'
        }));
        setBankmessagesData(finalArray);
        setCurrentpage(res.current_page);
        setTotal(res.total);
        setPerpage(res.per_page);
        setNextPage(res.next_page_url);
      } else {
        setBankmessagesData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };
  // on click of a page in pagination component

  const onChangepage = (page) => {
    setActivepage1(page);
    getBankmessages(page);
  };
  return (
    <>
      <HeaderCardDetails />

      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div>
          <Card
            title={lang.label_filters}
            style={{
              borderRadius: '3px',
              height: '140px'
            }}>
            <div>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center'
                }}>
                <Col
                  span={6}
                  style={{
                    color: '#636363',
                    fontSize: '14px',
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-around',
                    alignItems: 'center'
                  }}>
                  {/* <p
                   
                  > */}
                  <label style={{ marginLeft: '-1%', marginRight: '2%' }}>{lang.label_time} </label>

                  <DatePicker
                    //getting input value for start date
                    placeholder="Select Time"
                    showTime
                    format="YYYY-MM-DD HH:mm:ss"
                    style={datePickerStyle}
                    onChange={onChange}
                    value={startDate}
                    suffixIcon={<CalendarOutlined style={iconStyle} />}
                  />
                  <span
                    style={{
                      color: 'black',
                      fontSize: '33px',
                      marginLeft: '2%',
                      marginRight: '2%'
                    }}>
                    -
                  </span>
                  <DatePicker
                    placeholder="Select Time"
                    showTime
                    format="YYYY-MM-DD HH:mm:ss"
                    onChange={onChange1}
                    style={datePickerStyle}
                    //getting input value for end date
                    value={endDate}
                    suffixIcon={<CalendarOutlined style={iconStyle} />}
                  />
                  {/* </p> */}
                </Col>
                <Col
                  span={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}>
                  <label style={{ marginLeft: '10%', marginRight: '2%' }}>{lang.label_search} </label>
                  <Input
                    style={inputStyle}
                    placeholder="input search text"
                    onChange={(e) => {
                      setSearchtext(e.target.value);
                    }}
                    value={searchtext}
                  />
                </Col>

                <Col
                  span={10}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}>
                  <Button
                    htmlType="submit"
                    style={{ marginLeft: '1rem' }}
                    onClick={() => {
                      setActivepage1(1);
                      setLoading(true);
                    }}
                    className="btnStyle">
                    {lang.label_search}
                  </Button>

                  <Button
                    type="secondary"
                    style={{
                      marginLeft: '1rem'
                    }}
                    //handling reset
                    onClick={() => {
                      onReset();
                    }}
                    className="btnReset">
                    <label style={{ color: '#004A7F' }}>{lang.label_reset}</label>
                  </Button>
                </Col>
                <Col
                  span={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}>
                  {hasPermission('Create Bank Message') && (
                    <Button
                      type="secondary"
                      className="btnStyle"
                      //handling reset
                      onClick={() => {
                        setIsadd(true);
                        setVisible(true);
                      }}>
                      <label style={{ color: 'white' }}>{lang.label_addbankmessage}</label>
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </Card>
          <br />
        </div>
      </form>
      <div style={{ width: '100%', overflow: 'scroll' }}>
        <BankMessagesTable data={bankmessagesData} loading={loading} getBankmessages={getBankmessages} />
        <br />
        <Pagination
          defaultCurrent={1}
          current={activepage}
          onChange={onChangepage}
          total={total}
          responsive={true}
          pageSize={perpage}
        />
      </div>
      <div>
        {isadd && isadd ? (
          <Modal
            title={lang.label_addbankmessage}
            open={visible}
            footer={null}
            width={600}
            closable={true}
            onCancel={handleCancel}>
            <CreateMessage handleSubmit={handleOk} handleCancel={handleCancel} getBankmessages={getBankmessages} />
          </Modal>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
export default BankMessages;
