import React, { useState, useContext, useEffect } from 'react';
import { Button, Card, Col, Modal, Row, Table, Tag, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import CustomButton from '../../../../components/HelperComponents/CustomButton';
import MiniText from '../../../../components/HelperComponents/MiniText';
import BoaderBottomFlex from '../../../../components/HelperComponents/BoaderBottomFlex';
import LangContext from '../../../../helps/contexts/lang-context';
import styles from '../../../dashboard/DashboardPage.module.css';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import TextAreaWithLabel from '../../../../components/HelperComponents/TextAreaWithLabel';
import PutApi from '../../../../helps/contexts/Api/PutApi';
import { useNavigate } from 'react-router-dom';

const RiskApproveModal = ({ riskModalOpen, onCloseClicked, onActionClicked, transaction }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [BtnLoader, setBtnLoader] = useState(false);
  const [remark, setRemark] = useState();
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();

  useEffect(() => {
    if (!transaction) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [transaction]);

  const sendRiskAction = async (action) => {
    setLoading(true);
    const payload = {
      risk_action_status: action,
      risk_action_note: remark
    };
    try {
      const path = `/admin/transactions/${transaction.id}/risk`;
      //sending api request
      const res = await PutApi.PutApiRequest(path, payload);
      if (res && res.status) {
        setRemark('');
        onActionClicked();
      }
    } catch (error) {
      console.log(error);

      if (error?.data?.message === 'AUTO_RISK_APPROVAL_IS_TRUE_YOU_CANNOT_DO_MANUAL') {
        message.error(`Auto risk approval is enabled. Manual approval/reject is not possible.`);
      }

      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
    setLoading(false);
  };

  const riskRejectFunc = () => {
    //reject request
  };

  const cancelRiskFunc = () => {
    onCloseClicked();
  };

  return (
    <Modal
      title={lang.label_riskverify}
      centered
      loading={loading}
      open={riskModalOpen}
      onCancel={onCloseClicked}
      footer={() => (
        <div
          style={{
            display: 'flex',
            alignItems: 'middle',
            justifyContent: 'right',
            marginTop: '25px',
            gap: '10px'
          }}>
          <Button type="primary" onClick={() => sendRiskAction(1)} disabled={loading} icon={<CheckOutlined />}>
            {lang.label_riskapprove}
          </Button>
          <Button onClick={() => sendRiskAction(2)} disabled={loading} icon={<CloseOutlined />} danger>
            {lang.label_riskreject}
          </Button>
          <Button onClick={cancelRiskFunc} disabled={loading}>
            {lang.label_cancel}{' '}
          </Button>
        </div>
      )}
      width={560}
      style={{
        height: '782px',
        maxHeight: '782px',
        borderRadius: '5px',
        padding: '24px 24px',
        width: '560px'
      }}>
      <TextAreaWithLabel
        label={lang.label_remark}
        value={remark}
        onChange={(val) => setRemark(val)}
        isRequired={false}
      />
    </Modal>
  );
};

export default RiskApproveModal;
