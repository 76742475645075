import { Card, Row, Col, DatePicker, Button, Input, Pagination } from 'antd';
import { useContext, useState, useEffect } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import GetApi from '../../../helps/contexts/Api/GetApi';
import '../../../styles/ButtonStyle.css';
import { CalendarOutlined } from '@ant-design/icons';
import ChangeAgentHistoryTable from './ChangeAgentHistoryTable';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import Spacer from '../../../components/HelperComponents/Spacer';
import { convertLocalTimeToUTC } from '../../../helps/HelperFunctions';
import { TabContext } from '../../../helps/contexts/TabContext';
import SearchIcon from "../../../images/Search.png";
import ResetIcon from "../../../images/Reset.png";
import dayjs from 'dayjs';
//styles
const datePickerStyle = {
  width: '250px',
  height: '35px',
  background: ' #FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #D9D9D9',
  borderRadius: '3px'
};
const iconStyle = { color: '#004A7F' };
const inputStyle = {
  width: '250px',
  height: '35px',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #D9D9D9',
  borderRadius: '3px'
};

const ChangeAgentHistory = () => {
  //Lang Translation
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  //states
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchtext, setSearchtext] = useState('');
  const [startTimeParam, setStartTimeParam] = useState('');
  const [endTimeParam, setEndTimeParam] = useState('');
  const [loading, setLoading] = useState(false);
  const [agentHistoryTable, setAgentHistoryTable] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const { path } = useContext(TabContext);

  // Fetching login history api
  const getChangeAgnentHistory = async (customPage = null) => {
    const path = '/admin/agent-histories';
    const params = {};
    setLoading(true);
    //setting parameters to send for search results
    if (customPage) params['page'] = customPage;
    else params['page'] = activepage;
    if (startDate) params['start_date'] = convertLocalTimeToUTC(startTimeParam);
    if (endDate) params['end_date'] = convertLocalTimeToUTC(endTimeParam);
    if (searchtext) params['search'] = searchtext;

    //sending api request
    const res = await GetApi.sendApiRequest(path, {}, params);
    // console.log(res)
    if (res && res.data && res.data.length) {
      const finalArray = res.data.map((list) => ({
        key: list.id ? list.id : '',
        player: list.player ? list.player.user.user_name : '',
        previousagent: list.previous_agent ? list.previous_agent.user.user_name : '',
        newagent: list.new_agent ? list.new_agent.user.user_name : '',
        changedby: list.change_by ? list.change_by.user.user_name : '',
        time: list.created_at ? list.created_at : ''
      }));
      setAgentHistoryTable(finalArray);
      setCurrentpage(res.current_page);
      setTotal(res.total);
      setPerpage(res.per_page);
      setNextPage(res.next_page_url);
    } else {
      setAgentHistoryTable([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getChangeAgnentHistory();
  }, []);

  useEffect(() => {
    if (path === '/agent-management/change-agent-history') {
      getChangeAgnentHistory();
    }
  }, [path]);

  const onChangepage = (page) => {
    setActivepage1(page);
    getChangeAgnentHistory(page);
  };
  //on submit of filters on table values
  const onSubmit = (e) => {
    e.preventDefault();
    getChangeAgnentHistory();
  };

  // Reset Button
  const onReset = async () => {
    setActivepage1(1);
    setLoading(true);
    setSearchtext('');
    setStartDate('');
    setEndDate('');
    setSearchtext('');

    const path = '/admin/agent-histories';
    const params = { page: 1 };
    setLoading(true);
    //sending api request
    const res = await GetApi.sendApiRequest(path, null, params);
    if (res && res.data && res.data.length) {
      const finalArray = res.data.map((list) => ({
        key: list.id ? list.id : '',
        player: list.player ? list.player.user.user_name : '',
        previousagent: list.previous_agent ? list.previous_agent.user.user_name : '',
        newagent: list.new_agent ? list.new_agent.user.user_name : '',
        changedby: list.change_by ? list.change_by.user.user_name : '',
        time: list.created_at ? list.created_at : ''
      }));
      setAgentHistoryTable(finalArray);
      setCurrentpage(res?.current_page);
      setTotal(res?.total);
      setPerpage(res?.per_page);
      setNextPage(res?.next_page_url);
    } else {
      setAgentHistoryTable([]);
      setTotal(0);
    }

    setLoading(false);
  };

  return (
    <div>
      <HeaderCardDetails />

      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div>
          <Card
            title={lang.label_filters}
            style={{
              borderRadius: '3px'
            }}>
            <div>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center'
                }}>
                <Col
                  span={16}
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <label style={{ marginRight: '1rem' }}>{lang.label_time}</label>

                  <DatePicker
                    placeholder={lang.label_selectdate}
                    showTime ={{
                      defaultValue :dayjs('00:00:00', 'HH:mm:ss'),
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                    style={datePickerStyle}
                    value={startDate}
                    //getting input value for start date
                    onChange={(value, dateString) => {
                      setStartDate(value);
                      setStartTimeParam(dateString);
                    }}
                    suffixIcon={<CalendarOutlined style={iconStyle} />}
                  />
                  <span
                    style={{
                      color: 'black',
                      fontSize: '33px'
                    }}>
                    -
                  </span>
                  <DatePicker
                    placeholder={lang.label_selectdate}
                    showTime ={{
                      defaultValue :dayjs('23:59:59', 'HH:mm:ss'),
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                    style={datePickerStyle}
                    value={endDate}
                    //getting input value for end date
                    onChange={(value, dateString) => {
                      setEndDate(value);
                      setEndTimeParam(dateString);
                    }}
                    suffixIcon={<CalendarOutlined style={iconStyle} />}
                  />
                  <label style={{ marginLeft: '1rem', marginRight: '1rem' }}>{lang.label_username}</label>
                  <Input
                    style={inputStyle}
                    onChange={(e) => {
                      setSearchtext(e.target.value);
                    }}
                    name="search"
                    type="text"
                    value={searchtext}
                  />
                  <Button
                    style={{ margin: '0.6rem', width: '15%',display : 'flex',
                      alignItems : 'center',
                      justifyContent : 'space-evenly', }}
                    htmlType="submit"
                    className="btnStyle"
                    onClick={() => {
                      setLoading(true);
                    }}>
                      <img src={SearchIcon} alt='search' />
                    {lang.label_search}
                  </Button>
                  <Button
                  style={{
                    display : 'flex',
                alignItems : 'center',
                justifyContent : 'space-evenly',
                  }}
                    className="btnReset"
                    htmlType="reset"
                    onClick={() => {
                      onReset();
                    }}>
                       <img src={ResetIcon} alt='reset' />
                    {lang.label_reset}
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
          <Spacer />
          <ChangeAgentHistoryTable data={agentHistoryTable} loading={loading} />
          {/* Pagination */}
          {agentHistoryTable?.length > 0 && (
            <Pagination
              defaultCurrent={1}
              current={activepage}
              onChange={onChangepage}
              total={total}
              responsive={true}
              pageSize={perpage}
            />
          )}
        </div>
      </form>
    </div>
  );
};
export default ChangeAgentHistory;
