import { Table, Modal, Dropdown, Button } from 'antd';

import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState, useEffect } from 'react';
import EditAgentForm from './EditAgentForm';
import ResetPwdForm from '../../../components/forms/ResetPwdForm';
import { Menu } from 'antd';
import { IoMenu } from 'react-icons/io5';
import DeleteAgentForm from './DeleteAgentForm';
import { convertUTCToLocalTime } from '../../../helps/HelperFunctions';
import { MdEdit } from 'react-icons/md';
import UpdateBalanceForm from './UpdateBalanceForm';
import UpdateCreditForm from './UpdateCreditForm';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import { FaPlus } from 'react-icons/fa6';
import { LuWallet } from 'react-icons/lu';
import WalletPopUp from './WalletPopUp';
import { useNavigate } from 'react-router-dom';
const AgentListTableNew = ({ data, loading, fetchAgents }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { hasPermission } = useCheckPermission();
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState({});
  const [isEdit, setIsedit] = useState(false);
  const [deleteAgent, setDeleteAgent] = useState(false);
  const [reset, setReset] = useState(false);

  const [agentID, setAgentID] = useState('');
  const [updateBalanceOpen, setUpdateBalanceOpen] = useState(false);
  const [updateCreditOpen, setUpdateCreditOpen] = useState(false);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const [openWallet, setOpenWallet] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const navigate = useNavigate();

  const toggleWallet = (record) => {
    setSelectedRecord(record);
    setOpenWallet(true);
  };

  const handleCloseWallet = () => {
    setOpenWallet(false);
    setSelectedRecord(null);
  };

  // Edit Agent popup

  const handleCancel = (e) => {
    //  getPlayers();
    setIsedit(false);
    setDeleteAgent(false);
    setVisible(!visible);

    // setVisible(false);
  };

  //for the edit option in table
  const handleEditAgent = (record) => {
    setRowData(record);
    setIsedit(true);
    setReset(false);
    setDeleteAgent(false);
    setVisible(true);
  };
  //for the resetpwd option in table
  const handleResetPwd = () => {
    setIsedit(false);
    setReset(true);
    setDeleteAgent(false);
    setVisible(!visible);
  };
  const handleDelAgent = () => {
    setIsedit(false);
    setReset(false);
    setDeleteAgent(true);
    setVisible(true);
  };

  const handleEditClick = (row) => {
    setSelectedAgent(row);
    setNewStatus(row.status); // Set the initial status to the selected agent's status
    setIsEditModalVisible(true);
  };

  const handleConfirm = () => {
    // Handle the status update logic here
    console.log(`Status updated to ${newStatus === 1 ? 'Active' : 'Inactive'}`);
    setIsConfirmModalVisible(false);
    setIsEditModalVisible(false);
    // You can also update the status in your backend or state here
  };

  const handleCancelStatus = () => {
    setIsConfirmModalVisible(false);
    setIsEditModalVisible(false);
  };

  const handleStatusChange = (status) => {
    setNewStatus(status);
    setIsConfirmModalVisible(true); // Open the confirmation modal after status change
  };

  //menu items in table

  const items = [
    {
      key: 1,
      label: 'Edit Agent'
    },
    {
      key: 2,
      label: 'Reset Password'
    },
    {
      key: 3,
      label: 'Delete Agent'
    }
  ];

  useEffect(() => {
    if (isEdit) {
      handleEditAgent(rowData);
    }
  }, [isEdit]);
  //onclick of option in table
  const onClick = ({ key }) => {
    if (key == 1) {
      handleEditAgent();
    }
    if (key == 2) {
      handleResetPwd();
    }
    if (key == 3) {
      handleDelAgent();
    }
  };

  const columns = [
    {
      title: lang.label_agent_name,
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: lang.label_agent_level,
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: lang.lable_real_name,
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: lang.lable_settle_cycle,
      dataIndex: 'agent_type_name',
      key: 'agent_type_name',
      render: (text, row) => <>{text ?? `-`}</>
    },
    {
      title: lang.lable_registed_at,
      dataIndex: 'superioragent',
      key: 'superioragent'
    },
    {
      title: lang.lable_down_lines,
      dataIndex: 'uniquecode',
      key: 'uniquecode',
      render: (text, row) => (
        <>
          {(
            <div
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => navigate('/agent-management/downline-list')}>
              {text}
            </div>
          ) ?? `-`}
        </>
      )
    },
    {
      title: lang.lable_total_player,
      dataIndex: 'balance',
      key: 'balance',
      render: (text, row) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px'
          }}>
          {text ? Number(text).toFixed(2) : `0.00` ?? ``}
          {hasPermission('Create Agent Wallet') && (
            <Button
              type="link"
              style={{ border: 'none', padding: '0px' }}
              onClick={() => {
                setAgentID(row?.id);
                setUpdateBalanceOpen(true);
              }}>
              <MdEdit />
            </Button>
          )}
        </div>
      )
    },
    {
      title: lang.lable_wallet_balance,
      dataIndex: 'credit_line',
      key: 'credit_line',
      render: (text, row) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px'
          }}>
          {text ? Number(text).toFixed(2) : `0.00` ?? `-`}
          {hasPermission('Create Agent Credit') && (
            <Button
              type="link"
              style={{ border: 'none', padding: '0px' }}
              onClick={() => {
                setAgentID(row?.id);
                setUpdateCreditOpen(true);
              }}>
              <MdEdit />
            </Button>
          )}
        </div>
      )
    },
    {
      title: lang.lable_status,
      dataIndex: 'status',
      key: 'status',
      render: (text, row) => (
        <>
          {text === 1 ? 'Active' : 'Inactive'}
          <Button type="link" style={{ border: 'none', padding: '0px' }} onClick={() => handleEditClick(row)}>
            <MdEdit />
          </Button>
        </>
      )
    },
    // {
    //   title: `${lang.label_registerip}/${lang.label_registertime}`,
    //   dataIndex: ['registerip', 'registertime'],
    //   key: 'registerip',
    //   render: (time, row) => (
    //     <span style={{ color: '#2A93F0' }} title={row['registertime']}>
    //       <span title={row['registertime']} style={{}}>
    //         {row['registerip']}
    //         <br />
    //         <span style={{ color: 'black' }}>
    //           {row['registertime'] ? convertUTCToLocalTime(row['registertime']) : '-'}
    //         </span>
    //       </span>
    //     </span>
    //   )
    // },
    {
      //   title: `${lang.label_loginip}/${lang.label_logintime}`,
      title: lang.lable_update_at,
      dataIndex: ['loginip', 'logintime'],
      key: 'loginip',
      render: (time, row) => (
        <>
          {row?.created_by_admin ? (
            '-'
          ) : (
            <span style={{ color: '#2A93F0' }} title={row['logintime']}>
              {row['loginip']}
              {row['logintime'] && <br />}
              <span style={{ color: 'black' }}>{row['logintime'] && convertUTCToLocalTime(row['logintime'])}</span>
            </span>
          )}
        </>
      )
    },
    {
      title: lang.lable_update_by,
      dataIndex: 'uniquecode',
      key: 'uniquecode'
    },
    {
      title: lang.label_remark,
      dataIndex: 'uniquecode',
      key: 'uniquecode'
    }
  ];

  if (hasPermission('Create Agent')) {
    columns.push({
      title: lang.label_action,
      key: 'action',
      render: (_, record) => {
        return (
          <>
            <div style={{ display: 'flex' }}>
              <div style={{ cursor: 'pointer', marginRight: '10px' }}>
                <FaPlus size={20} onClick={() => navigate('/agent-management/agent-list/new-agent')} />
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() => toggleWallet(record)}>
                <LuWallet size={20} />
              </div>
            </div>
          </>
        );
      }
    });
  }
  return (
    <div>
      <Table
        style={{ width: '100%' }}
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        bordered
        onRow={(record) => {
          return {
            onClick: () => {
              setRowData(record);
            }
          };
        }}
      />
      <div>
        {(isEdit || reset || deleteAgent) && (
          <Modal
            closeIcon={null}
            title={isEdit ? lang.label_editagent : reset ? lang.label_resetpwd : 'Delete Agent'}
            open={visible}
            onCancel={handleCancel}
            footer={null}
            width={isEdit ? 700 : reset ? 700 : 450}
            closable={true}>
            {isEdit ? (
              <EditAgentForm rowdata={rowData} onCancel={handleCancel} fetchAgents={fetchAgents} />
            ) : reset ? (
              <ResetPwdForm rowdata={rowData} onCancel={handleCancel} fetchAgents={fetchAgents} />
            ) : deleteAgent ? (
              <DeleteAgentForm rowdata={rowData} onCancel={handleCancel} fetchAgents={fetchAgents} />
            ) : (
              ''
            )}
          </Modal>
        )}

        <UpdateBalanceForm
          id={agentID}
          setUpdateBalanceOpen={setUpdateBalanceOpen}
          updateBalanceOpen={updateBalanceOpen}
          fetchAgents={fetchAgents}
        />
        <UpdateCreditForm
          id={agentID}
          setUpdateCreditOpen={setUpdateCreditOpen}
          updateCreditOpen={updateCreditOpen}
          fetchAgents={fetchAgents}
        />
        <Modal
          title="Edit Status"
          visible={isEditModalVisible}
          onCancel={() => setIsEditModalVisible(false)}
          footer={null}
          width={300}>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
            <Button
              type={newStatus === 1 ? 'primary' : 'default'}
              onClick={() => handleStatusChange(1)}
              style={{ marginRight: '10px', backgroundColor: '#004A7F', color: 'white' }}>
              Active
            </Button>
            <Button
              type={newStatus === 0 ? 'primary' : 'default'}
              onClick={() => handleStatusChange(0)}
              style={{ backgroundColor: 'red', color: 'white' }}>
              Disabled
            </Button>
          </div>
        </Modal>

        {/* Confirmation Modal */}
        <Modal
          title="Confirm Change"
          visible={isConfirmModalVisible}
          footer={null}
          onCancel={handleCancelStatus}
          width={400}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div
              style={{ color: 'red', fontSize: '20px', display: 'flex', justifyContent: 'center', fontWeight: '500' }}>
              Alert!!!
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p style={{ fontSize: '15px', textAlign: 'center' }}>
                Are you sure you want to change the status to {newStatus === 1 ? 'Active' : 'Inactive'}?
              </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button key="cancel" onClick={handleCancelStatus} style={{ marginRight: '10px' }}>
                Cancel
              </Button>
              <Button
                key="confirm"
                type="primary"
                onClick={handleConfirm}
                style={{ backgroundColor: '#004A7F', color: 'white' }}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>

        <WalletPopUp visible={openWallet} onClose={() => setOpenWallet(false)} record={selectedRecord} />
      </div>
    </div>
  );
};

export default AgentListTableNew;
