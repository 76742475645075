import { Card, Row, Col, Button, Input, Pagination, Modal } from 'antd';

import { useContext, useState, useEffect } from 'react';
import LangContext from '../../../helps/contexts/lang-context';

import GetApi from '../../../helps/contexts/Api/GetApi';
import '../../../styles/ButtonStyle.css';

import SuperiorAgentListTable from './SuperiorAgentListTable';
import AddSuperiorAgentForm from './AddSuperiorAgentForm';
import { useNavigate } from 'react-router-dom';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import Spacer from '../../../components/HelperComponents/Spacer';

const SuperiorAgentList = () => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const nav = useNavigate();

  //styles

  const inputStyle = {
    width: '250px',
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };

  //states

  const [searchtext, setSearchtext] = useState('');

  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [Data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isadd, setIsadd] = useState(false);
  useEffect(() => {
    fetchSeniorgents();
  }, []);
  //onreset
  const onReset = async () => {
    setLoading(true);
    setActivepage1(1);
    setSearchtext('');
    const path = '/admin/agents/superior';

    setLoading(true);

    //sending api request
    const res = await GetApi.sendApiRequest(path, null, null);

    if (res && res.data && res.data.length) {
      const finalArray = res.data.map((agent, index) => ({
        key: index,
        id: agent.id,
        name: agent && agent.user && agent.user.name ? agent.user.name : '',
        username: agent.user.user_name,
        phone: agent && agent.user && agent.user.phone ? agent.user.phone : '',

        uniquecode: agent && agent.unique_code ? agent.unique_code : '',
        loginip:
          agent && agent.user && agent.user.latest_login_history && agent.user.latest_login_history.ip
            ? agent.user.latest_login_history.ip
            : '-',
        logintime:
          agent && agent.user && agent.user.latest_login_history && agent.user.latest_login_history.created_at
            ? agent.user.latest_login_history.created_at
            : '',
        registerip:
          agent && agent.user && agent.user.signup_history && agent.user.signup_history.ip
            ? agent.user.signup_history.ip
            : '-',
        registertime:
          agent && agent.user && agent.user.signup_history && agent.user.signup_history.created_at
            ? agent.user.signup_history.created_at
            : ''
      }));
      setData(finalArray);
      setCurrentpage(res.current_page);
      setTotal(res.total);
      setPerpage(res.per_page);
      setNextPage(res.next_page_url);
      setLoading(false);
    } else {
      setData([]);
      setTotal(0);
    }
  };

  //status dropdown
  //on submit of filters on table values
  const onSubmit = (e) => {
    e.preventDefault();

    setActivepage1(1);
    fetchSeniorgents();
  };
  // on click of a page in pagination component

  const onChangepage = (page) => {
    setActivepage1(page);
    fetchSeniorgents(page);
  };
  const showModal = () => {
    setIsadd(true);
    setVisible(true);
  };

  const handleCancel = (e) => {
    setIsadd(false);
    setVisible(false);
  };

  // Fetching api
  const fetchSeniorgents = async (customPage = null) => {
    try {
      const path = '/admin/agents/superior';
      const params = {};
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      if (searchtext) params['search'] = searchtext;

      setLoading(true);

      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.data && res.data.length) {
        const finalArray = res.data.map((agent, index) => ({
          key: index,
          id: agent.id,
          name: agent && agent.user && agent.user.name ? agent.user.name : '',
          username: agent && agent.user && agent.user.user_name ? agent.user.user_name : '',
          phone: agent && agent.user && agent.user.phone ? agent.user.phone : '',
          superioragent:
            agent && agent.senior_agent && agent.senior_agent.user && agent.senior_agent.user.name
              ? agent.senior_agent.user.name
              : '',
          uniquecode: agent && agent.unique_code ? agent.unique_code : '',
          loginip:
            agent && agent.user && agent.user.latest_login_history && agent.user.latest_login_history.ip
              ? agent.user.latest_login_history.ip
              : '-',
          logintime:
            agent && agent.user && agent.user.latest_login_history && agent.user.latest_login_history.created_at ? (
              <span>{new Date(agent.user.latest_login_history.created_at).toLocaleString()}</span>
            ) : (
              ''
            ),
          registerip:
            agent && agent.user && agent.user.signup_history && agent.user.signup_history.ip
              ? agent.user.signup_history.ip
              : '-',
          registertime:
            agent && agent.user && agent.user.signup_history && agent.user.signup_history.created_at ? (
              <span>{new Date(agent.user.signup_history.created_at).toLocaleString()}</span>
            ) : (
              ''
            )
        }));
        setData(finalArray);
        setCurrentpage(res.current_page);
        setTotal(res.total);
        setPerpage(res.per_page);
        setNextPage(res.next_page_url);
      } else {
        setData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };

  return (
    <div>
      <HeaderCardDetails />

      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div>
          <Card
            title={lang.label_filters}
            style={{
              borderRadius: '3px'
            }}>
            <div>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center'
                }}>
                <Col
                  span={18}
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <label style={{ marginRight: '1rem' }}>
                    {lang.label_username}/ {lang.label_uniquecode}
                  </label>
                  <Input
                    style={inputStyle}
                    onChange={(e) => {
                      setSearchtext(e.target.value);
                    }}
                    name="search"
                    type="text"
                    value={searchtext}
                  />

                  <Button
                    type="secondary"
                    style={{ margin: '0.6rem', width: '15%' }}
                    htmlType="submit"
                    className="btnStyle"
                    onClick={() => {
                      setActivepage1(1);
                      setLoading(true);
                    }}>
                    {lang.label_search}
                  </Button>

                  <Button className="btnReset" style={{ backgroundColor: 'white', color: 'black' }} onClick={onReset}>
                    {lang.label_reset}
                  </Button>
                </Col>

                <Col
                  span={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                  }}>
                  <Button
                    type="secondary"
                    onClick={showModal}
                    style={{
                      textAlign: 'center',
                      fontFamily: 'normal normal normal 14px/19px Roboto',
                      letterSpacing: '0px',
                      color: '#ffffff',
                      background: '#004a7f 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      width: '150px',
                      height: '35px'
                    }}>
                    <label> {lang.label_addsuperioragent}</label>
                  </Button>

                  <div>
                    {isadd && isadd ? (
                      <Modal
                        title="Add Superior Agent"
                        open={visible}
                        footer={null}
                        width={700}
                        closable={true}
                        onCancel={handleCancel}>
                        <AddSuperiorAgentForm
                          handleCancel={handleCancel}
                          data={Data}
                          fetchSeniorgents={fetchSeniorgents}
                        />
                      </Modal>
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>

          <Spacer />
          <SuperiorAgentListTable data={Data} loading={loading} />
          <Spacer />
          <Pagination
            defaultCurrent={1}
            currentPage={activepage}
            onChange={onChangepage}
            total={total}
            responsive={true}
            pageSize={perpage}
          />
        </div>
      </form>
    </div>
  );
};
export default SuperiorAgentList;
