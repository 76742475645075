import { Button, Flex, Input, InputNumber, Modal, Select, Switch, Table, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import GetApi from '../../../helps/contexts/Api/GetApi';
import { convertToTwoDecimalPlaces } from '../../../helps/HelperFunctions';

export default function RebateDetailPopUp({ isDetailPopUp, setDetailPopUp, handleCancelDetail, popupID }) {
  // console.log('pop-detail : ', popupID);
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [loading, setLoading] = useState(false);

  const [tableList1, setTableList1] = useState([]);
  const [tableList2, setTableList2] = useState([]);

  const columns1 = [
    {
      title: lang.label_rebate_title,
      dataIndex: 'rebate_title',
      key: 'rebate_title'
    },
    {
      title: lang.label_description,
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: lang.label_start_date,
      dataIndex: 'start_date',
      key: 'start_date'
    },
    {
      title: lang.label_end_date,
      dataIndex: 'end_date',
      key: 'end_date'
    },
    {
      title: lang.label_rebate_cycle,
      dataIndex: 'rebate_cycle',
      key: 'rebate_cycle'
    },
    {
      title: lang.label_category_title,
      dataIndex: 'category_title',
      key: 'category_title'
    },
    {
      title: lang.label_max_rebate,
      dataIndex: 'max_rebate',
      key: 'max_rebate'
    },
    {
      title: lang.label_conditional_multiplayer,
      dataIndex: 'conditional_multiplayer',
      key: 'conditional_multiplayer'
    },
    {
      title: lang.label_timezone,
      dataIndex: 'timezone',
      key: 'timezone'
    },

    {
      title: lang.label_status,
      dataIndex: 'status',
      key: 'status',
      render: (status) => <span style={{ color: status === 'Active' ? 'green' : 'red' }}>{status}</span>
    }
  ];

  const columns2 = [
    {
      title: lang.label_game_provider,
      dataIndex: 'game_provider',
      key: 'game_provider'
    },
    {
      title: lang.label_valid_bet,
      dataIndex: 'valid_bet',
      key: 'valid_bet'
    },
    {
      title: lang.label_rebate_recommended,
      dataIndex: 'rebate_recommended',
      key: 'rebate_recommended'
    },
    {
      title: lang.label_maxrebate,
      dataIndex: 'max_rebate',
      key: 'max_rebate'
    }
  ];

  const extractDate = (dateTime) => {
    return new Date(dateTime).toISOString()?.split('T')[0];
  };

  const getGameProviderName = async (id, searchId) => {
    try {
      const path = `/game/provider/${id}`;
      const res = await GetApi.sendApiRequest(path, {}, {});
      if (Array.isArray(res) && res.length > 0) {
        const provider = res.find((provider) => provider.game_platform_id === searchId);
        if (provider) {
          return provider.name;
        } else {
          return '-';
        }
      } else {
        return '-';
      }
    } catch (error) {
      return '-';
    }
  };

  const getDetails = async () => {
    setLoading(true);
    try {
      const path = `/admin/rebate/view/${popupID}`;
      const res = await GetApi.sendApiRequest(path, {}, {});

      if (res) {
        const newTableList1 = {
          key: res.id.toString(),
          rebate_title: res.title,
          description: res.description,
          start_date: extractDate(res.start_date),
          end_date: extractDate(res.end_date),
          rebate_cycle: res.rebate_cycle,
          category_title: res.category_name,
          max_rebate: convertToTwoDecimalPlaces(res.max_group_rebate),
          conditional_multiplayer: convertToTwoDecimalPlaces(res.conditional_multiplier),
          timezone: res.timezone_name ? res.timezone_name : '-',
          status: res.status === 1 ? 'Active' : 'Inactive'
        };

        setTableList1([newTableList1]);

        const newTableList2 = await Promise.all(
          res.rebate_conditions.map(async (condition) => {
            const gameProviderName = await getGameProviderName(res.game_category_id, condition.game_provider_id);
            return {
              key: condition.id.toString(),
              game_provider: gameProviderName,
              valid_bet: convertToTwoDecimalPlaces(condition.valid_bet),
              rebate_recommended: convertToTwoDecimalPlaces(condition.rebate_percentage),
              max_rebate: convertToTwoDecimalPlaces(condition.max_rebate)
            };
          })
        );

        setTableList2(newTableList2);
      }
    } catch (error) {
      console.error('Error fetching rebate details:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (popupID && isDetailPopUp) {
      getDetails();
    }
  }, [popupID, isDetailPopUp]);

  return (
    <Modal
      width="fit-content"
      centered
      title="Rebate Details"
      open={isDetailPopUp}
      onCancel={handleCancelDetail}
      afterClose={() => {
        setTableList1([]);
        setTableList2([]);
      }}
      footer={false}>
      <Flex
        style={{
          flexDirection: 'column',
          marginTop: '20px',
          gap: '5px'
        }}>
        <Table
          style={{
            border: '1px solid #E7E9F1',
            borderRadius: '5px'
          }}
          columns={columns1}
          loading={loading}
          dataSource={tableList1}
          bordered
          pagination={false}
          rowKey={'key'}
        />
        <br />
        <Table
          style={{
            border: '1px solid #E7E9F1',
            borderRadius: '5px'
          }}
          columns={columns2}
          loading={loading}
          dataSource={tableList2}
          bordered
          pagination={false}
          rowKey={'key'}
        />
      </Flex>
    </Modal>
  );
}
