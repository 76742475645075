import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Table, Modal, Button, Dropdown, Tag, Menu, Space, Tabs, message, Input, Checkbox, Select } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PlayerChangeStatus from '../../../components/forms/PlayerChangeStatus';
import { IoMenu } from 'react-icons/io5';
import ChangeAgent from './ChangeAgent';
import AddPlayerNotification from '../PlayerNotification/AddPlayerNotification';
import { toFixedFunc } from '../../../helps/HelperFunctions';
import lockRed from '../../../../src/images/LockRed.png';
import Loader from '../../../components/HelperComponents/Loader';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import PutApi from '../../../helps/contexts/Api/PutApi';
import TextArea from 'antd/es/input/TextArea';
import GetApi from '../../../helps/contexts/Api/GetApi';
const PlayerListTable = ({ data, loading, getPlayers }) => {
  // console.log(data)
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const navigate = useNavigate();
  const { hasPermission } = useCheckPermission();   

  //states
  const [rowData, setRowData] = useState({});
  const [status, setStatus] = useState(false);
  const [changeAgent, setChangeAgent] = useState(false);
  const [sendmessage, setsendmsg] = useState(false);
  const [showBalanceInput, setShowBalanceInput] = useState(false);
  const [balanceRemark, setBalanceRemark] = useState('');
  const [amount, setAmount] = useState('');
  const [tabIndex, setTabIndex] = useState('1');
  const [promotion, setPromotion] = useState(false);
  const [transactionData, setTransactionData] = useState({
    // transaction_id: '',
    game_type: '',
    turnover_multiplier: ''
  });
  const [updateBalanceLoading, setUpdateBalanceLoading] = useState(false);
  const [playerId, setPlayerId] = useState(null);
  const [playerCurrency, setPlayerCurrency] = useState('');
  const [currentBalance, setCurrentBalance] = useState();
  const [gameTypeList, setGameTypeList] = useState(null);

  // hover effect
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleBalanceModalCancel = (e) => {
    setShowBalanceInput(false);
    setPromotion(false);
    setTransactionData({
      // transaction_id: '',
      game_type: '',
      turnover_multiplier: ''
    });
    setTabIndex('1');
    setBalanceRemark('');
  };

  //fetching API for game type
  const fetchGameTypeDetails = async () => {
    try {
      const path = `/game_items/types`;
      // setLoading(true);
      //sending api request
      const res = await GetApi.sendApiRequest(path);
      if (res) {
        const formattedList = Object.entries(res).map(([key, value]) => ({
          value: Number(key),
          label: value
        }));
        setGameTypeList(formattedList);
      }
    } catch (e) {}
  };

  //updating balance
  const updateBalance = async () => {
    setUpdateBalanceLoading(true);
    const path = `/admin/player/${playerId}/balance/adjust`;
    try {
      const res = await PutApi.PutApiRequest(path, {
        amount: amount,
        remark: balanceRemark,
        is_turnover_check: promotion,
        is_withdrawal: tabIndex === '1' ? false : true,
        // transaction_id: transactionData?.transaction_id?.length > 0 ? transactionData?.transaction_id : null,
        game_type: transactionData?.game_type ? transactionData?.game_type : null,
        turnover_multiplier:
          transactionData?.turnover_multiplier?.length > 0 ? transactionData?.turnover_multiplier : null
      });

      if (res && res?.status === true) {
        message.success(lang.label_sucessupdatebalance);

        setPromotion(false);
        setTransactionData({
          // transaction_id: '',
          game_type: '',
          turnover_multiplier: ''
        });
        setTabIndex('1');
        setBalanceRemark('');

        handleBalanceModalCancel();
        getPlayers();
      } else {
        message.error(lang.label_updatebalanceerror);
      }
    } catch (error) {
      console.error('Failed to update balance:', error);
      message.error(lang.label_updatebalanceerror);
    } finally {
      setUpdateBalanceLoading(false);
    }
  };

  const onChangeGameType = (value) => {
    setTransactionData({ ...transactionData, game_type: value });
  };

  const items = [
    {
      key: '1',
      label: 'Add Points',
      children: (
        <div>
          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_points} ({playerCurrency})
          </div>
          <Input
            style={{
              marginBottom: '10px'
            }}
            value={amount}
            // defaultValue={Number(balance).toFixed(2)}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            type="number"
          />
          <Checkbox
            style={{
              fontSize: '12px',
              marginBottom: '10px'
            }}
            checked={promotion}
            onChange={(e) => setPromotion(e.target.checked)}>
            {lang.label_promotion}
          </Checkbox>

          {promotion ? (
            <div
              style={{
                padding: '10px',
                backgroundColor: 'rgb(238, 244, 248)',
                marginBottom: '10px'
              }}>
              <div
                style={{
                  fontSize: '12px',
                  marginBottom: '6px'
                }}>
                {lang.label_turnover_multiplier}
              </div>
              <Input
                style={{
                  marginBottom: '10px'
                }}
                value={transactionData?.turnover_multiplier}
                onChange={(e) => {
                  setTransactionData({
                    ...transactionData,
                    turnover_multiplier: e.target.value
                  });
                }}
                type="number"
              />
              <div
                style={{
                  fontSize: '12px',
                  marginBottom: '6px'
                }}>
                {lang.label_game_type}
              </div>
              {/* <Input
                style={{
                  marginBottom: '10px'
                }}
                value={transactionData?.transaction_id}
                onChange={(e) => {
                  setTransactionData({
                    ...transactionData,
                    transaction_id: e.target.value
                  });
                }}
                type="number"
              /> */}
              <Select
                required
                onChange={onChangeGameType}
                placeholder="Select Game type"
                value={(transactionData && transactionData?.game_type) || undefined}
                style={{
                  width: '100%',
                  borderRadius: '3px'
                }}
                options={gameTypeList}
              />
            </div>
          ) : null}

          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_remark}
          </div>
          <TextArea
            value={balanceRemark}
            style={{ width: '100%', marginBottom: '2rem' }}
            onChange={(e) => {
              setBalanceRemark(e.target.value);
            }}
          />
        </div>
      )
    },
    {
      key: '2',
      label: 'Remove Points',
      children: (
        <div>
          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_points} ({playerCurrency})
          </div>
          <Input
            style={{
              marginBottom: '10px'
            }}
            value={amount}
            // defaultValue={Number(balance).toFixed(2)}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            type="number"
          />
          {/* <Checkbox
            style={{
              fontSize: '12px',
              marginBottom: '10px'
            }}
            checked={promotion}
            onChange={(e) => {
              setPromotion(e.target.checked);
              setTransactionData({
                transaction_id: '',
                turnover_multiplier: ''
              });
            }}>
            {lang.label_promotion}
          </Checkbox>

          {promotion ? (
            <div
              style={{
                padding: '10px',
                backgroundColor: 'rgb(238, 244, 248)',
                marginBottom: '10px'
              }}>
              <div
                style={{
                  fontSize: '12px',
                  marginBottom: '6px'
                }}>
                {lang.label_transactionid}
              </div>
              <Input
                style={{
                  marginBottom: '10px'
                }}
                value={transactionData?.transaction_id}
                onChange={(e) => {
                  setTransactionData({
                    ...transactionData,
                    transaction_id: e.target.value
                  });
                }}
                type="number"
              />
              <div
                style={{
                  fontSize: '12px',
                  marginBottom: '6px'
                }}>
                {lang.label_turnover_multiplier}
              </div>
              <Input
                style={{
                  marginBottom: '10px'
                }}
                value={transactionData?.turnover_multiplier}
                onChange={(e) => {
                  setTransactionData({
                    ...transactionData,
                    turnover_multiplier: e.target.value
                  });
                }}
                type="number"
              />
            </div>
          ) : null} */}

          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_remark}
          </div>
          <TextArea
            value={balanceRemark}
            style={{ width: '100%', marginBottom: '2rem' }}
            onChange={(e) => {
              setBalanceRemark(e.target.value);
            }}
          />
        </div>
      )
    }
  ];

  const onChange = (key) => {
    setTabIndex(key);
    setPromotion(false);
    setTransactionData({
      // transaction_id: '',
      game_type: '',
      turnover_multiplier: ''
    });
    setBalanceRemark('');
  };

  // Edit player popup
  const handleCancel = () => {
    setStatus(false);
    setChangeAgent(false);
    setsendmsg(false);
  };
  //for onclick of options in table

  const handleChangeAgent = () => {
    setChangeAgent(true);
    setsendmsg(false);
  };
  const handleSendMsg = () => {
    setChangeAgent(false);
    setsendmsg(true);
  };

  const handleviewPlayer = (record) => {
    navigate(`/player-management/player/${record.id}/view`);
  };

  const handleaddremoveBalance = (record) => {
    fetchGameTypeDetails();
    setCurrentBalance(record?.balance);
    setPlayerId(record?.id);
    setPlayerCurrency(record?.currency);
    setShowBalanceInput(true);
  };

  //menu items in table

  const columns = [
    {
      title: lang.label_name,
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: lang.label_username,
      dataIndex: 'username',
      key: 'username',
      render: (_, record) => {
        return (
          <Link
            style={{ color: '#1677ff' }}
            state={{ name: record.username }}
            to={{
              pathname: `/player-management/player/${record.id}/view`
            }}>
            {record.username}
          </Link>
        );
      }
    },
    // for online
    {
      title: lang.label_last_seen,
      dataIndex: 'lastSeen',
      key: 'lastSeen',
      render: (_, record) => {
        return (
          <Space direction="horizontal" style={{ width: '100%' }}>
            <Tag
              style={{
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center',
                color : record?.lastSeen  === "Online" ? '#478d47' : 'black', 
                background : record?.lastSeen  === "Online"  ? 'rgba(201, 245, 201, 0.25) 0% 0% no-repeat padding-box' : '' 
              }}
            >
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: record?.lastSeen  === "Online"  ? 'green' : 'grey',
                  marginRight: '8px'
                }}
              ></div>
              {record?.lastSeen ? record?.lastSeen : 'Not Available'}
            </Tag>
          </Space>
        );
      }
    },
    

    {
      title: lang.label_phone_verified,
      dataIndex: 'phone_verified',
      key: 'phone_verified',
      render: (_, record) => {
        // console.log("record?.phone_verified", record?.phone_verified);
        return (
          <Space direction="horizontal" style={{ width: '100%' }}>
            <Tag
              style={{
                borderRadius: '12px'
              }}
              color={record?.phone_verified ? 'success' : 'error'}>
              {record?.phone_verified ? `Verified` : 'Not Verified'}
            </Tag>
          </Space>
        );
      }
    },

    {
      title: lang.label_phone,
      dataIndex: 'phone',
      key: 'phone',
      render: (row, record) => {
        return (
          <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
            {row || '-'}
          </Space>
        );
      }
    },

    {
      title: lang.label_agent,
      dataIndex: 'agent',
      key: 'agent',
      render: (row, record) => {
        return (
          <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
            {row || '-'}
          </Space>
        );
      }
    },
    // {
    //   title: lang.label_baseBalance,
    //   dataIndex: "baseBalance",
    //   key: "baseBalance",
    //   render: (number) => {

    //     return <span>{addCommasToNumber(Number(number))} USD</span>
    //   }
    // },
    {
      title: lang.label_balance,
      dataIndex: ['balance', 'playerCurrency', 'baseBalance', 'locked_balance', 'locked_base_balance'],
      key: 'balance',
      render: (number, row) => {
        return (
          <span style={{ color: '#207620' }} title={row['baseBalance']}>
            <span title="Balance">
              <span>
                {toFixedFunc(row['balance'], process.env.REACT_APP_Decimel_Points)} ({row['playerCurrency']})
              </span>
              <br />
              <span
                style={{
                  color: 'grey',
                  fontSize: '12px'
                }}
                title="Base Balance">
                {toFixedFunc(row['baseBalance'], process.env.REACT_APP_Decimel_Points)} (USD)
              </span>
              <br />
              {Number(row['locked_balance']) ? (
                <div
                  style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center'
                  }}>
                  <span
                    style={{
                      color: 'red',
                      fontSize: '12px'
                    }}
                    title="Freeze Balance">
                    {toFixedFunc(row['locked_balance'], process.env.REACT_APP_Decimel_Points)} ({row['playerCurrency']})
                  </span>
                  <img
                    src={lockRed}
                    alt="Lock_icon"
                    style={{
                      height: '10px',
                      width: '10px'
                    }}
                  />
                </div>
              ) : (
                <></>
              )}

              {Number(row['locked_base_balance']) ? (
                <div
                  style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center'
                  }}>
                  <span
                    style={{
                      color: 'red',
                      fontSize: '12px'
                    }}
                    title="Freeze Balance">
                    {toFixedFunc(row['locked_base_balance'], process.env.REACT_APP_Decimel_Points)} (USD)
                  </span>
                  <img
                    src={lockRed}
                    alt="Lock_icon"
                    style={{
                      height: '10px',
                      width: '10px'
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </span>
          </span>
        );
      }
    },

    {
      title: `${lang.label_registerip}/${lang.label_registertime}`,
      dataIndex: ['registerip', 'registertime'],
      key: 'registerip',
      render: (time, row) => (
        <span style={{ color: '#207620' }} title={row['registertime']}>
          <span title={row['registertime']}>
            {row['registerip']}
            <br />
            <span
              style={{
                color: 'grey',
                fontSize: '12px'
              }}>
              {row?.['registertime']}
            </span>
          </span>
        </span>
      )
    },
    {
      title: `${lang.label_loginip}/${lang.label_logintime}`,
      dataIndex: ['loginip', 'logintime'],
      key: 'loginip',
      render: (time, row) => (
        <>
          {row?.created_by_admin ? (
            '-'
          ) : (
            <span style={{ color: '#207620' }} title={row['logintime']}>
              {row['loginip']}
              <br />
              <span
                style={{
                  color: 'grey',
                  fontSize: '12px'
                }}>
                {row['logintime']}
              </span>
            </span>
          )}
        </>
      )
    },
    // onClick={() => setStatus(true)}
    {
      title: lang.label_status,
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => (
        <Button style={{ background: 'transparent', border: 'none' }}>
          {record.status === 1 ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              {lang.label_active}
            </Tag>
          ) : record.status === 0 ? (
            <Tag icon={<CloseCircleOutlined />} color="error">
              {lang.label_inactive}
            </Tag>
          ) : (
            ''
          )}
        </Button>
      )
    },
    {
      title: lang.label_options,
      key: 'action',
      dataIndex: 'actions',
      render: (_, record) => {
        const onClick = (record, key) => {
          switch (key) {
            case '1':
              handleChangeAgent();
              break;
            case '2':
              handleSendMsg();
              break;
            case '3':
              handleviewPlayer(record);
              break;
            case '4':
              handleaddremoveBalance(record);
              // console.log("recordss : ",record)
              break;
            default:
              break;
          }
        };

        const menu = (
          <Menu onClick={({ key }) => onClick(record, key)}>
            {/* <Menu.Item key="1">{lang.label_changeagent}</Menu.Item> */}
            {/* <Menu.Item key="2">{lang.label_sendmessage}</Menu.Item> */}
            <Menu.Item key="3">{lang.label_viewplayer}</Menu.Item>
            <Menu.Item key="4">{lang.label_addremovepoints}</Menu.Item>
          </Menu>
        );

        return (
          <Dropdown placement="bottom" overlay={menu}>
            <a href="#" style={{ color: '#004a7f' }} onClick={(e) => e.preventDefault()}>
              <IoMenu />
            </a>
          </Dropdown>
        );
      }
    }
  ];

  return (
    <>
      <Table
        size="small"
        columns={columns}
        bordered
        dataSource={data}
        style={{ width: '100%', marginTop: '30px', color: 'black' }}
        pagination={false}
        loading={loading}
        onRow={(record) => {
          return {
            onClick: () => {
              setRowData(record);
            }
          };
        }}
      />

      <Modal
        title={lang.label_changestatus}
        open={status}
        onOk={() => {
          setStatus(true);
        }}
        onCancel={handleCancel}
        footer={null}
        width={250}
        closable={true}>
        <PlayerChangeStatus rowdata={rowData} onCancel={handleCancel} getPlayers={getPlayers} />
      </Modal>

      {changeAgent && (
        <Modal
          title={lang.label_changeagent}
          open={changeAgent}
          onOk={() => {
            setChangeAgent(true);
          }}
          onCancel={handleCancel}
          footer={null}
          width={500}
          closable={true}>
          <ChangeAgent rowdata={rowData} onCancel={handleCancel} getPlayers={getPlayers} />
        </Modal>
      )}

      <Modal
        title="Add Message"
        open={sendmessage}
        onOk={() => {
          setsendmsg(true);
        }}
        onCancel={handleCancel}
        footer={null}
        width={400}
        closable={true}>
        <AddPlayerNotification rowdata={rowData} onCancel={handleCancel} getPlayers={getPlayers} />
      </Modal>

      {showBalanceInput && (
        <Modal
          title={lang.label_updatebalance}
          open={showBalanceInput}
          onOk={() => setShowBalanceInput(true)}
          onCancel={handleBalanceModalCancel}
          footer={null}
          width={500}>
          <div
            style={{
              fontSize: '14.4px',
              position: 'relative',
              left: '300px',
              width: '200px'
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div
              style={{
                color: isHovered ? '#1473E6' : 'black'
              }}>
              {`Balance : ${toFixedFunc(currentBalance, process.env.REACT_APP_Decimel_Points)} (${playerCurrency})`}
            </div>
          </div>

          <Tabs defaultActiveKey={tabIndex} items={items} onChange={onChange} centered />

          {hasPermission('Change Player Balance') ? (
            <>
              {updateBalanceLoading ? (
                <Loader />
              ) : (
                <Button
                  disabled={amount?.length > 0 ? false : true}
                  onClick={updateBalance}
                  style={{
                    float: 'right',
                    backgroundColor: '#004A7F',
                    color: 'white',
                    marginTop: '-1.2rem'
                  }}>
                  {lang.label_submit}
                </Button>
              )}
            </>
          ) : (
            ''
          )}
        </Modal>
      )}
    </>
  );
};

export default PlayerListTable;
