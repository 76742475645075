import { Button, Card, Col, Modal, Row, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import GetApi from '../../../helps/contexts/Api/GetApi';
import LangContext from '../../../helps/contexts/lang-context';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import PostApi from '../../../helps/contexts/Api/PostApi';
import BankCodesTable from './BankCodesTable';
import AddPaymentCategoryPopup from '../PaymentCategories/AddPaymentCategoryPopup';
import AddBankCodes from './AddBankCodes';
import { TabContext } from '../../../helps/contexts/TabContext';

const BankCodes = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState('');
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({ status: 1, display_for_players: 0 });
  const [formLoading, setFormLoading] = useState(false);
  const { hasPermission } = useCheckPermission();
  const [paymentCategories, setPaymentCategory] = useState([]);
  const [bankCodes, setBankCodes] = useState([]);
  const { path } = useContext(TabContext);
  console.log(path);

  const nav = useNavigate();
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const onFilterSubmit = async () => {};

  useEffect(() => {
    if (path === '/transaction-management/bank-codes') {
      setLoading(true);
      getBanks();
      getPaymentCategories();
      getBankCodes();
      setLoading(false);
    }
  }, [path]);

  const getBanks = async () => {
    try {
      const path = '/bank/code';
      const params = {};
      if (inputSearchValue) params['search'] = inputSearchValue;
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.length) {
        setCategories(res);
        setFilteredCategories(res);
      } else {
        setCategories([]);
      }
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };

  const getPaymentCategories = async () => {
    try {
      const res = await GetApi.sendApiRequest('/payment/category/drop/down');
      if (res) {
        const object1 = {
          key: 'Please Select currency',
          value: 'null',
          label: 'Please Select Category'
        };
        const finalArray = res.map((item, index) => {
          return {
            key: Number(item.id),
            value: Number(item.id),
            label: item.name
          };
        });
        finalArray.unshift(object1);
        setPaymentCategory(finalArray);
      } else {
        return null;
      }
    } catch (e) {}
  };

  const getBankCodes = async () => {
    try {
      const res = await GetApi.sendApiRequest('/bank/code/constants');
      if (res) {
        const object1 = {
          key: 'Please Select currency',
          value: 'null',
          label: 'Please Select Category'
        };
        const resObject = Object.keys(res);
        const finalArray = resObject?.map((item, index) => {
          return {
            key: Number(item),
            value: Number(item),
            label: res[item]
          };
        });
        finalArray.unshift(object1);
        setBankCodes(finalArray);
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setLoading(true);
    getBanks();
    getPaymentCategories();
    getBankCodes();
    setLoading(false);
  }, []);

  const onEditClicked = (row) => {
    setShowEditPopup(true);
    setEditMode(true);
    setFormData(row);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (editMode) {
      handleUpdate(e);
    } else {
      try {
        setFormLoading(true);
        console.log(formData);
        const res = await PostApi.PostApiRequest('/bank/code/store', formData);
        if (res?.status === 200) {
          message.success(lang.label_add_payment_category_success);
          setShowEditPopup(false);
          getBanks();
          setFormData({});
        }
      } catch (e) {
        console.log(e);
        if (e?.status == 422) {
          const errorArr = e?.data?.message;
          if (errorArr) {
            Object.keys(errorArr).map((item) => {
              message.error(errorArr[item][0]);
            });
          }
        }
      }
      setFormLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setFormLoading(true);
      const res = await PostApi.PostApiRequest(`/bank/code/${formData.id}/update`, formData);
      if (res?.status === 200) {
        message.success(lang.label_add_payment_category_success);
        setShowEditPopup(false);
        getBanks();
        setFormData({});
      }
    } catch (e) {
      console.log(e);
      if (e?.status == 422) {
        const errorArr = e?.data?.message;
        if (errorArr) {
          Object.keys(errorArr).map((item) => {
            message.error(errorArr[item][0]);
          });
        }
      }
    }
    setFormLoading(false);
  };

  return (
    <>
      <HeaderCardDetails />
      <div style={{ padding: '20px' }}>
        <Card
          title="Filters"
          extra={
            <>
              {hasPermission('Create Bank Code') && (
                <Button
                  style={{
                    backgroundColor: '#004A7F',
                    color: 'white',
                    width: '125px',
                    height: '35px',
                    background: '#004A7F 0% 0% no-repeat padding-box',
                    borderRadius: '3px',
                    opacity: '1'
                  }}
                  onClick={() => {
                    setFormData({});
                    setShowEditPopup(true);
                  }}
                  // style={{ left: '20px' }}
                  className="btnStyle">
                  {lang.label_create}
                </Button>
              )}
            </>
          }
          style={{
            borderRadius: '3px'
          }}>
          {/* <Row>
            <Col span={18}></Col>
            <Col span={6}>
              {hasPermission('Create Bank Code') && (
                <Button
                  onClick={() => {
                    setFormData({});
                    setShowEditPopup(true);
                  }}
                  style={{ left: '20px' }}
                  className="btnStyle">
                  {lang.label_create}
                </Button>
              )}
            </Col>
          </Row> */}
          <form onSubmit={onFilterSubmit}></form>
        </Card>
        <br />
        <BankCodesTable
          tableData={categories}
          setTableData={setCategories}
          loading={loading}
          onEditClicked={onEditClicked}
        />
        <Modal
          width={1000}
          open={showEditPopup}
          title={lang.label_create_bank_code}
          onCancel={() => {
            setShowEditPopup(false);
            setEditMode(false);
          }}
          footer={null}
          // maskClosable={false}
          // keyboard={false}
          // okText={lang.label_submit}
          // cancelText={lang.label_cancel}
          // onOk={handleFormSubmit}
          // confirmLoading={formLoading}
          >
          <AddBankCodes
            handleFormSubmit={handleFormSubmit}
            formLoading={formLoading}
            setShowEditPopup={setShowEditPopup}
            setEditMode={setEditMode}
            formData={formData}
            setFormData={setFormData}
            paymentCategories={paymentCategories}
            bankCodes={bankCodes}
          />
        </Modal>
      </div>
    </>
  );
};

export default BankCodes;
