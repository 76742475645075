import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LangContext from '../../../helps/contexts/lang-context';
import { TabContext } from '../../../helps/contexts/TabContext';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import { Modal, Table } from 'antd';
import PaginationComp from '../../../components/HelperComponents/PaginationComp';
import GetApi from '../../../helps/contexts/Api/GetApi';
import Spacer from '../../../components/HelperComponents/Spacer';
import TagManagementFilters from './TagManagementFilters';
import { BsPencil } from 'react-icons/bs';
import { GoPlus } from 'react-icons/go';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Button } from 'antd';
import NewPlayer from './addNewPlayer/NewPlayer';
import AddNewTag from './AddNewTag';
import DeleteTag from './DeleteTag';
import { convertLocalTimeToUTC, convertUTCToLocalTime } from '../../../helps/HelperFunctions';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';

export default function TagManagement() {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const Navigate = useNavigate();
  const { hasPermission } = useCheckPermission();
  const [loading, setLoading] = useState(false);
  const [tagsList, setTagsList] = useState([]);

  const [pageSize, setPageSize] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [tagName, setTagName] = useState('');
  const [filters, setFilters] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [tagID, setTagID] = useState(null);

  const { path } = useContext(TabContext);

  const showModal = (val) => {
    setVisible(true);
    setTagName(val);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const getTags = async ({ page, filter }) => {
    setLoading(true);
    try {
      const path = '/admin/tags';
      const finalData = filter
        ? {
            [filter?.key]: filter?.search,
            start_date: filter?.start_date,
            end_date: filter?.end_date
          }
        : null;

      const params = { ...finalData, page: page };
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.status && Array.isArray(res?.tags.data)) {
        console.log('tag-api-response : ', res);
        setLoading(false);
        setTagsList(res?.tags?.data);
        setTotalLength(res?.tags?.total);
        setPageSize(res?.tags?.per_page);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
    setLoading(false);
  };

  const onRefresh = () => {
    setFilters(null);
    getTags({ page: currentPage });
  };

  useEffect(() => {
    getTags({ page: currentPage, filter: filters ?? '' });
  }, [filters, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    getTags({ page: 1, filter: filters ?? '' });
  }, [filters]);

  const onChangePage = (page) => {
    setCurrentPage(page);
    // setIsPageChange(true);
  };

  const paginationConfig = {
    pageSize: pageSize ? pageSize : 0,
    total: totalLength ? totalLength : 0,
    showSizeChanger: false,
    showQuickJumper: false,
    current: currentPage,
    showTotal: (total) => `Total ${total} items`,
    onChange: onChangePage,
    itemRender: (current, type, originalElement) => {
      if (type === 'prev') {
        return <span className="pagination-item">Previous</span>;
      }
      if (type === 'next') {
        return <span className="pagination-item">Next</span>;
      }
      return originalElement;
    }
  };

  const columns = [
    {
      title: lang.label_tag_name,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left'
    },
    {
      title: lang.label_description,
      dataIndex: 'description',
      key: 'description',
      fixed: 'left'
    },
    {
      title: lang.label_total_players,
      dataIndex: 'total_players',
      key: 'total_players',
      render: (text, record) => {
        if (record.total_players > 0) {
          return (
            <span
              onClick={() =>
                Navigate('/player-management/player-list', { state: { agent_id: record.id, name: record.name } })
              }
              style={{ color: 'blue', cursor: 'pointer' }}>
              {text ?? '-'}
            </span>
          );
        } else {
          return <span>{text ?? '-'}</span>;
        }
      }
    },
    {
      title: lang.label_domain_name,
      dataIndex: 'domain',
      key: 'domain',
      render: (text, record) => {
        return <>{text ?? `-`}</>;
      }
    },
    {
      title: lang.label_updated_by,
      dataIndex: 'updated_by',
      key: 'updated_by',
      render: (text, record) => {
        return <>{text?.user?.user_name ?? `-`}</>;
      }
    },

    {
      title: lang.label_updated_at,
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (text, record) => {
        return <>{text ? convertUTCToLocalTime(text) : `-`}</>;
      }
    },
    {
      title: lang.label_remark,
      dataIndex: 'remark',
      key: 'remark',
      render: (text, record) => {
        return <>{text ?? `-`}</>;
      }
    }
  ];

  if (hasPermission('Create Tags') || hasPermission('Create Tag From Players')) {
    columns.push({
      title: lang.label_actions,
      key: 'actions',
      dataIndex: 'actions',
      fixed: 'right',
      render: (_, record) => {
        const isGeneralType = record.type === 'general';
        return (
          <div
            style={{
              display: 'flex',
              width: 'fit-content',
              gap: '4px',
              alignItems: 'center'
            }}>
            {hasPermission('Create Tags') && (
              <BsPencil
                style={{ cursor: isGeneralType ? 'pointer' : 'not-allowed', opacity: isGeneralType ? 1 : 0.5 }}
                color="black"
                size={16}
                onClick={() => {
                  if (isGeneralType) {
                    setCurrentRecord(record);
                    setOpenEditModal(true);
                  }
                }}
              />
            )}
            {hasPermission('Create Tags') && (
              <RiDeleteBinLine
                style={{ cursor: isGeneralType ? 'pointer' : 'not-allowed', opacity: isGeneralType ? 1 : 0.5 }}
                color="red"
                size={16}
                onClick={() => {
                  if (isGeneralType) {
                    setCurrentRecord(record);
                    setOpenDeleteModal(true);
                  }
                }}
              />
            )}
            {hasPermission('Create Tag From Players') && (
              <GoPlus
                onClick={() => {
                  showModal(record.name);
                  setTagID(record.id);
                }}
                style={{ cursor: 'pointer' }}
                color="black"
                size={25}
              />
            )}
          </div>
        );
      }
    });
  }
  const handleCloseModalEdit = () => {
    setOpenEditModal(!openEditModal);
    setCurrentRecord(null);
    onRefresh();
  };
  const handleCloseModalDelete = () => {
    setOpenDeleteModal(!openDeleteModal);
    setCurrentRecord(null);
    onRefresh();
  };

  return (
    <div>
      <HeaderCardDetails />
      <div style={{ padding: '20px' }}>
        <TagManagementFilters onRefresh={onRefresh} setFilters={setFilters} open={handleCloseModalEdit} />
        <br />
        <Table
          scroll={{
            x: 'max-content'
          }}
          columns={columns}
          loading={loading}
          dataSource={tagsList}
          bordered
          pagination={false}
          rowKey={'id'}
        />
        {tagsList?.length > 0 && <PaginationComp paginationConfig={paginationConfig} />}
        <Spacer />
        <Spacer />
        <div>
          <Modal
            centered
            title={`${currentRecord ? 'Update Tag' : 'New Tag'}`}
            open={openEditModal}
            onCancel={handleCloseModalEdit}
            footer={null}
            width={741}
            closable={false}>
            <AddNewTag record={currentRecord} onClose={handleCloseModalEdit} />
          </Modal>
        </div>
        <div>
          <Modal
            centered
            // title="Edit Tag"
            open={openDeleteModal}
            onCancel={handleCloseModalDelete}
            footer={null}
            width={600}
            closable={false}>
            <DeleteTag record={currentRecord} onClose={handleCloseModalDelete} />
          </Modal>

          <Modal
            afterClose={() => setTagID(null)}
            centered
            title={`New Player (${tagName})`}
            open={visible}
            onCancel={handleCancel}
            footer={null}
            width={500}
            closable={false}>
            <NewPlayer ID={tagID} onRefresh={onRefresh} onCancel={handleCancel} />
          </Modal>
        </div>
      </div>
    </div>
  );
}
