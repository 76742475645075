import React, { useContext, useState } from 'react';
import { Table, Modal, Button } from 'antd';
import LangContext from '../../../../helps/contexts/lang-context';

const ViewEditCommisionHistory = ({rowData, onCancel}) => {

    const ctx = useContext(LangContext);
    const lang = ctx.lang;

    const columns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
        },
        {
          title: 'Admin User',
          dataIndex: 'adminUser',
          key: 'adminUser',
        },
        {
          title: 'Original Value',
          dataIndex: 'originalValue',
          key: 'originalValue',
          render: (text) => (
            <span
              style={{
                color: text && text.startsWith('-') ? 'red' : '#2A93F0',
              }}
            >
              {text}
            </span>
          ),
        },
        {
          title: 'Modified Value',
          dataIndex: 'modifiedValue',
          key: 'modifiedValue',
          render: (text) => {
            const color = text && text.startsWith('-') ? 'red' : '#2A93F0';
      
            return (
              <span
                style={{
                  color: color,
                  borderBottom: `2px solid ${color}`,
                  paddingBottom: '1px',
                }}
              >
                {text}
              </span>
            );
          },
        },
        {
          title: 'Remark',
          dataIndex: 'remark',
          key: 'remark',
        },
      ];
      
      const dataSource = [
        {
            key: '1',
            date: rowData?.reviewAt,
            adminUser: rowData?.reviewBy,
            originalValue: rowData?.grossCommission,
            modifiedValue: rowData?.actualCommission,
            remark: rowData?.remark,
          },
    ]

  return (
  <div
  style={{
    width: '97%',
    height: '180px',
    borderRadius: '3px',
    justifyContent: 'space-evenly'
  }}
  >
     <Table 
     style={{
        marginTop : '20px',
        width : '100%',
        // display : 'flex',
        // alignItems : 'center',
        // justifyContent : 'center',
        overflowX: 'auto', overflowY: 'auto'
    }}
     dataSource={dataSource} columns={columns} pagination={false} />
     <div style={{
            marginTop : '20px',
            display : 'flex',
            alignItems : 'center',
            justifyContent : 'center'
        }}>
          <Button
            style={{
                borderRadius: '3px',
                backgroundColor: '#004A7F',
                color: 'white',
                // position: 'absolute',
                // right: '45%',
                // top: '80%'
            }}
            // style={{
            //     borderRadius: '3px',
            //     color: '#004A7F',
            //     position: 'absolute',
            //     right: '8%',
            //     top: '80%'
            //   }}
            onClick={onCancel}>
            Close
          </Button>
        </div>
  </div>
  );
};

export default ViewEditCommisionHistory;
