import React, { useContext, useEffect, useState } from 'react'
import {  CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import {  Pagination, Tag,Table } from 'antd';
import LangContext from '../../../../helps/contexts/lang-context';
import GetApi from '../../../../helps/contexts/Api/GetApi';
import { useNavigate } from 'react-router-dom';
import { convertUTCToLocalTime, toFixedFunc } from '../../../../helps/HelperFunctions';

const PlayerBalanceHistiryTable = ({id}) => {
  const [currentpage, setCurrentpage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [Data, setData] = useState([]);

  const nav = useNavigate()

  const ctx = useContext(LangContext);
  const lang = ctx.lang;



//  for fetch data of player balance by id
  const getPlayerBalanceHistory = async (customPage = null, id) => {
    try {
      const path = `/admin/player/balance/${id}`;
      const params = {};
      setLoading(true);

      //setting parameters to send for search results
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
     
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);  
      if (res && res.data.data && res.data.data.length) {
        const finalArray = res?.data?.data?.map((el,i) => {
          return {
            key: el?.id ? el?.id : '-',
            id: el?.player_id ? el?.player_id : '-',
            previousbalance: el?.previous_balance ? el?.previous_balance : '0',
            currency_name: el?.currency_name ? el?.currency_name : "",
            newbalance: el?.new_balance ? el?.new_balance : '0',
            amount: el?.amount ? el?.amount : '0',
            type:
              el?.type_name ? (
                <Tag color="processing">{el?.type_name}</Tag>
              ) : (
                <>-</>
              ),
            is_turnover_check: el?.is_turnover_check ? el?.is_turnover_check : '-',
            turnover_multiplier: el?.turnover_multiplier ? parseFloat(el?.turnover_multiplier).toFixed(2) : '-',
            actionby: el?.action_by ? el?.action_by.user_name : '-',
            transactionid: el?.transaction_id ? el?.transaction_id : '-',
            remark: el?.remark ? el?.remark : '',
            gametransactionid: el?.game_transaction_history_id ? el?.game_transaction_history_id : "-",
            status: (
              <Tag
                color={
                  el?.status == 1 ? 'success' : el?.status == 2 ? 'error' : el?.status == 'pending' ? 'processing' : null
                }
                icon={
                  el?.status == 1 ? (
                    <CheckCircleOutlined />
                  ) : el?.status == 2 ? (
                    <CloseCircleOutlined />
                  ) : el?.status == 'pending' ? (
                    <SyncOutlined spin />
                  ) : null
                }>
                {el?.status === 1
                  ? lang.label_completed
                  : el?.status === 2
                  ? lang.label_failed
                  : el?.status == 'pending'
                  ? lang.label_pending
                  : null}
              </Tag>
            ),
            time: el?.created_at ? el?.created_at : '-'
          };
        });
        setData(finalArray);
        setTotal(res?.data?.total);
        setCurrentpage(res?.data?.current_page);
        setPerpage(res?.data?.per_page);
        setNextPage(res?.data?.next_page_url);
      
      } else {
        setData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };

  useEffect(() => {
    getPlayerBalanceHistory(activepage, id);
  }, []);

// columns for table
  const columns = [
    {
      title: lang.label_previousbalance,
      dataIndex: "previousbalance",
      key: "previousbalance",
      render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: lang.label_points,
      dataIndex: "amount",
      key: "amount",
      render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: lang.label_newbalance,
      dataIndex: "newbalance",
      key: "newbalance",
      render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: lang.label_currency,
      dataIndex: "currency_name",
      key: "currency_name",
      // render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: lang.label_type,
      dataIndex: "type",
      key: "type",
    },
    {
      title: lang.label_is_turnover_check,
      dataIndex: "is_turnover_check",
      key: "is_turnover_check",
    },
    {
      title: lang.label_turnover_multiplier,
      dataIndex: "turnover_multiplier",
      key: "turnover_multiplier",
    },
    {
      title: lang.label_actionby,
      dataIndex: "actionby",
      key: "actionby",
    },
    {
      title: lang.label_transactionid,
      dataIndex: "transactionid",
      key: "transactionid",
    },
    {
      title: lang.label_remark,
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: lang.label_gametransactionid,
      dataIndex: "gametransactionid",
      key: "gametransactionid",
    },
    {
      title: lang.label_status,
      dataIndex: "status",
      key: "status",
    },
    {
      title: lang.label_time,
      dataIndex: "time",
      key: "time",
      render: (time) => <span>{ convertUTCToLocalTime(time)}</span>,
    },
  ];

   // on Change for Pagination
   const onChangepage = (page) => {
    setActivepage1(page);
    getPlayerBalanceHistory(page,id);
  };


  return (
    <>
    <Table
      pagination={false}
      style={{
        width: "100%",
        marginTop: "30px",
        overflowX: "auto",
        overflowY: "scroll",
      }}
      size="small"
      columns={columns}
      bordered
      scroll={{
        x: 'max-content'
      }}
      dataSource={Data}
      loading={loading}
    />
        <Pagination
            defaultCurrent={1}
            current={activepage}
            onChange={onChangepage}
            total={total}
            responsive={true}
            pageSize={perpage}
          />
  </>
  )
}

export default PlayerBalanceHistiryTable
