import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import GetApi from '../../../../helps/contexts/Api/GetApi';
import { convertUTCToLocalTime } from '../../../../helps/HelperFunctions';
const { Title } = Typography;

const PlayerTransactionTable = () => {
  let { id } = useParams();
  const nav = useNavigate();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState({});
  const [remTurn, setRemTurn] = useState({});

  useEffect(() => {
    getPlayerStats();
  }, []);

  const getPlayerStats = async () => {
    try {
      setLoading(true);

      const path = `/admin/player/${id}/statistics`;
      const res = await GetApi.sendApiRequest(path);
      const pathRemTurn = `/admin/getTurnOverData/${id}`;
      const res1 = await GetApi.sendApiRequest(pathRemTurn);

      setLoading(false);
      if (res) {
        setData(res);
      } else {
        setData({});
      }
      if (res1) {
        setRemTurn(res1.data);
      }
    } catch (e) {
      setLoading(false);
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
      // Object.values(e.data.message).map((errors) => message.error(errors));
    }
  };

  const columns = [
    {
      dataIndex: 'column1',
      key: 'column1',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: '#EEF4F8'
            }
          },
          children: text
        };
      }
    },
    {
      dataIndex: 'column2',
      key: 'column2'
    }
  ];

  const data1 = [
    {
      key: 1,
      column1: 'Total Deposit Count',
      column2: parseFloat(data.total_approved_deposit_count || '0.00').toFixed(2)
    },
    {
      key: 2,
      column1: 'Total Deposit Amount',
      column2: parseFloat(data.total_approved_deposit_sum || '0.00').toFixed(2)
    },
    { key: 3, column1: 'Average Deposit', column2: parseFloat(data.average_deposit_amount || '0.00').toFixed(2) },
    {
      key: 4,
      column1: 'First Deposit Date Time',
      column2: data.first_deposit_date_time ? convertUTCToLocalTime(data.first_deposit_date_time) : '-'
    },
    {
      key: 5,
      column1: 'Last Deposit Date Time',
      column2: data.last_deposit_date_time ? convertUTCToLocalTime(data.last_deposit_date_time) : '-'
    }
  ];

  const data2 = [
    {
      key: 1,
      column1: 'Total Withdrawal Count',
      column2: parseFloat(data.total_approved_withdraw_count || '0.00').toFixed(2)
    },
    {
      key: 2,
      column1: 'Total Withdrawal Amount',
      column2: parseFloat(data.total_approved_withdraw_sum || '0.00').toFixed(2)
    },
    { key: 3, column1: 'Average Withdrawal', column2: parseFloat(data.average_withdraw_amount || '0.00').toFixed(2) },
    {
      key: 4,
      column1: 'First Withdrawal Date Time',
      column2: data.first_withdrawal_date_time ? convertUTCToLocalTime(data.first_withdrawal_date_time) : '-'
    },
    {
      key: 5,
      column1: 'Last Withdrawal Date Time',
      column2: data.last_withdrawal_date_time ? convertUTCToLocalTime(data.last_withdrawal_date_time) : '-'
    }
  ];

  const data3 = [
    { key: 1, column1: 'Total Turnover', column2: parseFloat(data.total_turnover || '0.00').toFixed(2) },
    { key: 2, column1: 'Total Valid Bets', column2: parseFloat(data.total_valid_bets || '0.00').toFixed(2) },
    { key: 3, column1: 'Win/Loss', column2: parseFloat(data.total_win_loss || '0.00').toFixed(2) },
    { key: 4, column1: '', column2: '' },
    { key: 5, column1: '', column2: '' }
  ];

  const data4 = [
    { key: 1, column1: 'Total Turnover', column2: parseFloat(remTurn.totalDepositSum || '0.00').toFixed(2) },
    { key: 2, column1: 'Remaining Turnover', column2: parseFloat(remTurn.remainingTurnOver || '0.00').toFixed(2) }
    // { key: 3, column1: 'Win/Loss', column2: parseFloat(data.total_win_loss || '0.00').toFixed(2) },
    // { key: 4, column1: '', column2: '' },
    // { key: 5, column1: '', column2: '' },
  ];

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '25px' }}>
        <div
          style={{
            width: '100%'
          }}>
          <Title level={4}>Deposit Information</Title>
          <Table
            columns={columns}
            dataSource={data1}
            pagination={false}
            bordered
            showHeader={false}
            loading={loading}
            style={{
              border: '0.01rem solid #00000026',
              borderTopLeftRadius: '15px',
              borderBottomLeftRadius: '15px'
            }}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Title level={4}>Withdrawal Information</Title>
          <Table
            columns={columns}
            dataSource={data2}
            pagination={false}
            bordered
            showHeader={false}
            loading={loading}
            style={{
              borderTop: '0.01rem solid #00000026',
              borderBottom: '0.01rem solid #00000026'
            }}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Title level={4}>Total Information</Title>
          <Table
            columns={columns}
            dataSource={data3}
            pagination={false}
            bordered
            showHeader={false}
            loading={loading}
            style={{
              border: '0.01rem solid #00000026',
              borderTopRightRadius: '15px',
              borderBottomRightRadius: '15px'
            }}
          />
        </div>
      </div>
      <div style={{ width: '33%' }}>
        <Title level={4}>Remaining Turnover</Title>
        <Table
          columns={columns}
          dataSource={data4}
          pagination={false}
          bordered
          showHeader={false}
          loading={loading}
          style={{
            border: '0.01rem solid #00000026',
            borderRadius: '15px'
            // borderBottomRightRadius: '15px',
          }}
        />
      </div>
    </div>
  );
};
export default PlayerTransactionTable;
