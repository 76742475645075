import {
  Button,
  message,
} from "antd";
import LangContext from "../../helps/contexts/lang-context";
import { useContext, useState } from "react";
import PutApi from "../../helps/contexts/Api/PutApi";
const PlayerChangeStatusPlayerlist = ({ rowdata, onCancel , getPlayers,}) => {

  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const id = rowdata.id;

  //states
  const [status, setStatus] = useState(1);

  //on form submit request to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      setStatus(!status);

      const path = `/admin/player/${id}/status/toggle`;
      // sending request
      const res = await PutApi.PutApiRequest(path);
      //if success
      if (res && res.status == true) {
       message.success("Status Updated Successfully");
        onCancel()
        getPlayers()
       
      }
    } catch (e) {
      var message1 = e.message;
      message.error(message1);
    }
  };
  return (
    <div
      style={{
        width: "97%",
        height: "70px",
        borderRadius: "3px",
        justifyContent: "space-evenly",
      }}
    >
      <p
        style={{
          color: "black",
          fontSize: "16px",
          textAlign: "center",
          marginTop: "30px",
          marginLeft: "-50px",
        }}
      >
       {lang.label_userstatus}:{rowdata.status === 1 || rowdata.active === 1 ? lang.label_active : lang.label_inactive}
      </p>

      <form onSubmit={onSubmit}>
        <div>
          <Button
            style={{
              borderRadius: "3px",
              backgroundColor: "green",
              color: "white",
              position: "absolute",
              right: "25%",
              top: "70%",
              bottom: "10%",
            }}
            htmlType="submit"
          >
            {rowdata.status === 1 || rowdata.active === 1 ? lang.label_deactivate:lang.label_activate}
          </Button>
        </div>
      </form>
    </div>
  );
};
export default PlayerChangeStatusPlayerlist;
