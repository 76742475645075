import { Input, Button, Row, Col, Select, message } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PostApi from '../../../helps/contexts/Api/PostApi';

import GetApi from '../../../helps/contexts/Api/GetApi';

const AddAgentForm = ({ handleCancel, fetchAgents }) => {
  const nav = useNavigate();
  // form states
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState();

  const [balance, setBalance] = useState('');
  const [creditLine, setCreditLine] = useState('');
  const [maxCredit, setMaxCredit] = useState('');

  const [loading, setLoading] = useState(false);

  const [countrycode, setcountrycode] = useState();
  const [uniquecode, setUniquecode] = useState();
  const [senioragentid, setSenioragentid] = useState();
  const [currencyId, setCurrencyId] = useState();
  const [seniordata, setseniordata] = useState();
  const [currencyData, setCurrencyData] = useState(null);
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { Option } = Select;
  const [codedata, setcodedata] = useState([]);

  const [agentTypes, setAgentTypes] = useState([]);
  const [selectedType, setSelectedType] = useState();

  const onSelect = (value) => {
    setSenioragentid(value);
  };

  const onSelectAgentType = (value) => {
    setSelectedType(value);
    fetchSeniorgents({ id: value, search: '' });
  };

  const onSelectCurrency = (value) => {
    setCurrencyId(value);
  };
  useEffect(() => {
    // fetchSeniorgents();
    fetchCurrency();
    fetchCountryCode();
    fetchAgentTypes();
  }, []);

  const fetchAgentTypes = async () => {
    const path = '/admin/agent/types';
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path, {}, params);

    if (res) {
      console.log(res);
      const finalArray = Object.keys(res).map((key, index) => {
        return {
          key: Number(key),
          value: Number(key),
          label: res[key]
        };
      });
      setAgentTypes(finalArray);
    } else {
      setAgentTypes([]);
    }
  };
  // Fetching api
  const fetchSeniorgents = async ({ id, search }) => {
    setLoading(true);
    const path = `/admin/agent/get-superior-agent-list?agent_type=${id}&search=${search}`;
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path, {}, params);

    if (res && res && res.length) {
      // const finalArray = res.data.map((agent, index) => ({
      //   key: index,
      //   id: agent.id,
      //   name: agent.user.user_name
      // }));
      setseniordata(res);
    } else {
      setseniordata([]);
    }

    setLoading(false);
  };

  // Fetching api
  const fetchCurrency = async (customPage = null) => {
    const path = '/admin/agent/currency';
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path, {}, params);
    if (res) {
      const finalArray = Object.keys(res).map((key, index) => {
        return {
          key: Number(key),
          value: Number(key),
          label: res[key]
        };
      });
      setCurrencyData(finalArray);
    } else {
      setCurrencyData([]);
    }
  };
  const fetchCountryCode = async () => {
    const path = '/user/phone/codes';
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path, {}, params);

    if (res && res.length) {
      const finalArray = res.map((cc, index) => ({
        key: index,
        code: cc.code
      }));
      setcodedata(finalArray);
    } else {
      setcodedata([]);
    }
  };
  const oncodechange = (value) => {
    setcountrycode(value);
  };
  //form submit to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      const finalphone = countrycode + phone;

      const path = `/agent/create`;
      // sending request
      const res = await PostApi.PostApiRequest(path, {
        user_name: username,
        password: password,
        name: name,
        // phone: countrycode ? finalphone : phone,
        phone: phone,
        unique_code: uniquecode,
        senior_agent_id: senioragentid,
        currency: currencyId,
        agent_type: selectedType,
        balance: balance,
        credit_amount: creditLine,
        max_limit: maxCredit
      });

      //if success
      if (res?.status === 200) {
        message.success(lang.label_agentaddsuccess);
        setName('');
        setPassword('');
        setPhone('');
        setSenioragentid(0);
        setCurrencyId(0);
        setUniquecode('');
        setUserName('');
        handleCancel();
        fetchAgents();
      }
    } catch (e) {
      if (e?.status === 422) {
        message.error(lang.label_agentaddfail);
        Object.values(e.data.message).map((errors) => message.error(errors));
      }
    }
  };
  //username input change and validation
  const onInputChange = (e) => {
    const { value } = e.target;

    const re = /^[a-z0-9_-]*$/;
    if (value === '' || re.test(value)) {
      setUserName(value);
    } else {
      message.error('Please Enter Only Numbers and Lowercase Alphabets');
    }
  };
  const onCodeChange = (e) => {
    const { value } = e.target;

    const re = /^[a-z0-9_-]*$/;
    if (value === '' || re.test(value)) {
      setUniquecode(value);
    } else {
      message.error('Please Enter Only Numbers and Lowercase Alphabets');
    }
  };

  return (
    <div
      style={{
        width: '97%',
        height: '85%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: '3%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '-6%'
          }}>
          <Row>
            <Col span={12}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_name}</label>
              <br />

              <Input
                defaultValue="Agent Name"
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                placeholder="Agent Name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col span={12}>
              <label>
                {lang.label_username}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <br />

              <Input
                defaultValue="Agent Username"
                type="text"
                placeholder="Agent Name"
                name="username"
                required
                value={username}
                onChange={onInputChange}
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                minLength={8}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>
                {lang.label_password}
                <span style={{ color: 'red' }}>*</span>
              </label>{' '}
              <br />
              <Input.Password
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                required
                type="password"
                placeholder="Enter Password"
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                maxLength={18}
                minLength={8}
              />
            </Col>
            <Col span={12}>
              <label>
                {lang.label_uniquecode}
                <span style={{ color: 'red' }}>*</span>
              </label>{' '}
              <br />
              <Input
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                required
                placeholder="Enter Unique code"
                name="uniquecode"
                value={uniquecode}
                onChange={onCodeChange}
                maxLength={10}
                minLength={5}
              />
            </Col>
          </Row>

          <Row>
            {/* <Col span={3}>
              <label>code </label>
              <br />
              <Select
                style={{ width: "90%", marginTop: "5px" }}
                onChange={oncodechange}
                defaultValue={countrycode}
              >
                <Option>{}</Option>
                {codedata &&
                  codedata.map((cc, index) => {
                    return (
                      <Option key={index} value={cc.code}>
                        {cc.code}
                      </Option>
                    );
                  })}
              </Select>
            </Col> */}
            <Col span={12}>
              <label>{lang.label_phone} </label>
              <br />

              <Input
                style={{
                  width: '84%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                placeholder="Enter Phone Number"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                minLength={11}
                maxLength={14}
              />
            </Col>

            <Col span={12}>
              <label>{lang.label_agent_type}</label>
              <br />
              <Select
                style={{ marginTop: '5px', width: '85%' }}
                onChange={onSelectAgentType}
                defaultValue={'--Select a agent type--'}
                allowClear>
                {agentTypes &&
                  agentTypes.map((type, index) => {
                    return (
                      <Option key={index} value={type?.key}>
                        {type?.label}
                      </Option>
                    );
                  })}
              </Select>
            </Col>

            <Col span={12}>
              <label>{lang.label_superioragent}</label>
              <br />
              <Select
                style={{ marginTop: '5px', width: '85%' }}
                onChange={onSelect}
                loading={loading}
                defaultValue={'--Select A Senior Agent--'}
                allowClear>
                {seniordata &&
                  seniordata.map((agent, index) => {
                    return (
                      <Option key={index} value={agent?.agent_id}>
                        {agent?.user_name}
                      </Option>
                    );
                  })}
              </Select>
            </Col>

            <Col span={12}>
              <label>{lang.label_currency}</label>
              <br />
              <Select
                style={{ marginTop: '5px', width: '85%', marginBottom: '5px', marginTop: '5px' }}
                onChange={onSelectCurrency}
                defaultValue={'--Select A Currency--'}
                allowClear>
                {currencyData &&
                  currencyData.map((currency, index) => {
                    return (
                      <Option key={currency?.key} value={currency.value}>
                        {currency.label}
                      </Option>
                    );
                  })}
              </Select>
            </Col>

            <Col span={12}>
              <label>{lang.label_balance} </label>
              <br />

              <Input
                style={{
                  width: '84%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                placeholder="Enter Balance"
                name="balance"
                value={balance}
                onChange={(e) => setBalance(e.target.value)}
                // minLength={11}
                // maxLength={14}
              />
            </Col>

            <Col span={12}>
              <label>{lang.label_credit_line} </label>
              <br />

              <Input
                style={{
                  width: '84%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                placeholder="Enter Credit Line"
                name="credit_line"
                value={creditLine}
                onChange={(e) => setCreditLine(e.target.value)}
                // minLength={11}
                // maxLength={14}
                onBlur={(e) => {
                  if (creditLine !== '' && maxCredit !== '' && creditLine > maxCredit) {
                    message.error('Credit line cannot be greater than max credit.');
                    setCreditLine('');
                  }
                }}
              />
            </Col>

            <Col span={12}>
              <label>{lang.label_max_credit} </label>
              <br />

              <Input
                style={{
                  width: '84%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                placeholder="Enter Max Credit"
                name="max_credit"
                value={maxCredit}
                onChange={(e) => setMaxCredit(e.target.value)}
                onBlur={(e) => {
                  if (maxCredit !== '' && maxCredit < creditLine) {
                    message.error('Max Credit cannot be less than credit line.');
                    setMaxCredit('');
                  }
                }}
                // minLength={11}
                // maxLength={14}
              />
            </Col>
          </Row>

          <br />

          <Row></Row>
          <br />

          <div style={{}}>
            {' '}
            <Button
              style={{
                borderRadius: '3px',
                backgroundColor: '#004A7F',
                color: 'white',
                position: 'absolute',
                right: '20%',
                top: '87%'
              }}
              htmlType="submit">
              {lang.label_submit}
            </Button>
            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                position: 'absolute',
                right: '8%',
                top: '87%'
              }}
              onClick={() => {
                setName('');
                setPassword('');
                setPhone('');
                setSenioragentid('--select a superior agent--');
                setCurrencyId('--select a currency--');
                setUniquecode('');
                setUserName('');
                handleCancel();
              }}>
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddAgentForm;
