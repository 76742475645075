import { Row, Col, Button, Input, Card, Modal } from 'antd';
import { useContext, useState, useEffect } from 'react';
import LangContext from '../../helps/contexts/lang-context';
import PermissionsTable from './PermissionsTable';
import GetApi from '../../helps/contexts/Api/GetApi';
import AddRoleForm from '../../components/forms/AddRoleForm';
import { useNavigate } from 'react-router-dom';
import HeaderCardDetails from '../../components/HeaderDetailCard';
import Spacer from '../../components/HelperComponents/Spacer';
import useCheckPermission from '../../helps/hooks/useCheckPermission';
import { TabContext } from '../../helps/contexts/TabContext';
import SubHeading from '../../components/HelperComponents/SubHeading';
import Heading from '../../components/HelperComponents/Heading';
import SearchIcon from "../../images/Search.png";
import ResetIcon from "../../images/Reset.png";
import styles from "../adminmanagement/AdminManagement.module.css";

const { Search } = Input;
const Permissions = ({}) => {
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();

  const lang = ctx.lang;
  const nav = useNavigate();
  const inputStyle = {
    width: '190px',
    height: '33px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const [permissiondata, setPermissionData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [searchtext, setSearchtext] = useState('');
  const [filteredData, setFilteredData] = useState(roleData);
  const [loading, setLoading] = useState(false);
  const { path } = useContext(TabContext);

  useEffect(() => {
    getPermissions();
    getRoles();
  }, []);

  useEffect(() => {
    if (path === '/admin-management/permissions') {
      getPermissions();
      getRoles();
    }
  }, [path]);

  //get permissons data from backend
  const getPermissions = async () => {
    try {
      const path = `/permissions/index`;
      const params = {};
      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.length) {
        const finalArray = res.map((permissions, index) => ({
          key: index,
          id: permissions.id,
          name: permissions.name
        }));
        setPermissionData(finalArray);
      } else {
        setPermissionData([]);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };
  const onReset = () => {
    setSearchtext('');
    getRoles();
  };
  //get roles data from backend
  const getRoles = async () => {
    const path = `/roles/index`;
    const params = {};
    if (searchtext) params['search'] = searchtext;
    setSearchtext('');
    setLoading(true);
    const res = await GetApi.sendApiRequest(path, {}, params);
    if (res && res.length) {
      const finalArray = res.map((roles, index) => ({
        key: index,
        id: roles.id,
        name: roles.name,
        permissions: roles.permissions
      }));

      setRoleData(finalArray);
      setFilteredData(finalArray);
    } else {
      setRoleData([]);
    }
    setLoading(false);
  };
  // Add role popup
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  //search

  const onSubmit = (e) => {
    console.log(searchtext);
    e.preventDefault();
    if (searchtext) {
      let result = [];

      result = roleData.filter((data) => {
        return data.name.toLowerCase().search(searchtext.toLowerCase()) !== -1;
      });
      console.log(result);
      setFilteredData(result);
    }
  };

  return (
    <>
      <HeaderCardDetails />
      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div>   
        <div className={styles.container}>
            <div
              className={styles.filterUpperContainer}
              style={{
                display: 'flex',
                alignItems :'center',
                justifyContent : 'space-between',
                borderRadius: '3px'
              }}>
              <Heading>{lang.label_role}</Heading>
             <div>
             {hasPermission('Add Permission') && (
                <Button
                style={{
                  backgroundColor: '#004A7F',
                  color: 'white',
                  width: '125px',
                  height: '35px',
                  background: '#004A7F 0% 0% no-repeat padding-box',
                  borderRadius: '3px',
                  opacity: '1',
                  marginRight: '20px',
                }}
                  className="btnStyle"
                  onClick={showModal}>
                  {lang.label_addrole}
                </Button>
              )}

              <div>
                {visible ? (
                  <Modal  centered title={lang.label_addrole}  open={visible} footer={null} width={700} closable={false}>
                    <AddRoleForm checklist={permissiondata} handleCancel={handleCancel} getRoles={getRoles} />
                  </Modal>
                ) : (
                  ''
                )}
              </div>
              </div>
            </div>
            <div className={styles.filterLowerContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  width: '100%',
                  paddingRight: '14px',
                  flexWrap: 'wrap'
                }}>
             
          
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_name}
                  </SubHeading>

                  <Input
                    placeholder="input search text"
                    style={inputStyle}
                    name="search"
                    type="text"
                    value={searchtext}
                    onChange={(e) => {
                      setSearchtext(e.target.value);
                    }}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    marginTop: '20px',
                    marginLeft: '10px',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                  }}>
                  <Button
                    style={{
                      display : 'flex',
                     alignItems : 'center',
                     justifyContent : 'space-evenly',
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1'
                    }}
                    htmlType="submit"
                    className="btnStyle"
                    // onClick={() => {
                    //   setActivepage1(1);
                    //   setLoading(true);
                    // }}
                    >
                      <img src={SearchIcon} alt='search' />
                    {lang.label_search}
                  </Button>

                  <Button
                    style={{
                      display : 'flex',
                      alignItems : 'center',
                      justifyContent : 'space-evenly',
                      borderRadius: '3px',
                      padding: '6px',
                      border: '1px solid grey',
                      width: '84px'
                    }}
                    className="btnReset"
                    htmlType="reset"
                    onClick={onReset}>
                       <img src={ResetIcon} alt='reset' />
                    {lang.label_reset}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <Spacer />
          <PermissionsTable data={filteredData} checklist={permissiondata} loading={loading} getRoles={getRoles} />
        </div>
      </form>
    </>
  );
};

export default Permissions;
