import React from "react";
import { Button } from "antd";
import { useContext } from "react";
import styles from "./ChangeStatusModal.module.css";
import LangContext from "../../helps/contexts/lang-context";

const ChangeStatusModal = ({ rowdata, onCancel, onSubmit, text }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  return (
    <div className={styles.modalOverlay}>
      <p>{text}</p>
      <form onSubmit={onSubmit}>
        <Button htmlType="submit" className={styles.submitButton}>
          {(rowdata.status === 1) 
            ? lang.label_deactivate
            : lang.label_activate}
        </Button>
        <Button className={styles.cancelButton} onClick={onCancel}>
          {lang.label_cancel}
        </Button>
      </form>
    </div>
  );
};

export default ChangeStatusModal;
