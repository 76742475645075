import { Button, Row, Col, Select, message } from 'antd';
import LangContext from '../../helps/contexts/lang-context';
import { useContext, useState, useEffect } from 'react';
import GetApi from '../../helps/contexts/Api/GetApi';
import PostApi from '../../helps/contexts/Api/PostApi';
const AdminChangeRole = ({ rowdata, onCancel, getUsers }) => {
  const { Option } = Select;
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const userid = rowdata.userid;

  //states
  const [roledata, setRoledata] = useState([]);
  const [roleselect, setRoleselect] = useState(rowdata.role);

  useEffect(() => {
    getRoles();
  }, []);
  useEffect(() => {
    setRoleselect(rowdata.role);
  }, [rowdata]);
  //get role data
  const getRoles = async () => {
    const path = `/roles/index`;
    const params = {};
    const res = await GetApi.sendApiRequest(path, {}, params);

    if (res && res.length) {
      const finalArray = res.map((roles) => ({
        id: roles.id,

        name: roles.name,

        permissions: roles.permissions
      }));

      setRoledata(finalArray);
    } else {
      setRoledata([]);
    }
  };
  //type dropdown
  const handleChange1 = (value) => {
    setRoleselect(value);
  };

  //on submit form for sending request
  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      const path = `/user/${userid}/role/assign`;
      // sending request
      const res = await PostApi.PostApiRequest(path, {
        role_id: roleselect
      });
      //if success
      if (res.status === 200) {
        message.success(lang.label_assignnewrolesuccess);
        onCancel();
        getUsers();
        setRoleselect(null);
      } else {
        message.error(lang.label_assignnewrolefail);
      }
    } catch (e) {
      message.error(e.mesage);
    }
  };
  return (
    <div
      style={{
        width: '97%',
        height: '150px',
        borderRadius: '3px',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>

      <form onSubmit={onSubmit}>
        <Row>
          <Col span={12}>
            <label>{lang.label_type}</label> <br />
            <Select
              defaultValue="Please select a role"
              value={roleselect}
              style={{
                width: '186%',
                borderRadius: '3px',
                color: 'green',
                marginBottom: '5px',
                marginTop: '5px'
              }}
              onChange={handleChange1}>
              <Option value=" Select a role "> -- Select a role-- </Option>
              {roledata.map((role, index) => {
                return (
                  <Option key={index} value={role.id}>
                    {role.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>

        <div style={{}}>
          {' '}
          <Button
            style={{
              borderRadius: '3px',
              backgroundColor: '#004A7F',
              color: 'white',
              position: 'absolute',
              right: '30%',
              top: '80%'
            }}
            htmlType="submit">
            {lang.label_submit}
          </Button>
          <Button
            style={{
              borderRadius: '3px',
              color: '#004A7F',
              position: 'absolute',
              right: '8%',
              top: '80%'
            }}
            onClick={() => {
              onCancel();
            }}>
            {lang.label_cancel}
          </Button>
        </div>
      </form>
    </div>
  );
};
export default AdminChangeRole;
