
import Echo from "laravel-echo";
import { useEffect } from "react";
import Pusher from "pusher-js"
import { constants } from "../helps/contexts/Api/constants";
import axios from "axios";

window.axios = axios
window.Pusher = Pusher

const usePusherSubscription = (channelName, eventName, callback) => {

  useEffect(() => {
    window.Echo = new Echo({
      broadcaster: "pusher",
      // key: '6d1f80dfd34c86bce311',
      // key: "665f72637a27ea7b6aad",
      key: "5a0b840d437543f94aef",
      cluster: "ap1",
      forceTLS: true,
      authEndpoint: constants.BASE_URL2 + "/broadcasting/auth",
      auth: {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      },
    });

    axios.interceptors.request.use(
      config => {
        config.headers['X-Socket-ID'] = window.Echo.socketId()
        return config
      },
      error => Promise.reject(error)
    )
  
    var channel = window.Echo.private(channelName);
    channel.listen(`.${eventName}`, callback);
    return () => {
      window.Echo
      .private(channelName)
      .stopListening(`.${eventName}`)
      window.Echo.leave(channelName);
    };
  }, []);

  
};

export default usePusherSubscription;
