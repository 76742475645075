import { Table } from "antd";

const BetSumsTable = ({ data, loading }) => {

    const columns = [
        {
          title: 'Currency',
          dataIndex: 'currency_name',
          key: 'currency_name',
        },
        {
          title: 'Total Win/Loss',
          dataIndex: 'win_loss_sum',
          key: 'win_loss_sum',
        },
        {
          title: 'Total Turnover',
          dataIndex: 'total_turnovers_sum',
          key: 'total_turnovers_sum',
        },
        {
          title: 'Total Valid Bets',
          dataIndex: 'total_valid_bets_sum',
          key: 'total_valid_bets_sum',
        },
        {
          title: 'Total Win Amount',
          dataIndex: 'total_win_amount_sum',
          key: 'total_win_amount_sum',
        },
        // {
        //   title: 'Base Win/Loss',
        //   dataIndex: 'base_win_loss',
        //   key: 'base_win_loss',
        // },
        // {
        //   title: 'Base Total Turnover',
        //   dataIndex: 'base_total_turnovers',
        //   key: 'base_total_turnovers',
        // },
        // {
        //   title: 'Base Total Valid Bets',
        //   dataIndex: 'base_total_valid_bets',
        //   key: 'base_total_valid_bets',
        // },
        // {
        //   title: 'Base Total Win Amount',
        //   dataIndex: 'base_total_win_amount',
        //   key: 'base_total_win_amount',
        // },
    ]

    return (
      <Table
        loading={loading}
        columns={columns}
        bordered
        dataSource={data}
        pagination={false}
        size="small"
      />
    );
}

export default BetSumsTable