import {
  Table,
} from "antd";
import { useContext, useState, useEffect } from "react";
import LangContext from "../../../helps/contexts/lang-context";
import moment from "moment";
import { convertUTCToLocalTime } from "../../../helps/HelperFunctions";

const AdminLoginHistoryTable = ({ data, loading, getUsers, childrowdata }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  //states
 
 
  const [rowdata, setRowdata] = useState({});
  
  // columns of table
  const columns = [
    {
      title: ` ${lang.label_username}`,
      dataIndex: "username",
      key: "username",
    },
    {
      title: `${lang.label_loginip}`,
      dataIndex: ["loginip"],
      key: "loginip",
      render: (time, row) => (
        <a style={{ color: "#2A93F0" }} title={row["loginip"]}>
          {  row["loginip"]}
        </a>
      ),
    },

    {
      title: `${lang.label_logintime}`,
      dataIndex: ["logintime"],
      key: "logintime",
      render: (time, row) => (
        <a style={{ color: "black" }}title={row["logintime"]}>
         
            {
            // new Date(row["logintime"]).toLocaleString()
            //moment.utc(row["logintime"], "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")
            convertUTCToLocalTime(row["logintime"])
            }
        
        </a>
      ),
    },

    {
      title: ` ${lang.label_remark}`,
      dataIndex: "remark",
      key: "remark",
    },
  ];

  return (
    <>
      <Table
      style={{width:"100%"}}
        size="small"
        columns={columns}
        dataSource={data}
        bordered
        pagination={false}
        loading={loading}
        onRow={(record) => {
          return {
            onClick: () => {
              setRowdata(record);
            },
          };
        }}
      />
    </>
  );
};

export default AdminLoginHistoryTable;
