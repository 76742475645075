import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { constants } from './constants';

const PostApiRequest = async (path, Data, extraHeaders = null, extraConfig = null) => {
  const url = `${constants.BASE_URL1}` + `${path}`;
  const token1 = localStorage.getItem('token');
  return await axios
    .post(url, Data, {
      headers: {
        Authorization: `Bearer ${token1}`,
        'Content-Type': 'multipart/form-data',
        ...extraHeaders
      },
      ...extraConfig
    })
    .then((response) => {
      if (response?.status === 401) {
        localStorage.clear();
        return <Navigate to="/" replace />;
      } else if (response?.status === 200) {
        return response;
      } else return null;
    })
    .catch((e) => {
      if (e.response.request.status === 401) {
        localStorage.clear();
        return <Navigate to="/" replace />;
      }

      return Promise.reject(e.response);
    });
};

const PostApiRequest1 = async (path, Data) => {
  const url = `${constants.BASE_URL1}` + `${path}`;
  const token1 = localStorage.getItem('token');
  var formData = new FormData();
  formData.append('bank_code', Data.bank_code);
  formData.append(' bank_holder_name', Data.bank_holder_name);
  formData.append(' bank_account_number', Data.bank_account_number);
  formData.append('bank_image', Data.bank_image);
  formData.append(' api_key', Data.api_key);
  formData.append('callback_key', Data.callback_key);
  formData.append('allow_deposit', Data.allow_deposit);
  formData.append('allow_withdraw', Data.allow_withdraw);
  formData.append(' remark', Data.remark);
  formData.append(' currency', Data.currency);
  const res = await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${token1}`,
      'Content-Type': 'multipart/form-data'
    }
  });
  if (res) {
    return res;
  }
  return null;
};
const PostApiRequest2 = async (path, Data) => {
  const url = `${constants.BASE_URL1}` + `${path}`;
  const token1 = localStorage.getItem('token');
  var formData = new FormData();
  formData.append('otp', Data);

  const res = await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${token1}`,
      'Content-Type': 'multipart/form-data'
    }
  });
  if (res) {
    return res;
  }
  return null;
};
const PostApiRequest3 = async (path, Data) => {
  const url = `${constants.BASE_URL1}` + `${path}`;
  const token1 = localStorage.getItem('token');
  var formData = new FormData();
  formData.append('password', Data);

  const res = await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${token1}`,
      'Content-Type': 'multipart/form-data'
    }
  });
  if (res) {
    return res;
  }
  return null;
};

const PostApiRequest4 = async (path, Data, extraHeaders = null, extraConfig = null) => {
  const url = `${constants.BASE_URL1}` + `${path}`;
  const token1 = localStorage.getItem('token');
  return await axios
    .post(url, Data, {
      headers: {
        Authorization: `Bearer ${token1}`,
        // 'Content-Type': 'multipart/form-data',
        ...extraHeaders
      },
      ...extraConfig
    })
    .then((response) => {
      if (response?.status === 401) {
        localStorage.clear();
        return <Navigate to="/" replace />;
      } else if (response?.status === 200) {
        return response;
      } else return null;
    })
    .catch((e) => {
      if (e.response.request.status === 401) {
        localStorage.clear();
        return <Navigate to="/" replace />;
      }

      return Promise.reject(e.response);
    });
};

const PostApiRequest5 = async (path, Data, extraHeaders = null, extraConfig = null) => {
  const url = `${constants.BASE_URL1}` + `${path}`;
  const token1 = localStorage.getItem('token');
  return await axios
    .post(url, Data, {
      headers: {
        Authorization: `Bearer ${token1}`,
        'Content-Type': 'multipart/form-data',
        ...extraHeaders
      },
      ...extraConfig
    })
    .then((response) => {
      if (response?.status === 401) {
        localStorage.clear();
        return <Navigate to="/" replace />;
      } else if (response?.status === 201 || response?.status === 200) {
        return response;
      } else return null;
    })
    .catch((e) => {
      if (e.response.request.status === 401) {
        localStorage.clear();
        return <Navigate to="/" replace />;
      }

      return Promise.reject(e.response);
    });
};

export default {
  PostApiRequest,
  PostApiRequest1,
  PostApiRequest2,
  PostApiRequest3,
  PostApiRequest4,
  PostApiRequest5
};
