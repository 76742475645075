import React from "react";

import LangContext from "../../../helps/contexts/lang-context";
import { useContext} from "react";
import { Button, message} from "antd";
import { useNavigate } from "react-router-dom";
import DeleteApi from "../../../helps/contexts/Api/DeleteApi";
const DeleteNotification = ({ handleCloseDeleteMsg, getNotification, id }) => {

    const nav = useNavigate();
    const ctx = useContext(LangContext);
    const lang = ctx.lang;

    const deleteNofitication = async (e) => {
        try {
            e.preventDefault();

            const path = `/admin/notifications/delete/${id}`;
            // sending request
            const res = await DeleteApi.DeleteApiRequest(path);
            //if success
            if (res && res.status === true) {
                message.success("Player Notification Deleted Successfuly");
                handleCloseDeleteMsg();
                getNotification();
            } else if (!res) {
                message.error("Player Notification Deletion Failed");
            }
        } catch (e) {
            if (e?.status === 401) {
                localStorage.clear();
                nav("/login");
            }

        }
    };



    return (
        <div
            style={{
                width: "97%",
                minHeight: "50px",
                height: "100%",
                justifyContent: "space-evenly",
            }}
        >
            <form onSubmit={deleteNofitication}>
                <div
                    style={{
                        width: "97%",
                        height: "100%",
                        justifyContent: "space-around",
                    }}
                >

                    <Button
                        style={{
                            borderRadius: "3px",
                            backgroundColor: "#004A7F",
                            color: "white",
                            position: "absolute",
                            right: "30%",
                            // top: "80%",
                            bottom: '15px',
                            margin: "-1%",
                        }}
                        htmlType="submit"
                    >
                        {lang.label_delete}
                    </Button>
                    <Button
                        style={{
                            borderRadius: "3px",
                            color: "#004A7F",
                            position: "absolute",
                            right: "8%",
                            // top: "80%",
                            bottom: '15px',
                            margin: "-1%",
                        }}
                        onClick={() => {
                            handleCloseDeleteMsg()
                        }}
                    >
                        {lang.label_cancel}
                    </Button>
                </div>
            </form>
        </div>
    );
};
export default DeleteNotification;
