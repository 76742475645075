import React, { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';

const useCheckPermission = () => {
    const { user } = useContext(UserContext);
    const hasPermission = (permission) => {
        return user?.permissions?.includes(permission);
    };
  return { hasPermission };
};

export default useCheckPermission;
