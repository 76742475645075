import { Button, Checkbox, Flex, Input, InputNumber, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaPlusSquare } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import GetApi from '../../../helps/contexts/Api/GetApi';
import { useNavigate } from 'react-router-dom';
import PostApi from '../../../helps/contexts/Api/PostApi';
import PutApi from '../../../helps/contexts/Api/PutApi';
import Loader from '../../../components/HelperComponents/Loader';

export default function AffiliateSettings({ isModalOpen, handleOk, handleCancel, lang }) {
  const inputStyle = {
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const selectStyle = {
    height: '33px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '3px'
  };

  const labelStyle = {
    fontSize: '14px',
    color: '#4F5057'
  };

  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(null);
  const [rules, setRules] = useState(['']);
  // const [isFirst, setIsFirst] = useState(false);

  const getDetails = async () => {
    setLoading(true);
    try {
      const path = '/admin/affiliate/settings/view';

      const res = await GetApi.sendApiRequest(path, {}, {});

      setLoading(false);
      // console.log(res);
      if (res) {
        // setLoading(false);
        // if (res?.is_updated) {
        setDetails(res);
        setRules(res?.rules);
        //   setIsFirst(false);
        // } else {
        //   setIsFirst(true);
        // }
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    // if (isFirst) {
    //   try {
    //     const path = `/admin/affiliate/settings/store`;

    //     let body = {
    //       ...details,
    //       rules: rules,
    //       is_updated: 1
    //     };

    //     const res = await PostApi.PostApiRequest4(path, body);
    //     //if success
    //     setLoading(false);
    //     console.log('x', res);
    //     if (res) {
    //       message.success('Added successfully!');
    //       handleOk();
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     message.error(error?.data?.message[0] ?? `Something went wrong!`);
    //     setLoading(false);
    //   }
    // } else {
    if (rules.some((rule) => rule === null || rule.trim() === '')) {
      message.error('Please fill out all rule inputs before submitting.');
      return;
    }

    setLoading(true);
    try {
      const path = `/admin/affiliate/settings/update/${details?.id}`;
      const body = {
        ...details,
        rules: rules
      };

      const res = await PutApi.PutApiRequest(path, body);
      setLoading(false);

      if (res) {
        message.success('Updated successfully!');
        handleOk();
      }
    } catch (error) {
      console.log(error);
      message.error(error?.data?.message ?? `Something went wrong!`);
      setLoading(false);
    }
    // }
  };

  const handleAddRow = () => {
    if (rules.some((rule) => rule === null || rule.trim() === '')) {
      message.error('Please fill out the current rule input before adding a new one.');
      return;
    }
    setRules([...rules, '']);
  };

  const handleDeleteRow = (index) => {
    const newInputFields = rules.filter((_, i) => i !== index);
    setRules(newInputFields);
  };

  const handleInputChange = (index, event) => {
    const newInputFields = [...rules];
    newInputFields[index] = event.target.value;
    setRules(newInputFields);
  };

  useEffect(() => {
    if (isModalOpen) {
      getDetails();
    } else {
      setRules(['']);
    }
  }, [isModalOpen]);

  return (
    <>
      <Modal
        width="fit-content"
        centered
        title="Affiliate Settings"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={() => {
          setDetails(null);
          setRules(['']);
        }}
        footer={false}
        style={{ position: 'relative' }}>
        {loading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.4)',
              zIndex: 1
            }}>
            <Loader size="large" />
          </div>
        )}
        <Flex
          style={{
            gap: '20px',
            padding: '10px 0px'
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px'
            }}>
            <div style={labelStyle}>
              {lang.label_program_title} <span style={{ color: 'red' }}>*</span>
            </div>
            <div>
              <Input
                style={{
                  width: '250px',
                  ...inputStyle
                }}
                value={details?.program_title}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    program_title: e.target.value
                  })
                }
                placeHolder="Enter Program Title"
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px'
            }}>
            <div style={labelStyle}>
              {lang.label_description} <span style={{ color: 'red' }}>*</span>
            </div>
            <div>
              <Input
                style={{
                  width: '250px',
                  ...inputStyle
                }}
                value={details?.description}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    description: e.target.value
                  })
                }
                placeHolder="Enter Description"
              />
            </div>
          </div>
        </Flex>
        <Flex
          style={{
            padding: '10px 0px',
            flexDirection: 'column'
          }}>
          <div style={labelStyle}>
            {lang.label_referral_bonus_downline_requirement} <span style={{ color: 'red' }}>*</span>
          </div>
          <div
            style={{
              marginTop: '2px',
              marginBottom: '10px',
              gap: '20px',
              display: 'flex'
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px'
              }}>
              <div style={labelStyle}>
                <Checkbox
                  checked={details?.is_minimum_deposit}
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      is_minimum_deposit: e.target.checked
                    });
                  }}>
                  {lang.label_min_deposit}
                </Checkbox>
              </div>
              <div>
                <InputNumber
                  style={{
                    width: '250px',
                    ...inputStyle
                  }}
                  value={details?.minimum_deposit}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      minimum_deposit: e
                    })
                  }
                  placeHolder="Enter min deposit"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px'
              }}>
              <div style={labelStyle}>
                <Checkbox
                  checked={details?.is_betting_required}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      is_betting_required: e.target.checked
                    })
                  }>
                  {lang.labe_min_valid_bet}
                </Checkbox>
              </div>
              <div>
                <InputNumber
                  style={{
                    width: '250px',
                    ...inputStyle
                  }}
                  value={details?.minimum_bet}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      minimum_bet: e
                    })
                  }
                  placeHolder="Enter min valid bet"
                />
              </div>
            </div>
          </div>
          <div style={labelStyle}>
            <Checkbox
              checked={details?.phone_verified}
              onChange={(e) =>
                setDetails({
                  ...details,
                  phone_verified: e.target.checked
                })
              }>
              {lang.label_phone_validated}
            </Checkbox>
          </div>
        </Flex>
        <Flex
          style={{
            padding: '10px 0px',
            flexDirection: 'column'
          }}>
          <div style={labelStyle}>
            {lang.label_bonus_amount} <span style={{ color: 'red' }}>*</span>
          </div>
          <div
            style={{
              marginTop: '2px',
              marginBottom: '10px',
              gap: '20px',
              display: 'flex'
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px'
              }}>
              <div style={labelStyle}>
                <Checkbox
                  checked={details?.is_bonus_affiliate}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      is_bonus_affiliate: e.target.checked
                    })
                  }>
                  {lang.label_to_affiliate}
                </Checkbox>
              </div>
              <div>
                <InputNumber
                  style={{
                    width: '250px',
                    ...inputStyle
                  }}
                  value={details?.bonus_affiliate}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      bonus_affiliate: e
                    })
                  }
                  //   placeHolder="Enter min deposit"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px'
              }}>
              <div style={labelStyle}>
                <Checkbox
                  checked={details?.is_downline_affiliate}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      is_downline_affiliate: e.target.checked
                    })
                  }>
                  {lang.label_to_downline}
                </Checkbox>
              </div>
              <div>
                <InputNumber
                  style={{
                    width: '250px',
                    ...inputStyle
                  }}
                  value={details?.downline_affiliate}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      downline_affiliate: e
                    })
                  }
                  //   placeHolder="Enter min valid bet"
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px'
            }}>
            <div style={labelStyle}>
              <Checkbox
                checked={details?.is_condition_multiplier}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    is_condition_multiplier: e.target.checked
                  })
                }>
                {lang.label_condition_multiplier}
              </Checkbox>
            </div>
            <div>
              <InputNumber
                style={{
                  width: '250px',
                  ...inputStyle
                }}
                value={details?.condition_multiplier}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    condition_multiplier: e
                  })
                }
                //   placeHolder="Enter min valid bet"
              />
            </div>
          </div>
          <div
            style={{
              width: '100%',
              paddingTop: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <div style={labelStyle}>
              {lang.label_rules} <span style={{ color: 'red' }}>*</span>
            </div>
            <Button
              onClick={handleAddRow}
              style={{
                backgroundColor: '#004A7F',
                fontSize: '12px',
                color: 'white',
                width: '125px',
                border: '1px solid grey',
                textAlign: 'center',
                background: '#004a7f 0% 0% no-repeat padding-box',
                borderRadius: '3px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                gap: '4px'
              }}>
              <FaPlusSquare />

              {lang.label_add_new__rules}
            </Button>
          </div>
          <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
            {rules.map((value, index) => (
              <div
                key={index}
                style={{ marginTop: '10px', display: 'flex', gap: '4px', alignItems: 'center', paddingRight: '4px' }}>
                <div
                  style={{
                    color: '#004A7F',
                    fontWeight: 600,
                    fontSize: '14px'
                  }}>
                  {' '}
                  {index + 1}.
                </div>
                <Input
                  style={{
                    width: '100%',
                    ...inputStyle
                  }}
                  value={value}
                  onChange={(e) => handleInputChange(index, e)}
                  placeholder="Enter rule"
                />
                <Button
                  type="link"
                  shape="circle"
                  disabled={rules.length === 1}
                  onClick={() => handleDeleteRow(index)}
                  icon={<RiDeleteBin6Line color={rules.length === 1 ? 'gray' : 'red'} size={18} />}
                />
              </div>
            ))}
          </div>
        </Flex>
        <div
          style={{
            marginLeft: 'auto',
            display: 'flex',
            gap: '10px',
            marginTop: '20px',
            width: 'fit-content'
          }}>
          <Button
            //   loading={loading}
            //   disabled={isButtonDisabled()}
            onClick={handleSubmit}
            style={{
              backgroundColor: '#004A7F',
              color: 'white',
              width: '80px',
              height: '35px',
              background: '#004A7F 0% 0% no-repeat padding-box',
              borderRadius: '3px',
              opacity: '1'
            }}>
            {lang.label_save}
          </Button>
          <Button
            onClick={handleCancel}
            style={{
              borderRadius: '3px',
              color: '#004A7F',
              width: '80px',
              height: '35px'
            }}>
            {lang.label_cancel}
          </Button>
        </div>
      </Modal>
    </>
  );
}
