import React, { useEffect } from 'react';
import { Input, Button } from 'antd';
import styles from './login.module.css';
import { useContext, useState } from 'react';
import LangContext from '../../helps/contexts/lang-context.js';
import AdminService from '../../helps/contexts/Api/AdminService.js';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { UserContext } from '../../helps/contexts/UserContext.js';
import { Spin } from 'antd';
import { TabContext } from '../../helps/contexts/TabContext.js';

const Login = () => {
  const nav = useNavigate();
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { updateUser, user } = useContext(UserContext);
  const { handleTabChangeValue, setPath } = useContext(TabContext);
  //states
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [usererror, setUsererror] = useState('');
  const [pwderror, setPwderror] = useState('');
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);

  //request to backend
  const onFinish = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      const res = await AdminService.login(username, password, otp);
      console.log('login res is', res);
      if (res && res.status === 200) {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('username', res.data.user_name);
        localStorage.setItem('role', res.data.roles[0]?.name);
        localStorage.setItem('status', res.data?.status);
        localStorage.setItem('is_2fa_enabled', res.data?.is_2fa_enabled);
        localStorage.setItem('admin_id', res.data?.admin_id);
        localStorage.setItem('user_id', res.data?.user_id);

        const userData = {
          username: res.data.user_name,
          userid: res.data.user_id,
          role: res.data?.roles[0]?.name,
          status: res.data.status,
          is_2fa_enabled: res.data.is_2fa_enabled,
          permissions: res.data.permissions
        };
        updateUser(userData);
        nav('/dashboard');
      }
    } catch (e) {
      var message1 = e.message;
      setMsg(message1);
      // console.log(e);
      if (e.message === 'PASSWORD_INCORRECT' && e.message === 'USER_DOES_NOT_EXIST') {
        message.error('Login failed,Please Enter Valid Login Details');
      } else if (e.message === 'PASSWORD_INCORRECT') {
        setPwderror('Wrong Password');
        message.error(lang.label_wrongpassword);
      } else if (e.message === 'USER_DOES_NOT_EXIST') {
        setUsererror('User Does Not Exist');
        message.error(lang.label_userdoesnotexist);
      } else if (e.message === 'OTP_REQUIRED') {
        setUsererror('Please enter OTP');
        message.error('Please enter OTP');
      } else if (e.message === 'OTP_INCORRECT') {
        setUsererror('OTP entered is wrong');
        message.error('OTP entered is wrong');
      } else if (e.message === 'ACCOUNT_INACTIVE') {
        setUsererror('This account is Inactive');
        message.error('This account is Inactive');
      } else if (e.message === 'IP_NOT_WHITELISTED') {
        setUsererror('Unauthorized. Your IP Is Not Whitelisted');
        message.error('Unauthorized. Your IP Is Not Whitelisted');
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    handleTabChangeValue('');
    setPath(null);
  }, []);
  return (
    <div className={styles.loginFormOverlay}>
      <div className={styles.loginWrapper}>
        {/* <img src="./BW9-login.png" alt="loginbackground" /> */}
        <img src="./BW9Logo.jpg" alt="loginbackground" />
        <h6>{lang.label_logintoaccessdashboard}</h6>
        <form onSubmit={onFinish}>
          <label htmlFor="username">{lang.label_usernameoremail}</label>
          <span>*</span>
          <br />
          <Input
            type="text"
            placeholder="Enter Username"
            name="username"
            required
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            style={{
              border: ' 0.2px solid #C5C6D0',
              width: '490px',
              marginTop: '6px',
              marginBottom: '15px',
              height: '37px',
              borderRadius: '2.5px'
            }}
          />
          <br />
          <label htmlFor="password">{lang.label_password}</label>
          <span>*</span>
          <Input.Password
            type="password"
            placeholder="Enter Password"
            name="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              border: ' 0.2px solid #C5C6D0',
              width: '490px',
              marginTop: '6px',
              marginBottom: '15px',
              height: '37px',
              borderRadius: '2.5px',
              color: '#FFFFFF'
            }}
          />
          <label htmlFor="username">OTP</label>
          <br />
          <Input
            type="text"
            placeholder="Enter OTP"
            name="otp"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            style={{
              border: ' 0.2px solid #C5C6D0',
              width: '490px',
              marginTop: '6px',
              marginBottom: '15px',
              height: '37px',
              borderRadius: '2.5px'
            }}
            // required={user?.is_2fa_enabled == 1 ? true : false}
          />
          <br />
          {loading && (
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              {' '}
              <Spin color="red" />
            </div>
          )}

          <Button htmlType="submit" className={styles.loginButton} >
            <span style={{color: 'white'}}>{lang.label_login}</span>
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
