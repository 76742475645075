import react from "react";

import { Input, Button, Row, Col, message, Checkbox } from "antd";
import LangContext from "../../helps/contexts/lang-context";
import { useContext, useState, useEffect } from "react";
import PutApi from "../../helps/contexts/Api/PutApi";

const EditRoleForm = ({ onCancel, checklist, rowdata, getRoles }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  var id = rowdata.id;
  // form states
  const [checklistData, setChecklistData] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [rolename, setRoleName] = useState("");
  const [checked, setChecked] = useState({});
  const { Search } = Input;

  const onSearch = (value) => {
    setIsSearch(true);
    if (value) {
      let result = [];
      result = checklist.filter((data) => {
        return data.name.toLowerCase().search(value.toLowerCase()) !== -1;
      });
      setChecklistData(result);
    }
  };
  //onload of data
  useEffect(() => {
    if (rowdata && rowdata.name && rowdata.permissions) {
      setRoleName(rowdata.name);
      if (rowdata.permissions.length) {
        const obj = {};
        rowdata.permissions.forEach((perm, index) => {
          obj[perm.name] = true;
        });
        setChecked(obj);
      }
    } else {
      setRoleName("");
      setChecked({});
    }
  }, [rowdata]);

  //checkbox input onchange

  const handleCheck = (item, e) => {
    // setChecked((prevObj) => {
    //   prevObj[item.name] = e.target.checked===true ? false : true;
    //   return prevObj;
    // });
  
    setChecked((prevObj) => {
      // If the checkbox is checked, add the item
      if (e.target.checked) {
        return {
          ...prevObj,
          [item.name]: true
        };
      }
      // If the checkbox is unchecked, remove the item
      else {
        const updatedObj = { ...prevObj };
        delete updatedObj[item.name];
        return updatedObj;
      }
    });
  };

  //form submit to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const path = `/roles/update/${id}`;
      // sending request
      const res = await PutApi.PutApiRequest(path, {
        name: rolename,
        array_of_permissions: Object.keys(checked),
      });

      //if success
      if (res.status === true) {
        message.success("Role Updated Successfully");
        onCancel();
        getRoles();
      } else {
        message.error("Role Updation Failed");
      }
    } catch (e) {
      var message1 = e.message;

      alert(message1);
    }
  };

  return (
    <div
      style={{
        width: "97%",
        height: "85%",
        justifyContent: "space-evenly",
      }}
    >
      <p
        style={{
          color: "#A5A5A5",
          fontSize: "14px",
          marginTop: "-1%",
          marginBottom: "3%",
        }}
      >
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: "3%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            marginRight: "-6%",
          }}
        >
          <Row>
            <Col span={12}>
              <label style={{ paddingBottom: "5px" }}>{lang.label_name}</label>
              <span style={{ color: "red" }}>*</span>
              <br />

              <Input
                defaultValue="Role Name"
                style={{
                  width: "188%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                type="text"
                placeholder="Role Name"
                name="rolename"
                value={rolename}
                onChange={(e) => setRoleName(e.target.value)}
              />
            </Col>
          </Row>
          <br />
          <label style={{ paddingBottom: "5px" }}>
            {lang.label_permissions}
            <span style={{ color: "red" }}>*</span>
          </label>

          <div
            style={{
              padding: "15px",
              dispaly: "flex",
              backgroundColor: "#FAFAFA",
              width: "90%",
              height: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                padding: "20px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                  gap: "10px",
                }}
              >
                <Search
                  placeholder="input search text"
                  onSearch={onSearch}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Button
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "white",
                    color: "#004A7F",
                    width: "100px",
                  }}
                  onClick={() => {
                    setIsSearch(false);
                    setSearchText("");
                  }}
                >
                  {lang.label_reset}
                </Button>
              </div>
            </div>{" "}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "50% 50%",
              }}
            >
              {!isSearch &&
                checklist?.map((item, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", marginRight: "2%" }}
                  >
                    {/* <input
                      checked={checked[item.name]}
                      type="checkbox"
                      onChange={(e) => handleCheck(item, e)}
                    />
                    <span>{item.name}</span> */}
                    <Checkbox
                      checked={checked[item.name]}
                      type="checkbox"
                      onChange={(e) => handleCheck(item, e)}
                    >
                      <span key={index}>{item.name}</span>
                    </Checkbox>
                  </div>
                ))}

              {isSearch &&
                checklistData?.map((item, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", marginRight: "2%" }}
                  >
                    {/* <input
                      checked={checked[item.name]}
                      type="checkbox"
                      onChange={(e) => handleCheck(item, e)}
                    />
                    <span>{item.name}</span> */}
                    <Checkbox
                      checked={checked[item.name]}
                      type="checkbox"
                      onChange={(e) => handleCheck(item, e)}
                    >
                      <span key={index}>{item.name}</span>
                    </Checkbox>
                  </div>
                ))}
            </div>
          </div>

          <br />
          <br />
          <br />

          <div>
            {" "}
            <Button
              style={{
                borderRadius: "3px",
                backgroundColor: "#004A7F",
                color: "white",
                position: "absolute",
                right: "20%",
                top: "92%",
                width: "100px",
              }}
              htmlType="submit"
            >
              {lang.label_save}
            </Button>
            <Button
              style={{
                borderRadius: "3px",
                color: "#004A7F",
                position: "absolute",
                right: "6.5%",
                top: "92%",
                width: "100px",
              }}
              onClick={onCancel}
            >
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditRoleForm;
