import '../../styles/ButtonStyle.css';
import styles from './BetHistory.module.css';
import BetHistorytable from './BetHistorytable';
import moment from 'moment';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { Card, Row, Col, DatePicker, Button, Input, Pagination, Select, message, Tooltip, Progress, Spin } from 'antd';
import LangContext from '../../helps/contexts/lang-context';
import { useContext } from 'react';
import HeaderCardDetails from '../../components/HeaderDetailCard';
import Spacer from '../../components/HelperComponents/Spacer';
import { getBetHistory, getPlatformsForDropdown } from '../../helps/contexts/Api/api';
import { convertLocalTimeToUTC, convertUTCToLocalTime, toFixedFunc } from '../../helps/HelperFunctions';
import SelectBox from '../../components/HelperComponents/SelectBox';
import BetSumsTable from './BetSumsTable';
import { TabContext } from '../../helps/contexts/TabContext';
import { exportReportBet, exportReportPlayer } from '../../helps/contexts/Api/downloadFile';
import SubHeading from '../../components/HelperComponents/SubHeading';
import Heading from '../../components/HelperComponents/Heading';
import SearchIcon from '../../images/Search.png';
import ResetIcon from '../../images/Reset.png';
import { api } from '../../helps/contexts/Api/axios';
import Loader from '../../components/HelperComponents/Loader';
import { LoadingOutlined } from '@ant-design/icons';

const BetHistory = () => {
  const datePickerStyle = {
    width: '200px',
    height: '35px',
    background: ' #FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const iconStyle = { color: '#004A7F' };
  const inputStyle = {
    width: '200px',
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const selectStyle = {
    width: '200px',
    height: '35px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '3px'
  };

  //Language translation
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [activepage, setActivepage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [total, setTotal] = useState(0);
  const [totalBets, setTotalBets] = useState([]);

  // Filter States
  const [dateType, setDateType] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateFromString, setDateFromString] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [dateToString, setDateToString] = useState(null);
  const [userName, setUserName] = useState(null);
  const [gameName, setGameName] = useState(null);
  const [searchRefById, setSerachRefById] = useState();
  const [platformName, setPlatformName] = useState(0);
  const [platformsArray, setPlatformsArray] = useState([]);
  const [status, setStatus] = useState(null);
  const { path } = useContext(TabContext);
  const [filteredParams, setFilteredParams] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [progress, setProgress] = useState(0);
  const defaultDate = dayjs('00:00:00', 'HH:mm:ss'); // Default to today with time 00:00:00

  const downloadedFileIds = new Set();

  // console.log(path);
  const getAllPlatformsFunc = async () => {
    try {
      const token = localStorage.getItem('token');
      const res = await getPlatformsForDropdown({ token });
      if (res.status === 200) {
        const newArray = [...res.data];
        const newObject = { id: 0, value: 'Select Option.....' };
        const data = newArray.map((item) => {
          return {
            ...item,
            key: item.id,
            value: item.name
          };
        });
        data.unshift(newObject);
        setPlatformsArray(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Call API to get bet hiostory list
  useEffect(() => {
    getDataAPI({}, {}, 1);
    getAllPlatformsFunc();
  }, []);

  useEffect(() => {
    if (path === '/reports/bet-history') {
      getDataAPI({}, {}, 1);
      getAllPlatformsFunc();
    }
  }, [path]);

  // API Function
  const getDataAPI = async (params, customPage = null, page) => {
    const apiParams = {};
    if (params == 'reset') {
      apiParams['perPage'] = 10;
      if (customPage) apiParams['page'] = customPage;
      else apiParams['page'] = activepage;
    } else {
      apiParams['perPage'] = 10;
      apiParams['page'] = page;
      if (dateType) apiParams['dateType'] = dateType;
      if (dateFromString) apiParams['dateFrom'] = convertLocalTimeToUTC(dateFromString);
      if (dateToString) apiParams['dateTo'] = convertLocalTimeToUTC(dateToString);
      if (userName) apiParams['userName'] = userName;
      if (platformName) apiParams['game_platform_id'] = platformName;
      if (status) apiParams['status'] = status;
      if (gameName) apiParams['game_name'] = gameName;
      if (searchRefById) apiParams['round_reference'] = searchRefById;
    }
    const token = localStorage.getItem('token');
    setLoading(true);
    setFilteredParams(apiParams);

    try {
      const res = await getBetHistory(token, apiParams ? apiParams : '');
      if (res?.data?.records?.data) {
        const finalArray = res.data?.records?.data.map((user, index) => ({
          key: index,
          id: user.id,
          player_id: user?.player_id,
          round_reference: user.round_reference ? user.round_reference : '-',
          openedOn: convertUTCToLocalTime(user.started_on),
          closedOn: convertUTCToLocalTime(user.ended_on),
          platform: user.game_platform.name ? user.game_platform.name : '-',
          gameName: user.game_items
            ? user.game_items.map((e) => JSON.parse(e.name).en + `\n${user.game_items.length > 1 ? '\n' : ''}`)
            : '-',
          playerName: user.player.user.user_name ? user.player.user.user_name : '-',
          agentId: user?.player?.agent?.unique_code,
          winLoss: user?.win_loss ? toFixedFunc(user.win_loss, process.env.REACT_APP_Decimel_Points) : 0,
          // resultStatus: checkBetStatus(user.status),
          resultStatus: user?.status_name,
          totalBetAmount: user.bets_sum_total_bet_amount
            ? toFixedFunc(user.bets_sum_total_bet_amount, process.env.REACT_APP_Decimel_Points)
            : 0,
          totalTurnover: user.total_turnovers
            ? toFixedFunc(user.total_turnovers, process.env.REACT_APP_Decimel_Points)
            : 0,
          totalValidBet: user.total_valid_bets
            ? toFixedFunc(user.total_valid_bets, process.env.REACT_APP_Decimel_Points)
            : 0,
          totalWinAmount: user.total_win_amount
            ? toFixedFunc(user.total_win_amount, process.env.REACT_APP_Decimel_Points)
            : 0
        }));
        setData(finalArray);
        setTotal(res?.data?.records?.total);
        setPerpage(res?.data?.records?.per_page);

        const totalBetsArray = res.data?.sums.map((e, index) => ({
          key: index,
          total_valid_bets_sum: e.total_valid_bets_sum ? parseFloat(e.total_valid_bets_sum).toFixed(2) : '-',
          total_turnovers_sum: e.total_turnovers_sum ? parseFloat(e.total_turnovers_sum).toFixed(2) : '-',
          total_win_amount_sum: e.total_win_amount_sum ? parseFloat(e.total_win_amount_sum).toFixed(2) : '-',
          win_loss_sum: e.win_loss_sum ? parseFloat(e.win_loss_sum).toFixed(2) : '-',
          currency_name: e.currency_name ? e.currency_name : '-',
          base_win_loss: e.base_win_loss ? parseFloat(e.base_win_loss).toFixed(2) : '-',
          base_total_turnovers: e.base_total_turnovers ? parseFloat(e.base_total_turnovers).toFixed(2) : '-',
          base_total_valid_bets: e.base_total_valid_bets ? parseFloat(e.base_total_valid_bets).toFixed(2) : '-',
          base_total_win_amount: e.base_total_win_amount ? parseFloat(e.base_total_win_amount).toFixed(2) : '-'
        }));

        setTotalBets(totalBetsArray);
      } else {
        setData([]);
        setTotal(0);
        setTotalBets([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]);
      setTotal(0);
      setTotalBets([]);
    } finally {
      setLoading(false);
    }
  };

  // check bet status
  const checkBetStatus = (status) => {
    if (status == 1) {
      return 'Opened';
    } else if (status == 2) {
      return 'Closed';
    } else {
      return 'Re Closed';
    }
  };

  //on submit of filters
  const onSubmit = (e) => {
    e.preventDefault();
    setActivepage(1);
    getDataAPI({}, {}, 1);
  };

  // handle Reset
  const handleReset = () => {
    setGameName(null);
    setDateType(null);
    setActivepage(1);
    setDateFrom(null);
    setDateFromString(null);
    setDateTo(null);
    setDateToString(null);
    setUserName(null);
    setSerachRefById();
    setPlatformName(null);
    setStatus(null);
    getDataAPI('reset', null, null);
  };

  // On change page
  const onChangepage = (page) => {
    setActivepage(page);
    getDataAPI({}, {}, page);
  };

  // const exportData = async (payload) => {
  //   const params = {
  //     export_type: 1,
  //     export: 1,
  //     per_page: payload.per_page,
  //     page: payload.page,
  //     dateType: payload.dateType,
  //     date_to: payload.date_to,
  //     user_name: payload.user_name,
  //     game_platform_id: payload.game_platform_id,
  //     game_name: payload.game_name,
  //     round_reference: payload.round_reference,
  //     dateFrom: payload.dateFrom,
  //     dateTo: payload.dateTo,
  //     status: payload.status
  //   };

  //   try {
  //     const response = await exportReportBet(params);

  //     const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
  //     const blobUrl = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = blobUrl;
  //     a.download = 'data.xls'; // Adjust the filename as needed
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(blobUrl);
  //   } catch (error) {
  //     if (error.response.status === 503) {
  //       message.error('Exceeded 2000 row limit. Unable to export');
  //     } else {
  //       message.error(error);
  //     }
  //   }
  // };

  const exportData = async (payload) => {
    const params = {
      export_type: 0,
      export: 1,
      date_type: payload.dateType,
      date_to: payload.dateTo,
      date_from: payload.dateFrom,
      user_name: payload.userName,
      game_platform_id: payload.game_platform_id,
      game_name: payload.game_name,
      round_reference: payload.round_reference,
      status: payload.status
    };

    try {
      setLoadingData(true);
      const response = await exportReportBet(params);

      if (response?.data?.job_id) {
        const jobId = response.data.job_id;
        const totalFileCount = response.data.progress.total_file_count;

        const pollExportStatus = async (jobId) => {
          try {
            const statusResponse = await exportReportPlayer(jobId);

            const files = statusResponse?.data?.file || [];
            files.forEach((file) => {
              if (!downloadedFileIds.has(file.file_id)) {
                const base64String = file.file_content;
                const fileName = file.file_name + (file.file_type.includes('csv') ? '.csv' : '.xlsx');
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: file.file_type });

                const link = document.createElement('a');
                const blobUrl = URL.createObjectURL(blob);
                link.href = blobUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);

                // Mark the file as downloaded
                downloadedFileIds.add(file.file_id);
                // Optionally, you can keep track of downloaded files count
                // setDownloadedFiles((prev) => prev + 1);
              }
            });

            if (statusResponse?.data?.progress?.is_all_file_generated) {
              setLoadingData(false);
              message.success(`Export completed. ${totalFileCount} files downloaded.`);
            } else {
              setTimeout(() => pollExportStatus(jobId), 2000);
            }
          } catch (error) {
            setLoadingData(false);
            message.error('Failed to check export status.');
          }
        };

        pollExportStatus(jobId);
      } else {
        setLoadingData(false);
        message.error('Failed to initiate export.');
      }
    } catch (error) {
      setLoadingData(false);
      message.error('Failed to initiate export.');
    }
  };

  return (
    <>
      <HeaderCardDetails />

      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div>
          <div className={styles.container}>
            <div
              style={{ display: 'flex', justifyContent: 'space-between', padding: '7px 10px' }}
              className={styles.filterUpperContainer}>
              <Heading>{lang.label_filters}</Heading>
              <div>
                {loadingData ? (
                  <div style={{ paddingRight: '60px' }}>
                    <Spin indicator={<LoadingOutlined spin />} size="large" />
                  </div>
                ) : (
                  <div>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="btnStyle"
                      style={{
                        backgroundColor: '#004A7F',
                        color: 'white',
                        width: '125px',
                        height: '35px',
                        background: '#004A7F 0% 0% no-repeat padding-box',
                        borderRadius: '3px',
                        opacity: '1',
                        marginRight: '15px'
                      }}
                      onClick={() => exportData(filteredParams)}>
                      Export
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.filterLowerContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  width: '100%',
                  paddingRight: '14px',
                  flexWrap: 'wrap'
                }}>
                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.lable_datetype}
                  </SubHeading>
                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-around',
                      alignItems: 'center'
                    }}>
                    <Select
                      style={selectStyle}
                      placeholder="Select Time"
                      onChange={(value) => setDateType(value)}
                      value={dateType}
                      options={[
                        {
                          value: 'start',
                          label: 'Bet Start Time'
                        },
                        {
                          value: 'end',
                          label: 'Bet End Time'
                        }
                      ]}
                    />
                    <span
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        marginLeft: '1%',
                        marginRight: '1%'
                      }}>
                      -
                    </span>
                    <DatePicker
                      placeholder={lang.label_selecttime}
                      showTime={{
                        defaultValue: dayjs('00:00:00', 'HH:mm:ss')
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                      //getting input value for start date
                      value={dateFrom}
                      onChange={(value, dateString) => {
                        setDateFrom(value);
                        setDateFromString(dateString);
                      }}
                    />
                    <span
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        marginLeft: '1%',
                        marginRight: '1%'
                      }}>
                      -
                    </span>
                    <DatePicker
                      placeholder={lang.label_selecttime}
                      showTime={{
                        defaultValue: dayjs('23:59:59', 'HH:mm:ss')
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                      style={datePickerStyle}
                      value={dateTo}
                      //getting input value for end date
                      onChange={(value, dateString) => {
                        setDateTo(value);
                        setDateToString(dateString);
                      }}
                    />
                  </div>
                </div>
                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_playername}
                  </SubHeading>
                  <Input
                    //getting input value for search
                    placeholder="Player Name"
                    value={userName}
                    style={inputStyle}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                  />{' '}
                </div>
                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_platformname}
                  </SubHeading>
                  {/* <Input
                      //getting input value for ip
                      placeholder="Platform"
                      value={platformName}
                      style={inputStyle}
                      onChange={(e) => {
                        setPlatformName(e.target.value);
                      }}
                    /> */}
                  <SelectBox
                    style={selectStyle}
                    name="bank"
                    value={platformName}
                    onChange={(e) => {
                      return setPlatformName(e);
                    }}
                    data={platformsArray}
                  />
                </div>
                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_status}
                  </SubHeading>

                  <Select
                    style={selectStyle}
                    placeholder="Status"
                    value={status}
                    onChange={(value) => setStatus(value)}
                    options={[
                      {
                        value: null,
                        label: 'All'
                      },
                      {
                        value: 1,
                        label: 'Opened'
                      },
                      {
                        value: 2,
                        label: 'Re Opened'
                      },
                      {
                        value: 3,
                        label: 'Closed'
                      },
                      { value: 4, label: 'Re Closed' }
                    ]}
                  />
                </div>

                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_gamename}
                  </SubHeading>
                  <Input
                    placeholder={lang.label_gamename}
                    value={gameName}
                    style={inputStyle}
                    onChange={(e) => {
                      setGameName(e.target.value);
                    }}
                  />
                </div>

                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_referenceid}
                  </SubHeading>
                  <Input
                    placeholder={lang.label_referenceid}
                    value={searchRefById}
                    style={inputStyle}
                    onChange={(e) => {
                      setSerachRefById(e.target.value);
                    }}
                  />
                </div>
                <div style={{ display: 'flex', marginTop: '19px' }}>
                  <Button
                    htmlType="submit"
                    className="btnStyle"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1',
                      marginRight: '10px'
                    }}>
                    <img src={SearchIcon} alt="search" />
                    {lang.label_search}
                  </Button>

                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      borderRadius: '3px',
                      padding: '6px',
                      border: '1px solid grey',
                      width: '84px'
                    }}
                    className="btnReset"
                    onClick={() => handleReset()}>
                    <img src={ResetIcon} alt="reset" />
                    {lang.label_reset}
                  </Button>
                </div>

                {/* reference ID */}
              </div>
            </div>
          </div>
          <Spacer />
          <Spacer />
          <BetHistorytable data={data} loading={loading} getData={getDataAPI} />
          <Spacer />
          <Pagination
            showSizeChanger={false}
            defaultCurrent={1}
            current={activepage}
            onChange={onChangepage}
            total={total}
            responsive={true}
            pageSize={perpage}
          />
          <Spacer />
          <Spacer />
          <Spacer />
          <BetSumsTable data={totalBets} loading={loading} />
        </div>
      </form>
    </>
  );
};

export default BetHistory;
