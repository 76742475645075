import React, { useContext, useState } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import styles from './TagManagementFilters.module.css';
import Heading from '../../../components/HelperComponents/Heading';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import { Button, Input, InputNumber, Select } from 'antd';
import dayjs, { utc } from 'dayjs';
import { DatePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import SearchIcon from "../../../images/Search.png";
import ResetIcon from "../../../images/Reset.png";
const { RangePicker } = DatePicker;
dayjs.extend(utc);

export default function TagManagementFilters({ setFilters, onRefresh, open }) {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { hasPermission } = useCheckPermission();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    key: 'tag_name'
  });

  const formatDate = (dayjsObj) => {
    return dayjs.utc(dayjsObj).format('YYYY-MM-DD HH:mm:ss');
  };
  const onReset = () => {
    onRefresh();
    setFormData({
      key: 'tag_name'
    });
  };

  const onSubmit = () => {
    setFilters(formData);
  };

  const inputStyle = {
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const selectStyle = {
    height: '33px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '3px'
  };

  return (
    <div className={styles.container}>
      <div className={styles.filterUpperContainer}>
        <Heading>{lang.label_filters}</Heading>
        <div
          style={{
            display: 'flex',

            alignItems: 'center',
            gap: '20px',
            marginLeft: 'auto',
            paddingRight: '14px'
          }}>
          <div className={styles.flexStyle}>
            {hasPermission('View Adjust Tag History') && (
              <Button
                style={{
                  backgroundColor: '#004A7F',
                  color: 'white',
                  minWidth: '125px',
                  border: '1px solid grey'
                }}
                className="btnStyle"
                onClick={() => navigate('/player-management/tag-management/tag-adjustment-history')}>
                {lang.label_tags_adjustment_history}
              </Button>
            )}
          </div>

          <div className={styles.flexStyle}>
            {hasPermission('Create Tags') && (
              <Button
                style={{
                  backgroundColor: '#004A7F',
                  color: 'white',
                  width: '125px',
                  border: '1px solid grey'
                }}
                className="btnStyle"
                onClick={() => open()}>
                {lang.label_add_tag}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.filterLowerContainer}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            paddingRight: '14px',
            flexWrap: 'wrap'
          }}>
      
            <div className={styles.flexStyle}>
              <div
                style={{
                  width:"100%"
                }}>
                <SubHeading
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500
                  }}>
                  {lang.label_tag_type}
                </SubHeading>
              </div>
              <Select
                placeholder="Select type"
                style={{ width: 150, ...selectStyle }}
                // allowClear
                // defaultValue={'tag_name'}
                value={formData?.key}
                onChange={(val) => setFormData((prevData) => ({ ...prevData, key: val, search: '' }))}
                // onClear={() => setFormData((prevData) => ({ ...prevData, category_id: null }))}
                options={[
                  {
                    value: 'tag_name',
                    label: 'Tag Name'
                  },
                  {
                    value: 'updated_by',
                    label: 'Updated By'
                  }
                ]}
              />
            </div>
    
            <div className={styles.flexStyle}>
              <div
                style={{
                  width:"100%"
                }}>
                <SubHeading
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500
                  }}>
                  {lang.label_search}
                </SubHeading>
              </div>
              <Input
                style={{
                  width: '150px',
                  ...inputStyle
                }}
                placeHolder="Enter text"
                value={formData?.search}
                onChange={(e) => setFormData((prevData) => ({ ...prevData, search: e.currentTarget.value }))}
              />
            </div>
       
          <div>
            <div className={styles.flexStyle}>
              <div
                style={{
                  width:"100%"
                }}>
                <SubHeading
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500
                  }}>
                  {lang.label_updating_time}
                </SubHeading>
              </div>
              <RangePicker
                value={formData ? formData.dates : []}
                showTime={{ defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss'),] }}
                onChange={(x) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    start_date: x ? formatDate(x[0]) : null,
                    end_date: x ? formatDate(x[1]) : null,
                    dates: x
                  }));
                }}
              />
            </div>
          </div>

          <div className={styles.flexStyle}
          style={{
            marginTop :'22px',
          }}
          >
            <Button
              style={{
                // borderRadius: "3px",
                // padding: "6px",
                // width: "84px",
                // marginRight: '0.5rem',
                // marginLeft: '0.5rem',
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'space-evenly',
                backgroundColor: '#004A7F',
                color: 'white',
                width: '125px',
                border: '1px solid grey'
              }}
              className="btnStyle"
              onClick={onSubmit}>
                <img src={SearchIcon} alt='search' />
              {lang.label_search}
            </Button>
          </div>
          <div className={styles.flexStyle}
             style={{
              marginTop :'22px',
            }}
          >
            <Button
              onClick={onReset}
              type="secondary"
              style={{
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'space-evenly',
                borderRadius: '3px',
                padding: '6px',
                border: '1px solid grey',
                width: '84px'
              }}
              className="btnReset">
                <img src={ResetIcon} alt='reset' />
              {lang.label_reset}
            </Button>
          </div>
        </div>
        {/* <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            marginLeft: 'auto',
            paddingRight: '14px'
          }}>
          <div className={styles.flexStyle}>
            {hasPermission('View Adjust Tag History') && (
              <Button
                style={{
                  backgroundColor: '#004A7F',
                  color: 'white',
                  minWidth: '125px',
                  border: '1px solid grey'
                }}
                className="btnStyle"
                onClick={() => navigate('/player-management/tag-management/tag-adjustment-history')}>
                {lang.label_tags_adjustment_history}
              </Button>
            )}
          </div>

          <div className={styles.flexStyle}>
            {hasPermission('Create Tags') && (
              <Button
                style={{
                  backgroundColor: '#004A7F',
                  color: 'white',
                  width: '125px',
                  border: '1px solid grey'
                }}
                className="btnStyle"
                onClick={() => open()}>
                {lang.label_add_tag}
              </Button>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
}
