import { api } from './axios';

const getConfig = (contentType = 'application/vnd.ms-excel', params = {}) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': contentType,
      Accept: contentType // Ensures the response type matches the content type
    },
    params: params,
    responseType: 'blob' // Important for downloading files
  };
};

const getConfig1 = (contentType = 'application/json', params = {}) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': contentType
      // Accept: contentType // Ensures the response type matches the content type
    },
    params: params
  };
};

// Export for bethistory page

export const exportReportBet = (payload) => {
  // console.log(payload);
  return api.get(`/bet_rounds/list`, getConfig1('application/json', payload));
};

export const exportReportPlayer = (payload) => {
  return api.get(`/bet_rounds/export/${payload}`, getConfig1('application/json'));
};

// player balance history

export const exportReportPlayerBalance1 = (payload) => {
  return api.get(
    `/admin/players/balance/history`,
    getConfig1('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', payload)
  );
};

export const exportReportPlayerBalance2 = (payload) => {
  return api.get(`/admin/players/export/${payload}`, getConfig1('application/json'));
};

// Deposit

export const exportReportDeposit1 = (payload) => {
  return api.get(
    `/admin/transactions?isWithdraw=0`,
    getConfig1('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', payload)
  );
};

export const exportReportDeposit2 = (payload) => {
  return api.get(`/admin/transactions/export/${payload}`, getConfig1('application/json'));
};

// withdraw

export const exportReportWithdraw1 = async (payload) => {
  try {
    const response = await api.get(
      `/admin/transactions?isWithdraw=1`,
      getConfig1('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', payload)
    );

    if (!response.data.success) {
      // console.error('Error in exportReportWithdraw1:', response.data.message);
      throw new Error(response.data.message);
    }

    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const exportReportWithdraw2 = async (payload) => {
  try {
    const response = await api.get(`/admin/transactions/export/${payload}`, getConfig1('application/json'));

    if (!response.data.success) {
      // console.error('Error in exportReportWithdraw2:', response.data.message);
      throw new Error(response.data.message);
    }

    return response;
  } catch (error) {
    // console.error('Error in exportReportWithdraw2:', error.message);
    throw new Error('Failed to fetch export status. Please try again.');
  }
};


// Export for player list

export const exportPlayerList = (payload) => {
  // console.log(payload);
  return api.get(`/admin/players`, getConfig1('application/json', payload));
};

export const exportPlayerList1 = (payload) => {
  return api.get(`/admin/players/export/${payload}`, getConfig1('application/json'));
};


// player balance history

export const exportReportPromotionHistory = (payload) => {
  return api.get(
    `/admin/players/balance/history`,
    getConfig1('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', payload)
  );
};

export const exportReportPromotionHistory2 = (payload) => {
  return api.get(`/admin/players/export/${payload}`, getConfig1('application/json'));
};
