const AddCommasAndLength = val => {
  if (val != null && val.toString().includes('.')) {
    const formattedValue = Number(val).toFixed(2);
    return formattedValue.replace(/\B(?=(\d{3})+(?=\.\d{2}$))/g, ",");
  } else {
    if(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d|\.\d+$))/g, ",");
    }
  } 
  return val
}

export default AddCommasAndLength