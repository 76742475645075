import { useEffect } from 'react';
import { Input, Button, Row, Col, Switch, Upload, Select, message } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState } from 'react';
import PostApi from '../../../helps/contexts/Api/PostApi';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import GetApi from '../../../helps/contexts/Api/GetApi';
import ErrorsHandlingComp from '../../../helps/errorsHandlingComp';
import Loader from '../../../components/HelperComponents/Loader';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';

const AddCompanyBankPopup = ({ handleCancel, fetchCompanyBanks }) => {
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();

  const lang = ctx.lang;
  // form states

  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState();
  const [balance, setBalance] = useState('');
  const [bankImg, setBankImg] = useState(null);
  const [apiKey, setApiKey] = useState('');
  const [callbackKey, setCallbackKey] = useState('');
  const [remark, setRemark] = useState('');
  const [deposit, setDeposit] = useState(0);
  const [withdrawl, setWithdrawl] = useState(0);
  const [bankList, setBankList] = useState([]);
  const [bankName, setBankname] = useState('Please select a bank');
  const [bankimgname, setbankimgname] = useState('');
  const [allCurrency, setAllCurrency] = useState([]);
  const [currency, setcurrency] = useState('Please Select currency');
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const { TextArea } = Input;
  const { Option } = Select;

  //form value bank select
  function handleChange(value) {
    setBankname(value);
    delete errors.bank_code;
  }
  const handleCurrency = (value) => {
    setcurrency(value);
    delete errors.currency;
  };

  //switch deposit
  const onChangeSwitch1 = (checked) => {
    if (checked === true) {
      setDeposit(1);
    } else if (checked === false) {
      setDeposit(0);
    }
  };
  //switch withdrawl
  const onChangeSwitch2 = (checked) => {
    setWithdrawl(checked);

    if (checked === true) {
      setWithdrawl(1);
    } else if (checked === false) {
      setWithdrawl(0);
    }
  };
  const dummyRequest = (file, onSuccess) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  const handleDelete = () => {
    setBankImg('');
    setbankimgname('');
  };

  //file upload

  const props = {
    name: 'file',
    onChange(e) {
      setBankImg(e.file);
      setbankimgname(e.file.name);
      if (e.file.type === 'image/jpg' || e.file.type === 'image/jpeg' || e.file.type === 'image/png') {
      }
      const isLt2M = e.file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        message.error('File size must smaller than 20MB!!');
        return;
      }
    }
  };
  const getCurrencyList = async () => {
    try {
      const res = await GetApi.sendApiRequest('/player/currencies');
      if (res) {
        const object1 = {
          key: 'Please Select currency',
          value: 'Please Select currency',
          label: 'Please Select currency'
        };
        const finalArray = Object.keys(res).map((key, index) => {
          return {
            key: Number(key),
            value: Number(key),
            label: res[key]
          };
        });
        finalArray.unshift(object1);
        setAllCurrency(finalArray);
      } else {
        return null;
      }
    } catch (e) {}
  };
  const getBankList = async () => {
    try {
      const res = await GetApi.sendApiRequest('/user/bank/list');

      if (res) {
        const object1 = {
          value: 'Please select a bank',
          label: 'Please select a bank'
        };
        const objArray1 = Object.keys(res);

        const finalArray = objArray1.map((v, index) => ({
          key: index,
          value: v,
          label: res[v]
        }));
        finalArray.unshift(object1);

        setBankList(finalArray);
      } else {
        return null;
      }
    } catch (e) {}
  };
  useEffect(() => {
    getBankList();
    getCurrencyList();
  }, []);

  //form submit to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (
        !Object.keys(errors).length &&
        bankName !== 'Please select a bank' &&
        currency !== 'Please Select currency' &&
        bankName !== null &&
        bankName !== undefined &&
        bankName !== '' &&
        currency !== null &&
        currency !== undefined &&
        currency !== ''
      ) {
        setLoader(true);
        const path = `/company/banks/store`;
        // sending request
        const res = await PostApi.PostApiRequest(path, {
          bank_code: bankName,
          bank_holder_name: accountName,
          bank_account_number: accountNumber,
          balance: balance,
          bank_image: bankImg,
          api_key: apiKey,
          callback_key: callbackKey,
          allow_deposit: deposit,
          allow_withdraw: withdrawl,
          remark: remark,
          currency: currency
        });

        //if success
        if (res?.status === 200) {
          message.success(lang.label_addbanksuccess);
          setBankname('Please select a bank');
          setAccountName('');
          setAccountNumber('');
          setBalance('');
          setBankImg('');
          setApiKey('');
          setCallbackKey('');
          setDeposit('');
          setWithdrawl('');
          setRemark('');
          setbankimgname('');
          setcurrency('Please Select currency');
          fetchCompanyBanks();
          handleCancel();
          setLoader(false);
        } else if (!res) {
          message.error(lang.label_addbankfail);
        }
      } else {
        if (bankName === 'Please select a bank' || bankName == null || bankName === undefined || bankName === '') {
          setErrors({ ...errors, bank_code: 'Bank is required' });
        } else if (
          currency === 'Please Select currency' ||
          currency == null ||
          currency === undefined ||
          currency === ''
        ) {
          setErrors({ ...errors, currency: 'Currency is required' });
        } else {
        }
        setLoader(false);
      }
    } catch (e) {
      if (e?.status === 422) {
        message.error(lang.label_addbankfail);
        Object.values(e.data.message).map((errors) => message.error(errors));
      }
      setLoader(false);
    }
  };

  return (
    <div
      style={{
        width: '97%',
        height: '85%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: '3%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '-6%'
          }}>
          <Row>
            <Col span={12}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_bankname}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />
              <Select
                required
                value={bankName}
                style={{ width: '85%', marginTop: '2px', right: '0.5%' }}
                onChange={handleChange}>
                {bankList.map((bank, index) => (
                  <Option key={index} value={bank.value}>
                    {bank.label}
                  </Option>
                ))}
              </Select>
              <ErrorsHandlingComp errors={errors} name="bank_code" />
            </Col>
            <Col span={12}>
              <label>
                {lang.label_accountname}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <br />

              <Input
                required
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_accountnumber}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <Input
                required
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </Col>
            <Col span={12}>
              <label>
                {lang.label_balance}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <br />

              <Input
                required
                value={balance}
                onChange={(e) => setBalance(e.target.value)}
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '93%' }}>
              <label>{lang.label_bankimage}</label>
              {/* <span style={{ color: "red" }}>*</span> */}
              <br />{' '}
              <div className="customUploader">
                {!bankimgname ? (
                  <Input disabled className="uploaderInput" placeholder="upload image" />
                ) : (
                  <Input
                    value={bankimgname}
                    className="uploaderInput"
                    addonAfter={<DeleteOutlined onClick={handleDelete} />}
                  />
                )}
                <Upload {...props} beforeUpload={() => false} customRequest={() => dummyRequest} maxCount={1}>
                  <Button
                    className="custumBtn"
                    icon={<UploadOutlined style={{ color: 'white' }} />}
                    style={{
                      backgroundColor: '#004A7F',
                      color: 'white',
                      borderColor: '#004A7F',
                      width: '100% !important'
                    }}>
                    {lang.label_upload}
                  </Button>
                </Upload>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <label>{lang.label_currency}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />
              <Select
                required
                value={currency}
                onChange={handleCurrency}
                style={{
                  width: '93%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                options={allCurrency}

                // options={[
                //   {
                //     value: 4,
                //     label: "VND",
                //   },
                //   {
                //     value: 24,
                //     label: "INR",
                //   },
                //   {
                //     value: 11,
                //     label: "PHP",
                //   },
                // ]}
              />
              <ErrorsHandlingComp errors={errors} name="currency" />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_apikey}</label>

              <br />

              <Input
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
              />
            </Col>
            <Col span={12}>
              <label>{lang.label_callbackkey}</label>
              <br />

              <Input
                value={callbackKey}
                onChange={(e) => setCallbackKey(e.target.value)}
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
              />
            </Col>
          </Row>
          <br />
          {hasPermission('Company Bank Deposit Toggle') && (
            <Row>
              <Col span={24}>
                <label>{lang.label_deposit}</label>

                <Switch style={{ marginLeft: '19px' }} onChange={onChangeSwitch1} checked={deposit} />
              </Col>
            </Row>
          )}
          <br />
          {hasPermission('Company Bank Withdraw Toggle') && (
            <Row>
              <Col span={24}>
                <label>{lang.label_withdraw}</label>

                <Switch style={{ marginLeft: '10px' }} onChange={onChangeSwitch2} checked={withdrawl} />
              </Col>
            </Row>
          )}

          <br />
          <Row>
            <Col span={12}>
              <label>{lang.label_remark}</label>

              <br />

              <>
                <TextArea
                  showCount
                  maxLength={100}
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                  style={{
                    width: '186%',
                    height: '100px',
                    borderRadius: '3px',
                    marginBottom: '5px',
                    marginTop: '5px'
                  }}
                  value={remark}
                />
              </>
            </Col>
          </Row>
          <br />
          <br />
          <br />

          <div style={{}}>
            {loader ? (
              <Loader />
            ) : (
              <Button
                style={{
                  position: 'absolute',
                  right: '20%',
                  top: '93%'
                }}
                className="btnStyle"
                htmlType="submit">
                {lang.label_submit}
              </Button>
            )}

            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                position: 'absolute',
                right: '7%',
                top: '93%',
                width: '12%',
                height: '5%'
              }}
              onClick={() => {
                setBankname('Please select a bank');
                setAccountName('');
                setAccountNumber('');
                setBalance('');
                setBankImg('');
                setApiKey('');
                setCallbackKey('');
                setDeposit('');
                setWithdrawl('');
                setRemark('');
                setbankimgname('');
                handleCancel();
              }}>
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCompanyBankPopup;
