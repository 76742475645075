import { Switch } from 'antd';
import React from 'react';

const SwitchWithLabel = ({value, onChange, label}) => {
  return (
    <div style={{marginTop:"10px"}}>
         <label style={{display:"block"}}>
            {label}
        </label>
        <Switch checked={value} onChange={onChange} />
    </div>
        
    )
}

export default SwitchWithLabel