import React from 'react'
import {  Pagination  } from 'antd';

const PaginationComp = ({paginationConfig}) => {
  return (
    <div className="pagination-container">
    <Pagination {...paginationConfig} />  
        </div>
    

  )
}

export default PaginationComp