import React, { useState } from 'react';
import { Dropdown, Modal, Table, Tag, message } from 'antd';
import { useContext } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import { IoMenu } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import DeleteNotification from './DeleteNotification';
import AddAgentNotification from './AddAgentNotification';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';

const AgentNotificationTable = ({ data, loading, getNotification }) => {
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();
  const [rowdata, setRowdata] = useState({});
  const lang = ctx.lang;
  const [edit, setEdit] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState(false);
  //menu items in table

  const items = [
    {
      key: 1,
      label: 'Edit Message'
    },
    {
      key: 2,
      label: 'Delete'
    }
  ];
  //onclick of option in table
  const onClick = ({ key }) => {
    if (key == 1) {
      handleEditMessage();
    } else if (key == 2) {
      handleDeleteMessage();
    }
  };
  const columns = [
    {
      title: lang.label_agent,
      dataIndex: 'agent',
      key: 'agent',
      render: (item, record) => (
        <>
          {record?.agent.map((agent) => (
            <Tag color={agent?.is_read ? 'green' : 'red'} style={{ marginTop: 5 }}>
              {agent?.agent?.user?.user_name}
            </Tag>
          ))}
        </>
      )
    },
    {
      title: lang.label_notification,
      dataIndex: 'notification',
      key: 'notification',
      render: (text, record) => (
        <>
          <div>
            <strong>{record.notificationTitle}</strong>
          </div>
          <div>{text ?? '-'}</div>
        </>
      )
    },
    // {
    //   title: lang.label_read,
    //   dataIndex: "read",
    //   key: "read",
    // },
    {
      title: lang.label_createdby,
      dataIndex: 'createdby',
      key: 'createdby',
      render: (item, record) => (
        <>
          <Tag color="green">{record?.createdby}</Tag>
        </>
      )
    },
    {
      title: lang.label_createdat,
      dataIndex: 'createdat',
      key: 'createdat'
    }
  ];

  if (hasPermission('Create Agent Notification')) {
    columns.push({
      title: lang.label_action,
      key: 'action',
      render: (_, record) => {
        return (
          <Dropdown
            placement="bottom"
            menu={{
              items,
              onClick
            }}>
            <a href="#" style={{ color: '#004a7f' }} onClick={(e) => e.preventDefault()}>
              <IoMenu />
            </a>
          </Dropdown>
        );
      }
    });
  }

  //for closing the popup
  const handleCancel = () => {
    setEdit(false);
  };

  const handleCloseDeleteMsg = () => {
    setDeleteMsg(false);
  };

  //for onclick of options in table

  const handleEditMessage = () => {
    setEdit(true);
  };

  const handleDeleteMessage = () => {
    setDeleteMsg(true);
  };

  return (
    <>
      {' '}
      <Table
        loading={loading}
        columns={columns}
        bordered
        dataSource={data}
        pagination={false}
        size="small"
        onRow={(record) => {
          return {
            onClick: () => {
              setRowdata(record);
            }
          };
        }}
      />
      {edit && edit ? (
        <Modal
          closeIcon={null}
          title="Edit Message "
          onOk={() => {
            setEdit(true);
          }}
          open={edit}
          onCancel={handleCancel}
          footer={null}
          width={400}
          color={'#004A7F'}>
          {/* <EditPlayerNotification rowdata={rowdata} onCancel={handleCancel} /> */}
          <AddAgentNotification
            rowdata={rowdata}
            onCancel={handleCancel}
            type="edit"
            getNotification={getNotification}
          />
        </Modal>
      ) : (
        ''
      )}
      {deleteMsg && deleteMsg ? (
        <Modal
          closeIcon={null}
          title="Are you sure you want to delete this notification?"
          onOk={() => {
            setDeleteMsg(true);
          }}
          open={deleteMsg}
          onCancel={handleCloseDeleteMsg}
          footer={null}
          width={400}
          color={'#004A7F'}>
          <DeleteNotification
            handleCloseDeleteMsg={handleCloseDeleteMsg}
            id={rowdata?.id}
            getNotification={getNotification}
          />
        </Modal>
      ) : (
        ''
      )}
    </>
  );
};

export default AgentNotificationTable;
