import { Input, Button, Row, Col, Switch, Upload, Select, message } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState, useEffect } from 'react';
import GetApi from '../../../helps/contexts/Api/GetApi';
import PostApi from '../../../helps/contexts/Api/PostApi';
import SelectBox from '../../../components/HelperComponents/SelectBox';
import Loader from '../../../components/HelperComponents/Loader';

const AdjustCompanyBankPopup = ({ handleCancel, fetchCompanyBanks }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  // form states

  const [transactionType, setTransactionType] = useState(2);
  const [amount, setAmount] = useState();
  const [remark, setRemark] = useState('');
  const { TextArea } = Input;
  const [bankList, setBankList] = useState([]);
  const [bankselect, setBankselect] = useState('--Select A Bank--');
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const { Option } = Select;

  //form value bank select
  function handleChange(value) {
    setBankselect(value);
  }
  useEffect(() => {
    getBankList();
  }, []);
  // listing bank

  const getBankList = async () => {
    try {
      const res = await GetApi.sendApiRequest('/company/banks');

      if (res && res.length) {
        const newArray = [...res];
        const newObject = { id: '--Select A Bank--', value: '--Select A Bank--' };
        const data = newArray.map((bank) => {
          return {
            ...bank,
            key: bank.id,
            value: `${bank.bank_code}-${bank.bank_holder_name}`
          };
        });
        data.unshift(newObject);
        setBankList(data);
      }
    } catch (e) {}
  };

  const onChangetranstype = (value) => {
    setTransactionType(value);
  };

  //form submit to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const path = `/company/banks/adjust`;
      if (
        Object.keys(errors).length === 0 &&
        bankselect !== '--Select A Bank--' &&
        amount &&
        (transactionType === 0 || transactionType === 1)
      ) {
        // sending request
        setLoader(true);
        const res = await PostApi.PostApiRequest(path, {
          amount: amount,
          company_bank_id: bankselect,
          isWithdraw: transactionType,
          remark: remark
        });

        //if success
        if (res?.status === 200) {
          setLoader(false);
          message.success(lang.label_adjcreation);
          setBankselect('--Select A Bank--');
          setAmount('');
          setTransactionType('');
          setRemark('');
          fetchCompanyBanks();
          handleCancel();
        } else if (!res) {
          message.error(lang.label_adjcreationfail);
        }
      } else {
        if (!bankselect || bankselect === '--Select A Bank--') {
          setErrors({ ...errors, bankError: 'Kndlly Select Bank' });
        } else if (transactionType !== 0 && transactionType !== 1) {
          setErrors({ ...errors, transactionTypeError: 'Kndlly Select TransactionType' });
        }
      }
    } catch (e) {
      if (e.data.message === 'LACK_BALANCE_CANT_WITHDRAW') {
        setErrors({ ...errors, amountError: 'Bank dont have enough balance to withdraw this amount.' });
      }
      if (e?.status === 422) {
        message.error(lang.label_adjcreationfail);
        Object.values(e.data.message).map((errors) => message.error(errors));
      }
      setLoader(false);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Row>
            <Col span={24}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_bankname}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />
              {/* <Select
              required
                defaultValue={""}
                style={{ width: "93%", marginTop: "2px", right: "0.5%" }}
                onChange={handleChange}
              >
                {bankList.map((bank, index) => (
                  <Option key={index} value={bank.key}>
                    {bank.bankname}-{bank.bankholdername}
                  </Option>
                ))}
              </Select> */}
              <SelectBox
                name="bank"
                value={bankselect}
                onChange={(e) => {
                  delete errors.bankError;
                  return setBankselect(e);
                }}
                data={bankList}
                customWidth="100%"
              />

              {errors?.bankError && <span style={{ color: 'red' }}>{errors?.bankError}</span>}
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <label> {lang.label_transactiontype}</label>
              <span style={{ color: 'red' }}>*</span> <br />
              {/* <Select
              required
                defaultValue="1"
                value={transactionType}
                style={{
                  width: "186%",
                  borderRadius: "3px",
                  color: "green",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                onChange={onChangetranstype}
                options={[
                  {
                    value: 1,
                    label: lang.label_withdraw,
                  },
                  {
                    value: 0,
                    label:lang.label_deposit,
                  },
                ]}
              /> */}
              <SelectBox
                name="transactionType"
                value={transactionType}
                onChange={(e) => {
                  delete errors.transactionTypeError;
                  return setTransactionType(e);
                }}
                data={[
                  { id: 2, value: 'Select Option.....' },
                  {
                    id: 1,
                    value: lang.label_withdraw
                  },
                  {
                    id: 0,
                    value: lang.label_deposit
                  }
                ]}
                customWidth="100%"
              />
              {errors?.transactionTypeError && <span style={{ color: 'red' }}>{errors?.transactionTypeError}</span>}
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_amount}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <Input
                required
                style={{
                  width: '100%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                value={amount}
                onChange={(e) => {
                  delete errors.amountError;
                  setAmount(e.target.value);
                }}
              />
              {errors?.amountError && <span style={{ color: 'red' }}>{errors?.amountError}</span>}
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <label>{lang.label_remark}</label>

              <br />

              <>
                <TextArea
                  showCount
                  maxLength={100}
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                  style={{
                    width: '100%',
                    height: '100px',
                    borderRadius: '3px',
                    marginBottom: '5px',
                    marginTop: '5px'
                  }}
                  value={remark}
                />
              </>
            </Col>
          </Row>
          <br />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: '2rem'
            }}>
            {loader ? (
              <Loader />
            ) : (
              <Button
                style={{
                  borderRadius: '3px',
                  backgroundColor: '#004A7F',
                  color: 'white'
                }}
                htmlType="submit">
                {lang.label_submit}
              </Button>
            )}

            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                marginLeft: '0.6rem'
              }}
              onClick={() => {
                setBankselect('--Select A Bank--');
                setAmount('');
                setTransactionType('');
                setRemark('');
                handleCancel();
              }}>
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdjustCompanyBankPopup;
