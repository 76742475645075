import { Button, Table, Modal } from 'antd';
import { MdEdit } from 'react-icons/md';
import { AiOutlineDelete } from 'react-icons/ai';
import EditRoleForm from '../../components/forms/EditRoleForm';
import DeleteRoleForm from '../../components/forms/DeleteRoleForm';
import LangContext from '../../helps/contexts/lang-context';
import { useContext, useState } from 'react';
import useCheckPermission from '../../helps/hooks/useCheckPermission';

const PermissionsTable = ({ data, checklist, loading, getRoles }) => {
  const [isEdit, setIsedit] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState({});
  const { hasPermission } = useCheckPermission();

  // Edit role popup

  const handleCancel = (e) => {
    setIsedit(false);
    setDelete(false);
    setVisible(false);
  };
  const columns = [
    {
      title: lang.label_name,
      dataIndex: 'name',
      key: 'name'
    },
    ...(hasPermission('Add Permission')
      ? [
          {
            title: lang.label_action,
            key: 'action',
            render: (text, record) => (
              <span style={{ display: 'flex', flexDirection: 'row', columnGap: '5px' }}>
                {' '}
                {hasPermission('Add Permission') && (
                  <Button
                    onClick={() => {
                      setVisible(true);
                      setIsedit(true);
                      setDelete(false);
                      setRowData(record);
                    }}
                    style={{
                      marginRight: '1%',
                      backgroundColor: '#eeeeee',
                      color: '#004A7F',
                      borderRadius: '4px',
                      width: '1px',
                      height: '12%'
                    }}>
                    <MdEdit size={14} style={{ marginLeft: '-6px' }} />
                  </Button>
                )}
                {hasPermission('Add Permission') && (
                  <Button
                    onClick={() => {
                      setVisible(true);
                      setIsedit(false);
                      setDelete(true);
                      setRowData(record);
                    }}
                    style={{
                      color: 'red',
                      backgroundColor: '#eeeeee',
                      borderRadius: '4px',
                      width: '1px',
                      height: '12%'
                    }}>
                    <AiOutlineDelete size={14} style={{ marginLeft: '-8px' }} />
                  </Button>
                )}
              </span>
            )
          }
        ]
      : [])
  ];

  return (
    <div>
      <Table
        style={{ width: '100%' }}
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        bordered
        onRow={(record) => {
          return {
            onClick: () => {
              setRowData(record);
            }
          };
        }}
      />
      <div>
        {isEdit || isdelete ? (
          <Modal
            closeIcon={null}
            title={isEdit ? lang.label_editrole : lang.label_deleteconfirmation}
            open={visible}
            onCancel={handleCancel}
            footer={null}
            width={isEdit ? 900 : 350}
            closable={false}>
            {isEdit ? (
              <EditRoleForm rowdata={rowData} onCancel={handleCancel} checklist={checklist} getRoles={getRoles} />
            ) : (
              ''
            )}
            {isdelete ? <DeleteRoleForm rowdata={rowData} onCancel={handleCancel} getRoles={getRoles} /> : ''}
          </Modal>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default PermissionsTable;
