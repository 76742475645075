import React, { useState, useEffect, useContext } from 'react';
import { Pagination, Table } from 'antd';
import GetApi from '../../../../helps/contexts/Api/GetApi';
import LangContext from '../../../../helps/contexts/lang-context';
import { convertUTCToLocalTime } from '../../../../helps/HelperFunctions';

const LogInHistoryTable = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [Data, setData] = useState([]);

  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  
  //  fetch data from api
  const getLoginHistory = async (customPage = null, id) => {
    const path = `/admin/player/login/${id}`;
    const params = {};

    if (customPage) params['page'] = customPage;
    else params['page'] = activepage;
    setLoading(true);
    //sending api request
    const res = await GetApi.sendApiRequest(path, {}, params);
    // console.log(res.data.data)
    if (res && res?.data?.data && res?.data?.data?.length) {
      const finalArray = res?.data?.data?.map((loginData) => ({
        key: loginData?.id ? loginData?.id : '-',
        id: loginData?.user_id ? loginData?.user_id : '-',
        ip: loginData?.ip ? loginData?.ip : '-',
        device: loginData?.device_type ? loginData?.device_type : '-',
        browser: loginData?.browser_type ? loginData?.browser_type : '-',
        time: loginData?.created_at ? loginData?.created_at : '-',
        remark: loginData?.note ? loginData?.note : '-'
      }));
      setData(finalArray);
      setCurrentpage(res?.data?.current_page);
      setTotal(res?.data?.total);
      setPerpage(res?.data?.per_page);
      setNextPage(res?.data?.next_page_url);
    } else {
      setData([]);
      setTotal(0);
    }
    setLoading(false);
  };

  useEffect(() => {
    getLoginHistory(activepage, id);
  }, []);

  // on Change for Pagination
  const onChangepage = (page) => {
    setActivepage1(page);
    getLoginHistory(page,id);
  };

  //  set column on Table
  const columns = [
    {
      title: lang.label_ip,
      dataIndex: 'ip',
      key: 'ip',
      render: (text) => <span>{text}</span>
    },

    {
      title: lang.label_device,
      dataIndex: 'device',
      key: 'device',
      render: (text, record) => (
        <div>
          <span>{text}</span>
          <br />
          <span>{record.browser}</span>
        </div>
      )
    },

    {
      title: lang.label_time,
      dataIndex: 'time',
      key: 'time',
      render: (time) => <span>{convertUTCToLocalTime(time)}</span>
    },

    {
      title: lang.label_remark,
      dataIndex: 'remark',
      key: 'remark',
      render: (text) => <p>{text}</p>
    }
  ];

  return (
    <>
      <Table
        pagination={false}
        style={{
          width: '100%',
          marginTop: '30px',
          overflowX: 'auto',
          overflowY: 'scroll'
        }}
        size="small"
        columns={columns}
        bordered
        dataSource={Data}
        loading={loading}
      />
      <Pagination
        defaultCurrent={1}
        current={activepage}
        onChange={onChangepage}
        total={total}
        responsive={true}
        pageSize={perpage}
      />
    </>
  );
};

export default LogInHistoryTable;
