import React, { useState } from 'react';
import { Table, Modal, Button, Dropdown, Menu, Space, Tag, message, Input, Select } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { IoMenu } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
// import { toFixedFunc } from '../../../helps/HelperFunctions';
// import ExportForm from './ExportForm'; // Assuming this component handles the export form

const ExportListTable = ({ data, loading, getExports }) => {
  const navigate = useNavigate();

  // States for modals
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [exportDetails, setExportDetails] = useState({});
  const [selectedExportId, setSelectedExportId] = useState(null);

  // Handle export modal
  const handleExportModalCancel = () => {
    setExportModalVisible(false);
    setExportDetails({});
  };

  // Handle export action
  const handleExport = async () => {
    try {
      // Perform the export logic here
      message.success('Export successful');
      setExportModalVisible(false);
      getExports();
    } catch (error) {
      message.error('Export failed');
    }
  };

  // Handle menu item click
  const handleMenuClick = (key, record) => {
    switch (key) {
      case '1':
        setExportDetails(record);
        setExportModalVisible(true);
        break;
      default:
        break;
    }
  };

  // Columns configuration
  const columns = [
    {
      title: 'Unique ID',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
      render: (_, record) => (
        <a
          style={{ color: '#1677ff' }}
          onClick={() => navigate(`/player-management/player/${record.id}/view`)}
        >
          {record.username}
        </a>
      )
    },
    {
        title: 'Name',
        key: 'balance',
        dataIndex: 'balance',
        render: (balance, record) => (
          <span style={{ color: '#207620' }} title={record['baseBalance']}>
            {/* <span>{toFixedFunc(balance, 2)} ({record.currency})</span> */}
          </span>
        )
      },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => (
        <Button style={{ background: 'transparent', border: 'none' }}>
          {record.status === 1 ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Active
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              Inactive
            </Tag>
          )}
        </Button>
      )
    },
   
    
    {
      title: 'Options',
      key: 'action',
      dataIndex: 'actions',
      render: (_, record) => {
        const menu = (
          <Menu onClick={({ key }) => handleMenuClick(key, record)}>
            <Menu.Item key="1">Export Data</Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <a href="#" style={{ color: '#004a7f' }} onClick={(e) => e.preventDefault()}>
              <IoMenu />
            </a>
          </Dropdown>
        );
      }
    }
  ];

  return (
    <>
      <Table
        size="small"
        columns={columns}
        bordered
        dataSource={data}
        style={{ width: '100%', marginTop: '30px', color: 'black' }}
        pagination={false}
        loading={loading}
      />

      <Modal
        title="Export Data"
        visible={exportModalVisible}
        onOk={handleExport}
        onCancel={handleExportModalCancel}
        footer={null}
        width={500}
      >
        {/* <ExportForm details={exportDetails} onCancel={handleExportModalCancel} /> */}
      </Modal>
    </>
  );
};

export default ExportListTable;