import { Table, Modal, Dropdown } from "antd";

import LangContext from "../../../helps/contexts/lang-context";
import { useContext, useState, useEffect } from "react";
import EditSupAgentForm from "./EditSupAgentForm";
import ResetPwdForm from "../../../components/forms/ResetPwdForm";
import { Menu } from "antd";
import { IoMenu } from "react-icons/io5";

const SuperiorAgentListTable = ({ data, loading, fetchSeniorgents }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState({});
  const [isEdit, setIsedit] = useState(false);
  const [reset, setReset] = useState(false);
  // Edit Agent popup

  const handleCancel = (e) => {
    setIsedit(false);
    setVisible(!visible);
  };

  //for the edit option in table
  const handleEditAgent = (record) => {
    setVisible(true);
    setRowData(record);
    setIsedit(true);
    setReset(false);
  };
  //for the resetpwd option in table
  const handleResetPwd = () => {
    setVisible(true);
    setIsedit(false);
    setReset(true);
  };

  //menu items in table

  const items = [
    {
      key: 1,
      label: "Edit Senior Agent",
    },
    {
      key: 2,
      label: "Reset Password",
    },
  ];

  
  //onclick of option in table
  const onClick = ({ key }) => {
    if (key == 1) {
      handleEditAgent();
    }
    if (key == 2) {
      handleResetPwd();
    }
  };
  useEffect(() => {
    if (isEdit) {
      handleEditAgent(rowData);
    }
  }, [isEdit]);

  const columns = [
    {
      title: lang.label_name,
      dataIndex: "name",
      key: "name",
    },
    {
      title: lang.label_username,
      dataIndex: "username",
      key: "username",
    },
    {
      title: lang.label_phone,
      dataIndex: "phone",
      key: "phone",
    },

    {
      title: lang.label_uniquecode,
      dataIndex: "uniquecode",
      key: "uniquecode",
    },
    {
      title: `${lang.label_registerip}/${lang.label_registertime}`,
      dataIndex: ["registerip", "registertime"],
      key: "registerip",
      render: (time, row) => (
        <span style={{ color: "#2A93F0" }} title={row["registertime"]}>
          <span title={row["registertime"]} style={{}}>
            {row["registerip"]}
            <br />
            <span style={{ color: "black" }}>
              {row["registertime"]}
            </span>
          </span>
        </span>
      ),
    },

    {
      title: `${lang.label_loginip}/${lang.label_logintime}`,
      dataIndex: ["loginip", "logintime"],
      key: "loginip",
      render: (time, row) => (
        <span style={{ color: "#2A93F0" }} title={row["logintime"]}>
          {row["loginip"]}
          <br />
          <span style={{ color: "black" }}>
            {row["logintime"]}
          </span>
        </span>
      ),
    },

    {
      title: lang.label_action,
      key: "action",
      render: (_, record) => {
        return (
          <Dropdown
            placement="bottom"
            menu={{
              items,
              onClick,
            }}
          >
          
          <a
              href="#"
              style={{ color: "#004a7f" }}
              onClick={(e) => e.preventDefault()}
            >
              <IoMenu />
            </a>
           
          </Dropdown>
        );
      },
    },
  ];
  return (
    <div>
      <Table
        style={{ width: "100%" }}
        size="small"
        columns={columns}
        loading={loading}
        dataSource={data}
        pagination={false}
        bordered
        onRow={(record) => {
          return {
            onClick: () => {
              setRowData(record);
            },
          };
        }}
      />
      <div>
        {(isEdit || reset) && (
          <Modal
            closeIcon={null}
            title={
              isEdit
                ? lang.label_editsupagent
                : reset
                ? lang.label_resetpwd
                : ""
            }
            open={visible}
            onCancel={handleCancel}
            footer={null}
            width={isEdit ? 700 : reset ? 700 : ""}
            closable={true}
          >
            {isEdit ? (
              <EditSupAgentForm
                rowdata={rowData}
                onCancel={handleCancel}
                fetchSeniorgents={fetchSeniorgents}
              />
            ) : reset ? (
              <ResetPwdForm
                rowdata={rowData}
                onCancel={handleCancel}
                fetchSeniorgents={fetchSeniorgents}
              />
            ) : (
              ""
            )}
          </Modal>
        )}
      </div>
    </div>
  );
};
export default SuperiorAgentListTable;
