import { Layout } from 'antd';
import { useContext, useState } from 'react';
import '../styles/Sidebar.css';
import Topbar from './navigation/Topbar';
import dashboardLogo from '../images/bw9 logo.png';
import SidebarMenu from './SidebarMenu';
import annoucement from '../images/Announcement.png';
import styles from './AuthLayout.module.css';
import menu from '../images/Menu.png';
import { Link } from 'react-router-dom';
import usePusherSubscription from '../Pusher/usePusherSubscription';
import NotificationContext from '../helps/contexts/Notification-Context';
import depositTune from '../sound/depositTune.wav';
import withdrawTune from '../sound/withdrawTune.wav';
import { UserContext } from '../helps/contexts/UserContext';
import TransactionContext from '../helps/contexts/Transaction-Context';
const { Header, Sider, Content } = Layout;

const AuthLayout = ({ children }) => {
  const depositSound = new Audio(depositTune);
  const withdrawSound = new Audio(withdrawTune);
  const { transactionObject, counterIncreaseFunc, counterDecreaseFunc, setTransactionObject } =
    useContext(NotificationContext);
  const { isWithdrawSoundAllowedRef, isDepositSoundAllowedRef } = useContext(UserContext);
  const [collapsed, setCollapsed] = useState(false);
  const {
    setDepositcounter,
    setWithdrawCounter,
    setTransactionObject: setTransaction
  } = useContext(TransactionContext);

  const handleNewTransaction = (data) => {
    setTransaction({ type: 'NEW', data: data });
    try {
      if (data.isWithdraw === 0) {
        if (isDepositSoundAllowedRef.current) {
          setTimeout(() => {
            depositSound?.play();
          }, 1000);
        }
        setDepositcounter((prevCounter) => prevCounter + 1);
      } else {
        if (isWithdrawSoundAllowedRef.current) {
          setTimeout(() => {
            withdrawSound?.play();
          }, 1000);
        }
        setWithdrawCounter((prevCounter) => prevCounter + 1);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeTransaction = (data) => {
    if (data.status == 1 || data.status == 2) {
      setTransaction({ type: 'REMOVE', data: data });
    } else {
      setTransaction({ type: 'CHANGE', data: data });
    }
  };

  const handleRiskApprove = (data) => {
    if (data.risk_action_status_string === 'rejected') {
      counterDecreaseFunc('withdraw');
    }
    // if(data?.risk_action_status ){
    const obj = {
      isWithdraw: 1,
      is_withdraw_transaction: data?.is_withdraw_transaction
    };

    setTransactionObject(obj);
    // setListnerRiskAprove(true)
    // }
  };

  const handleFianceApprove = (data) => {
    if (data.isWithdraw === 0) {
      counterDecreaseFunc('deposit');
    } else {
      counterDecreaseFunc('withdraw');
    }
    setTransactionObject(data);
  };

  // const handleTransactionInProcessing = (data)=>{

  //   setTransactionObject(data)
  // }

  const handleDepositLockChangeFunc = (data) => {
    const obj = {
      depositLock: true,
      isWithdraw: 0,
      is_deposit_transaction: data?.is_deposit_transaction
    };
    setTransactionObject(obj);
  };

  const handleWithdrawLockChangeFunc = (data) => {
    const obj = {
      withdrawLock: true,
      isWithdraw: 1,
      is_withdraw_transaction: data?.is_withdraw_transaction
    };
    setTransactionObject(obj);
  };

  usePusherSubscription('transactions', 'TransactionCreated', handleNewTransaction);
  usePusherSubscription('transactions', 'TransactionRiskStatusChange', handleRiskApprove);
  usePusherSubscription('transactions', 'TransactionFinanceStatusChange', handleFianceApprove);
  usePusherSubscription('transactions', 'TransactionChange', handleChangeTransaction);
  usePusherSubscription('transactions', 'TransactionWithdrawLockChange', handleWithdrawLockChangeFunc);

  return (
    <Layout>
      <Sider
        className="sidebar"
        trigger={null}
        collapsible
        collapsed={collapsed}
        width="250"
        style={{
          boxSizing: 'border-box',
          backgroundColor: '#004A7F',
          height: '100vh',
          overflowX: 'hidden',
          overflowY: 'auto',
          position: 'fixed'
        }}>
        <div style={collapsed ? { maxWidth: '80px' } : { maxWidth: '245px' }}>
          <div className={styles.logoContainer}>
            <div className={styles.logoWrapper}>
              <Link to="/dashboard">
                <img className={collapsed ? styles.minilogo : styles.logo} src={dashboardLogo} alt="PM logo" />
              </Link>
            </div>
          </div>
          <SidebarMenu collapsed={collapsed} />
        </div>
      </Sider>

      <Layout>
        <Header
          style={{
            position: 'fixed',
            zIndex: 100, // fixing navbar
            padding: 0,
            background: 'white',
            marginLeft: collapsed ? `80px` : `250px`,
            transition: 'margin-left 0.3s ease',
            height: '70px',
            width: collapsed ? `100%` : `90%`,
            borderBottom: '1px solid #ccc'
          }}>
          <nav>
            <div
              onClick={() => setCollapsed(!collapsed)}
              style={{
                height: '20px',
                width: '20px',
                cursor: 'pointer'
              }}>
              <img
                src={menu}
                alt="Menu Icon"
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain'
                }}
              />
            </div>
            {/* <div className="AnnoucemntSection">
              <div className="AnnoucementImageWrapper">
                <img src={annoucement} alt="Annoucement Image1" />
              </div>
              <span className="AnnoucementText">
                Announcement goes here... Lorem ipsum dolor sit amet, consetetur
                sadipscing elitr, sed diam nonumy.
              </span>
            </div> */}
            <Topbar />
          </nav>
        </Header>
        <Content
          style={{
            // padding: '24px',
            minHeight: 280,
            background: '#F3F3F9',
            marginLeft: collapsed ? `80px` : `250px`,
            transition: 'margin-left 0.3s ease'
          }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AuthLayout;
