import { Input, Button, Row, Col, Select, message } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PostApi from '../../../helps/contexts/Api/PostApi';
import GetApi from '../../../helps/contexts/Api/GetApi';
import { Editor } from '@tinymce/tinymce-react';
import ErrorsHandlingComp from '../../../helps/errorsHandlingComp';

const { TextArea } = Input;

const AdminCreateForm = ({ handleCancel, getUsers, roledata }) => {
  const nav = useNavigate();
  // form states
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState(null);
  const [countrycode, setcountrycode] = useState();
  const [senioragentid, setSenioragentid] = useState('Select A Role');
  const [remark, setRemark] = useState('');
  const [roleError, setRoleError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [errors, setErrors] = useState({});
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { Option } = Select;
  const [codedata, setcodedata] = useState([]);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const editorRef = useRef();

  useEffect(() => {
    fetchCountryCode();
  }, []);
  // Fetching api

  const fetchCountryCode = async () => {
    const path = '/user/phone/codes';
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path, {}, params);

    if (res && res.length) {
      const finalArray = res.map((cc, index) => ({
        key: index,
        code: cc.code
      }));
      setcodedata(finalArray);
    } else {
      setcodedata([]);
    }
  };
  const oncodechange = (value) => {
    setcountrycode(value);
  };
  //form submit to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const finalphone = countrycode + phone;
      const path = `/admin/create`;
      // sending request
      if (senioragentid && senioragentid !== 'Select A Role') {
        if (!passwordErrorMessage && password && username && name && !phoneError) {
          const res = await PostApi.PostApiRequest(path, {
            user_name: username,
            password: password,
            name: name,
            // phone: countrycode ? finalphone : phone,
            phone: phone,
            role_id: senioragentid,
            remark: remark
          });

          //if success
          if (res?.status === 200) {
            message.success('Admin created successfully');
            setName('');
            setPassword('');
            setPhone('');
            setUserName('');
            setRemark('');
            handleCancel();
            getUsers();
          }
        }
      } else {
        setRoleError('Please select a valid role.');
      }
    } catch (e) {
      if (e?.status === 422) {
        console.log(e.data.message);
        Object.values(e.data.message).map((errors) => message.error(errors));
      }
    }
  };
  //username input change and validation
  // const onInputChange = (e) => {
  //   const { value } = e.target;
  //   const re = /^[a-z0-9_-]*$/;
  //   if ((value === "" || re.test(value) && (value?.length>8 || value?.length<=12))) {
  //     setUserName(value);
  //   } else  {
  //     message.error("Please Enter Only Numbers ,Lowercase Alphabets");
  //   }
  // };

  const onSelect = (value) => {
    if (value === 'Select A Role') {
      setRoleError('Please select a valid role.');
      setSenioragentid(value);
    } else {
      setRoleError(''); // Clear error message
      setSenioragentid(value);
    }
  };
  const passwordRegex = /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};'"\\|,.<>\/?]{6,16}$/;
  const phoneRegex = /^\+?\d{11,14}$/;

  const handleKeyPress = (e) => {
    // Allow only digits and plus sign
    if (!/[0-9+]/.test(e.key) || (e.key === '+' && phone.includes('+'))) {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const phoneRegex = /^\+?\d{0,13}$/;

    if (!phoneRegex.test(inputValue)) {
      setPhoneError('Invalid format');
    } else {
      setPhone(inputValue);
      setPhoneError('');

      // Check the length requirement
      if (inputValue.length < 11 || inputValue.length > 14) {
        setPhoneError('Phone number should be 11-14 digits long');
      } else {
        setPhoneError('');
      }
    }
  };
  return (
    <div
      style={{
        width: '97%',
        height: '85%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: '3%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '-6%'
          }}>
          <Row>
            <Col span={12}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_name}</label>
                <span style={{ color: 'red' }}>*</span>
              <br />

              <Input
                defaultValue="Admin Name"
                required
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                placeholder="Admin Name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <ErrorsHandlingComp errors={errors} name="name" />
            </Col>
            <Col span={12}>
              <label>
                {lang.label_username}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <br />

              <Input
                defaultValue="Admin user Username"
                type="text"
                placeholder="Admin user Name"
                name="admin_username"
                required
                value={username}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                minLength={8}
                maxLength={12}
              />
              <ErrorsHandlingComp errors={errors} name="user_name" />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>
                {lang.label_password}
                <span style={{ color: 'red' }}>*</span>
              </label>{' '}
              <br />
              <Input.Password
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                required
                type="password"
                placeholder="Enter Password"
                name="admin_password"
                value={password}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setPassword(inputValue);
                  if (passwordRegex.test(inputValue)) {
                    setPasswordErrorMessage(''); // Clear error message if input is valid
                  } else {
                    setPasswordErrorMessage(
                      'The password must be 6-16 characters long and can include letters, numbers, and special characters.'
                    );
                  }
                }}
                maxLength={16}
                minLength={6}
                autoComplete="new-password"
              />
              {passwordErrorMessage && <div style={{ color: 'red' }}>{passwordErrorMessage}</div>}
              <ErrorsHandlingComp errors={errors} name="password" />
            </Col>
            {/* <Col span={3}>
              <label>code </label>
              <br />
              <Select
                style={{ width: "90%", marginTop: "5px" }}
                onChange={oncodechange}
                defaultValue={countrycode}
              >
                <Option>{}</Option>
                {codedata &&
                  codedata.map((cc, index) => {
                    return (
                      <Option key={index} value={cc.code}>
                        {cc.code}
                      </Option>
                    );
                  })}
              </Select>
            </Col> */}
            <Col span={12}>
              <label>{lang.label_phone}</label>
              {/* <span style={{ color: 'red' }}>*</span> */}
              <br />

              <Input
                style={{
                  width: '84%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                placeholder="Enter Phone Number"
                name="phone"
                value={phone}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                // required
              />
              {phoneError && <div style={{ color: 'red' }}>{phoneError}</div>}
              <ErrorsHandlingComp errors={errors} name="phone" />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <label>{lang.label_role}</label>
              <br />
              <Select
                style={{ marginTop: '5px', width: '92%' }}
                onChange={onSelect}
                // defaultValue="Select A Role"
                value={senioragentid}>
                <Option value="Select A Role">Select A Role</Option>
                {roledata &&
                  roledata.map((agent, index) => {
                    return (
                      <Option key={index} value={agent.id}>
                        {agent.name}
                      </Option>
                    );
                  })}
              </Select>
              {roleError && <div style={{ color: 'red' }}>{roleError}</div>}
              <ErrorsHandlingComp errors={errors} name="remark" />
            </Col>
          </Row>

          <br />

          <Row>
            <Col span={24}>
              <label>{lang.label_remark}</label>
              <br />

              {/* <TextArea
                showCount
                maxLength={100}
                // onChange={onChangeRemark}
                style={{
                  width: "92%",
                  height: "100px",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                value={remark}
              /> */}
              {/* <Editor
                apiKey="oe0a6fgjalzmwz262hbvhwf5dyihw4yvdlviqy6cmafqrdld"
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={remark}
                onEditorChange={(e, editor) => setRemark(e)}
                init={{
                  menubar: true,
                  width: 600,
                  height: 350,
                  selector: 'textarea#local-upload',
                  plugins: 'image code'
                  // toolbar: 'image code',

                  // toolbar: 'image',
                }}
              /> */}
              <TextArea rows={10} placeholder="" onChange={(e) => setRemark(e)} style={{ width: 600 }} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'flex-end',
                  marginTop: '20px',
                  marginRight: '50px'
                }}>
                {' '}
                <Button
                  style={{
                    borderRadius: '3px',
                    backgroundColor: '#004A7F',
                    color: 'white'
                    // position: "absolute",
                    // right: "20%",
                    // top: "95%",
                  }}
                  htmlType="submit">
                  {lang.label_submit}
                </Button>
                <Button
                  style={{
                    borderRadius: '3px',
                    color: '#004A7F'
                    // position: "absolute",
                    // right: "8%",
                    // top: "95%",
                  }}
                  onClick={() => {
                    setName('');
                    setPassword('');
                    setPhone('');
                    setUserName('');
                    handleCancel();
                  }}>
                  {lang.label_cancel}
                </Button>
              </div>
            </Col>
          </Row>

          <br />
        </div>
      </form>
    </div>
  );
};

export default AdminCreateForm;
