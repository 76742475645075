import { Input, Button, Row, Col, message } from 'antd';
import LangContext from '../../helps/contexts/lang-context';
import { useContext, useState } from 'react';
import PutApi from '../../helps/contexts/Api/PutApi';

const ResetPwdForm = ({ onCancel, rowdata }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const userid = rowdata?.user_id ? rowdata?.user_id : rowdata?.userid ;
  const id = rowdata.id;
  
  const [password, setPassword] = useState();
  const [confirmpwd, setConfirmpwd] = useState();

  //on form submit
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (password !== confirmpwd) {
        message.error('password did not match');
      } else if (password === confirmpwd && userid) {
        const path = `/user/${userid}/password/reset`;
        const res = await PutApi.PutApiRequest(path, {
          new_password: password
        });
        if (res && res.status === true) {
          setPassword('');
          setConfirmpwd('');
          message.success('Password Updated Successfully');
          onCancel();
        } else {
          message.error('Password failed to Updated Successfully');
        }
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  return (
    <div
      style={{
        width: '97%',
        height: '100%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: '3%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '-6%'
          }}>
          <Row>
            <Col span={12}>
              <label>
                {lang.label_newpassword}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <br />
              <Input.Password
                style={{
                  width: '186%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                required
                type="password"
                placeholder="Enter Password"
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                maxLength={18}
                minLength={8}
              />
            </Col>
          </Row>

          <br />
          <Row>
            <Col span={12}>
              <label>
                {lang.label_confirmpassword}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <br />
              <Input.Password
                style={{
                  width: '186%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                required
                type="password"
                placeholder="Enter Password"
                name="password"
                value={confirmpwd}
                onChange={(e) => setConfirmpwd(e.target.value)}
                maxLength={18}
                minLength={8}
              />
            </Col>
          </Row>
          <br />

          <div style={{ marginBottom: '10%', backgroundColor: 'yellow' }}>
            <Button
              style={{
                borderRadius: '3px',
                backgroundColor: '#004A7F',
                color: 'white',
                position: 'absolute',
                right: '20%',
                top: '86%'
              }}
              htmlType="submit">
              {lang.label_submit}
            </Button>
            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                position: 'absolute',
                right: '8%',
                top: '86%'
              }}
              onClick={(e) => {
                setPassword('');
                setConfirmpwd('');
                onCancel();
              }}>
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPwdForm;
