import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LangContext from '../../../helps/contexts/lang-context';
import { TabContext } from '../../../helps/contexts/TabContext';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import { Modal, Table, Tag } from 'antd';
import PaginationComp from '../../../components/HelperComponents/PaginationComp';
import GetApi from '../../../helps/contexts/Api/GetApi';
import Spacer from '../../../components/HelperComponents/Spacer';
import TagAdjustmentFilters from './TagAdjustmentFilters';
import { convertLocalTimeToUTC, convertUTCToLocalTime } from '../../../helps/HelperFunctions';

export default function TagManagementHistory() {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const Navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tagsAdjustmentList, setTagsAdjustmentList] = useState([]);

  const [pageSize, setPageSize] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(null);

  const { path } = useContext(TabContext);

  const getTagsAdjustmentHistory = async ({ page, filter }) => {
    setLoading(true);
    try {
      const path = '/admin/tags/tag-player/history';
      const finalData = filter
        ? {
            player_name: filter?.modify_account,
            start_date: filter?.start_date,
            end_date: filter?.end_date,
            admin_name: filter?.operate_by,
            after_modify_tags: filter?.after_modify,
            before_modify_tags: filter?.before_modify
          }
        : null;

      const params = { ...finalData, page: page };
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      // console.log('tag-api-response : ', res);
      if (res && Array.isArray(res?.data)) {
        const finalArray = res.data?.map((tags) => ({
          updated_on: convertUTCToLocalTime(tags.updated_at),
          modify_account: tags?.player_name,
          before_modify_tags: tags?.previous_tags,
          after_modify_tags: tags?.new_tags,
          operate_by: tags?.admin_name
        }));
        setLoading(false);
        setTagsAdjustmentList(finalArray);
        setTotalLength(res?.total);
        setPageSize(res?.per_page);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
    setLoading(false);
  };
  const onRefresh = () => {
    setFilters(null);
    setCurrentPage(1);
    getTagsAdjustmentHistory({ page: currentPage });
  };

  useEffect(() => {
    getTagsAdjustmentHistory({ page: currentPage, filter: filters ?? '' });
  }, [filters, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    getTagsAdjustmentHistory({ page: currentPage, filter: filters ?? '' });
  }, [filters]);

  const onChangePage = (page) => {
    setCurrentPage(page);
    // setIsPageChange(true);
  };

  const paginationConfig = {
    pageSize: pageSize ? pageSize : 0,
    total: totalLength ? totalLength : 0,
    showSizeChanger: false,
    showQuickJumper: false,
    current: currentPage,
    showTotal: (total) => `Total ${total} items`,
    onChange: onChangePage,
    itemRender: (current, type, originalElement) => {
      if (type === 'prev') {
        return <span className="pagination-item">Previous</span>;
      }
      if (type === 'next') {
        return <span className="pagination-item">Next</span>;
      }
      return originalElement;
    }
  };
  const columns = [
    {
      title: lang.label_upd_on,
      dataIndex: 'updated_on',
      key: 'updated_on',
      fixed: 'left'
    },
    {
      title: lang.label_modify_account,
      dataIndex: 'modify_account',
      key: 'modify_account',
      fixed: 'left'
    },
    {
      title: lang.label_before_modify_tags,
      dataIndex: 'before_modify_tags',
      key: 'before_modify_tags',
      render: (text, record) => {
        return (
          <>
            {text?.length
              ? text.map((item) => (
                  <Tag color={item?.type.toLowerCase() === 'functional' ? 'error' : 'warning'}>{item?.name}</Tag>
                ))
              : '-'}
          </>
        );
      }
    },
    {
      title: lang.label_after_modify_tags,
      dataIndex: 'after_modify_tags',
      key: 'after_modify_tags',
      render: (text, record) => {
        return (
          <>
            {text?.length
              ? text.map((item) => (
                  <Tag color={item?.type.toLowerCase() === 'functional' ? 'error' : 'warning'}>{item?.name}</Tag>
                ))
              : '-'}
          </>
        );
      }
    },
    {
      title: lang.label_operate_by,
      key: 'operate_by',
      dataIndex: 'operate_by',
      fixed: 'right'
    }
  ];

  return (
    <div>
      <HeaderCardDetails />
      <div style={{ padding: '20px' }}>
        <TagAdjustmentFilters onRefresh={onRefresh} setFilters={setFilters} />
        <br />
        <Table
          scroll={{
            x: 'max-content'
          }}
          columns={columns}
          loading={loading}
          dataSource={tagsAdjustmentList}
          bordered
          pagination={false}
          rowKey={'id'}
        />
        {tagsAdjustmentList?.length > 0 && <PaginationComp paginationConfig={paginationConfig} />}
        <Spacer />
        <Spacer />
      </div>
    </div>
  );
}
