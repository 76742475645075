import {
  Button,
  message,
} from "antd";
import LangContext from "../../../helps/contexts/lang-context";
import { useContext, useState } from "react";
import PutApi from "../../../helps/contexts/Api/PutApi";



const ToggleWithdraw=({handleCancel, rowData, fetchCompanyBanks})=>{
 
const ctx = useContext(LangContext);
const lang = ctx.lang;
  const [withdraw, setWithdraw] = useState(rowData&&rowData.withdraw?rowData.withdraw:1);
  const id=rowData.key
  //on form submit request to backend
const onSubmit = async (e) => {
  try {
    e.preventDefault();
    const path = `/company/banks/${id}/withdraw/toggle`;
    // sending request
    const res = await PutApi.PutApiRequest(path, {
      withdraw:withdraw,
    });
    //if success
    if (res.status == true) {
      handleCancel();
      message.success(lang.label_withdrawsuccess);
     fetchCompanyBanks()
    } else {
      message.success(lang. label_withdrawfail);
    }
  } catch (e) {
    var message1 = e.message;

    alert(message1);
  }
};
return(
  <div  style={{
      width: "97%",
      height: "70px",
      borderRadius: "3px",
      justifyContent: "space-evenly",
    }}>
      <p>{lang.label_withdrawconfirm}</p>
      <form onSubmit={onSubmit}>
      <div style={{}}>
        {" "}
        <Button
          style={{
            borderRadius: "3px",
            backgroundColor: "green",
            color: "white",
            position: "absolute",
            right: "25%",
            top: "70%",
            bottom: "10%",
          }}
          htmlType="submit"
        >
          {rowData.withdraw === 1 ? lang.label_deactivate:lang.label_activate}
        </Button>
        <Button
          style={{
            borderRadius: "3px",
            color: "black",
            position: "absolute",
            right: "5%",
            top: "70%",
            bottom: "10%",
          }}
         onClick={handleCancel}
        >
          {lang.label_cancel}
        </Button>
      </div>
    </form>
  </div>
)
}
export default ToggleWithdraw;