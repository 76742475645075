import {
  Table,
} from "antd";
import { useContext, useState, useEffect } from "react";
import LangContext from "../../../helps/contexts/lang-context";
import moment from "moment";
import { convertUTCToLocalTime } from "../../../helps/HelperFunctions";

const AdminLogsTable = ({ data, loading, getUsers, childrowdata }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  //states
 
 
  const [rowdata, setRowdata] = useState({});
  
  // columns of table
  const columns = [
    {
      title: ` Change`,
      dataIndex: "change",
      key: "change",
    },
    {
      title: ` Changed By`,
      dataIndex: "changedBy",
      key: "changedBy",
    },
    {
      title: `Ip`,
      dataIndex: ["ip"],
      key: "ip",
      render: (time, row) => (
        <a style={{ color: "#2A93F0" }} title={row["ip"]}>
          {row["ip"]}
        </a>
      ),
    },

    {
      title: `Date`,
      dataIndex: ["date"],
      key: "date",
      render: (time, row) => (
        <a style={{ color: "black" }}title={row["date"]}>
         
            {
            // new Date(row["logintime"]).toLocaleString()
           // moment.utc(row["date"], "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")
            convertUTCToLocalTime(row["date"])
            }
        
        </a>
      ),
    },

  
  ];

  return (
    <>
      <Table
      style={{width:"100%"}}
        size="small"
        columns={columns}
        dataSource={data}
        bordered
        pagination={false}
        loading={loading}
        onRow={(record) => {
          return {
            onClick: () => {
              setRowdata(record);
            },
          };
        }}
      />
    </>
  );
};

export default AdminLogsTable;
