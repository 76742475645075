import React from 'react';
import { Image, Table } from 'antd';
import ViewImg from '../../../src/images/View.png';
import { useNavigate } from 'react-router-dom';
import { convertUTCToLocalTime } from '../../helps/HelperFunctions';
// import LangContext from "../../helps/contexts/lang-context";
// import { useContext } from "react";

const BetHistorytable = ({ data, loading }) => {
  // const ctx = useContext(LangContext);
  // const lang = ctx.lang;
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Opened On',
      dataIndex: 'openedOn',
      key: 'openedOn',
      render: (time) => <span>{convertUTCToLocalTime(time)}</span>
    },
    {
      title: 'Closed On',
      dataIndex: 'closedOn',
      key: 'closedOn',
      render: (time) => <span>{time === convertUTCToLocalTime(null) ? '_' : convertUTCToLocalTime(time)}</span>
    },
    {
      title: 'Reference ID',
      dataIndex: 'round_reference',
      key: 'round_reference'
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform'
    },
    {
      title: 'Game',
      dataIndex: 'gameName',
      key: 'gameName',
      render: (item) => {
        return <div style={{ whiteSpace: 'pre-wrap' }}>{item}</div>;
      }
    },
    {
      title: 'Player Name',
      dataIndex: 'playerName',
      key: 'playerName'
    },
    {
      title: 'Agent ID',
      dataIndex: 'agentId',
      key: 'agentId',
      render: (ip) => {
        return ip ? ip : '-';
      }
    },
    {
      title: 'Win/Loss (amount)',
      dataIndex: 'winLoss',
      key: 'winLoss',
      render: (item) => {
        const value = Number(item); 
        return <span style={{ color: value <= 0 ? 'red' : '' }}>{isNaN(value) ? '-' : item}</span>;
      }
    },
    {
      title: 'Status',
      dataIndex: 'resultStatus',
      key: 'resultStatus'
    },
    {
      title: 'Total Bet Amount',
      dataIndex: 'totalBetAmount',
      key: 'totalBetAmount'
    },
    {
      title: 'Total Turnover',
      dataIndex: 'totalTurnover',
      key: 'totalTurnover'
    },
    {
      title: 'Total Valid Bets',
      dataIndex: 'totalValidBet',
      key: 'totalValidBet'
    },
    {
      title: 'Total Win Amount',
      dataIndex: 'totalWinAmount',
      key: 'totalWinAmount'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Image
          src={ViewImg}
          style={{ color: 'rgb(64, 81, 137)', cursor: 'pointer' }}
          preview={false}
          onClick={() => navigate(`/reports/bet-history/bet-details/${record.id}`)}
        />
      )
    }
  ];

  return <Table loading={loading} columns={columns} bordered dataSource={data} pagination={false} size="small" />;
};

export default BetHistorytable;
