import React from "react";

import LangContext from "../../../helps/contexts/lang-context";
import { useContext, useState, useEffect } from "react";
import PutApi from "../../../helps/contexts/Api/PutApi";
import { Select, Input, Row, Col, Button, message, Spin } from "antd";
import GetApi from "../../../helps/contexts/Api/GetApi";
import PostApi from "../../../helps/contexts/Api/PostApi";
import { useNavigate } from "react-router-dom";
import SubHeading from "../../../components/HelperComponents/SubHeading";
import { constants } from "../../../helps/contexts/Api/constants";
import axios from 'axios';
const { Option } = Select;
const AddPlayerNotification = ({ onCancel, rowdata, getNotification, type="new" }) => {
  const nav = useNavigate();
  const ctx = useContext(LangContext);
  const [notification, setnotification] = useState(rowdata && rowdata?.notification ? rowdata?.notification : "");
  const [notificationTitle, setNotificationTitle] = useState(rowdata && rowdata?.notificationTitle ? rowdata?.notificationTitle : "");
  const [players, setPlayers] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPlayerIds, setSelectedPlayerIds] = useState([]);
  const lang = ctx.lang;
  const id = rowdata?.id;
  const { TextArea } = Input;
  const onSubmit = async (e) => {

    try {
      e.preventDefault();
      const path = `/admin/notifications/create`;
      // sending request
      const res = await PostApi.PostApiRequest(path, {
        title: notificationTitle,
        description: notification,
        players:selectedPlayerIds,
      });
      //if success
      if (res && res.status === 200) {
        message.success("Player Notification Created Successfuly");
        setnotification("");
        setNotificationTitle("");
        selectedPlayerIds([]);
        setPlayers([]);
        setPage(1);
      } else if (!res) {
        message.error("Player Notification Creation Failed");
      }
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav("/login");
      }
      
    }finally{
      onCancel();
      getNotification();
    }
  };

  const updateNofitication = async (e) => {
    try {
      e.preventDefault();

      const path = `/admin/notifications/update/${id}`;
      // sending request
      const res = await PutApi.PutApiRequest(path, {
        title:notificationTitle,
        description: notification,
      });
      //if success
      if (res && res.status === true) {
        message.success("Player Notification Updated Successfuly");
        setnotification("");
        setNotificationTitle("");
        onCancel();
        getNotification();
       
      } else if (!res) {
        message.error("Player Notification Creation Failed");
      }
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav("/login");
      }
     
    }
  };

  useEffect(() => {
    fetchPlayers(page);
  }, [page]);

  useEffect(()=>{
    if (rowdata) {
      const newPlayerList = rowdata?.player?.map((player) => player?.player?.user?.user_name);
      setSelectedPlayerIds(newPlayerList);
    }
  },[rowdata])

  const fetchPlayers = async (page,searchTerm) => {
    setLoading(true);
    try {
      const path = `/admin/players`;
      const params = {};
      params["page"] = page;
      if (searchTerm) params["search"] = searchTerm;

      const response = await GetApi.sendApiRequest(path, {}, params);
      // setPlayers(prevPlayers => [...prevPlayers, ...response.data]);
      setPlayers(prevPlayers => {
        // Create a set of existing player IDs for quick lookup
        const existingPlayerIds = new Set(prevPlayers.map(player => player.id));
        // Filter out players that are already in the state
        const newPlayers = response.data.filter(player => !existingPlayerIds.has(player.id));
        // Append the new, unique players to the existing state
        return [...prevPlayers, ...newPlayers];
    });
    } catch (error) {
      console.error('Failed to fetch players', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePopupScroll = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPage(prevPage => prevPage + 1);
    }
  };
  const handleChange = (value) => {
    setSelectedPlayerIds(value);
  };

  // Debounced function to call fetchPlayers (optional)
  const debounceFetchPlayers = (searchTerm) => {
    const timeoutId = setTimeout(() => fetchPlayers(page,searchTerm), 300);
    return () => clearTimeout(timeoutId);
  };
  
  return (
    <div
      style={{
        width: "97%",
        minHeight: "350px",
        height: "100%",
        justifyContent: "space-evenly",
      }}
    >
      <form onSubmit={type==="edit" ? updateNofitication : onSubmit}>
        <div
          style={{
            width: "97%",
            height: "100%",
            justifyContent: "space-around",
          }}
        >
          <Row>
            <Col span={24}>
              <label style={{}}>Notification</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <span style={{ height: "15px" }}>{""}</span>
              <Input
                //getting input value for search
                placeholder="Enter title here"
                value={notificationTitle}
                required
                style={{ width: "100%", borderRadius: "3px", marginTop: "10px" }}
                onChange={(e) => {
                  setNotificationTitle(e.target.value);
                }}
              />
              <TextArea
                type="text"
                style={{ width: "100%", borderRadius: "3px", marginTop: "10px", height: "70%" }}
                value={notification}
                required
                onChange={(e) => {
                  setnotification(e.target.value);
                }}
              />

              {/* <Select
                style={{ width: "100%", borderRadius: "3px", marginTop: "10px" }}
                allowClear
                mode="multiple"
                value={formData.status}
                onChange={(val) => setFormData((prevData) => ({ ...prevData, status: val }))}
                onClear={() => setFormData((prevData) => ({ ...prevData, status: null }))}
                options={[]}
              /> */}
              
              <Select
                mode="multiple"
                style={{ width: "100%", borderRadius: "3px", marginTop: "10px" }}
                placeholder="Select players"
                disabled={type==="edit" ? true : false}
                // onPopupScroll={handlePopupScroll}
                notFoundContent={loading ? <Spin size="small" /> : null}
                onChange={handleChange}
                value={selectedPlayerIds}
                required
                onSearch={debounceFetchPlayers}   
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {players.map(player => (
                  <Option key={player.id} value={player.id}>
                    {player?.user?.user_name}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>

          <br />
          <br />{" "}
          <Button
            style={{
              borderRadius: "3px",
              backgroundColor: "#004A7F",
              color: "white",
              position: "absolute",
              right: "30%",
              // top: "80%",
              bottom: '15px',
              margin: "-1%",
            }}
            htmlType="submit"
          >
            {type==="edit" ? lang.label_update : lang.label_submit}
          </Button>
          <Button
            style={{
              borderRadius: "3px",
              color: "#004A7F",
              position: "absolute",
              right: "8%",
              // top: "80%",
              bottom: '15px',
              margin: "-1%",
            }}
            onClick={() => {
              setnotification("")
              setSelectedPlayerIds([]);
              setNotificationTitle("");
              onCancel()
              setPage(1);
            }}
          >
            {lang.label_cancel}
          </Button>
        </div>
      </form>
    </div>
  );
};
export default AddPlayerNotification;
