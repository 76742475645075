import axios from "axios";

import { constants } from "../../contexts/Api/constants";
import { Navigate } from "react-router-dom";

const PutApiRequest = async (path, Data) => {
  const url = `${constants.BASE_URL1}` + `${path}`;
  const token1 = localStorage.getItem("token");
  return await axios
    .put(url, Data, {
      headers: {
        Authorization: `Bearer ${token1}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response?.status === 401) {
        localStorage.clear();
        return <Navigate to="/" replace />;
      } else if (response?.status === 200) {
        return response.data;
      } else return null;
    })
    .catch((e) => {
      if (e.response.request.status === 401) {
        localStorage.clear();
        return <Navigate to="/" replace />;
      }

      return Promise.reject(e.response);
    });
};

export default {
  PutApiRequest,
};
