const strings = {
  en: {
    label_name: 'Name',
    label_create: 'Create',
    label_agentid: 'Agent Unique Code',
    label_statusupdation: 'Status Updated Successfully',
    label_roleadsuccess: 'Role Created Successfully',
    label_roleadfail: 'Role Creation Failed',
    label_new_users: 'New Players',
    label_assignnewrolesuccess: 'Assigned New Role Successfully',
    label_assignnewrolefail: 'New Role Assignment Failed',
    label_promodeletesucess: 'Deleted Promotion Sucessfully!!!',
    label_promocategorydeletesucess: 'Deleted Promotion Category Sucessfully!!!',
    label_promodeletefail: 'Promotion Deletion Failed!!!!',
    label_promocategorydeletefail: 'Promotion Category Deletion Failed!!!!',
    label_roledelsuccess: 'Deleted Role Sucessfully!!!',
    label_roledelfail: 'Role Deletion Failed!!!',
    label_gameitem: 'Game Item',
    label_bethistory: 'Bet History',
    label_customeraccno: 'Customer Account Number',
    label_currency: 'Currency Name',
    label_Currency: 'Currency',
    label_approved: 'Approved',
    label_rejected: 'Rejected',
    label_pending: 'Pending',
    label_settleCycle : 'Settle Cycle',
    label_processing: 'Processing',
    label_promotionaddsuccess: 'Promotion Created Successfully',
    label_promotionaddfail: 'Promotion Creation Failed',
    label_beforepoints: 'Before Points',
    label_afterpoints: 'After Points',
    label_addnotification: 'Add Notification',
    label_read: 'Read',
    label_notification: 'Notification',
    label_createdby: 'Created By',
    label_createdat: 'Created At',
    label_playernotification: 'Player Notification',
    label_lock: 'Lock',
    label_unlock: 'Unlock',
    label_locked_by: 'Locked By',
    label_dashboard: 'Dashboard',
    label_logintoaccessdashboard: 'Login To Access Dashboard',
    label_usernameoremail: 'Username or Email',
    label_password: 'Password',
    label_login: 'Login',
    label_playerlist: 'Player List',
    label_filters: 'Filters',
    label_time: 'Time',
    label_daterange: 'Date Range',
    label_device: 'Device',
    label_selectdate: 'Select Date',
    label_all: 'All',
    label_Kick_From_All_Games: "Kick From All Games",
    label_search: 'Search ',
    label_reset: 'Reset',
    label_player: 'Player',
    label_player_id: 'Player ID',
    label_match_id: 'Match ID',
    label_username: 'User Name',
    label_phone: 'Phone Number',
    label_agent: 'Agent',
    label_registerip: 'Register Ip',
    label_registertime: 'Register Time',
    label_loginip: 'Login IP',
    label_logintime: 'Login Time',
    label_loginhistory: 'Login History',
    label_options: 'Options',
    label_ip: 'IP',
    label_addplayer: 'Add Player',
    label_editplayer: 'Edit Player',
    label_resetpwd: 'Reset Password',
    label_editAdminUser: 'Edit Admin User',
    label_is_turnover_check: 'Is Turnover Checked',
    label_turnover_multiplier: 'Turnover Multiplier',
    label_fillinfo: 'Fill all information as below',
    label_allowdeposit: 'Allow Deposit',
    label_id: 'ID',
    label_bankname: 'Bank Name',
    label_riskverify: ' Risk Verification',
    label_financeverify: 'Finance Verification',
    label_withdrawverify: 'Withdraw Verification',
    label_withdrawaccessdetails: 'Access all the Withdraw details as below',
    label_financeapproval: 'Finance Approval',
    label_bankname1: 'Company Bank Name',
    label_bankname2: 'Customer Bank Name',
    label_companyBankAcc: 'Company Bank Account No',
    label_company_payment_method: 'Company Payment Method',
    label_attachment: 'Attachment',
    label_cardholder: 'Card Holder',
    label_addremark: 'Add Remarks',
    label_accountnumber: 'Account Number',
    label_name_public: 'Public Name',
    label_internal_name: 'Internal Name',
    label_city: 'City',
    label_branch: 'Branch',
    label_addeddate: 'Added Date',
    label_updateddate: 'Updated Date',
    lable_datetype: 'Date Type',
    label_operation: 'Operation',
    label_userdetails: 'User Details',
    label_balancehistory: 'Balance History',
    label_userbanks: 'User Bank',
    label_playerdetails: 'Player Details',
    label_pending_fa_approval: 'Pending FA Approval',
    label_user: 'User',
    label_edit: 'Edit',
    label_enable: 'Enable',
    label_disable: 'Disable',
    label_withdrawalfunction: 'Withdrawal Function',
    label_phonenumber: 'Phone Number',
    label_uniqueid: 'Unique ID',
    label_createdtime: 'Created Time',
    label_actiontime: 'Action Time',

    label_newpassword: 'New Password',
    label_confirmpassword: 'Confirm Password',
    label_promotions: 'Promotions',
    label_conditions:'Conditions',
    label_rewards: 'Rewards',
    label_promotionsCategory: 'Promotion Category',
    label_permissions: 'Permissions',
    label_addpermissions: 'Add New Permission',
    label_no_permission: 'No Permission',
    label_addrole: 'Add Role',
    label_role: 'Roles',
    label_userlist: 'User List',
    label_action: 'Action',
    label_editrole: 'Edit Role',
    label_editbank: 'Edit Bank',
    label_deleteconfirmation: 'Delete Confirmation',
    label_save: 'Save',
    label_delete: 'Delete',
    label_confirm: 'Confirm',
    label_doyouwanttodelrole: 'Do you want to delete this role??',
    label_changerole: 'Change Role',
    label_changestatus: 'Change Status',
    label_toggledeposit: 'Deposit Status',
    label_togglewithdraw: 'Withdraw Status',
    // label_companybankshistory: 'Company Banks History',
    label_companybankshistory: 'Payment Method History',
    label_amount: 'Amount',
    label_norecord: 'No Record Found',
    label_actionby: 'Action By',
    label_transactionid: 'Transaction ID',
    label_createddate: 'Created Date',
    label_starteddate: 'Started Date',
    label_note: 'Note',
    label_incomingdate: 'Incoming Date',
    label_message: 'Message',
    label_userstatus: 'User Status',
    label_deactivate: 'Deactivate Now',
    label_activate: 'Activate Now',
    label_image: 'Image',
    label_desktopImage: 'Desktop Image',

    label_title: 'Title',
    label_subtitle : 'Sub Title',
    label_expirydate: 'Expiry Date',
    label_turnedonby: 'Turned On By',
    label_addpromotion: 'Add New Promotion',
    label_period: 'Period',
    label_editpromotion: 'Edit Promotion',
    label_editpromotioncategory: 'Edit Promotion Category',
    label_deletepromotion: 'Delete Promotion',
    label_deletepromotionCat: 'Delete Promotion Category',
    label_editpromosucess: 'Promotion Updated Successfully',
    label_editpromocategorysucess: 'Promotion Category Updated Successfully',
    label_editpromofail: 'Promotion Updation Failed',
    label_editpromocategoryfail: 'Promotion Category Updation Failed',
    label_total: 'Total',
    label_regusers: 'Registered Users',
    label_delpromoconfirm: 'Are you sure that you want to delete this promotion details?',
    label_delpromocategoryconfirm: 'Are you sure that you want to delete this promotion category details?',
    label_edituserbank: 'Edit User Bank',
    label_viewmore: 'View More',
    label_latestlogins: 'Latest Logins',
    label_addbank: 'Add Bank',
    label_accessall: 'Access all the user details as below',
    label_latesttransactions: 'Latest Transactions',
    label_disablebankinfo: 'Are you sure you wish to disable this bank?',
    label_disablebank: 'Disable Bank',
    label_enablebankinfo: 'Are you sure you wish to Enable this bank?',
    label_enablebank: 'Enable Bank',
    label_areyousuretodelete: 'Are you sure you want to delete this company bank details?',
    label_depositdetails: 'Deposit Details',
    label_withdraw: 'Withdraw',
    label_to_reach:'To Reach',
    label_signup_days:'Signup Days',
    label_deposit: 'Deposit',
    label_bet: 'Bet',
    label_promotion: 'Promotion',
    label_manual_adding: 'Manual Adding',
    label_manual_deducting: 'Manual Deducting',
    label_event_reward: 'Event Reward',
    label_affiliate: 'Affiliate',
    label_companybanks: 'Company Banks',
    label_bankenablesuccess: 'Bank has been enabled successfully',
    label_bankenablefail: 'Error!!!Bank could not be activated',
    label_bankdisablesuccess: 'Bank has been disabled successfully',
    label_bankdisablefail: 'Bank could not be disabled',
    label_bankmessages: 'Bank Messages',
    label_paymentmethods: 'Payment Methods',
    label_add_payment_method_success: 'Payment Method Added Successfully',
    label_add_payment_category_success: 'Payment Category Added Successfully',
    label_adjustment: 'Adjustment',
    label_bankimage: 'Bank Image',
    label_bankaccountname: 'Bank Account Name',
    label_bankCurrencyname: 'Currency Name',
    label_payment_code: 'Payment Code',
    label_bankaccountnumber: 'Bank Account Number',
    label_balance: 'Balance',
    label_baseBalance: 'Base Balance',
    label_callbackkey: 'Callback Key',
    label_apikey: 'API Key',
    label_apiurl: 'API URL',
    label_accountname: 'Account Name',
    label_bankcode: 'Bank Code',
    label_bankcodes: 'Bank Codes',
    label_updatebank: 'Update Bank',
    label_bank_max_limit_today: 'Max Daily Amount',
    label_max_total_amt: 'Max Total Amount',
    label_transactiontype: 'Transaction Type',
    label_selecttransactiontype: 'Select Transaction Type',
    label_deletecompanybank: 'Delete Company Bank',
    label_transactionmanagement: 'Transaction Management / Deposit',
    label_playermanagement: 'Player Management',
    label_agentmgmt: 'Agent Management',
    label_adminmgmt: 'Admin Management',
    label_phno: 'Phone Number',
    label_gender: 'Gender',
    label_bday: 'Birthday',
    label_status: 'Status',
    label_groupby: 'Group by',
    label_country: 'Country',
    label_isAuto:'Auto',
    label_add: 'Add',
    label_promot_cat_id:'Category ID',
    label_claimable_limit:'Claimable Limit',
    label_promotion_category_id: 'Category',
    label_active: 'Active',
    label_inactive: 'Inactive',
    label_allowbetting: 'Allow Betting ',
    label_withdrawl: 'Withdrawl Function',
    label_view: 'View',
    label_remark: 'Remark',
    label_tagname: 'Tag Name',
    label_no_withdrawal: 'NoWithdrawal',
    label_high_risk_member: 'HighRiskMember',
    label_monitor_user: 'Monitor User',
    label_cancel: 'Cancel',
    label_submit: 'Submit',
    label_playerbalancehistory: 'Player Balance History',
    label_gametransactionhistory: 'Game Transaction History',
    label_taransaction: 'Transaction',
    label_resetpassword: 'Reset Password',
    label_birthday: 'Birthday',
    label_type: 'Type',
    label_allowwithdraw: 'Allow Withdraw',
    label_selecttime: 'Select Time',
    label_companybank: 'Company Bank',
    label_customerbank: 'Customer Bank',
    label_customer_payment_method: 'Customer Payment Method',
    label_approvedby: 'Approved By',
    label_rejectedby: 'Rejected By',
    label_sendmessage: 'Send Message',
    label_viewplayer: 'View Player',
    label_addremovepoints: 'Add/Remove Points',

    label_playermanagement_playerlist_playerDetails: 'Player Management / Playerlist / PlayerDetails',
    label_addbankmessage: 'Add Message',
    label_upload: 'Upload',
    label_changeagent: 'Change Agent',
    label_newagent: 'New Agent',
    label_editagent: 'Edit Agent',
    label_edittagname: 'Edit Tag Name',
    label_editsupagent: 'Edit Superior Agent',
    label_newagentname: 'New Agent Name',
    label_previousagent: 'Previous Agent',
    label_changedby: 'Changed By',
    label_updatebalance: 'Update Balance',
    label_language: 'Language',

    label_uppercaseonly: 'Please Enter Only Numbers and Lowercase Alphabets',
    label_typeagentname: 'Type a new agent name as below',
    label_gamecategoryname: 'Game Category Name',
    label_gameCategory_id : 'Game Category ID',
    label_platformname: 'Platform Name',
    label_platformcode: 'Platform Code',
    message_file_format_invalid: 'File Format Invalid',
    message_file_size_exceeded: 'File Size Exceeded',

    //error messages
    label_wrongpassword: 'Wrong Password',
    label_changeagenthistory: 'Change Agent History',
    label_userdoesnotexist: 'User Does Not Exist',
    label_playercreatsuccess: 'Player Created Successfully',
    label_playercreatfail: 'Player Creation Failed',
    label_playerupdatesuccess: 'Player Updated Successfully',
    label_sucessupdatebalance: 'Balance Updated Successfully',
    label_playerupdatefail: 'Player Updation failed',
    label_updatebalanceerror: 'Balance Update failed',
    label_Bankmessagecreatesuccess: ' Company Bank Message Created Successfully',
    label_Bankmessagecreatefail: 'Company Bank Message Creation Failed',
    label_addbanksuccess: 'Added Company Bank Successfully',
    label_bankaddfailed: 'Bank could not be added, Please make sure all fields are filled',
    label_bankaddedsuccess: 'Bank has been added successfully',

    label_addbankfail: 'Company Bank Creation Failed',
    label_adjcreation: 'Adjustment Has Been made Successfully',
    label_adjcreationfail: 'Adjustment Creation Failed',
    label_companybankupdatesuccess: 'Company bank Updated',
    label_companybankupdatefail: 'Company bank Updation failed',

    label_depositfail: 'Status Updation Failed',
    label_maintenance_from: 'Maintenance From',
    label_maintenance_until: 'Maintenance Until',
    label_maintainance : 'Maintenance',
    label_display_for_players: 'Display for players',
    label_bank_code: 'Bank Code',
    label_create_bank_code: 'Create New Bank Code',
    // _______________________________________________________________________________________________________________________________
    // Dont remove these extra spaces

    label_id: 'ID',
    label_user_name: 'User Name',
    label_agent_id: 'Agent ID',
    label_amount: 'Amount',
    label_amount_limits: 'Amount Limits',
    label_deposit_currency: 'Deposit Currency',
    label_payment_method: 'Payment Method',
    label_created_time: 'Created Time',

    label_payment_category: 'Payment Category',

    label_bank_city: 'Bank City',
    label_bank_branch: 'Bank Branch',
    label_payment_methods: 'Payment Methods',
    label_add_payment_methods: 'Add Payment Methods',
    label_bank_code: 'Bank Code',
    label_category_name: 'Category Name',

    label_select_all: 'Select All',
    label_deselect_all: 'Deselect All',

    label_parent_category_name: 'Parent Category Name',
    label_is_lobby: 'Is Lobby',

    label_reference_no: 'Reference No',

    label_pending_risk_approval: 'Pending Risk Approval',
    label_updated_by: 'Updated By',

    // Dont remove these extra spaces
    // _______________________________________________________________________________________________________________________________
    label_game_properties: 'Game Properties',
    label_icon_trend: 'Icon Trend',
    label_icon_bgImg: 'Background Image',
    label_agenteditsuccess: 'Agent Updated Successfully',
    label_agenteditsupsuccess: 'Senior Agent Updated Successfully',

    label_gamelist: ' Game List',
    label_gameplatformitems: 'Game Category Platform Items',
    label_playername: 'Player Name',
    label_gamename: 'Game Name',
    label_date: 'Date',
    label_points: 'Points',
    label_gameaccesshistory: 'Game Access History',

    label_adminloginhistory: 'Admin Login History',
    label_depositsuccess: 'Deposit Status Updated Successfully',

    label_depositconfirm: 'Are you Sure You want to change the status of Deposit??',
    label_withdrawsuccess: 'Withdraw Status Updated Successfully',
    label_withdrawfail: 'Status Updation Failed',
    label_withdrawconfirm: 'Are you Sure You want to change the status of Withdraw??',
    label_agentlist: 'Agent List',
    label_agentreport: 'Agent Report',
    label_agentNotification: 'Agent Notification',
    label_uniquecode: 'UniqueCode',
    label_createagent: 'Add Agent',
    label_superioragent: 'Superior Agent',
    label_addsuperioragent: 'AddSuperiorAgent',
    label_agentaddsuccess: 'Agent Created Successfully',
    label_agentaddfail: 'Agent Creation Failed',
    label_superagentaddsuccess: 'Superior Agent Created Successfully',
    label_superagentaddfail: 'Superior Agent Creation failed',
    label_platforms: 'Platforms',
    label_platform: 'Platform',
    label_gamecategories: 'Game Categories',

    label_minimum: 'Minimum',
    label_maximum: 'Maximum',
    label_minimum_deposit: 'Minimum Deposit Amount',
    label_maximum_deposit: 'Maximum Deposit Amount',
    label_minimum_withdraw: 'Minimum Withdraw Amount',
    label_maximum_withdraw: 'Maximum Withdraw Amount',
    label_payment_category: 'Payment Category',
    label_create_category: 'Create New Category',
    label_payment_categories: 'Payment Categories',
    label_yes: 'Yes',
    label_no: 'No',
    label_previousbalance: 'Previous Balance',
    label_newbalance: 'New Balance',
    label_ignoretestaccount: 'Ignore Test Account',
    label_gametransactionid: 'Game Transaction ID',
    label_totalwithdrawalamount: 'Total Withdrawal Amount :',
    label_totaldepositamount: 'Total Deposit Amount :',
    label_totalamount: 'Total Amount :',
    label_withdrawal: 'Withdrawal',
    label_completed: 'completed',
    label_failed: 'Failed',

    //translations game management platforms
    label_addnewplatform: 'Add New Platform',
    label_updateplatform: 'Update Platform',
    label_deleteplatform: 'Delete Platform',
    label_deletegamecat: 'Delete Game Category',
    label_addinfo: 'Type new platform details as below',
    label_editinfo: 'Update the platform details as below',
    label_del: 'Delete the platform details',
    label_delcat: 'Delete the game category details',
    label_platformid: 'Platform Code',
    label_icon: 'Icon',
    label_platformicon: ' Platform Icon',
    label_actions: 'Actions',
    label_update: 'Update',
    label_deleteplatforminfo: 'Are you sure that you want to delete this platform details?',
    label_deletegameinfo: 'Are you sure that you want to delete this game details?',
    label_deletegamecategory: 'Are you sure that you want to delete this game category details?',
    //translations game management gamecategories
    label_category: 'Category Name',
    label_sort: 'Sort Order',
    label_updatedtime: 'Updated Time',
    label_updatedby: 'Updated By',
    label_addnewcategory: 'Add New Category',
    label_editcategory: 'Update Category',
    label_addcatinfo: 'Type new Category details as below',
    label_editcatinfo: 'Update the Category details as below',
    label_en: '(EN)',
    label_vn: '(VN)',
    label_bn: '(BN)',
    label_tl: '(TL)',
    label_gameid: 'Game Id',
    label_game_type: "Game Type",
    label_gameplatform: 'Game Platform',
    label_gamecategory: 'Game Category',
    label_addgameitem: 'Add Game Item',
    label_updategameitem: 'Update Game Item',
    label_deletegame: 'Delete Game',
    label_addgameinfo: 'Type new game item details as below',
    label_updategameinfo: 'Update the game item details as below',
    label_iconsq: 'Icon Square',
    label_iconrec: 'Icon Rectangle',
    label_iconactive: 'Icon Active',
    //success or error messages
    label_platformaddsuccess: 'Platform Added Successfully',
    label_platformdelsuccess: 'Platform Deleted Successfully',
    label_platformeditsuccess: 'Platform Updated Successfully',
    label_gamecataddsuccess: 'Game Category Created  Successfully',
    label_gamecateditsuccess: 'Game Category updated successfully',
    label_gameaddsuccess: 'Game Details Created successfully',
    label_gameeditsuccess: 'Game Details Updated Successfully',
    label_gamedelsuccess: 'Game Details removed successfully',
    label_receiptno: 'Receipt is not available',
    label_approve: 'Approve',
    label_manual: 'Manual',
    label_riskapprove: 'Risk Approve',
    label_risk_verify: 'Risk Verify',
    label_fa_verify: 'Finance Verify',
    label_reject: 'Reject',
    label_riskreject: 'Risk Reject',
    label_receipt: 'Receipt',
    label_cusnotice: ' Customer Notice',
    label_totaldeposit: 'Total Deposit',
    label_totalwithdraw: 'Total Withdraw',
    label_noofregisteredtoday: 'Number of Registered Today',
    label_todaytotalprofit: 'Today Total profit',
    label_platformname: 'Platform Name',
    label_provider: 'Provider',
    label_ipaddress: 'IP Address',
    label_device: 'Device',
    label_referenceid: 'Reference ID',
    label_reference_ticket_id: 'Reference Ticket ID',
    label_startedon: 'Started On',
    label_endedon: 'Ended On',
    label_currency: 'Currency',
    label_winloss: 'Win Loss',
    label_winloss_limit: 'Win Loss Limit',
    label_winloss_this_month: 'Win/Loss This Month',
    label_total_winloss_purchases: 'Win/Loss Purchases This Month',
    label_winloss_remaining: 'Win/Loss Remaining',
    label_bets: 'Bets',
    label_refresh: 'Refresh',
    label_ticket_id:'Ticket ID',

    label_settings: 'Settings',
    label_game_code: 'Game Code',
    label_default: 'Default',
    label_first_deposit_date: 'First Deposit Date',
    label_first_withdraw_date: 'First Withdraw Date',
    label_group_level: 'Group Level',
    label_real_name: 'Real Name',
    label_agent: 'Agent',
    label_customer_message: 'Customer Message',
    label_deposit_ip: 'Deposit IP',
    label_finance_remark: 'Finance Remark',
    label_approved_time: 'Approved Time',
    label_withdraw_request_ip: 'Withdraw Request IP',
    label_customer_bank_account_name: 'Customer Bank Account Name',
    label_customer_bank_account_number: 'Customer Bank Account No.',
    label_customer_bank_code: 'Customer Bank Code',
    label_turnover_multiplier: 'Turnover Multiplier',
    label_promotion: 'Promotion',
    label_promotion_history: 'Promotion History',
    label_turnover_limit: 'Turnover Limit',
    label_is_turnover_check: 'Is Turnover Checked',
    label_turnover_multiplier: 'Turnover Multiplier',
    label_transfer_no: 'Transfer No',
    label_phone_verified: 'Phone Verified',
    label_last_seen : "Last Seen",
    label_rebate: 'Rebate',
    label_rebate_title: 'Rebate Title',
    label_start_at: 'Start At',
    label_end_at: 'End At',
    label_rebate_cycle: 'Rebate Cycle',
    label_max_group_rebate: 'Max Group Rebate',
    label_updated_at: 'Updated At',
    label_category_title: 'Category',
    label_add_rebate: 'Add Rebate',
    label_description: 'Description',
    label_date_range: 'Date range',
    label_max_rebate: 'Max Group Rebate(0 is unlimited)',
    label_conditional_multiplayer: 'Conditional Multiplier',
    label_game_provider: 'Game Provider',
    label_valid_bet: 'Valid Bet',
    label_valid_bet_amount: 'Valid Bet Amount',
    label_bet_amount: 'Bet Amount',
    label_odds: 'Odds',
    label_rebate_recommended: 'Rebate% Recommended<=1',
    label_maxrebate: 'Max Rebate (0 is unlimited)',
    label_add_new_row: 'Add New Row',
    label_WL_amount: 'W/L amount',
    label_timezone: 'Time Zone',
    label_affiliate_list: 'Affiliate List',
    label_affiliate_id: 'Affiliate ID',
    label_player_username: 'Player Username',
    label_referral_bonus_count: 'Referral Bonus Count',
    label_referral_bonus_amount: 'Referral Bonus Amount',
    label_program_title: 'Program Title',
    label_referral_bonus_downline_requirement: 'Referral Bonus Downline Requirement',
    label_min_deposit: 'Min Deposit',
    labe_min_valid_bet: 'Min Valid Bet',
    label_phone_validated: 'Phone Validated',
    label_bonus_amount: 'Bonus Amount',
    label_to_affiliate: 'To Affiliate',
    label_to_downline: 'To Downline',
    label_condition_multiplier: 'Condition Multiplier',
    label_add_new__rules: 'Add new rules',
    label_rules: 'Rules',
    label_search_by: 'Search By',
    label_start_date: 'Start Date',
    label_end_date: 'End Date',
    label_tag_management: 'Tag Management',
    label_tag_name: 'Tag Name',
    label_total_players: 'Total Players',
    label_domain_name: 'Domain Name',
    label_tag_type: 'Tag Type',
    label_updating_time: 'Updating Time',
    label_tags_adjustment_history: 'Tags Adjustment History',
    label_add_tag: 'Add Tag',
    label_upd_on: 'Upd. On',
    label_modify_account: 'Modify Account',
    label_before_modify_tags: 'Before Modify Tags',
    label_after_modify_tags: 'After Modify Tags',
    label_operate_by: 'Operate By',
    label_agent_type: 'Agent Type',
    label_credit_line: 'Credit Line',
    label_max_credit: 'Max Credit',
    label_update_credit: 'Update Credit',

    //Agent List
    label_agent_name : "Agent Name",
    label_agent_level : "Agent Level",
    lable_real_name: "Real Name",
    lable_settle_cycle:"Settle Cycle",
    lable_registed_at:"Register At",
    lable_down_lines:"Downlines",
    lable_total_player:"Total Players",
    lable_wallet_balance:"Wallet Balance",
    lable_status:"Status",
    lable_update_at:"Update At",
    lable_update_by:"Update By",
    lable_actions:"Actions",
    // Position Report Table
    lable_commission : "Commission %",
    lable_active_player :"Active Player",
    lable_direct_player :"Direct Player Valid Turnover",
    lable_direct_player_revenue :"Direct Player Revenue",
    lable_direct_player_revenue_valid :"Direct Player Valid Revenue",
    lable_shared_expense :"Shared Expense",
    lable_winloss_fee :"Win/Loss Fee",
    lable_direct_profit :"Direct Profit",
    lable_downline_profit :"Downline Profit",
    lable_total_commission :"Total Commission",

    // side bar 
    lable_commission_report:"Commission Report",
    lable_position_report:"Position Report"
  },

  vn: {
    label_dashboard: 'Dasssss',
    label_logintoaccessdashboard: 'đăng nhập để truy cập bảng điều khiển',
    label_usernameoremail: 'Tên người dùng hoặc email',
    label_password: 'mật khẩu',
    label_login: 'đăng nhập',
    label_playerlist: 'Danh sách người chơi',
    label_filters: 'bộ lọc',
    label_settleCycle: 'Chu kỳ Thanh toán',
    label_time: 'Thời gian',
    label_device: 'thiết bị',
    label_status: 'Trạng thái',
    label_selectdate: 'chọn ngày',
    label_selecttime: 'chọn thời gian',
    label_all: 'Tất cả',
    label_Kick_From_All_Games : "Đuổi khỏi tất cả trò chơi",
    label_search: 'Tìm kiếm',
    label_reset: 'Cài lại',
    label_addplayer: 'Thêm người chơi',
    label_username: 'tên tài khoản',
    label_name: 'Tên',
    label_phone: 'Điện thoại',
    label_agent: 'Đại lý',
    label_balance: 'Sự cân bằng',
    label_baseBalance: 'Cân bằng cơ bản',
    label_registerip: 'Đăng ký IP',
    label_registertime: 'Thời gian đăng ký',
    label_loginip: 'IP đăng nhập lần cuối',
    label_logintime: 'Thời gian đăng nhập lần cuối',
    label_options: 'Tùy chọn',
    label_playermanagement: 'Quản lý người chơi',
    label_agentmanagement: 'Quản lý đại lý',
    label_adminmanagement: 'quản lý quản trị'
  }
};

export default strings;
