import React from 'react';
import { Button } from 'antd';

const CustomButton = ({ text, type, onClick, className, disabled = false, style }) => {
  return (
    <Button onClick={onClick} className={`${type} ${className}`} disabled={disabled} style={style}>
      {text}
    </Button>
  );
};

export default CustomButton;
