import React from 'react';
import { Table } from 'antd';
import styles from './AgentReport.module.css';

const AgentTable = () => {
  const columns = [
    { title: 'Agent ID', dataIndex: 'agentId', key: 'agentId' },
    { title: 'Real Name', dataIndex: 'realName', key: 'realName' },
    { title: 'Agent Level', dataIndex: 'agentLevel', key: 'agentLevel' },
    { title: 'Commission', dataIndex: 'commission', key: 'commission' },
    { title: 'Downlines', dataIndex: 'downlines', key: 'downlines' },
    { title: 'New Registrations', dataIndex: 'newRegistrations', key: 'newRegistrations' },
    { title: 'Total Players', dataIndex: 'totalPlayers', key: 'totalPlayers' },
    { title: 'Deposit Users/First Deposit Users', dataIndex: 'depositUsers', key: 'depositUsers' },
    { title: 'Deposit Amount', dataIndex: 'depositAmount', key: 'depositAmount' },
    { title: 'Withdrawal Users', dataIndex: 'withdrawalUsers', key: 'withdrawalUsers' },
    { title: 'Withdrawal Amount', dataIndex: 'withdrawalAmount', key: 'withdrawalAmount' },
    { title: 'Balance Adjustment', dataIndex: 'balanceAdjustment', key: 'balanceAdjustment' },
    { title: 'Total Bets', dataIndex: 'totalBets', key: 'totalBets' },
    { title: 'Effective Turnover', dataIndex: 'effectiveTurnover', key: 'effectiveTurnover' },
    { title: 'Total Win/Loss', dataIndex: 'totalWinLoss', key: 'totalWinLoss' },
    { title: 'Rebate', dataIndex: 'rebate', key: 'rebate' },
    { title: 'Promotional Bonus', dataIndex: 'promotionalBonus', key: 'promotionalBonus' }
  ];

  const data = [
    {
      key: '1',
      agentId: 'A001',
      realName: 'John Doe',
      agentLevel: 'Level 1',
      commission: 1200,
      downlines: 15,
      newRegistrations: 5,
      totalPlayers: 50,
      depositUsers: 30,
      depositAmount: 10000,
      withdrawalUsers: 10,
      withdrawalAmount: 5000,
      balanceAdjustment: 200,
      totalBets: 50000,
      effectiveTurnover: 45000,
      totalWinLoss: 3000,
      rebate: 500,
      promotionalBonus: 1500,
    },
    // Add more rows as needed
  ];

  const calculateSubtotal = (key) => data.reduce((acc, curr) => acc + curr[key], 0);

  return (
    <div>
      <h2 className={styles.heading}>Downline Team Report</h2>
      <Table
        style={{ width: '100%', marginTop: '30px', color: 'black' }}
        columns={columns}
        dataSource={data}
        rowKey="agentId"
        className={styles.agentTable}
        pagination={{ pageSize: 10 }}
        summary={() => (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={3}>Subtotal</Table.Summary.Cell>
              {columns.slice(3).map((col) => (
                <Table.Summary.Cell key={col.key} index={col.key}>
                  {calculateSubtotal(col.dataIndex)}
                </Table.Summary.Cell>
              ))}
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={3}>Total</Table.Summary.Cell>
              {columns.slice(3).map((col) => (
                <Table.Summary.Cell key={col.key} index={col.key}>
                  {calculateSubtotal(col.dataIndex)}
                </Table.Summary.Cell>
              ))}
            </Table.Summary.Row>
          </>
        )}
      />
    </div>
  );
};

export default AgentTable;
