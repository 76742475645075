import HeaderCardDetails from '../../../components/HeaderDetailCard';
import React, { useState, useContext } from 'react'; // Import React and useState hook
import { Button, DatePicker, Modal, Select, Spin, Input } from 'antd'; // Ant Design components
import { LoadingOutlined, CalendarOutlined } from '@ant-design/icons'; // Ant Design icons
import dayjs from 'dayjs'; // For date manipulation

import styles from './AgentReport.module.css';
import Heading from '../../../components/HelperComponents/Heading';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import SearchIcon from '../../../images/Search.png';
import ResetIcon from '../../../images/Reset.png';

// import 'antd/dist/antd.css';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import LangContext from '../../../helps/contexts/lang-context';
import moment from 'moment';
import AgentReportList from './AgentReportList';

const AgentReport = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const [selectedValue, setSelectedValue] = useState(1); // Default to "Agent ID"

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  const [visible, setVisible] = useState(false);
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const { RangePicker } = DatePicker;

  const { hasPermission } = useCheckPermission();
  const onChange = (date, dateString) => {
    setStartDate(dateString);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Call API to fetch data based on filters
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      // const response = await api.getData({ startDate, endDate, status, searchText });
      // setData(response.data);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };
  const iconStyle = { color: '#004A7F' };

  const onSubmit = (e) => {
    e.preventDefault();
    // setActivepage1(1);
    // getPlayers();
  };
  const cardTitle = ' Agent Report ';
  // tagname ? `${lang.label_filters} / ${tagname}` : lang.label_filters;
  const showModal = () => {
    setVisible(true);
  };
  const datePickerStyle = {
    width: '185px',
    height: '35px',
    background: ' #FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const selectStyle = {
    width: '188px',
    height: '33px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '3px'
    , marginRight:"10px"
  };
  const inputStyle = {
    width: '190px',
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  return (
    <div>
      <HeaderCardDetails />
      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div>
          <div className={styles.container}>
            <div
              className={styles.filterUpperContainer}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: '3px'
              }}>
              <Heading>{cardTitle}</Heading>
            </div>
            <div className={styles.filterLowerContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  width: '100%',
                  paddingRight: '14px',
                  flexWrap: 'wrap'
                }}>
                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_daterange}
                  </SubHeading>
                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-around',
                      alignItems: 'center'
                    }}>
                      <RangePicker
                      // style={{
                      //   width: '200px',
                      //   height: '35px'
                      // }}
                      defaultValue={[moment('2024-06-30'), moment('2024-06-30')]}
                      format="YYYY-MM-DD dddd"
                      ranges={{
                        Today: [moment(), moment()]
                      }}
                    /> 
                    {/* <RangePicker
                      defaultValue={[moment('2024-06-30'), moment('2024-06-30')]}
                      format="YYYY-MM-DD dddd"
                      ranges={{
                        Today: [moment(), moment()]
                      }}
                      style={{
                        width: '220px',
                        height: '38px',
                        padding: '5px 10px',
                        borderRadius: '8px',
                        border: '1px solid #d9d9d9',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#fff'
                      }}
                      dropdownClassName="custom-range-picker-dropdown"
                    /> */}

                    {/* <DatePicker
                      //getting input value for start date
                      placeholder="Select Time"
                      showTime={{
                        defaultValue: dayjs('00:00:00', 'HH:mm:ss')
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      onChange={onChange}
                      value={startDate}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    />
                    <span
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        marginLeft: '1%',
                        marginRight: '1%'
                      }}>
                      -
                    </span>
                    <DatePicker
                      //getting input value for start date
                      placeholder="Select Time"
                      showTime={{
                        defaultValue: dayjs('23:59:59', 'HH:mm:ss')
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      // onChange={onChange1}
                      value={endDate}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    /> */}
                  </div>
                </div>

                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_status}
                  </SubHeading>
                  <div>
                    <Select
                      defaultValue="All"
                      style={selectStyle}
                      // onChange={handleChange}
                      customWidth="200px"
                      extraStyle={{ height: '35px' }}
                      options={[
                        {
                          value: 1,
                          label: 'All'
                        },
                        {
                          value: '0',
                          label: 'Active'
                        },
                        // for online
                        {
                          value: 2,
                          label: 'Disable'
                        }
                      ]}
                    />
                  </div>
                </div>
                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_groupby}
                  </SubHeading>
                  <div>
                    <Select
                      defaultValue="Team"
                      style={selectStyle}
                      // onChange={handleChange}
                      customWidth="200px"
                      extraStyle={{ height: '35px' }}
                      options={[
                        
                        {
                          value: '0',
                          label: 'Team'
                        },{
                          value: 1,
                          label: 'Individual'
                        },
                        // for online
                        // {
                        //   value: 2,
                        //   label: 'Online'
                        // }
                      ]}
                    />
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_search_by}
                  </SubHeading>

                  <div>
                    <Select
                      defaultValue={1} // Set to default value of "Agent ID"
                      style={selectStyle}
                      // onChange={handleChange}
                      customWidth="200px"
                      extraStyle={{ height: '35px'  }}
                      onChange={handleSelectChange}
                      options={[
                        {
                          value: 1,
                          label: 'Agent ID'
                        },
                        {
                          value: '0',
                          label: 'Agent Level'
                        }
                      ]}
                    />

                    {selectedValue === 1 && (
                      <Input placeholder="Enter Agent ID" style={{  width: 200, height: 35 }} />
                    )}

                    {selectedValue === '0' && (
                      <Select
                        defaultValue="CM"
                        placeholder="Select Agent Level"
                        style={{ marginLeft: 10,  width: 200, height: 35 }}
                        options={[
                          { value: 'level1', label: 'EA' },
                          { value: 'level2', label: 'MA' },
                          { value: 'level3', label: 'SA' },
                          { value: 'level4', label: 'JA' },
                          { value: 'level5', label: 'AG' },
                          { value: 'Default', label: 'CM' }
                          //                           - **CM:** Show general agent data.
                          // - **EA:** Show first-level agent data.
                          // - **MA:** Show second-level agent data.
                          // - **SA:** Show third-level agent data.
                          // - **JA:** Show fourth-level agent data.
                          // - **AG:** Show fifth-level agent data.
                          // - Default is CM
                        ]}
                      />
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    marginTop: '20px',
                    marginLeft: '10px',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                  }}>
                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1'
                    }}
                    htmlType="submit"
                    className="btnStyle"
                    onClick={() => {
                      // setActivepage1(1);
                      setLoading(true);
                    }}>
                    <img src={SearchIcon} alt="search" />
                    {lang.label_search}
                  </Button>

                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      borderRadius: '3px',
                      padding: '6px',
                      border: '1px solid grey',
                      width: '84px'
                    }}
                    className="btnReset"
                    htmlType="reset"
                    onClick={() => {
                      setStartDate('');
                      setEndDate('');
                      // setSearchtext(null);
                      setStatus('');
                      // getPlayersWithouparams();
                      // setTagName('');
                      // setagentId('');
                    }}>
                    <img src={ResetIcon} alt="reset" />
                    {lang.label_reset}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          
          <AgentReportList/>
          {/* <PlayerListTable
            data={playerData}
            loading={loading}
            showModal={showModal}
            handleSubmit={handleOk}
            handleCancel={handleCancel}
            visible={visible}
            getPlayers={getPlayers}
          /> */}
          <br />
          {/* <Pagination
            defaultCurrent={1}
            currentPage={activepage}
            onChange={onChangepage}
            total={total}
            responsive={true}
            pageSize={perpage}
          /> */}
          {/* <PaginationComp paginationConfig={paginationConfig} /> */}
        </div>
      </form>
    </div>
  );
};

export default AgentReport;
