import { Button, Switch, Table, message } from 'antd';
import React, { useContext } from 'react';
import { MdEdit } from 'react-icons/md';
import NoPermissionLock from '../../../components/HelperComponents/NoPermissionLock';
import PutApi from '../../../helps/contexts/Api/PutApi';
import LangContext from "../../../helps/contexts/lang-context";
import useCheckPermission from "../../../helps/hooks/useCheckPermission";

const PaymentCategoriesTable = ({tableData, setTableData, loading, onEditClicked}) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { hasPermission } = useCheckPermission()

  const toggleStatus = async (id) => {
    toggleInLocal(id);
    let url = "/payment/category/"+id+"/status/toggle";
    PutApi.PutApiRequest(url, {}, {}).then((res) => {
      if(!res || !res.status){
        toggleInLocal(id);
      }else{
        message.success("Action has been updated");
      }
    }).catch((e) => { 
        toggleInLocal(id);
    });
  }


  const toggleInLocal = (id) => {
    setTableData(prevData => prevData.map((record) => {
        if(record.id === id){
          record.is_enabled = record.is_enabled ? 0 : 1;
        }
        return record;
      }));
  }

  const columns = [
    {
      title: lang.label_category_name,
      dataIndex: "name",
      key: "name",
    },
    {
      title: lang.label_icon + " (en)",
      dataIndex: "full_image",
      key: "icon_en", 
      render: (record, row) =>{
        return (
        <img src={record.en_icon} style={{width: "50px", height: "50px"}} />
       )
      },
    },
    {
      title: lang.label_icon + " (vn)",
      dataIndex: "full_image",
      key: "icon_vn", 
      render: (record, row) =>{
       return (
        <img src={record.vn_icon} style={{width: "50px", height: "50px"}} />
       )
      },
    },

    {
        title: lang.label_status,
        dataIndex: "is_enabled",
        key: "is_enabled",
        render: (record, row) =>{
          if(!hasPermission('Adjust Payment Method Settings')){
            return <NoPermissionLock />;
          }
           return (
            <div onClick={() => toggleStatus(row.id)}>
              <Switch size="small" checked={record} />
            </div>
          );
        },
     },
   {
    title: "Action",
    key: "action",
    render: (record, row) => {
      if(!hasPermission('Update Payment Category')){
        return <NoPermissionLock />;
      }
      return ( <Button onClick={()=>onEditClicked(row)} type="primary" icon={<MdEdit />} />)
    }
  }
  ];

  return (
    <>
      <Table rowKey={"id"} dataSource={tableData} columns={columns} loading={loading} />
    </>
  )
}

export default PaymentCategoriesTable