import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col, Button, Input, Modal, Pagination } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import GetApi from '../../../helps/contexts/Api/GetApi';
import AddNewPromotionForm from '../../../components/forms/AddNewPromotionForm';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import PromotionsCategoryTable from './PromotionsCategoryTable';
import AddNewPromotionCategory from '../../../components/forms/AddNewPromotionCategoryForm';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import SearchIcon from "../../../images/Search.png";
import ResetIcon from "../../../images/Reset.png";
import { TabContext } from '../../../helps/contexts/TabContext';

const PromotionCategories = () => {
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();
  const lang = ctx.lang;
  const { Search } = Input;
  const nav = useNavigate();
  const inputStyle = {
    width: '190px',
    height: '33px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };

  //states
  const [searchtext, setSearchtext] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [visible, setVisible] = useState(false);
  const [promotionsCategoryData, setPromotionsCategoryData] = useState([]);
  const [filteredData, setFilteredData] = useState(promotionsCategoryData);
  const { path } = useContext(TabContext);
  useEffect(() => {
    getPromotionCategories();
  }, []);

  useEffect(() => {
    if (path === '/promotions/promotion-category') {
      getPromotionCategories();
    }
  }, [path]);

  // Add role popup
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };
  const onReset = () => {
    setSearchtext(null);
    setActivepage1(1);
    getPromotionCategories(currentpage);
  };

  const getPromotionCategories = async (customPage = null) => {
    try {
      const path = `/promotion_categories`;
      const params = {};
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      setLoading(true);
      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.data && res.data.length) {
        const finalArray = res.data.map((category, index) => ({
          key: index,
          id: category && category.id ? category.id : '',
          categoryName: category && category?.name ? category?.name?.en : '',
          status: category && category?.is_active
        }));
        const reversedData = finalArray.reverse();
        setPromotionsCategoryData(finalArray);
        setFilteredData(reversedData);
        setCurrentpage(res.current_page);
        setTotal(res.total);
        setPerpage(res.per_page);
        setNextPage(res.next_page_url);
      } else {
        setPromotionsCategoryData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };
  // on click of a page in pagination component
  const onChangepage = (page) => {
    setActivepage1(page);
    getPromotionCategories(page);
  };
  // //search on table
  // const onSearch = (event) => {
  //   setSearchtext(event);

  //   let result = [];

  //   result = PromotionsData.filter((data) => {
  //     return data.title.toLowerCase().search(event.toLowerCase()) !== -1;
  //   });
  //   setFilteredData(result);
  // };
  //on submit of filters on table values
  const onSubmit = (e) => {
    e.preventDefault();
    if (searchtext) {
      let result = [];
      result = promotionsCategoryData.filter((data) => {
        return data.categoryName.toLowerCase().search(searchtext.toLowerCase()) !== -1;
      });
      setFilteredData(result);
    }
    setActivepage1(1);
  };
  return (
    <>
      <HeaderCardDetails />

      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div style={{ overflowY: 'hidden' }}>
          <Card
            title={lang.label_filters}
            style={{
              borderRadius: '3px',
              height: '140px'
            }}>
            <div
              style={{
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around'
              }}>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center'
                }}>
                <Col
                  span={18}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}>
                  <label style={{ marginLeft: '0%', marginRight: '2%' }}>{lang.label_category} :</label>
                  {/* <Search
                    placeholder="Please Enter Title.."
                    style={{ width: 250, marginRight: "2%" }}
                    onSearch={onSearch}
                    onChange={(e) => {
                      setSearchtext(e.target.value);
                    }}
                    value={searchtext}
                  /> */}
                  <Input
                    style={inputStyle}
                    value={searchtext}
                    type="text"
                    onChange={(e) => {
                      setSearchtext(e.target.value);
                    }}
                  />
                  <Button
                    htmlType="submit"
                    style={{
                      display : 'flex',
                      alignItems : 'center',
                      justifyContent : 'space-evenly',
                      marginRight: '0.5rem',
                      marginLeft: '0.5rem'
                    }}
                    className="btnStyle">
                      <img src={SearchIcon} alt='search' />
                    {lang.label_search}
                  </Button>
                  <Button
                    type="secondary"
                    style={{
                      borderRadius: '3px',
                      display : 'flex',
                      alignItems : 'center',
                      justifyContent : 'space-evenly',
                      border: '1px solid grey',
                      width: '84px',
                      backgroundColor: 'white'
                    }}
                    className="btnReset"
                    //handling reset
                    onClick={onReset}>
                      <img src={ResetIcon} alt='reset' />
                    <label style={{ color: '#004A7F' }}>{lang.label_reset}</label>
                  </Button>
                </Col>
                <Col
                  span={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'end'
                  }}>
                  {hasPermission('Create Promotion Category') && (
                    <Button
                      type="secondary"
                      style={{
                        borderRadius: '3.5px',
                        right: '20%',
                        border: '1px solid grey',
                        width: '200px !important',
                        backgroundColor: '#004A7F',
                        height: '35px',

                        right: 1
                      }}
                      onClick={showModal}>
                      <label style={{ color: 'white' }}>{lang.label_addnewcategory}</label>
                    </Button>
                  )}
                  <div>
                    <Modal title={lang.label_addnewcategory} open={visible} footer={null} width={800} closable={false}>
                      <AddNewPromotionCategory
                        handleCancel={handleCancel}
                        getPromotionCategories={getPromotionCategories}
                      />
                    </Modal>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <br />
          <br />
          <PromotionsCategoryTable
            loading={loading}
            data={filteredData}
            getPromotionCategories={getPromotionCategories}
            currentpage={currentpage}
            onChangepage={onChangepage}
            total={total}
            perpage={perpage}
          />
          <br />

          <Pagination
            defaultCurrent={1}
            current={activepage}
            onChange={onChangepage}
            total={total}
            responsive={true}
            pageSize={perpage}
          />
        </div>
      </form>
    </>
  );
};
export default PromotionCategories;
