import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Card, Input, Spin, Typography, DatePicker, Button, Table, Flex, Group, Radio } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import styles from './PlayerAnalysis.module.css';
import LangContext from '../../../helps/contexts/lang-context';
import ResetIcon from "../../../images/Reset.png";
const { RangePicker } = DatePicker;
dayjs.extend(utc);

export const PlayerAnalysis = () => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [activeFilter, setActiveFilter] = useState('today');
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    from_date: null,
    to_date: null,
    dates: []
  });

  const formatDate = (dayjsObj) => {
    return dayjs.utc(dayjsObj).format('YYYY-MM-DD HH:mm:ss');
  };

  const filterOptions = [
    { name: 'Today', url: 'today' },
    { name: 'Yesterday', url: 'yesterday' },
    { name: 'This Week', url: 'this_week' },
    { name: 'Last Week', url: 'last_week' },
    { name: 'This Month', url: 'this_month' },
    { name: 'Last Month', url: 'last_month' }
  ];

  const column1 = [
    {
      title: 'Players',
      dataIndex: 'players',
      key: 'players'
    },
    {
      title: 'Deposit Amount',
      dataIndex: 'depositamount',
      key: 'depositamount'
    },
    {
      title: 'Withdraw Amount',
      dataIndex: 'withdrawamount',
      key: 'withdrawamount'
    },
    {
      title: 'Total  Valid Bets',
      dataIndex: 'validbet',
      key: 'validbet'
    },
    {
      title: 'Win/Loss',
      dataIndex: 'winloss',
      key: 'winloss'
    }
  ];

  const onReset = () => {
    setActiveFilter('today');
    setFormData({
      from_date: null,
      to_date: null,
      dates: []
    });
  };

  // loading component
  const Loading = () => {
    return (
      loader && (
        <div className={styles.loadingWrapper}>
          <Spin color="red" />
        </div>
      )
    );
  };

  const dataSource1 = [
    {
      key: '1',
      players: '100',
      depositamount: '1',
      withdrawamount: '0',
      validbet: '0',
      winloss: '0'
    }
  ];

  return (
    <div
      style={{
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Card style={{ minHeight: '80px',borderRadius: '10px', }}>
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            padding: '0 0 15px 0 !important'
            /* alignItems: "center"; */
          }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div
              className={styles.filterButtonsWrapper}
              style={{
                display: 'flex',
                gap: '10px',
                flexWrap: 'wrap'
              }}>
              {filterOptions.map((item) => (
                <Button
                  style={{}}
                  key={item.url}
                  className={activeFilter === item.url ? styles.activeFilter : ''}
                  onClick={() => setActiveFilter(item.url)}>
                  {item.name}
                </Button>
              ))}
              <RangePicker
                style={{
                  width: '350px',
                  height: '33px'
                }}
                value={formData ? formData.dates : []}
                showTime={{ defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')] }}
                onChange={(x) => {
                  setFormData(() => ({
                    start_date: x ? formatDate(x[0]) : null,
                    end_date: x ? formatDate(x[1]) : null,
                    dates: x
                  }));
                }}
              />
            </div>
            {/* <TfiReload style={{ fontSize: '20px', lineHeight: '23px', color: '#003049' }} onClick={getData} /> */}
            <Button
              onClick={onReset}
              type="secondary"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                borderRadius: '3px',
                padding: '6px',
                border: '1px solid grey',
                width: '84px'
              }}
              className="btnReset">
              <img src={ResetIcon} alt="reset" />
              {lang.label_reset}
            </Button>
          </div>
        </Col>
        {/* {!loader && <TotalRecord data={data} addCommasToNumber={addCommasToNumber} />} */}
        <Loading />
      </Card>

      <Flex className={styles.tableClass}
        style={{
          marginTop: '20px',
          width: '100%',
          height: '200px'
        }}
        justify={'space-between'}
        align={'flex-start'}>
        <Card className={`${styles.cardClass} custom-card`} title="Total Cumulative Data(Personal)">
          <Table 
            style={{
              Width: '100%',
              height: '75%',
              overflowX: 'auto',
              overflowY: 'auto'
            }}
            dataSource={dataSource1}
            columns={column1}
            pagination={false}
          />
        </Card>

        <Card className={`${styles.cardClass} custom-card`} title="Total Cumulative Data(Team)">
          <Table
            style={{
              width: '100%',
              height: '75%',
              overflowX: 'auto',
              overflowY: 'auto'
            }}
            dataSource={dataSource1}
            columns={column1}
            pagination={false}
          />
        </Card>
      </Flex>
    </div>
    
  );
};
