import { Button, Switch, Table, message } from 'antd';
import React, { useContext } from 'react';
import { MdEdit } from 'react-icons/md';
import NoPermissionLock from '../../../components/HelperComponents/NoPermissionLock';
import PutApi from '../../../helps/contexts/Api/PutApi';
import LangContext from '../../../helps/contexts/lang-context';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';

const BankCodesTable = ({ tableData, setTableData, loading, onEditClicked }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { hasPermission } = useCheckPermission();

  const toggleStatus = async (id) => {
    toggleInLocalStatus(id);
    let url = '/bank/code/' + id + '/status/toggle';
    PutApi.PutApiRequest(url, {}, {})
      .then((res) => {
        if (!res || !res.status) {
          toggleInLocalStatus(id);
        } else {
          message.success('Action has been updated');
        }
      })
      .catch((e) => {
        toggleInLocalStatus(id);
      });
  };

  const toggleDisplayForPlayers = async (id) => {
    toggleInLocalDisplayForPlayers(id);
    let url = '/bank/code/' + id + '/display_players/toggle';
    PutApi.PutApiRequest(url, {}, {})
      .then((res) => {
        if (!res || !res.status) {
          toggleInLocalDisplayForPlayers(id);
        } else {
          message.success('Action has been updated');
        }
      })
      .catch((e) => {
        toggleInLocalDisplayForPlayers(id);
      });
  };

  const toggleInLocalStatus = (id) => {
    setTableData((prevData) =>
      prevData.map((record) => {
        if (record.id === id) {
          record.status = !record.status;
        }
        return record;
      })
    );
  };

  const toggleInLocalDisplayForPlayers = (id) => {
    setTableData((prevData) =>
      prevData.map((record) => {
        if (record.id === id) {
          record.display_for_players = !record.display_for_players;
        }
        return record;
      })
    );
  };

  const columns = [
    {
      title: lang.label_bank_code,
      dataIndex: 'code_name',
      key: 'code_name'
    },
    {
      title: lang.label_image,
      dataIndex: 'full_image',
      key: 'full_image',
      render: (record, row) => {
        return <img src={record} style={{ width: '50px', height: '50px' }} />;
      }
    },
    {
      title: lang.label_status,
      dataIndex: 'status',
      key: 'status',
      render: (record, row) => {
        return (
          <div onClick={() => toggleStatus(row.id)}>
            <Switch size="small" checked={record} />
          </div>
        );
      }
    },
    {
      title: lang.label_display_for_players,
      dataIndex: 'display_for_players',
      key: 'display_for_players',
      render: (record, row) => {
        return (
          <div onClick={() => toggleDisplayForPlayers(row.id)}>
            <Switch size="small" checked={record} />
          </div>
        );
      }
    },

    ...(hasPermission('Create Bank Code')
      ? [
          {
            title: 'Action',
            key: 'action',
            render: (record, row) => {
              return <Button onClick={() => onEditClicked(row)} type="primary" icon={<MdEdit />} />;
            }
          }
        ]
      : [])
  ];

  return (
    <>
      <Table rowKey={'id'} dataSource={tableData} columns={columns} loading={loading} />
    </>
  );
};

export default BankCodesTable;
