import { Input, Button, Row, Col, Select, message } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PostApi from '../../../helps/contexts/Api/PostApi';

import GetApi from '../../../helps/contexts/Api/GetApi';
import PutApi from '../../../helps/contexts/Api/PutApi';
import ErrorsHandlingComp from '../../../helps/errorsHandlingComp';
import Loader from '../../../components/HelperComponents/Loader';

const EditIPForm = ({ rowdata, getPromotions, onCancel }) => {
  const nav = useNavigate();
  // form states
  const [name, setName] = useState(rowdata && rowdata.name ? rowdata.name : '');
  const [ip, setIp] = useState(rowdata && rowdata.ip ? rowdata.ip : '');
  const [type, setType] = useState(rowdata && rowdata.typeId ? rowdata.typeId : 'Select A Type');
  const [typeList, setTypeList] = useState([]);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { Option } = Select;
  var ipid = rowdata?.id;

  useEffect(() => {
    fetchTypes();
  }, []);
  // Fetching api

  const fetchTypes = async () => {
    const path = '/ip/white/list/types';
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path);
    const types = Object.keys(res).map((key) => ({
      key: Number(key),
      value: res[key]
    }));
    setTypeList(types);
  };
  //form submit to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (
        !Object.keys(errors).length &&
        type !== 'Select A Type' &&
        type !== null &&
        type !== undefined &&
        type !== '' &&
        ip
      ) {
        const path = `/ip/white/list/${ipid}/update`;
        // sending request
        setLoader(true);
        const res = await PutApi.PutApiRequest(path, {
          name: name,
          ip: ip,
          type: type ? type : rowdata?.typeId
        });
        //if success
        if (res?.status === true) {
          message.success('Ip updated successfully');
          setName('');
          setIp('');
          setType('Select A Type');
          getPromotions();
          onCancel();
          setLoader(false);
        }
      } else {
        if (type === 'Select A Type' || type === null || type === undefined || type === '') {
          setErrors({ ...errors, type: 'Please Select A Type' });
        }
      }
    } catch (e) {
      if (e?.status === 422) {
        Object.values(e.data.message).map((errors) => message.error(errors));
      }
      setLoader(false);
    }
  };
  const onTypeChange = (value) => {
    setType(value);
    delete errors.type;
  };
  return (
    <div
      style={{
        width: '97%',
        height: '85%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: '3%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '-6%'
          }}>
          <Row>
            <Col span={24}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_name}</label>
              <br />

              <Input
                defaultValue="Ip Name"
                style={{
                  width: '94%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                placeholder="IP Name"
                name="ip name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  delete errors?.name;
                }}
              />
              {<ErrorsHandlingComp errors={errors} name="name" />}
            </Col>
          </Row>

          <br />

          <Row>
            <Col span={24}>
              <label style={{ paddingBottom: '3px' }}>IP</label>
              <br />

              <Input
                style={{
                  width: '94%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                required
                type="text"
                placeholder="IP"
                name="ip"
                value={ip}
                onChange={(e) => {
                  setIp(e.target.value);
                  delete errors?.ip;
                }}
              />
              {<ErrorsHandlingComp errors={errors} name="ip" />}
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <label style={{ paddingBottom: '3px' }}>Type</label>
              <br />

              {/* <Input
                defaultValue="type"
                style={{
                  width: "94%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                type="text"
                placeholder="IP type"
                name="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              /> */}
              <Select
                style={{
                  width: '94%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                onChange={onTypeChange}
                defaultValue={type}>
                <Option value="Select A Type">Select A Type</Option>
                {typeList &&
                  typeList.map((item, index) => {
                    return (
                      <Option key={item.key} value={item.key}>
                        {item.value}
                      </Option>
                    );
                  })}
              </Select>
              {<ErrorsHandlingComp errors={errors} name="type" />}
            </Col>
          </Row>
          <br />
          <div>
            {loader ? (
              <Loader />
            ) : (
              <Button
                style={{
                  borderRadius: '3px',
                  backgroundColor: '#004A7F',
                  color: 'white',
                  position: 'absolute',
                  right: '25%',
                  top: '90%'
                }}
                htmlType="submit">
                {lang.label_submit}
              </Button>
            )}
            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                position: 'absolute',
                right: '8%',
                top: '90%'
              }}
              onClick={() => {
                setName('');
                setIp('');
                setType('Select A Type');
                onCancel();
              }}>
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditIPForm;
