import { useContext } from 'react';
import styles from './BetDetailComponent.module.css'
import LangContext from '../../../../helps/contexts/lang-context';
import AddCommasAndLength from '../../../../helps/AddCommasAndLength';
import { Row } from 'antd';
import StatusChip from '../../../../components/HelperComponents/StatusChip';
import { convertUTCToLocalTime } from '../../../../helps/HelperFunctions';

const BetDetailComponent = (data) => {

  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  
  // check bet status
  const checkBetStatus = (status_name) => {
      return <StatusChip color={status_name === 'Opened' ? '#148F14' : status_name === 'Closed' ? '#707070' : '#004A7F'}  chipBkg='#E7F9E7' text = {status_name}/> 
    
  }

  // Round Record
  const roundRecords = [
    // { name: 'id' , value: data.data.id } ,
    { name: lang.label_username , value : data.data.player.user.user_name ? data.data.player.user.user_name : '-' } ,
    { name: lang.label_platform , value : data.data.game_platform.name ? data.data.game_platform.name : '-' } ,
    { name: lang.label_provider , value : data.data.provider ? data.data.provider : '-' } ,
    { name: lang.label_ipaddress , value : data.data.ip_address ? data.data.ip_address : '-' } ,
    { name: lang.label_device , value : data.data.device ? data.data.device : '-' } ,
    { name: lang.label_referenceid , value : data.data.round_reference ? data.data.round_reference : '-' } ,
    { name: lang.label_startedon, value: data.data.started_on ? convertUTCToLocalTime(data.data.started_on) : '-' } ,
    { name: lang.label_endedon, value: data.data.ended_on ? convertUTCToLocalTime(data.data.ended_on) : '-' } ,
    { name: lang.label_status, value: data.data.status_name ? checkBetStatus(data.data.status_name) : '-' } ,
    { name: lang.label_currency , value : data.data.currency_name ? data.data.currency_name : '-' } ,
    { name: lang.label_winloss , value : data.data.win_loss ? AddCommasAndLength(data.data.win_loss) : 0 } ,
  ]

  return (
    <Row className={styles.roundDetailsWrapper}>
      {roundRecords?.map((item, index) => (
        <div key={index} className={styles.roundDetailsItem}>
          <span>{item.name}</span>
          <span 
            style={{ 
              color : `${
                item.name == lang.label_ipaddress ? '#2A93F0' 
                : item.name == lang.label_winloss && item.value <= 10  ? 'red'
                : ''
              }`
            }}
          >
            {item.value}
          </span>
        </div>
      ))}
    </Row>
  )
}

export default BetDetailComponent