import React from 'react';

const Heading = ({ children }) => {
  const styled = {
    padding: 0,
    margin: 0,
    fontStyle: 'normal',
    fontWeight: '500',
    fontVariant: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    fontFamily: 'Roboto',
    letterSpacing: '0px',
    color: '#4F5057',
    width: 'auto'
  };
  return <span style={styled}>{children}</span>;
};

export default Heading;
