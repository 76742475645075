import { Button, Select, Spin, message } from 'antd';
import GetApi from '../../../helps/contexts/Api/GetApi';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import PutApi from '../../../helps/contexts/Api/PutApi';
const { Option } = Select;

const ChangeAgent = ({ onCancel, rowdata, getPlayers = () => {} }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const [changeAgent, setChangeAgent] = useState('');
  const [agentList, setAgentList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  //Listing banks
  // const getAgentList = async () => {
  //   const res = await GetApi.sendApiRequest("/admin/agent/get/all");
  //   console.log(res)

  //   const finalArray = res?.data?.user?.map((v) => ({ value: v.id, label: v.user.user_name }));
  //   setAgentList(finalArray);
  // };

  const getAgentList = async (page = 1) => {
    setLoading(true);
    try {
      const res = await GetApi.sendApiRequest(`/admin/agent/get/all?page=${page}`);
      const newAgents = res?.data?.map((v) => ({
        value: v.id,
        label: v.user.user_name
      }));
  
      setAgentList((prev) => [...prev, ...newAgents]);
  
      if (res?.next_page_url) {
        // Recursively fetch next page
        await getAgentList(page + 1);
      } else {
        setHasMore(false); // All agents are fetched
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  
  // console.log(agentList)

  const requestChangeAgent = async () => {
    setSubmitting(true);
    try {
      const id = rowdata.id;
      const path = `/admin/player/${id}/agent/change`;

      const res = await PutApi.PutApiRequest(path, {
        agent_id: changeAgent
      });

      if (res) {
        message.success(res.message);
        getPlayers();
        onCancel();
      }
      setSubmitting(false);
    } catch (e) {
      // console.log(e);
      var message1 = e.data.message.agent_id[0];
      message.error(message1);
      setSubmitting(false);
    } finally {
      setSubmitting(false);
    }
  };

  const handleScroll = (event) => {
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (bottom && hasMore && !loading) {
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    getAgentList(page);
  }, [page]);

  return (
    <div style={{ height: '170px' }}>
      <p style={{ color: '#A5A5A5', font: 'normal normal normal 16px/21px Roboto' }}>{lang.label_typeagentname}</p>
      <br />
      <p style={{ color: '#4F5057' }}>{lang.label_newagent}</p>
      <Select
        showSearch
        placeholder="Search and select an agent"
        options={agentList}
        allowClear
        style={{ width: '450px', color: '#4F5057' }}
        value={changeAgent || undefined}
        onChange={(value) => setChangeAgent(value)}
        onPopupScroll={handleScroll}
        filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
        dropdownRender={(menu) => (
          <div>
            {loading ? (
              <div style={{ padding: '8px', textAlign: 'center' }}>
                <Spin size="small" />
              </div>
            ) : null}
            {menu}
          </div>
        )}>
        {agentList.map((agent) => (
          <Option key={agent.value} value={agent.value}>
            {agent.label}
          </Option>
        ))}
      </Select>
      {submitting && (
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10
          }}>
          <Spin size="large" />
        </div>
      )}
      <span style={{ float: 'right', marginTop: '1rem' }}>
        <Button
          style={{
            backgroundColor: '#004A7F',
            color: 'white',
            margin: '1rem'
          }}
          htmlType="submit"
          onClick={requestChangeAgent}>
          {lang.label_submit}
        </Button>
        <Button onClick={onCancel}>{lang.label_cancel}</Button>
      </span>
    </div>
  );
};

export default ChangeAgent;
