import moment from 'moment';
import { api } from './axios';
import { convertLocalTimeToUTC } from '../../HelperFunctions';

export const getConfig = (token, contentType = 'application/json') => {
  const config = {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': contentType ? contentType : 'application/json'
      // "Content-Type": "multipart/form-data",
    }
  };
  return config;
};

export const dailyReport = (token) => {
  return api.get(`/payment/method/daily/report`, getConfig(token));
};

export const monthlyTransaction = (token) => {
  return api.get(`dashboard/monthly/transaction`, getConfig(token));
};
export const dailyTransaction = (token) => {
  return api.get(`dashboard/daily/transaction`, getConfig(token));
};

export const getNotifications = (token) => {
  return api.get(`/api/players/notification/`, getConfig(token));
};

export const toggleFaLockWithdraw = (id, payload, token) => {
  return api.put(`/admin/withdraw/${id}/fa/lock/unlock`, payload, getConfig(token));
};
export const toggleFaLock = (id, payload, token) => {
  return api.put(`/admin/deposit/${id}/fa/lock/unlock`, payload, getConfig(token));
};
export const toggleRiskLock = (id, payload, token) => {
  return api.put(`/admin/withdraw/${id}/risk/lock/unlock`, payload, getConfig(token));
};
export const getTransactionsCount = (payload) => {
  return api.get(`/admin/get/pending/transactions/count`, getConfig(payload.token));
};

export const getTransactions = (payload) => {
  const date1String = `${payload.date1 ? `&from_date=${convertLocalTimeToUTC(payload.date1.$d)}` : ``}`;
  const date2String = `${payload.date2 ? `&to_date=${convertLocalTimeToUTC(payload.date2.$d)}` : ``}`;
  const minAmmountString = `${payload.minAmmount ? `&min_amount=${payload.minAmmount}` : ``}`;
  const maxAmmountString = `${payload.maxAmmount ? `&max_amount=${payload.maxAmmount}` : ``}`;
  const phoneString = `${payload.phone ? `&phone=${payload.phone}` : ``}`;
  const bankString = `${payload.bank !== 'Select Option' && payload.bank ? `&company_bank_id=${payload.bank}` : ``}`;
  const statusString = `${payload.status !== '5' && payload.status ? `&status=${payload.status}` : ``}`;
  const userNameString = `${payload.user_name ? `&user_name=${payload.user_name}` : ``}`;
  const transactionId = `${payload?.transaction_id ? `&transaction_id=${payload?.transaction_id}` : ``}`;
  const agentId = `${payload?.agent_id ? `&agent_code=${payload?.agent_id}` : ``}`;
  const filterString = `${
    payload.isFilterActive
      ? date1String +
        date2String +
        minAmmountString +
        maxAmmountString +
        phoneString +
        statusString +
        userNameString +
        bankString +
        transactionId +
        agentId
      : ``
  }`;
  return api.get(
    `/admin/transactions?isWithdraw=${payload.isWithdraw}${
      payload?.page ? `&page=${payload?.page}` : ``
    }${filterString}`,
    getConfig(payload.token)
  );
};

export const getBanks = (payload) => {
  return api.get(`/company/banks`, getConfig(payload.token));
};
export const getBankMessages = (payload) => {
  const date3String = `${payload.date3 ? `&from_time=${convertLocalTimeToUTC(payload.date3.$d)}` : ``}`;
  const date4String = `${payload.date4 ? `&to_time=${convertLocalTimeToUTC(payload.date4.$d)}` : ``}`;
  const searchString = `${payload.search ? `&search=${payload.search}` : ``}`;
  const filterString = `${payload.isFilterActive ? date3String + date4String + searchString : ``}`;

  return api.get(
    `/admin/bank_messages?amount=${payload?.amount}${payload?.page ? `&page=${payload?.page}` : ``}${filterString}`,
    getConfig(payload.token)
  );
};
export const getAllBankMessages = (payload) => {
  return api.get(`/admin/bank_messages/all`, getConfig(payload.token));
};
export const updateTransection = ({
  token,
  id,
  type,
  status,
  customer_message,
  remark,
  risk_action_status,
  company_bank_id
}) => {
  // const modifiedText = remark?.slice(3, -4);
  return api.put(
    `/admin/transactions/${id}/${type}`,
    {
      status,
      customer_message,
      remark,
      risk_action_status,
      company_bank_id
    },
    getConfig(token)
  );
};

export const processingTransaction = ({ token, id, in_process }) => {
  return api.put(
    `/admin/transactions/${id}/processing`,
    {
      in_process
    },
    getConfig(token)
  );
};
export const updateTransectionRisk = ({ token, id, type, risk_action_status, risk_action_note }) => {
  return api.put(
    `/admin/transactions/${id}/${type}`,
    {
      risk_action_status,
      risk_action_note
    },
    getConfig(token)
  );
};

export const getPlatforms = ({ token, isFilterActive, page, game_platform_id }) => {
  const gamePlatformString = `${game_platform_id ? `&game_platform_id=${game_platform_id}` : ``}`;
  const filterString = `${isFilterActive ? gamePlatformString : ``}`;
  return api.get(`/game_platforms${page ? `?page=${page}` : ''}${filterString}`, getConfig(token));
};

export const getPlatformsForDropdown = ({ token, isFilterActive, page, game_platform_id }) => {
  const gamePlatformString = `${game_platform_id ? `&game_platform_id=${game_platform_id}` : ``}`;
  const filterString = `${isFilterActive ? gamePlatformString : ``}`;
  return api.get(`/game_platforms/dropdown${page ? `?page=${page}` : ''}${filterString}`, getConfig(token));
};

export const deletePlatform = (token, id) => {
  return api.delete(`/game_platforms/${id}/delete`, getConfig(token));
};

export const updatePlatform = (token, payload) => {
  return api.post(`/game_platforms/${payload.id}/update`, payload, getConfig(token, 'multipart/form-data'));
};
export const createPlatform = (token, payload) => {
  return api.post(`/game_platforms/create`, payload, getConfig(token, 'multipart/form-data'));
};

export const getGameProperties = ({ token }) => {
  return api.get(`/game_categories/properties`, getConfig(token));
};

export const getGameCategories = ({ token, isFilterActive, page, game_category_id }) => {
  const filterGameCat = `${game_category_id ? `&game_category_id=${game_category_id}` : ``}`;
  const filterString = `${isFilterActive ? filterGameCat : ``}`;
  return api.get(`/game_categories${page ? `?page=${page}` : ''}${filterString}`, getConfig(token));
};

export const getAllGameCategories = ({ token }) => {
  return api.get(`/game_categories/all`, getConfig(token));
};

export const updateGameCategory = (token, payload) => {
  return api.post(`/game_categories/${payload.id}/update`, payload, getConfig(token, 'multipart/form-data'));
};
export const createGameCategory = (token, payload) => {
  return api.post(`/game_categories/create`, payload, getConfig(token, 'multipart/form-data'));
};
export const deleteGameCategory = (token, id) => {
  return api.delete(`/game_categories/${id}/delete`, getConfig(token));
};
export const toggleGameCategory = (token, payload) => {
  return api.post(`/game_categories/${payload.id}/status/toggle`, payload, getConfig(token));
};
export const sortingGameCategory = (token, payload) => {
  return api.put(`/game_categories/order/change`, payload, getConfig(token));
};
export const sortingGameCategorySingleGame = (token, payload, game_category) => {
  return api.put(`/game_categories/${game_category}/games/order/change`, payload, getConfig(token));
};

export const getGameList = ({ token, game_category_id, game_platform_id, isFilterActive, page, name, game_code }) => {
  const platFormString = `${game_platform_id ? `&game_platform_id=${game_platform_id}` : ``}`;
  const categoryString = `${game_category_id ? `&game_category_id=${game_category_id}` : ``}`;

  const nameString = `${name ? `&name=${name}` : ``}`;
  const gameCodeString = `${game_code ? `&game_code=${game_code}` : ``}`;

  const filterString = `${isFilterActive ? platFormString + categoryString + nameString + gameCodeString : ``}`;

  return api.get(`/game_items?${page ? `page=${page}` : ''}${filterString}`, getConfig(token));
};

export const sortingGameList = (token, payload) => {
  return api.put(`/game_items/order/change`, payload, getConfig(token));
};

export const createGameList = (token, payload) => {
  return api.post(`/game_items/create`, payload, getConfig(token, 'multipart/form-data'));
};
export const deleteGameList = (token, id) => {
  return api.delete(`/game_items/${id}/delete`, getConfig(token));
};

export const updateGameList = (token, payload) => {
  return api.post(`/game_items/${payload.id}/update`, payload, getConfig(token, 'multipart/form-data'));
};

export const updateMaintenanceGameItem = (token, payload, game_item) => {
  return api.put(`/game_items/${game_item}/maintenance`, payload, getConfig(token, 'multipart/form-data'));
};

export const getGameCategoryPlatform = ({ token }) => {
  return api.get(`/transactions/`, getConfig(token));
};
export const createGameCategoryPlatform = ({ token }) => {
  return api.post(`/transactions/`, getConfig(token));
};
export const updateGameCategoryPlatform = ({ token }) => {
  return api.put(`/transactions/`, getConfig(token));
};

// Dashboard filter API
export const getFilterdData = (token, payload) => {
  return api.get(`/dashboard/cards/stats?timeframe=${payload}`, getConfig(token));
};
export const getFilterdData2 = (token, payload) => {
  return api.get(`/dashboard/cards/stats`, {
    ...getConfig(token),
    params: {
     
      date_range: {
        start_date: payload.start_date,
        end_date: payload.end_date
      }
      
    }
  });
};

// Get Bet History API
export const getBetHistory = (token, param) => {
  return api.get(
    `bet_rounds/list${param && `?per_page=${param.perPage}&page=${param.page}`}
      ${param.dateType ? `&date_type=${param.dateType}` : ''}
      ${param.dateFrom ? `&date_from=${param.dateFrom}` : ''}
      ${param.dateTo ? `&date_to=${param.dateTo}` : ''}
      ${param.userName ? `&user_name=${param.userName}` : ''}
      ${param.game_platform_id ? `&game_platform_id=${param.game_platform_id}` : ''}
      ${param.status ? `&status=${param.status}` : ''}
      ${param.game_name ? `&game_name=${param.game_name}` : ''}
      ${param.round_reference ? `&round_reference=${param.round_reference}` : ''}
    `,
    getConfig(token)
  );
};

// Get Round Bets API
export const getRoundDetailsAPI = (token, id) => {
  return api.get(`/bet_rounds/${id}/view`, getConfig(token));
};

export const getSettingsAPI = (token) => {
  return api.get(`/setting`, getConfig(token));
};

export const createSettingsAPI = (payload, token) => {
  return api.post(`/setting`, payload, getConfig(token));
};

export const getSettingsAPISearch = (token, params) => {
  return api.get(`/setting?search=${params}`, getConfig(token));
};

export const deleteSettingsAPI = (token, id) => {
  return api.delete(`/setting/${id}`, getConfig(token));
};

export const editSettingsAPI = (payload, token, id) => {
  return api.put(`/setting/${id}`, payload, getConfig(token));
};
