import React, { useEffect, useState, useContext } from 'react';
import styles from '../../../components/HelperComponents/Filter.module.css';
import Heading from '../../../components/HelperComponents/Heading';
import { Button, Col, Row } from 'antd';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import InputField from '../../../components/HelperComponents/InputField';
import CustomButton from '../../../components/HelperComponents/CustomButton';
import Spacer from '../../../components/HelperComponents/Spacer';
import TableComp from '../../../components/HelperComponents/TableComp';
import Loader from '../../../components/HelperComponents/Loader';
import { createPlatform, deletePlatform, getPlatforms, updatePlatform } from '../../../helps/contexts/Api/api';
import Action from '../Action';
import CustomModal from '../../../components/HelperComponents/CustomModal';
import CreateModal from './CreateModal/CreateModal';
import DeleteModal from './DeleteModal';
import { useNavigate } from 'react-router-dom';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import SearchIcon from "../../../images/Search.png";
import ResetIcon from "../../../images/Reset.png";
import LangContext from '../../../helps/contexts/lang-context';
import { message } from 'antd';
import SelectBox from '../../../components/HelperComponents/SelectBox';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';

const Platforms = () => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { hasPermission } = useCheckPermission();
  const Navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterActive, setIsFilterActive] = useState(true);
  const [perPage, setPerPage] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalType, setModalType] = useState('');
  const [data, setData] = useState([]);
  const [isPageChange, setIsPageChange] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [platforms, setPlatforms] = useState([]);

  const selectStyle = {
    width: '200px',
    height: '35px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '3px'
  };

  const [formData, setFormData] = useState({
    search: 0,
    name: '',
    icon: '',
    platform_code: ''
  });
  const [errors, setErrors] = useState({});
  const [item, setItem] = useState({});
  const [transactionTableData, setTransactionTableData] = useState([]);

  const handleOpenModal = (type, item) => {
    setModalType(type);
    setModalVisible(true);
    item && setItem(item);
  };

  const handleCloseModal = () => {
    setFormData({});
    setItem({});
    setModalVisible(false);
  };
  const handleInputChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    setItem({
      ...item,
      [name]: value
    });
  };

  const resetFunc = () => {
    setFormData({
      search: 0,
      name: '',
      icon: '',
      platform_code: ''
    });
    setCurrentPage(1);
    setIsFilterActive(true);
  };
  const searchFunc = () => {
    if (formData.search) {
      setIsFilterActive(true);
      setCurrentPage(1);
    }
  };
  const onChangePage = (page) => {
    if (formData.search) {
      setIsFilterActive(true);
    }
    setCurrentPage(page);
    setIsPageChange(true);
  };
  const columns = [
    {
      title: lang.label_platformid,
      dataIndex: 'platformCode',
      key: 'platformCode'
    },
    {
      title: lang.label_platformname,
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: lang.label_platformicon,
      dataIndex: 'icon',
      key: 'icon'
    },
    ...(hasPermission('Create Game Platform')
      ? [
          {
            title: lang.label_actions,
            dataIndex: 'actions',
            key: 'actions'
          }
        ]
      : [])
  ];
  const getSelectPlatforms = async () => {
    const payload = {
      token: localStorage.getItem('token')
    };
    var res = await getPlatforms(payload);
    if (res && res?.status === 200) {
      const temp = res?.data?.data;
      const newObject = { id: 0, value: 'Select Platform' };
      const data = temp.map(({ name, id }) => {
        // const jsonString = JSON.parse(name);
        return { value: name, id };
      });

      data.unshift(newObject);
      setPlatforms(data);
    }
  };
  useEffect(() => {
    getSelectPlatforms();
  }, []);
  const getAllPlatforms = async () => {
    try {
      const payload = {
        token: localStorage.getItem('token'),
        isFilterActive: isFilterActive,
        page: currentPage,
        game_platform_id: formData?.search
      };
      if (isFilterActive || isPageChange) {
        setLoading(true);
        var res = await getPlatforms(payload);
      }

      if (res && res?.status === 200) {
        setLoading(false);
        const temp = res?.data?.data;
        setTotalLength(res.data.total);
        setPerPage(res.data.per_page);
        const newArray = temp?.map((item, index) => {
          return {
            key: item?.id,
            platformCode: item?.platform_code,
            name: item?.name,
            icon: (
              <div
                style={{
                  height: '30px',
                  width: '35px',
                  objectFit: 'contain'
                }}>
                <img
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
                  src={item?.icon_image}
                  alt="icon"
                />{' '}
              </div>
            ),
            actions: <Action handleOpenModal={handleOpenModal} item={item} deleteState={false} />
          };
        });

        setData(newArray);
        setIsFilterActive(false);
        setIsPageChange(false);
      }

      if (res?.status === 401) {
        Navigate('/login');
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getAllPlatforms();
  }, [currentPage, isFilterActive]);

  const submitFunc = async () => {
    const payload = {
      name: formData?.name,
      icon_image: formData?.icon,
      platform_code: formData?.platform_code
    };
    try {
      if (payload?.name.trim() === '') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameError: 'Name is required *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameError: ''
        }));
      }
      if (payload?.platform_code.trim() === '') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          platformCodeError: 'Platform Id is required *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          platformCodeError: ''
        }));
      }

      if (!payload?.icon_image) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          imageError: 'Please upload an image *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          imageError: ''
        }));
      }
      if (payload?.name.trim() !== '' && payload?.icon_image !== '' && payload?.platform_code !== '') {
        const token = localStorage.getItem('token');
        setSubmitLoading(true);
        const res = await createPlatform(token, payload);
        if (res.status === 200) {
          message.success(lang.label_platformaddsuccess);
          setSubmitLoading(false);
          setItem({});

          setFormData({
            search: 0,
            name: '',
            icon: '',
            platform_code: ''
          });
          handleCloseModal();
          setIsFilterActive(true);
        }
        setErrors({});
      }
    } catch (error) {
      setSubmitLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
      if (typeof error.response.data.message === 'object') {
        if (error.response.data.message.platform_code.length > 0) {
          message.error(error.response.data.message.platform_code[0]);
        }
      } else {
        message.error(error.response.data.message);
      }
    }
  };

  const deleteFunc = async () => {
    try {
      const token = localStorage.getItem('token');
      setSubmitLoading(true);
      const res = await deletePlatform(token, item?.id);
      if (res.status === 200) {
        message.success(lang.label_platformdelsuccess);
        setItem({});

        setSubmitLoading(false);
        handleCloseModal();
        setFormData({
          search: 0,
          name: '',
          icon: '',
          platform_code: ''
        });
        setIsFilterActive(true);
      }
    } catch (error) {
      setSubmitLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }

      message.error(error.response.data.message);
    }
  };

  const updateFunc = async () => {
    const payload = {
      id: item?.id,
      name: formData?.name,
      icon_image: formData?.icon,
      platform_code: formData?.platform_code
    };

    try {
      if (payload?.name.trim() === '' || !payload?.name.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameError: 'Name is required *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameError: ''
        }));
      }
      if (payload?.platform_code?.trim() === '') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          platformCodeError: 'Platform Id is required *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          platformCodeError: ''
        }));
      }
      if (payload?.icon_image === '' || !payload?.icon_image) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          imageError: 'Please upload an image *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          imageError: ''
        }));
      }

      if (payload?.name.trim() && payload?.icon_image && payload?.platform_code !== '') {
        const token = localStorage.getItem('token');
        setSubmitLoading(true);
        const res = await updatePlatform(token, payload);

        if (res.status === 200) {
          message.success(lang.label_platformeditsuccess);
          setItem({});
          setSubmitLoading(false);
          handleCloseModal();
          setFormData({
            search: 0,
            name: '',
            icon_image: '',
            platform_code: ''
          });
          setIsFilterActive(true);
        }
        setErrors({});
      }
    } catch (error) {
      setSubmitLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }

      message.error(error.response.data.message);
    }
  };
  const handleFilterSelectChange = (value, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    setItem((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  const subHeadingStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    fontSize: '14px',
    fontWeight: 500
  };
  return (
    <>
      <HeaderCardDetails />

      <div style={{ padding: '20px' }}>
        <div className={styles.container}>
          <div
            style={{
              display: 'flex',
              borderBottom: '1px solid #d9d9d9',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '48px',
              padding: '0px 13px'
            }}>
            <Heading>{lang.label_filters}</Heading>
            {hasPermission('Create Game Platform') && (
              <Button
                onClick={() => handleOpenModal('new')}
                className="btnStyle"
                style={{
                  borderRadius: '3px',
                  padding: '6px',
                  border: '1px solid grey',
                  width: '140px'
                }}>
                {lang.label_addnewplatform}
              </Button>
            )}
          </div>
          <div className={styles.filterLowerContainer}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={12}>
                <div className={styles.scndRow}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label style={subHeadingStyle}>{lang.label_search}</label>
                    <SelectBox
                      name="search"
                      value={formData.search}
                      onChange={handleFilterSelectChange}
                      data={platforms}
                      style={selectStyle}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                      onClick={searchFunc}
                      className="btnStyle"
                      style={{
                        display : 'flex',
                       alignItems : 'center',
                        justifyContent : 'space-evenly',
                        backgroundColor: '#004A7F',
                        color: 'white',
                        width: '125px',
                        height: '35px',
                        background: '#004A7F 0% 0% no-repeat padding-box',
                        borderRadius: '3px',
                        opacity: '1',
                        margin: '0px 10px',
                        marginTop: '19px'
                      }}>
                        <img src={SearchIcon} alt='search' />
                      {lang.label_search}
                    </Button>
                    <Button
                      onClick={resetFunc}
                      style={{
                        display : 'flex',
                        alignItems : 'center',
                        justifyContent : 'space-evenly',
                        borderRadius: '3px',
                        padding: '6px',
                        border: '1px solid grey',
                        width: '84px',
                        marginTop: '19px'
                      }}
                      className="btnReset">
                         <img src={ResetIcon} alt='reset' />
                      {lang.label_reset}
                    </Button>
                  </div>
                </div>
              </Col>

              <Col
                xs={2}
                sm={6}
                md={8}
                lg={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                <div
                  style={{
                    paddingRight: '15px'
                  }}>
                  {/* {hasPermission('Create Game Platform') && (
                    <Button
                      onClick={() => handleOpenModal('new')}
                      style={{
                        backgroundColor: '#004A7F',
                        color: 'white',
                        width: '150px',
                        height: '35px',
                        background: '#004A7F 0% 0% no-repeat padding-box',
                        borderRadius: '3px',
                        opacity: '1',

                        marginTop: '19px'
                      }}>
                      {lang.label_addnewplatform}
                    </Button>
                  )} */}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Spacer height="30px" />
        {loading ? (
          <Loader />
        ) : (
          <TableComp
            transactionTableData={transactionTableData}
            setTransactionTableData={setTransactionTableData}
            dataSource={data}
            columns={columns}
            totalLength={totalLength}
            perPage={perPage}
            currentPage={currentPage}
            onChangePage={onChangePage}
          />
        )}

        <CustomModal
          visible={modalVisible}
          onCancel={handleCloseModal}
          title={
            modalType === 'new'
              ? lang.label_addnewplatform
              : modalType === 'update'
              ? lang.label_updateplatform
              : modalType === 'delete' && lang.label_deleteplatform
          }
          subTitle={
            modalType === 'new'
              ? lang.label_addinfo
              : modalType === 'update'
              ? lang.label_editinfo
              : modalType === 'delete' && lang.label_del
          }
          content={
            modalType === 'new' || modalType === 'update' ? (
              <CreateModal
                errors={errors}
                setItem={setItem}
                updateFunc={updateFunc}
                item={item}
                type={modalType}
                formData={formData}
                handleCloseModal={handleCloseModal}
                submitFunc={submitFunc}
                handleInputChange={handleInputChange}
                setFormData={setFormData}
                submitLoading={submitLoading}
              />
            ) : (
              modalType === 'delete' && (
                <DeleteModal
                  handleCloseModal={handleCloseModal}
                  submitLoading={submitLoading}
                  deleteFunc={deleteFunc}
                />
              )
            )
          }
          footer={<></>}
          modalStyle={{ width: '600px' }}
          titleStyle={
            modalType === 'delete' && {
              color: '#D40000'
            }
          }
          contentStyle={{}}
          footerStyle={{}}
        />
      </div>
    </>
  );
};

export default Platforms;
