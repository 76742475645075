import React, { useContext, useState, useEffect } from 'react';
import styles from './AgentCommissionReport.module.css';
import AgentCommisionReoprtTable from './AgentCommisionReoprtTable';
import HeaderCardDetails from '../../../../components/HeaderDetailCard';
import Heading from '../../../../components/HelperComponents/Heading';
import SubHeading from '../../../../components/HelperComponents/SubHeading';
import LangContext from '../../../../helps/contexts/lang-context';
import { Card, Row, Col, DatePicker, Button, Spin, Input, Select, Pagination, Modal, message } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import SearchIcon from '../../../../images/Search.png';
import ResetIcon from '../../../../images/Reset.png';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { LoadingOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
dayjs.extend(utc);

const AgentCommissionReport = () => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  // const nav = useNavigate();

  // states
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [agentId, setAgentId] = useState('');
  const [statusType, setStatusType] = useState(null);
  const [startTimeParam, setStartTimeParam] = useState('');
  const [endTimeParam, setEndTimeParam] = useState('');
  const [agentLevel, setAgentLevel] = useState(null);
  const [settleCycle, setSettleCycle] = useState(null);
  const [searchBy, setSearchBy] = useState(null);
  const [perpage, setPerpage] = useState(10);
  const [nextPage, setNextPage] = useState();
  const [formDate, setFormDate] = useState({
    from_date: null,
    to_date: null,
    dates: []
  });
  const [loadingData, setLoadingData] = useState(false);

  const formatDate = (dayjsObj) => {
    return dayjs.utc(dayjsObj).format('YYYY-MM-DD HH:mm:ss');
  };

  //styles
  const datePickerStyle = {
    width: '150px',
    height: '35px',
    background: ' #FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const iconStyle = { color: '#004A7F' };
  const inputStyle = {
    width: '150px',
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '5px'
  };
  const selectStyle = {
    width: '200px',
    height: '35px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '3px'
  };

  //date picker startdate
  const onChange = (value, dateString) => {
    setStartDate(value);
    setStartTimeParam(dateString);
  };
  //date picker start date completed
  //date picker end date

  const onChange1 = (value, dateString) => {
    setEndDate(value);
    setEndTimeParam(dateString);
  };

  const exportData = async (e) => {
    e.preventDefault();
    // const params = {
    //   export_type: 0,
    //   export: 1,
    //   agent_code: payload.agent_code,
    //   from_date: payload.from_date,
    //   to_date: payload.to_date,
    //   max_amount: payload.max_amount,
    //   min_amount: payload.min_amount,
    //   user_name: payload.user_name,
    //   payment_method_id: payload.payment_method_id,
    //   reference_no: payload.reference_no,
    //   status: payload.status,
    //   transaction_id: payload.transaction_id
    // };

    // try {
    //   setLoadingData(true);
    //   setDownloadedFiles(0);
    //   const response = await exportReportWithdraw1(params);

    //   if (response?.data?.job_id) {
    //     const jobId = response.data.job_id;
    //     const totalFileCount = response.data.progress.total_file_count;
    //     setTotalFiles(totalFileCount);

    //     const pollExportStatus = async (jobId) => {
    //       try {
    //         const statusResponse = await exportReportWithdraw2(jobId);
    //         const files = statusResponse?.data?.file || [];

    //         files.forEach((file) => {
    //           if (!downloadedFileIds.has(file.file_id)) {
    //             const base64String = file.file_content;
    //             const fileName = file.file_name + (file.file_type.includes('csv') ? '.csv' : '.xlsx');
    //             const byteCharacters = atob(base64String);
    //             const byteNumbers = new Array(byteCharacters.length);
    //             for (let i = 0; i < byteCharacters.length; i++) {
    //               byteNumbers[i] = byteCharacters.charCodeAt(i);
    //             }
    //             const byteArray = new Uint8Array(byteNumbers);
    //             const blob = new Blob([byteArray], { type: file.file_type });

    //             const link = document.createElement('a');
    //             const blobUrl = URL.createObjectURL(blob);
    //             link.href = blobUrl;
    //             link.download = fileName;
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //             URL.revokeObjectURL(blobUrl);

    //             downloadedFileIds.add(file.file_id);

    //             setDownloadedFiles((prev) => prev + 1);
    //           }
    //         });

    //         if (statusResponse?.data?.progress?.is_all_file_generated) {
    //           setLoadingData(false);
    //           message.success(`Export completed. ${totalFileCount} files downloaded.`);
    //         } else {
    //           setTimeout(() => pollExportStatus(jobId), 2000);
    //         }
    //       } catch (error) {
    //         setLoadingData(false);
    //         message.error('Failed to check export status.');
    //       }
    //     };

    //     pollExportStatus(jobId);
    //   } else {
    //     setLoadingData(false);
    //     message.error('Failed to initiate export.');
    //   }
    // } catch (error) {
    //   setLoadingData(false);
    //   message.error('Failed to initiate export.');
    // }
  };

  const onReset = () => {
    setFormDate({
      from_date: null,
      to_date: null,
      dates: []
    });
    setSearchBy(null);
    setSettleCycle(null);
    setStatusType(null);
    setAgentLevel(null);
    setAgentId('');
  };

  return (
    <>
      <HeaderCardDetails />
      <form
        // onSubmit={onSubmit}
        style={{ padding: '20px' }}
        >
        <div>
          <div className={styles.container}>
            <div className={styles.filterUpperContainer}>
              <div style={{ paddingTop: '6px' }}>
                <Heading>{lang.label_filters}</Heading>
              </div>
              <div>
                {loadingData ? (
                  <div style={{ paddingRight: '40px', display: 'flex', alignItems: 'center' }}>
                    <Spin indicator={<LoadingOutlined spin />} size="large" />
                    {/* <div style={{ marginLeft: '10px', fontWeight: '500', fontSize: '16px' }}>
                {downloadedFiles} of {totalFiles} Files Downloaded
              </div> */}
                  </div>
                ) : (
                  <div>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="btnStyle"
                      style={{
                        backgroundColor: '#004A7F',
                        color: 'white',
                        width: '125px',
                        height: '35px',
                        background: '#004A7F 0% 0% no-repeat padding-box',
                        borderRadius: '3px',
                        opacity: '1',
                        marginRight: '15px'
                      }}
                      onClick={exportData}>
                      Export
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.filterLowerContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  width: '100%',
                  paddingRight: '14px',
                  flexWrap: 'wrap'
                }}>
                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_time}
                  </SubHeading>

                  <RangePicker
                    style={{
                      width: '340px',
                      height: '35px'
                    }}
                    value={formDate ? formDate.dates : []}
                    showTime={{ defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')] }}
                    onChange={(x) => {
                      setFormDate(() => ({
                        from_date: x ? formatDate(x[0]) : null,
                        to_date: x ? formatDate(x[1]) : null,
                        dates: x
                      }));
                    }}
                  />
                </div>

                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {`Search by`}
                  </SubHeading>
                  <Select
                    // defaultValue={null}
                    value={searchBy}
                    style={selectStyle}
                    onChange={(value) => {
                      setSearchBy(value);
                    }}
                    options={[
                      {
                        value: null,
                        label: 'Agent level'
                      },
                      {
                        value: 1,
                        label: 'Agent ID'
                      }
                    ]}
                  />
                </div>

                {/* Conditional Rendering Based on Selected Value */}
                {searchBy === null && (
                  <div className={styles.flexStyle}>
                    <SubHeading
                      extraStyle={{
                        fontSize: '14px',
                        fontWeight: 500
                      }}>
                      {'Agent Level'}
                    </SubHeading>
                    <Select
                      // defaultValue={null}
                      value={agentLevel}
                      style={selectStyle}
                      onChange={(value) => {
                        setAgentLevel(value);
                      }}
                      options={[
                        {
                          value: null,
                          label: 'All'
                        },
                        {
                          value: 1,
                          label: 'CM'
                        },
                        {
                          value: 3,
                          label: 'EA'
                        },
                        {
                          value: 4,
                          label: 'MA'
                        },
                        {
                          value: 5,
                          label: 'SA'
                        },
                        {
                          value: 6,
                          label: 'JA'
                        },
                        {
                          value: 7,
                          label: 'AG'
                        }
                      ]}
                    />
                  </div>
                )}

                {searchBy === 1 && (
                  <div className={styles.flexStyle} style={{ display: 'flex', gap: '0px' }}>
                    <SubHeading
                      extraStyle={{
                        fontSize: '14px',
                        fontWeight: 500
                      }}>
                      {'Agent ID'}
                    </SubHeading>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                      {/* need to handle if angent id is null */}
                      <Input
                        style={inputStyle}
                        placeholder="please key in text"
                        onChange={(e) => {
                          setAgentId(e.target.value);
                        }}
                        value={agentId}
                      />
                    </div>
                  </div>
                )}

                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_settleCycle}
                  </SubHeading>
                  <Select
                    // defaultValue={null}
                    value={settleCycle}
                    style={selectStyle}
                    onChange={(value) => {
                      setSettleCycle(value);
                    }}
                    options={[
                      {
                        value: null,
                        label: 'Weekly'
                      },
                      {
                        value: 1,
                        label: 'Monthly'
                      }
                    ]}
                  />
                </div>

                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_status}
                  </SubHeading>
                  <Select
                    // defaultValue={null}
                    value={statusType}
                    style={selectStyle}
                    onChange={(value) => {
                      setStatusType(value);
                    }}
                    options={[
                      {
                        value: null,
                        label: 'All'
                      },
                      {
                        value: 1,
                        label: lang.label_pending
                      },
                      {
                        value: 3,
                        label: lang.label_approved
                      },
                      {
                        value: 4,
                        label: lang.label_rejected
                      }
                    ]}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    marginTop: '20px',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                  }}>
                  <Button
                    // htmlType="submit"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1'
                    }}
                    className="btnStyle">
                    <img src={SearchIcon} alt="search" />
                    {lang.label_search}
                  </Button>

                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      borderRadius: '3px',
                      padding: '6px',
                      border: '1px solid grey'
                      // width: '84px'
                    }}
                    className="btnReset"
                    // handling
                    // reset
                    onClick={onReset}>
                    <img src={ResetIcon} alt="reset" />
                    <label>{lang.label_reset}</label>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div 
          style={{
            backgroundColor : 'rgba(255, 255, 255, 1)',
            borderRadius : '5px'
          }}>
            <div className="card">
              <Row
                style={{
                  height: '100%',
                  padding : '15px',
                }}>
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%'
                  }}>
                  <span
                    style={{
                      width: '100%',
                      textAlign: 'left',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '19px',
                      lineHeight: '23px',
                      fontFamily: 'Roboto',
                      letterSpacing: '0px',
                      color: '#003049',
                      opacity: 1,
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                    <span style={{ paddingRight: '10px' }}>Agent Commission Report</span>
                    <span style={{ fontSize: '10px', color: 'rgba(141, 146, 150, 1)' }}>
                    Weekly/Monthly reports will be generated within 48 hours after the end of the natural week/month, once the data is reviewed, it can't run again.
                    </span>
                  </span>
                </Col>
              </Row>
            </div>
            <AgentCommisionReoprtTable />
          </div>

          {/* <AgentCommisionReoprtTable data={Data} loading={loading} />

          <Pagination
            showSizeChanger={false}
            current={activepage}
            onChange={onChangepage}
            total={total}
            responsive={true}
            pageSize={10}
          /> */}
        </div>
      </form>
    </>
  );
};

export default AgentCommissionReport;
