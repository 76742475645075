import { Table } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState } from 'react';
import addCommasToNumber from '../../../helps/AddCommasToNumber';
import moment from 'moment';
import { convertUTCToLocalTime, toFixedFunc } from '../../../helps/HelperFunctions';
import { Link } from 'react-router-dom';
import { render } from '@testing-library/react';

const PlayerBalanceHistoryTable = ({ data, loading }) => {
  const [IsDeduction, setIsDeduction] = useState();

  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const columns = [
    {
      title: lang.label_username,
      dataIndex: 'playername',
      key: 'playername',
      render: (_, record) => {
        return (
          <Link
            style={{ color: '#1677ff' }}
            state={{ name: record.playername }}
            to={{
              pathname: `/player-management/player/${record.id}/view`
            }}>
            {record.playername}
          </Link>
        );
      }
    },
    {
      title: lang.label_previousbalance,
      dataIndex: 'previousbalance',
      key: 'previousbalance',
      render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: lang.label_points,
      dataIndex: 'amount',

      key: 'amount',

      render: (number, record) => {
        const color = record.is_deduction == 0 ? 'green' : 'red';
        let sign = number == 0 ? ' ' : record.is_deduction == 0 ? '+' : '-';
        // record.is_deduction == 0
        const formattedNumber = toFixedFunc(number, process.env.REACT_APP_Decimel_Points);

        return (
          <span
            style={{
              color: color,
              display: 'block',
              width: '100%',
              textAlign: 'left',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}>
            {sign} {formattedNumber}
          </span>
        );
      }
      // render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: lang.label_newbalance,
      dataIndex: 'newbalance',
      key: 'newbalance',
      render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: lang.label_currency,
      dataIndex: 'currency_name',
      key: 'currency_name'
      // render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: lang.label_type,
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: lang.label_is_turnover_check,
      dataIndex: 'is_turnover_check',
      key: 'is_turnover_check'
    },
    {
      title: lang.label_turnover_multiplier,
      dataIndex: 'turnover_multiplier',
      key: 'turnover_multiplier'
    },
    {
      title: lang.label_actionby,
      dataIndex: 'actionby',
      key: 'actionby'
    },
    {
      title: lang.label_transactionid,
      dataIndex: 'transactionid',
      key: 'transactionid'
    },
    {
      title: lang.label_remark,
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      title: lang.label_gametransactionid,
      dataIndex: 'gametransactionid',
      key: 'gametransactionid'
    },
    {
      title: lang.label_status,
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: lang.label_time,
      dataIndex: 'time',
      key: 'time',
      render: (time) => <span>{convertUTCToLocalTime(time)}</span>
    }
  ];

  return <Table loading={loading} columns={columns} bordered dataSource={data} pagination={false} size="small" />;
};

export default PlayerBalanceHistoryTable;
