import React from 'react'
import SubHeading from '../../../components/HelperComponents/SubHeading'
import InputField from '../../../components/HelperComponents/InputField'
import CustomButton from '../../../components/HelperComponents/CustomButton'

const TabNameSection = ({name , handleInputChange}) => {
  return (
    <div className='TabNameSectionWrapper'>
       <SubHeading>{name}</SubHeading>
       <div className='flexStyle'>
       <InputField placeHolder="Search..." type='text' name="search" onChange={handleInputChange} />
       <CustomButton  className="mainBg" text="Search" />
       </div>
         </div>
  )
}

export default TabNameSection