import { useEffect } from 'react';
import { Table, Modal, Button, message, Switch } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import LangContext from '../../../../../helps/contexts/lang-context';
import { useContext, useState } from 'react';
import PencilImg from '../../../../../images/Edit.png';
import GetApi from '../../../../../helps/contexts/Api/GetApi';
import EditUserBank from './EditUserBank';
import DisableBank from './DisableBank';
import EnableBank from './EnabelBank';
import { useNavigate } from 'react-router-dom';
import { MdEdit } from 'react-icons/md';
import { AiOutlineDelete } from 'react-icons/ai';
import { convertUTCToLocalTime } from '../../../../../helps/HelperFunctions';
import PutApi from '../../../../../helps/contexts/Api/PutApi';

const UserBankTable = ({ data, loading, onEditClicked }) => {
  //States
  const [tableData, setTableData] = useState(data?.user?.user_payment_methods);

  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  useEffect(() => {
    setTableData(data?.user?.user_payment_methods);
  }, [loading]);

  const toggleStatus = async (id) => {
    toggleInLocalStatus(id);
    let url = `/user/payment/method/status/toggle/${id}`;
    PutApi.PutApiRequest(url, {}, {})
      .then((res) => {
        if (!res || !res.status) {
          toggleInLocalStatus(id);
        }
      })
      .catch((e) => {
        toggleInLocalStatus(id);
      });
  };

  const toggleInLocalStatus = (id) => {
    setTableData((prevData) =>
      prevData.map((record) => {
        if (record.id === id) {
          record.is_active = !record.is_active;
        }
        return record;
      })
    );
  };

  const columns = [
    {
      title: lang.label_id,
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: lang.label_bankname,
      dataIndex: 'bank_code',
      key: 'bank_code',
      render: (record, row) => {
        return record?.code_name;
      }
    },
    {
      title: lang.label_cardholder,
      dataIndex: 'account_name',
      key: 'account_name'
    },
    {
      title: lang.label_accountnumber,
      dataIndex: 'account_number',
      key: 'account_number'
    },
    {
      title: lang.label_currency,
      dataIndex: 'currency_name',
      key: 'currency_name'
    },
    {
      title: lang.label_city,
      dataIndex: 'bank_city',
      key: 'bank_city'
    },
    {
      title: lang.label_branch,
      dataIndex: 'bank_branch',
      key: 'bank_branch'
    },
    {
      title: lang.label_payment_category,
      dataIndex: 'payment_category',
      key: 'payment_category',
      render: (record, row) => {
        return record?.name;
      }
    },
    {
      title: lang.label_remark,
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      title: lang.label_addeddate,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (time) => <span>{convertUTCToLocalTime(time)}</span>
    },
    {
      title: lang.label_updateddate,
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (time) => <span>{convertUTCToLocalTime(time)}</span>
    },
    {
      title: lang.label_status,
      dataIndex: 'is_active',
      key: 'is_active',
      render: (record, row) => {
        return (
          <div onClick={() => toggleStatus(row.id)}>
            <Switch size="small" checked={record} />
          </div>
        );
      }
    },
    {
      title: lang.label_action,
      key: 'action',
      render: (record, row) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {}{' '}
            <Button
              onClick={() => {
                onEditClicked(row);
              }}
              style={{
                marginRight: '1%',
                backgroundColor: '#eeeeee',
                color: '#004A7F'
              }}>
              <MdEdit />
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <Table
        pagination={false}
        style={{
          width: '100%',
          marginTop: '30px',
          overflowX: 'auto',
          overflowY: 'scroll'
        }}
        size="small"
        columns={columns}
        bordered
        dataSource={tableData}
        loading={loading}
      />
    </>
  );
};

export default UserBankTable;
