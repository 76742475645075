import React, { useContext } from 'react';
import { Button, message } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import DeleteApi from '../../../helps/contexts/Api/DeleteApi';

// call delete api
const DeleteTag = ({ onClose, record }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const deleteTag = async (id) => {
    try {
      const path = `/admin/tags/${id}`;
      // sending request
      const res = await DeleteApi.DeleteApiRequest(path);
      //if success
      if (res && res.status === true) {
        message.success('Tag Deleted Successfuly');
        onClose();
      } else if (!res) {
        message.error('Tag Deletion Failed');
      }
    } catch (e) {
      message.error('Tag Deletion Failed');
    }
  };

  // handel Delete
  const handleDelete = (e) => {
    e.preventDefault();
    deleteTag(record.id);
  };
  return (
    <div
      style={{
        width: '97%',
        minHeight: '50px',
        height: '100%',
        justifyContent: 'space-evenly'
      }}>
      <form onSubmit={handleDelete}>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center'
          }}>
          <div style={{ fontWeight: '400', fontSize: '25px', color: 'red', marginTop: '10px' }}>Alert!!!</div>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', fontSize: '14px' }}>
            <span>Please be aware that the removal of this tag will also remove the tag from the players.</span>
            <span>Are you sure you want to remove the tag from the list?</span>
          </div>
          <br />
          <div style={{ marginTop: '30px' }}>
            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                position: 'absolute',
                right: '50%',
                // top: "80%",
                bottom: '15px',
                margin: '-1%'
              }}
              onClick={() => {
                onClose();
              }}>
              {lang.label_cancel}
            </Button>
            <Button
              style={{
                borderRadius: '3px',
                backgroundColor: '#004A7F',
                color: 'white',
                position: 'absolute',
                right: '34%',
                bottom: '15px',
                margin: '-1%'
              }}
              htmlType="submit">
              {lang.label_confirm}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DeleteTag;
