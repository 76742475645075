import React from 'react';
import { Table, Card, Switch, Button } from 'antd';

const GameSetting = ({ formData, handleGameSettingChange, prevTab, confirm }) => {
  const { sportData, casinoData, slotsData, sabongData } = formData.gameSettings;

  const sportColumns = [
    {
      title: 'Sport',
      dataIndex: 'sport',
      key: 'sport'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <Switch
          size="small"
          checked={record.status === 1} // Use 1 for active, 0 for inactive
          onChange={(checked) => handleGameSettingChange('sportData', record.key, checked)}
        />
      )
    }
  ];

  const casinoColumns = [
    {
      title: 'Casino',
      dataIndex: 'casino',
      key: 'casino'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <Switch
          size="small"
          checked={record.status === 1}
          onChange={(checked) => handleGameSettingChange('casinoData', record.key, checked)}
        />
      )
    }
  ];

  const slotsColumns = [
    {
      title: 'Slots',
      dataIndex: 'slots',
      key: 'slots'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <Switch
          size="small"
          checked={record.status === 1}
          onChange={(checked) => handleGameSettingChange('slotsData', record.key, checked)}
        />
      )
    }
  ];

  const sabongColumns = [
    {
      title: 'Sabong',
      dataIndex: 'sabong',
      key: 'sabong'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <Switch
          size="small"
          checked={record.status === 1}
          onChange={(checked) => handleGameSettingChange('sabongData', record.key, checked)}
        />
      )
    }
  ];

  return (
    <div>
      <Card
        className="custom-card"
        title="Allowed Games"
        style={{
          borderRadius: '3px',
          marginTop: '30px',
          marginRight: '40px'
        }}>
        <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
          <Table style={{ width: '350px' }} dataSource={sportData} columns={sportColumns} pagination={false} />
          <Table style={{ width: '350px' }} dataSource={casinoData} columns={casinoColumns} pagination={false} />
          <Table style={{ width: '350px' }} dataSource={slotsData} columns={slotsColumns} pagination={false} />
          <Table style={{ width: '350px' }} dataSource={sabongData} columns={sabongColumns} pagination={false} />
        </div>
      </Card>
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <Button onClick={prevTab} style={{ marginRight: '10px', width: '80px', height: '35px', borderRadius: '3px' }}>
          Previous
        </Button>
        <Button
          type="primary"
          onClick={confirm}
          style={{
            backgroundColor: '#004A7F',
            color: 'white',
            width: '80px',
            height: '35px',
            background: '#004A7F 0% 0% no-repeat padding-box',
            borderRadius: '3px',
            opacity: '1',
            marginRight: '15px'
          }}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default GameSetting;
