import { Select } from "antd";
import React from 'react';

const SelectWithLabel = ({label, isRequired, value, onChange, options,disabled=false}) => {
    const { Option } = Select;
    return (
        <div>
            <label>
                {label}
            </label>
            {isRequired  ? <span style={{ color: "red" }}>*</span> : ""}
            <Select
                required
                value={value}
                style={{ display:"block" }}
                onChange={onChange}
                disabled={disabled}
            >
                {options.map((item, index) => (
                    <Option key={index} value={item.value}>
                    {item.label}
                    </Option>
                ))}
            </Select>
        </div>
    )
}

export default SelectWithLabel