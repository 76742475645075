import React, { useContext, useEffect, useState } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import { TabContext } from '../../../helps/contexts/TabContext';
import { useNavigate } from 'react-router-dom';
import PaginationComp from '../../../components/HelperComponents/PaginationComp';
import GetApi from '../../../helps/contexts/Api/GetApi';
import Spacer from '../../../components/HelperComponents/Spacer';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import { Button, Switch, Table } from 'antd';
import PutApi from '../../../helps/contexts/Api/PutApi';
import AffiliateFilters from './AffiliateFilters';
import settingsIcon from '../../../images/SettingsIcon.png';
import AffiliateSettings from './AffiliateSettings';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';

export default function AffiliateList() {
  const ctx = useContext(LangContext);
  const Navigate = useNavigate();
  const { hasPermission } = useCheckPermission();
  const [loading, setLoading] = useState(false);
  const [affiliateList, setAffiliateList] = useState([]);
  // const [filterData, setFilterData] = useState();
  const [pageSize, setPageSize] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(null);
  const { path } = useContext(TabContext);
  const lang = ctx.lang;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getAffiliates = async ({ page, filter }) => {
    setLoading(true);
    try {
      const path = '/admin/affiliate/getAffiliateList';

      const finalData = filter
        ? {
            [filter?.key]: filter?.value
          }
        : null;

      const params = { ...finalData, page: page };
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);

      if (res && res.data && Array.isArray(res.data)) {
        setLoading(false);
        setAffiliateList(res.data);
        setTotalLength(res.pagination.total);
        setPageSize(res.pagination.per_page);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
    setLoading(false);
  };

  const onRefresh = () => {
    setFilters(null);
    setCurrentPage(1);
    getAffiliates({ page: currentPage });
  };

  useEffect(() => {
    getAffiliates({ page: currentPage, filter: filters ?? '' });
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    getAffiliates({ page: 1, filter: filters ?? '' });
  }, [filters]);

  const onChangePage = (page) => {
    setCurrentPage(page);
    // setIsPageChange(true);
  };

  const paginationConfig = {
    pageSize: pageSize ? pageSize : 0,
    total: totalLength ? totalLength : 0,
    showSizeChanger: false,
    showQuickJumper: false,
    current: currentPage,
    showTotal: (total) => `Total ${total} items`,
    onChange: onChangePage,
    itemRender: (current, type, originalElement) => {
      if (type === 'prev') {
        return <span className="pagination-item">Previous</span>;
      }
      if (type === 'next') {
        return <span className="pagination-item">Next</span>;
      }
      return originalElement;
    }
  };

  const columns = [
    {
      title: lang.label_affiliate_id,
      dataIndex: 'affiliate_id',
      key: 'affiliate_id',
      fixed: 'left',
      render: (text, record) => {
        return <>{text ?? '-'}</>;
      }
    },
    {
      title: lang.label_player_username,
      dataIndex: 'user_name',
      key: 'user_name',
      fixed: 'left',
      render: (text, record) => {
        return <>{text ?? '-'}</>;
      }
    },
    {
      title: lang.label_referral_bonus_count,
      dataIndex: 'bonus_count',
      key: 'bonus_count',
      render: (text, record) => {
        return <>{text ?? '-'}</>;
      }
    },
    {
      title: lang.label_referral_bonus_amount,
      dataIndex: 'balance_amount',
      key: 'balance_amount',
      render: (text, record) => {
        return <>{text ?? '-'}</>;
      }
    }
  ];


  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          top: 12,
          right: 12,
          zIndex: 1000
        }}>
        {hasPermission('Create Affiliates') && (
          <Button onClick={showModal} type="secondary" className="btnSettings">
            <img src={settingsIcon} />
            {lang.label_settings}
          </Button>
        )}
      </div>
      <AffiliateSettings isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} lang={lang} />
      <HeaderCardDetails />
      <div style={{ padding: '20px' }}>
        <AffiliateFilters onRefresh={onRefresh} setFilters={setFilters} />
        <br />
        <Table
          scroll={{
            x: 'max-content'
          }}
          columns={columns}
          loading={loading}
          dataSource={affiliateList}
          bordered
          pagination={false}
          rowKey={'id'}
        />
        {affiliateList?.length > 0 && <PaginationComp paginationConfig={paginationConfig} />}
        <Spacer />
        <Spacer />
      </div>
    </div>
  );
}
