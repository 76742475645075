import React, { useContext, useState } from 'react'
import LangContext from '../../../helps/contexts/lang-context'
import { Button, Modal, Switch, Table, message } from 'antd';
import styles from "../Promotions.module.css";
import { MdEdit } from 'react-icons/md';
import { AiOutlineDelete } from 'react-icons/ai';
import EditPromotionCategoryForm from '../../../components/forms/EditPromotionCategoryForm';
import DeletePromotionCategoryForm from '../../../components/forms/DeletePromotionCategoryForm';
import ChangeStatusModal from '../../../components/ChangeStatusModal/ChangeStatusModal';
import PutApi from '../../../helps/contexts/Api/PutApi';

const PromotionsCategoryTable = ({ data, loading, getPromotionCategories,currentpage,onChangepage,total,perpage }) => {
  const reversedData = [...data].reverse();
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [isEdit, setIsedit] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const [status, setStatus] = useState();
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState({});
  const [ischangestatus, setIschangestatus] = useState(false);
  const {id} = rowData;
  const handleCancel1 = (e) => {
    setVisible(false);
  };
  const handleCancel = (e) => {
    if(total>1){
      const currentTotalPage = (perpage * currentpage) - (perpage-1);
      if(currentTotalPage === total){
        --currentpage;
        onChangepage(currentpage);
      }
      onChangepage(currentpage)
    }
    setVisible(false);
    setIsedit(false);
  };



  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const path = `/promotion_categories/${id}/status/toggle`;
      // sending request
      const res = await PutApi.PutApiRequest(path);
      //if success
      if (res.status == true) {
        message.success("Status Updated Successfully");
        handleCancel1();
        getPromotionCategories();
      } else {
        message.success("Status Updation Failed");
      }
    } catch (e) {
      // var message1 = e.message;
      // alert(message1);
    }
  };

  const columns = [
    {
      title: lang.label_id,
      dataIndex: "id",
      key: "id",
   
    },
    {
      title: lang.label_name,
      dataIndex: "categoryName",
      key: "categoryName",
    },
    
    {
      title: lang.label_status,
      dataIndex: "status",
      key: "status",
      render: (record) =>
        record === 1 ? (
          <div
            onClick={() => {
              setVisible(true);
              setIsedit(false);
              setDelete(false);
              setIschangestatus(true);
            }}
          >
            {" "}
            <Switch size="small" checked={record} />
          </div>
        ) : (
          <div
            onClick={() => {
              setVisible(true);
              setIsedit(false);
              setDelete(false);
              setIschangestatus(true);
            }}
          >
            {" "}
            <Switch size="small" checked={record} />
          </div>
        ),
    },
    {
      title: lang.label_action,
      key: "action",
      render: (text, record) => (
        <span
          style={{ display: "flex", flexDirection: "row", columnGap: "5px" }}
        >
          {" "}
          {}{" "}
          <Button
            onClick={() => {
              setVisible(!visible);
              setIsedit(true);
              setDelete(false);
              setRowData({ ...record });
              setStatus(record.status);
            }}
            className={styles.editButton}
          >
            <MdEdit size={15} style={{ marginLeft: "-6px" }} />
          </Button>
          <Button
            onClick={() => {
              setVisible(true);
              setIsedit(false);
              setDelete(true);
            }}
            className={styles.deleteButton}
          >
            <AiOutlineDelete size={15} style={{ marginLeft: "-8px" }} />
          </Button>
        </span>
      ),
    },
  ];

  return (
    <div>
    <Table
      style={{ width: "100%" }}
      size="small"
      columns={columns}
      dataSource={reversedData}
      pagination={false}
      loading={loading}
      bordered
      onRow={(record) => {
        return {
          onClick: () => {
            setRowData(record);
          },
        };
      }}
    />
    <div>
      {(isEdit || isdelete || ischangestatus) && (
        <Modal
          closeIcon={null}
          title={
            isEdit
              ? lang.label_editpromotioncategory
              : isdelete
              ? lang.label_deletepromotionCat
              : ischangestatus
              ? lang.label_changestatus
              : ""
          }
          open={visible}
          onCancel={handleCancel}
          footer={null}
          width={isEdit ? 800 : isdelete ? 350 : ischangestatus ? 460 : ""}
          closable={false}
        >
          {isEdit ? (
            <EditPromotionCategoryForm
              rowdata={rowData}
              onCancel={handleCancel}
            />
          ) : isdelete ? (
            <DeletePromotionCategoryForm
              rowdata={rowData}
              onCancel={handleCancel}
            />
          ) : ischangestatus ? (
            <ChangeStatusModal
              rowdata={rowData}
              onCancel={handleCancel1}
              onSubmit={onSubmit}
              text={
                "Are you Sure You want to change the status of promotion??"
              }
            />
          ) : (
            ""
          )}
        </Modal>
      )}
    </div>
  </div>
  )
}

export default PromotionsCategoryTable