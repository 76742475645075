import { Button, Card, Col, Modal, Row, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import GetApi from '../../../helps/contexts/Api/GetApi';
import LangContext from '../../../helps/contexts/lang-context';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import AddPaymentCategoryPopup from './AddPaymentCategoryPopup';
import PaymentCategoriesTable from './PaymentCategoriesTable';
import PostApi from '../../../helps/contexts/Api/PostApi';
import { TabContext } from '../../../helps/contexts/TabContext';

const PaymentCategories = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState('');
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({ is_enabled: 1 });
  const [formLoading, setFormLoading] = useState(false);
  const { hasPermission } = useCheckPermission();
  const { path } = useContext(TabContext);
  const nav = useNavigate();
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const onFilterSubmit = async () => {};

  useEffect(() => {
    if (path === '/transaction-management/payment-categories') {
      getPaymentCategories();
    }
  }, [path]);

  const getPaymentCategories = async () => {
    setLoading(true);
    try {
      const path = '/payment/category';
      const params = {};
      if (inputSearchValue) params['search'] = inputSearchValue;
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.length) {
        setCategories(res);
        setFilteredCategories(res);
      } else {
        setCategories([]);
      }
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getPaymentCategories();
  }, []);

  const onEditClicked = (row) => {
    setShowEditPopup(true);
    setEditMode(true);
    setFormData(row);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (editMode) {
      handleUpdate();
    } else {
      try {
        setFormLoading(true);
        const res = await PostApi.PostApiRequest('/payment/category/store', { ...formData, is_enabled: 1 });
        if (res?.status === 200) {
          message.success(lang.label_add_payment_category_success);
          setShowEditPopup(false);
          getPaymentCategories();
          setFormData({});
        }
      } catch (e) {
        if (e?.status == 422) {
          const errorArr = e?.data?.message;
          if (errorArr) {
            Object.keys(errorArr).map((item) => {
              message.error(errorArr[item][0]);
            });
          }
        }
      }
      setFormLoading(false);
    }
  };
  const handleUpdate = async () => {
    try {
      setFormLoading(true);
      const res = await PostApi.PostApiRequest(`/payment/category/${formData.id}/update`, formData);
      // const res = await PostApi.PostApiRequest(`/payment/category/${formData.id}/update`, { ...formData, icon: JSON.parse(formData.icon) });

      if (res?.status === 200) {
        message.success(lang.label_add_payment_category_success);
        setShowEditPopup(false);
        getPaymentCategories();
        setFormData({});
      }
    } catch (e) {
      if (e?.status == 422) {
        const errorArr = e?.data?.message;
        if (errorArr) {
          Object.keys(errorArr).map((item) => {
            message.error(errorArr[item][0]);
          });
        }
      }
    }
    setFormLoading(false);
  };

  return (
    <>
      <HeaderCardDetails />
      <div style={{ padding: '20px' }}>
        <Card
          title="Filters"
          extra={
            <>
              {hasPermission('Create Payment Category') && (
                <Button
                  onClick={() => {
                    setFormData({});
                    setShowEditPopup(true);
                  }}
                  style={{
                    backgroundColor: '#004A7F',
                    color: 'white',
                    width: '125px',
                    height: '35px',
                    background: '#004A7F 0% 0% no-repeat padding-box',
                    borderRadius: '3px',
                    opacity: '1'
                  }}
                  className="btnStyle">
                  {lang.label_create}
                </Button>
              )}
            </>
          }
          style={{
            borderRadius: '3px'
          }}>
          {/* <Row>
            <Col span={18}></Col>
            <Col span={6}>
              {hasPermission('Create Payment Category') && (
                <Button
                  onClick={() => {
                    setFormData({});
                    setShowEditPopup(true);
                  }}
                  style={{ left: '20px' }}
                  className="btnStyle">
                  {lang.label_create}
                </Button>
              )}
            </Col>
          </Row> */}
          <form onSubmit={onFilterSubmit}></form>
        </Card>
        <br />
        <PaymentCategoriesTable
          tableData={categories}
          setTableData={setCategories}
          loading={loading}
          onEditClicked={onEditClicked}
        />
        {showEditPopup && (
          <Modal
            width={1000}
            open={showEditPopup}
            title={lang.label_create_category}
            onCancel={() => {
              setShowEditPopup(false);
              setEditMode(false);
              setFormData({});
            }}
            footer={null}
            // maskClosable={false}
            // keyboard={false}
            // okText={lang.label_submit}
            // cancelText={lang.label_cancel}
            // onOk={handleFormSubmit}
            // confirmLoading={formLoading}
          >
            <AddPaymentCategoryPopup
              setShowEditPopup={setShowEditPopup}
              setEditMode={setEditMode}
              formData={formData}
              setFormData={setFormData}
              formLoading={formLoading}
              handleFormSubmit={handleFormSubmit}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default PaymentCategories;
