import { Button, DatePicker, Flex, Input, InputNumber, Modal, Radio, Select, Switch, Table, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FaPlusSquare } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import GetApi from '../../../helps/contexts/Api/GetApi';
import { useNavigate } from 'react-router-dom';
import PostApi from '../../../helps/contexts/Api/PostApi';
import ReviewPopup from './ReviewPopup';

const { RangePicker } = DatePicker;
dayjs.extend(utc);

export default function AddRebatePopup({
  isModalOpen,
  handleOk,
  handleCancel,
  reviewHandleCancel,
  reviewHandleOk,
  reviewOpen,
  setReviewOpen
}) {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const Navigate = useNavigate();
  const formatDate = (dayjsObj) => {
    return dayjs.utc(dayjsObj).format('YYYY-MM-DD HH:mm:ss');
  };
  const [value, setValue] = useState(1);
  const [loading, setLoading] = useState(false);
  const [tableList, setTableList] = useState([
    {
      key: '1',
      game_provider: '',
      valid_bet: '',
      rebate_recommended: '',
      max_rebate: '',
      game_provider_name: ''
      // actions: null
    }
  ]);

  const [inputs, setInputs] = useState({
    title: '',
    description: '',
    game_category_id: '',
    currency: 2,
    rebate_cycle: '',
    status: false,
    max_group_rebate: '',
    conditional_multiplier: '',
    cycle_timezone: '',
    start_date: '',
    end_date: '',
    category_name: '',
    timezone_name: ''
  });

  const [dates, setDates] = useState([]);

  const [categoryList, setCategoryList] = useState([]);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [gameProviderList, setGameProviderList] = useState([]);
  const [currencyList, setCurrencyList] = useState([
    {
      id: 2,
      name: 'PHP'
    }
  ]);

  const getCategories = async () => {
    setLoading(true);
    setCategoryList([]);
    try {
      const path = '/game/category';

      const res = await GetApi.sendApiRequest(path, {}, {});

      setLoading(false);
      // console.log(res);
      if (res && res?.length > 0) {
        // setLoading(false);
        setCategoryList(res);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
    setLoading(false);
  };

  const getTimeZones = async () => {
    setLoading(true);
    setTimeZoneList([]);
    try {
      const path = '/admin/rebate/timezone';

      const res = await GetApi.sendApiRequest(path, {}, {});

      setLoading(false);
      if (res && res?.length > 0) {
        // setLoading(false);
        setTimeZoneList(res);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
    setLoading(false);
  };

  const getGameProviders = async (id) => {
    setLoading(true);
    setGameProviderList([]);
    try {
      const path = `/game/provider/${id}`;

      const res = await GetApi.sendApiRequest(path, {}, {});

      setLoading(false);
      // console.log(res);
      if (res && res?.length > 0) {
        // setLoading(false);
        setGameProviderList(res);
      } else {
        setGameProviderList([]);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
    setLoading(false);
  };

  const onChange = (e) => {
    setInputs({
      ...inputs,
      rebate_cycle: e.target.value
    });
  };

  const handleAddRow = () => {
    const newRow = {
      key: (tableList.length + 1).toString(),
      game_provider: '',
      valid_bet: '',
      rebate_recommended: '',
      max_rebate: ''
      // actions: null
    };
    setTableList([...tableList, newRow]);
  };

  const handleDeleteRow = (key) => {
    if (tableList.length > 1) {
      setTableList(tableList.filter((item) => item.key !== key));
    }
  };

  const handleInputChange = (key, dataIndex, value) => {
    console.log(key);
    setTableList(tableList.map((item) => (item.key === key ? { ...item, [dataIndex]: value } : item)));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const path = `/admin/rebate/create`;

      let conditions = tableList?.map((item) => {
        return {
          game_provider_id: item?.game_provider,
          valid_bet: item?.valid_bet,
          rebate_percentage: item?.rebate_recommended,
          max_rebate: item?.max_rebate
        };
      });
      let { category_name, timezone_name, ...restInputs } = inputs;

      let body = {
        ...restInputs,
        conditions: conditions
      };

      const res = await PostApi.PostApiRequest4(path, body);
      //if success
      setLoading(false);
      console.log('x', res);
      if (res) {
        message.success('Added successfully!');
        handleOk();
      }
    } catch (error) {
      console.log(error);
      message.error(
        (Array.isArray(error?.data?.message) ? error?.data?.message[0] : error?.data?.message) ??
          `Something went wrong!`
      );
      setLoading(false);
    }
  };

  const columns = [
    {
      title: lang.label_game_provider,
      dataIndex: 'game_provider',
      key: 'game_provider',
      render: (_, record) => (
        <>
          <Select
            variant="borderless"
            style={{ width: '100%' }}
            value={record.game_provider}
            onChange={(val) => {
              let name;
              gameProviderList?.map((item) => {
                if (item?.game_platform_id === val) {
                  name = item?.name;
                }
              });

              console.log('val', val);

              setTableList((prevTableList) =>
                prevTableList.map((item) =>
                  item.key === record.key ? { ...item, game_provider: val, game_provider_name: name } : item
                )
              );

              // handleInputChange(record.key, 'game_provider', val);

              // const changeFunction = () => {
              //   handleInputChange(record.key, 'game_provider_name', name);
              // };
              // changeFunction();
              // setTableList(
              //   tableList.map((item) => (item.key === record.key ? { ...item, ['game_provider_name']: name } : item))
              // );
            }}
            options={
              gameProviderList && gameProviderList.map((cat) => ({ value: cat.game_platform_id, label: cat.name }))
            }
          />
        </>
      )
    },
    {
      title: lang.label_valid_bet,
      dataIndex: 'valid_bet',
      key: 'valid_bet',
      render: (_, record) => (
        <InputNumber
          style={{ width: '100%' }}
          placeholder="Please Enter Valid bet"
          variant="borderless"
          value={record.valid_bet}
          onChange={(e) => handleInputChange(record.key, 'valid_bet', e)}
        />
      )
    },
    {
      title: lang.label_rebate_recommended,
      dataIndex: 'rebate_recommended',
      key: 'rebate_recommended',
      render: (_, record) => (
        <InputNumber
          style={{ width: '100%' }}
          placeholder="Please Enter Percentage"
          variant="borderless"
          value={record.rebate_recommended}
          onChange={(e) => handleInputChange(record.key, 'rebate_recommended', e)}
        />
      )
    },
    {
      title: lang.label_maxrebate,
      dataIndex: 'max_rebate',
      key: 'max_rebate',
      render: (_, record) => (
        <InputNumber
          style={{ width: '100%' }}
          placeholder="Please Enter Text"
          variant="borderless"
          value={record.max_rebate}
          onChange={(e) => handleInputChange(record.key, 'max_rebate', e)}
        />
      )
    },
    {
      title: lang.label_actions,
      key: 'actions',
      dataIndex: 'actions',
      fixed: 'right',
      render: (_, record) => (
        <Button
          type="link"
          shape="circle"
          disabled={tableList.length === 1}
          onClick={() => handleDeleteRow(record.key)}
          icon={<RiDeleteBin6Line color={tableList.length === 1 ? 'gray' : 'red'} size={18} />}
        />
      )
    }
  ];

  const inputStyle = {
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const selectStyle = {
    height: '33px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '3px'
  };

  const labelStyle = {
    fontSize: '14px',
    color: '#4F5057'
  };

  useEffect(() => {
    if (isModalOpen) {
      getCategories();
      getTimeZones();
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (inputs?.game_category_id) {
      getGameProviders(inputs?.game_category_id);
    }
  }, [inputs?.game_category_id]);

  const isButtonDisabled = () => {
    const areInputsValid = Object.values(inputs).some((value) => {
      if (typeof value === 'boolean') {
        return false;
      }
      return value === '';
    });

    const isTableListValid = tableList.some((entry) => {
      return Object.values(entry).some((value) => value === null || value === '');
    });

    return areInputsValid || isTableListValid;
  };

  return (
    <>
      <Modal
        width="fit-content"
        centered
        title="Add New Rabate"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={() => {
          setInputs({
            title: '',
            description: '',
            game_category_id: '',
            currency: 2,
            rebate_cycle: '',
            status: false,
            max_group_rebate: '',
            conditional_multiplier: '',
            cycle_timezone: '',
            start_date: '',
            end_date: '',
            category_name: '',
            timezone_name: ''
          });
          setTableList([
            {
              key: '1',
              game_provider: '',
              valid_bet: '',
              rebate_recommended: '',
              max_rebate: '',
              game_provider_name: ''
              // actions: null
            }
          ]);
          setGameProviderList([]);
          setDates([]);
        }}
        footer={false}>
        <Flex
          style={{
            flexDirection: 'column',
            gap: '10px'
          }}>
          <div style={{ color: '#A5A5A5', marginTop: '-10px' }}>Type new rebate item details as below</div>

          <Flex
            style={{
              gap: '20px',
              padding: '10px 0px'
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
              <div style={labelStyle}>
                {lang.label_rebate_title} <span style={{ color: 'red' }}>*</span>
              </div>
              <div>
                <Input
                  style={{
                    width: '250px',
                    ...inputStyle
                  }}
                  value={inputs.title}
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      title: e.target.value
                    })
                  }
                  placeHolder="Enter Rebate Title"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
              <div style={labelStyle}>
                {lang.label_description} <span style={{ color: 'red' }}>*</span>
              </div>
              <div>
                <Input
                  style={{
                    width: '250px',
                    ...inputStyle
                  }}
                  value={inputs.description}
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      description: e.target.value
                    })
                  }
                  placeHolder="Enter Description"
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
              <div style={labelStyle}>
                {lang.label_date_range} <span style={{ color: 'red' }}>*</span>
              </div>
              <div>
                <RangePicker
                  style={{
                    width: '250px',
                    ...inputStyle
                  }}
                  value={dates?.length > 0 ? dates : []}
                  onChange={(x) => {
                    setDates(x);

                    setInputs((prevData) => ({
                      ...prevData,
                      start_date: x ? formatDate(x[0]) : null,
                      end_date: x ? formatDate(x[1]) : null
                    }));
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
              <div style={labelStyle}>
                {lang.label_rebate_cycle} <span style={{ color: 'red' }}>*</span>
              </div>
              <div>
                <Radio.Group onChange={onChange} value={inputs?.rebate_cycle}>
                  <Radio value="daily">Daily</Radio>
                  <Radio value="weekly">Weekly</Radio>
                  <Radio value="monthly">Monthly</Radio>
                </Radio.Group>
              </div>
            </div>
          </Flex>

          <Flex
            style={{
              gap: '20px',
              paddingBottom: '10px'
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
              <div style={labelStyle}>
                {lang.label_category_title} <span style={{ color: 'red' }}>*</span>
              </div>
              <div>
                <Select
                  style={{ width: 250, ...selectStyle }}
                  // allowClear
                  value={inputs?.game_category_id}
                  onChange={(val) => {
                    let name;
                    categoryList?.map((item) => {
                      if (item?.id === val) {
                        name = item?.name;
                      }
                    });
                    // console.log('name', name);

                    setInputs((prevData) => ({ ...prevData, game_category_id: val, category_name: name }));
                    setTableList([
                      {
                        key: '1',
                        game_provider: '',
                        valid_bet: '',
                        rebate_recommended: '',
                        max_rebate: ''
                        // actions: null
                      }
                    ]);
                    setGameProviderList([]);
                  }}
                  // onClear={() => setInputs((prevData) => ({ ...prevData, game_category_id: '' }))}
                  options={categoryList && categoryList.map((cat) => ({ value: cat.id, label: cat.name }))}
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
              <div style={labelStyle}>
                {lang.label_currency} <span style={{ color: 'red' }}>*</span>
              </div>
              <div>
                <Select
                  style={{ width: 250, ...selectStyle }}
                  // allowClear
                  value={inputs?.currency}
                  onChange={(val) => setInputs((prevData) => ({ ...prevData, currency: val }))}
                  // onClear={() => setInputs((prevData) => ({ ...prevData, cycle_timezone: null }))}
                  options={currencyList && currencyList.map((cat) => ({ value: cat.id, label: cat.name }))}
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
              <div style={labelStyle}>
                {lang.label_timezone} <span style={{ color: 'red' }}>*</span>
              </div>
              <div>
                <Select
                  style={{ width: 250, ...selectStyle }}
                  // allowClear
                  value={inputs?.cycle_timezone}
                  onChange={(val) => {
                    let name;
                    timeZoneList?.map((item) => {
                      if (item?.id === val) {
                        name = item?.name;
                      }
                    });

                    setInputs((prevData) => ({ ...prevData, cycle_timezone: val, timezone_name: name }));
                  }}
                  // onClear={() => setInputs((prevData) => ({ ...prevData, timezone: null }))}
                  options={timeZoneList && timeZoneList.map((cat) => ({ value: cat.id, label: cat.name }))}
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
              <div style={labelStyle}>
                {lang.label_status}
                {/* <span style={{ color: 'red' }}>*</span> */}
              </div>
              <div>
                <Switch
                  size="small"
                  checked={inputs?.status}
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      status: e
                    })
                  }
                />
              </div>
            </div>
          </Flex>

          <p
            style={{
              color: '#004A7F',
              fontWeight: 600,
              fontSize: '16px'
            }}>
            Rebate Requirements
          </p>

          <Flex
            style={{
              gap: '20px',
              paddingBottom: '10px',
              marginTop: '-10px'
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
              <div style={labelStyle}>
                {lang.label_max_rebate} <span style={{ color: 'red' }}>*</span>
              </div>
              <div>
                <InputNumber
                  style={{
                    width: '280px',
                    ...inputStyle
                  }}
                  value={inputs?.max_group_rebate}
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      max_group_rebate: e
                    })
                  }
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
              <div style={labelStyle}>
                {lang.label_conditional_multiplayer} <span style={{ color: 'red' }}>*</span>
              </div>
              <div>
                <InputNumber
                  style={{
                    width: '280px',
                    ...inputStyle
                  }}
                  value={inputs?.conditional_multiplier}
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      conditional_multiplier: e
                    })
                  }
                />
              </div>
            </div>
          </Flex>
          <Flex
            style={{
              flexDirection: 'column',
              gap: '10px'
            }}>
            <div style={{ width: '100%' }}>
              <Button
                onClick={handleAddRow}
                style={{
                  backgroundColor: '#004A7F',
                  fontSize: '12px',
                  color: 'white',
                  width: '125px',
                  border: '1px solid grey',
                  textAlign: 'center',
                  background: '#004a7f 0% 0% no-repeat padding-box',
                  borderRadius: '3px',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  float: 'right'
                }}>
                <FaPlusSquare />

                {lang.label_add_new_row}
              </Button>
            </div>
            <Table
              // scroll={{
              //   x: 'max-content'
              // }}
              style={{
                border: '1px solid #E7E9F1',
                borderRadius: '5px'
              }}
              columns={columns}
              loading={loading}
              dataSource={tableList}
              bordered
              pagination={false}
              rowKey={'id'}
            />
          </Flex>

          <div
            style={{
              marginLeft: 'auto',
              display: 'flex',
              gap: '10px'
            }}>
            <Button
              loading={loading}
              disabled={isButtonDisabled()}
              onClick={() => setReviewOpen(true)}
              style={{
                borderRadius: '3px',
                backgroundColor: isButtonDisabled() ? 'lightgray' : '#004A7F',
                color: 'white',
                width: '100px'
              }}>
              {lang.label_submit}
            </Button>
            <Button
              onClick={handleCancel}
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                width: '100px'
              }}>
              {lang.label_cancel}
            </Button>
          </div>
        </Flex>
      </Modal>

      <ReviewPopup
        reviewHandleCancel={reviewHandleCancel}
        reviewOpen={reviewOpen}
        reviewHandleOk={reviewHandleOk}
        final={handleSubmit}
        inputs={inputs}
        tableList={tableList}
        lang={lang}
      />
    </>
  );
}
