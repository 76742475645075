import { Button, Card, Col, Input, Modal, Row } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import GetApi from '../../../helps/contexts/Api/GetApi';
import LangContext from '../../../helps/contexts/lang-context';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import '../../../styles/ButtonStyle.css';
import AddCompanyBankPopup from './AddCompanyBankPopup';
import AdjustCompanyBankPopup from './AdjustCompanyBankPopup';
import CompanyBanksTable from './CompanyBanksTable';

const CompanyBanks = () => {
  //Language translation
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();

  const lang = ctx.lang;
  const nav = useNavigate();

  const inputStyle = {
    width: '190px',
    height: '33px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px',
    left: '5px'
  };

  //States
  const [companyTableData, setcompanyTableData] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [isaddbank, setIsaddbank] = useState(false);
  const [isaddadj, setIsaddadj] = useState(false);
  const [visible, setVisible] = useState(false);

  //to close the popups opened

  const handleCancel = () => {
    setVisible(!visible);
    setIsaddbank(false);
    setIsaddadj(false);
  };

  // Fetching api
  const fetchCompanyBanks = async (customPage = null) => {
    try {
      const path = '/company/banks';
      const params = {};

      //setting parameters to send for search results

      if (inputSearchValue) params['search'] = inputSearchValue;
      setLoading(true);
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.length) {
        const finalArray = res.map((company) => {
          return {
            key: company && company.id,
            bankId: company?.bank_code,
            currency: company && company.currency,
            bankimage: company && company.bank_image ? company.bank_image : null,
            bankname: company && company.bank_code_name ? company.bank_code_name : '-',
            bankaccountname: company && company.bank_holder_name ? company.bank_holder_name : '-',
            currencyName: company?.currency_name,
            bankaccountnumber: company && company.bank_account_number ? company.bank_account_number : '-',
            balance: company && company.balance ? company.balance : '-',
            deposit: company.allow_deposit,
            withdraw: company.allow_withdraw,
            apikey: company && company.api_key ? company.api_key : '',
            callbackkey: company && company.callback_key ? company.callback_key : '',
            remark: company && company.remark ? company.remark : ''
          };
        });
        setcompanyTableData(finalArray);
        setFiltered(finalArray);
      } else {
        setcompanyTableData([]);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };

  useEffect(() => {
    fetchCompanyBanks();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (inputSearchValue) {
      let result = [];
      result = companyTableData.filter((data) => {
        return (
          data.bankname.toLowerCase().search(inputSearchValue.toLowerCase()) != -1 ||
          data.bankaccountname.toLowerCase().search(inputSearchValue.toLowerCase()) != -1
        );
      });
      setFiltered(result);
    }
  };
  return (
    <>
      <HeaderCardDetails />
      <div style={{ padding: '20px' }}>
        <div>
          <Card
            title="Filters"
            style={{
              borderRadius: '3px'
            }}>
            <form onSubmit={onSubmit}>
              <div>
                <Row
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center'
                  }}>
                  <Col
                    span={18}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexWrap: 'wrap'
                    }}>
                    <label style={{}}>
                      {lang.label_bankname} / {lang.label_bankaccountname}:
                    </label>
                    <Input
                      style={inputStyle}
                      value={inputSearchValue}
                      type="text"
                      onChange={(e) => {
                        setInputSearchValue(e.target.value);
                      }}
                    />
                    <Button
                      htmlType="submit"
                      style={{
                        marginRight: '0.2rem',
                        marginLeft: '0.5rem'
                      }}
                      className="btnStyle">
                      {lang.label_search}
                    </Button>

                    <Button
                      onClick={() => {
                        setInputSearchValue('');
                        fetchCompanyBanks();
                      }}
                      type="secondary"
                      className="btnReset">
                      <label style={{ color: '#405189' }}>{lang.label_reset}</label>
                    </Button>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexWrap: 'wrap'
                    }}>
                    {hasPermission('Add Bank') && (
                      <Button
                        onClick={() => {
                          setVisible(true);
                          setIsaddadj(false);
                          setIsaddbank(true);
                        }}
                        style={{ left: '20px' }}
                        className="btnStyle">
                        {lang.label_addbank}
                      </Button>
                    )}

                    {hasPermission('Add Adjustments') && (
                      <Button
                        onClick={() => {
                          setVisible(true);
                          setIsaddadj(true);
                          setIsaddbank(false);
                        }}
                        style={{ left: '20px' }}
                        className="btnStyle">
                        {lang.label_adjustment}
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </form>
          </Card>
          <br />
          <CompanyBanksTable companyTableData={filtered} loading={loading} fetchCompanyBanks={fetchCompanyBanks} />
        </div>

        <div>
          {(isaddbank || isaddadj) && (
            <Modal
              closeIcon={null}
              title={isaddbank ? lang.label_addbank : isaddadj ? lang.label_adjustment : ''}
              open={visible}
              footer={null}
              width={isaddbank ? 800 : isaddadj ? 500 : ''}
              closable={true}
              onCancel={handleCancel}>
              {isaddbank ? (
                <AddCompanyBankPopup
                  handleCancel={handleCancel}
                  companyTableData={companyTableData}
                  fetchCompanyBanks={fetchCompanyBanks}
                />
              ) : isaddadj ? (
                <AdjustCompanyBankPopup handleCancel={handleCancel} fetchCompanyBanks={fetchCompanyBanks} />
              ) : (
                ''
              )}
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyBanks;
