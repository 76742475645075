import { Input, Button, Row, Col, Select, message } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PostApi from '../../../helps/contexts/Api/PostApi';
import GetApi from '../../../helps/contexts/Api/GetApi';
import PutApi from '../../../helps/contexts/Api/PutApi';
import { Editor } from '@tinymce/tinymce-react';
import ErrorsHandlingComp from '../../../helps/errorsHandlingComp';
import axios from 'axios';
import { get } from 'react-hook-form';
import { getConfig } from '../../../helps/contexts/Api/api';
import { api } from '../../../helps/contexts/Api/axios';

const AdminEditForm = ({ rowdata, onCancel, getUsers, setEditAdmin }) => {
  const phoneprev = rowdata?.phone;
  const phonewithoutcode = phoneprev.slice(3);
  const phonecode = phoneprev.substring(0, 3);
  const oldphone = phonecode + phoneprev;
  const editorRef = useRef();
  const nav = useNavigate();
  var adminid = rowdata?.id;
  // form states
  const [username, setUserName] = useState(rowdata && rowdata.username ? rowdata.username : '');
  const [password, setPassword] = useState();
  const [name, setName] = useState(rowdata && rowdata.name ? rowdata.name : '');
  const [remark, setRemark] = useState(rowdata && rowdata.remark ? rowdata.remark : '');
  const [countrycode, setcountrycode] = useState(phonecode && phonecode ? phonecode : '');
  const [phone, setPhone] = useState(rowdata?.phone && rowdata?.phone ? rowdata?.phone : '');
  const [errors, setErrors] = useState({});

  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { Option } = Select;
  const [codedata, setcodedata] = useState([]);

  useEffect(() => {
    fetchCountryCode();
  }, []);
  // Fetching api

  const fetchCountryCode = async () => {
    const path = '/user/phone/codes';
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path, {}, params);

    if (res && res.length) {
      const finalArray = res.map((cc, index) => ({
        key: index,
        code: cc.code
      }));
      setcodedata(finalArray);
    } else {
      setcodedata([]);
    }
  };
  const oncodechange = (value) => {
    setcountrycode(value);
  };
  //form submit to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      const finalphone = countrycode + phone;
      const path = `/admin/edit/${adminid}`;
      // sending request
      const res = await api.put(
        path,
        {
          user_name: username,
          password: password,
          name: name,
          phone: phone,
          remark: remark
        },
        getConfig(localStorage.getItem('token'))
      );
      //if success
      if (res?.status === 200) {
        message.success('Admin updated successfully');
        setName('');
        setPassword('');
        setcountrycode('');
        setPhone('');
        setUserName('');
        setRemark('');
        onCancel();
        getUsers();
        setErrors({});
        // setEditAdmin(false);
      }
    } catch (e) {
      if (e?.response?.status === 422) {
        Object.values(e.data.message).map((errors) => message.error(errors));
        setErrors(e?.response?.data?.errors);
      }
    }
  };
  //username input change and validation
  const onInputChange = (e) => {
    const { value } = e.target;

    const re = /^[a-z0-9_-]*$/;
    if (value === '' || (re.test(value) && (value?.length > 8 || value?.length <= 12))) {
      setUserName(value);
    } else {
      message.error('Please Enter Only Numbers ,Lowercase Alphabets');
    }
  };

  return (
    <div
      style={{
        width: '97%',
        height: '85%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: '3%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '-6%'
          }}>
          <Row>
            <Col span={24}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_name}</label>
              <br />

              <Input
                defaultValue="Admin Name"
                style={{
                  width: '94%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                placeholder="Admin Name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>
                {lang.label_username}
                {/* <span style={{ color: "red" }}>*</span> */}
              </label>
              <br />

              <Input
                defaultValue="Admin user Username"
                type="text"
                placeholder="Admin user Name"
                name="username"
                required
                value={username}
                onChange={onInputChange}
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                minLength={8}
                maxLength={12}
              />
              <ErrorsHandlingComp errors={errors} name="user_name" />
            </Col>
            {/* <Col span={12}>
              <label>
                {lang.label_password}
                <span style={{ color: "red" }}>*</span>
              </label>{" "}
              <br />
              <Input.Password
                style={{
                  width: "85%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                required
                type="password"
                placeholder="Enter Password"
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                maxLength={18}
                minLength={8}
              />
            </Col> */}
            {/* <Col span={3}>
              <label>code </label>
              <br />
              <Select
                style={{ width: "90%", marginTop: "5px" }}
                onChange={oncodechange}
                defaultValue={countrycode}
              >
                <Option>{}</Option>
                {codedata &&
                  codedata.map((cc, index) => {
                    return (
                      <Option key={index} value={cc.code}>
                        {cc.code}
                      </Option>
                    );
                  })}
              </Select>
            </Col> */}
            <Col span={12}>
              <label>{lang.label_phone} </label>
              <br />

              <Input
                style={{
                  width: '84%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                placeholder="Enter Phone Number"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                minLength={11}
                maxLength={14}
              />
              <ErrorsHandlingComp errors={errors} name="phone" />
            </Col>
          </Row>

          <Row></Row>

          <br />

          <Row>
            <Col span={24}>
              <label>{lang.label_remark}</label>
              <br />

              {/* <TextArea
                  showCount
                  maxLength={100}
                  onChange={onChangeRemark}
                  style={{
                    width: "100%",
                    height: "100px",
                    borderRadius: "3px",
                    marginBottom: "5px",
                    marginTop: "5px",
                  }}
                  value={remark}
                /> */}
              <Editor
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={remark}
                onEditorChange={(e, editor) => setRemark(e)}
                init={{
                  menubar: true,
                  width: 600,
                  height: 350,
                  selector: 'textarea#local-upload',
                  plugins: 'image code'
                  // toolbar: 'image code',

                  // toolbar: 'image',
                }}
              />
            </Col>
          </Row>
          <br />

          <div style={{}}>
            {' '}
            <Button
              style={{
                borderRadius: '3px',
                backgroundColor: '#004A7F',
                color: 'white',
                position: 'absolute',
                right: '20%',
                top: '94%'
              }}
              htmlType="submit">
              {lang.label_submit}
            </Button>
            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                position: 'absolute',
                right: '8%',
                top: '94%'
              }}
              onClick={() => {
                setName('');
                setPassword('');
                setPhone('');
                setUserName('');
                setcountrycode('');
                setEditAdmin(false);
                onCancel();
              }}>
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminEditForm;
