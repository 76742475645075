import { Card, Col, Row, Table, message } from 'antd';

import { useContext } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import LangContext from '../../../../helps/contexts/lang-context';
import { convertUTCToLocalTime } from '../../../../helps/HelperFunctions';

const AgentPositionReportTable = ({ data, loading }) => {
  const handleMenuClick = (e) => {
    message.info('Click on menu item.');
  };
  //   console.log('asdfgh', data);
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const columns = [
    {
      title: lang.label_agent_id,
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: lang.label_agent_level,
      dataIndex: 'remark',
      key: 'remark',
      render: (text) => <p>{text}</p>
      // render: (text) => <span>{text}</span>
    },

    {
      title: lang.lable_commission,
      dataIndex: 'remark',
      key: 'remark',
      render: (text) => <p>{text}</p>
      // render: (text, record) => (
      //   <div>
      //     <span>{text}</span>
      //     <br />
      //     <span>{record.browser}</span>
      //   </div>
      // )
    },

    {
      title: lang.lable_active_player,
      dataIndex: 'remark',
      key: 'remark',
      render: (text) => <p>{text}</p>
    },

    {
      title: lang.lable_direct_player,
      dataIndex: 'remark',
      key: 'remark',
      render: (text) => <p>{text}</p>
    },
    {
      title: lang.lable_direct_player_revenue,
      dataIndex: 'remark',
      key: 'remark',
      render: (text) => <p>{text}</p>
    },
    {
      title: lang.lable_direct_player_revenue_valid,
      dataIndex: 'remark',
      key: 'remark',
      render: (text) => <p>{text}</p>
    },
    {
      title: lang.lable_shared_expense,
      dataIndex: 'remark',
      key: 'remark',
      render: (text) => <p>{text}</p>
    },
    {
      title: lang.lable_winloss_fee,
      dataIndex: 'remark',
      key: 'remark',
      render: (text) => <p>{text}</p>
    },
    {
      title: lang.lable_direct_profit,
      dataIndex: 'remark',
      key: 'remark',
      render: (text) => <p>{text}</p>
    },
    {
      title: lang.lable_downline_profit,
      dataIndex: 'remark',
      key: 'remark',
      render: (text) => <p>{text}</p>
    },
    {
      title: lang.lable_total_commission,
      dataIndex: 'remark',
      key: 'remark',
      render: (text) => <p>{text}</p>
    }
  ];
  return (
    <div>
      <div
        style={{
          backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRadius: '5px'
        }}>
        <div className="card">
          <Row
            style={{
              height: '100%',
              padding: '15px'
            }}>
            <Col
              span={24}
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%'
              }}>
              <span
                style={{
                  width: '100%',
                  textAlign: 'left',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '19px',
                  lineHeight: '23px',
                  fontFamily: 'Roboto',
                  letterSpacing: '0px',
                  color: '#003049',
                  opacity: 1,
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <span style={{ paddingRight: '10px' }}>Position Summary Report</span>
                <span style={{ fontSize: '10px', color: 'rgba(141, 146, 150, 1)' }}>
                  Weekly/Monthly reports will be generated within 48 hours after the end of the natural week/month, once
                  the data is reviewed, it can't run again.
                </span>
              </span>
            </Col>
          </Row>
        </div>
        <div style={{ padding: '0px 15px 15px 15px' }}>
          <Table loading={loading} columns={columns} bordered dataSource={data} pagination={false} size="small" />
        </div>
      </div>
    </div>
  );
};

export default AgentPositionReportTable;
