import SubHeading from '../../../components/HelperComponents/SubHeading';
import styles from './CreateModal/CreateModal.module.css';
import CustomButton from '../../../components/HelperComponents/CustomButton';
import Loader from '../../../components/HelperComponents/Loader';
import LangContext from '../../../helps/contexts/lang-context';
import React, { useContext } from 'react';

const DeleteModal = ({ handleCloseModal, submitLoading, deleteFunc }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  return (
    <div
      style={{
        marginTop: '10px'
      }}>
      <SubHeading className={styles.heading}>{lang.label_deletegamecategory}</SubHeading>
      <div>
        <div
          className={styles.flexStyle}
          style={{
            marginTop: '20px',
            justifyContent: 'flex-end'
          }}>
          {submitLoading ? (
            <Loader />
          ) : (
            <CustomButton type={styles.deleteBtn} text={lang.label_delete} onClick={deleteFunc} />
          )}
          <CustomButton type={styles.deleteCancelBtn} text={lang.label_cancel} onClick={handleCloseModal} />
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
