import React from 'react'

const Spacer = ({height = '10px'}) => {
const spacerStyle = {
height: height
}
    return (
    <div style={spacerStyle}></div>
  )
}

export default Spacer