import {Table, message } from "antd";
import LangContext from "../../../helps/contexts/lang-context";
import { useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { convertUTCToLocalTime } from "../../../helps/HelperFunctions";

const PlayerHistoryTable = ({ data, loading }) => {
  const handleMenuClick = (e) => {
    message.info("Click on menu item.");
  };
  console.log("asdfgh",data)
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const columns = [
    {
      title: lang.label_user,
      dataIndex: "name",
      key: "name",
      // render: (_, record) => {
      //   return (
      //     <Link
      //       style={{ color: "#1677ff" }}
      //       state={{ name: record.name }}
      //       to={{
      //         pathname: `/player-management/player/${record.id}/view`,
      //       }}
      //     >
      //       {record.name}
      //     </Link>
      //   )
      // },
    },
    {
      title: lang.label_ip,
      dataIndex: "ip",
      key: "ip",
      render: (text) => <span>{text}</span>,
    },
  
      {
        title: lang.label_device,
        dataIndex: "device",
        key: "device",
        render: (text, record) => (
          <div>
            <span>{text}</span>
            <br />
            <span>{record.browser}</span>
          </div>
        ),
      },
    
    
    {
      title: lang.label_time,
      dataIndex: "time",
      key: "time",
      render: (time) => <span>{convertUTCToLocalTime(time)}</span>,
    },
    
    {
      title: lang.label_remark,
      dataIndex: "remark",
      key: "remark",
      render: (text) => <p>{text}</p>,
    },
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      bordered
      dataSource={data}
      pagination={false}
      size="small"
    />
  );
};

export default PlayerHistoryTable;
