import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { sortingGameCategory } from '../../helps/contexts/Api/api';
import PaginationComp from './PaginationComp';

const DraggableBodyRow = ({ index, moveRow, ...props }) => {
  const ref = React.useRef();
  const [, drop] = useDrop({
    accept: 'row',
    drop(item) {
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Move the row
      moveRow(dragIndex, hoverIndex);
    }
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'row',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(drop(ref));
  preview(ref);

  return <tr ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }} {...props} />;
};

const DragTableComp = ({
  setData,
  dataSource,
  columns,
  sortingfunc,
  game_category,
  totalLength,
  perPage,
  onChangePage,
  currentPage,
  draggable = true
}) => {
  const [isSortApi, setIsSortApi] = useState(false);
  const moveRow = async (dragIndex, hoverIndex) => {
    const dragRecord = dataSource[dragIndex];
    const newData = [...dataSource];

    // Remove the dragged record from the array
    newData.splice(dragIndex, 1);

    // Insert the dragged record at the hover index
    newData.splice(hoverIndex, 0, { ...dragRecord });

    // Update the sort values
    newData.forEach((record, index) => {
      record.sort = index + 1;
    });

    setData(newData);
    setIsSortApi(true);
  };

  const sortUpdateFunc = async () => {
    try {
      const token = localStorage.getItem('token');
      const newArray = dataSource.map(({ key, sort, id }) => ({
        id: key || id,
        sort_order: sort
      }));
      const payload = {
        records: newArray
      };
      const res = await sortingfunc(token, payload, game_category);
      if (res.status === 200) {
        setIsSortApi(false);
      }
    } catch (error) {
      setIsSortApi(false);
    }
  };

  useEffect(() => {
    if (isSortApi) {
      sortUpdateFunc();
    }
  }, [isSortApi]);

  const paginationConfig = {
    current: currentPage,
    pageSize: perPage ? perPage : 0,
    total: totalLength ? totalLength : 0,
    showSizeChanger: false,
    showQuickJumper: false,
    showTotal: (total) => `Total ${total} items`,
    onChange: onChangePage,
    itemRender: (current, type, originalElement) => {
      if (type === 'prev') {
        return <span className="pagination-item">Previous</span>;
      }
      if (type === 'next') {
        return <span className="pagination-item">Next</span>;
      }
      return originalElement;
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="draggable-table-container">
        <Table
          dataSource={dataSource}
          pagination={false}
          columns={columns}
          components={
            draggable
              ? {
                  body: {
                    row: DraggableBodyRow
                  }
                }
              : null
          }
          rowKey={(record) => record.key}
          onRow={(record, index) => {
            return {
              index,
              moveRow
            };
          }}
        />
      </div>
      {dataSource?.length > 0 && <PaginationComp paginationConfig={paginationConfig} />}
    </DndProvider>
  );
};

export default DragTableComp;
