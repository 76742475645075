import { Button, Table, Modal, Switch, message } from "antd";
import { MdEdit } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import LangContext from "../../helps/contexts/lang-context";
import { useContext, useState } from "react";
import EditPromotionForm from "../../components/forms/EditPromotionForm";
import DeletePromotionForm from "../../components/forms/DeletePromotionForm";
import ChangeStatusModal from "../../components/ChangeStatusModal/ChangeStatusModal";
import PutApi from "../../helps/contexts/Api/PutApi";
import styles from "../Promotions/Promotions.module.css";
import { convertUTCToLocalTime } from "../../helps/HelperFunctions";

const PromotionsTable = ({ data, loading, getPromotions, currencyData, promoCategoryData,getPromotionCategory,currentpage,onChangepage,total,perpage  }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  //states
  const [isEdit, setIsedit] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const [status, setStatus] = useState();
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState({});
  const [ischangestatus, setIschangestatus] = useState(false);
  const {id} = rowData;
  // Edit promotion popup
  const handleCancel1 = (e) => {
    setVisible(false);
    setDelete(false);
    setIsedit(false);
    setIschangestatus(false);
  };
  const handleCancel = (e) => {
    if(total>1){
      const currentTotalPage = (perpage * currentpage) - (perpage-1);
      if(currentTotalPage === total){
        --currentpage;
        onChangepage(currentpage);
      }
      onChangepage(currentpage)
    }
    setVisible(false);
    setIsedit(false);
  };

  //on form submit request to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const path = `/admin/promotions/${id}/toggle/status`;
      // sending request
      const res = await PutApi.PutApiRequest(path, {
        status: status,
      });

      //if success
      if (res.status == true) {
        message.success("Status Updated Successfully");
        handleCancel1();
        getPromotions();
      } else {
        message.success("Status Updation Failed");
      }
    } catch (e) {
      // var message1 = e.message;
      // alert(message1);
    }
    finally{
      setIschangestatus(false)
    }
  };

  const columns = [
    {
      title: lang.label_id,
      dataIndex: "id",
      key: "id",
   
    },
    {
      title: lang.label_desktopImage,
      dataIndex: "desktopImage",
      key: "desktopImage",
      render: (record) => (
        <img
          style={{ width: "50px", height: "50px" }}
          src={record}
          alt="desktopImage"
        ></img>
      ),
    },
    {
      title: lang.label_image,
      dataIndex: "image",
      key: "image",
      render: (record) => (
        <img
          style={{ width: "50px", height: "50px" }}
          src={record}
          alt="image"
        ></img>
      ),
    },
    {
      title: lang.label_title,
      dataIndex: "title",
      key: "title",
    },
    {
      title: lang.label_starteddate,
      dataIndex: "createdadte",
      key: "createddate",
      render: (time, row) => (
        <>
          <a title={row["createddate"]}>
            <span style={{ color: "black" }}>{convertUTCToLocalTime(row["createddate"]) }</span>
          </a>
        </>
      ),
    },

    {
      title: lang.label_expirydate,
      dataIndex: "expirydate",
      key: "expirydate",
      render: (time, row) => (
        <a title={row["expirydate"]}>
          <span style={{ color: "black" }}>{convertUTCToLocalTime(row["expirydate"])}</span>
        </a>
      ),
    },

    {
      title: lang.label_status,
      dataIndex: "status",
      key: "status",
      render: (record) =>
        record === 1 ? (
          <div
            onClick={() => {
              setVisible(true);
              setIsedit(false);
              setDelete(false);
              setIschangestatus(true);
            }}
          >
            {" "}
            <Switch size="small" checked={record} />
          </div>
        ) : (
          <div
            onClick={() => {
              setVisible(true);
              setIsedit(false);
              setDelete(false);
              setIschangestatus(true);
            }}
          >
            {" "}
            <Switch size="small" checked={record} />
          </div>
        ),
    },
    {
      title: lang.label_updated_by,
      dataIndex: "turnedonby",
      key: "turnedonby",
    },
    {
      title: lang.label_action,
      key: "action",
      render: (text, record) => (
        <span
          style={{ display: "flex", flexDirection: "row", columnGap: "5px" }}
        >
          {" "}
          {}{" "}
          <Button
            onClick={() => {
              setVisible(!visible);
              setIsedit(true);
              setDelete(false);
              setRowData({ ...record });
              setStatus(record.status);
            }}
            className={styles.editButton}
          >
            <MdEdit size={15} style={{ marginLeft: "-6px" }} />
          </Button>
          <Button
            onClick={() => {
              setVisible(true);
              setIsedit(false);
              setDelete(true);
            }}
            className={styles.deleteButton}
          >
            <AiOutlineDelete size={15} style={{ marginLeft: "-8px" }} />
          </Button>
        </span>
      ),
    },
  ];
  return (
    <div>
      <Table
        style={{ width: "100%" }}
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        bordered
        onRow={(record) => {
          return {
            onClick: () => {
              setRowData(record);
            },
          };
        }}
      />
      <div>
        {(isEdit || isdelete || ischangestatus) && (
          <Modal
            closeIcon={null}
            title={
              isEdit
                ? lang.label_editpromotion
                : isdelete
                ? lang.label_deletepromotion
                : ischangestatus
                ? lang.label_changestatus
                : ""
            }
            open={visible}
            onCancel={handleCancel}
            footer={null}
            width={isEdit ? 800 : isdelete ? 350 : ischangestatus ? 460 : ""}
            closable={false}
          >
            {isEdit ? (
              <EditPromotionForm
                rowdata={rowData}
                onCancel={handleCancel}
                promoCategoryData={promoCategoryData}
                currencyData={currencyData}
                getPromotions={getPromotions}
                getPromotionCategory={getPromotionCategory}
              />
            ) : isdelete ? (
              <DeletePromotionForm
                rowdata={rowData}
                onCancel={handleCancel}
                getPromotions={getPromotions}
              />
            ) : ischangestatus ? (
              <ChangeStatusModal
                rowdata={rowData}
                onCancel={handleCancel1}
                onSubmit={onSubmit}
                text={
                  "Are you Sure You want to change the status of promotion??"
                }
              />
            ) : (
              ""
            )}
          </Modal>
        )}
      </div>
    </div>
  );
};
export default PromotionsTable;
