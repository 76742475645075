import ViewPlayerPage from '../../src/pages/player/PlayerList/view/ViewPlayerPage';
import GameCategories from '../pages/GameManagement/GameCategories/GameCategories';
import GameCategoryPlatformItems from '../pages/GameManagement/GameCategoryPlatformItems/GameCategoryPlatformItems';
import GameList from '../pages/GameManagement/GameList/GameList';
import Platforms from '../pages/GameManagement/Platforms/Platforms';
import Permissions from '../pages/Permissions/Permissions';
import Promotions from '../pages/Promotions/Promotions.jsx';
// import Deposit from "../pages/TransactionManagement/Deposit&Withdraw/Deposit";
import Withdraw from '../pages/TransactionManagement/Deposit&Withdraw/Withdraw.js';
import Deposit from '../pages/TransactionManagement/Deposit&Withdraw/Deposit.js';
import AdminLoginHistory from '../pages/adminmanagement/AdminLoginHistory/AdminLoginHistory.jsx';
import AdminLogs from '../pages/adminmanagement/AdminLogs/AdminLogs.jsx';
import IpWhiteList from '../pages/adminmanagement/IpWhiteList/IpWhiteList.js';
import UserList from '../pages/adminmanagement/UserList/UserList.jsx';
import AgentList from '../pages/agentmanagement/agent/AgentList';
import ChangeAgentHistory from '../pages/agentmanagement/changeAgentHistory/ChangeAgentHistory';
import SuperiorAgentList from '../pages/agentmanagement/superioragent/SuperiorAgentList';
import DashboardPage from '../pages/dashboard/DashboardPage';
import GameAccessHistory from '../pages/player/GameAccessHistory/GameAccessHistory';
import GameTransactionHistory from '../pages/player/GameTransactionHistory/GameTransactionHistory';
import PlayerList from '../pages/player/PlayerList/PlayerList';
import PlayerNotification from '../pages/player/PlayerNotification/PlayerNotification';
import LoginHistory from '../pages/player/loginHistory/LoginHistory';
import PlayerBalanceHistory from '../pages/player/playerBalanceHistory/PlayerBalanceHistory';
import BetDetails from '../pages/reports/BetDetails/BetDetails.jsx';
import BetHistory from '../pages/reports/BetHistory';
import BankCodes from '../pages/transactions/BankCodes/BankCodes.jsx';
import BankMessages from '../pages/transactions/BankMessages/BankMessages';
import CompanyBanksHistory from '../pages/transactions/CompanyBanksHistory/CompanyBanksHistory';
import PaymentCategories from '../pages/transactions/PaymentCategories/PaymentCategories.jsx';
import PaymentMethod from '../pages/transactions/PaymentMethod/PaymentMethod.jsx';
import CompanyBanks from '../pages/transactions/companyBanks/CompanyBanks';
import PromotionCategories from '../pages/Promotions/PromotionCategories/PromotionCategories.js';
import Settings from '../pages/settings/Settings.jsx';
import Rebate from '../pages/GameManagement/Rebate/Rebate.js';
import AffiliateList from '../pages/Affiliate/Affiliate-List/AffiliateList.js';
import TagManagement from '../pages/player/TagManagement/TagManagement.js';
import TagManagementHistory from '../pages/player/TagAdjustmentHistory/TagAdjustmentHistory.js';
import AgentNotification from '../pages/agentmanagement/AgentNotification/AgentNotification.jsx';
import PromotionHistory from '../pages/Promotions/PromotionHistory/PromotionHistory.js';
import Agentprofile from '../pages/agentmanagement/agentProfile/agentprofile.jsx';
import ExportList from '../pages/exportList/ExportList.js';
import AgentReport from '../pages/agentmanagement/agentReport/AgentReport.jsx';
import AgentListNew from '../pages/agentmanagement/agent/AgentListNew.jsx';
import DownlineAgentList from '../pages/agentmanagement/agent/DownlineAgentList.jsx';
import NewAgent from '../pages/agentmanagement/agent/newagent/NewAgent.jsx';
import AgentPositionReport from '../pages/agentmanagement/agent/agentReport/AgentPositionReport.jsx';
import AgentCommissionReport from '../pages/agentmanagement/agent/agentReport/AgentCommissionReport.jsx';

export const privateRoutes = [
  {
    key: 1,
    name: 'Dashboard',
    path: '/dashboard',
    element: <DashboardPage />
  },
  {
    key: 2,
    name: 'Deposit',
    path: '/transaction-management/deposit',
    element: <Deposit />
  },
  {
    key: 3,
    name: 'Withdraw',
    path: '/transaction-management/withdraw',
    element: <Withdraw />
  },
  {
    key: 4,
    name: 'Company Banks',
    path: '/transaction-management/company-banks',
    element: <CompanyBanks />
  },
  {
    key: 5,
    name: 'Company Banks History',
    path: '/transaction-management/payment-method-history',
    element: <CompanyBanksHistory />
  },
  {
    key: 6,
    name: 'Bank Messages',
    path: '/transaction-management/bank-messages',
    element: <BankMessages />
  },
  {
    key: 7,
    name: 'Player List',
    path: '/player-management/player-list',
    element: <PlayerList />
  },
  {
    key: 7.1,
    name: 'Player View',
    path: '/player-management/player/:id/view',
    element: <ViewPlayerPage />
  },
  {
    key: 8,
    name: 'Player Balance History',
    path: '/player-management/player-balance-history',
    element: <PlayerBalanceHistory />
  },
  {
    key: 9,
    name: 'Login History',
    path: '/player-management/login-history',
    element: <LoginHistory />
  },
  {
    key: 10,
    name: 'Game Transaction History',
    path: '/player-management/game-transaction-history',
    element: <GameTransactionHistory />
  },
  {
    key: 11,
    name: 'Game Access History',
    path: '/player-management/game-access-history',
    element: <GameAccessHistory />
  },
  {
    key: 12,
    name: 'Player Notification',
    path: '/player-management/player-notification',
    element: <PlayerNotification />
  },
  {
    key: 13,
    name: 'Promotions',
    path: '/promotions/promotions-list',
    element: <Promotions />
  },
  {
    key: 14,
    name: 'Agent List',
    path: '/agent-management/agent-list',
    element: <AgentList />
  },

  {
    key: 15,
    name: 'Superior Agent List',
    path: '/agent-management/superior-agent-list',
    element: <SuperiorAgentList />
  },
  {
    key: 16,
    name: 'Change Agent History',
    path: '/agent-management/change-agent-history',
    element: <ChangeAgentHistory />
  },
  {
    key: 17,
    name: 'User List',
    path: '/admin-management/user-list',
    element: <UserList />
  },
  {
    key: 18,
    name: 'Admin Login History',
    path: '/admin-management/admin-login-history',
    element: <AdminLoginHistory />
  },
  {
    key: 19,
    name: 'Permissions',
    path: '/admin-management/permissions',
    element: <Permissions />
  },
  {
    key: 20,
    name: 'Admin Logs',
    path: '/admin-management/admin-logs',
    element: <AdminLogs />
  },
  {
    key: 21,
    name: 'IP White List',
    path: '/admin-management/ip-white-list',
    element: <IpWhiteList />
  },

  {
    key: 22,
    name: 'Platforms',
    path: '/game-management/platforms',
    element: <Platforms />
  },
  {
    key: 23,
    name: 'Game Categories',
    path: '/game-management/game-categories',
    element: <GameCategories />
  },
  {
    key: 24,
    name: 'Game List',
    path: '/game-management/game-list',
    element: <GameList />
  },
  {
    key: 25,
    name: 'Change Agent History',
    path: 'Agent-management/Change-Agent-History',
    element: <ChangeAgentHistory />
  },
  {
    key: 26,
    name: 'Game Category Platform Items',
    path: '/game-management/game-category-platform-items',
    element: <GameCategoryPlatformItems />
  },
  {
    key: 27,
    name: 'Bet History',
    path: '/reports/bet-history',
    element: <BetHistory />
  },
  {
    key: 28,
    name: 'Bet Details',
    path: '/reports/bet-history/bet-details/:id',
    element: <BetDetails />
  },
  {
    key: 29,
    name: 'Payment Method',
    path: '/transaction-management/payment-methods',
    element: <PaymentMethod />
  },
  {
    key: 30,
    name: 'Payment Categories',
    path: '/transaction-management/payment-categories',
    element: <PaymentCategories />
  },
  {
    key: 31,
    name: 'Bank Codes',
    path: '/transaction-management/bank-codes',
    element: <BankCodes />
  },
  {
    key: 32,
    name: 'Promotion Category',
    path: '/promotions/promotion-category',
    element: <PromotionCategories />
  },
  {
    key: 33,
    name: 'Settings',
    path: '/settings',
    element: <Settings />
  },
  {
    key: 34,
    name: 'Rebate',
    path: '/game-management/rebate',
    element: <Rebate />
  },
  {
    key: 35,
    name: 'Affiliate List',
    path: '/affiliate-management/affiliate-list',
    element: <AffiliateList />
  },
  {
    key: 36,
    name: 'Tag Management',
    path: '/player-management/tag-management',
    element: <TagManagement />
  },
  {
    key: 37,
    name: 'Tag Adjustment History',
    path: '/player-management/tag-management/tag-adjustment-history',
    element: <TagManagementHistory />
  },
  {
    key: 38,
    name: 'Agent Notification',
    path: '/agent-management/agent-notification',
    element: <AgentNotification />
  },
  {
    key: 39,
    name: 'Promotion History',
    path: '/promotions/promotion-history',
    element: <PromotionHistory />
  },

  {
    key: 40,
    name: 'Export List',
    path: '/export-list',
    element: <ExportList />
  },
  {
    key: 41,
    name: 'Agent List New',
    path: '/agent-management/agent-list-new',
    element: <AgentListNew />
  },
  {
    key: 42,
    name: 'Agent List',
    path: '/agent-management/agent-report',
    element: <AgentReport />
  },
  {
    key: 43,
    name: 'DownLine List',
    path: '/agent-management/downline-list',
    element: <DownlineAgentList />
  },
  {
    key: 44,
    name: 'DownLine List',
    path: '/agent-management/agent-list/new-agent',
    element: <NewAgent />
  },
  {
    key: 45,
    name: 'Agent Profile',
    path: '/agent-management/agent-list/agent-profile',
    element: <Agentprofile />
  },
  {
    key: 46,
    name: 'Agent Position Report',
    path: '/agent-management/agent-position-report',
    element: <AgentPositionReport />
  },
  {
    key: 47,
    name: 'Agent Commission Report',
    path: '/agent-management/agent-commission-report',
    element: <AgentCommissionReport />
  },
];
