import React, { useContext, useEffect, useState } from 'react';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import { Button, Card, Col, message, Modal, Popover, Row, Table, Tag, Spin } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { Link, useNavigate } from 'react-router-dom';
import { activeTransactionStatus, convertUTCToLocalTime, toFixedFunc } from '../../../helps/HelperFunctions';
import CustomBadge from '../../../components/HelperComponents/CustomBadge';
import { CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { getTransactions } from '../../../helps/contexts/Api/api';
import GetApi from '../../../helps/contexts/Api/GetApi';
import TransactionFilters from './Components/TransactionFilters';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import PutApi from '../../../helps/contexts/Api/PutApi';
import styles from '../../dashboard/DashboardPage.module.css';
import RiskApproveModal from './Components/RiskApproveModal';
import FinanceApproveWithdraw from './Components/FinanceApproveWithdrawModal';
import FinanceApproveWithdrawModal from './Components/FinanceApproveWithdrawModal';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import PaginationComp from '../../../components/HelperComponents/PaginationComp';
import TransactionContext from '../../../helps/contexts/Transaction-Context';
import { FaCopy } from 'react-icons/fa';
import Spacer from '../../../components/HelperComponents/Spacer';
import WithdrawPopup from './Popups/WithdrawPopup/WithdrawPopup';
import WithdrawSumTable from './WithdrawSumTable';
import { TabContext } from '../../../helps/contexts/TabContext';
import { exportReportWithdraw1, exportReportWithdraw2 } from '../../../helps/contexts/Api/downloadFile';
import { LoadingOutlined } from '@ant-design/icons';

const Withdraw = () => {
  const ctx = useContext(LangContext);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const Navigate = useNavigate();
  const [filterData, setFilterData] = useState({ status: ['3', '4', '5', '6'] });
  const { hasPermission } = useCheckPermission();
  const [riskModalOpen, setRiskModalOpen] = useState(false);
  const [faModalOpen, setFaModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [activeTransaction, setActiveTransaction] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [pageSize, setPageSize] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loginUser, setLoginUser] = useState('');
  const [totalWithdrawal, setTotalWithdrawal] = useState([]);
  const { path } = useContext(TabContext);
  const [loadingData, setLoadingData] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0);
  const [downloadedFiles, setDownloadedFiles] = useState(0);
  const downloadedFileIds = new Set();

  const lang = ctx.lang;
  const { socketTransactionObject } = useContext(TransactionContext);

  const [popoverOpen, setPopoverOpen] = useState(null);

  const handleOpenChange = (open, record, value) => {
    const textToCopy = value;

    navigator.clipboard.writeText(textToCopy);

    setPopoverOpen(open ? record.id : null);
  };

  const hide = () => {
    setPopoverOpen(null);
  };

  const [popupId, setPopupId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (id) => {
    setIsModalOpen(true);
    setPopupId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setPopupId(null);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setPopupId(null);
  };

  useEffect(() => {
    if (socketTransactionObject && socketTransactionObject.data.isWithdraw) {
      if (socketTransactionObject.action === 'NEW' && currentPage === 1) {
        const index = transactions.findIndex((item) => item.id === socketTransactionObject.data.id);

        if (index === -1) {
          setTransactions((prevTxns) => [socketTransactionObject.data, ...prevTxns]);
        }
      } else if (socketTransactionObject.action === 'CHANGE') {
        // console.log(socketTransactionObject.data);
        setTransactions((prevTxns) =>
          prevTxns.map((txn) => {
            if (txn.id === socketTransactionObject.data.id) {
              let updatedTransaction = { ...socketTransactionObject.data };
              if (!updatedTransaction.player) {
                updatedTransaction.player = {};
              }
              updatedTransaction.player.agent = txn?.player?.agent;
              return updatedTransaction;
            }
            return txn;
          })
        );
      } else if (socketTransactionObject.action === 'REMOVE') {
        const index = transactions.findIndex((item) => item.id === socketTransactionObject.data.id);

        if (index > -1) {
          const newTransactionList = JSON.parse(JSON.stringify(transactions));
          newTransactionList.splice(index, 1);

          setTransactions(newTransactionList);
        }
      }
    }
  }, [socketTransactionObject]);

  const getPaymentMethods = async () => {
    try {
      const path = '/payment/method/drop/down';
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, {});
      if (res && res.length) {
        // setPaymentMethods(res.map((pm) => ({ ...pm, label: pm.account_name, value: pm.id })));
        setPaymentMethods(
          res.map((pm) => ({ ...pm, label: pm.internal_name || pm.public_name?.en_public_name, value: pm.id }))
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
  };

  function convertToTwoDecimalPlaces(numberString) {
    let number = parseFloat(numberString);
    let formattedNumber = number.toFixed(2);
    return formattedNumber;
  }

  const getTransactions = async () => {
    setLoading(true);
    try {
      const path = '/admin/transactions';
      const finalData = filterData;

      const params = { isWithdraw: 1, ...finalData, page: currentPage };

      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.total_amount) {
        const finalArray = res?.total_amount.map((item, index) => ({
          key: index,
          currencyName: item ? item?.currency_name : '-',
          totalWithdraw: item ? convertToTwoDecimalPlaces(item?.amount) : 0
        }));
        setTotalWithdrawal(finalArray);
      }
      if (res && res.transactions && res.transactions.data && Array.isArray(res.transactions.data)) {
        setLoading(false);
        setTransactions(res.transactions.data);
        setTotalLength(res.transactions.total);
        setPageSize(res.transactions.per_page);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
    setLoading(false);
  };

  const onRefresh = () => {
    getPaymentMethods();
    getTransactions();
  };

  useEffect(() => {
    getTransactions();
  }, [filterData, currentPage]);

  useEffect(() => {
    if (path === '/transaction-management/withdraw') {
      getTransactions();
    }
  }, [path]);

  useEffect(() => {
    getPaymentMethods();
    setLoginUser(localStorage.getItem('user_id'));
  }, []);

  const makeStatusBadge = (row) => {
    let color = 'success';
    if (row.status_name === 'pending') {
      color = 'processing';
    } else if (row.status_name === 'rejected') {
      color = 'red';
    } else if (row.status_name === 'processing') {
      color = 'geekblue';
    } else if (row.status_name === 'Failed') {
      color = 'red';
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: '5px', width: '100%' }}>
        {(() => {
          if (row.is_withdraw_transaction?.is_first === 1) {
            return (
              <Tag bordered={false} color={'volcano'}>
                First
              </Tag>
            );
          }
        })()}
        <Tag bordered={false} color={color}>
          {row.status_name}
        </Tag>
      </div>
    );
  };

  const withdrawActions = (transaction) => {
    // return faLockButton(transaction);
    if (!transaction || !transaction.is_withdraw_transaction) {
      return 'ERROR';
    }

    //if risk is not approved and risk is not locked and transaction is pending and you have permission to risk lock
    // console.log('risk_action_status', transaction.is_withdraw_transaction.risk_action_status !== 1);
    if (transaction?.is_withdraw_transaction?.risk_action_status !== 1 && transaction?.status_name === 'pending') {
      return riskLockButton(transaction?.is_withdraw_transaction, transaction);
    }

    if (
      transaction?.is_withdraw_transaction?.risk_action_status !== 1 &&
      !hasPermission('Finance Approve/Reject Withdraw')
    ) {
      return <>{lang.label_pending_fa_approval}</>;
    }

    if (
      transaction?.is_withdraw_transaction?.risk_action_status === 1 &&
      ['pending', 'Waiting For Payment', 'processing', 'Failed'].includes(transaction.status_name)
    ) {
      return faLockButton(transaction);
    }

    // if (transaction.is_withdraw_transaction.risk_action_status !== 1 && transaction.status_name === 'pending') {
    //   //show finance approve
    //   return <Button type="primary">{lang.label_risk_verify}</Button>;
    // }
  };

  const faLockButton = (transaction) => {
    const withdrawData = transaction?.is_withdraw_transaction;

    if (!withdrawData?.fa_locked_by && withdrawData?.is_fa_locked === 1) {
      return `${lang.label_locked_by} System`;
    }

    if (!withdrawData?.fa_locked_by && hasPermission('Finance Approve/Reject Withdraw')) {
      return (
        <Button onClick={() => lockFAToggle(transaction.id, 1)} type="primary" icon={<LockOutlined />}>
          {lang.label_lock}
        </Button>
      );
    }

    if (!withdrawData?.fa_locked_by && !hasPermission('Finance Approve/Reject Withdraw')) {
      return <>{lang.label_no_permission}</>;
    }

    if (withdrawData?.fa_locked_by && Number(withdrawData?.fa_locked_by.id) === Number(loginUser)) {
      return (
        <div>
          <Button
            type="primary"
            onClick={() => {
              setActiveTransaction(transaction);
              setFaModalOpen(true);
            }}>
            {lang.label_fa_verify}
          </Button>
          <Button onClick={() => lockFAToggle(transaction.id, 0)} icon={<UnlockOutlined />}>
            {lang.label_unlock}
          </Button>
        </div>
      );
    }

    if (withdrawData?.fa_locked_by && withdrawData?.fa_locked_by.id != loginUser) {
      return `${lang?.label_locked_by} ${withdrawData?.fa_locked_by.name}`;
    }
  };

  const lockRiskToggle = async (id, isLock) => {
    setRiskLockState(id, isLock);
    const payload = {
      is_risk_locked: isLock
    };
    try {
      const path = `/admin/withdraw/${id}/risk/lock/unlock`;
      //sending api request
      const res = await PutApi.PutApiRequest(path, payload);
      if (!res || !res.status) {
        setRiskLockState(id, !isLock);
      }
      if (res?.status) {
        const transformedMessage = res?.message.split('_').join(' ');
        message.success(transformedMessage);
      } else {
        const transformedMessage = res?.message.split('_').join(' ');
        message.error(transformedMessage);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Navigate('/login');
      } else {
        setRiskLockState(id, !isLock);
      }
    }
  };

  const setRiskLockState = (id, isLock) => {
    setTransactions((prevTxns) =>
      prevTxns.map((txn) => {
        if (txn.id === id) {
          if (txn.is_withdraw_transaction) {
            txn.is_withdraw_transaction.risk_locked_by = isLock ? { id: Number(loginUser) } : null;
          } else {
            txn.is_withdraw_transaction = { risk_locked_by: isLock ? { id: Number(loginUser) } : null };
          }
        }
        return txn;
      })
    );
  };

  const lockFAToggle = async (id, isLock) => {
    // setFALockState(id, isLock);
    setLoading(true);
    const payload = {
      is_fa_locked: isLock
    };
    try {
      const path = `/admin/withdraw/${id}/fa/lock/unlock`;
      // sending api request
      const res = await PutApi.PutApiRequest(path, payload);
      if ((!res || !res.status) && res.faLockedBy) {
        setFALockStateById(id, true, res.faLockedBy);
      } else {
        setFALockState(id, isLock);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Navigate('/login');
      } else {
        setFALockState(id, !isLock);
      }
    }
    setLoading(false);
  };

  const setFALockState = (id, isLock) => {
    setTransactions((prevTxns) =>
      prevTxns.map((txn) => {
        if (txn.id === id) {
          const updatedTxn = { ...txn };
          if (updatedTxn.is_withdraw_transaction) {
            if (isLock) {
              updatedTxn.is_withdraw_transaction = {
                ...updatedTxn.is_withdraw_transaction,
                fa_locked_by: {
                  ...updatedTxn.is_withdraw_transaction.fa_locked_by,
                  id: Number(loginUser)
                }
              };
            } else {
              updatedTxn.is_withdraw_transaction = {
                ...updatedTxn.is_withdraw_transaction,
                fa_locked_by: null
              };
            }
          } else {
            // Initialize is_withdraw_transaction if it doesn't exist
            updatedTxn.is_withdraw_transaction = {
              ...updatedTxn.is_withdraw_transaction,
              fa_locked_by: null
            };
          }

          return updatedTxn;
        }

        return txn;
      })
    );
  };

  // const setFALockStateById = (id, isLock, lockedBy) => {
  //   setTransactions((prevTxns) =>
  //     prevTxns.map((txn) => {
  //       if (txn.id === id) {
  //         if (txn.is_withdraw_transaction) {
  //           txn.is_withdraw_transaction.fa_locked_by = isLock ? { id: lockedBy.id, name: lockedBy.name } : null;
  //         } else {
  //           txn.is_withdraw_transaction = { fa_locked_by: isLock ? lockedBy : null };
  //         }
  //       }
  //       return txn;
  //     })
  //   );
  // };
  const setFALockStateById = (id, isLock, lockedBy) => {
    setTransactions((prevTxns) =>
      prevTxns.map((txn) => {
        if (txn.id === id) {
          const updatedTxn = { ...txn };

          if (updatedTxn.is_withdraw_transaction) {
            if (isLock) {
              updatedTxn.is_withdraw_transaction = {
                ...updatedTxn.is_withdraw_transaction,
                fa_locked_by: { ...lockedBy }
              };
            } else {
              updatedTxn.is_withdraw_transaction = {
                ...updatedTxn.is_withdraw_transaction,
                fa_locked_by: null
              };
            }
          } else {
            updatedTxn.is_withdraw_transaction = {
              fa_locked_by: isLock ? { ...lockedBy } : null
            };
          }

          return updatedTxn;
        }

        return txn;
      })
    );
  };
  const riskLockButton = (withdrawData, transaction) => {
    if (!withdrawData.risk_locked_by && hasPermission('Approve/Reject Risk')) {
      return (
        <Button onClick={() => lockRiskToggle(transaction.id, 1)} type="primary" icon={<LockOutlined />}>
          {lang.label_lock}
        </Button>
      );
    }

    if (!withdrawData.risk_locked_by && !hasPermission('Approve/Reject Risk')) {
      // return <>{lang.label_no_permission}</>;
      return <>{lang.label_pending_risk_approval}</>;
    }

    if (withdrawData.risk_locked_by && withdrawData.risk_locked_by.id == loginUser) {
      return (
        <div>
          <Button
            type="primary"
            onClick={() => {
              setActiveTransaction(transaction);
              setRiskModalOpen(true);
            }}>
            {lang.label_risk_verify}
          </Button>
          <Button onClick={() => lockRiskToggle(transaction.id, 0)} icon={<UnlockOutlined />}>
            {lang.label_unlock}
          </Button>
        </div>
      );
    }

    if (!withdrawData.risk_locked_by && withdrawData.is_risk_locked === 1) {
      return `${lang.label_locked_by} System`;
    }

    if (withdrawData.risk_locked_by && withdrawData.risk_locked_by.id != loginUser) {
      return `${lang.label_locked_by} ${withdrawData.risk_locked_by.name}`;
    }
  };

  const makeRiskBadge = (transaction) => {
    const withdrawData = transaction?.is_withdraw_transaction;
    const s = withdrawData?.risk_action_status;

    if (!withdrawData || s === 3 || s === 4 || s === 5) {
      return '-';
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Tag
          icon={s == 1 ? <CheckOutlined /> : <CloseOutlined />}
          color={s == 1 ? 'green' : 'red'}
          style={{
            width: 'min-content'
          }}>
          {s == 1 ? lang.label_approvedby : lang.label_rejectedby}{' '}
          {!withdrawData?.risk_action_by && withdrawData?.risk_action_status
            ? 'System'
            : withdrawData?.risk_action_by?.user_name}
        </Tag>
        <span
          style={{
            paddingTop: '7.5px',
            fontSize: '12px'
          }}>
          {withdrawData?.risk_action_note}
        </span>
      </div>
    );
  };

  const exportData = async (payload) => {
    const params = {
      export_type: 0,
      export: 1,
      agent_code: payload.agent_code,
      from_date: payload.from_date,
      to_date: payload.to_date,
      max_amount: payload.max_amount,
      min_amount: payload.min_amount,
      user_name: payload.user_name,
      payment_method_id: payload.payment_method_id,
      reference_no: payload.reference_no,
      status: payload.status,
      transaction_id: payload.transaction_id
    };

    try {
      setLoadingData(true);
      setDownloadedFiles(0);
      const response = await exportReportWithdraw1(params);

      if (response?.data?.job_id) {
        const jobId = response.data.job_id;
        const totalFileCount = response.data.progress.total_file_count;
        setTotalFiles(totalFileCount);

        const pollExportStatus = async (jobId) => {
          try {
            const statusResponse = await exportReportWithdraw2(jobId);
            const files = statusResponse?.data?.file || [];

            files.forEach((file) => {
              if (!downloadedFileIds.has(file.file_id)) {
                const base64String = file.file_content;
                const fileName = file.file_name + (file.file_type.includes('csv') ? '.csv' : '.xlsx');
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: file.file_type });

                const link = document.createElement('a');
                const blobUrl = URL.createObjectURL(blob);
                link.href = blobUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);

                downloadedFileIds.add(file.file_id);

                setDownloadedFiles((prev) => prev + 1);
              }
            });

            if (statusResponse?.data?.progress?.is_all_file_generated) {
              setLoadingData(false);
              message.success(`Export completed. ${totalFileCount} files downloaded.`);
            } else {
              setTimeout(() => pollExportStatus(jobId), 2000);
            }
          } catch (error) {
            setLoadingData(false);
            message.error('Failed to check export status.');
          }
        };

        pollExportStatus(jobId);
      } else {
        setLoadingData(false);
        message.error('Failed to initiate export.');
      }
    } catch (error) {
      setLoadingData(false);
      message.error('Failed to initiate export.');
    }
  };
  const makeFABadge = (transaction) => {
    var tagColor = 'green';
    var tagIcon = <CheckOutlined />;


    if (
      transaction.is_withdraw_transaction.risk_action_status == 2 ||
      transaction.status_name == 'processing' ||
      transaction.status_name == 'pending' ||
      transaction.status_name == 'Waiting For Payment' ||
      transaction.status_name == 'Failed' 
    ) {
      return '-';
    } else if (transaction.status_name == 'rejected') {
      tagColor = 'red';
      tagIcon = <CloseOutlined />;
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Tag icon={tagIcon} color={tagColor}>
          {transaction.status_name.toUpperCase()} {'by'}{' '}
          {!transaction.action_by ? 'System' : transaction.action_by.user_name}
        </Tag>
        <span
          style={{
            paddingTop: '7.5px',
            fontSize: '12px'
          }}>
          {transaction.remark}
        </span>
      </div>
    );
  };

  const onChangePage = (page) => {
    setCurrentPage(page);
    // setIsPageChange(true);
  };

  const paginationConfig = {
    pageSize: pageSize ? pageSize : 1,
    total: totalLength ? totalLength : 0,
    showSizeChanger: false,
    showQuickJumper: false,
    current: currentPage,
    showTotal: (total) => `Total ${total} items`,
    onChange: onChangePage,
    itemRender: (current, type, originalElement) => {
      if (type === 'prev') {
        return <span className="pagination-item">Previous</span>;
      }
      if (type === 'next') {
        return <span className="pagination-item">Next</span>;
      }
      return originalElement;
    }
  };

  const columns = [
    {
      title: lang.label_id,
      dataIndex: 'id',
      key: 'uniqueId',
      fixed: 'left',
      render: (_, record) => {
        return (
          <Button
            style={{
              padding: '0px 0px'
            }}
            type="link"
            onClick={() => showModal(record?.id)}>
            {record?.id}
          </Button>
        );
      }
    },
    {
      title: lang.label_username,
      dataIndex: 'username',
      key: 'username',
      fixed: 'left',
      render: (_, record) => {
        return (
          <Link style={{ color: '#1677ff' }} to={`/player-management/player/${record?.player_id}/view`}>
            {record?.player?.user?.user_name}
          </Link>
        );
      }
    },
    {
      title: lang.label_referenceid,
      dataIndex: 'is_withdraw_transaction',
      key: 'reference_no',
      render: (_, record) => {
        return (
          <div>
            {record?.is_withdraw_transaction?.reference_no || '-'}
            {record?.is_withdraw_transaction?.reference_no ? (
              <Popover
                content={
                  <div style={{ cursor: 'default' }} onClick={hide}>
                    Copied Successfully!
                  </div>
                }
                // title="Copied Successfully!"
                trigger="click"
                open={popoverOpen === record.id}
                onOpenChange={(open) =>
                  handleOpenChange(open, record, record?.is_withdraw_transaction?.reference_no || '-')
                }>
                <Button
                  style={{
                    padding: '0px 1px'
                  }}
                  type="link">
                  <FaCopy color="gray" />
                </Button>
              </Popover>
            ) : null}
          </div>
        );
      }
    },
    {
      title: lang.label_transfer_no,
      dataIndex: 'is_deposit_transaction',
      key: 'transfer_no',
      render: (_, record) => {
        return record?.is_withdraw_transaction?.withdraw_transaction_no || '-';
      },
      width: 100
    },
    {
      title: lang.label_agentid,
      dataIndex: 'player',
      key: 'agentId',
      render: (_, record) => {
        return record?.player?.agent?.unique_code || '-';
      }
    },

    {
      title: lang.label_amount,
      dataIndex: 'amount',
      key: 'amount',
      render: (row, record) => toFixedFunc(record.amount, process.env.REACT_APP_Decimel_Points) || '-',
      width: 100
    },
    {
      title: lang.label_Currency,
      dataIndex: 'currency_name',
      key: 'currency_name'
    },
    {
      title: lang.label_company_payment_method,
      dataIndex: 'payment_method',
      key: 'companyPaymentMethod',
      render: (item) => <div>{item?.internal_name || '-'}</div>
    },
    {
      title: lang.label_customer_payment_method,
      dataIndex: 'user_payment_method',
      key: 'user_payment_method',
      render: (item, row) => {
        return (
          <div>
            <div>{row.user_payment_method ? row.user_payment_method?.bank_code?.public_name?.en_public_name : ''}</div>
            <div>{item?.account_name}</div>
            <div>{item?.account_number}</div>
          </div>
        );
      }
    },
    {
      title: lang.label_riskverify,
      key: 'risk_status',
      render: (item, row) => makeRiskBadge(row),
      dataIndex: 'risk_status',
      align: 'center'
    },
    {
      title: lang.label_status,
      dataIndex: 'status_name',
      key: 'status',
      render: (record, row) => makeStatusBadge(row),
      align: 'center'
    },
    {
      title: lang.label_createdtime,
      dataIndex: 'created_at',
      key: 'createdTime',
      render: (item) => {
        return convertUTCToLocalTime(item);
      }
    },
    {
      title: lang.label_approved_time,
      dataIndex: 'approved_time',
      key: 'approved_time',
      render: (item) => {
        return item ? convertUTCToLocalTime(item) : '-';
      },
      width: 100
    },
    {
      title: lang.label_financeverify,
      key: 'fa_status',
      render: (item, row) => makeFABadge(row),
      align: 'center'
    },
    {
      title: lang.label_actions,
      key: 'actions',
      dataIndex: 'actions',
      render: (item, row) => withdrawActions(row),
      fixed: 'right'
    }
  ];

  return (
    <div>
      <HeaderCardDetails />
      <div style={{ padding: '20px' }}>
        <TransactionFilters
          setData={(data) => {
            setCurrentPage(1);
            setFilterData(data);
          }}
          paymentMethods={paymentMethods}
          onRefresh={onRefresh}
          exportButton={
            <>
              {' '}
              {loadingData ? (
                <div style={{ paddingRight: '40px', display: 'flex', alignItems: 'center' }}>
                  <Spin indicator={<LoadingOutlined spin />} size="large" />
                  {/* <div style={{ marginLeft: '10px', fontWeight: '500', fontSize: '16px' }}>
                {downloadedFiles} of {totalFiles} Files Downloaded
              </div> */}
                </div>
              ) : (
                <div>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="btnStyle"
                    style={{
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1',
                      marginRight: '15px'
                    }}
                    onClick={() => exportData(filterData)}>
                    Export
                  </Button>
                </div>
              )}
            </>
          }
        />
        <br />
        <Table
          scroll={{
            x: 'max-content'
          }}
          columns={columns}
          loading={loading}
          dataSource={transactions}
          bordered
          pagination={false}
          rowKey={'id'}
        />
        {transactions?.length > 0 && <PaginationComp paginationConfig={paginationConfig} />}
        <Spacer />
        <Spacer />
        <WithdrawSumTable data={totalWithdrawal} loading={loading} />
        <RiskApproveModal
          riskModalOpen={riskModalOpen}
          onCloseClicked={() => setRiskModalOpen(false)}
          onActionClicked={() => {
            setRiskModalOpen(false);
            getTransactions();
          }}
          transaction={activeTransaction}
        />
        <FinanceApproveWithdrawModal
          modalOpen={faModalOpen}
          onCloseClicked={() => setFaModalOpen(false)}
          onActionClicked={() => {
            setFaModalOpen(false);
            getTransactions();
          }}
          transaction={activeTransaction}
          paymentMethods={paymentMethods}
        />
        <WithdrawPopup handleCancel={handleCancel} handleOk={handleOk} isModalOpen={isModalOpen} popupId={popupId} />
      </div>
    </div>
  );
};

export default Withdraw;
