import React, { useContext, useState, useRef, useEffect } from 'react';
import { Input, Button, Row, Col, Select, DatePicker, message, Upload } from 'antd';
import LangContext from '../../helps/contexts/lang-context';
import { useNavigate } from 'react-router-dom';
import PostApi from '../../helps/contexts/Api/PostApi';

const AddNewPromotionCategoryForm = ({ handleCancel, getPromotionCategories }) => {
  // form states
  const editorRef = useRef();

  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const nav = useNavigate();

  const [categoryName, setCategoryName] = useState('');
  const [status, setStatus] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formErrors, setFormErrors] = useState({
    categoryName: false,
    status: false
  });

  const validateForm = () => {
    const errors = {
      categoryName: !categoryName,
      status: status === null
    };

    setFormErrors(errors);

    return Object.values(errors).every((error) => !error);
  };

  const onChangeStatus = (value) => {
    setStatus(value);
    setFormErrors({ ...formErrors, status: false });
  };

  const onSubmit = async (e) => {
    //preventing multiple call api with same data
    if (isSubmitting) {
      return;
    }
    try {
      e.preventDefault();

      setIsSubmitting(true);

      if (!validateForm()) {
        // message.error(lang.label_formvalidationerror);
        return;
      }
      const path = `/promotion_categories/create`;
      // sending request
      const res = await PostApi.PostApiRequest(path, {
        en: categoryName,
        is_active: status
      });

      //if success
      if (res?.status === 200) {
        setCategoryName('');
        setStatus(1);
        message.success(lang.label_promotionaddsuccess);
        getPromotionCategories();
        handleCancel();
      }
    } catch (e) {
      if (e?.status === 422) {
        message.error(lang.label_promotionaddfail);
        Object.values(e.data.message).map((errors) => message.error(errors));
      }
    } finally {
      // Reset the submitting state after API call is complete
      setIsSubmitting(false);
    }
  };

  return (
    <div
      style={{
        width: '97%',
        height: '85%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: '3%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '-6%'
          }}>
          <Row>
            <Col span={22}>
              <label style={{ paddingBottom: '3px' }}>
                {lang.label_category}
                {lang.label_en}
              </label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <Input
                required
                style={{
                  width: '100%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                onChange={(e) => setCategoryName(e.target.value)}
                name="categoryName"
                value={categoryName}
              />
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <label>{lang.label_status}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />
              <Select
                required
                onChange={onChangeStatus}
                value={status}
                style={{
                  width: '184%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                options={[
                  {
                    key: 1,
                    value: 1,
                    label: 'Visible'
                  },
                  {
                    key: 0,
                    value: 0,
                    label: 'Hidden'
                  }
                ]}
              />
              {formErrors.status && <span style={{ color: 'red' }}>status is required</span>}
            </Col>
          </Row>
          <br />
          <br />
          <br />

          <div style={{}}>
            {' '}
            <Button
              style={{
                borderRadius: '3px',
                backgroundColor: '#004A7F',
                color: 'white',
                position: 'absolute',
                right: '25%',
                top: '80%'
              }}
              htmlType="submit">
              {lang.label_submit}
            </Button>
            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                position: 'absolute',
                right: '8%',
                top: '80%'
              }}
              onClick={() => {
                setCategoryName('');
                setStatus(1);
                handleCancel();
              }}>
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewPromotionCategoryForm;
