import React from 'react';
import { Input, Button, Select, Row, Col, Card } from 'antd';

const { Option } = Select;

const BasicInfo = ({ formData, handleInputChange, nextTab, type, confirm }) => {
  const settleCycleOptions = [
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' }
  ];

  const currencyOptions = [
    { label: 'USD', value: 'usd' },
    { label: 'EUR', value: 'eur' }
  ];

  return (
    <>
      <Card
        title="Basic Info"
        style={{
          borderRadius: '3px',
          marginTop: '30px',
          //   marginLeft: '10px',
          marginRight: '40px'
        }}>
        {type === 'agent' && (
          <div style={{ padding: '20px' }}>
            <Row gutter={16}>
              {/* First row */}
              <Col xs={24} sm={12} lg={6}>
                <div style={{ marginBottom: '16px' }}>
                  <label style={{ fontWeight: 500 }}>Agent Level</label>
                  <Select
                    placeholder="Select Agent Level"
                    style={{ width: '100%' }}
                    onChange={(value) => handleInputChange({ target: { name: 'agentLevel', value } })}
                    value={formData.agentLevel}>
                    <Option value="level1">Level 1</Option>
                    <Option value="level2">Level 2</Option>
                  </Select>
                </div>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <div style={{ marginBottom: '16px' }}>
                  <label style={{ fontWeight: 500 }}>Agent ID</label>
                  <Input
                    name="Id"
                    placeholder="Agent ID"
                    // value={formData.agentId || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <div style={{ marginBottom: '16px' }}>
                  <label style={{ fontWeight: 500 }}>Real Name</label>
                  <Input
                    name="realName"
                    placeholder="Real Name"
                    value={formData.realName || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col sm={12} lg={6}>
                <div style={{ marginBottom: '16px' }}>
                  <label style={{ fontWeight: 500 }}>Remarks</label>
                  <Input
                    name="remarks"
                    placeholder="Remarks"
                    // value={formData.remarks || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              {/* Second row */}
              <Col xs={24} sm={12} lg={6}>
                <div style={{ marginBottom: '16px' }}>
                  <label style={{ fontWeight: 500 }}>Password</label>
                  <Input
                    name="password"
                    placeholder="Password"
                    // value={formData.password || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <div style={{ marginBottom: '16px' }}>
                  <label style={{ fontWeight: 500 }}>Confirm Password</label>
                  <Input
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={formData.confirmPassword || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <div style={{ marginBottom: '16px' }}>
                  <label style={{ fontWeight: 500 }}>Mobile</label>
                  <Input
                    name="mobile"
                    placeholder="Mobile Number"
                    value={formData.mobile || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              {/* Third row */}
              <Col xs={24} sm={12} lg={6}>
                <div style={{ marginBottom: '16px' }}>
                  <label style={{ fontWeight: 500 }}>Upline Agent ID</label>
                  <Select
                    placeholder="Select Upline Agent ID"
                    style={{ width: '100%' }}
                    onChange={(value) => handleInputChange({ target: { name: 'uplineAgentId', value } })}
                    value={formData.uplineAgentId}>
                    <Option value="agent1">Agent 1</Option>
                    <Option value="agent2">Agent 2</Option>
                  </Select>
                </div>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <div style={{ marginBottom: '16px' }}>
                  <label style={{ fontWeight: 500 }}>Settle Cycle</label>
                  <Select
                    placeholder="Settle Cycle"
                    style={{ width: '100%' }}
                    onChange={(value) => handleInputChange({ target: { name: 'settleCycle', value } })}
                    value={formData.settleCycle}>
                    {settleCycleOptions.map((cycle) => (
                      <Option key={cycle.value} value={cycle.value}>
                        {cycle.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <div style={{ marginBottom: '16px' }}>
                  <label style={{ fontWeight: 500 }}>Currency</label>
                  <Select
                    placeholder="Currency"
                    style={{ width: '100%' }}
                    onChange={(value) => handleInputChange({ target: { name: 'currency', value } })}
                    value={formData.currency}>
                    {currencyOptions.map((currency) => (
                      <Option key={currency.value} value={currency.value}>
                        {currency.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
            </Row>
          </div>
        )}
        {type === 'player' && (
          <>
            {/* First Row */}
            <Row gutter={16}>
              <Col xs={24} sm={6}>
                <div style={{ marginBottom: 16 }}>
                  <label style={{ fontWeight: 500 }}>Agent Level</label>
                  <Select
                    placeholder="Select Agent Level"
                    value={formData.agentLevel}
                    onChange={(value) => handleInputChange({ target: { name: 'agentLevel', value } })}
                    style={{ width: '100%' }}>
                    <Option value="level1">Level 1</Option>
                    <Option value="level2">Level 2</Option>
                  </Select>
                </div>
              </Col>
              <Col xs={24} sm={6}>
                <div style={{ marginBottom: 16 }}>
                  <label style={{ fontWeight: 500 }}>Upline Agent ID</label>
                  <Select
                    placeholder="Select Upline Agent ID"
                    value={formData.uplineAgentId}
                    onChange={(value) => handleInputChange({ target: { name: 'uplineAgentId', value } })}
                    style={{ width: '100%' }}>
                    {/* Populate options as needed */}
                    <Option value="agent1">Agent 1</Option>
                    <Option value="agent2">Agent 2</Option>
                  </Select>
                </div>
              </Col>
              <Col xs={24} sm={6}>
                <div style={{ marginBottom: 16 }}>
                  <label style={{ fontWeight: 500 }}>Currency</label>
                  <Select
                    placeholder="Select Currency"
                    value={formData.currency}
                    onChange={(value) => handleInputChange({ target: { name: 'currency', value } })}
                    style={{ width: '100%' }}>
                    <Option value="usd">USD</Option>
                    <Option value="eur">EUR</Option>
                  </Select>
                </div>
              </Col>
            </Row>

            {/* Second Row */}
            <Row gutter={16}>
              <Col xs={24} sm={6}>
                <div style={{ marginBottom: 16 }}>
                  <label style={{ fontWeight: 500 }}>Player ID</label>
                  <Input name="playerId" value={formData.playerId} onChange={handleInputChange} />
                </div>
              </Col>
              <Col xs={24} sm={6}>
                <div style={{ marginBottom: 16 }}>
                  <label style={{ fontWeight: 500 }}>Real Name</label>
                  <Input name="realName" onChange={handleInputChange} />
                </div>
              </Col>
              <Col xs={24} sm={6}>
                <div style={{ marginBottom: 16 }}>
                  <label style={{ fontWeight: 500 }}>Password</label>
                  <Input name="password" onChange={handleInputChange} />
                </div>
              </Col>
              <Col xs={24} sm={6}>
                <div style={{ marginBottom: 16 }}>
                  <label style={{ fontWeight: 500 }}>Confirm Password</label>
                  <Input name="confirmPassword" value={formData.confirmPassword} onChange={handleInputChange} />
                </div>
              </Col>
            </Row>

            {/* Third Row */}
            <Row gutter={16}>
              <Col xs={24} sm={6}>
                <div style={{ marginBottom: 16 }}>
                  <label style={{ fontWeight: 500 }}>Mobile</label>
                  <Input name="mobile" value={formData.mobile} onChange={handleInputChange} />
                </div>
              </Col>
              <Col xs={24} sm={6}>
                <div style={{ marginBottom: 16 }}>
                  <label style={{ fontWeight: 500 }}>Remark</label>
                  <Input name="remark" value={formData.remark} onChange={handleInputChange} />
                </div>
              </Col>
            </Row>
          </>
        )}
      </Card>
      {type === 'agent' && (
        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => console.log('Cancel')}
            style={{ marginRight: '10px', width: '70px', height: '35px', borderRadius: '3px' }}>
            Cancel
          </Button>
          <Button
            type="primary"
            style={{
              backgroundColor: '#004A7F',
              color: 'white',
              width: '70px',
              height: '35px',
              background: '#004A7F 0% 0% no-repeat padding-box',
              borderRadius: '3px',
              opacity: '1',
              marginRight: '15px'
            }}
            onClick={nextTab}>
            Next
          </Button>
        </div>
      )}
      {type === 'player' && (
        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
          <Button type="default" style={{ marginRight: '10px', width: '70px', height: '35px', borderRadius: '3px' }}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={confirm}
            style={{
              backgroundColor: '#004A7F',
              color: 'white',
              width: '80px',
              height: '35px',
              background: '#004A7F 0% 0% no-repeat padding-box',
              borderRadius: '3px',
              opacity: '1',
              marginRight: '15px'
            }}>
            Confirm
          </Button>
        </div>
      )}
    </>
  );
};

export default BasicInfo;
