import axios from "axios";
import { constants } from "./constants";
import { Navigate } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

const sendApiRequest = async (path, Data, params = {}) => {
  const url = `${constants.BASE_URL1}` + `${path}`;
  const token1 = localStorage.getItem("token");

  return await axios
    .get(url, {
      data: Data,
      params: params,
      headers: {
        Authorization: `Bearer ${token1}`,
        Accept: 'application/json'
      },
    })
    .then((response) => {
      if (response?.status === 401) {
        
        localStorage.clear();
        return <Navigate to="/" replace />;
      } else if (response?.status === 200) {
        return response.data;
      } else return null;
    })
    .catch((e) => {
      if (e.response.request.status === 401) {
        localStorage.clear();
        return <Navigate to="/" replace />;
      }

      return Promise.reject(e.response);
    });
};

export default {
  sendApiRequest,
};
