import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthLayout from '../components/AuthLayout';
import { privateRoutes } from './PrivateRoutes';
import MainTabs from './MainTabs';
import NotificationProvider from '../helps/contexts/NotificationProvider';


const PrivateRoute = () => {
  const auth = localStorage.getItem('token');
  return auth ? (

    <AuthLayout>
      <MainTabs privateRoutes={privateRoutes} />
    </AuthLayout>
  ) : (
    <Navigate to="/login" />
  );
};
 
export default PrivateRoute;