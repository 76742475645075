import { Input, Button, Row, Col, Switch, Upload, Select, message } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState, useEffect } from 'react';
import PostApi from '../../../helps/contexts/Api/PostApi';
import GetApi from '../../../helps/contexts/Api/GetApi';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import '../../../styles/ButtonStyle.css';
import ErrorsHandlingComp from '../../../helps/errorsHandlingComp';
import Loader from '../../../components/HelperComponents/Loader';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';

const UpdateCompanyBanks = ({ handleCancel, rowData, fetchCompanyBanks }) => {
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();

  const lang = ctx.lang;
  // form states
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [bankvalue, setbankvalue] = useState('');
  const [bankImg, setBankImg] = useState('');
  const [bankimgname, setbankimgname] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [callbackKey, setCallbackKey] = useState('');
  const [remark, setRemark] = useState('');
  const [deposit, setDeposit] = useState(false);
  const [bankid, setBankid] = useState('');
  const [withdrawl, setWithdrawl] = useState(false);
  const [bankName, setBankName] = useState('Please select a bank');
  const [bankList, setBankList] = useState([]);
  const [currency, setcurrency] = useState('Please Select currency');
  const [allCurrency, setAllCurrency] = useState([]);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const { Option } = Select;
  const { TextArea } = Input;

  const handleCurrency = (value) => {
    setcurrency(value);
    delete errors.currency;
  };

  //switch deposit
  const onChangeSwitch1 = (checked) => {
    if (checked === true) {
      setDeposit(1);
    } else if (checked === false) {
      setDeposit(0);
    }
  };
  //switch withdrawl
  const onChangeSwitch2 = (checked) => {
    setWithdrawl(checked);

    if (checked === true) {
      setWithdrawl(1);
    } else if (checked === false) {
      setWithdrawl(0);
    }
  };
  const dummyRequest = (file, onSuccess) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  //file upload

  const props = {
    name: 'file',
    onChange(e) {
      setBankImg(e.file);
      setbankimgname(e.file.name);
      if (e.file.type === 'image/jpg' || e.file.type === 'image/jpeg' || e.file.type === 'image/png') {
      }
      const isLt2M = e.file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        message.error('File size must smaller than 20MB!!');
        return;
      }
    }
  };

  const getBankList = async () => {
    const res = await GetApi.sendApiRequest('/user/bank/list');
    if (res) {
      const object1 = {
        value: 'Please select a bank',
        label: 'Please select a bank'
      };
      const objArray1 = Object.keys(res);
      const finalArray = objArray1.map((v) => ({ value: v, label: res[v] }));
      finalArray.unshift(object1);

      setBankList(finalArray);
    }
  };
  const getCurrencyList = async () => {
    try {
      const res = await GetApi.sendApiRequest('/player/currencies');
      if (res) {
        const object1 = {
          key: 'Please Select currency',
          value: 'Please Select currency',
          label: 'Please Select currency'
        };
        const finalArray = Object.keys(res).map((key, index) => {
          return {
            key: Number(key),
            value: Number(key),
            label: res[key]
          };
        });
        finalArray.unshift(object1);
        setAllCurrency(finalArray);
      } else {
        return null;
      }
    } catch (e) {}
  };
  //form value role select
  function handleChange(value) {
    setBankName(value);
    delete errors.bank_code;
  }
  //

  //form submit to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (
        !Object.keys(errors).length &&
        bankName !== 'Please select a bank' &&
        currency !== 'Please Select currency' &&
        bankName !== null &&
        bankName !== undefined &&
        bankName !== '' &&
        currency !== null &&
        currency !== undefined &&
        currency !== ''
      ) {
        setLoader(true);
        const path = `/company/banks/${bankid}/update`;
        // sending request
        const res = await PostApi.PostApiRequest1(path, {
          bank_code: bankName,
          bank_holder_name: accountName,
          bank_account_number: accountNumber,
          currency: currency,
          bank_image: bankImg,
          api_key: apiKey,
          callback_key: callbackKey,
          allow_deposit: deposit,
          allow_withdraw: withdrawl,
          remark: remark
        });
        //if success
        if (res?.status === 200) {
          message.success(lang.label_companybankupdatesuccess);
          handleCancel();
          fetchCompanyBanks();
          setLoader(false);
        }
      } else {
        if (bankName === 'Please select a bank' || bankName == null || bankName === undefined || bankName === '') {
          setErrors({ ...errors, bank_code: 'Bank is required' });
        } else if (
          currency === 'Please Select currency' ||
          currency == null ||
          currency === undefined ||
          currency === ''
        ) {
          setErrors({ ...errors, currency: 'Currency is required' });
        } else {
        }
        setLoader(false);
      }
    } catch (e) {
      if (e.response?.status === 422) {
        Object.values(e.response.data.message).map((errors) => message.error(errors));
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    getBankList();
    getCurrencyList();
    setBankid(rowData ? rowData.key : '');
    setcurrency(rowData ? rowData.currency : 'Please Select currency');
    setAccountName(rowData ? rowData.bankaccountname : '');
    setAccountNumber(rowData ? rowData.bankaccountnumber : '');
    setBankImg(rowData ? rowData.bankimage : '');
    setbankimgname(rowData.bankimage ? rowData.bankimage : '');
    setBankName(rowData ? rowData.bankId : 'Please select a bank');
    setApiKey(rowData.apikey ? rowData.apikey : '');
    setCallbackKey(rowData.callbackkey ? rowData.callbackkey : '');
    setDeposit(rowData ? rowData.deposit : '');
    setWithdrawl(rowData ? rowData.withdraw : '');
    setRemark(rowData ? rowData.remark : '');
  }, []);
  const handleDelete = () => {
    setBankImg('');
    setbankimgname('');
  };

  // useEffect(() => {

  //   var selectedBankname = bankList.find(function (bank) {
  //     return bank.label === rowData.bankname;
  //   });
  //   setbankvalue(selectedBankname?.value);

  // });

  return (
    <div
      style={{
        width: '97%',
        height: '85%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: '3%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '-6%'
          }}>
          <Row>
            <Col span={12}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_bankname}</label>
              <span style={{ color: 'red' }}>*</span>

              <br />
              <Select
                value={bankName}
                required
                // defaultValue={rowData.bankname}
                style={{
                  width: '93%',
                  marginTop: '2px',
                  right: '0.5%',
                  color: 'black'
                }}
                onChange={handleChange}>
                {bankList.map((bank, index) => (
                  <Option key={index} value={Number(bank.value)}>
                    {bank.label}
                  </Option>
                ))}
              </Select>
              <ErrorsHandlingComp errors={errors} name="bank_code" />
            </Col>
            <Col span={12}>
              <label>
                {lang.label_accountname}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <br />

              <Input
                required
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <label style={{ paddingBottom: '3px' }}>
                {lang.label_accountnumber}
                <span style={{ color: 'red' }}>*</span>
              </label>

              <br />

              <Input
                required
                style={{
                  width: '93%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col style={{ width: '605px' }}>
              <label>{lang.label_bankimage}</label>
              <br />{' '}
              <div className="customUploader">
                {!bankImg ? (
                  <Input disabled className="uploaderInput" placeholder="upload image" />
                ) : (
                  <Input
                    defaultValue={''}
                    value={bankimgname}
                    className="uploaderInput"
                    addonAfter={<DeleteOutlined onClick={handleDelete} />}
                  />
                )}
                <Upload {...props} beforeUpload={() => false} customRequest={() => dummyRequest} maxCount={1}>
                  <Button
                    className="custumBtn"
                    icon={<UploadOutlined style={{ color: 'white' }} />}
                    style={{
                      backgroundColor: '#004A7F',
                      color: 'white',
                      borderColor: '#004A7F',
                      width: '100% !important'
                    }}>
                    {lang.label_upload}
                  </Button>
                </Upload>
              </div>
            </Col>
          </Row>
          <br />

          <Row>
            <Col span={24}>
              <label>{lang.label_currency}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />
              <Select
                defaultValue={rowData.currency}
                value={currency}
                onChange={handleCurrency}
                style={{
                  width: '93%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                options={allCurrency}
              />
              <ErrorsHandlingComp errors={errors} name="currency" />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_apikey}</label>

              <br />

              <Input
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
              />
            </Col>
            <Col span={12}>
              <label>{lang.label_callbackkey}</label>
              <br />

              <Input
                value={callbackKey}
                onChange={(e) => setCallbackKey(e.target.value)}
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
              />
            </Col>
          </Row>

          {hasPermission('Company Bank Deposit Toggle') && (
            <Row>
              <Col span={24}>
                <label>{lang.label_deposit}</label>

                <Switch style={{ marginLeft: '20px' }} defaultChecked onChange={onChangeSwitch1} checked={deposit} />
              </Col>
            </Row>
          )}
          <br />
          {hasPermission('Company Bank Withdraw Toggle') && (
            <Row>
              <Col span={24}>
                <label>{lang.label_withdraw}</label>

                <Switch style={{ marginLeft: '10px' }} defaultChecked onChange={onChangeSwitch2} checked={withdrawl} />
              </Col>
            </Row>
          )}

          <br />
          <Row>
            <Col span={12}>
              <label>{lang.label_remark}</label>

              <br />

              <>
                <TextArea
                  showCount
                  maxLength={100}
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                  style={{
                    width: '186%',
                    height: '100px',
                    borderRadius: '3px',
                    marginBottom: '5px',
                    marginTop: '5px'
                  }}
                  value={remark}
                />
              </>
            </Col>
          </Row>
          <br />
          <br />
          <br />

          <div>
            {loader ? (
              <Loader />
            ) : (
              <Button
                style={{
                  // borderRadius: "3px",
                  // backgroundColor: "#405088",
                  // color: "white",
                  position: 'absolute',
                  right: '30%',
                  top: '93%'
                }}
                htmlType="submit"
                className="btnStyle">
                {lang.label_submit}
              </Button>
            )}

            <Button
              style={{
                // borderRadius: "3px",
                // color: "#405088",
                position: 'absolute',
                right: '8%',
                top: '93%'
              }}
              onClick={() => {
                handleCancel();
              }}
              className="btnReset">
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateCompanyBanks;
