import { Button, Input, Select, message, Row, Col, Spin } from "antd";
import React, { useEffect, useState, useContext } from "react";
import PostApi from "../../../../../helps/contexts/Api/PostApi";
import GetApi from "../../../../../helps/contexts/Api/GetApi";
import LangContext from "../../../../../helps/contexts/lang-context";
import PutApi from "../../../../../helps/contexts/Api/PutApi";

const AddUserBankForm = ({ handleCancel, id, fetchUserBankData, formData }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;


  const [accountNumber, setAccountNumber] = useState(formData?.account_number || '');
  const [accountName, setAccountName] = useState(formData?.account_name || '');
  const [bankCity, setBankCity] = useState(formData?.bank_city || '');
  const [bankBranch, setBankBranch] = useState(formData?.bank_branch || '');
  const [remark, setRemark] = useState(formData?.remark || '');

  const [loading, setLoading] = useState(false);
  const [bankCodes, setBankCodes] = useState([]);
  const [paymentCategories, setPaymentCategories] = useState([]);
  const [selectedBankCode, setSelectedBankCode] = useState(formData?.bank_code_id || null);
  const [selectedPaymentCategory, setSelectedPaymentCategory] = useState(formData?.payment_category_id || null);

  const getUserPaymentCategories = async () => {
    const path = '/user/payment/categories';
    const res = await GetApi.sendApiRequest(path);

    if (res && res.length) {
      const finalArray = res.map((item, index) => {
        return {
          value: item.id,
          label: item.name,
        }
      });
      setPaymentCategories(finalArray);
    } else {
      setPaymentCategories([]);
    }
  };

  const getUserBankCodes = async () => {
    const path = '/user/bank/codes';
    const res = await GetApi.sendApiRequest(path);

    console.log(res)

    // const curr = Object.keys(res);
    if (res && res.length) {
      const finalArray = res.map((item, index) => {
        return {
          value: parseInt(item.id),
          label: item.public_name.en_public_name,
        }
      });
      setBankCodes(finalArray);
    } else {
      setBankCodes([]);
    }
  };

  useEffect(() => {
    getUserPaymentCategories()
    getUserBankCodes()
  }, []);

  const addUserPaymentCategory = async (e) => {
    setLoading(true)
    try {
      const path = `/user/payment/method/store`;
      const res = await PostApi.PostApiRequest(path, {
        bank_code_id: selectedBankCode,
        payment_category_id: selectedPaymentCategory,
        account_number: accountNumber,
        player_id: id,
        account_name: accountName,
        bank_city: bankCity,
        bank_branch: bankBranch,
        remark: remark,
      });

      if (res && res.status === 200) {
        fetchUserBankData()
        message.success(lang.label_bankaddedsuccess);
      }
      handleCancel();
    } catch (e) {
      message.error(lang.label_bankaddfailed);
    }
    setLoading(false)
  };

  const editUserPaymentCategory = async (e) => {
    setLoading(true)
    try {
      const path = `/user/payment/method/update/${formData?.id}`;
      const res = await PutApi.PutApiRequest(path, {
        bank_code_id: selectedBankCode,
        payment_category_id: selectedPaymentCategory,
        account_number: accountNumber,
        player_id: id,
        account_name: accountName,
        bank_city: bankCity,
        bank_branch: bankBranch,
        remark: remark,
      });

      if (res && res.status) {
        fetchUserBankData()
        message.success(lang.label_bankaddedsuccess);
      }
      handleCancel();
    } catch (e) {
      message.error(lang.label_bankaddfailed);
    }
    setLoading(false)
  };

  return (
    <div style={{ borderRadius: "20px" }}>
      <p
        style={{
          color: "#A5A5A5",
          fontSize: "14px",
          marginTop: "-1%",
          marginBottom: "3%",
          fontFamily: "Roboto",
          fontWeight: "300"
        }}
      >
        {lang.label_fillinfo}
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (typeof (formData) == 'object' && Object.keys(formData).length === 0) {
            addUserPaymentCategory();
          } else {
            editUserPaymentCategory()
          }
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "2px"
          }}
        >
          <Row>
            <Col span={12}>
              <span style={{ width: "100%" }}>
                <p>{lang.label_bank_code}<span style={{ color: "red", marginLeft: "0.2rem" }}>*</span></p>
                <Select
                  value={selectedBankCode}
                  options={bankCodes}
                  style={{
                    width: "95%", borderRadius: "2.5px", marginRight: "10"
                  }}
                  onChange={(value) => {
                    setSelectedBankCode(value);
                  }}
                />
              </span>
            </Col>
            <Col span={12}>
              <span style={{ width: "100%" }}>
                <p>{lang.label_payment_category}<span style={{ color: "red", marginLeft: "0.2rem" }}>*</span></p>
                <Select
                  value={selectedPaymentCategory}
                  options={paymentCategories}
                  style={{ width: "100%", borderRadius: "2.5px", marginLeft: "10" }}
                  onChange={(value) => {
                    setSelectedPaymentCategory(value);
                  }}
                />
              </span>
            </Col>
          </Row>
        </div>
        <Row>
          <Col span={24}>
            <p>{lang.label_accountnumber}<span style={{ color: "red", marginLeft: "0.2rem" }}>*</span></p>
            <Input
              required
              style={{
                borderRadius: "2.5px",
              }}
              value={accountNumber}
              onChange={(e) => {
                setAccountNumber(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p>{lang.label_accountname}<span style={{ color: "red", marginLeft: "0.2rem" }}>*</span></p>
            <Input
              required
              style={{
                borderRadius: "2.5px",
              }}
              value={accountName}
              onChange={(e) => {
                setAccountName(e.target.value);
              }}
            />
          </Col>
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "2px"
          }}
        >
          <Row>
            <Col span={12}>
              <p>{lang.label_bank_city}</p>
              <Input
                style={{
                  borderRadius: "2.5px",
                  width: "95%",
                  marginRight: "10"
                }}
                value={bankCity}
                onChange={(e) => {
                  setBankCity(e.target.value);
                }}
              />
            </Col>
            <Col span={12}>
              <p>{lang.label_bank_branch}</p>
              <Input
                style={{
                  borderRadius: "2.5px",
                }}
                value={bankBranch}
                onChange={(e) => {
                  setBankBranch(e.target.value);
                }}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col span={24}>
            <p>{lang.label_remark}</p>
            <Input
              rows={4}
              style={{
                borderRadius: "2.5px",
                marginBottom: "2rem",
              }}
              value={remark}
              onChange={(e) => {
                setRemark(e.target.value);
              }}
            />
          </Col>
        </Row>
        <span
          style={{
            position: "absolute",
            right: "2.9%",
            bottom: "0",
            marginBottom: "0.5rem",
          }}
        >
          {(() => {
            if (loading) {
              return <Spin style={{ marginRight: "40px" }} />
            } else {
              return <Button
                htmlType="submit"
                style={{
                  backgroundColor: "#004A7F",
                  color: "white",
                  marginRight: "0.5rem",
                  borderRadius: "2.5px",
                  width: "45%"
                }}
              >
                {lang.label_submit}
              </Button>
            }
          })()}
          <Button
            style={{
              marginRight: "0.5rem",
              borderRadius: "2.5px",
            }}
            onClick={() => {
              handleCancel();
            }}
          >
            {lang.label_cancel}
          </Button>
        </span>
      </form>
    </div>
  );
};

export default AddUserBankForm;
