import React, { useContext, useState, useEffect } from 'react';
import styles from './AgentCommissionReport.module.css';
import { Table, Modal, Form, Button, Tag, Space, message, Input, Checkbox, Select } from 'antd';
import { EditOutlined, CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Edit from '../../../../images/Edit.png';
import EditCommission from './EditCommission';
import ApproveRejectActionModal from './ApproveRejectActionModal';
import ViewEditCommisionHistory from './ViewEditCommisionHistory';
import { IoMenu } from 'react-icons/io5';
import { toFixedFunc } from '../../../../helps/HelperFunctions';

const AgentCommisionReoprtTable = () => {
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState({});
  const [isEdit, setIsedit] = useState(false);
  const [agentID, setAgentID] = useState('');
  const [viewEditHistory, setViewEditHistory] = useState(false);
  const [viewApprove, setViewApprove] = useState(false);
  const [viewReject, setViewReject] = useState(false);
  const [editCommissionVisible, setEditCommissionVisible] = useState(false);

  const handleApprove = () => {
    alert('hii there');
  };
  const handleReject = () => {
    alert('hii there');
  };

  // Function to handle opening the modal
  const showEditCommissionModal = (row) => {
    setVisible(true);
    setRowData(row);
    setAgentID(row.agentId);
    setEditCommissionVisible(true);
  };

  // Function to handle opening the modal
  const EditHistoryModal = (row) => {
    setVisible(true);
    setRowData(row);
    setAgentID(row.agentId);
    setViewEditHistory(true);
  };

  // Function to handle opening the modal
  const showApproveModal = (row) => {
    setVisible(true);
    setRowData(row);
    setAgentID(row.agentId);
    setViewApprove(true);
  };

  // Function to handle opening the modal
  const showRejectModal = (row) => {
    setVisible(true);
    setRowData(row);
    setAgentID(row.agentId);
    setViewReject(true);
  };

  //for closing the popup
  const handleCancel = () => {
    setEditCommissionVisible(false);
    setViewEditHistory(false);
    setViewApprove(false);
    setViewReject(false);
    setVisible(false);
  };

  const columns = [
    {
      title: 'Agent ID',
      dataIndex: 'agentId',
      key: 'agentId'
      //   sorter: true
    },
    {
      title: 'Agent Level',
      dataIndex: 'agentLevel',
      key: 'agentLevel'
    },
    {
      title: 'Settlement Period',
      dataIndex: 'settlementPeriod',
      key: 'settlementPeriod'
    },
    {
      title: 'Gross Commission',
      dataIndex: 'grossCommission',
      key: 'grossCommission',
      render: (text, record) => (
        <a style={{ color: record.grossCommission.startsWith('-') ? 'red' : '#2A93F0' }}>
          {/* {toFixedFunc(record, process.env.REACT_APP_Decimel_Points)} */}
          {text}
        </a>
      )
    },
    {
      title: 'Actual Commission',
      dataIndex: 'actualCommission',
      key: 'actualCommission',
      render: (text, row) => {
        const color = row.actualCommission.startsWith('-') ? 'red' : '#2A93F0';

        return (
          <Space>
            <a
              style={{
                color: color,
                borderBottom: `2px solid ${color}`,
                paddingBottom: '1px'
              }}
              onClick={() => EditHistoryModal(row ?? row)}>
              {/* {toFixedFunc(record, process.env.REACT_APP_Decimel_Points)} */}
              {text}
            </a>
            {row.status === 'Pending' && (
              <Button
                type="link"
                style={{ border: 'none', padding: '0px' }}
                icon={
                  <img
                    style={{
                      height: '13px',
                      width: '13px',
                      objectFit: 'contain'
                    }}
                    src={Edit}
                    alt="Edit Icon"
                  />
                }
                onClick={() => showEditCommissionModal(row ?? row)}
              />
            )}
          </Space>
        );
      }
    },
    // {
    //     title: lang.label_status,
    //     dataIndex: 'status',
    //     key: 'status',
    //     render: (_, record) =>
    //       record.status === 1 ? (
    //         <Tag color="success" icon={<CheckCircleOutlined />}>
    //           Success
    //         </Tag>
    //       ) : record.status === 2 ? (
    //         <Tag color="error">Rejected</Tag>
    //       ) : record.status === 3 ? (
    //         <Tag color="processing" icon={<SyncOutlined spin />} bordered={false}>
    //           Pending
    //         </Tag>
    //       ) : record.status === 4 ? (
    //         <Tag color="red">Processing</Tag>
    //       ) : (
    //         ''
    //       )
    //   },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let color = status === 'Approved' ? 'green' : status === 'Rejected' ? 'red' : 'orange';
        return (
          <Tag color={color} bordered={false}>
            {status}
          </Tag>
        );
      }
    },
    {
      title: 'Review By',
      dataIndex: 'reviewBy',
      key: 'reviewBy'
    },
    {
      title: 'Review At',
      dataIndex: 'reviewAt',
      key: 'reviewAt'
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        if (record.status !== 'Pending') {
          return null;
        }

        return (
          <div style={{
            display : 'flex',
            flexDirection : 'row',
            alignItems : 'flex-start',
            justifyContent : 'center',
            flexWrap :'wrap',
            gap : "5px"
          }}>
            <Button
              type="primary"
              icon={<CheckOutlined style={{ color: 'rgba(255, 255, 255, 1)' }} />}
              style={{ borderRadius: '3px', backgroundColor: '#004A7F', color: 'white', width : '100px'}}
              onClick={() => showApproveModal(record)}>
              Approve
            </Button>
            <Button
              style={{
                borderRadius: '5px',
                color: '#004A7F',
                 width : '100px'
              }}
              icon={<CloseCircleOutlined style={{ color: 'rgba(255, 78, 63, 1)'}} />}
              onClick={() => showRejectModal(record)}>
              Reject
            </Button>
          </div>
        );
      }
    }
  ];

  const data = [
    {
      key: '1',
      agentId: 'A',
      agentLevel: 'CM',
      settlementPeriod: '2024-07-01\n2024-07-31',
      grossCommission: '72,500.00',
      actualCommission: '72,500.00',
      status: 'Approved',
      reviewBy: 'BOBO123',
      reviewAt: '2024-08-01 11:11:11',
      remark: 'no abnormality in the agent line'
    },
    {
      key: '2',
      agentId: 'C',
      agentLevel: 'EA',
      settlementPeriod: '2024-07-01\n2024-07-31',
      grossCommission: '25,000.00',
      actualCommission: '23,000.00',
      status: 'Approved',
      reviewBy: 'BOBO123',
      reviewAt: '2024-08-01 11:11:11',
      remark: 'manually adjusted wallet balance\n2024-07-20 -2000'
    },
    {
      key: '3',
      agentId: 'D',
      agentLevel: 'EA',
      settlementPeriod: '2024-07-01\n2024-07-31',
      grossCommission: '30,000.00',
      actualCommission: '30,000.00',
      status: 'Pending',
      reviewBy: 'BOBO123',
      reviewAt: '2024-08-01 11:11:11',
      remark: ''
    },
    {
      key: '4',
      agentId: 'G',
      agentLevel: 'MA',
      settlementPeriod: '2024-07-01\n2024-07-31',
      grossCommission: '16,000.00',
      actualCommission: '16,000.00',
      status: 'Pending',
      reviewBy: 'BOBO123',
      reviewAt: '2024-08-01 11:11:11',
      remark: 'The agent is engaged in hedging and arbitrage activities.'
    },
    {
      key: '5',
      agentId: 'L',
      agentLevel: 'SA',
      settlementPeriod: '2024-07-01\n2024-07-31',
      grossCommission: '9,500.00',
      actualCommission: '9,500.00',
      status: 'Approved',
      reviewBy: 'BOBO123',
      reviewAt: '2024-08-01 11:11:11',
      remark: 'no abnormality in the agent line'
    },
    {
      key: '6',
      agentId: 'O',
      agentLevel: 'JA',
      settlementPeriod: '2024-07-01\n2024-07-31',
      grossCommission: '3,250.00',
      actualCommission: '2,000.00',
      status: 'Approved',
      reviewBy: 'BOBO123',
      reviewAt: '2024-08-01 11:11:11',
      remark: 'no abnormality in the agent line'
    },
    {
      key: '7',
      agentId: 'M',
      agentLevel: 'AG',
      settlementPeriod: '2024-07-01\n2024-07-31',
      grossCommission: '1,250.00',
      actualCommission: '1,000.00',
      status: 'Approved',
      reviewBy: 'BOBO123',
      reviewAt: '2024-08-01 11:11:11',
      remark: 'no abnormality in the agent line'
    },
    {
      key: '8',
      agentId: 'trade',
      agentLevel: 'AG',
      settlementPeriod: '2024-07-01\n2024-07-31',
      grossCommission: '-1,000.00',
      actualCommission: '-1,000.00',
      status: 'Pending',
      reviewBy: 'BOBO123',
      reviewAt: '2024-08-01 11:11:11',
      remark: 'no abnormality in the agent line'
    }
  ];

  return (
    <div className={styles.commisionReportTable}>
      <Table
        style={{ width: '100%', height: '100%', overflowX: 'auto', overflowY: 'auto' }}
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        onRow={(record) => ({
          onClick: () => {
            setRowData(record);
          }
        })}
      />

      <div>
        {/* <EditCommission
          rowData = {rowData}
          id={agentID}
          setEditCommissionVisible={setEditCommissionVisible}
          editCommissionVisible={editCommissionVisible}
        /> */}

        <div>
          {(editCommissionVisible || viewReject || viewApprove || viewEditHistory) && (
            <Modal
              centered
              closeIcon={null}
              title={
                editCommissionVisible
                  ? `Adjust Actual Commission (Agent ID : ${agentID})`
                  : viewReject
                  ? 'Are you sure you want to reject this commission review?'
                  : viewApprove
                  ? 'Are you sure you want to approve this commission review?'
                  : `Actual Commission Edit History (Agent ID : ${agentID})`
              }
              open={visible}
              onCancel={handleCancel}
              footer={null}
              width={700}
              color={'#004A7F'}>
              {editCommissionVisible ? (
                <EditCommission
                  rowData={rowData}
                  id={agentID}
                  setEditCommissionVisible={setEditCommissionVisible}
                  editCommissionVisible={editCommissionVisible}
                  onCancel={handleCancel}
                />
              ) : viewReject ? (
                <ApproveRejectActionModal
                  rowData={rowData}
                  id={agentID}
                  viewReject={viewReject}
                  onCancel={handleCancel}
                />
              ) : viewApprove ? (
                <ApproveRejectActionModal
                  rowData={rowData}
                  id={agentID}
                  viewApprove={viewApprove}
                  onCancel={handleCancel}
                />
              ) : viewEditHistory ? (
                <ViewEditCommisionHistory rowData={rowData} onCancel={handleCancel} />
              ) : (
                ''
              )}
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgentCommisionReoprtTable;
