import { Input } from 'antd';
import React from 'react'
const { TextArea } = Input;


const TextAreaWithLabel = ({label, value, onChange, isRequired, rows=4 }) => {
  return (
    <div>
        <label>
            {label}
            {isRequired ? <span style={{ color: "red" }}>*</span> : ""}
        </label>
        <TextArea 
            rows={rows}
            required={isRequired}
            value={value}
            onChange={(e) => onChange(e.currentTarget.value) }
        />
    </div>
  )
}

export default TextAreaWithLabel


