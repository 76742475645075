import { Button, Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import InputWithLabel from '../../../components/HelperComponents/InputWithLabel';
import UploadWithLabel from '../../../components/HelperComponents/UploadWithLabel';
import LangContext from '../../../helps/contexts/lang-context';
import SwitchWithLabel from '../../../components/HelperComponents/SwitchWithLabel';
import SelectWithLabel from '../../../components/HelperComponents/SelectWithLabel';
import Loader from '../../../components/HelperComponents/Loader';

const AddBankCodes = ({
  formLoading,
  formData,
  setFormData,
  paymentCategories,
  bankCodes,
  setShowEditPopup,
  setEditMode,
  handleFormSubmit
}) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const setImageData = (val, key) => {
    setFormData((prevData) => {
      if (!prevData) {
        return { icon: { [key]: val } };
      }
      return { ...prevData, icon: { ...prevData.icon, [key]: val } };
    });
  };

  const setTextData = (val, key) => {
    setFormData((prevData) => {
      if (!prevData) {
        return { public_name: { [key]: val } };
      }
      return { ...prevData, public_name: { ...prevData.public_name, [key]: val } };
    });
  };

  return (
    <form>
      <Row gutter={[20, 20]}>
        <Col span={8}>
          <InputWithLabel
            label={`${lang.label_name_public} (en)`}
            value={formData.public_name?.en_public_name}
            isRequired={true}
            onChange={(val) => setTextData(val, 'en_public_name')}
          />
          <SelectWithLabel
            label={lang.label_bank_code}
            options={bankCodes}
            onChange={(val) => setFormData({ ...formData, code: val })}
            value={formData.code}
            isRequired={true}
          />
          {/* <SwitchWithLabel label={lang.label_status} onChange={val => setFormData({ ...formData, status: val ? 1 : 0 })} value={formData.status} /> */}
        </Col>
        <Col span={8}>
          <InputWithLabel
            label={`${lang.label_name_public} (vn)`}
            value={formData.public_name?.vn_public_name}
            isRequired={false}
            onChange={(val) => setTextData(val, 'vn_public_name')}
          />
          <SelectWithLabel
            label={lang.label_payment_category}
            options={paymentCategories}
            onChange={(val) => setFormData({ ...formData, payment_category_id: val })}
            value={formData.payment_category_id}
            isRequired={true}
          />
          {/* <SwitchWithLabel label={lang.label_display_for_players} onChange={val => setFormData({ ...formData, display_for_players: val ? 1 : 0 })} value={formData.display_for_players} /> */}
        </Col>
        <Col span={8}>
          <UploadWithLabel
            name="bank_codes_icons"
            label={lang.label_icon}
            initialImageUrl={formData && formData.full_image ? formData.full_image : null}
            onChange={(val) => setFormData({ ...formData, image: val })}
          />
        </Col>
      </Row>
      <div
        style={{
          marginTop: '2%',
          display: 'flex',
          justifyContent: 'flex-end',
          // gap: '50px',
        //   width: '92%'
        }}>
        {!formLoading ? (
          <Button
            style={{
              backgroundColor: '#004A7F',
              color: 'white',
              width: '80px',
              height: '35px',
              background: '#004A7F 0% 0% no-repeat padding-box',
              borderRadius: '3px',
              opacity: '1',
              margin: '0px 10px'
            }}
            onClick={handleFormSubmit}>
            {lang.label_submit}
          </Button>
        ) : (
          <Loader size={'small'} />
        )}
        <Button
          style={{
            borderRadius: '3px',
            color: '#004A7F',
            width: '80px',
            height: '35px'
          }}
          onClick={() => {
            setShowEditPopup(false);
            setEditMode(false);
          }}>
          {lang.label_cancel}
        </Button>
      </div>
    </form>
  );
};

export default AddBankCodes;
