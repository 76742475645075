import LockOutlined from '@ant-design/icons/LockOutlined';
import { Tooltip } from 'antd';
import { useContext } from 'react';
import LangContext from '../../helps/contexts/lang-context';

const NoPermissionLock = () => {
    const ctx = useContext(LangContext);
    const lang = ctx.lang;

    return (
        <Tooltip title={lang.label_no_permission}><LockOutlined /></Tooltip>
    )
}

export default NoPermissionLock