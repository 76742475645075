import React from 'react';
import { Avatar, Card, List } from 'antd';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import SelectBox from '../../../components/HelperComponents/SelectBox';
import MiniText from '../../../components/HelperComponents/MiniText';
import UserImage from '../../../images/pic.jpg';
import { useContext, useState, useEffect } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import GetApi from '../../../helps/contexts/Api/GetApi';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { TabContext } from '../../../helps/contexts/TabContext';

const UserList = ({ days, setDays }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [players, setPlayers] = useState([]);
  const { path } = useContext(TabContext);
  const handleSelectChange = (value, name) => {
    setDays(value);
  };

  const Navigate = useNavigate();

  const getPlayers = async () => {
    const res = await GetApi.sendApiRequest('/admin/players/latest');
    if (res && res.length) {
      setPlayers(res);
    }
  };

  useEffect(() => {
    getPlayers();
  }, []);

  useEffect(() => {
    if (path === '/admin-management/user-list') {
      getPlayers();
    }
  }, [path]);

  const statusArray = [
    {
      id: 0,
      value: 'All'
    },
    {
      id: 1,
      value: 'Approved'
    },
    {
      id: 2,
      value: 'Rejected'
    },
    {
      id: 3,
      value: 'Pending'
    },
    {
      id: 4,
      value: 'processing'
    }
  ];
  const statusData = statusArray?.map(({ value, id }) => ({ value, id }));

  return (
    <Card>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
        <div
          className="flexStyleGraph"
          style={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <div style={{ width: '70%' }}>
            <SubHeading
              extraStyle={{
                fontStyle: 'normal',
                fontFamily: 'Roboto',
                fontSize: '21px',
                fontWeight: '500',
                lineHeight: '27px',
                letterSpacing: '0px',
                color: '#003049',
                opacity: 1
              }}>
              {lang.label_new_users}
            </SubHeading>
          </div>
          {/* <div style={{ width: "45%" }}>
            <SelectBox
              extraStyle={{
                width: "135px",
              }}
              name="days"
              value={days}
              onChange={handleSelectChange}
              data={statusData}
            />
          </div> */}
        </div>
        <div
          style={{
            marginTop: '10px'
          }}>
          <List
            bordered
            dataSource={players}
            renderItem={(item) => (
              <List.Item
                onClick={() => Navigate(`/player-management/player/${item.id}/view`)}
                style={{ cursor: 'pointer', wordBreak: 'break-word' }}>
                <List.Item.Meta
                  avatar={<Avatar icon={<UserOutlined />} style={{ background: '#003049' }} />}
                  title={item.user?.name}
                  description={`Username: ${item.user?.user_name}`}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </Card>
  );
};

export default UserList;
