import React from 'react';
const styled = {
  padding: 0,
  margin: 0,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '19px',
  letterSpacing: '0px',
  color: '#4F5057',
  width: 'auto'
};
const SubHeading = ({ children, extraStyle, onClick, className }) => {
  return (
    <span className={className} onClick={onClick} style={{ ...styled, ...extraStyle }}>
      {children}
    </span>
  );
};

export default SubHeading;
