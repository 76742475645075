import React, { useEffect } from 'react';
import LangContext from '../../../../helps/contexts/lang-context';
import { useContext, useState } from 'react';
import LatestLogins from '../../loginHistory/LatestLogins';
import LatestTransactions from '../../components/LatestTransactions';
import { Button, Modal, Table, message, Tag } from 'antd';
import AddUserBankForm from './UserBank/AddUserBankForm';
import { Link, useLocation } from 'react-router-dom';
import '../../../../styles/ButtonStyle.css';
import PlayerDetailsTable from '../PlayerDetailsTable';
import UserBankTable from './UserBank/UserBankTable';
import { useParams } from 'react-router-dom';
import HeaderCardDetails from '../../../../components/HeaderDetailCard';
import GetApi from '../../../../helps/contexts/Api/GetApi';
import PutApi from '../../../../helps/contexts/Api/PutApi';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../../helps/hooks/useCheckPermission';
import PlayerTransactionTable from './PlayerTransactionTable';
import styles from './ViewPlayerPage.module.css';
import ViewPlayerHistoryTable from './ViewPlayerHistoryTable';
import { MdVerified } from 'react-icons/md';
import LogInHistoryTable from './LogInHistoryTable';
import PlayerBalanceHistiryTable from './PlayerBalanceHistiryTable';
import PlayerBetHistory from './PlayerBetHistory';
import PlayerDepositHistory from './PlayerDepositHistory';
import PlayerWithdrawHistory from './PlayerWithdrawHistory';

const ViewPlayerPage = () => {
  //Getting props from Player list Table
  const location = useLocation();
  const { hasPermission } = useCheckPermission();
  const [tabValue, setTableValue] = useState(1);

  let { id } = useParams();

  const nav = useNavigate();
  let { state } = useLocation();

  //states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userBankTable, setUserBankTable] = useState([]);
  const [openDisableBank, setOpenDisableBank] = useState(false);
  const [openEnableBank, setOpenEnableBank] = useState(false);
  const [loading, setLoading] = useState(false);

  const [verified, setVerified] = useState(false);
  const [lastActive, setLastActive] = useState('Not Availabale');
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  //Handling Modal
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onEditClicked = (row) => {
    setIsModalOpen(true);
    setEditMode(true);
    setFormData(row);
  };

  //Language translation
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  useEffect(() => {
    fetchUserBankData();
  }, []);

  const fetchUserBankData = async () => {
    try {
      setLoading(true);

      const path = `/admin/player/${id}/view`;
      const res = await GetApi.sendApiRequest(path);

      if (res) {
        setUserBankTable(res);
      } else {
        setUserBankTable([]);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
      Object.values(e.data.message).map((errors) => message.error(errors));
    }
  };

  const postKickFromAllGames = async(e) =>{
    e.preventDefault();
    try{
          setLoading(true);
          const path = `/admin/player/kick/${id}`;
           const res = await GetApi.sendApiRequest(path);
          if(res.status){
            message.success(res.message)
          }
    }
    catch (e) {
      message.success("Something went wrong")
    }
    finally{
      setLoading(false);
    }
  }

  const historyData = [
    { id: 1, name: 'Bet History' },
    { id: 2, name: 'Deposit History' },
    { id: 3, name: 'Withdraw History' },
    { id: 4, name: 'Login History' },
    { id: 5, name: 'Player Balance History' }
  ];
  const betHistoryColumns = [
    { title: 'Opened On', dataIndex: 'openOn', key: 'openOn' },
    { title: 'Closed On', dataIndex: 'closed', key: 'closed' },
    { title: 'Reference ID', dataIndex: 'referenceId', key: 'referenceId' },
    { title: 'Win/Loss (amount)', dataIndex: 'winloss', key: 'winloss' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Total Bet Amount', dataIndex: 'totalBet', key: 'totalBet' },
    { title: 'Total Turnover', dataIndex: 'turnover', key: 'turnover' },
    { title: 'Total Valid Bets', dataIndex: 'validBets', key: 'validBets' },
    { title: 'Total Win Amount', dataIndex: 'totalWin', key: 'totalWin' }
  ];

  const columns = [
    {
      title: 'Deposit Information',
      colSpan: 2,
      dataIndex: 'depositInfo'
    },
    {
      title: 'DepositDetail',
      colSpan: 0,
      dataIndex: 'DepositDetail'
    },
    {
      title: 'Withdrawal Information',
      colSpan: 2,
      dataIndex: 'withdrawInfo'
    },
    {
      title: 'withdrawDetail',
      colSpan: 0,
      dataIndex: 'withdrawDetail'
    },
    {
      title: 'Total Information',
      colSpan: 2,
      dataIndex: 'totalInfo'
    },
    {
      title: 'totalInfoDetail',
      colSpan: 0,
      dataIndex: 'totalInfoDetail'
    }
  ];
  const data = [
    {
      depositInfo: 'Total Deposit Count',
      DepositDetail: 32432,
      withdrawInfo: 'Total Withdrawal Count',
      withdrawDetail: 0,
      totalInfo: 'Total Turnover',
      totalInfoDetail: 0.0
    },
    {
      depositInfo: 'Total Deposit Amount',
      DepositDetail: 0.0,
      withdrawInfo: 'Total Withdrawal Amount',
      withdrawDetail: 0.0,
      totalInfo: 'Total Valid Bets',
      totalInfoDetail: 0.0
    },
    {
      depositInfo: 'Average Deposit',
      DepositDetail: 0.0,
      withdrawInfo: 'Average Withdrawal',
      withdrawDetail: 0.0,
      totalInfo: 'Win/Loss',
      totalInfoDetail: 0.0
    },
    {
      depositInfo: 'First Deposit Date Time',
      DepositDetail: '',
      withdrawInfo: 'First Withdrawal Date Time',
      withdrawDetail: '',
      totalInfo: '',
      totalInfoDetail: ''
    },
    {
      depositInfo: 'Last Deposit Date Time',
      DepositDetail: '',
      withdrawInfo: 'Last Withdrawal Date Time',
      withdrawDetail: '',
      totalInfo: '',
      totalInfoDetail: ''
    }
  ];

  return (
    <>
      <HeaderCardDetails />
      <div
        style={{
          color: 'black',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          borderRadius: '2rem',
          boxShadow: '1px solid #A5A5A5',
          padding: '20px'
        }}>
        <span>
          <PlayerDetailsTable id={id} setVerified={setVerified} setLastActive={setLastActive} />
        </span>
        <div
          className="player-vie-table"
          style={{
            color: '#4F5057',
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            padding: '2rem',
            backgroundColor: 'white',
            overflow: 'auto',
            gap: 30,
            position: 'relative'
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '20px',
              position: 'relative'
            }}>
            {/* Handling Kick From All Games */}

            <Button
              style={{
                 backgroundColor: '#004A7F',
                  color: 'white',
                borderRadius: '3px',
                padding: '6px',
                border: '1px solid grey',
                width: '84px'
              }}
              className="btnStyle1"
              onClick={postKickFromAllGames}>
              {lang.label_Kick_From_All_Games}
            </Button>

            {lastActive ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  borderRadius: '12px',
                  fontWeight: 500,
                  color: lastActive === 'Online' ? '#478d47' : 'black',
                  backgroundColor: lastActive === 'Online' ? 'rgba(201, 245, 201, 0.25)' : 'rgba(211, 211, 211,0.25)',
                  padding: '5px 10px'
                }}>
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: lastActive === 'Online' ? 'green' : 'grey',
                    marginRight: '8px'
                  }}></div>
                {lastActive}
              </div>
            ) : null}

            {verified ? (
              <div
                style={{
                  // position: 'absolute',
                  // right: '25px',
                  // top: '15px',
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px'
                }}>
                Verified
                <MdVerified color="#3A9DEC" size={18} />
              </div>
            ) : null}
          </div>

          <PlayerTransactionTable />
          {/* <div style={{ backgroundColor: 'white', marginBottom: '1rem' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '-2rem',
                marginTop: '-1rem'
              }}>
              <h3
                style={{
                  color: '#4F5057',
                  fontFamily: 'Roboto',
                  fontWeight: '590',
                  fontSize: '17px',
                  letterSpacing: '0px',
                  lineHeight: '25px'
                }}>
                {lang.label_latestlogins}
              </h3>
              <Button className="btnStyle" style={{ marginTop: '0.5rem' }}>
                <Link
                  state={{ name: state?.name }}
                  to={{
                    pathname: '/player-management/login-history'
                  }}>
                  {lang.label_viewmore}
                </Link>
              </Button>
            </div>
            <LatestLogins id={id} />
          </div> */}
          {/* <div style={{ marginBottom: '1rem' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '-2rem'
              }}>
              <h3
                style={{
                  color: '#4F5057',
                  fontFamily: 'Roboto',
                  fontWeight: '590',
                  fontSize: '17px',
                  letterSpacing: '0px',
                  lineHeight: '25px'
                }}>
                {lang.label_latesttransactions}
              </h3>
              <Button style={{ marginTop: '0.5rem' }} className="btnStyle">
                {lang.label_viewmore}
              </Button>
            </div>
            <LatestTransactions />
          </div> */}
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '-2rem'
              }}>
              <h3
                style={{
                  color: '#4F5057',
                  fontFamily: 'Roboto',
                  fontWeight: '590',
                  fontSize: '17px',
                  letterSpacing: '0px',
                  lineHeight: '25px'
                }}>
                {lang.label_payment_methods}
              </h3>{' '}
              {hasPermission('Create Player Banks') && (
                <Button
                  style={{ marginTop: '0.5rem', backgroundColor: '#004A7F', color: 'white' }}
                  onClick={() => {
                    setFormData({});
                    setIsModalOpen(true);
                  }}
                  className="btnStyle1">
                  {lang.label_add_payment_methods}
                </Button>
              )}
            </div>
            {isModalOpen ? (
              <Modal
                title={editMode ? lang.label_edit_payment_methods : lang.label_add_payment_methods}
                width={600}
                footer={null}
                open={isModalOpen}
                onOk={() => {
                  setIsModalOpen(false);
                }}
                onCancel={handleCancel}>
                <AddUserBankForm
                  id={id}
                  handleCancel={handleCancel}
                  fetchUserBankData={fetchUserBankData}
                  formData={formData}
                />
              </Modal>
            ) : (
              ''
            )}
            {hasPermission('View Player Banks') && (
              <UserBankTable
                data={userBankTable}
                fetchUserBankData={fetchUserBankData}
                loading={loading}
                onEditClicked={onEditClicked}
              />
            )}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: 15, borderBottom: '1px solid #DDDDDD', position: 'relative' }}>
              {historyData?.map((child, index) => (
                <div
                  key={child.id}
                  style={{
                    borderBottom: child.id === tabValue ? '3px solid #004A7F' : 'none',
                    cursor: 'pointer',
                    position: 'relative',
                    zIndex: 1,
                    top: 2,
                    color: child.id === tabValue ? '#004A7F' : '#AAAAAA'
                  }}
                  onClick={() => setTableValue(child.id)}
                  className={styles.textStyling}>
                  {child?.name}
                </div>
              ))}
            </div>

            <div>
              {tabValue === 1 && <PlayerBetHistory id={id} />}
              {tabValue === 2 && <PlayerDepositHistory id={id} />}
              {tabValue === 3 && <PlayerWithdrawHistory id={id} />}
              {tabValue === 4 && <LogInHistoryTable id={id} />}
              {tabValue === 5 && <PlayerBalanceHistiryTable id={id} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewPlayerPage;
