import IpWhiteListTable from './IpWhiteListTable';
import { useContext, useState, useEffect } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col, Button, Input, Modal, Pagination, Select } from 'antd';
import GetApi from '../../../helps/contexts/Api/GetApi';
import AddNewPromotionForm from '../../../components/forms/AddNewPromotionForm';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import AddIPForm from './AddIPForm';
import { set } from 'react-hook-form';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import { TabContext } from '../../../helps/contexts/TabContext';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import Heading from '../../../components/HelperComponents/Heading';
import SearchIcon from "../../../images/Search.png";
import ResetIcon from "../../../images/Reset.png";
import styles from '../AdminManagement.module.css';

const IpWhiteList = () => {
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();

  const lang = ctx.lang;
  const { Search } = Input;
  const nav = useNavigate();

  const iconStyle = { color: '#004A7F' };
  const inputStyle = {
    width: '190px',
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const selectStyle = {
    width: '188px',
    height: '33px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '3px'
  };

  //states
  const [searchtext, setSearchtext] = useState('');
  const [type, setType] = useState('Select A Type');
  const [ip, setIp] = useState();
  const [typeList, setTypeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [PromotionsData, setPromotionsData] = useState([]);
  const [filteredData, setFilteredData] = useState(PromotionsData);
  const [selectValue, setSelectValue] = useState(null);
  const { path } = useContext(TabContext);

  useEffect(() => {
    getPromotions();
  }, []);
  const { Option } = Select;

  useEffect(() => {
    fetchTypes();
  }, []);
  // Fetching api
  useEffect(() => {
    if (path === '/admin-management/ip-white-list') {
      getPromotions();
      fetchTypes();
    }
  }, [path]);
  const fetchTypes = async () => {
    const path = '/ip/white/list/types';
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path);
    const types = Object.values(res);
    if (types && types.length) {
      const finalArray = types.map((item, index) => ({
        key: index,
        type: item
      }));
      setTypeList(finalArray);
    } else {
      setTypeList([]);
    }
  };

  // Add role popup
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };
  const onReset = async () => {
    try {
      setSearchtext('');
      setIp('');
      setType('Select A Type'); // Set type to an empty string
      setActivepage(1);

      // Reset the value of the Select component using the setSelectValue function
      setSelectValue(null);

      const path = `/ip/white/list`;
      const params = {};
      setLoading(true);
      const res = await GetApi.sendApiRequest(path, null, null);
      console.log(res);
      if (res && res.data && res.data.length) {
        const finalArray = res.data.map((list, index) => ({
          key: index,
          id: list && list.id ? list.id : '',
          name: list && list.name ? list.name : '',
          type: list && list.type_name ? list.type_name : '',
          typeId: list && list.type ? list.type : '',
          ip: list && list.ip ? list.ip : ''
        }));

        setPromotionsData(finalArray);
        setFilteredData(finalArray);
        setCurrentpage(res.current_page);
        setTotal(res.total);
        setPerpage(res.per_page);
        setNextPage(res.next_page_url);
      } else {
        setPromotionsData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };

  const onTypeChange = (event) => {
    setType(event);
  };
  //on submit of filters
  const onSubmit = (e) => {
    e.preventDefault();
    setActivepage(1);
    getPromotions();
  };
  const getPromotions = async (customPage = null) => {
    try {
      const path = `/ip/white/list`;
      const params = {};
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      if (searchtext) params['name'] = searchtext;
      if (ip) params['ip'] = ip;
      if (type !== 'Select A Type') params['type'] = Number(type);
      setLoading(true);
      const res = await GetApi.sendApiRequest(path, {}, params);
      console.log(res);
      if (res && res.data && res.data.length) {
        const finalArray = res.data.map((list, index) => ({
          key: index,
          id: list && list.id ? list.id : '',
          name: list && list.name ? list.name : '',
          type: list && list.type_name ? list.type_name : '',
          typeId: list && list.type ? list.type : '',
          ip: list && list.ip ? list.ip : ''
        }));

        setPromotionsData(finalArray);
        setFilteredData(finalArray);
        setCurrentpage(res.current_page);
        setTotal(res.total);
        setPerpage(res.per_page);
        setNextPage(res.next_page_url);
      } else {
        setPromotionsData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };

  // on click of a page in pagination component

  const onChangepage = (page) => {
    setActivepage(page);
    getPromotions(page);
  };

  return (
    <>
      <HeaderCardDetails />

      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div style={{ overflowY: 'hidden' }}>
          <div className={styles.container}>
            <div
              className={styles.filterUpperContainer}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: '3px'
              }}>
              <Heading>{lang.label_filters}</Heading>
              <div>
                {hasPermission('Create IP Whitelist') && (
                  <Button
                    style={{
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1',
                      marginRight: '20px'
                    }}
                    className="btnStyle"
                    onClick={showModal}>
                    Add IP
                  </Button>
                )}

                <div>
                  {visible ? (
                    <Modal centered title="Add IP" open={visible} footer={null} width={500} closable={false}>
                      <AddIPForm handleCancel={handleCancel} getPromotions={getPromotions} />
                    </Modal>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className={styles.filterLowerContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  width: '100%',
                  paddingRight: '14px',
                  flexWrap: 'wrap'
                }}>
                {/* <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_time}
                  </SubHeading>
                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-around',
                      alignItems: 'center'
                    }}>
                    <DatePicker
                      //getting input value for start date
                      placeholder="Select Time"
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      onChange={onChange}
                      value={startDate}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    />
                    <span
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        marginLeft: '1%',
                        marginRight: '1%'
                      }}>
                      -
                    </span>
                    <DatePicker
                      //getting input value for start date
                      placeholder="Select Time"
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      onChange={onChange1}
                      value={endDate}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    />
                  </div>
                </div> */}

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    Name
                  </SubHeading>

                  <Input
                    style={inputStyle}
                    value={searchtext}
                    type="text"
                    onChange={(e) => {
                      setSearchtext(e.target.value);
                    }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    IP
                  </SubHeading>

                  <Input
                    style={inputStyle}
                    value={ip}
                    type="text"
                    onChange={(e) => {
                      setIp(e.target.value);
                    }}
                  />
                </div>

                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    Type
                  </SubHeading>
                  <div>
                    <Select
                      // defaultValue="All"
                      style={selectStyle}
                      onChange={(value) => {
                        onTypeChange(value);
                        setSelectValue(value); // Update the selectValue state
                      }}
                      value={type}
                      customWidth="200px"
                      extraStyle={{ height: '35px' }}>
                      <Option value="Select A Type"> Select A Type</Option>
                      {typeList &&
                        typeList.map((item, index) => {
                          return (
                            <Option key={index} value={index + 1}>
                              {item.type}
                            </Option>
                          );
                        })}
                    </Select>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    marginTop: '20px',
                    marginLeft: '10px',
                    // marginBottom : "20px",
                    alignItems: 'center',
                    justifyContent: 'space-around'
                  }}>
                  <Button
                    style={{
                      display : 'flex',
                      alignItems : 'center',
                      justifyContent : 'space-evenly',
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1'
                    }}
                    htmlType="submit"
                    className="btnStyle"
                    onClick={() => {
                      setActivepage(1);
                      setLoading(true);
                    }}>
                      <img src={SearchIcon} alt='search' />
                    {lang.label_search}
                  </Button>

                  <Button
                    style={{
                      display : 'flex',
                      alignItems : 'center',
                      justifyContent : 'space-evenly',
                      borderRadius: '3px',
                      padding: '6px',
                      border: '1px solid grey',
                      width: '84px'
                    }}
                    className="btnReset"
                    htmlType="reset"
                    onClick={onReset}>
                       <img src={ResetIcon} alt='reset' />
                    {lang.label_reset}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />
          <IpWhiteListTable loading={loading} data={PromotionsData} getPromotions={getPromotions} />
          <br />

          <Pagination
            defaultCurrent={1}
            current={activepage}
            onChange={onChangepage}
            total={total}
            responsive={true}
            pageSize={perpage}
          />
        </div>
      </form>
    </>
  );
};
export default IpWhiteList;
