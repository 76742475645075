import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import '../src/styles/app.css';
import LangProvider from './helps/contexts/LangProvider';
import NotificationProvider from './helps/contexts/NotificationProvider';
import { TabProvider } from './helps/contexts/TabContext';
import { UserProvider } from './helps/contexts/UserContext';
import Login from './pages/Login/Login';
import PrivateRoute from './routing/PrivateRoute';
import { privateRoutes } from './routing/PrivateRoutes';
import TransactionProvider from './helps/contexts/TransactionProvider';

const App = () => {
  return (
    <Router>
      <TransactionProvider>
        <NotificationProvider>
          <LangProvider>
            <UserProvider>
              <TabProvider>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  {privateRoutes.map((route) => {
                    return (
                      <Route key={route.key} path={route.path} element={<PrivateRoute>{route.element}</PrivateRoute>} />
                    );
                  })}
                  <Route path="/" element={<Navigate to="/dashboard" replace />} />
                  <Route path="/*" element={<Navigate to="/dashboard" replace />} />
                </Routes>
              </TabProvider>
            </UserProvider>
          </LangProvider>
        </NotificationProvider>
      </TransactionProvider>
    </Router>
  );
};
export default App;
