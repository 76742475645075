// import  from 'react';

import HeaderCardDetails from '../../components/HeaderDetailCard';
import LangContext from '../../helps/contexts/lang-context';
// import Search from 'antd/es/input/Search';
import SettingsTable from './SettingsTable';
import Spacer from '../../components/HelperComponents/Spacer';
import { useEffect, useState, useContext } from 'react';
import { Card, Row, Col, Button, Input, Modal, message } from 'antd';
import {
  getSettingsAPI,
  createSettingsAPI,
  getSettingsAPISearch,
  deleteSettingsAPI,
  editSettingsAPI
} from '../../helps/contexts/Api/api';
import AddSettingsForm from '../../components/forms/AddSettingsForm';
import { TabContext } from '../../helps/contexts/TabContext';
import useCheckPermission from '../../helps/hooks/useCheckPermission';
import SubHeading from '../../components/HelperComponents/SubHeading';
import SearchIcon from "../../images/Search.png";
import ResetIcon from "../../images/Reset.png";

const Settings = () => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const token = localStorage.getItem('token');
  const { hasPermission } = useCheckPermission();
  const [searchData, setSearchData] = useState('');
  const [visible, setVisible] = useState(false);
  const [formData, setFormData] = useState({ key: '', value: '' });
  const [settingsData, setSettingsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { path } = useContext(TabContext);

  const handlerSearch = () => {
    getSettingsDataSearch();
  };

  const handleShowModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    setIsEdit(false);
    setFormData({
      id: '',
      key: '',
      value: ''
    });
    setVisible(false);
  };

  const handleReset = () => {
    getSettingsData();
    setFormData({
      id: '',
      key: '',
      value: ''
    });
    setSearchData('');
  };

  const handleEditButton = (record) => {
    setIsEdit(true);
    setFormData({
      id: record.id,
      key: record.key,
      value: record.value
    });
    handleShowModal();
  };

  const deleteSettings = async (id) => {
    setLoading(true);
    try {
      const response = await deleteSettingsAPI(token, id);
      if (response.status === 200) {
        getSettingsData();
        setLoading(false);
      }
    } catch (e) {}
  };

  const getSettingsData = async () => {
    setLoading(true);
    try {
      const response = await getSettingsAPI(token);
      if (response.status === 200) {
        setSettingsData(response.data.data);
        setLoading(false);
      }
    } catch (e) {}
  };

  const getSettingsDataSearch = async () => {
    setLoading(true);
    try {
      const response = await getSettingsAPISearch(token, searchData);
      if (response.status === 200) {
        setSettingsData(response.data.data);
        setLoading(false);
      }
    } catch (e) {}
  };

  const createSettings = async () => {
    setLoading(true);
    try {
      if (formData.key !== '' && formData.value !== '') {
        const payload = {
          key: formData.key,
          value: formData.value
        };
        const response = await createSettingsAPI(payload, token);
        if (response.status === 200) {
          getSettingsData();
          setLoading(false);
        }
      } else {
        throw 'Invalid value';
      }
    } catch (e) {
      if (e.response.status === 422) {
        message.error(e.response.data.message.key[0]);
      }
      setLoading(false);
    }
  };

  const editSetting = async (id) => {
    setLoading(true);
    try {
      if (formData.key !== '' && formData.value !== '') {
        const payload = {
          key: formData.key,
          value: formData.value
        };
        const response = await editSettingsAPI(payload, token, id);
        if (response?.status === 200) {
          getSettingsData();
          message.success('Setting Created Successfully');
          setLoading(false);
        }
      } else {
        throw 'Invalid value';
      }
    } catch (e) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getSettingsData();
  }, []);

  useEffect(() => {
    if (path === '/settings') {
      getSettingsData();
    }
  }, [path]);

  return (
    <>
      <HeaderCardDetails />
      <div style={{ padding: '20px' }}>
        <Card
          title="Filters"
          extra={
            <>
              {hasPermission('Create Setting') && (
                <Button
                  className="btnStyle"
                  style={{
                    borderRadius: '3px',
                    padding: '6px',
                    border: '1px solid grey',
                    width: '84px'
                  }}
                  onClick={handleShowModal}>
                  {'Create'}
                </Button>
              )}
            </>
          }
          style={{
            borderRadius: '5px',
            boxShadow: '0px 1px 5px #00000026'
          }}>
          <div>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
                padding: '0'
              }}>
              <div>
                <div
                  style={{
                    color: '#636363',
                    fontSize: '14px',
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    gap: '5px'
                  }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <SubHeading
                      className="filterTest"
                      extraStyle={{
                        fontSize: '14px',
                        fontWeight: 500,
                        display: 'flex',
                        justifyContent: 'flex-start'
                      }}>
                      {'Search'}
                    </SubHeading>
                    <Input
                      //getting input value for search
                      placeholder="Key or Value"
                      value={searchData}
                      //   style={''}
                      onChange={(e) => {
                        setSearchData(e.target.value);
                      }}
                      style={{ width: '200px' }}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '18px' }}>
                    <Button
                      htmlType="submit"
                      className="btnStyle"
                      style={{
                        display : 'flex',
                        alignItems : 'center',
                        justifyContent : 'space-evenly',
                        backgroundColor: '#004A7F',
                        color: 'white',
                        width: '125px',
                        height: '35px',
                        background: '#004A7F 0% 0% no-repeat padding-box',
                        borderRadius: '3px',
                        opacity: '1',
                        margin: '0px 10px'
                      }}
                      onClick={handlerSearch}>
                      <img src={SearchIcon} alt='search' />
                      {lang.label_search}
                    </Button>

                    <Button
                      onClick={handleReset}
                      style={{
                        display : 'flex',
                        alignItems : 'center',
                        justifyContent : 'space-evenly',
                        borderRadius: '3px',
                        padding: '6px',
                        border: '1px solid grey',
                        width: '84px'
                      }}
                      className="btnReset">
                         <img src={ResetIcon} alt='reset' />
                      {lang.label_reset}
                    </Button>
                  </div>
                </div>
              </div>

              <Col
                span={6}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'end'
                }}></Col>
            </Row>
          </div>
        </Card>
        <Spacer />
        <Spacer />
        <SettingsTable
          loading={loading}
          data={settingsData}
          deleteSettings={deleteSettings}
          handleEditButton={handleEditButton}
        />

        {visible && (
          <Modal
            centered
            title={`${isEdit ? 'Edit Settings' : 'Add Settings'}`}
            open={visible}
            footer={null}
            width={500}
            closable={true}
            onCancel={handleCloseModal}>
            <AddSettingsForm
              formData={formData}
              setFormData={setFormData}
              handleCloseModal={handleCloseModal}
              createSettings={createSettings}
              loading={loading}
              isEdit={isEdit}
              editSetting={editSetting}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default Settings;
