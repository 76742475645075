import { useEffect } from 'react';
import { Input, Button, Row, Col, Switch, Select, DatePicker, message } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState } from 'react';
import PutApi from '../../../helps/contexts/Api/PutApi';
import GetApi from '../../../helps/contexts/Api/GetApi';
import moment from 'moment';

const EditPlayerForm = ({
  onCancel,
  rowdata,
  fetchPlayerDetails,
  fetchPlayerTagName,
  sendApiBettingToggleReques,
  sendApiWithdrawToggleReques
}) => {
  // console.log(rowdata);

  const phoneprev = rowdata.user.phone;
  const phonewithoutcode = phoneprev.slice(3);
  const phonecode = phoneprev.substring(0, 3);
  const oldphone = phonecode + phoneprev;
  const [currencyList, setCurrencyList] = useState([]);
  const [gendersList, setGendersList] = useState([]);

  // form states
  const [username, setUserName] = useState(rowdata && rowdata.user.user_name ? rowdata.user.user_name : '');
  const [name, setName] = useState(rowdata && rowdata.user.name ? rowdata.user.name : '');
  const [countrycode, setcountrycode] = useState(phonecode && phonecode ? phonecode : '');
  const [codedata, setcodedata] = useState([]);
  const [phone, setPhone] = useState(phonewithoutcode && phonewithoutcode ? phonewithoutcode : '');
  // const [phone, setPhone] = useState(
  //   rowdata && rowdata.user.phone ? rowdata.user.phone : ""
  // );
  const [remark, setRemark] = useState(rowdata && rowdata.user.remark ? rowdata.user.remark : '');
  const [gender, setGender] = useState(rowdata && rowdata.user.gender ? Number(rowdata.user.gender) : '');
  const [birthday, setBirthday] = useState(rowdata && rowdata.user.birthday ? rowdata.user.birthday : '');
  const [status, setStatus] = useState(rowdata && rowdata.type ? rowdata.type : '');
  const [currency, setcurrency] = useState(rowdata?.wallet?.currency ? rowdata.wallet?.currency : '');
  // const [selectValue, setSelectValue] = useState(null);
  // const [allowbetting, setAllowBetting] = useState('');
  // const [withdrawl, setWithdrawl] = useState('');
  const [dateparam, setDateparam] = useState('');
  const { TextArea } = Input;
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { Option } = Select;
  var playerid = rowdata.id;

  useEffect(() => {
    fetchCurrency();
    fetchCountryCode();
    fetchGenders();
  }, []);

  const disableFutureDates = (current) => {
    // Disable dates after today
    return current && current > moment().endOf('day');
  };

  // Fetching api
  const fetchGenders = async () => {
    const path = '/player/get/genders';
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path);
    const curr = Object.keys(res);
    if (curr && curr.length) {
      const finalArray = curr.map((item, index) => ({
        value: Number(item),
        label: res[item]
      }));
      setGendersList(finalArray);
    } else {
      setGendersList([]);
    }
  };

  const fetchCurrency = async () => {
    const path = '/player/currencies';

    //sending api request
    const res = await GetApi.sendApiRequest(path);
    if (res) {
      const objArray = Object.keys(res);
      const finalArray = objArray.map((v) => ({ value: Number(v), label: res[v] }));
      setCurrencyList(finalArray);
    } else {
      setCurrencyList([]);
    }
  };
  //remark
  const onChangeRemark = (e) => {
    setRemark(e.target.value);
  };

  //date picker
  const onChange = (value, dateString) => {
    setBirthday(dateString);
    setDateparam();
  };

  //gender dropdown
  const handleChange = (value) => {
    setGender(value);
  };

  //status dropdown
  const handleChange1 = (value) => {
    setStatus(value);
  };

  const fetchCountryCode = async () => {
    const path = '/user/phone/codes';
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path, {}, params);

    if (res && res.length) {
      const finalArray = res.map((cc, index) => ({
        key: index,
        code: cc.code
      }));
      setcodedata(finalArray);
    } else {
      setcodedata([]);
    }
  };

  const oncodechange = (value) => {
    setcountrycode(value);
  };

  //form submit to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const finalphone = countrycode + phone;
      const path = `/admin/player/${playerid}/update`;
      // sending request

      const res = await PutApi.PutApiRequest(path, {
        user_name: username,
        name: name,
        phone: countrycode ? finalphone : oldphone,
        gender: gender,
        birthday: birthday,
        status: status,
        remark: remark,
        currency: currency
        // allow_withdraw: withdrawl,
        // allow_betting: allowbetting
      });
      //if success
      if (res) {
        onCancel();
        message.success(lang.label_playerupdatesuccess);
        fetchPlayerDetails();
        fetchPlayerTagName();
      } else if (!res) {
        message.error(lang.label_playerupdatefail);
      }
    } catch (e) {
      message.error(lang.label_playerupdatefail);
      Object.values(e.data.message).map((errors) => message.error(errors));
    }
  };

  // useEffect(() => {
  //   fetchCountryCode();
  //   setAllowBetting(
  //     rowdata.allow_betting === 1 ? (
  //       <div>
  //         <Switch defaultChecked={rowdata.allow_betting} onChange={sendApiBettingToggleReques} />
  //       </div>
  //     ) : (
  //       <div>
  //         <Switch defaultChecked={rowdata.allow_betting} onChange={sendApiBettingToggleReques} />
  //       </div>
  //     )
  //   );
  //   setWithdrawl(
  //     rowdata.allow_withdraw === 1 ? (
  //       <div>
  //         <Switch defaultChecked={rowdata.allow_withdraw} onChange={sendApiWithdrawToggleReques} />
  //       </div>
  //     ) : (
  //       <div>
  //         <Switch defaultChecked={rowdata.allow_withdraw} onChange={sendApiWithdrawToggleReques} />
  //       </div>
  //     )
  //   );
  // }, [rowdata.allow_betting, rowdata.allow_withdraw]);

  const handleCurrency = (value) => {
    setcurrency(value);
  };

  return (
    <div
      style={{
        width: '97%',
        height: '85%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: '3%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '-6%'
          }}>
          <Row>
            <Col span={12}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_name}</label>
              <br />

              <Input
                defaultValue="Player Name"
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                placeholder="Player Name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength={18}
                minLength={8}
              />
            </Col>
            <Col span={12}>
              <label>
                {lang.label_username}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <br />

              <Input
                disabled
                defaultValue="Player Username"
                type="text"
                placeholder="Player Name"
                name="username"
                required
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                minLength={8}
                maxLength={18}
              />
            </Col>
          </Row>
          <Row>
            <Col span={3}>
              <label>code </label>
              <br />
              <Select style={{ width: '90%', marginTop: '5px' }} onChange={oncodechange} defaultValue={countrycode}>
                <Option>{}</Option>
                {codedata &&
                  codedata.map((cc, index) => {
                    return (
                      <Option key={index} value={cc.code}>
                        {cc.code}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
            <Col span={9}>
              <label>{lang.label_phone} </label>
              <br />

              <Input
                defaultValue="5123455633"
                style={{
                  width: '215%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                placeholder="Enter Phone Number"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>{lang.label_gender}</label>
              <br />

              <Select
                defaultValue={1}
                value={gender}
                style={{
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                onChange={handleChange}
                options={gendersList}
              />
            </Col>
            <Col span={12}>
              <label>{lang.label_bday}</label>
              <br />

              <DatePicker
                onChange={onChange}
                placeholder={birthday}
                style={{
                  height: '30px',
                  width: '85%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                disabledDate={disableFutureDates}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <label>{lang.label_currency}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />
              <Select
                options={currencyList}
                style={{
                  width: '93%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                value={currency}
                onChange={(value) => {
                  handleCurrency(value);
                  // setSelectValue(value);
                }}>
                {/* <Option key={1000} value={currency}>
                  {currency}
                </Option> */}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>{lang.label_status}</label> <br />
              <Select
                defaultValue={lang.label_active}
                value={status}
                style={{
                  width: '186%',
                  borderRadius: '3px',
                  color: 'green',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                onChange={handleChange1}
                options={[
                  {
                    value: 1,
                    label: 'Normal Account'
                  },
                  {
                    value: 2,
                    label: 'Test Account'
                  }
                ]}
              />
            </Col>
          </Row>
          {/* <br />
          <Row>
            <Col span={24}>
              <label>{lang.label_allowbetting} </label>
              {allowbetting}
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <label>{lang.label_allowwithdraw}</label>
              {withdrawl}
            </Col>
          </Row>
          <br /> */}
          <Row>
            <Col span={12}>
              <label>{lang.label_remark}</label>
              <br />

              <>
                <TextArea
                  showCount
                  maxLength={100}
                  onChange={onChangeRemark}
                  style={{
                    width: '186%',
                    height: '100px',
                    borderRadius: '3px',
                    marginBottom: '5px',
                    marginTop: '5px'
                  }}
                  value={remark}
                />
              </>
            </Col>
          </Row>
          <br />
          <br />
          <br />

          <div>
            <Button
              style={{
                borderRadius: '3px',
                backgroundColor: '#004A7F',
                color: 'white',
                position: 'absolute',
                right: '20%',
                top: '93%'
              }}
              htmlType="submit">
              {lang.label_submit}
            </Button>
            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                position: 'absolute',
                right: '8%',
                top: '93%'
              }}
              onClick={onCancel}>
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditPlayerForm;
