import React from 'react';
import { Button } from 'antd';

const DuplicatePlayerList = ({ duplicateID, handleCancelDuplicate }) => {
  return (
    <div style={{ width: '97%', minHeight: '200px', height: '100%', overflowX: 'auto', position: 'relative' }}>
      <div style={{ paddingRight: '10px', whiteSpace: 'nowrap' }}>
        {duplicateID.join(', ')}
      </div>
      <Button
        style={{
          borderRadius: '3px',
          color: '#004A7F',
          position: 'absolute',
          right: '10px',
          bottom: '10px',
        }}
        onClick={()=>handleCancelDuplicate()}
      >
        Close
      </Button>
    </div>
  );
};

export default DuplicatePlayerList;
