import PromotionsTable from './PromotionsTable';
import { useContext, useState, useEffect } from 'react';
import LangContext from '../../helps/contexts/lang-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Row, Col, Button, Input, Modal, Pagination } from 'antd';
import GetApi from '../../helps/contexts/Api/GetApi';
import AddNewPromotionForm from '../../components/forms/AddNewPromotionForm';
import HeaderCardDetails from '../../components/HeaderDetailCard';
import { TabContext } from '../../helps/contexts/TabContext';
import SearchIcon from "../../images/Search.png";
import ResetIcon from "../../images/Reset.png";
const Promotions = () => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const location = useLocation();
  const pathname = location.pathname;
  const { Search } = Input;
  const nav = useNavigate();
  const inputStyle = {
    width: '190px',
    height: '33px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };

  //states
  const [searchtext, setSearchtext] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [visible, setVisible] = useState(false);
  const [PromotionsData, setPromotionsData] = useState([]);
  const [filteredData, setFilteredData] = useState(PromotionsData);
  const [countryData, setCountryData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [promoCategoryData, setPromoCategoryData] = useState([]);
  // const [promotionTypeData, setPromotionTypeData] = useState([]);
  // const [conditionsTypeData, setConditionsTypeData] = useState([]);
  // const [rewardsLevelsData, setRewardsLevelsData] = useState([]);
  // const[rewardsTypeData, setRewardsTypeData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const { path } = useContext(TabContext);
  // const [conditionArray, setConditionArray] = useState([]);
  // const [rewardsArray, setRewardsArray] = useState([]);
  // const [rewards, setRewards] = useState({
  //   amount:'',
  //   turnover_multiplier:'',
  //   type:'',
  //   level_id:'',
  //   probability:'',
  //   image:'',
  //   desktop_image:'',
  //   remarks:''
  // });

  // const [condition, setCondition] = useState({
  //   is_withdraw:1,
  //   type:2,
  //   to_reach:1,
  //   sing_up_days:'',
  //   amount_limit:'',
  //   turnover_limit:'',
  //   win_loss_limit:'',
  //   valid_bet_amount:''
  // });

  //formdata for addNewPromotion --Add new Promotion---//
  const [formData, setFormData] = useState({
    title: '',
    sub_title : '',
    status: 1,
    image: null,
    desktopImage: null,
    // claimableLimit:null,
    // isAuto:1,
    // type:'',
    // startDate:'',
    // endDate:'',
    startTimeParam: '',
    endTimeParam: '',
    remark: '',
    // country:'',
    currency: '',
    promoCategory: [],
    // conditions:[],
  });
  const [formErrors, setFormErrors] = useState({
    title: false,
    sub_title : false,
    image: false,
    currency:false,
    // type:false,
    desktopImage: false,
    startDate: false,
    endDate: false,
    status: false,
    // isAuto:false,
    // country: false,
    category: false,
    remark: false,    
  });

  const onChangeStatus = (value) => {
    setFormData({ ...formData, status: value });
    setFormErrors({ ...formErrors, status: false });
  };

  // const onChangeCountry = (value) => {
  //   setFormData({ ...formData, country: value });
  //   setFormErrors({ ...formErrors, country: false });
  // };
  const onChangeCurrency = (value) => {
    setFormData({ ...formData, currency: value });
    setFormErrors({ ...formErrors, currency: false });
  };
  // const onChangePromotionType = (value) => {
  //   setFormData({ ...formData, type: value });
  //   setFormErrors({ ...formErrors, type: false });
  // };

  // const onChangeConditionType = (value) => {
  //   setCondition({ ...condition, type: value });
  // };

  // const onChangeRewardsType = (value) => {
  //   setRewards({ ...rewards, type: value });
  // };

  // const onChangeRewardsLevelId = (value) => {
  //   setRewards({ ...rewards, level_id: value });
  // };

  const onChangeCategory = (value, prop2, name) => {
    setFormData({ ...formData, promoCategory: value });
    setFormErrors({ ...formErrors, category: false });
  };

  const onChange = (value, dateString) => {
    // setFormData({...formData, startDate: value});
    setStartDate(value);
    setFormData({ ...formData, startTimeParam: dateString });
    setFormErrors({ ...formErrors, startDate: false });
  };
  const onChange1 = (value, dateString) => {
    // setFormData({...formData, endDate: value});
    setEndDate(value);
    setFormData({ ...formData, endTimeParam: dateString });
    setFormErrors({ ...formErrors, endDate: false });
  };

  // --Add new Promotion---//

  useEffect(() => {
    getPromotions();
    getPromotionCategory();
    // getUserCountries();
    getCurrencyList();
    // getPromotionTypeList();
    // getConditionsTypeList();
    // getRewardsTypeList();
    // getRewardsLevelId();
  }, []);

  useEffect(() => {
    if (path === '/promotions/promotions-list') {
      getPromotions();
      getPromotionCategory();
      // getUserCountries();
      getCurrencyList();
      // getPromotionTypeList();
      // getConditionsTypeList();
      // getRewardsTypeList();
      // getRewardsLevelId();
    }
  }, [path]);

  useEffect(() => {
    getPromotionCategory();
  }, [pathname.includes('promotions-list')]);

  // Add role popup
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };
  const onReset = () => {
    setSearchtext('');
    setActivepage1(1);
    getPromotions(currentpage);
  };

  const getPromotions = async (customPage = null) => {
    try {
      const path = `/admin/promotions`;
      const params = {};
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      setLoading(true);
      const res = await GetApi.sendApiRequest(path, {}, params);

      if (res && res.data && res.data.length) {
        const finalArray = res.data.map((promo, index) => ({
          key: index,
          id: promo && promo.id ? promo.id : '',
          promotion_category_ids: promo && promo?.promotion_categories ? promo?.promotion_categories : '',
          // country: promo && promo.country ? promo.country : '',
          currency: promo && promo?.currency ? promo?.currency : '',
          image: promo && promo.image ? promo.image : '',
          desktopImage: promo && promo.desktop_image ? promo.desktop_image : '',
          title: promo && promo.title ? promo.title : '', 
          sub_title : promo && promo.sub_title ? promo.sub_title  : '',
         
         
 
          createddate: promo && promo.start_date ? promo.start_date : null,
          remark: promo && promo.body ? promo.body : '',
          expirydate: promo && promo.end_date ? promo.end_date : null,
          status: promo && promo.status,
          turnedonby: promo && promo.action_by && promo.action_by.user_name.length ? promo.action_by.user_name : '-'
        }));
        
        setPromotionsData(finalArray);
        setFilteredData(finalArray);
        setCurrentpage(res.current_page);
        setTotal(res.total);
        setPerpage(res.per_page);
        setNextPage(res.next_page_url);
      } else {
        setPromotionsData([]);
        setFilteredData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };
  //API to fetch promotion category list
  const getPromotionCategory = async () => {
    try {
      const path = `/promotion_categories`;
      const res = await GetApi.sendApiRequest(path);
      if (res && res.data && res.data.length) {
        const filteredData = res.data.filter((item) => item?.is_active === 1);
        const options = filteredData.map((item) => ({
          key: item.id,
          value: item.id,
          label: item.name.en
        }));
        setPromoCategoryData(options);
      } else {
        setPromoCategoryData([]);
      }
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };
  //API for get country list
  const getUserCountries = async () => {
    try {
      const path = `/user/countries`;
      const res = await GetApi.sendApiRequest(path);
      if (res) {
        const options = Object.keys(res).map((key) => ({
          key: parseInt(key),
          value: parseInt(key),
          label: res[key]
        }));
        setCountryData(options);
      } else {
        setCountryData([]);
      }
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };
  const getCurrencyList = async () => {
    try {
      const res = await GetApi.sendApiRequest('/player/currencies');
      if (res) {
        const options = Object.keys(res).map((key) => ({
          key: parseInt(key),
          value: parseInt(key),
          label: res[key]
        }));
        setCurrencyData(options);
      } else {
        setCurrencyData([]);
      }
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };
  // const getPromotionTypeList = async () => {
  //   try {
  //     const res = await GetApi.sendApiRequest('/admin/promotions/types');
  //     if(res){
  //       const options = Object.keys(res).map((key) => ({
  //         key: parseInt(key),
  //         value: parseInt(key),
  //         label: res[key]
  //       }));
  //       setPromotionTypeData(options);
  //     } else {
  //       setPromotionTypeData([]);
  //     }
  //   } catch (e) {
  //     if (e?.status === 401) {
  //       localStorage.clear();
  //       nav('/login');
  //     }
  //   }
  // };

  // const getConditionsTypeList = async () => {
  //   try {
  //     const res = await GetApi.sendApiRequest('/admin/conditions/types');
  //     if(res){
  //       const options = Object.keys(res).map((key) => ({
  //         key: parseInt(key),
  //         value: parseInt(key),
  //         label: res[key]
  //       }));
  //       setConditionsTypeData(options);
  //     } else {
  //       setConditionsTypeData([]);
  //     }
  //   } catch (e) {
  //     if (e?.status === 401) {
  //       localStorage.clear();
  //       nav('/login');
  //     }
  //   }
  // };

  // const getRewardsTypeList = async () => {
  //   try {
  //     const res = await GetApi.sendApiRequest('/admin/reward/types');
  //     if(res){
  //       const options = Object.keys(res).map((key) => ({
  //         key: parseInt(key),
  //         value: parseInt(key),
  //         label: res[key]
  //       }));
  //       setRewardsTypeData(options);
  //     } else {
  //       setRewardsTypeData([]);
  //     }
  //   } catch (e) {
  //     if (e?.status === 401) {
  //       localStorage.clear();
  //       nav('/login');
  //     }
  //   }
  // };

  // const getRewardsLevelId = async () => {
  //   try {
  //     const res = await GetApi.sendApiRequest('/get-levels');
  //     if(res){
  //       const options = res.map((item) => ({
  //         key: parseInt(item?.id),
  //         value: parseInt(item?.id),
  //         label: item?.level_name
  //       }));
  //       setRewardsLevelsData(options);
  //     } else {
  //       setRewardsLevelsData([]);
  //     }
  //   } catch (e) {
  //     if (e?.status === 401) {
  //       localStorage.clear();
  //       nav('/login');
  //     }
  //   }
  // };
  
  // on click of a page in pagination component

  const onChangepage = (page) => {
    setActivepage1(page);
    getPromotions(page);
  };
  // //search on table
  // const onSearch = (event) => {
  //   setSearchtext(event);

  //   let result = [];

  //   result = PromotionsData.filter((data) => {
  //     return data.title.toLowerCase().search(event.toLowerCase()) !== -1;
  //   });
  //   setFilteredData(result);
  // };
  //on submit of filters on table values
  const onSubmit = (e) => {
    e.preventDefault();
    if (searchtext) {
      let result = [];

      result = PromotionsData.filter((data) => {
        return data.title.toLowerCase().search(searchtext.toLowerCase()) !== -1;
      });
      setFilteredData(result);
    }
    setActivepage1(1);
  };

  return (
    <>
      <HeaderCardDetails />

      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div style={{ overflowY: 'hidden' }}>
          <Card
            title={lang.label_filters}
            style={{
              borderRadius: '3px',
              height: '140px'
            }}>
            <div
              style={{
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around'
              }}>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center'
                }}>
                <Col
                  span={18}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}>
                  <label style={{ marginLeft: '0%', marginRight: '2%' }}>{lang.label_title} :</label>
                  {/* <Search
                    placeholder="Please Enter Title.."
                    style={{ width: 250, marginRight: "2%" }}
                    onSearch={onSearch}
                    onChange={(e) => {
                      setSearchtext(e.target.value);
                    }}
                    value={searchtext}
                  /> */}
                  <Input
                    style={inputStyle}
                    value={searchtext}
                    type="text"
                    onChange={(e) => {
                      setSearchtext(e.target.value);
                    }}
                  />
                  <Button
                    htmlType="submit"
                    style={{
                      display : 'flex',
                      alignItems : 'center',
                      justifyContent : 'space-evenly',
                      marginRight: '0.5rem',
                      marginLeft: '0.5rem'
                    }}
                    className="btnStyle">
                      <img src={SearchIcon} alt='search' />
                    {lang.label_search}
                  </Button>
                  <Button
                    type="secondary"
                    style={{
                      display : 'flex',
                     alignItems : 'center',
                     justifyContent : 'space-evenly',
                      borderRadius: '3px',
                      border: '1px solid grey',
                      width: '84px',
                      backgroundColor: 'white'
                    }}
                    className="btnReset"
                    //handling reset
                    onClick={onReset}>
                      <img src={ResetIcon} alt='reset' />
                    <label style={{ color: '#004A7F' }}>{lang.label_reset}</label>
                  </Button>
                </Col>
                <Col
                  span={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'end'
                  }}>
                  <Button
                    type="secondary"
                    style={{
                      borderRadius: '3.5px',
                      right: '20%',
                      border: '1px solid grey',
                      width: '200px !important',
                      backgroundColor: '#004A7F',
                      height: '35px',

                      right: 1
                    }}
                    onClick={showModal}>
                    <label style={{ color: 'white' }}>{lang.label_addpromotion}</label>
                  </Button>
                  <div>
                    {visible ? (
                      <Modal title={lang.label_addpromotion} open={visible} footer={null} width={800} closable={false}>
                        <AddNewPromotionForm
                          handleCancel={handleCancel}
                          getPromotions={getPromotions}
                          currencyData={currencyData}
                          promoCategoryData={promoCategoryData}
                          // promoTypeData={promotionTypeData}
                          // onChangePromotionType={onChangePromotionType}
                          formData={formData}
                          onChangeStatus={onChangeStatus}
                          // onChangeCountry={onChangeCountry}
                          // countryData={countryData}
                          onChangeCurrency={onChangeCurrency}
                          onChangeCategory={onChangeCategory}
                          onChange={onChange}
                          onChange1={onChange1}
                          setFormData={setFormData}
                          setFormErrors={setFormErrors}
                          formErrors={formErrors}
                          startDate={startDate}
                          endDate={endDate}
                          setStartDate={setStartDate}
                          setEndDate={setEndDate}
                          // setCondition={setCondition}
                          // condition={condition}
                          // setRewards={setRewards}
                          // rewards={rewards}
                          // onChangeConditionType={onChangeConditionType}
                          // conditionsTypeData={conditionsTypeData}
                          // rewardsTypeData={rewardsTypeData}
                          // onChangeRewardsType = {onChangeRewardsType}
                          // onChangeRewardsLevelId={onChangeRewardsLevelId}
                          // rewardsLevelsData={rewardsLevelsData}
                          // setConditionArray={setConditionArray}
                          // conditionArray={conditionArray}
                          // setRewardsArray={setRewardsArray}
                          // rewardsArray = {rewardsArray}
                        />
                      </Modal>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <br />
          <br />
          <PromotionsTable
            loading={loading}
            data={filteredData}
            getPromotions={getPromotions}
            // countryData={countryData}
            currencyData={currencyData}
            promoCategoryData={promoCategoryData}
            getPromotionCategory={getPromotionCategory}
            onChangepage={onChangepage}
            total={total}
            perpage={perpage}
            currentpage={currentpage}
          />
          <br />

          <Pagination
            defaultCurrent={1}
            current={activepage}
            onChange={onChangepage}
            total={total}
            responsive={true}
            pageSize={perpage}
          />
        </div>
      </form>
    </>
  );
};
export default Promotions;
