import { Table, Button, Modal, Switch, message } from "antd";
import { useContext, useState } from "react";
import LangContext from "../../../helps/contexts/lang-context";

import { MdEdit } from "react-icons/md";
import UpdateCompanyBanks from "./UpdateCompanyBanks";
import ToggleDeposit from "./ToggleDeposit";
import ToggleWithdraw from "./ToggleWithdraw";
import ChangeStatusModal from "../../../components/ChangeStatusModal/ChangeStatusModal";
import PutApi from "../../../helps/contexts/Api/PutApi";
import addCommasToNumber from "../../../helps/AddCommasToNumber";
import { toFixedFunc } from "../../../helps/HelperFunctions";
import useCheckPermission from "../../../helps/hooks/useCheckPermission";

const CompanyBanksTable = ({
  companyTableData,
  loading,
  fetchCompanyBanks,
}) => {
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission()

  const lang = ctx.lang;
  //states
  const [rowData, setRowData] = useState({});
  const [isDeposit, setIsdeposit] = useState(false);
  const [isWithdraw, setIswithdraw] = useState(false);
  const [isEdit, setIsedit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deposit, setDeposit] = useState();
  const [withdraw, setWithdraw] = useState();
  const id = rowData.key;
  const handleCancel = (record) => {
    setIsedit(false);
    setVisible(!visible);
  };
  const handleEdit = (record) => {
    setIsedit(true);
    setVisible(!visible);
    setIsdeposit(false);
    setIswithdraw(false);
    setRowData(record);
  };
 

  const columns = [
    {
      title: lang.label_bankimage,
      dataIndex: "bankimage",
      key: "bankimage",
      render: (record) => (
        <img style={{ width: "50px", height: "50px" }} src={record} alt="" />
      ),
    },
    {
      title: lang.label_bankname,
      dataIndex: "bankname",
      key: "bankname",
    },
    {
      title: lang.label_bankaccountname,
      dataIndex: "bankaccountname",
      key: "bankaccountname",
    },
    {
      title: lang.label_bankCurrencyname,
      dataIndex: "currencyName",
      key: "currencyName",
    },

    {
      title: lang.label_bankaccountnumber,
      dataIndex: "bankaccountnumber",
      key: "bankaccountnumber",
    },

    {
      title: lang.label_balance,
      dataIndex: "balance",
      key: "balance",
      render: (record) => toFixedFunc(record, process.env.REACT_APP_Decimel_Points),
    },
    ...(hasPermission('Company Bank Deposit Toggle') ? [
      {
        title: lang.label_deposit,
        dataIndex: "deposit",
        key: "deposit",
        render: (record) =>
          record === 1 ? (
            <div
              onClick={() => {
                setVisible(true);
                setIsedit(false);
                setIsdeposit(true);
                setIswithdraw(false);

              }}
            >
              {" "}
              <Switch size="small" checked={record} />
            </div>
          ) : (
            <div
              onClick={() => {
                setVisible(true);
                setIsedit(false);
                setIsdeposit(true);
                setIswithdraw(false);

              }}
            >
              {" "}
              <Switch checked={record} size="small" />
            </div>
          ),
      }
    ]:[]),
    ...(hasPermission('Company Bank Withdraw Toggle') ? [
    {
      title: lang.label_withdraw,
      dataIndex: "withdraw",
      key: "withdraw",
      render: (record) =>
        record === 1 ? (
          <div
            onClick={() => {
              setVisible(true);
              setIsedit(false);
              setIsdeposit(false);
              setIswithdraw(true);
              
            }}
          >
            {" "}
            <Switch checked={record} size="small" />
          </div>
        ) : (
          <div
            onClick={() => {
              setVisible(true);
              setIsedit(false);
              setIsdeposit(false);
              setIswithdraw(true);
             
            }}
          >
            {" "}
            <Switch checked={record} size="small" />
          </div>
        ),
    }]:[]),

    ...(hasPermission('Edit Bank') ? [
      {
        title: "Action",
        key: "action",
        render: (record, index) => (
          <div>
            <Button
              onClick={() => {
                handleEdit(record);
              }}
              style={{
                backgroundColor: "#eeeeee",
                color: "#004A7F",
                borderRadius: "4px",
                width: "18%",
                height: "12%",
              }}
            >
              <MdEdit size={15} style={{ marginLeft: "-6px" }} />
            </Button>
          </div>
        ),
      }
    ]:[]),
    
  ];

  return (
    <>
      <Table
        style={{ height: "570px", width: "100%" }}
        size="small"
        columns={columns}
        dataSource={companyTableData}
        bordered
        pagination={false}
        loading={loading}
        onRow={(record) => {
          return {
            onClick: () => {
              setRowData(record);
            },
          };
        }}
      />
      <div>
        {(isEdit || isDeposit || isWithdraw) && (
          <Modal
            closeIcon={null}
            title={
              isEdit
                ? lang.label_editbank
                : isDeposit
                ? lang.label_toggledeposit
                : isWithdraw
                ? lang.label_togglewithdraw
                : ""
            }
            open={visible}
            onCancel={handleCancel}
            footer={null}
            width={isEdit ? 700 : isDeposit ? 460 : isWithdraw ? 460 : ""}
            closable={true}
          >
            {isEdit ? (
              <UpdateCompanyBanks
                rowData={rowData}
                handleCancel={handleCancel}
                fetchCompanyBanks={fetchCompanyBanks}
              />
            ) : isDeposit ? (
              <ToggleDeposit
                rowData={rowData}
                handleCancel={handleCancel}
                fetchCompanyBanks={fetchCompanyBanks}
              />
            ) : isWithdraw ? (
              <ToggleWithdraw
                rowData={rowData}
                handleCancel={handleCancel}
                fetchCompanyBanks={fetchCompanyBanks}
              />
            ) : (
              ""
            )}
          </Modal>
        )}
      </div>
    </>
  );
};

export default CompanyBanksTable;
