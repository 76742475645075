import SubHeading from '../../../../components/HelperComponents/SubHeading';
import InputField from '../../../../components/HelperComponents/InputField';
import styles from './CreateModal.module.css';
import CustomButton from '../../../../components/HelperComponents/CustomButton';
import SelectBox from '../../../../components/HelperComponents/SelectBox';
import Loader from '../../../../components/HelperComponents/Loader';
import React, { useContext, useEffect, useState } from 'react';
import LangContext from '../../../../helps/contexts/lang-context';
import DragTableComp from '../../../../components/HelperComponents/DragTableComp';
import { sortingGameCategorySingleGame } from '../../../../helps/contexts/Api/api';
import UploadWithLabel from '../../../../components/HelperComponents/UploadWithLabel';
import SwitchWithLabel from '../../../../components/HelperComponents/SwitchWithLabel';
import GetApi from '../../../../helps/contexts/Api/GetApi';
import MultiSelectComp from '../../../../components/HelperComponents/MultiSelect';

const CreateModal = ({
  submitLoading,
  errors,
  type,
  updateFunc,
  setItem,
  submitFunc,
  status,
  categories,
  setFormData,
  formData,
  handleSelectChange,
  handleInputChange,
  item,
  handleCloseModal,
  gameProperties
}) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (type === 'update') {
      getAllGames();
    }
  }, []);

  const handleMulltiSelectChange = (value, prop2, name) => {
    setFormData({
      ...formData,
      [name]: prop2
    });
  };

  async function getAllGames() {
    setLoading(true);
    const path = `/game_categories/${item.id}/get`;
    const res = await GetApi.sendApiRequest(path, {}, null);

    if (res && res[0]) {
      setItem({ ...item, game_items: res[0].game_items });
    }
    setLoading(false);
  }

  useEffect(() => {
    if (item) {
      const newArray = item.game_items?.map((item, index) => {
        return {
          key: item?.id,
          sort: item?.pivot?.game_item_sort_order,
          platformName: item?.game_platform?.name,
          categoryId: item?.id,
          // categoryName : item.categoryName,
          gameId: item.game_id,
          gameName: JSON.parse(item.name)?.en,
          iconsquare: (
            <div
              style={{
                height: '30px',
                width: '35px',
                objectFit: 'contain'
              }}>
              <img
                style={{
                  height: '100%',
                  width: '100%'
                }}
                src={item.icon_square}
                alt={item.game_id}
              />
            </div>
          ),
          iconrectangle: (
            <div
              style={{
                height: '30px',
                width: '35px',
                objectFit: 'contain'
              }}>
              <img
                style={{
                  height: '100%',
                  width: '100%'
                }}
                src={item.icon_rectangle}
                alt={item.game_id}
              />
            </div>
          ),
          icon: item.icon,
          status: item.status,
          actions: item.actions
        };
      });
      console.log(item?.bg_image);
      const categoryData = {
        categoryName: item?.categoryName || formData.categoryName,
        categoryNameVt: item?.categoryNameVt || formData.categoryNameVt,
        categoryNameBn: item?.categoryNameBn || formData.categoryNameBn,
        categoryNameTl: item?.categoryNameTl || formData.categoryNameTl,
        icon: item?.icon_image || formData.icon,
        iconactive: item?.icon_active || formData.icon_active,
        bg_image: item?.bg_image || formData?.bg_image,
        status: item?.status ?? formData.status,
        is_lobby: item.is_lobby ?? formData.is_lobby,
        parent_category_id: item.parent_category_id || formData.parent_category_id,
        icon_trend: item.icon_trend || formData.icon_trend,
        properties: (item.properties_array || formData.properties || [])
          .map((item) => {
            const valueKey = Object.keys(item).find((key) => item[key] === true);
            return { value: valueKey, id: item.id };
          })
          .filter((item) => item.value !== undefined)
      };

      setFormData(categoryData);

      setData(newArray);
    }
  }, [item]);

  const columns = [
    {
      title: lang.label_sort,
      dataIndex: 'sort',
      key: 'sort'
    },
    {
      title: lang.label_platformname,
      dataIndex: 'platformName',
      key: 'platformName'
    },
    // {
    //   title: lang.label_category,
    //   dataIndex: "categoryName",
    //   key: "categoryName",

    // },
    {
      title: lang.label_gameid,
      dataIndex: 'gameId',
      key: 'gameId'
    },
    {
      title: lang.label_gamename,
      dataIndex: 'gameName',
      key: 'gameName'
    },
    {
      title: lang.label_iconsq,
      dataIndex: 'iconsquare',
      key: 'iconsquare'
    },
    {
      title: lang.label_iconrec,
      dataIndex: 'iconrectangle',
      key: 'iconrectangle'
    },
    {
      title: lang.label_icon,
      dataIndex: 'icon',
      key: 'icon'
    }
    // {
    //   title: lang.label_status,
    //   dataIndex: "status",
    //   key: "status",
    // },
    // {
    //   title: lang.label_actions,
    //   dataIndex: "actions",
    //   key: "actions",
    // },
  ];

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          display: 'flex',
          flexDirection: type === 'update' ? 'row' : 'column',
          gap: type === 'update' ? '20px' : '0px'
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
          <div>
            <div className={styles.flexColumn} style={{ marginTop: '20px' }}>
              <SubHeading className={styles.heading}>
                {lang.label_category}
                {lang.label_en}
                <span className={styles.redSteric}> *</span>
              </SubHeading>
              <InputField
                extraStyle={{
                  width: '100%'
                }}
                name="categoryName"
                value={formData.categoryName}
                onChange={handleInputChange}
                type="text"
              />
              {errors?.nameError && <p className="error">{errors?.nameError}</p>}
            </div>
            <div className={styles.flexColumn} style={{ marginTop: '20px' }}>
              <SubHeading className={styles.heading}>
                {lang.label_category}
                {lang.label_vn}
                <span className={styles.redSteric}></span>
              </SubHeading>
              <InputField
                extraStyle={{
                  width: '100%'
                }}
                name="categoryNameVt"
                value={formData.categoryNameVt}
                onChange={handleInputChange}
                type="text"
              />
            </div>
            <div className={styles.flexColumn} style={{ marginTop: '20px' }}>
              <SubHeading className={styles.heading}>
                {lang.label_category}
                {lang.label_tl}
                <span className={styles.redSteric}></span>
              </SubHeading>
              <InputField
                extraStyle={{
                  width: '100%'
                }}
                name="categoryNameTl"
                value={formData.categoryNameTl}
                onChange={handleInputChange}
                type="text"
              />
            </div>
            <div className={styles.flexColumn} style={{ marginTop: '20px' }}>
              <SubHeading className={styles.heading}>
                {lang.label_category}
                {lang.label_bn}
                <span className={styles.redSteric}></span>
              </SubHeading>
              <InputField
                extraStyle={{
                  width: '100%'
                }}
                name="categoryNameBn"
                value={formData.categoryNameBn}
                onChange={handleInputChange}
                type="text"
              />
            </div>

            <div className={styles.flexColumn} style={{ marginTop: '20px' }}>
              <SubHeading className={styles.heading}>
                {lang.label_parent_category_name}
                {/* <span className={styles.redSteric}> *</span> */}
              </SubHeading>
              <SelectBox
                name="parent_category_id"
                value={formData?.parent_category_id}
                onChange={handleSelectChange}
                data={categories}
                extraStyle={{
                  width: '100%',
                  height: '40px'
                }}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <UploadWithLabel
                name="game_categories"
                label={lang.label_icon + ' (en)'}
                initialImageUrl={formData?.icon || null}
                onChange={(val) => setFormData({ ...formData, icon: val })}
              />

              <UploadWithLabel
                name="game_categories"
                label={lang.label_iconactive + ' (en)'}
                initialImageUrl={formData?.iconactive || null}
                onChange={(val) => setFormData({ ...formData, iconactive: val })}
              />
              <SwitchWithLabel
                label={`${lang.label_is_lobby}:`}
                value={formData.is_lobby ? 1 : 0}
                onChange={(checked) => setFormData({ ...formData, is_lobby: checked ? 1 : 0 })}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <UploadWithLabel
                name="game_categories"
                label={lang.label_icon_trend}
                initialImageUrl={formData?.icon_trend || null}
                onChange={(val) => setFormData({ ...formData, icon_trend: val })}
              />
              <UploadWithLabel
                name="game_categories"
                label={lang.label_icon_bgImg}
                initialImageUrl={formData?.bg_image || null}
                onChange={(val) => setFormData({ ...formData, bg_image: val })}
              />
            </div>
            <div className={styles.flexColumn} style={{ marginTop: '20px' }}>
              <SubHeading className={styles.heading}>
                {lang.label_game_properties}
                <span className={styles.redSteric}> *</span>
              </SubHeading>
              <MultiSelectComp
                name="properties"
                options={gameProperties.filter((properties) => properties.value !== 0)}
                extraStyle={{
                  width: '100%',
                  height: '40px'
                }}
                type={type === 'update' ? 'update' : ''}
                value={formData?.properties}
                handleChange={handleMulltiSelectChange}
              />
            </div>
            <div className={styles.flexColumn} style={{ marginTop: '20px' }}>
              <SubHeading className={styles.heading}>
                {lang.label_status}
                <span className={styles.redSteric}> *</span>
              </SubHeading>
              <SelectBox
                name="status"
                value={formData?.status}
                onChange={handleSelectChange}
                data={status}
                extraStyle={{
                  width: '100%',
                  height: '40px'
                }}
              />
            </div>
          </div>
          <div>
            <div
              className={styles.flexStyle}
              style={{
                marginTop: '30px',
                justifyContent: 'flex-end',
                float: 'right'
              }}>
              {submitLoading ? (
                <Loader />
              ) : (
                <CustomButton
                  type={styles.mainBg}
                  onClick={type === 'update' ? updateFunc : submitFunc}
                  text={type === 'update' ? lang.label_update : lang.label_submit}
                />
              )}
              <CustomButton type={styles.whiteBg} onClick={handleCloseModal} text={lang.label_cancel} />
            </div>
          </div>
        </div>
        {type !== 'update' ? (
          <></>
        ) : (
          <div style={{ width: '100%' }}>
            {loading ? (
              <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Loader />
              </div>
            ) : (
              <DragTableComp
                dataSource={data}
                columns={columns}
                setData={setData}
                sortingfunc={sortingGameCategorySingleGame}
                game_category={item?.categoryId}
                loading={loading}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateModal;
