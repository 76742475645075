import { Button, message } from "antd";
import LangContext from "../../../helps/contexts/lang-context";
import { useContext, useState } from "react";
import PutApi from "../../../helps/contexts/Api/PutApi";

const ToggleDeposit = ({ handleCancel, rowData, fetchCompanyBanks }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const [deposit, setDeposit] = useState(
    rowData && rowData.deposit ? rowData.deposit : 1
  );
  const id = rowData.key;
  //on form submit request to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      const path = `/company/banks/${id}/deposit/toggle`;
      // sending request
      const res = await PutApi.PutApiRequest(path, {
        deposit: deposit,
      });

      //if success
      if (res.status == true) {
        handleCancel();
        message.success(lang.label_depositsuccess);
        fetchCompanyBanks();
      } else {
        message.success(lang.label_depositfail);
      }
    } catch (e) {
      var message1 = e.message;

      alert(message1);
    }
  };
  return (
    <div
      style={{
        width: "97%",
        height: "70px",
        borderRadius: "3px",
        justifyContent: "space-evenly",
      }}
    >
      <p>{lang.label_depositconfirm}</p>
      <form onSubmit={onSubmit}>
        <div style={{}}>
          {" "}
          <Button
            style={{
              borderRadius: "3px",
              backgroundColor: "green",
              color: "white",
              position: "absolute",
              right: "25%",
              top: "70%",
              bottom: "10%",
            }}
            htmlType="submit"
          >
            {rowData.deposit === 1
              ? lang.label_deactivate
              : lang.label_activate}
          </Button>
          <Button
            style={{
              borderRadius: "3px",

              color: "black",
              position: "absolute",
              right: "5%",
              top: "70%",
              bottom: "10%",
            }}
            onClick={handleCancel}
          >
            {lang.label_cancel}
          </Button>
        </div>
      </form>
    </div>
  );
};
export default ToggleDeposit;
