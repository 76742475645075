import { useContext, useEffect, useState } from 'react';
import styles from './ProgressSection.module.css';
import { Col, Image } from 'antd';
import ChartImg from '../../../images/HomePage/Chart.png';
import GetApi from '../../../helps/contexts/Api/GetApi';
import { Progress } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { toFixedFunc } from '../../../helps/HelperFunctions';

const winLoseOptions = [
  { lable: 'Win/Loss This Month', value: '-1557 K' },
  { lable: `Total Member's Loss Limit`, value: '-3040 K' },
  { lable: 'Loading Progress', value: '21%' }
];
const conicColors = { '0%': '#87d068', '50%': '#ffe58f', '100%': '#ffccc7' };
const purchasedAmount = 3000;

const ProgressSection = () => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const [percentage, setPercentage] = useState(0);
  const [winloss, setWinLoss] = useState(0);
  const [totalMemberLossLimit, setTotalMemberLossLimit] = useState(0);

  const getWinLossPerMonth = async () => {
    const res = await GetApi.sendApiRequest('/bet_rounds/monthly/winloss');
    if (res && res.status) {
      setWinLoss(res.total_winloss ? toFixedFunc(res.total_winloss, process.env.REACT_APP_Decimel_Points) : 0);
      const remaining = purchasedAmount - Math.abs(res.total_winloss);
      setPercentage((remaining / 3000) * 100);
    }
  };

  useEffect(() => {
    getWinLossPerMonth();
  }, []);

  // add comma
  const addCommasToNumber = (val) => {
    return val?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const removeCommasFromNumber = (val) => {
    if (typeof val === 'string') {
      return parseFloat(val.replace(/,/g, ''));
    }
    return val;
  };

  return (
    <Col span={8}>
      <div className={styles.winLostSectionWrapper}>
        <div className={styles.winLostSection}>
          <div className={styles.winLose}>
            <div
              style={{
                textAlign: 'center'
              }}>
              {lang.label_winloss_this_month}
            </div>
            <div
              style={{
                textAlign: 'center'
              }}>
              {addCommasToNumber(winloss)}
            </div>
          </div>
          <div className={styles.winLose}>
            <div
              style={{
                textAlign: 'center'
              }}>
              {lang.label_total_winloss_purchases}
            </div>
            <div
              style={{
                textAlign: 'center'
              }}>
              {addCommasToNumber(purchasedAmount)}
            </div>
          </div>
          <div className={styles.winLose}>
            <div
              style={{
                textAlign: 'center'
              }}>
              {lang.label_winloss_remaining}
            </div>
            <div
              style={{
                textAlign: 'center'
              }}>
              {addCommasToNumber(parseInt(purchasedAmount) - Math.abs(winloss ? removeCommasFromNumber(winloss) : ''))}
            </div>
          </div>
        </div>
        <div className={styles.chartImgWrapper}>
          <Image alt="img" src={ChartImg} preview={false} />
        </div>
        <Progress
          percent={percentage}
          showInfo={false}
          status={percentage <= 35 ? 'exception' : percentage <= 75 ? 'active' : 'success'}
        />
      </div>
    </Col>
  );
};

export default ProgressSection;
