import AgentListTable from './AgentListTable';
import { Card, Row, Col, Button, Input, Pagination, Modal, message } from 'antd';

import { useContext, useState, useEffect } from 'react';
import LangContext from '../../../helps/contexts/lang-context';

import GetApi from '../../../helps/contexts/Api/GetApi';
import '../../../styles/ButtonStyle.css';
import AddAgentForm from './AddAgentForm';
import { useNavigate } from 'react-router-dom';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import Spacer from '../../../components/HelperComponents/Spacer';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import { TabContext } from '../../../helps/contexts/TabContext';
import SearchIcon from "../../../images/Search.png";
import ResetIcon from "../../../images/Reset.png";

const AgentList = () => {
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();

  const lang = ctx.lang;
  const nav = useNavigate();
  //styles

  const inputStyle = {
    width: '250px',
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };

  //states

  const [searchtext, setSearchtext] = useState('');
  const [searchSup, setSearchSup] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [Data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isadd, setIsadd] = useState(false);
  const { path } = useContext(TabContext);

  //onreset
  const onReset = async () => {
    setActivepage1(1);
    setLoading(true);
    setSearchSup('');
    setSearchtext('');
    const path = '/admin/agent/get/all';
    const params = { page: 1 };
    setLoading(true);
    //sending api request
    const res = await GetApi.sendApiRequest(path, null, params);
    if (res?.data && res?.data?.length) {
      const finalArray = res?.data?.map((agent, index) => ({
        key: index,
        id: agent.id,
        currency: agent && agent?.currency && agent?.currency,
        name: agent && agent.user && agent.user.name ? agent.user.name : '',
        username: agent && agent.user && agent.user.user_name ? agent.user.user_name : '',
        phone: agent && agent.user && agent.user.phone ? agent.user.phone : '',
        created_by_admin: agent?.user?.latest_login_history?.created_by_admin,
        superioragent:
          agent && agent.senior_agent && agent.senior_agent.user && agent.senior_agent.user.user_name
            ? agent.senior_agent.user.user_name
            : '',
        uniquecode: agent && agent.unique_code ? agent.unique_code : '',
        loginip:
          agent && agent.user && agent.user.latest_login_history && agent.user.latest_login_history.ip
            ? agent.user.latest_login_history.ip
            : '-',
        logintime: agent && agent.user && agent.user.latest_login_history && agent.user.latest_login_history.created_at,
        registerip:
          agent && agent.user && agent.user.signup_history && agent.user.signup_history.ip
            ? agent.user.signup_history.ip
            : '-',
        registertime: agent && agent.user && agent.user.signup_history && agent.user.signup_history.created_at
      }));
      setData(finalArray);
      setCurrentpage(res?.current_page);
      setTotal(res?.total);
      setPerpage(res?.per_page);
      setNextPage(res?.next_page_url);
    } else {
      setData([]);
      setTotal(0);
    }

    setLoading(false);
  };

  //status dropdown
  //on submit of filters on table values
  const onSubmit = (e) => {
    e.preventDefault();
    setActivepage1(1);
    fetchAgents();
  };
  // on click of a page in pagination component

  const onChangepage = (page) => {
    setActivepage1(page);
    fetchAgents(page);
  };
  const showModal = () => {
    setIsadd(true);
    setVisible(true);
  };

  const handleCancel = (e) => {
    setIsadd(false);
    setVisible(false);
  };

  // Fetching api
  const fetchAgents = async (customPage = null) => {
    try {
      const path = '/admin/agent/get/all';
      const params = {};
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      if (searchtext) params['search'] = searchtext;
      if (searchSup) params['senior_agent'] = searchSup;

      setLoading(true);
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      // console.log(res)
      if (res?.data && res?.data?.length) {
        const finalArray = res?.data?.map((agent, index) => ({
          key: index,
          id: agent.id,
          user_id: agent.user_id,
          currency: agent && agent?.currency && agent?.currency,
          name: agent && agent.user && agent.user.name ? agent.user.name : '',
          username: agent && agent.user && agent.user.user_name ? agent.user.user_name : '',
          phone: agent && agent.user && agent.user.phone ? agent.user.phone : '',
          created_by_admin: agent?.user?.latest_login_history?.created_by_admin,
          superioragent:
            agent && agent.senior_agent && agent.senior_agent.user && agent.senior_agent.user.user_name
              ? agent.senior_agent.user.user_name
              : '',
          uniquecode: agent && agent.unique_code ? agent.unique_code : '',
          loginip:
            agent && agent.user && agent.user.latest_login_history && agent.user.latest_login_history.ip
              ? agent.user.latest_login_history.ip
              : '-',
          logintime:
            agent && agent.user && agent.user.latest_login_history && agent.user.latest_login_history.created_at,
          registerip:
            agent && agent.user && agent.user.signup_history && agent.user.signup_history.ip
              ? agent.user.signup_history.ip
              : '-',
          registertime: agent && agent.user && agent.user.signup_history && agent.user.signup_history.created_at,
          agent_type: agent && agent.agent_type ? agent.agent_type : '',
          agent_type_name: agent && agent.agent_type_name ? agent.agent_type_name : '',
          balance: agent && agent.wallet ? agent.wallet.balance : '',
          credit_line: agent && agent.credit_wallet ? agent.credit_wallet.credit_balance : '',
          max_credit: agent && agent.credit_wallet ? agent.credit_wallet.max_limit : ''
        }));
        setData(finalArray);
        setCurrentpage(res?.current_page);
        setTotal(res?.total);
        setPerpage(res?.per_page);
        setNextPage(res?.next_page_url);
      } else {
        setData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }

      Object.values(e.data.message).map((errors) => message.error(errors));
    }
  };
  useEffect(() => {
    fetchAgents();
  }, []);

  useEffect(() => {
    if (path === '/agent-management/agent-list') {
      fetchAgents();
    }
  }, [path]);

  return (
    <div>
      <HeaderCardDetails />

      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div>
          <Card
            title={lang.label_filters}
            style={{
              borderRadius: '3px'
            }}>
            <div>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center'
                }}>
                <Col
                  span={18}
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <label style={{ marginRight: '1rem' }}>
                    {lang.label_username}/ {lang.label_uniquecode}
                  </label>
                  <Input
                    style={inputStyle}
                    onChange={(e) => {
                      setSearchtext(e.target.value);
                    }}
                    name="search"
                    type="text"
                    value={searchtext}
                  />
                  <label style={{ marginLeft: '0.5rem', marginRight: '1rem' }}>Superior Agent</label>
                  <Input
                    style={inputStyle}
                    onChange={(e) => {
                      setSearchSup(e.target.value);
                    }}
                    name="search"
                    type="text"
                    value={searchSup}
                  />

                  <Button
                    type="secondary"
                    style={{ margin: '0.6rem', width: '15%' , display : 'flex',
                      alignItems : 'center',
                      justifyContent : 'space-evenly'}}
                    htmlType="submit"
                    className="btnStyle"
                    onClick={() => {
                      setActivepage1(1);
                      setLoading(true);
                    }}>
                      <img src={SearchIcon} alt='search' />
                    {lang.label_search}
                  </Button>

                  <Button className="btnReset" htmlType="reset" onClick={onReset}
                  style={{
                    display : 'flex',
                alignItems : 'center',
                justifyContent : 'space-evenly',
                  }}>
                     <img src={ResetIcon} alt='reset' />
                    {lang.label_reset}
                  </Button>
                </Col>

                <Col
                  span={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                  }}>
                  {hasPermission('Create Agent') && (
                    <Button type="secondary" className="btnStyle" onClick={showModal}>
                      {lang.label_createagent}
                    </Button>
                  )}

                  <div>
                    {isadd && isadd ? (
                      <Modal
                        title="Add Agent"
                        open={visible}
                        footer={null}
                        width={700}
                        closable={true}
                        onCancel={handleCancel}>
                        <AddAgentForm handleCancel={handleCancel} data={Data} fetchAgents={fetchAgents} />
                      </Modal>
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>

          <Spacer />
          <AgentListTable data={Data} fetchAgents={fetchAgents} loading={loading} />
          <Spacer />
          {/* Pagination */}
          {Data?.length > 0 && (
            <Pagination
              defaultCurrent={1}
              current={activepage}
              onChange={onChangepage}
              total={total}
              responsive={true}
              pageSize={perpage}
            />
          )}
        </div>
      </form>
    </div>
  );
};
export default AgentList;
