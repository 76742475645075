import { useContext, useState, useEffect } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import { CalendarOutlined } from '@ant-design/icons';
import { Navigate, useNavigate } from 'react-router-dom';
import { Card, Row, Col, DatePicker, Button, Input, Select, Pagination, message } from 'antd';
import GetApi from '../../../helps/contexts/Api/GetApi';
import GameTransactionHistoryTable from './GameTransactionHistoryTable';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import Spacer from '../../../components/HelperComponents/Spacer';
import PaginationComp from '../../../components/HelperComponents/PaginationComp';
import { convertLocalTimeToUTC } from '../../../helps/HelperFunctions';
import { TabContext } from '../../../helps/contexts/TabContext';
import Heading from '../../../components/HelperComponents/Heading';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import styles from "../components/Player.module.css"
import InputField from '../../../components/HelperComponents/InputField';
import SearchIcon from "../../../images/Search.png";
import ResetIcon from "../../../images/Reset.png";
import dayjs from 'dayjs';

const GameTransactionHistory = () => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const nav = useNavigate();
  //styles
  const datePickerStyle = {
    width: '190px',
    height: '35px',
    background: ' #FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const iconStyle = { color: '#004A7F' };
  const inputStyle = {
    width: '190px',
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const selectStyle = {
    width: '190px',
    height: '33px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '5px !important'
  };
  //states

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [playername, setplayername] = useState('');
  const [startTimeParam, setStartTimeParam] = useState('');
  const [endTimeParam, setEndTimeParam] = useState('');

  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [Data, setData] = useState([]);
  const [startpoints, setstartpoints] = useState();
  const [endpoints, setendpoints] = useState();
  const { path } = useContext(TabContext);
  console.log(path);
  useEffect(() => {
    fetchhistory();
  }, []);

  useEffect(() => {
    if (path === '/player-management/game-transaction-history') {
      fetchhistory();
    }
  }, [path]);

  const onChangepage = (page) => {
    setActivepage1(page);
    fetchhistory(page);
  };
  const paginationConfig = {
    current: currentpage,
    pageSize: perpage ? perpage : 0,
    total: total ? total : 0,
    showSizeChanger: false,
    showQuickJumper: false,
    showTotal: (total) => `Total ${total} items`,
    onChange: onChangepage
  };
  const [status, setStatus] = useState();
  //status dropdown
  const handleChange = (value) => {
    setStatus(value);
  };
  //date picker startdate
  const onChange = (value, dateString) => {
    setStartDate(value);
    setStartTimeParam(dateString);
  };
  //date picker start date completed
  //date picker end date

  const onChange1 = (value, dateString) => {
    setEndDate(value);
    setEndTimeParam(dateString);
  };

  const onReset = async () => {
    setLoading(true);

    setStartDate('');
    setEndDate('');
    setplayername('');
    setstartpoints('');
    setendpoints('');
    setStatus();
    const path = '/admin/players/game/transaction/history';
    const params = {};
    setLoading(true);

    //setting parameters to send for search results
    try {
      //sending api request
      const res = await GetApi.sendApiRequest(path, null, null);

      if (res && res.data && res.data.length) {
        const finalArray = res.data.map((history, index) => {
          return {
            key: index,
            id: history?.player.id ? history?.player.id : '',
            playername: history.player.user.user_name,
            points: history.points,
            gamename: history.points,
            remark: history.remark,
            status: history.status,
            type: history.type,
            date: history.created_at,
            actionby: history.action_by
          };
        });
        setData(finalArray);
        setCurrentpage(res.current_page);
        setTotal(res.total);
        setPerpage(res.per_page);
        setNextPage(res.next_page_url);
      } else {
        setData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
      message.error('error is' + e.data.message);
    }
  };

  //on submit of filters on table values
  const onSubmit = (e) => {
    e.preventDefault();
    setActivepage1(1);
    fetchhistory();
  };
  // Fetching api
  const fetchhistory = async (customPage = null) => {
    try {
      const path = '/admin/players/game/transaction/history';
      const params = {};
      setLoading(true);

      //setting parameters to send for search results

      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      if (startDate) params['from_date'] = convertLocalTimeToUTC(startTimeParam);
      if (endDate) params['to_date'] = convertLocalTimeToUTC(endTimeParam);
      if (status) params['type'] = status;
      if (playername) params['user_name'] = playername;
      if (startpoints) params['min_points'] = startpoints;
      if (endpoints) params['max_points'] = endpoints;

      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);

      if (res && res.data && res.data.length) {
        const finalArray = res.data.map((history, index) => {
          return {
            key: index,
            id: history?.player.id ? history?.player.id : '',
            playername: history.player.user.user_name,
            points: history.points,
            beforepoints: history.before_points,
            afterpoints: history.after_points,
            gamename: JSON.parse(history.game_item.name)['en'],
            remark: history.remark,
            status: history.status,
            type: history.type,
            date: history.created_at,
            actionby: history.action_by
          };
        });
        setData(finalArray);
        setCurrentpage(res.current_page);
        setTotal(res.total);
        setPerpage(res.per_page);
        setNextPage(res.next_page_url);
      } else {
        setData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
      message.error('error is' + e.data.message);
    }
  };

  return (
    <>
      <HeaderCardDetails />

      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div>
          
        <div className={styles.container}>
            <div
              className={styles.filterUpperContainer}
              style={{
                display: 'flex',
                alignItems :'center',
                justifyContent : 'space-between',
                borderRadius: '3px'
              }}>
              <Heading>{lang.label_filters}</Heading>
            </div>

            <div className={styles.filterLowerContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  width: '100%',
                  paddingRight: '14px',
                  flexWrap: 'wrap'
                }}>
                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_time}
                  </SubHeading>
                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-around',
                      alignItems: 'center'
                    }}>
                    <DatePicker
                      //getting input value for start date
                      placeholder="Select Time"
                      showTime ={{
                        defaultValue :dayjs('00:00:00', 'HH:mm:ss'),
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      onChange={onChange}
                      value={startDate}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    />
                    <span
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        marginLeft: '1%',
                        marginRight: '1%'
                      }}>
                      -
                    </span>
                    <DatePicker
                      //getting input value for start date
                      placeholder="Select Time"
                      showTime ={{
                        defaultValue :dayjs('23:59:59', 'HH:mm:ss'),
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      // onChange={onChange1}
                      // value={endDate}
                      value={endDate}
                      onChange={onChange1}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    />
                  </div>
                </div>

                <div className={styles.flexStyle}>
                    <SubHeading extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                      {lang.label_points}
                    </SubHeading>
                    <div  style={{
                      display: 'flex',
                      // justifyContent: 'space-around',
                      alignItems: 'center',
                      // width : "300px"
                    }}>
                     <InputField
                      placeholder="Input Amount Range"
                      type="number"
                      // name="minAmmount"
                      value={startpoints}
                      onChange={(e) => {
                        setstartpoints(e.target.value);
                      }}
                    />
                     <span
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        marginLeft: '1%',
                        marginRight: '1%'
                      }}>
                      -
                    </span>
                    <InputField
                      placeholder="Input Amount Range"
                      type="number"
                      // name="maxAmmount"
                      onChange={(e) => {
                        setendpoints(e.target.value);
                      }}
                      value={endpoints}
                    />
                    </div>
                    
                  </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                     {lang.label_playername}
                  </SubHeading>

                  <Input
                    // style={inputStyle}
                    placeholder="Enter Player name"
                    name="search"
                    // type="text"
                    onChange={(e) => {
                      setplayername(e.target.value);
                    }}
                    value={playername}
                  />
                </div>

                  <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_type}
                  </SubHeading>
                  <div>
                    <Select
                      value={status}
                      defaultValue={'--Select Transaction Type---'}
                      // style={selectStyle}
                      onChange={handleChange}
                      style={{ width: '200px' }}
                      customWidth="200px"
                      extraStyle={{ height: '35px' }}
                      options={[
                        {
                          value: '1',
                          label: 'Withdraw'
                        },
                        {
                          value: '0',
                          label: 'Deposit'
                        }
                      ]}
                    />
                  </div>
                </div>
                    
                {/* <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                   {lang.label_ignoretestaccount}
                  </SubHeading>
                  <div>
                    <Select
                      // value={type}
                      defaultValue="Yes"
                      // style={selectStyle}
                      style={{ width: '200px' }}
                      onChange={(value) => {
                        setIgnoreTestAccount(value);
                      }}
                      customWidth="200px"
                      extraStyle={{ height: '35px' }}
                      options={[
                        {
                          value: 1,
                          label: lang.label_yes
                        },
                        {
                          value: '0',
                          label: lang.label_no
                        }
                      ]}
                    />
                  </div>
                </div> */}

                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    marginTop: '20px',
                    marginLeft: '10px',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                  }}>
                  <Button
                    style={{
                      display : 'flex',
                      alignItems : 'center',
                      justifyContent : 'space-evenly',
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1'
                    }}
                    htmlType="submit"
                    className="btnStyle"
                    // type="primary"
                    >
                    <img src={SearchIcon} alt='search' />
                    {lang.label_search}
                  </Button>

                  <Button
                    style={{
                      display : 'flex',
                      alignItems : 'center',
                      justifyContent : 'space-evenly',
                      borderRadius: '3px',
                      padding: '6px',
                      border: '1px solid grey',
                      width: '84px'
                    }}
                    className="btnReset"
                    htmlType="reset"
                    onClick={onReset}>
                      <img src={ResetIcon} alt='reset' />
                    {lang.label_reset}
                  </Button>
                </div>
              </div>
            </div>
          </div>


          <Spacer />
          <Spacer />
          <GameTransactionHistoryTable data={Data} loading={loading} />
          <Spacer />
          <PaginationComp paginationConfig={paginationConfig} />
        </div>
      </form>
    </>
  );
};
export default GameTransactionHistory;
