import { Table } from 'antd';
import AddCommasAndLength from '../../../../helps/AddCommasAndLength';
import StatusChip from '../../../../components/HelperComponents/StatusChip';
import { convertUTCToLocalTime } from '../../../../helps/HelperFunctions';

const BetDetailsTable = ({data, loading}) => {

  // game name function
  const gameName = (item) => {
    const jsonString = JSON.parse(item.name);
    const englishName = jsonString["en"];
    return englishName
  }
  
  // bet status
  const betStatus = (status) => {
    if(status === 'Settled') {
      return <StatusChip color= '#148F14' chipBkg='#E7F9E7' text = 'Settled'/> 
    } else if (status === 'Unsettled') {
      return <StatusChip color= '#707070' chipBkg='#F1F1F1' text = 'Unsettled'/>
    } else if (status === 'Resettled') {
      return <StatusChip color= '#004A7F' chipBkg='#E3EFF8' text = 'Resettled'/>
    } else if (status === 'Canceled') {
      return <StatusChip color= '#FF0000' chipBkg='#FFE9E9' text = 'Canceled'/>
    }
  }

  const columns = [
    {
      title: "Game",
      dataIndex: "game_item",
      key: "game_item",
      render: (item) => <span>{gameName(item)}</span>,
    },
    {
      title: "Reference ID",
      dataIndex: "bet_reference",
      key: "bet_reference",
    },
    {
      title: "Start Time",
      dataIndex: "bet_on",
      key: "bet_on",
      render: (time) => <span>{convertUTCToLocalTime(time)}</span>,
    },
    {
      title: "End Time",
      dataIndex: "closed_on",
      key: "closed_on",
      render: (time) => <span>{convertUTCToLocalTime(time)}</span>,
    
    },
    {
      title: "Status",
      dataIndex: "status_name",
      key: "status_name",
      render: (item) => betStatus(item)
    },
    {
      title: "Currency",
      dataIndex: "currency_name",
      key: "currency_name",
    },
    {
      title: "Bet Amount",
      dataIndex: "bet_amount",
      key: "bet_amount",
      render: (item) => <span style={{color: `${item <= 10 ? 'red' : ''}`}}>{AddCommasAndLength(item)}</span>
    },
    {
      title: "Valid Bet",
      dataIndex: "valid_bet",
      key: "valid_bet",
      render: (item) => <span style={{color: `${item <= 10 ? 'red' : ''}`}}>{AddCommasAndLength(item)}</span>
    },
    {
      title: "Turn Over",
      dataIndex: "turnover",
      key: "turnover",
      render: (item) => <span style={{color: `${item <= 10 ? 'red' : ''}`}}>{AddCommasAndLength(item)}</span>
    },
    {
      title: "Win/Loss",
      dataIndex: "win_loss",
      key: "win_loss",
      render: (item) => <span style={{ color: `${item <= 10 ? 'red' : ''}`}}>{ item ? AddCommasAndLength(item) : <span style={{color: '#858585'}}>-</span>} </span>
    },
    {
      title: "Win Amount",
      dataIndex: "win_amount",
      key: "win_amount",
      render: (item) => <span style={{ color: `${item <= 10 ? 'red' : ''}`}}>{ item ? AddCommasAndLength(item) : <span style={{color: '#858585'}}>-</span>} </span>
    },
    {
      title: "Re-Bate",
      dataIndex: "rebate",
      key: "rebate",
      render: (item) => <span>{ item ? item : '-'}</span>
    },
    {
      title: "Commission",
      dataIndex: "comm",
      key: "comm",
      render: (item) => <span>{ item ? item : '-'}</span>
    }
  ];

  // Table Summary Func 
  const summaryTable = (tableData) => {
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell>Total:</Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell></Table.Summary.Cell>
          <Table.Summary.Cell>{AddCommasAndLength(tableData.reduce((acc, item) => acc + Number(item.bet_amount), 0))}</Table.Summary.Cell>
          <Table.Summary.Cell>{AddCommasAndLength(tableData.reduce((acc, item) => acc + Number(item.valid_bet), 0))}</Table.Summary.Cell>
          <Table.Summary.Cell>{AddCommasAndLength(tableData.reduce((acc, item) => acc + Number(item.turnover), 0))}</Table.Summary.Cell>
          <Table.Summary.Cell>{AddCommasAndLength(tableData.reduce((acc, item) => acc + Number(item.win_loss), 0))}</Table.Summary.Cell>
          <Table.Summary.Cell>{AddCommasAndLength(tableData.reduce((acc, item) => acc + Number(item.win_amount), 0))}</Table.Summary.Cell>
          <Table.Summary.Cell>{AddCommasAndLength(tableData.reduce((acc, item) => acc + Number(item.rebate), 0))}</Table.Summary.Cell>
          <Table.Summary.Cell>{AddCommasAndLength(tableData.reduce((acc, item) => acc + Number(item.comm), 0))}</Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  }
  
  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={data}
      pagination={false}
      size='small'
      bordered
      summary={(tableData) => summaryTable(tableData)}
    />
  )
}

export default BetDetailsTable