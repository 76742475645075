import React, { useEffect, useRef, useState } from 'react';
import NotificationContext from './Notification-Context';
import { getTransactionsCount } from './Api/api';

const NotificationProvider = (props) => {
const [depositCounter ,setDepositcounter] = useState( localStorage.getItem('depositCount') ?  localStorage.getItem('depositCount') : 0)
const [withdrawCounter ,setWithdrawCounter] = useState( localStorage.getItem('withdrawCount') ?  localStorage.getItem('withdrawCount') : 0)
const [userCounter ,setUsercounter] = useState(0)
const [listnerRiskAprove ,setListnerRiskAprove] = useState(false)
const [listnerFinanceAprove ,setListnerFinanceAprove] = useState(false)
const [transactionObject ,setTransactionObject] = useState(null)
  const [transactionType, setTransactionType] = useState()
const [depositMessageArray ,setDepositMessageArray] = useState( JSON.parse(localStorage.getItem('depositArray')) ?  JSON.parse(localStorage.getItem('depositArray')) : [])
const [withdrawMessageArray ,setWithdrawMessageArray] = useState(JSON.parse(localStorage.getItem('withdrawArray')) ? JSON.parse(localStorage.getItem('withdrawArray')) : [])
const [userMessageArray ,setUserMessageArray] = useState([])
  const depositCounterRef = useRef(depositCounter);
  const withdrawCounterRef = useRef(withdrawCounter);

  // Update refs whenever state changes
  useEffect(() => {
    depositCounterRef.current = depositCounter;
    withdrawCounterRef.current = withdrawCounter;
  }, [depositCounter, withdrawCounter]);

      const counterIncreaseFunc = (value)=>{
          if(value === 'deposit'){	
            localStorage.setItem("depositCount", depositCounterRef.current + 1);
            setDepositcounter(depositCounterRef.current + 1)
      }else{
            localStorage.setItem("withdrawCount", withdrawCounterRef.current + 1);
            setWithdrawCounter(withdrawCounterRef.current + 1)
      }
    }
        const counterDecreaseFunc = (value) => {
          if (value === 'deposit') {
            localStorage.setItem("depositCount", depositCounterRef.current - 1);
            setDepositcounter(depositCounterRef.current - 1)

          } else {
            localStorage.setItem("withdrawCount", withdrawCounterRef.current - 1);
            setWithdrawCounter(withdrawCounterRef.current - 1)
          }
        }
    const NotiValues = {
        depositCounter,
        setDepositcounter,
        withdrawCounter,
        setWithdrawCounter,
        userCounter,
        setUsercounter,
        depositMessageArray,
        setDepositMessageArray,
        withdrawMessageArray,
        setWithdrawMessageArray,
        userMessageArray,
        setUserMessageArray,
        listnerRiskAprove,
        setListnerRiskAprove,
        listnerFinanceAprove,
        setListnerFinanceAprove,
        transactionObject,
        setTransactionObject,
      counterIncreaseFunc,
      counterDecreaseFunc,
      withdrawCounterRef,
      depositCounterRef,
      transactionType,
      setTransactionType
     }

const getPendingTransactionsCount = async () => {
  try {
    const payload = {
      token: localStorage.getItem("token"),    
    };
    var res = await getTransactionsCount(payload);
    if (res && res?.status === 200) {
      localStorage.setItem('depositCount', Number(res?.data?.pending_deposit_count) )
      setDepositcounter(Number(res?.data?.pending_deposit_count))
      localStorage.setItem('withdrawCount', Number(res?.data?.pending_withdraw_count))
      setWithdrawCounter(Number(res?.data?.pending_withdraw_count))
      }
  } catch (error) {
   
  }
};

useEffect(() => {
  getPendingTransactionsCount();
}, []);
    return (
        <NotificationContext.Provider value={NotiValues}>
            {props.children}
        </NotificationContext.Provider>
    )
}

export default NotificationProvider;