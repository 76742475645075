import React, { useContext, useState, useEffect, useRef } from 'react';
import { Button, Select, Tooltip, message, Spin, Modal } from 'antd';
import LangContext from '../../../../helps/contexts/lang-context';
import GetApi from '../../../../helps/contexts/Api/GetApi';
import PostApi from '../../../../helps/contexts/Api/PostApi';
import DuplicatePlayerList from './DuplicatePlayerList';

const { Option } = Select;

const NewPlayer = ({ ID, onRefresh, onCancel }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileSelection, setFileSelection] = useState(false);
  const [optSelection, setOptSelection] = useState(false);
  const fileInputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [players, setPlayers] = useState({});
  const [selectedPlayerIds, setSelectedPlayerIds] = useState([]);
  const [duplicateID, setDuplicateID] = useState([]);
  const [duplicate, setDuplicate] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileSelection(true);
      setSelectedFile(file);
    }
  };

  const handleUploadButtonClick = () => {
    setSelectedPlayerIds([]);
    setPlayers([]);
    if (fileInputRef.current) {
      setFileSelection(true);
      fileInputRef.current.click();
    }
  };

  const handleCancelDuplicate = () => {
    setDuplicate(false);
    setDuplicateID([]);
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setSelectedPlayerIds([]);
    setPlayers({});
    setFileSelection(false);
    setOptSelection(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  useEffect(() => {
    if (searchTerm) {
      debounceFetchPlayers(searchTerm, page);
    }
    if (selectedFile) {
      setSelectedFile(null);
    }
  }, [searchTerm, page]);


  const handlePopupScroll = (event) => {
    const { target } = event;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleChange = (value) => {
    setSelectedPlayerIds(value);
  };

  const fetchPlayers = async (searchTerm, page=1) => {
    setOptSelection(true);
    setLoading(true);
    if (fileInputRef.current) {
      setFileSelection(false);
      setSelectedFile(null);
      fileInputRef.current.value = null;
    }
    try {
      const path = `/admin/tags/getActivePlayersList?search=${searchTerm}&page=${page}`;
      const response = await GetApi.sendApiRequest(path, {});
      if (response.status) {
        const newPlayers = response?.data || {};
        if(searchTerm){
          setPlayers((prevTags) => {
            const existingTagIds = new Set(prevTags?.data?.map((tag) => tag?.player_id));
            const newTags = newPlayers?.data.filter((tag) => !existingTagIds.has(tag?.player_id));
            return {
              ...prevTags,
              data: [...(prevTags?.data || []), ...newTags]
            };
        });
        setHasMore(response?.data?.current_page < response?.data?.last_page);
      }
      }
    } catch (error) {
      console.error('Failed to fetch players', error);
    } finally {
      setLoading(false);
    }
  };


  

  const handleSearch = (value) => {
    setSearchTerm(value);
    setPage(1); // Reset page to 1 on new search
    debounceFetchPlayers(value, 1); 
  };

  const debounceFetchPlayers = (searchTerm, page=1) => {
    clearTimeout(debounceFetchPlayers.timeout);
    debounceFetchPlayers.timeout = setTimeout(() => fetchPlayers(searchTerm, page), 300);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      let res;
      if (selectedFile) {
        setOptSelection(false);
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('tag_id', ID);
        res = await PostApi.PostApiRequest('/admin/tags/tag-player/bulk-add', formData, {});
      } else {
        if (selectedPlayerIds.length > 0) {
          const playerIdsString = selectedPlayerIds.join(',');
          res = await PostApi.PostApiRequest('/admin/tags/tag-player/add', {
            player_ids: playerIdsString,
            tag_id: ID
          });
        } else {
          message.error('Player ID is null, please add atleast one player ID');
          return; // Exit the function since no player IDs were provided
        }
      }

      if (res && res.status === 200) {
        message.success('Players added successfully');
      } else {
        message.error('Failed to add players');
      }
    } catch (e) {
      if (optSelection && e.status === 400) {
        setDuplicate(true);
        setDuplicateID(e.data.data);
      } else if (selectedFile && e.status === 400) {
        if (e.data.errors.length > 1) {
          message.error(e.data.errors[0]);
          console.log('file-error ', e.data.errors[1]);
          setDuplicate(true);
          setDuplicateID(e.data.errors[1]);
        } else {
          message.error('Duplicate player entry');
          console.log('file-error ', e.data.errors[0]);
          setDuplicate(true);
          setDuplicateID(e.data.errors[0]);
        }
      } else if (e.status === 409) {
        message.error('Duplicate player entry');
      } else if (e.status === 500) {
        message.error('Please check the data and try again');
      } else {
        message.error('An error occurred while adding players');
      }
    } finally {
      setPlayers({})
      setSearchTerm('');
      setPage(1);
      handleCancel();
      onCancel();
      onRefresh();
    }
  };

  return (
    <div style={{ width: '97%', minHeight: '350px', height: '100%', justifyContent: 'space-evenly' }}>
      <form onSubmit={onSubmit}>
        <p style={{ color: 'black' }}>Player ID</p>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Select
            mode="multiple"
            style={{ flex: 1, borderRadius: '3px', marginRight: '10px' }}
            placeholder="Select players"
            // notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={handleChange}
            value={selectedPlayerIds}
            required={!selectedFile}
            onSearch={handleSearch}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onPopupScroll={handlePopupScroll}
            dropdownRender={(menu) => (
            <div>
              {loading ? (
                <div style={{ padding: '8px', textAlign: 'center' }}>
                  <Spin size="small" />
                </div>
              ) : null}
              {menu}
            </div>
          )}>
            {players?.data?.map((player) => (
              <Option key={player.player_id} value={player.player_id}>
                {player.user_name}
              </Option>
            ))}
          </Select>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
            <Button
              type="primary"
              onClick={handleUploadButtonClick}
              style={{ marginLeft: '10px', backgroundColor: '#004A7F', borderRadius: '3px' }}>
              <input
                type="file"
                accept=".txt,.csv"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                ref={fileInputRef}
              />
              {selectedPlayerIds.length > 0
                ? 'Upload File'
                : fileSelection && selectedFile
                ? selectedFile.name
                : 'Upload File'}
            </Button>
            <Tooltip
              color="white"
              title={<span style={{ color: 'black', fontSize: '12px' }}>Upload .txt or .csv files</span>}>
              <img src="../Icon.png" alt="file Options" style={{ marginLeft: '10px', cursor: 'pointer' }} />
            </Tooltip>
          </div>
        </div>

        <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'flex-end', gap: '20px' }}>
          <Button
            style={{
              borderRadius: '3px',
              backgroundColor: '#004A7F',
              color: 'white',
              position: 'absolute',
              right: '25%',
              bottom: '15px'
              // margin: '-1%'
            }}
            htmlType="submit"
            // confirm button disable if not selected player or file
            disabled={selectedPlayerIds.length === 0 && !selectedFile}
            // onClick={() => onRefresh()}
          >
            {lang.label_confirm}
          </Button>

          <Button
            style={{
              borderRadius: '3px',
              color: '#004A7F',
              position: 'absolute',
              // right: '50%',
              bottom: '15px'
              // margin: '-1%'
            }}
            onClick={() => {
              handleCancel();
              onCancel();
            }}>
            {lang.label_cancel}
          </Button>
        </div>
      </form>
      <div>
        <Modal
          centered
          title={`Duplicate ID List`}
          open={duplicate}
          onCancel={handleCancelDuplicate}
          footer={null}
          width={500}
          closable={false}>
          <DuplicatePlayerList duplicateID={duplicateID} handleCancelDuplicate={handleCancelDuplicate} />
        </Modal>
      </div>
    </div>
  );
};

export default NewPlayer;
