
import SubHeading from '../../../components/HelperComponents/SubHeading'
import styles from "./CreateModal/CreateModal.module.css"
import CustomButton from '../../../components/HelperComponents/CustomButton'
import Loader from '../../../components/HelperComponents/Loader'
import LangContext from "../../../helps/contexts/lang-context";
import React, { useContext } from "react";

const DeleteModal = ({ handleCloseModal , submitLoading,deleteFunc}) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  return (
    <div style={{
      marginTop:'25px'
    }}>
      <SubHeading className={styles.heading}>{lang.label_deletegameinfo}</SubHeading>
      <div>
     
     <div className={styles.flexStyle} style={{
       marginTop: "20px",
       justifyContent:'flex-end'
     }}>
        <CustomButton type={styles.deleteCancelBtn}  text={lang.label_cancel} onClick={handleCloseModal} />
    {submitLoading ? <Loader />  : <CustomButton type={styles.deleteBtn} text={lang.label_delete} onClick={deleteFunc} />}
      
     </div>

 </div>
    </div>
  )
}

export default DeleteModal