import { Button, Input, message, Modal, Tabs } from 'antd';
import React, { useContext, useState } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import TextArea from 'antd/es/input/TextArea';
import PutApi from '../../../helps/contexts/Api/PutApi';

export default function UpdateBalanceForm({ updateBalanceOpen, setUpdateBalanceOpen, id, fetchAgents }) {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [tabIndex, setTabIndex] = useState('1');
  const [amount, setAmount] = useState('');
  const [balanceRemark, setBalanceRemark] = useState('');

  const [loading, setLoading] = useState(false);

  const onChange = (key) => {
    setTabIndex(key);
  };

  const items = [
    {
      key: '1',
      label: 'Add Balance',
      children: (
        <div>
          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_balance}
          </div>
          <Input
            style={{
              marginBottom: '10px'
            }}
            value={amount}
            // defaultValue={Number(balance).toFixed(2)}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            type="number"
          />

          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_remark}
          </div>
          <TextArea
            value={balanceRemark}
            style={{ width: '100%', marginBottom: '2rem' }}
            onChange={(e) => {
              setBalanceRemark(e.target.value);
            }}
          />
        </div>
      )
    },
    {
      key: '2',
      label: 'Remove Balance',
      children: (
        <div>
          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_balance}
          </div>
          <Input
            style={{
              marginBottom: '10px'
            }}
            value={amount}
            // defaultValue={Number(balance).toFixed(2)}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            type="number"
          />

          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_remark}
          </div>
          <TextArea
            value={balanceRemark}
            style={{ width: '100%', marginBottom: '2rem' }}
            onChange={(e) => {
              setBalanceRemark(e.target.value);
            }}
          />
        </div>
      )
    }
  ];

  const updateBalance = async () => {
    setLoading(true);
    const path = `/admin/agent/wallet/adjust-balance/${id}`;
    try {
      const res = await PutApi.PutApiRequest(path, {
        amount: amount,
        is_withdrawal: tabIndex === '1' ? false : true,
        remark: balanceRemark
      });

      if (res && res?.status === true) {
        message.success(lang.label_sucessupdatebalance);

        setTabIndex('1');
        setAmount('');
        setBalanceRemark('');
        setUpdateBalanceOpen(false);
        fetchAgents();
      } else {
        message.error(lang.label_updatebalanceerror);
      }
    } catch (error) {
      console.error('Failed to update balance:', error);
      message.error(lang.label_updatebalanceerror);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={lang.label_updatebalance}
      open={updateBalanceOpen}
      onOk={() => {
        setTabIndex('1');
        setAmount('');
        setBalanceRemark('');
        setUpdateBalanceOpen(false);
      }}
      onCancel={() => {
        setTabIndex('1');
        setAmount('');
        setBalanceRemark('');
        setUpdateBalanceOpen(false);
      }}
      footer={null}
      width={500}>
      <Tabs defaultActiveKey={tabIndex} items={items} onChange={onChange} centered />
      <Button
        loading={loading}
        disabled={amount?.length > 0 ? false : true}
        onClick={updateBalance}
        style={{
          float: 'right',
          backgroundColor: '#004A7F',
          color: 'white',
          marginTop: '-1.2rem'
        }}>
        {lang.label_submit}
      </Button>
    </Modal>
  );
}
