import { useContext, useState, useEffect } from 'react';
import LangContext from '../../../../helps/contexts/lang-context';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
const MonthlyTransactionChart = ({ transactionResponse, type }) => {
  const ctx = useContext(LangContext);
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  const [chartData, setChartData] = useState({});
  const [chartOption, setChartOption] = useState({});

  let _ = require('lodash');

  const fillChart = () => {
    const filteredDeposit = transactionResponse.name_of_months?.map((label) =>
      transactionResponse.transaction_data[label]
        ? transactionResponse.transaction_data[label].monthly_deposit_amount
        : '0'
    );

    const filteredWithdrawal = transactionResponse.name_of_months?.map((label) =>
      transactionResponse.transaction_data[label]
        ? transactionResponse.transaction_data[label].monthly_withdraw_amount
        : '0'
    );

    const filteredProfit = transactionResponse.name_of_months?.map((label) =>
      transactionResponse.transaction_data[label]
        ? transactionResponse.transaction_data[label].monthly_deposit_amount -
          transactionResponse.transaction_data[label].monthly_withdraw_amount
        : '0'
    );

    let datasets = [];

    if (type === 'transaction') {
      datasets = [
        {
          label: 'Deposit',
          data: filteredDeposit && filteredDeposit,
          fill: 'origin',
          backgroundColor: '#079C8D',
          borderColor: '#079C8D',
          borderWidth: 1,
          pointStyle: 'circle',
          borderPercentage: 1,
          categoryPercentage: 1,
          borderRadius: 10
        },
        {
          label: 'Withdrawal',
          data: filteredWithdrawal && filteredWithdrawal,
          fill: 'origin',
          backgroundColor: '#FF8100',
          borderColor: '#FF8100',
          borderWidth: 1,
          borderPercentage: 1,
          categoryPercentage: 1,
          borderRadius: 10
        }
      ];
    } else if (type === 'deposit') {
      datasets = [
        {
          label: 'Deposit',
          data: filteredDeposit && filteredDeposit,
          fill: 'origin',
          backgroundColor: '#079C8D',
          borderColor: '#079C8D',
          borderWidth: 1,
          pointStyle: 'circle',
          borderPercentage: 1,
          categoryPercentage: 1,
          borderRadius: 10
        }
      ];
    } else if (type === 'withdrawal') {
      datasets = [
        {
          label: 'Withdrawal',
          data: filteredWithdrawal && filteredWithdrawal,
          fill: 'origin',
          backgroundColor: '#FF8100',
          borderColor: '#FF8100',
          borderWidth: 1,
          borderPercentage: 1,
          categoryPercentage: 1,
          borderRadius: 10
        }
      ];
    } else if (type === 'profit') {
      datasets = [
        {
          label: 'Profit',
          data: filteredProfit && filteredProfit,
          fill: 'origin',
          backgroundColor: '#079C8D',
          borderColor: '#079C8D',
          borderWidth: 1,
          borderPercentage: 1,
          categoryPercentage: 1,
          borderRadius: 10
        }
      ];
    }

    const maxDepositValue = Math.max(...filteredDeposit) * 1.1;
    setChartData({
      labels: transactionResponse.name_of_months,
      datasets
      // datasets: [
      //   {
      //     label: 'Deposit',
      //     data: filteredDeposit && filteredDeposit,
      //     // fill: false,
      //     fill: 'origin', // Fill the area below the line starting from the origin
      //     backgroundColor: '#079C8D',
      //     borderColor: '#079C8D',
      //     borderWidth: 1,
      //     pointStyle: 'circle',
      //     borderPercentage: 1,
      //     categoryPercentage: 1,
      //     borderRadius:10,
      //   },
      //   {
      //     label: 'Withdrawal',
      //     data: filteredWithdrawal && filteredWithdrawal,
      //     // fill: false,
      //     fill: 'origin', // Fill the area below the line starting from the origin
      //     backgroundColor: '#FF8100',
      //     borderColor: '#FF8100',
      //     borderWidth: 1,
      //     borderPercentage: 1,
      //     categoryPercentage: 1,
      //     borderRadius:10,
      //   }
      // ]
    });

    setChartOption({
      // responsive: true,
      maintainAspectRatio: true,
      height: '100%',
      scales: {
        y: {
          beginAtZero: false,
          suggestedMax: maxDepositValue
        },
        x: {
          ticks: {
            font: {
              weight: 'bold',
              size: 12
            }
          }
        },
        xAxes: [
          {
            barThickness: 6
          }
        ]
      },
      plugins: {
        legend: {
          align: 'center',
          labels: {
            // This more specific font property overrides the global property
            font: {
              size: 12
            }
          },
          position: 'top'
        }
        // title: {
        //   align: 'start',
        //   display: true,
        //   text: 'Transaction History (Last 12 months)',
        //   font: {
        //     size: 24
        //   }
        // }
      }
    });
  };

  useEffect(() => {
    fillChart();
  }, [type]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ maxWidth: '131px', width: '100%', float: 'right' }}>
        <select
          style={{
            width: '100%',
            height: '30px',
            outline: 'none',
            border: '1px solid #D9D9D9',
            borderRadius: 3,
            opacity: 1,
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            textAlign: 'center',
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '15px',
            fontFamily: 'Roboto',
            letterSpacing: '0px',
            color: '#003049'
          }}>
          <option className="centered-option">Last 12 months</option>
          <option>Last 6 months</option>
          <option>Last 3 months</option>
        </select>
      </div>

      <div
        style={{
          width: '100%',
          height: '100%',
          boxSizing: 'border-box'
        }}>
        {!_.isEmpty(chartData) && !_.isEmpty(chartOption) && <Bar data={chartData} options={chartOption} />}
      </div>
    </div>
  );
};

export default MonthlyTransactionChart;
