import React, { useState, useContext, useEffect } from 'react';
import { Button, Card, Col, Modal, Row, Table, Tag, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import TextAreaWithLabel from '../../../../components/HelperComponents/TextAreaWithLabel';
import PutApi from '../../../../helps/contexts/Api/PutApi';
import { useNavigate } from 'react-router-dom';
import LangContext from '../../../../helps/contexts/lang-context';
import SelectWithLabel from '../../../../components/HelperComponents/SelectWithLabel';
import { constants } from '../../../../helps/contexts/Api/constants';

const FinanceApproveDepositModal = ({ modalOpen, onCloseClicked, onActionClicked, transaction, paymentMethods }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const Navigate = useNavigate();
  const sendFinanceAction = async (action) => {
    setLoading(true);
    setErrorMessage('');
    const payload = {
      status: action,
      remark: formData.remark,
      customer_message: formData.customer_message
    };
    try {
      const path = `/admin/transactions/${transaction.id}/deposit`;
      //sending api request
      const res = await PutApi.PutApiRequest(path, payload);

      if (res && res.status) {
        setFormData({});
        const transformedMessage = res?.message.split('_').join(' ');
        message.success(transformedMessage);
        onActionClicked();
      } else if (res && res.message) {
        const transformedMessage = res?.message.split('_').join(' ');
        message.error(transformedMessage);
        setErrorMessage(res.message);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Navigate('/login');
      } else if (error?.status === 403) {
        message.error('You do not have the required permissions.');
        onCloseClicked();
      }
    }
    setLoading(false);
  };

  const cancelRiskFunc = () => {
    onCloseClicked();
  };

  return (
    <Modal
      title={lang.label_financeverify}
      centered
      loading={loading}
      open={modalOpen}
      onCancel={onCloseClicked}
      footer={() => (
        <div
          style={{
            display: 'flex',
            alignItems: 'middle',
            justifyContent: 'right',
            marginTop: '25px',
            gap: '10px'
          }}>
          <Button
            type="primary"
            onClick={() => sendFinanceAction(1)}
            // disabled={
            //   (constants.BASE_URL1.includes('staging') || constants.BASE_URL2.includes('staging'))
            //     ? false
            //     : transaction?.status_name === 'Waiting For Payment'
            // }
            icon={<CheckOutlined />}>
            Approve
          </Button>
          <Button onClick={() => sendFinanceAction(2)} disabled={loading} icon={<CloseOutlined />} danger>
            {lang.label_reject}
          </Button>
          <Button onClick={cancelRiskFunc} disabled={loading}>
            {lang.label_cancel}{' '}
          </Button>
        </div>
      )}
      width={560}
      style={{
        height: '782px',
        maxHeight: '782px',
        borderRadius: '5px',
        padding: '24px 24px',
        width: '560px'
      }}>
      <TextAreaWithLabel
        label={lang.label_remark}
        value={formData.remark}
        onChange={(val) => setFormData((prevData) => ({ ...prevData, remark: val }))}
        isRequired={false}
        rows={3}
      />
      <TextAreaWithLabel
        label={lang.label_cusnotice}
        value={formData.customer_message}
        onChange={(val) => setFormData((prevData) => ({ ...prevData, customer_message: val }))}
        isRequired={false}
        rows={2}
      />
    </Modal>
  );
};

export default FinanceApproveDepositModal;
