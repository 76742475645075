import React, { useContext, useEffect, useState } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Pagination, Tag, Table } from 'antd';
import LangContext from '../../../../helps/contexts/lang-context';
import GetApi from '../../../../helps/contexts/Api/GetApi';
import { useNavigate } from 'react-router-dom';
import { convertUTCToLocalTime, toFixedFunc } from '../../../../helps/HelperFunctions';

const PlayerDepositHistory = ({ id }) => {
  const [currentpage, setCurrentpage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [Data, setData] = useState([]);

  const nav = useNavigate();

  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  function convertToTwoDecimalPlaces(numberString) {
    let number = parseFloat(numberString);
    let formattedNumber = number.toFixed(2);
    return formattedNumber;
  }


  const getPlayerDepositHistory = async (customPage = null, id) => {
    try {
      const path = `/admin/player/transaction/${id}?isWithdraw=0`;
      const params = {};
      setLoading(true);

      //setting parameters to send for search results
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;

      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      console.log('response deposit', res);
      if (res && res?.data && res?.data.length) {
        console.log('res.data,', res.data);
        const finalArray = res?.data?.map((user, index) => ({
          key: index,
          id: user?.id, //ID
          //round_reference: user?.round_reference ? user?.round_reference : '-',
          // openedOn: convertUTCToLocalTime(user?.approved_time),
          reference_no: user?.is_deposit_transaction?.reference_no, //reference id
          // transferNo:  user?.is_deposit_transaction?.deposit_transaction_no || "-", //transfer no
          transferNo:user?.is_deposit_transaction?.deposit_transaction_no || '-',
          amount: user ? convertToTwoDecimalPlaces(user?.amount):0, 
        //Amount  item ? convertToTwoDecimalPlaces(item?.amount) : 0
          currency: user ? user?.currency_name: "-", //currency
          createdTime:convertUTCToLocalTime(user?.created_at),//Created 
          approvedTime:user?.approvedTime ? convertUTCToLocalTime(user?.approved_time) : "-",//Approved Time
          status: (
            <Tag
              color={
                user?.status == 1 ? 'success' : user?.status == 2 ? 'error' : user?.status == 'pending' ? 'processing' : null
              }
              icon={
                user?.status == 1 ? (
                  <CheckCircleOutlined />
                ) : user?.status == 2 ? (
                  <CloseCircleOutlined />
                ) : user?.status == 'pending' ? (
                  <SyncOutlined spin />
                ) : null
              }>
              {user?.status === 1
                ? lang.label_completed
                : user?.status === 2
                ? lang.label_failed
                : user?.status == 'pending'
                ? lang.label_pending
                : null}
            </Tag>
          ),
         
        }));
        setData(finalArray);
        setCurrentpage(res?.current_page);
        setTotal(res?.total);
        setPerpage(res?.per_page);
        setNextPage(res?.next_page_url);
      } else {
        setData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };

  useEffect(() => {
    getPlayerDepositHistory(activepage, id);
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: 'Reference ID',
      dataIndex: 'reference_no',
      key: 'reference_no',
      // render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: 'Transfer No',
      dataIndex: 'transferNo',
      key: 'transferNo',
      // render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount'
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency'
      // render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },

    {
      title: 'Created Time',
      dataIndex: 'createdTime',
      key: 'createdTime'
    },
    {
      title: 'Approved Time',
      dataIndex: 'approvedTime',
      key: 'approvedTime'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    }
  ];

  const onChangepage = (page) => {
    setActivepage1(page);
    getPlayerDepositHistory(page, id);
  };
  return (
    <>
      <Table
        pagination={false}
        style={{
          width: '100%',
          marginTop: '30px',
          overflowX: 'auto',
          overflowY: 'scroll'
        }}
        size="small"
        columns={columns}
        bordered
        scroll={{
          x: 'max-content'
        }}
        dataSource={Data}
        loading={loading}
      />
      <Pagination
        defaultCurrent={1}
        current={activepage}
        onChange={onChangepage}
        total={total}
        responsive={true}
        pageSize={perpage}
      />
    </>
  );
};

export default PlayerDepositHistory;
