import { Card, Row, Col, DatePicker, Button, Input, Pagination } from 'antd';

import { useContext, useState, useEffect } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import GetApi from '../../../helps/contexts/Api/GetApi';
import '../../../styles/ButtonStyle.css';
import { useForm } from 'react-hook-form';
import { CalendarOutlined } from '@ant-design/icons';
import { Navigate, useNavigate } from 'react-router-dom';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import Spacer from '../../../components/HelperComponents/Spacer';
import AdminLogsTable from './AdminLogsTable';
import { convertLocalTimeToUTC } from '../../../helps/HelperFunctions';
import { TabContext } from '../../../helps/contexts/TabContext';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import Heading from '../../../components/HelperComponents/Heading';
import styles from '../AdminManagement.module.css';
import SearchIcon from "../../../images/Search.png";
import ResetIcon from "../../../images/Reset.png";
import dayjs from 'dayjs';

const AdminLogs = () => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const nav = useNavigate();
  //styles
  const datePickerStyle = {
    width: '185px',
    height: '35px',
    background: ' #FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const iconStyle = { color: '#004A7F' };
  const inputStyle = {
    width: '190px',
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  //states

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchtext, setSearchtext] = useState(null);
  const [startTimeParam, setStartTimeParam] = useState('');
  const [endTimeParam, setEndTimeParam] = useState('');
  const [ip, setip] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage] = useState(1);
  const [userData, setUserData] = useState([]);
  const Navigate = useNavigate();
  const { path } = useContext(TabContext);
  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (path === '/admin-management/admin-logs') {
      getUsers();
    }
  }, [path]);
  //date picker startdate
  const onChange = (value, dateString) => {
    setStartDate(value);
    setStartTimeParam(dateString);
  };
  //date picker start date completed
  //date picker end date

  const onChange1 = (value, dateString) => {
    setEndDate(value);
    setEndTimeParam(dateString);
  };
  //onreset
  const onReset = async () => {
    setActivepage(1);
    setLoading(true);
    setStartDate('');
    setEndDate('');
    setSearchtext(null);
    setip(null);
    //--------
    const path = `/admin/logs/admin`;
    const params = {};

    //   setLoading(true);
    const res = await GetApi.sendApiRequest(path, null, null);

    if (res && res.data && res.data.length) {
      const finalArray = res.data.map((log, index) => ({
        key: index,
        change: log?.change,
        date: log?.created_at,
        ip: log?.ip,
        changedBy: log?.actor_id
      }));
      setUserData(finalArray);
      setCurrentpage(res.current_page);
      setTotal(res.total);
      setPerpage(res.per_page);
      setNextPage(res.next_page_url);
    } else {
      setUserData([]);
      setTotal(0);
    }
    setLoading(false);
    //--------

    setLoading(false);
  };

  //on submit of filters on table values
  const onSubmit = (e) => {
    e.preventDefault();
    setActivepage(1);
    getUsers();
  };

  const getUsers = async (customPage = null) => {
    setLoading(true);
    try {
      const path = `/admin/logs/admin`;
      const params = {};
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      if (startDate) params['start_time'] = convertLocalTimeToUTC(startTimeParam);
      if (endDate) params['end_time'] = convertLocalTimeToUTC(endTimeParam);
      if (ip) params['ip'] = ip;
      if (searchtext) params['search'] = searchtext;

      //   setLoading(true);
      const res = await GetApi.sendApiRequest(path, null, params);
      if (res && res.data && res.data.length) {
        const finalArray = res.data.map((log, index) => ({
          key: index,
          change: log?.change,
          date: log?.created_at,
          ip: log?.ip,
          changedBy: log?.admin?.name
        }));
        setUserData(finalArray);
        setCurrentpage(res.current_page);
        setTotal(res.total);
        setPerpage(res.per_page);
        setNextPage(res.next_page_url);
      } else {
        setUserData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
    setLoading(false);
  };
  // on click of a page in pagination component

  const onChangepage = (page) => {
    setActivepage(page);
    getUsers(page);
  };
  return (
    <>
      <HeaderCardDetails />

      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div>
        <div className={styles.container}>
            <div
              className={styles.filterUpperContainer}
              style={{
                display: 'flex',
                alignItems :'center',
                justifyContent : 'space-between',
                borderRadius: '3px'
              }}>
              <Heading>{lang.label_filters}</Heading>
           
            </div>
            <div className={styles.filterLowerContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  width: '100%',
                  paddingRight: '14px',
                  flexWrap: 'wrap'
                }}>
                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    Log Period
                  </SubHeading>
                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-around',
                      alignItems: 'center'
                    }}>
                    <DatePicker
                      //getting input value for start date
                      placeholder="Select Time"
                      showTime ={{
                        defaultValue :dayjs('00:00:00', 'HH:mm:ss'),
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      onChange={onChange}
                      value={startDate}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    />
                    <span
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        marginLeft: '1%',
                        marginRight: '1%'
                      }}>
                      -
                    </span>
                    <DatePicker
                      //getting input value for start date
                      placeholder="Select Time"
                      showTime ={{
                        defaultValue :dayjs('23:59:59', 'HH:mm:ss'),
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      onChange={onChange1}
                      value={endDate}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    />
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    Change
                  </SubHeading>

                  <Input
                   placeholder="input search text"
                    style={inputStyle}
                    // name="search"
                    // type="text"
                    onChange={(e) => setSearchtext(e.target.value)}
                    value={searchtext}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    Changed By
                  </SubHeading>

                  <Input
                   placeholder="input search IP"
                    style={inputStyle}
                    // name="search"
                    // type="text"
                    onChange={(e) => {
                      setip(e.target.value);
                    }}
                    value={ip}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    marginTop: '20px',
                    marginLeft: '10px',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                  }}>
                  <Button
                    style={{
                      display : 'flex',
                      alignItems : 'center',
                      justifyContent : 'space-evenly',
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1'
                    }}
                    htmlType="submit"
                    className="btnStyle"
                    onClick={() => {
                      setActivepage(1);
                      setLoading(true);
                    }}>
                      <img src={SearchIcon} alt='search' />
                    {lang.label_search}
                  </Button>

                  <Button
                    style={{
                      display : 'flex',
                      alignItems : 'center',
                      justifyContent : 'space-evenly',
                      borderRadius: '3px',
                      padding: '6px',
                      border: '1px solid grey',
                      width: '84px'
                    }}
                    className="btnReset"
                    htmlType="reset"
                    onClick={() => {
                      onReset();
                    }}>
                       <img src={ResetIcon} alt='reset' />
                    {lang.label_reset}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <Spacer />
        </div>

        <div style={{ width: '100%', overflow: 'scroll' }}>
          <AdminLogsTable data={userData} loading={loading} getUsers={getUsers} />
          <Spacer />
          <Pagination
            showSizeChanger={false}
            defaultCurrent={1}
            current={activepage}
            onChange={onChangepage}
            total={total}
            responsive={true}
            pageSize={perpage}
          />
        </div>
      </form>
    </>
  );
};
export default AdminLogs;
