import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useLocation, useNavigate} from "react-router-dom";
import SubHeading from "../components/HelperComponents/SubHeading";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { TabContext } from "../helps/contexts/TabContext";


const { TabPane } = Tabs;

const MainTabs = ({  privateRoutes }) => {
  const navigate = useNavigate();

  const { handleTabChangeValue, handleTabListArray,
    setPath ,path} = useContext(TabContext);
  const [activeTab, setActiveTab] = useState(null);
  const [currentRoute, setCurrentRoute] = useState(null);

  const [tabList, setTabList] = useState([]);
  const location = useLocation();
  const { pathname } = location;
  const handleTabChange = (key) => {
    const tabIndex = tabList.findIndex((t) => t.path === key);
    if (tabIndex !== 0) {
      const updatedTabs = tabList.filter((t) => t.path !== key);
      updatedTabs.splice(0, 0, tabList[tabIndex]);

      setTabList(updatedTabs);
      handleTabListArray(updatedTabs);
    }
    setActiveTab(key);
    handleTabChangeValue(key);
    window.history.pushState(null, null, key);
    setPath(key)
  };

  const addTab = (path, name) => {
    const tabExists = tabList.find((tab) => tab.path === path);
    if (!tabExists) {
      let updatedTabs = [...tabList];
      if (updatedTabs.length >= 3) {
        updatedTabs.pop();
        updatedTabs.unshift({ path, name });
      } else {
        updatedTabs.unshift({ path, name });
      }
      setTabList(updatedTabs);
      handleTabListArray(updatedTabs);
      setActiveTab(path);
      setPath(path)

      handleTabChangeValue(path);
    } else {
      setActiveTab(path);
      setPath(path)

      handleTabChangeValue(path);
    }
  };

  const handleTabClose = (e, tab) => {
    e.stopPropagation();
    const updatedTabs = tabList.filter((t) => {
      return t.path !== tab;
    });
    setTabList(updatedTabs);
    handleTabListArray(updatedTabs);
    setActiveTab(updatedTabs.length > 0 ? updatedTabs[0].path : null);
    handleTabChangeValue(updatedTabs.length > 0 ? updatedTabs[0].path : null);
    updatedTabs.length > 0
      ? window.history.pushState(null, null, updatedTabs[0].path)
      : navigate("/dashboard");
    setPath(updatedTabs.length > 0 ? updatedTabs[0].path :  "/dashboard")
  };
  // useEffect(() => {
  
  //   const foundTab = tabList.find((tab) => tab.path === pathname);
  //   const currentPathRoute = privateRoutes.find((route) => {
  //     return route.path === pathname;
  //   });
 
  //   setCurrentRoute(currentPathRoute);
  //   let routeName = privateRoutes.find((route) => {
  //     const regex = new RegExp('^' + route.path.replace(/:\w+/g, '\\w+') + '$');
  //     return regex.test(pathname);
  //   })?.name;
  //   const nameArray = routeName ? routeName?.split("-") : "";
  //   const modifiedNames = nameArray
  //     ? nameArray
  //         .slice(0, nameArray.length)
  //         .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //         .join(" ")
  //     : "";
  //   routeName = modifiedNames + routeName?.slice(modifiedNames?.length + 1);
  //   if (!foundTab) {
  //     addTab(pathname, routeName);
  //   } else {
  //     tabList.splice(tabList.indexOf(foundTab), 1);
  //     tabList.unshift(foundTab);
  //     setActiveTab(pathname);
  //     setPath(pathname)
  //     handleTabChangeValue(pathname);
  //   }
  // }, [pathname]);
  useEffect(() => {
    setPath(pathname)
  },[pathname])
  useEffect(() => {
const pathTemp = path || pathname
    const foundTab = tabList.find((tab) => tab.path === pathTemp);
    const currentPathRoute = privateRoutes.find((route) => {
      return route.path === pathTemp;
    });
 
    setCurrentRoute(currentPathRoute);
    let routeName = privateRoutes.find((route) => {
 
      const regex = new RegExp('^' + route.path.replace(/:\w+/g, '\\w+') + '$');
      return regex.test(pathname);
    })?.name;
 
    const nameArray = routeName ? routeName?.split("-") : "";
 

    const modifiedNames = nameArray
      ? nameArray
        .slice(0, nameArray.length)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
      : "";


    routeName = modifiedNames + routeName?.slice(modifiedNames?.length + 1);
    if (!foundTab) {
      addTab(pathTemp, routeName);
    } else {
      tabList.splice(tabList.indexOf(foundTab), 1);
      tabList.unshift(foundTab);
      setActiveTab(pathTemp);
      setPath(pathTemp)
      handleTabChangeValue(pathTemp);
    }
  }, [path]);
  return (
    <Tabs
      className="MainTabs"
      activeKey={activeTab}
      onChange={handleTabChange}
      tabBarStyle={{ background: "white" }}
      tabBarGutter={0}
    >
    
      {tabList.map((tab) => {
       
        return (
          <TabPane
            key={tab.path}
            closable={false}
            tab={
              <div>
                <SubHeading
                  extraStyle={{
                    marginRight: "5px",
                    color: tab.path === activeTab && "#1677ff",
                  }}
                >
                  {tab.name}
                </SubHeading>
                {tabList?.length === 1 && activeTab === "/dashboard" ? null : (
                  <CloseCircleOutlined
                    onClick={(e) => handleTabClose(e, tab.path)}
                  />
                )}
              </div>
            }
          >
            {privateRoutes.map((child) => {
              const { path, element } = child;
              const regex = new RegExp('^' + path.replace(/:\w+/g, '\\w+') + '$');
              return regex.test(tab.path) ? element : null;
            })}
          </TabPane>
        );
      })}
    </Tabs>
  );
};

export default MainTabs;