import React from 'react'
import { Input } from 'antd';

const InputField = ({placeHolder ,type ,  name, value, onChange,extraStyle }) => {
  const handleFieldChange = (event) => {
    const { value } = event.target;
    onChange(name, value);
  };
    const inputStyle = {
        width: '190px',
        height: '35px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
      };
  return (
    <Input style={{...inputStyle,...extraStyle}} type={type} placeholder={placeHolder} name={name} value={value} onChange={handleFieldChange} />
  )
}

export default InputField