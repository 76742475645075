import react, { useContext, useEffect } from 'react';
import { Card, Col, Row, Tabs } from 'antd';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { privateRoutes } from '../routing/PrivateRoutes';
import { CloseCircleOutlined } from '@ant-design/icons';
import SubHeading from './HelperComponents/SubHeading';
import MainTabs from '../routing/MainTabs';
import { TabContext } from '../helps/contexts/TabContext';

const { TabPane } = Tabs;

const HeaderCardDetails = (props) => {
  const { activeTab, tabList } = useContext(TabContext);
  const tabExists = tabList?.find((tab) => tab?.path === activeTab);

  const location = useLocation();
  const segments = tabExists?.path?.split('/');

  const capitalizedSegments = segments?.map((segment) => {
    let subStr;
    let result;
    if (segment?.length === 0) {
      return segment;
    }
    if (segment?.includes('-')) {
      const newSegment = segment?.split('-');
      result = newSegment.map((segment) => {
        if (segment?.length === 0) {
          return segment;
        }
        const firstChar = segment?.charAt(0).toUpperCase();
        const restOfString = segment?.slice(1);
        return firstChar + restOfString;
      });
      return (subStr = result.map((str) => str).join(' '));
    }

    const firstChar = segment.charAt(0).toUpperCase();
    const restOfString = segment.slice(1);
    return firstChar + restOfString;
  });

  let finalString = '';

  for (let i = 1; i < capitalizedSegments?.length; i++) {
    finalString = finalString + capitalizedSegments[i] + (capitalizedSegments?.length - 1 === i ? '' : ' / ');
  }

  return (
    <div className="card" style={{ boxShadow: '0px 1px 6px #0000001A' }}>
      <Card
        className="headingCard"
        style={{
          width: '100%',
          height: '50px',
          borderRadius: '3px',
          position: 'relative',
          marginTop: '8px'
        }}>
        <Row
          style={{
            height: '100%'
          }}>
          <Col
            span={24}
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}>
            <span
              style={{
                width: '100%',
                textAlign: 'left',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '19px',
                lineHeight: '23px',
                fontFamily: 'Roboto',
                letterSpacing: '0px',
                color: '#003049',
                opacity: 1
              }}>
              {finalString}
            </span>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default HeaderCardDetails;
