import React from 'react';
import { Select } from 'antd';

// const handleChange = (value) => {
//     console.log(`selected ${value}`);
// };
const MultiSelectComp = ({ handleChange  , options , type , value, name}) => {
    return <>
        <Select
            mode="multiple"
            allowClear
            style={{
                width: '100%',
            }}
            // defaultValue={['a10', 'c12']}
            placeholder="Please Select"
            onChange={(value, prop2) => handleChange(value, prop2, name)}
            value={value}
            options={options}
        />
    </>
};
export default MultiSelectComp;