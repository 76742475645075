import { Table, Tag } from 'antd';

import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState } from 'react';
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';
import moment from 'moment';
import addCommasToNumber from '../../../helps/AddCommasToNumber';
import { convertUTCToLocalTime, toFixedFunc } from '../../../helps/HelperFunctions';

const CompanyBanksHistoryTable = ({ data, loading }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [rowdata, setRowdata] = useState({});

  // Edit player popup

  const columns = [
    {
      title: lang.label_id,
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: lang.label_previousbalance,
      dataIndex: 'prevbalance',
      key: 'prevbalance',
      render: (record) => toFixedFunc(record, process.env.REACT_APP_Decimel_Points)
    },
    {
      title: lang.label_amount,
      dataIndex: 'amount',
      key: 'amount',
      render: (record) => toFixedFunc(record, process.env.REACT_APP_Decimel_Points)
    },

    {
      title: lang.label_newbalance,
      dataIndex: 'newbalance',
      key: 'newbalance',
      render: (record) => toFixedFunc(record, process.env.REACT_APP_Decimel_Points)
    },

    {
      title: lang.label_type,
      dataIndex: 'type',
      key: 'type',
      render: (_, record) =>
        record.type === 1 ? (
          <Tag color="error" bordered={false}>
            {lang.label_withdraw}
          </Tag>
        ) : record.type === 0 ? (
          <Tag color="success" bordered={false}>
            {lang.label_deposit}
          </Tag>
        ) : null
    },
    {
      title: lang.label_accountname,
      dataIndex: 'bankname',
      key: 'bankname ',
      render: (record) => {
        console.log(record, 'record');
        return (
          <>
            <span>{record}</span>
          </>
        );
      }
    },
    {
      title: lang.label_actionby,
      dataIndex: 'actionby',
      key: 'actionby'
    },
    {
      title: lang.label_transactionid,
      dataIndex: 'transactionid',
      key: 'transactionid'
    },
    {
      title: lang.label_createddate,
      dataIndex: ['createddate'],
      key: 'createddate',
      render: (time, row) => (
        <a title={row['createddate']}>
          <span style={{ color: 'black' }}>
            {/* {new Date(row["createddate"]).toLocaleString()
              var utcMoment = moment.utc(item?.created_at, "YYYY-MM-DD HH:mm:ss");
            } */}
            {/* moment.utc(row["createddate"], "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss") */}
            {convertUTCToLocalTime(row['createddate'])}
          </span>
        </a>
      )
    },

    {
      title: lang.label_status,
      dataIndex: 'status',
      key: 'status',
      render: (_, record) =>
        record.status === 1 ? (
          <Tag color="success" icon={<CheckCircleOutlined />}>
            Success
          </Tag>
        ) : record.status === 2 ? (
          <Tag color="error">Rejected</Tag>
        ) : record.status === 3 ? (
          <Tag color="processing" icon={<SyncOutlined spin />} bordered={false}>
            Pending
          </Tag>
        ) : record.status === 4 ? (
          <Tag color="red">Processing</Tag>
        ) : (
          ''
        )
    },
    {
      title: lang.label_note,
      dataIndex: 'note',
      key: 'note'
    }
  ];

  return (
    <div>
      <Table
        style={{ width: '100%', height: '100%', overflowX: 'hidden', overflowY: 'auto' }}
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        bordered
        onRow={(record) => {
          return {
            onClick: () => {
              setRowdata(record);
            }
          };
        }}
      />
    </div>
  );
};
export default CompanyBanksHistoryTable;
