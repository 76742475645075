import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Input, Switch, Typography, Button, Table, Flex, Group, Radio } from 'antd';
import styles from './CostAndPt.module.css';

export const CostAndPt = () => {
  const [gapSize, setGapSize] = useState('small');
  const column1 = [
    {
      title: 'Deposit Bonus %',
      dataIndex: 'bonus',
      key: 'bonus'
    },
    {
      title: 'Other Bonus %',
      dataIndex: 'otherbonus',
      key: 'otherbonus'
    },
    {
      title: 'Rebate %',
      dataIndex: 'rebate',
      key: 'rebate'
    },
    {
      title: 'Deposit Fee %',
      dataIndex: 'depositfee',
      key: 'depositfee'
    },
    {
      title: 'Withdraw Fee %',
      dataIndex: 'withdrawfee',
      key: 'withdrawfee'
    }
  ];

  const dataSource1 = [
    {
      key: '1',
      bonus: '100',
      otherbonus: '1',
      rebate: '0',
      depositfee: '0',
      withdrawfee: '0'
    }
  ];

  const sportColumn = [
    {
      title: 'Sport',
      dataIndex: 'sport',
      key: 'sport'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (record) => (
        <Switch size="small" checked={record} />
      )
    },
  ]

  const sportData = [
    {
      key: '1',
      sport: 'UG Sports',
      status : 0
    },
    {
      key: '2',
      sport: 'PINNACLE',
      status : 1
    },
    {
      key: '3',
      sport: 'CMD Sports',
      status : 1
    }
  ]

  const CasinoColumn = [
    {
      title: 'Casino',
      dataIndex: 'casino',
      key: 'casino'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (record) => (
        <Switch size="small" checked={record} />
      )
    }
  ];

  const CasinoData = [
    {
      key: '1',
      casino: 'Evolution',
      status : 0
    },
    {
      key: '2',
      casino: 'ViA',
      status : 1
    },
    {
      key: '3',
      casino: 'Ae Sexy',
      status : 1
    },
    {
      key: '4',
      casino: 'EZUGI',
      status : 1
    },
  ]

  const SlotsColumn = [
    {
      title: 'Slots',
      dataIndex: 'slots',
      key: 'slots'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (record) => (
        <Switch size="small" checked={record} />
      )
    },
  ]

  const SlotsData = [
    {
      key: '1',
      slots: 'JILI',
      status : 0
    },
    {
      key: '2',
      slots: 'Kingmaker',
      status : 1
    },
    {
      key: '3',
      slots: 'RelaxGaming',
      status : 1
    },
    {
      key: '4',
      slots: 'PG Soft',
      status : 1
    },
  ]

  const SabongColumn = [
    {
      title: 'Sabong',
      dataIndex: 'Sabong',
      key: 'Sabong'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (record) => (
        <Switch size="small" checked={record} />
      )
    },
  ]

  const SabongData = [
    {
      key: '1',
      Sabong: 'DS88',
      status : 0
    },
  ]

  return (
    <div
      style={{
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Card className={styles.customCard} title="Share Expense">
        <Table
          style={{
            width: '100%'
            // overflowX: 'auto',
            // overflowY: 'scroll'
          }}
          dataSource={dataSource1}
          columns={column1}
          pagination={false}
        />
      </Card>

      <Card
        className={styles.customCard}
        title="Win/Loss Fee Rate"
        style={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'column'
          // justifyContent : 'center'
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '20%',
            height: '30px',
            columnGap: '10px'
          }}>
          <span
            style={{
              display: 'flex',
              width: '90%',
              height: '100%',
              border: '1px #EEE',
              borderRadius: '2px',
              background: '#EEE',
              padding: '5px',
              alignItems: 'center',
              boxSizing: 'border-box',
              color: '#BABABA',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500
            }}>
            0.0
          </span>
          %
        </div>
      </Card>

      <Flex
        style={{
          marginTop: '20px',
          width: '100%',
          height: '150px'
        }}
        justify={'space-between'}
        align={'flex-start'}>
        <Card className={styles.customCard} style={{ width: '49%', height: '100%' }} title="Settle Cycle">
          <Flex gap="middle" Horizontal>
            <Radio.Group value={gapSize} onChange={(e) => setGapSize(e.target.value)}>
              {['Weekly (Mon 00:00 ~ Sun 23:59 GMT+8)', 'Monthly'].map((size) => (
                <Radio
                  style={{
                    marginTop: '15px',
                    color: '#BABABA',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500
                  }}
                  key={size}
                  value={size}>
                  {size}
                </Radio>
              ))}
            </Radio.Group>
          </Flex>
        </Card>

        <Card className={styles.customCard} style={{ width: '49%', height: '100%' }} title="Active Players Requirements">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '48% ',
                color: '#BABABA',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500
              }}>
              Min Valid Bets
              <span
                style={{
                  display: 'flex',
                  width: '90%',
                  height: '100%',
                  border: '1px #EEE',
                  borderRadius: '2px',
                  background: '#EEE',
                  padding: '5px',
                  // alignItems: 'center',
                  boxSizing: 'border-box'
                }}>
                0.0
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '48% ',
                color: '#BABABA',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500
              }}>
              Min Deposit
              <span
                style={{
                  display: 'flex',
                  width: '90%',
                  height: '100%',
                  border: '1px #EEE',
                  borderRadius: '2px',
                  background: '#EEE',
                  padding: '5px',
                  // alignItems: 'center',
                  boxSizing: 'border-box'
                }}>
                0.0
              </span>
            </div>
          </div>
        </Card>
      </Flex>

      <Flex
        style={{
          marginTop: '20px',
          width: '100%',
          height: '150px'
        }}
        justify={'space-between'}
        align={'flex-start'}>
        <Card className={styles.customCard} title="Settle Requirements" style={{ width: '49%', height: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '45% ',
                color: '#BABABA',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500
              }}>
              Min Total Revenue
              <span
                style={{
                  display: 'flex',
                  width: '90%',
                  height: '100%',
                  border: '1px #EEE',
                  borderRadius: '2px',
                  background: '#EEE',
                  padding: '5px',
                  // alignItems: 'center',
                  boxSizing: 'border-box'
                }}>
                0.0
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '45% ',
                color: '#BABABA',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500
              }}>
              Active Players
              <span
                style={{
                  display: 'flex',
                  width: '90%',
                  height: '100%',
                  border: '1px #EEE',
                  borderRadius: '2px',
                  background: '#EEE',
                  padding: '5px',
                  // alignItems: 'center',
                  boxSizing: 'border-box'
                }}>
                0.0
              </span>
            </div>
          </div>
        </Card>

        <Flex
          style={{
            width: '49%',
            // height: '150px'
          }}
          justify={'space-between'}
          align={'flex-start'}>
          <Card className={styles.customCard} title="Commission" style={{ width: '49%', height: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100% ',
                color: '#BABABA',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500
              }}>
              Max.(100)%
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                  columnGap: '10px'
                }}>
                <span
                  style={{
                    display: 'flex',
                    width: '75%',
                    height: '100%',
                    border: '1px #EEE',
                    borderRadius: '2px',
                    background: '#EEE',
                    padding: '5px',
                    alignItems: 'center',
                    boxSizing: 'border-box'
                  }}>
                  0.0
                </span>
              </div>
            </div>
          </Card>
          <Card className={styles.customCard} title="Credit Line" style={{ width: '49%', height: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                color: '#BABABA',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500
              }}>
              Max Credit Line
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                  columnGap: '10px'
                }}>
                <span
                  style={{
                    display: 'flex',
                    width: '75%',
                    height: '100%',
                    border: '1px #EEE',
                    borderRadius: '2px',
                    background: '#EEE',
                    padding: '5px',
                    alignItems: 'center',
                    boxSizing: 'border-box'
                  }}>
                  0.0
                </span>
              </div>
            </div>
          </Card>
        </Flex>
      </Flex>

      <Card 
        style={{
          borderRadius : '10px',
          marginTop: "20px",
        }}
        className={styles.customCard} title="Allowed Games">
        <div 
        className='tablesContainer'
        style={{
          display : 'flex',
          flexDirection  : 'row',
          alignItems : 'flex-start',
          justifyContent : 'space-between',
          flexFlow : 'wrap',
          gap : "20px"
        }}>
        <Table  className={styles.tables}
          
          dataSource={sportData}
          columns={sportColumn}
          pagination={false}
        />
        <Table className={styles.tables}
          dataSource={CasinoData}
          columns={CasinoColumn}
          pagination={false}
        />
        <Table className={styles.tables}
          dataSource={SlotsData}
          columns={SlotsColumn}
          pagination={false}
        />
        <Table className={styles.tables}
          dataSource={SabongData}
          columns={SabongColumn}
          pagination={false}
        />
        </div>
      </Card>
    </div>
  );
};
