import React, { useState } from 'react';
import { Tabs } from 'antd';
import Platform1 from './Platform1/Platform1';
import Platform2 from './Platform2/Platform2';
import Platform3 from './Platfom3/Platform3';
import './GameCategoryPlatform.css';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
const { TabPane } = Tabs;

const GameCategoryPlatformItems = () => {
  const [activeTab, setActiveTab] = useState('platform1');
  const [formData, setFormData] = useState({
    search: '',
    platformName: '',
    icon: ''
  });
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleInputChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  return (
    <>
      <HeaderCardDetails />

      <div className="customTab" style={{ padding: '20px' }}>
        <Tabs
          activeKey={activeTab}
          onChange={handleTabChange}
          tabBarStyle={{ backgroundColor: '#F2F6F9', padding: '6px 24px' }}>
          <TabPane tab="Game Category Platform 1" key="platform1" style={{ background: '#fff', padding: '20px' }}>
            <Platform1 name="Game Category Platform 1" handleInputChange={handleInputChange} formData={formData} />
          </TabPane>
          <TabPane tab="Game Category Platform 2" key="platform2" style={{ background: '#fff', padding: '20px' }}>
            <Platform2 name="Game Category Platform 2" handleInputChange={handleInputChange} formData={formData} />
          </TabPane>
          <TabPane tab="Game Category Platform 3" key="platform3" style={{ background: '#fff', padding: '20px' }}>
            <Platform3 name="Game Category Platform 3" handleInputChange={handleInputChange} formData={formData} />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default GameCategoryPlatformItems;
