import React, { useState } from "react";
import { Dropdown, Modal, Table, Tag, message } from "antd";
import { useContext } from "react";
import LangContext from "../../../helps/contexts/lang-context";
import AddPlayerNotification from "./AddPlayerNotification";
import { IoMenu } from "react-icons/io5";
import EditPlayerNotification from "./EditPlayerNotification";
import { Link } from "react-router-dom";
import DeleteNotification from "./DeleteNotification";

const PlayerNotificationTable = ({ data, loading, getNotification }) => {
  const ctx = useContext(LangContext);
  const [rowdata, setRowdata] = useState({});
  const lang = ctx.lang;
  const [edit, setEdit] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState(false);

  //menu items in table

  const items = [
    {
      key: 1,
      label: "Edit Message",
    },
    {
      key: 2,
      label: "Delete",
    }
  ];
  //onclick of option in table
  const onClick = ({ key }) => {
    if (key == 1) {
      handleEditMessage();
    } else if (key == 2) {
      handleDeleteMessage();
    }
  };
  const columns = [
    {
      title: lang.label_player,
      dataIndex: "player",
      key: "player",
      render: (item, record) => (
        <>
          {record?.player.map((player) =>
            <Link
              state={{ name: player?.user?.user_name }}
              to={{
                pathname: `/player-management/player/${player?.player_id}/view`,
              }}
            >
              <Tag color={player?.is_read ? 'green' : 'red'} style={{ marginTop: 5 }}>
                {player?.player?.user?.user_name}
              </Tag>
            </Link>
          )}
        </>
      ),
    },
    {
      title: lang.label_notification,
      dataIndex: "notification",
      key: "notification",
      render: (text, record) => (
        <>
          <div><strong>{record.notificationTitle}</strong></div>
          <div>{text}</div>
        </>
      ),
    },
    // {
    //   title: lang.label_read,
    //   dataIndex: "read",
    //   key: "read",
    // },
    {
      title: lang.label_createdby,
      dataIndex: "createdby",
      key: "createdby",
      render: (item, record) => (
        
        <>
        <Tag color='green'>
          {record?.createdby}
        </Tag>
        </>
      ),

    },
    {
      title: lang.label_createdat,
      dataIndex: "createdat",
      key: "createdat",
    },
    {
      title: lang.label_action,
      key: "action",
      render: (_, record) => {
        return (
          <Dropdown
            placement="bottom"
            menu={{
              items,
              onClick,
            }}
          >
            <a
              href="#"
              style={{ color: "#004a7f" }}
              onClick={(e) => e.preventDefault()}
            >
              <IoMenu />
            </a>
          </Dropdown>
        );
      },
    },
  ];
  //for closing the popup
  const handleCancel = () => {
    setEdit(false);
  };

  const handleCloseDeleteMsg = () => {
    setDeleteMsg(false);
  };

  //for onclick of options in table

  const handleEditMessage = () => {
    setEdit(true);
  };

  const handleDeleteMessage=() => {
    setDeleteMsg(true);
  }

  return (
    <>
      {" "}
      <Table
        loading={loading}
        columns={columns}
        bordered
        dataSource={data}
        pagination={false}
        size="small"
        onRow={(record) => {
          return {
            onClick: () => {
              setRowdata(record);
            },
          };
        }}
      />
      {edit && edit ? <Modal
        closeIcon={null}
        title="Edit Message "
        onOk={() => {
          setEdit(true);
        }}
        open={edit}
        onCancel={handleCancel}
        footer={null}
        width={400}
        color={"#004A7F"}
      >
        {/* <EditPlayerNotification rowdata={rowdata} onCancel={handleCancel} /> */}
        <AddPlayerNotification rowdata={rowdata} onCancel={handleCancel} type="edit" getNotification={getNotification} />
      </Modal> : ""}

      {deleteMsg && deleteMsg ? <Modal
        closeIcon={null}
        title="Are you sure you want to delete this notification?"
        onOk={() => {
          setDeleteMsg(true);
        }}
        open={deleteMsg}
        onCancel={handleCloseDeleteMsg}
        footer={null}
        width={400}
        color={"#004A7F"}
      >
        <DeleteNotification handleCloseDeleteMsg={handleCloseDeleteMsg} getNotification={getNotification} id={rowdata?.id}/>
      </Modal> : ""}


    </>
  );
};

export default PlayerNotificationTable;
