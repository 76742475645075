import { Modal, Button, Switch, message, Input, Tag, Checkbox, Flex, Tabs, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import addCommasToNumber from '../../../helps/AddCommasToNumber';
import './PlayerDetailsStyle.css';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState, useEffect } from 'react';
import GetApi from '../../../helps/contexts/Api/GetApi';
import EditPlayerForm from './EditPlayerForm';
import ResetPwdForm from '../../../components/forms/ResetPwdForm';
import PutApi from '../../../helps/contexts/Api/PutApi';
import PlayerChangeStatus from '../../../components/forms/PlayerChangeStatus';
import { MdEdit } from 'react-icons/md';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ChangeAgent from './ChangeAgent';
import { convertUTCToLocalTime, toFixedFunc } from '../../../helps/HelperFunctions';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import Loader from '../../../components/HelperComponents/Loader';
import EditTagName from './EditTagName';

const PlayerDetailsTable = ({ id, setVerified , setLastActive}) => {
  const { hasPermission } = useCheckPermission();

  //States
  const [name, setName] = useState('');
  const [playerUsername, setPlayerUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('');
  const [birthday, setBirthday] = useState('');
  const [status, setStatus] = useState('');
  const [allowBetting, setAllowBetting] = useState('');
  const [withdrawal, setwithdrawal] = useState('');
  const [agent, setAgent] = useState('');
  const [balance, setBalance] = useState('');
  const [playerCurrency, setPlayerCurrency] = useState('');
  const [baseBalance, setBaseBalance] = useState('');
  const [baseCurrency, setBaseCurrency] = useState('USD');
  const [changeAgent, setChangeAgent] = useState(false);
  const [updateTagName, setUpdateTagName] = useState(false);
  const [tagNames, setTagNames] = useState([]);

  const [resgisterTime, setResgisterTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [remark, setRemark] = useState('');
  const [isEdit, setIsedit] = useState(false);
  const [reset, setReset] = useState(false);
  const [show, setShow] = useState(true);
  const [showBalanceInput, setShowBalanceInput] = useState(false);
  const [balanceRemark, setBalanceRemark] = useState('');
  const [amount, setAmount] = useState('');
  const [tabIndex, setTabIndex] = useState('1');
  const [promotion, setPromotion] = useState(false);
  const [categoryList, setCategoryList] = useState(null);
  const [gameTypeList, setGameTypeList] = useState(null);
  const [transactionData, setTransactionData] = useState({
    // transaction_id: '',
    // game_category_id: '',
    game_type: '',
    turnover_multiplier: ''
  });

  const [language, setLanguage] = useState('');
  const [currency, setCurrency] = useState('');
  const [statusChange, setStatusChange] = useState(false);
  const [rowData, setRowData] = useState({});
  const [currentBalance, setCurrentBalance] = useState();
  const [lastSeen, setlastSeen] = useState("Not Available")
  const [isHovered, setIsHovered] = useState(false);

  const [updateBalanceLoading, setUpdateBalanceLoading] = useState(false);

  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const nav = useNavigate();
  // popup
  const handleCancel = (e) => {
    setIsedit(false);
    setReset(false);
    setStatusChange(false);
    setShowBalanceInput(false);

    setPromotion(false);
    setTransactionData({
      // transaction_id: '',
      // game_category_id: '',
      game_type: '',
      turnover_multiplier: ''
    });
    setTabIndex('1');
    setBalanceRemark('');
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  //updating balance
  const updateBalance = async () => {
    setUpdateBalanceLoading(true);
    const path = `/admin/player/${id}/balance/adjust`;
    try {
      const res = await PutApi.PutApiRequest(path, {
        amount: amount,
        remark: balanceRemark,
        is_turnover_check: promotion,
        is_withdrawal: tabIndex === '1' ? false : true,
        // transaction_id: transactionData?.transaction_id?.length > 0 ? transactionData?.transaction_id : null,
        // game_category_id: transactionData?.game_category_id ? transactionData?.game_category_id : null,
        game_type: transactionData?.game_type ? transactionData?.game_type : null,
        turnover_multiplier:
          transactionData?.turnover_multiplier?.length > 0 ? transactionData?.turnover_multiplier : null
      });

      if (res && res?.status === true) {
        message.success(lang.label_sucessupdatebalance);

        setPromotion(false);
        setTransactionData({
          game_category_id: '',
          // transaction_id: '',
          turnover_multiplier: ''
        });
        setTabIndex('1');
        setBalanceRemark('');

        handleCancel();
        fetchPlayerDetails(id);
        fetchPlayerTagName(id);
      } else {
        message.error(lang.label_updatebalanceerror);
      }
    } catch (error) {
      console.error('Failed to update balance:', error);
      message.error(lang.label_updatebalanceerror);
    } finally {
      setUpdateBalanceLoading(false);
    }
  };

  const fetchPlayerCategoryDetails = async (id) => {
    try {
      const path = `/game_categories/all`;
      setLoading(true);
      //sending api request
      const res = await GetApi.sendApiRequest(path);
      if (res.length) {
        const formattedList = res.map((category) => {
          const parsedName = JSON.parse(category.name);
          return {
            value: category.id,
            label: parsedName.en // Display the English name
          };
        });
        setCategoryList(formattedList);
      }
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };

  //fetching API for game type
  const fetchGameTypeDetails = async () => {
    try {
      const path = `/game_items/types`;
      setLoading(true);
      //sending api request
      const res = await GetApi.sendApiRequest(path);
      if (res) {
        const formattedList = Object.entries(res).map(([key, value]) => ({
          value: Number(key),
          label: value
        }));
        setGameTypeList(formattedList);
      }
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };
  // Fetching api
  const fetchPlayerDetails = async (id) => {
    try {
      const path = `/admin/player/${id}/view`;
      setLoading(true);
      //sending api request
      const res = await GetApi.sendApiRequest(path);
      if (res) {
        setRowData(res);
        setName(res?.user?.name ? res?.user?.name : '');
        setPlayerUsername(res?.user?.user_name ? res?.user?.user_name : '');
        setlastSeen(res?.user?.last_seen ? res?.user?.last_seen : 'Not Available')
        setLastActive(res?.user?.last_seen ? res?.user?.last_seen : 'Not Available')
        setPhone(res.user.phone ? res.user.phone : '');
        setGender(
          res?.user?.gender === 1
            ? 'male'
            : res?.user?.gender === 2
            ? 'Female'
            : res?.user?.gender === 3
            ? 'Other'
            : null
        );
        setBirthday(res?.user?.birthday ? res?.user?.birthday : '');

        setVerified(res?.user?.is_contact_verified);
        // setStatus(
        //   res?.active === 1 ? (
        //     <Tag color="success" icon={<CheckCircleOutlined />}>
        //       {lang.label_active}
        //     </Tag>
        //   ) : res?.active === 0 ? (
        //     <Tag color="error" icon={<CloseCircleOutlined />}>
        //       {lang.label_inactive}
        //     </Tag>
        //   ) : null
        // );
        setStatus(
          <div>
            <Switch
              size="small"
              disabled={!hasPermission('Toggle Player Status')}
              defaultChecked={res.active}
              onChange={togglePlayerStatus}
              className="player-switch"
            />
          </div>
        );
        setAllowBetting(
          res?.allow_betting === 1 ? (
            <div>
              <Switch
                size="small"
                disabled={!hasPermission('Toggle Player Status')}
                defaultChecked={res.allow_betting}
                onChange={sendApiBettingToggleReques}
              />
            </div>
          ) : (
            <div>
              <Switch
                size="small"
                disabled={!hasPermission('Toggle Player Status')}
                defaultChecked={res.allow_betting}
                onChange={sendApiBettingToggleReques}
              />
            </div>
          )
        );
        setwithdrawal(
          res?.allow_withdraw === 1 ? (
            <div>
              <Switch
                size="small"
                disabled={!hasPermission('Toggle Player Status')}
                defaultChecked={res.allow_withdraw}
                onChange={sendApiWithdrawToggleReques}
              />
            </div>
          ) : (
            <div>
              <Switch
                size="small"
                disabled={!hasPermission('Toggle Player Status')}
                defaultChecked={res.allow_withdraw}
                onChange={sendApiWithdrawToggleReques}
              />
            </div>
          )
        );
        setAgent(
          res?.agent?.user?.user_name
            ? res?.agent?.user?.user_name
            : res?.agent?.user?.name
            ? res?.agent?.user?.name
            : ''
        );
        setBalance(res?.wallet?.balance ? res?.wallet?.balance : 0);
        setPlayerCurrency(res?.wallet?.currency_name);
        setBaseBalance(res?.wallet?.base_balance);
        setCurrency(res?.wallet?.currency_name);
        // setCurrency(
        //   res?.currency == 1
        //     ? "CURRENCY_VND"
        //     : res?.currency == 2
        //     ? "CURRENCY_INR"
        //     : res?.currency == 3
        //     ? " CURRENCY_PHP"
        //     : null
        // );
        // setLanguage(
        //   res?.language == 1
        //     ? "LANG_CH"
        //     : res?.language == 3
        //     ? "LANG_ENG"
        //     : res?.language == 7
        //     ? "LANG_VN"
        //     : null
        // );
        setLanguage(res?.language_name);
        setRemark(res?.user?.remark ? res?.user?.remark : '');
        setResgisterTime(res?.user?.created_at);
      }
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
      Object.values(e.data.message).map((errors) => message.error(errors));
    }
  };

  //fetch tagname
  const fetchPlayerTagName = async (id) => {
    try {
      const path = `/admin/tags/player/${id}`;
      setLoading(true);
      //sending api request
      const res = await GetApi.sendApiRequest(path);
      if (res?.status) {
        setTagNames(res?.data);
      }
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }

      Object.values(e.data.message).map((errors) => message.error(errors));
    }
  };
  //Sending api put request to toggle allow withdraw
  const sendApiWithdrawToggleReques = async () => {
    try {
      const path = `/admin/player/${id}/withdraw/toggle`;
      setLoading(true);
      //sending api request
      const res = await PutApi.PutApiRequest(path, {
        withdraw: withdrawal
      });
      //if success
      if (res) {
        message.success(res?.message);
        fetchPlayerDetails(id);
        fetchPlayerTagName(id);
      }
    } catch (error) {
      message.error('Withdrawal update Failed');
    }
  };
  //Sending api put request to toggle allow betting
  const sendApiBettingToggleReques = async () => {
    try {
      const path = `/admin/player/${id}/betting/toggle`;
      setLoading(true);
      //sending api request
      const res = await PutApi.PutApiRequest(path, {
        allow_betting: allowBetting
      });

      //if success
      if (res.status === true) {
        message.success(res?.message);
        fetchPlayerDetails(id);
        fetchPlayerTagName(id);
      }
    } catch (error) {
      message.error('Betting Update Failed');
    }
  };
  //on form submit request to backend
  const togglePlayerStatus = async () => {
    try {
      // e.preventDefault();

      // setStatus(!status);

      const path = `/admin/player/${id}/status/toggle`;
      // sending request
      const res = await PutApi.PutApiRequest(path);
      //if success
      if (res && res.status == true) {
        message.success('Status Updated Successfully');
        // onCancel()
        // getPlayers()
      }
    } catch (e) {
      var message1 = e.message;
      message.error(message1);
    }
  };

  const onChange = (key) => {
    setTabIndex(key);
    setPromotion(false);
    setTransactionData({
      // game_category_id: '',
      // transaction_id: '',
      game_type: '',
      turnover_multiplier: ''
    });
    setBalanceRemark('');
  };

  const onChangeCategory = (value) => {
    setTransactionData({ ...transactionData, game_category_id: value });
  };

  const onChangeGameType = (value) => {
    setTransactionData({ ...transactionData, game_type: value });
  };

  const items = [
    {
      key: '1',
      label: 'Add Points',
      children: (
        <div>
          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_points} ({playerCurrency})
          </div>
          <Input
            style={{
              marginBottom: '10px'
            }}
            value={amount}
            // defaultValue={Number(balance).toFixed(2)}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            type="number"
          />
          <Checkbox
            style={{
              fontSize: '12px',
              marginBottom: '10px'
            }}
            checked={promotion}
            onChange={(e) => setPromotion(e.target.checked)}>
            {lang.label_promotion}
          </Checkbox>

          {promotion ? (
            <div
              style={{
                padding: '10px',
                backgroundColor: 'rgb(238, 244, 248)',
                marginBottom: '10px'
              }}>
              <div
                style={{
                  fontSize: '12px',
                  marginBottom: '6px'
                }}>
                {lang.label_turnover_multiplier}
              </div>
              <Input
                style={{
                  marginBottom: '10px'
                }}
                value={transactionData?.turnover_multiplier}
                onChange={(e) => {
                  setTransactionData({
                    ...transactionData,
                    turnover_multiplier: e.target.value
                  });
                }}
                type="number"
              />
              <div
                style={{
                  fontSize: '12px',
                  marginBottom: '6px'
                }}>
                {lang.label_game_type}
              </div>
              {/* <Input
                style={{
                  marginBottom: '10px'
                }}
                value={transactionData?.transaction_id}
                onChange={(e) => {
                  setTransactionData({
                    ...transactionData,
                    transaction_id: e.target.value
                  });
                }}
                type="number"
              /> */}
              {/* <Select
                required
                onChange={onChangeCategory}
                placeholder="Select Category"
                value={(transactionData && transactionData?.game_category_id) || undefined}
                style={{
                  width: '100%',
                  borderRadius: '3px'
                }}
                options={categoryList}
              /> */}
              <Select
                required
                onChange={onChangeGameType}
                placeholder="Select Game type"
                value={(transactionData && transactionData?.game_type) || undefined}
                style={{
                  width: '100%',
                  borderRadius: '3px'
                }}
                options={gameTypeList}
              />
            </div>
          ) : null}

          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_remark}
          </div>
          <TextArea
            value={balanceRemark}
            style={{ width: '100%', marginBottom: '2rem' }}
            onChange={(e) => {
              setBalanceRemark(e.target.value);
            }}
          />
        </div>
      )
    },
    {
      key: '2',
      label: 'Remove Points',
      children: (
        <div>
          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_points} ({playerCurrency})
          </div>
          <Input
            allowClear
            style={{
              marginBottom: '10px'
            }}
            value={amount}
            // defaultValue={Number(balance).toFixed(2)}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            type="number"
          />
          {/* <Checkbox
            style={{
              fontSize: '12px',
              marginBottom: '10px'
            }}
            checked={promotion}
            onChange={(e) => {
              setPromotion(e.target.checked);
              setTransactionData({
                transaction_id: '',
                turnover_multiplier: ''
              });
            }}>
            {lang.label_promotion}
          </Checkbox>

          {promotion ? (
            <div
              style={{
                padding: '10px',
                backgroundColor: 'rgb(238, 244, 248)',
                marginBottom: '10px'
              }}>
              <div
                style={{
                  fontSize: '12px',
                  marginBottom: '6px'
                }}>
                {lang.label_transactionid}
              </div>
              <Input
                style={{
                  marginBottom: '10px'
                }}
                value={transactionData?.transaction_id}
                onChange={(e) => {
                  setTransactionData({
                    ...transactionData,
                    transaction_id: e.target.value
                  });
                }}
                type="number"
              />
              <div
                style={{
                  fontSize: '12px',
                  marginBottom: '6px'
                }}>
                {lang.label_turnover_multiplier}
              </div>
              <Input
                style={{
                  marginBottom: '10px'
                }}
                value={transactionData?.turnover_multiplier}
                onChange={(e) => {
                  setTransactionData({
                    ...transactionData,
                    turnover_multiplier: e.target.value
                  });
                }}
                type="number"
              />
            </div>
          ) : null} */}

          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_remark}
          </div>
          <TextArea
            value={balanceRemark}
            style={{ width: '100%', marginBottom: '2rem' }}
            onChange={(e) => {
              setBalanceRemark(e.target.value);
            }}
          />
        </div>
      )
    }
  ];
  // console.log(tabIndex);
  useEffect(() => {
    fetchPlayerDetails(id);
  }, []);

  useEffect(() => {
    fetchPlayerTagName(id);
  }, [id]);
  const handleAgentModalClose = () => {
    setChangeAgent(false);
  };

  const handleTagNameModalClose = () => {
    setUpdateTagName(false);
    fetchPlayerDetails(id);
    fetchPlayerTagName(id);
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
        height: '100%',
        padding: '0.8rem',
        position: 'relative',
        borderRight: '0.3px solid #F0F0F0'
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <h3
          style={{
            color: '#4F5057',
            fontFamily: 'Roboto',
            fontWeight: '590',
            fontSize: '17px',
            letterSpacing: '0px',
            lineHeight: '25px'
          }}>
          {lang.label_playerdetails}
        </h3>
        {hasPermission('Create Player') && (
          <Button
            style={{
              background: '#004a7f 0% 0% no-repeat padding-box',
              borderRadius: '2.5px',
              border: 'none',
              marginTop: '4%',
              // marginLeft: '29%',
              color: 'white',
              fontWeight: '450'
            }}
            onClick={() => setIsedit(true)}>
            {lang.label_editplayer}
          </Button>
        )}
      </div>

      <div>
        <Modal
          title={lang.label_editplayer}
          width={700}
          footer={null}
          open={isEdit}
          onOk={() => setIsedit(true)}
          onCancel={handleCancel}>
          <EditPlayerForm
            sendApiWithdrawToggleReques={sendApiWithdrawToggleReques}
            sendApiBettingToggleReques={sendApiBettingToggleReques}
            fetchPlayerDetails={() => fetchPlayerDetails(id)}
            fetchPlayerTagName={() => fetchPlayerTagName(id)}
            rowdata={rowData}
            onCancel={handleCancel}
            loading={loading}
          />
        </Modal>
        <Modal
          title={lang.label_resetpassword}
          width={700}
          footer={null}
          open={reset}
          onOk={() => setReset(true)}
          onCancel={handleCancel}>
          <ResetPwdForm rowdata={rowData} onCancel={handleCancel} />
        </Modal>
        {/* <Modal
          title={lang.label_changestatus}
          open={statusChange}
          onOk={() => setStatusChange(true)}
          onCancel={handleCancel}
          footer={null}
          width={250}
        >
          <PlayerChangeStatus
            getPlayers={fetchPlayerDetails}
            rowdata={rowData}
            onCancel={handleCancel}
          />
        </Modal> */}
      </div>
      <table>
        <tbody>
          <tr>
            <td>{lang.label_name}</td>
            <td className="response-data">{name}</td>
          </tr>
          <tr>
            <td>{lang.label_username}</td>
            <td className="response-data">{playerUsername}</td>
          </tr>
          <tr>
            <td>{lang.label_phonenumber}</td>
            <td className="response-data">{phone}</td>
          </tr>
          <tr>
            <td>{lang.label_gender}</td>
            <td className="response-data">{gender}</td>
          </tr>
          <tr>
            <td>{lang.label_birthday}</td>
            <td className="response-data">{birthday}</td>
          </tr>
          <tr>
            <td>{lang.label_status}</td>
            <td
              className="response-data"
              // style={{
              //   borderRadius: "12px",
              //   minWidth: "43px",
              //   textAlign: "center",
              //   marginLeft: "2rem",
              // }}
              onClick={() => setStatusChange(true)}>
              {status}
            </td>
          </tr>
          <tr>
            <td>{lang.label_allowbetting}</td>
            <td
              className="response-data"
              // style={{
              //   borderRadius: "12px",
              //   minWidth: "43px",
              //   textAlign: "center",
              //   marginLeft: "2rem",
              // }}
            >
              {allowBetting}
            </td>
          </tr>
          <tr>
            <td>{lang.label_allowwithdraw}</td>
            <td
              className="response-data"
              // style={{
              //   borderRadius: "12px",
              //   minWidth: "43px",
              //   textAlign: "center",
              //   marginLeft: "2rem",
              // }}
            >
              {withdrawal}
            </td>
          </tr>
          {hasPermission('Reset Player Password') && (
            <tr>
              <td>{lang.label_resetpassword}</td>
              <td
                className="response-data"
                // style={{
                //   borderRadius: "12px",
                //   minWidth: "43px",
                //   textAlign: "center",
                //   marginLeft: "2rem",
                // }}
              >
                <Button
                  style={{
                    border: 'none',
                    height: '28px',
                    background: '#004a7f 0% 0% no-repeat padding-box',
                    borderRadius: '2.5px',
                    color: 'white',
                    fontWeight: '450'
                  }}
                  onClick={() => setReset(true)}>
                  {lang.label_reset}
                </Button>
              </td>
            </tr>
          )}
          <tr>
            <td>
              {lang.label_agent}
              {hasPermission('Change Player Agent') && (
                <Button onClick={() => setChangeAgent(true)} style={{ border: 'none' }}>
                  <MdEdit />
                </Button>
              )}
              {changeAgent && (
                <Modal
                  title={lang.label_changeagent}
                  open={changeAgent}
                  // onOk={() => {
                  //   setChangeAgent(true);
                  // }}
                  onCancel={handleAgentModalClose}
                  footer={null}
                  width={500}
                  closable={true}>
                  <ChangeAgent
                    rowdata={rowData}
                    onCancel={handleAgentModalClose}
                    // getPlayers={getPlayers}
                    getPlayers={() => fetchPlayerDetails(id)}
                  />
                </Modal>
              )}
            </td>
            <td className="response-data">{agent}</td>
          </tr>
          <tr>
            <td>
              {lang.label_balance}
              {hasPermission('Add/Remove Points') && (
                <Button
                  onClick={() => {
                    // fetchPlayerCategoryDetails(id);
                    fetchGameTypeDetails();
                    setShowBalanceInput(!showBalanceInput);
                    // setAmount(Number(balance).toFixed(2));
                  }}
                  style={{ border: 'none' }}>
                  <MdEdit />
                </Button>
              )}
              {showBalanceInput && (
                <Modal
                  title={lang.label_updatebalance}
                  open={showBalanceInput}
                  onOk={() => {
                    setShowBalanceInput(true);
                  }}
                  onCancel={handleCancel}
                  footer={null}
                  width={500}>
                  <div
                    style={{
                      fontSize: '14.4px',
                      position: 'relative',
                      left: '300px',
                      width: '200px'
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    <div
                      style={{
                        color: isHovered ? '#1473E6' : 'black'
                      }}>
                     {`Balance : ${toFixedFunc(balance, process.env.REACT_APP_Decimel_Points)} (${currency})`}
                    </div>
                  </div>

                  <Tabs defaultActiveKey={tabIndex} items={items} onChange={onChange} centered />

                  {hasPermission('Change Player Balance') ? (
                    <>
                      {updateBalanceLoading ? (
                        <Loader />
                      ) : (
                        <Button
                          disabled={amount?.length > 0 ? false : true}
                          onClick={updateBalance}
                          style={{
                            float: 'right',
                            backgroundColor: '#004A7F',
                            color: 'white',
                            marginTop: '-1.2rem'
                          }}>
                          {lang.label_submit}
                        </Button>
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </Modal>
              )}
            </td>
            <td className="response-data">
              <span style={{ color: '#207620' }}>
                <span title="Balance">
                  <span>
                    {toFixedFunc(balance, process.env.REACT_APP_Decimel_Points)} ({playerCurrency})
                  </span>
                  <br />
                  <span
                    style={{
                      color: 'grey',
                      fontSize: '12px'
                    }}
                    title="Base Balance">
                    {toFixedFunc(baseBalance, process.env.REACT_APP_Decimel_Points)} ({baseCurrency})
                  </span>
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td>{lang.label_currency}</td>
            <td className="response-data">{currency}</td>
          </tr>
          <tr>
            <td>{lang.label_language}</td>
            <td className="response-data">{language}</td>
          </tr>
          <tr>
            <td>{lang.label_registertime}</td>
            <td className="response-data">{convertUTCToLocalTime(resgisterTime)}</td>
          </tr>
          <tr>
            <td>{lang.label_remark}</td>
            <td className="response-data" style={{ wordBreak: 'break-word' }}>
              {remark}
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ whiteSpace: 'nowrap' }}>{lang.label_tagname}</span>
                {hasPermission('Create Tags') && (
                  <Button style={{ border: 'none' }} onClick={() => setUpdateTagName(true)}>
                    <MdEdit />
                  </Button>
                )}
                <Modal
                  title={lang.label_edittagname}
                  centered
                  open={updateTagName}
                  onCancel={handleTagNameModalClose}
                  footer={null}
                  width={500}
                  closable={false}>
                  <EditTagName
                    updateTagName={updateTagName}
                    onCancel={handleTagNameModalClose}
                    playerTags={tagNames}
                    id={id}
                  />
                </Modal>
              </div>
            </td>
            <td className="response-data">
              {tagNames &&
                tagNames?.map((item) => (
                  <div key={item?.id} style={{ display: 'flex', gap: 5, flexWrap: 'wrap', margin: '8px 0px' }}>
                    <Tag color={item?.type?.toLowerCase() === 'functional' ? 'error' : 'warning'}>{item?.name}</Tag>
                  </div>
                ))}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PlayerDetailsTable;
