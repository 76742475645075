import { Input, Button, Row, Col, Select, DatePicker, message, Upload } from 'antd';
import axios from 'axios';
import LangContext from '../../helps/contexts/lang-context';
import { useContext, useState, useRef, useEffect, useMemo } from 'react';
import PostApi from '../../helps/contexts/Api/PostApi';
import GetApi from '../../helps/contexts/Api/GetApi';
import styles from '../../pages/Promotions/Promotions.module.css';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import UploadWithLabel from '../HelperComponents/UploadWithLabel';
import SubHeading from '../HelperComponents/SubHeading';
import MultiSelectComp from '../HelperComponents/MultiSelect';
import { Navigate } from 'react-router-dom';
import { constants } from '../../helps/contexts/Api/constants';
import JoditEditor from 'jodit-react';

import isValidUrl from '../../helps/urlValidator';

const { TextArea } = Input;

const EditPromotionForm = ({
  rowdata,
  onCancel,
  promoCategoryData,
  currencyData,
  getPromotions,
  getPromotionCategory
}) => {
  const editorRef = useRef();
  const token1 = localStorage.getItem('token');
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const { id } = rowdata;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dateNotValid, setDateNotValid] = useState(false);
  const [title, setTitle] = useState(rowdata && rowdata.title ? rowdata.title : '');
  const [subtitle, setSubTitle] = useState(rowdata && rowdata.sub_title ? rowdata.sub_title : '');
  const [currency, setCurrency] = useState(rowdata && rowdata.currency ? rowdata.currency : '');
  const [promoCategory, setPromoCategory] = useState([]);

  const buttons= [
    'source', '|',
    'bold',
    'strikethrough',
    'underline',
    'italic', '|',
    'ul',
    'ol', '|',
    'outdent', 'indent',  '|',
    'font',
    'fontsize',
    'brush',
    'paragraph', '|',
    'image',
    'video',
    'table',
    'link', '|',
    'align', 'undo', 'redo', '|',
    'hr',
    'eraser',
    'copyformat', '|',
    'symbol',
    'fullsize',
    'print',
    'about'
]

 
  const config = useMemo(
    () => ({
    readonly: false,
    placeholder: '',
    defaultActionOnPaste: 'insert_as_html',
    // defaultLineHeight: 1.5,
     enter: 'div',
    // enter: 'P',
    statusbar: false,
    height: 350,
    // sizeLG: 1000,
    // sizeMD: 700,
    // sizeSM: 600,
    addNewLine: false,
    // toolbarAdaptive: false,
    buttons : buttons,
    uploader: {
      insertImageAsBase64URI: true,
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif', 'svg', 'webp'],
    },
    }),
    [],
   );

  // const [image, setImage] = useState(
  //   rowdata && rowdata.image ? rowdata.image : ""
  // );
  // const [desktopImage, setDesktopImage] = useState(rowdata && rowdata.desktopImage ? rowdata.desktopImage : "");

  const [status, setStatus] = useState(rowdata && rowdata.status === 1 ? rowdata?.status : 0);
  const [imageData, setImageData] = useState({
    image: rowdata && rowdata.image ? rowdata.image : null,
    desktopImage: rowdata && rowdata.desktopImage ? rowdata.desktopImage : null
  });
  const [startDate, setStartDate] = useState(
    rowdata?.createddate ? moment((rowdata?.createddate).toLocaleString(), dateFormat) : null
  );
  const [endDate, setEndDate] = useState(
    rowdata?.expirydate ? moment((rowdata?.expirydate).toLocaleString(), dateFormat) : null
  );
  const [startTimeParam, setStartTimeParam] = useState(
    rowdata?.createddate ? moment((rowdata?.createddate).toLocaleString(), dateFormat) : null
  );
  const [endTimeParam, setEndTimeParam] = useState(
    rowdata?.expirydate ? moment((rowdata?.expirydate).toLocaleString(), dateFormat) : null
  );
  const [remark, setRemark] = useState(rowdata && rowdata.remark ? rowdata.remark : '');

  const [formErrors, setFormErrors] = useState({
    title: false,
    subtitle: false,
    image: false,
    desktopImage: false,
    startDate: false,
    endDate: false,
    remark: false,
    currency: false,
    category: false
  });

  const validateForm = () => {
    const errors = {
      title: !title,
      subtitle: !subtitle,
      image: !imageData.image,
      desktopImage: !imageData.desktopImage,
      startDate: !startDate,
      // endDate: !endDate,
      remark: !remark,
      currency: !currency,
      category: !promoCategory.length
    };
    setFormErrors(errors);

    return Object.values(errors).every((error) => !error);
  };

  //adding preloaded category data
  useEffect(() => {
    if (rowdata.promotion_category_ids.length) {
      const categoryIds = rowdata.promotion_category_ids.map((item) => item.id);
      setPromoCategory(categoryIds);
    }
  }, [rowdata]);

  const onChangeStatus = (value) => {
    setStatus(value);
    setFormErrors({ ...formErrors, status: false });
  };

  const onChangeCurrency = (value) => {
    setCurrency(value);
    setFormErrors({ ...formErrors, currency: false });
  };

  const onChangeCategory = (value, prop2, name) => {
    setPromoCategory(value);
    setFormErrors({ ...formErrors, category: false });
  };

  function onOk(value) {}

  //date picker startdate
  const onChange = (value, dateString) => {
    setStartDate(value);
    setStartTimeParam(dateString);
    setFormErrors({ ...formErrors, startDate: false });
  };

  //date picker start date completed
  //date picker end date

  const onChange1 = (value, dateString) => {
    setEndDate(value);
    setEndTimeParam(dateString);
    setFormErrors({ ...formErrors, endDate: false });
  };

  const onChangeRemark = (e) => {
    setRemark(e.target.value);
  };

  const getURLPath = (url) => {
    const newURL = new URL(url);
    return newURL.pathname;
  };

  //form submit to backend
  const onSubmit = async (e) => {
    if (isSubmitting) {
      return;
    }

    const payload = {
      title: title,
      sub_title: subtitle,
      status: status,
      image: isValidUrl(imageData.image) ? getURLPath(imageData.image) : imageData.image,
      desktop_image: isValidUrl(imageData.desktopImage) ? getURLPath(imageData.desktopImage) : imageData.desktopImage,
      start_date: startTimeParam ? moment(startTimeParam)._i : startTimeParam ? startTimeParam.toLocaleString() : null,
      end_date: endTimeParam ? moment(endTimeParam)._i : endTimeParam ? endTimeParam.toLocaleString() : null,
      body: remark,
      currency: currency,
      promotion_category_ids: promoCategory
    };
    const path = `/admin/promotions/${id}/update`;
    const url = `${constants.BASE_URL1}` + `${path}`;

    try {
      e.preventDefault();
      setIsSubmitting(true);
      if (!validateForm()) {
        message.error(lang.label_formvalidationerror);
        return;
      }

      if (endTimeParam) {
        if (startTimeParam > endTimeParam) {
          setDateNotValid(true);
          return;
        } else if (endTimeParam < startTimeParam) {
          setDateNotValid(true);
          return;
        }
      }
      const response = await axios.put(url, payload, {
        headers: {
          Authorization: `Bearer ${token1}`,
          'Content-Type': 'application/json'
        }
      });

      // sending request

      //if success
      if (response?.status === 200) {
        message.success(lang.label_editpromosucess);
        setDateNotValid(false);
        getPromotions();
        getPromotionCategory();
        onCancel();
      }

      if (response?.status === 401) {
        localStorage.clear();
        return <Navigate to="/" repalce />;
      }
    } catch (e) {
      if (e?.status === 422) {
        message.error(lang.label_editpromofail);
        Object.values(e.data.message).map((errors) => message.error(errors));
      }
    } finally {
      // Reset the submitting state after API call is complete
      setIsSubmitting(false);
    }
  };

  return (
    <div
      style={{
        width: '97%',
        height: '85%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: '3%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '-6%'
          }}>
          <Row>
            <Col span={22}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_title}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <Input
                required
                style={{
                  width: '100%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                onChange={(e) => setTitle(e.target.value)}
                name="title"
                value={title}
              />
            </Col>

            <Col span={22}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_subtitle}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <Input
                required
                style={{
                  width: '100%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                onChange={(e) => setSubTitle(e.target.value)}
                name="subtitle"
                value={subtitle}
              />
            </Col>

            <Col span={22}>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div className={styles.flexColumn} style={{ marginTop: '15px' }}>
                  <UploadWithLabel
                    name="promotions"
                    label={
                      <SubHeading className={styles.heading}>
                        {lang.label_image}
                        <span className={styles.redSteric}> *</span>
                      </SubHeading>
                    }
                    initialImageUrl={imageData.image || null}
                    onChange={(val) => setImageData({ ...imageData, image: val })}
                  />
                  {formErrors?.image && <p className="error">Image is required</p>}
                </div>
                <div className={styles.flexColumn} style={{ marginTop: '15px' }}>
                  <UploadWithLabel
                    name="promotions"
                    label={
                      <SubHeading className={styles.heading}>
                        {lang.label_desktopImage}
                        <span className={styles.redSteric}> *</span>
                      </SubHeading>
                    }
                    initialImageUrl={imageData.desktopImage || null}
                    onChange={(val) => setImageData({ ...imageData, desktopImage: val })}
                  />
                  {formErrors?.desktopImage && <p className="error">Desktop Image is required</p>}
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <label>{lang.label_currency}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />
              <Select
                required
                onChange={onChangeCurrency}
                value={currency}
                style={{
                  width: '184%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                options={currencyData}
              />
              {formErrors.currency && <span style={{ color: 'red' }}>currency is required</span>}
            </Col>
          </Row>

          <Row>
            <Col span={22}>
              <label>{lang.label_promotion_category_id}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <MultiSelectComp
                name="categories"
                style={{
                  width: '184%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                options={promoCategoryData.filter((category) => category.value !== 0)}
                extraStyle={{
                  width: '100%',
                  height: '40px'
                }}
                value={promoCategory}
                handleChange={onChangeCategory}
              />
              {formErrors.category && <span style={{ color: 'red' }}>category is required</span>}
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <label>{lang.label_status}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <Select
                defaultValue={rowdata?.status}
                onChange={onChangeStatus}
                style={{
                  width: '184%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                options={[
                  {
                    key: 1,
                    value: 1,
                    label: 'Visible'
                  },
                  {
                    key: 0,
                    value: 0,
                    label: 'Hidden'
                  }
                ]}
              />
            </Col>
            <Col span={22}>
              <label>{lang.label_starteddate}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <DatePicker
                defaultValue={moment(rowdata?.createddate, dateFormat)}
                showTime
                onOk={onOk}
                placeholder="Select Time"
                format="YYYY-MM-DD HH:mm:ss"
                onChange={onChange}
                style={{
                  height: '30px',
                  width: '100%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                value={startDate}
              />
              {formErrors.startDate && <span style={{ color: 'red' }}>Start Date is required</span>}
              {dateNotValid && <span style={{ color: 'red' }}>Please check the date</span>}
            </Col>
          </Row>

          <Row>
            <Col span={22}>
              <label>{lang.label_expirydate}</label>
              {/* <span style={{ color: 'red' }}>*</span> */}
              <br />

              <DatePicker
                // showTime={{
                //   defaultValue: dayjs('00:00:00', 'HH:mm:ss')
                // }}
                defaultValue={moment(rowdata?.expirydate, dateFormat)}
                onOk={onOk}
                onChange={onChange1}
                placeholder="Select Time"
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                style={{
                  height: '30px',
                  width: '100%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                value={endDate}
              />
              {/* {formErrors.endDate && <span style={{ color: 'red' }}>End Date is required</span>} */}
              {dateNotValid && <span style={{ color: 'red' }}>Please check the date</span>}
            </Col>
          </Row>

          <Row>
            <Col span={22}>
              <label>{lang.label_remark}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              {/* <Editor
                  apiKey='ud5rtcrk9pwxbwx1bza0kdc97yl36hjtwjmwopxwzvuu70gx'
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  value={remark}
                  onEditorChange={(e, editor) => setRemark(e)}
                  init={{
                    menubar: true,
                    height: 300,
                    selector: 'textarea#local-upload',
                    plugins: 'image code',
                  }}
                /> */}

              <JoditEditor
                ref={(evt, editor) => (editorRef.current = editor)}
                value={remark}
                config={config}
                tabIndex={1}
                //onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onBlur={(e, editor) => setRemark(e)}
              />
              {/* <TextArea rows={10} placeholder="" value={remark} onChange={onChangeRemark} /> */}
            </Col>
          </Row>
          <br />
          <br />
          <br />

          <div style={{ marginTop: '5px' }}>
            {' '}
            <Button
              style={{
                borderRadius: '3px',
                backgroundColor: '#004A7F',
                color: 'white',
                position: 'absolute',
                right: '25%',
                top: '93%'
              }}
              htmlType="submit">
              {lang.label_submit}
            </Button>
            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                position: 'absolute',
                right: '8%',
                top: '93%'
              }}
              onClick={onCancel}>
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditPromotionForm;
