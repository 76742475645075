import { Input, Button, Row, Col, Select, DatePicker, message } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState, useEffect } from 'react';
import GetApi from '../../../helps/contexts/Api/GetApi';
import PostApi from '../../../helps/contexts/Api/PostApi';
import '../../../styles/ButtonStyle.css';

const CreateMessage = ({
  handleCancel,

  getBankmessages
}) => {
  // form states
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { TextArea } = Input;
  const [date, setDate] = useState('');
  const [message1, setMessage] = useState('');
  const [bankList, setBankList] = useState([]);
  const [bankName, setBankname] = useState('');
  const [TimeParam, setTimeParam] = useState('Y-m-d H:i:s');
  const [success, setSuccess] = useState('');
  const [bankselect, setBankselect] = useState();
  const { Option } = Select;
  useEffect(() => {
    getBankList();
  }, []);

  //request to backend
  const onSubmit = async (e) => {
    if (bankselect === null || message1 === '' || TimeParam === '') {
      message.error(lang.label_Bankmessagecreatefail);
      message.error('Please Fill All The Fields');
    }
    try {
      e.preventDefault();

      const path = `/admin/bank_messages/create`;

      // sending request
      const res = await PostApi.PostApiRequest(path, {
        company_bank_id: bankselect,
        content: message1,
        message_time: TimeParam
      });

      //if success
      if (res?.status === 200) {
        message.success(lang.label_Bankmessagecreatesuccess);
        setBankselect('--Select a Bank--');
        setMessage('');
        setDate('');

        handleCancel();

        getBankmessages();
      } else if (!res) {
        message.error(lang.label_Bankmessagecreatefail);
      }
    } catch (e) {
      if (e?.status === 422) {
        Object.values(e.data.message).map((errors) => message.error(errors));
      }
    }
  };

  function onOk(value) {}
  //date picker startdate
  const onChange = (value, dateString) => {
    setDate(value);
    setTimeParam(dateString);
  };
  //date picker start date completed

  // listing bank

  const getBankList = async () => {
    try {
      const res = await GetApi.sendApiRequest('/company/banks');

      if (res && res.length) {
        const BankArray = res.map((bank) => ({
          key: bank.id,
          bankholdername: bank.bank_holder_name,
          bankname: bank.bank_code
        }));
        setBankList(BankArray);
      }
    } catch (e) {}
  };
  //form value bank select
  function handleChange(value) {
    setBankselect(value);
  }

  return (
    <div
      style={{
        width: '97%',
        height: '85%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      {success}
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: '3%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '-6%'
          }}>
          <Row>
            <Col span={24}>
              <label style={{ paddingBottom: '3px' }}>{lang.label_bankname}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />
              <Select
                defaultValue={'--Select A Bank--'}
                style={{ width: '93%', marginTop: '2px', right: '0.5%' }}
                onChange={handleChange}>
                {bankList.map((bank, index) => (
                  <Option key={index} value={bank.key}>
                    {bank.bankname}-{bank.bankholdername}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <br />

          <Row>
            <Col span={12}>
              <label>{lang.label_message}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <>
                <TextArea
                  showCount
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  style={{
                    width: '186%',
                    height: '100px',
                    borderRadius: '3px',
                    marginBottom: '5px',
                    marginTop: '5px'
                  }}
                  value={message1}
                />
              </>
            </Col>
          </Row>

          <br />

          <br />
          <Row>
            <Col span={24}>
              <label>{lang.label_incomingdate}</label>
              <span style={{ color: 'red' }}>*</span>

              <DatePicker
                onOk={onOk}
                placeholder="Select Time"
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                onChange={onChange}
                style={{
                  height: '35px',
                  borderRadius: '3px',
                  width: '93%',
                  marginTop: '5px'
                }}
                value={date}
              />
            </Col>
          </Row>
          <br />
          <br />
          <br />

          <div style={{}}>
            {' '}
            <Button
              style={{
                borderRadius: '3px',
                backgroundColor: '#004A7F',
                color: 'white',
                position: 'absolute',
                right: '30%',
                top: '90%'
              }}
              htmlType="submit"
              className="btnStyle">
              {lang.label_submit}
            </Button>
            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                position: 'absolute',

                right: '5%',
                top: '90%'
              }}
              onClick={() => {
                setBankselect('--Select a Bank--');
                setMessage('');
                setDate('');
                handleCancel();
              }}
              className="btnReset">
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateMessage;
