import LangContext from '../../../helps/contexts/lang-context';
import React, { useEffect, useState, useContext } from 'react';
import styles from '../../../components/HelperComponents/Filter.module.css';
import Heading from '../../../components/HelperComponents/Heading';
import { Button, Col, Row, Tag, message } from 'antd';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import CustomButton from '../../../components/HelperComponents/CustomButton';
import Spacer from '../../../components/HelperComponents/Spacer';
import Loader from '../../../components/HelperComponents/Loader';
import {
  createGameCategory,
  deleteGameCategory,
  getGameCategories,
  getGameProperties,
  sortingGameCategory,
  updateGameCategory,
  getAllGameCategories as getAllGameCategoriesApi
} from '../../../helps/contexts/Api/api';
import { CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined, SyncOutlined } from '@ant-design/icons';
import CustomModal from '../../../components/HelperComponents/CustomModal';
import CreateModal from './CreateModal/CreateModal';
import Action from './Action';
import MiniText from '../../../components/HelperComponents/MiniText';
import CustomBadge from '../../../components/HelperComponents/CustomBadge';
import DragTableComp from '../../../components/HelperComponents/DragTableComp';
import { useNavigate } from 'react-router-dom';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import DeleteModal from './DeleteModal';
import { convertUTCToLocalTime } from '../../../helps/HelperFunctions';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import SelectBox from '../../../components/HelperComponents/SelectBox';
import isValidUrl from '../../../helps/urlValidator';

const GameCategories = () => {
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();
  const lang = ctx.lang;
  const Navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterActive, setIsFilterActive] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isPageChange, setIsPageChange] = useState(true);
  const [modalType, setModalType] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [item, setItem] = useState({});
  const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([]);
  const [properties, setProperties] = useState([]);
  const [perPage, setPerPage] = useState(0);
  const [totalLength, setTotalLength] = useState(0);

  const [formData, setFormData] = useState({
    search: 0,
    categoryName: '',
    categoryNameVt: '',
    categoryNameTl: '',
    categoryNameBn: '',
    icon: '',
    iconactive: '',
    bg_image: '',
    is_lobby: 0,
    status: 1
  });

  const status = [
    {
      id: 1,
      value: lang.label_active
    },
    {
      id: 0,
      value: lang.label_inactive
    }
  ];

  const handleInputChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  const handleFilterSelectChange = (value, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const resetFunc = () => {
    setFormData({
      search: 0,
      categoryName: '',
      categoryNameVt: '',
      categoryNameTl: '',
      categoryNameBn: '',
      icon: '',
      iconactive: '',
      bg_image: '',
      status: 1
    });
    setCurrentPage(1);
    setIsFilterActive(true);
  };
  const searchFunc = () => {
    if (formData.search) {
      setIsFilterActive(true);
      setCurrentPage(1);
    }
  };
  const onChangePage = (page) => {
    if (formData.search) {
      setIsFilterActive(true);
    }
    setCurrentPage(page);
    setIsPageChange(true);
  };
  const handleOpenModal = (type, item = {}) => {
    setModalType(type);
    setModalVisible(true);
    const name = item?.hasOwnProperty('name') ? JSON.parse(item?.name) : {};
    const englishName = name['en'];
    const vietnameseName = name['vn'];
    const banglaName = name['bn'];
    const phillipineName = name['tl'];
    item &&
      setItem({
        ...item,
        categoryName: englishName,
        categoryNameVt: vietnameseName,
        categoryNameBn: banglaName,
        categoryNameTl: phillipineName,
        categoryId: item.id
      });
  };
  const columns = [
    {
      title: lang.label_sort,
      dataIndex: 'sort_order',
      key: 'sort_order'
    },
    {
      title: lang.label_category,
      dataIndex: 'name',
      key: 'name',
      render: (record, row) => {
        return <div>{record ? JSON.parse(record).en : '-'} </div>;
      }
    },
    {
      title: lang.label_parent_category_name,
      dataIndex: 'parent_category',
      key: 'parent_category',
      render: (record, row) => {
        return <div>{record && record.name ? JSON.parse(record.name).en : '-'} </div>;
      }
    },
    {
      title: lang.label_icon,
      dataIndex: 'icon_image',
      key: 'icon_image',
      render: (record, row) => (
        <div
          style={{
            height: '30px',
            width: '35px',
            objectFit: 'contain'
          }}>
          <img
            style={{
              height: '100%',
              width: '100%'
            }}
            src={record}
            alt="icon"
          />
        </div>
      )
    },
    {
      title: lang.label_iconactive,
      dataIndex: 'icon_active',
      key: 'icon_active',
      render: (record, row) => (
        <div
          style={{
            height: '30px',
            width: '35px',
            objectFit: 'contain'
          }}>
          <img
            style={{
              height: '100%',
              width: '100%'
            }}
            src={record}
            alt="icon"
          />
        </div>
      )
    },

    {
      title: lang.label_status,
      dataIndex: 'status',
      key: 'status',
      render: (record, row) => <Tag color={record === 1 ? 'green' : 'red'}>{record === 1 ? 'Active' : 'Inactive'}</Tag>
    },
    {
      title: 'Hidden',
      dataIndex: 'status',
      key: 'status',
      render: (record, row) => <Tag color={record === 1 ? 'green' : 'red'}>{record === 1 ? 'Active' : 'Inactive'}</Tag>
    },
    {
      title: 'Common',
      dataIndex: 'status',
      key: 'status',
      render: (record, row) => <Tag color={record === 1 ? 'green' : 'red'}>{record === 1 ? 'Active' : 'Inactive'}</Tag>
    },
    {
      title: lang.label_updatedtime,
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (record, row) => (record ? <span>{convertUTCToLocalTime(record)}</span> : null)
    },
    // {
    //   title: lang.label_updatedby,
    //   dataIndex: "updatedBy",
    //   key: "updatedBy",
    // },

    ...(hasPermission('Create Game Category')
      ? [
          {
            title: lang.label_actions,
            dataIndex: 'actions',
            key: 'actions',
            render: (record, row) => <Action handleOpenModal={handleOpenModal} item={row} />
          }
        ]
      : [])
  ];

  const getAllCategories = async () => {
    const payload = {
      token: localStorage.getItem('token')
    };
    var res = await getAllGameCategoriesApi(payload);
    if (res && res?.status === 200) {
      const temp = res?.data;
      // const newObject = { id: 0, value: "Select Category" };
      if (res.data.length) {
        const data = res.data.map(({ name, id }) => {
          const jsonString = JSON.parse(name);
          return { value: jsonString['en'], id };
        });
        setCategories(data);
      }
      // data.unshift(newObject);
    }
  };

  const getAllGameProperties = async () => {
    const payload = {
      token: localStorage.getItem('token')
    };

    var res = await getGameProperties(payload);
    if (res && res?.status === 200) {
      if (res.data) {
        const data = Object.keys(res.data).map((key) => ({
          id: key,
          value: res.data[key]
        }));
        setProperties(data);
      }
    }
  };

  useEffect(() => {
    getAllCategories();
    getAllGameProperties();
  }, []);
  const getAllGameCategories = async () => {
    try {
      const payload = {
        token: localStorage.getItem('token'),
        isFilterActive: isFilterActive,
        page: currentPage,
        game_category_id: formData?.search
      };

      if (isFilterActive || isPageChange) {
        setLoading(true);
        var res = await getGameCategories(payload);
      }

      if (res && res?.status === 200 && res?.data?.data) {
        setLoading(false);
        setData(res?.data?.data);
        setIsFilterActive(false);
        setIsPageChange(false);

        setTotalLength(res?.data?.total);
        setPerPage(res?.data?.per_page);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getAllGameCategories();
  }, [currentPage, isFilterActive]);

  const handleCloseModal = () => {
    setModalVisible(false);
    setFormData({
      search: 0,
      categoryName: '',
      categoryNameVt: '',
      categoryNameTl: '',
      categoryNameBn: '',
      icon: '',
      status: 1,
      iconactive: '',
      is_lobby: 0,
      bg_image: ''
    });
  };

  const submitFunc = async () => {
    const payload = {
      en: formData?.categoryName,
      vn: formData?.categoryNameVt,
      bn: formData?.categoryNameBn,
      tl: formData?.categoryNameTl,
      icon_image: formData?.icon,
      icon_active: formData?.iconactive,
      bg_image: formData?.bg_image,
      status: Number(formData?.status),
      is_lobby: formData?.is_lobby,
      parent_category_id: formData?.parent_category_id,
      properties: formData.properties.map((item) => item.id),
      icon_trend: formData.icon_trend
    };

    try {
      if (payload?.en?.trim() === '') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameError: 'Name is required'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameError: ''
        }));
      }
      if (!payload?.icon_image) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          imageError: 'Please upload an image'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          imageError: ''
        }));
      }

      if (payload?.en.trim() !== '' && payload?.icon_image !== '') {
        const token = localStorage.getItem('token');
        setSubmitLoading(true);

        const res = await createGameCategory(token, payload);
        if (res.status === 200) {
          message.success(lang.label_gamecataddsuccess);
          setItem({});
          setSubmitLoading(false);
          handleCloseModal();

          setIsFilterActive(true);
        }
        setErrors({});
      }
    } catch (error) {
      setSubmitLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }

      message.error(error.response?.data?.message);
    }
  };

  const getURLPath = (url) => {
    const newURL = new URL(url);
    return newURL.pathname;
  };

  const updateFunc = async () => {
    const payload = {
      id: item?.id,
      en: formData?.categoryName,
      vn: formData?.categoryNameVt,
      bn: formData?.categoryNameBn,
      tl: formData?.categoryNameTl,
      icon_image: isValidUrl(formData?.icon) ? getURLPath(formData?.icon) : formData?.icon,
      icon_active: isValidUrl(formData?.iconactive) ? getURLPath(formData?.iconactive) : formData?.iconactive,
      bg_image: isValidUrl(formData?.bg_image) ? getURLPath(formData?.bg_image) : formData?.bg_image,
      status: formData?.status && Number(formData?.status),
      is_lobby: formData?.is_lobby,
      parent_category_id: formData?.parent_category_id,
      properties: formData.properties.map((item) => item.id),
      icon_trend: formData.icon_trend
    };
    try {
      if (payload?.en.trim() === '' || !payload?.en.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameError: 'Name is required'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameError: ''
        }));
      }

      if (payload?.icon_image === '' || !payload?.icon_image) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          imageError: 'Please upload an image'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          imageError: ''
        }));
      }
      if (payload?.en.trim() && payload?.icon_image) {
        const token = localStorage.getItem('token');

        setSubmitLoading(true);
        const res = await updateGameCategory(token, payload);
        if (res.status === 200) {
          message.success(lang.label_gamecateditsuccess);
          setItem({});
          setSubmitLoading(false);
          handleCloseModal();

          setIsFilterActive(true);
        }
        setErrors({});
      }
    } catch (error) {
      setSubmitLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
      message.error(error.response.data.message);
    }
  };
  const handleSelectChange = (value, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    // setItem({
    //   ...item,
    //   status: value,
    // });
  };

  const deleteFunc = async () => {
    try {
      const token = localStorage.getItem('token');
      setSubmitLoading(true);
      const res = await deleteGameCategory(token, item?.id);
      if (res.status === 200) {
        message.success(lang.label_gamedelsuccess);
        setItem({});

        setSubmitLoading(false);
        handleCloseModal();

        setIsFilterActive(true);
      }
    } catch (error) {
      setSubmitLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
      message.error(error.response.data.message);
    }
  };

  return (
    <>
      <HeaderCardDetails />
      <div style={{ padding: '20px' }}>
        <div className={styles.container}>
          <div
            style={{
              display: 'flex',
              borderBottom: '1px solid #d9d9d9',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '48px',
              padding: '0px 13px'
            }}>
            <Heading>{lang.label_filters}</Heading>
            {hasPermission('Create Game Category') && (
              <div style={{ width: '100%', display: 'flex', flexDirection: 'flex-end' }}>
                <Col
                  // xs={24}
                  // sm={24}
                  // md={24}
                  // lg={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    textAlign: 'left',
                    width: '100%'
                  }}>
                  <div
                    style={{
                      paddingRight: '15px'
                    }}>
                    <Button
                      className="btnStyle"
                      style={{
                        borderRadius: '3px',
                        padding: '6px',
                        width: '150px'
                      }}
                      onClick={() => handleOpenModal('new')}>
                      {lang.label_addnewcategory}
                    </Button>
                  </div>
                </Col>
              </div>
            )}
          </div>
          <div className={styles.filterLowerContainer}>
            <Row style={{ height: '20px' }}>
              {/* <Col xs={24} sm={24} md={24} lg={12}>
              <div className={styles.scndRow}>
                <div className={styles.flexStyle}>
                  <SubHeading>{lang.label_category}</SubHeading>
                  <InputField
                    placeHolder="Search..."
                    type="text"
                    name="search"
                    value={formData.search}
                    onChange={handleInputChange}
                  />
                  <SelectBox
                    name="search"
                    value={formData.search}
                    onChange={handleFilterSelectChange}
                    data={categories}
                  />

                </div>
                <div className={styles.flexStyle}>
                  <CustomButton
                    type={styles.mainBg}
                    onClick={searchFunc}
                    text={lang.label_search}
                  />
                  <CustomButton
                    type={styles.whiteBg}
                    onClick={resetFunc}
                    text={lang.label_reset}
                  />
                </div>
              </div>
            </Col> */}
            </Row>
          </div>
        </div>
        <Spacer height="30px" />
        {loading ? (
          <Loader />
        ) : (
          <DragTableComp
            dataSource={data}
            columns={columns}
            setData={setData}
            sortingfunc={sortingGameCategory}
            totalLength={totalLength}
            perPage={perPage}
            onChangePage={onChangePage}
            currentPage={currentPage}
          />
        )}

        <CustomModal
          visible={modalVisible}
          onCancel={handleCloseModal}
          title={
            modalType === 'new'
              ? lang.label_addnewcategory
              : modalType === 'update'
              ? lang.label_editcategory
              : modalType === 'delete' && lang.label_deletegamecat
          }
          subTitle={
            modalType === 'new'
              ? lang.label_addcatinfo
              : modalType === 'update'
              ? lang.label_editcatinfo
              : modalType === 'delete' && lang.label_delcat
          }
          content={
            modalType === 'new' || modalType === 'update' ? (
              <CreateModal
                errors={errors}
                setItem={setItem}
                updateFunc={updateFunc}
                item={item}
                type={modalType}
                formData={formData}
                handleCloseModal={handleCloseModal}
                submitFunc={submitFunc}
                submitLoading={submitLoading}
                setFormData={setFormData}
                handleInputChange={handleInputChange}
                handleSelectChange={handleSelectChange}
                status={status}
                categories={categories}
                gameProperties={properties}
              />
            ) : (
              modalType === 'delete' && (
                <DeleteModal
                  handleCloseModal={handleCloseModal}
                  submitLoading={submitLoading}
                  deleteFunc={deleteFunc}
                />
              )
            )
          }
          footer={<></>}
          modalStyle={{ width: '300px' }}
          titleStyle={
            modalType === 'delete' && {
              color: '#D40000'
            }
          }
          customWidth={modalType === 'delete' ? '400px' : '1200px'}
          contentStyle={{}}
          footerStyle={{}}
        />
      </div>
    </>
  );
};

export default GameCategories;
