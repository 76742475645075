import { Button, Col, Divider, Row, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import InputWithLabel from '../../../components/HelperComponents/InputWithLabel';
import SelectWithLabel from '../../../components/HelperComponents/SelectWithLabel';
import SwitchWithLabel from '../../../components/HelperComponents/SwitchWithLabel';
import { toFixedFunc } from '../../../helps/HelperFunctions';
import GetApi from '../../../helps/contexts/Api/GetApi';
import LangContext from '../../../helps/contexts/lang-context';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import Loader from '../../../components/HelperComponents/Loader';

const AddPaymentMethodPopup = ({
  formData,
  setFormData,
  formErrors,
  formLoading,
  setShowEditBankPopup,
  setEditMode,
  handleFormSubmit
}) => {
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();
  const [bankList, setBankList] = useState([]);
  const [allCurrency, setAllCurrency] = useState([]);
  const [paymentCategories, setPaymentCategory] = useState([]);
  const [paymentCodes, setPaymentCodes] = useState([]);

  const lang = ctx.lang;
  const { Option } = Select;
  const onSubmit = (e) => {
    e.preventDefault();
  };

  const getBankList = async () => {
    try {
      const res = await GetApi.sendApiRequest('/admin/bank/codes');

      if (res && res.length) {
        const object1 = {
          value: 'Please select a bank',
          label: 'Please select a bank'
        };

        const finalArray = res.map((item, index) => ({
          value: Number(item.id),
          label: item.code_name
        }));

        finalArray.unshift(object1);
        // console.log(finalArray)
        setBankList(finalArray);
      } else {
        return null;
      }
    } catch (e) {}
  };

  const getCurrencyList = async () => {
    try {
      const res = await GetApi.sendApiRequest('/player/currencies');
      if (res) {
        const object1 = {
          key: 'Please Select currency',
          value: 'Please Select currency',
          label: 'Please Select currency'
        };
        const finalArray = Object.keys(res).map((key, index) => {
          return {
            key: Number(key),
            value: Number(key),
            label: res[key]
          };
        });
        finalArray.unshift(object1);
        setAllCurrency(finalArray);
      } else {
        return null;
      }
    } catch (e) {}
  };

  const getPaymentCategories = async () => {
    try {
      const res = await GetApi.sendApiRequest('/payment/category/drop/down');
      if (res) {
        const object1 = {
          key: 'Please Select currency',
          value: 'null',
          label: 'Please Select Category'
        };
        const finalArray = res.map((item, index) => {
          return {
            key: Number(item.id),
            value: Number(item.id),
            label: item.name
          };
        });
        finalArray.unshift(object1);
        setPaymentCategory(finalArray);
      } else {
        return null;
      }
    } catch (e) {}
  };

  const getPaymentCodes = async () => {
    try {
      const res = await GetApi.sendApiRequest('/user/payment/code/enum');
      if (res) {
        const object1 = {
          key: 'Please Select currency',
          value: 'null',
          label: 'Please Select Category'
        };
        const finalArray = Object.keys(res).map((key, index) => {
          return {
            key: Number(key),
            value: Number(key),
            label: res[key]
          };
        });
        // console.log(finalArray)
        finalArray.unshift(object1);
        setPaymentCodes(finalArray);
      } else {
        return null;
      }
    } catch (e) {}
  };

  useEffect(() => {
    getBankList();
    getCurrencyList();
    getPaymentCategories();
    getPaymentCodes();
    // console.log(toFixedFunc(formData.balance))
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <Row gutter={[20, 20]}>
        <Col span={8}>
          <SelectWithLabel
            label={lang.label_bank_code}
            options={bankList}
            onChange={(val) => setFormData({ ...formData, bank_code_id: val })}
            value={formData.bank_code_id}
            isRequired={true}
          />
        </Col>
        <Col span={8}>
          <SelectWithLabel
            label={lang.label_payment_category}
            options={paymentCategories}
            onChange={(val) => setFormData({ ...formData, payment_category_id: val })}
            value={formData.payment_category_id}
            isRequired={true}
          />
        </Col>
        <Col span={8}>
          <SelectWithLabel
            label={lang.label_currency}
            options={allCurrency}
            onChange={(val) => setFormData({ ...formData, currency: val })}
            value={formData.currency}
            isRequired={true}
          />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={8}>
          <SelectWithLabel
            label={lang.label_payment_code}
            options={paymentCodes}
            onChange={(val) => setFormData({ ...formData, payment_code: val })}
            value={formData.payment_code}
            isRequired={true}
          />
        </Col>
        <Col span={8}>
          <InputWithLabel
            label={lang.label_accountname}
            value={formData.account_name}
            isRequired={true}
            onChange={(val) => setFormData({ ...formData, account_name: val })}
          />
        </Col>
        <Col span={8}>
          <InputWithLabel
            type="number"
            label={lang.label_bankaccountnumber}
            value={formData.account_number}
            isRequired={true}
            onChange={(val) => setFormData({ ...formData, account_number: val })}
          />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={8}>
          <InputWithLabel
            isError={formErrors.en_public_name}
            label={`${lang.label_name_public} (en)`}
            value={formData.en_public_name}
            isRequired={true}
            onChange={(val) => setFormData({ ...formData, en_public_name: val })}
          />
        </Col>
        <Col span={8}>
          <InputWithLabel
            isError={formErrors.vn_public_name}
            label={`${lang.label_name_public} (vn)`}
            value={formData.vn_public_name}
            isRequired={false}
            onChange={(val) => setFormData({ ...formData, vn_public_name: val })}
          />
        </Col>
        <Col span={8}>
          <InputWithLabel
            isError={formErrors.en_internal_name}
            label={`${lang.label_internal_name} (en)`}
            value={formData.internal_name}
            isRequired={true}
            onChange={(val) => setFormData({ ...formData, internal_name: val })}
          />
        </Col>
        <Col span={8} style={{ display: 'flex', justifyContent: 'space-around' }}>
          <SwitchWithLabel
            label={lang.label_allowdeposit}
            value={formData.allow_deposit ? true : false}
            onChange={(checked) => setFormData({ ...formData, allow_deposit: checked ? 1 : 0 })}
          />
          <SwitchWithLabel
            label={lang.label_allowwithdraw}
            value={formData.allow_withdraw ? true : false}
            onChange={(checked) => setFormData({ ...formData, allow_withdraw: checked ? 1 : 0 })}
          />
          <SwitchWithLabel
            label={lang.label_maintainance}
            value={formData.under_maintenance ? true : false}
            onChange={(checked) => setFormData({ ...formData, under_maintenance: checked ? 1 : 0 })}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[20, 20]}>
        <Col span={8}>
          <InputWithLabel
            label={lang.label_balance}
            type="number"
            value={formData.balance}
            isRequired={true}
            onChange={(val) => setFormData({ ...formData, balance: val })}
          />
        </Col>
        <Col span={8}>
          <InputWithLabel
            label={lang.label_bank_max_limit_today}
            type="number"
            value={formData.max_daily_amount}
            isRequired={false}
            onChange={(val) => setFormData({ ...formData, max_daily_amount: val })}
          />
        </Col>
        <Col span={8}>
          <InputWithLabel
            label={lang.label_max_total_amt}
            type="number"
            value={formData.max_total_amount}
            isRequired={false}
            onChange={(val) => setFormData({ ...formData, max_total_amount: val })}
          />
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={6}>
          <InputWithLabel
            label={lang.label_minimum_deposit}
            type="number"
            value={formData.min_deposit_amount}
            isRequired={true}
            onChange={(val) => setFormData({ ...formData, min_deposit_amount: val })}
          />
        </Col>
        <Col span={6}>
          <InputWithLabel
            label={lang.label_maximum_deposit}
            type="number"
            value={formData.max_deposit_amount}
            isRequired={true}
            onChange={(val) => setFormData({ ...formData, max_deposit_amount: val })}
          />
        </Col>
        <Col span={6}>
          <InputWithLabel
            label={lang.label_minimum_withdraw}
            type="number"
            value={formData.min_withdraw_amount}
            isRequired={true}
            onChange={(val) => setFormData({ ...formData, min_withdraw_amount: val })}
          />
        </Col>
        <Col span={6}>
          <InputWithLabel
            label={lang.label_maximum_withdraw}
            type="number"
            value={formData.max_withdraw_amount}
            isRequired={true}
            onChange={(val) => setFormData({ ...formData, max_withdraw_amount: val })}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[20, 20]}>
        <Col span={6}>
          <InputWithLabel
            label={lang.label_apikey}
            value={formData.api_key}
            isRequired={false}
            onChange={(val) => setFormData({ ...formData, api_key: val })}
          />
        </Col>
        <Col span={6}>
          <InputWithLabel
            label={lang.label_callbackkey}
            value={formData.callback_key}
            isRequired={false}
            onChange={(val) => setFormData({ ...formData, callback_key: val })}
          />
        </Col>
        <Col span={6}>
          <InputWithLabel
            label={lang.label_apiurl}
            value={formData.api_url}
            isRequired={false}
            onChange={(val) => setFormData({ ...formData, api_url: val })}
          />
        </Col>
        <Col span={6}>
          <InputWithLabel
            label={lang.label_remark}
            value={formData.remark}
            isRequired={false}
            onChange={(val) => setFormData({ ...formData, remark: val })}
          />
        </Col>
      </Row>
      <div
        style={{
          marginTop: '2%',
          display: 'flex',
          justifyContent: 'flex-end'
          // gap: '50px',
          //   width: '92%'
        }}>
        {!formLoading ? (
          <Button
            style={{
              backgroundColor: '#004A7F',
              color: 'white',
              width: '80px',
              height: '35px',
              background: '#004A7F 0% 0% no-repeat padding-box',
              borderRadius: '3px',
              opacity: '1',
              margin: '0px 10px'
            }}
            onClick={handleFormSubmit}>
            {lang.label_submit}
          </Button>
        ) : (
          <Loader size={'small'} />
        )}
        <Button
          style={{
            borderRadius: '3px',
            color: '#004A7F',
            width: '80px',
            height: '35px'
          }}
          onClick={() => {
            setShowEditBankPopup(false);
            setEditMode(false);
          }}>
          {lang.label_cancel}
        </Button>
      </div>
    </form>
  );
};

export default AddPaymentMethodPopup;
