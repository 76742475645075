import React, { useState } from 'react';
import { Card } from 'antd';
import { useContext } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import './CustomChart.css';
// import styles from './CustomChart.module.css'
import MonthlyTransactionChart from './MonthlyTransactionChart/MonthlyTransactionChart';
import ActiveTabIcon from '../../../../src/images/Active tab.png';
import InactiveTabIcon from '../../../../src/images/Inactive tab.png';

const tabs = [
  {
    id: 'transaction',
    label: 'Transaction History'
  },
  {
    id: 'deposit',
    label: 'Deposit History'
  },
  {
    id: 'withdrawal',
    label: 'Withdrawal History'
  },
  {
    id: 'profit',
    label: 'Profit History'
  }
];
const CustomChart = ({ days, setDays, monthlyTransactionResponse, dailyTransactionResponse }) => {
  const ctx = useContext(LangContext);
  const [selectedTab, setSelectedTab] = useState(tabs[0]?.id);

  return (
    <div className="customCharts">
      <div className="tabsContainer">
        {tabs.map((item) => (
          <div key={item.id} className="singleTabStyle" onClick={() => setSelectedTab(item.id)}>
            {item.id === selectedTab ? <img src={ActiveTabIcon} /> : <img src={InactiveTabIcon} />}
            <div className={item.id === selectedTab ? 'activeTabTextStyle' : 'tabTextStyle'}>{item.label}</div>
          </div>
        ))}
      </div>
      <div className="chartContainer">
        <MonthlyTransactionChart transactionResponse={monthlyTransactionResponse} type={selectedTab} />
      </div>
      {/* <div className="chartContainer mt-2">
        <DailyTransactionChart transactionResponse={dailyTransactionResponse} />
      </div> */}
    </div>
  );
};

export default CustomChart;
