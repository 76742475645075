import styles from '../../../components/HelperComponents/Filter.module.css';
import Heading from '../../../components/HelperComponents/Heading';
import { Button, Col, DatePicker, Input, Modal, Row, message } from 'antd';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import SearchIcon from "../../../images/Search.png";
import ResetIcon from "../../../images/Reset.png";
import CustomButton from '../../../components/HelperComponents/CustomButton';
import Spacer from '../../../components/HelperComponents/Spacer';
import Loader from '../../../components/HelperComponents/Loader';
import {
  createGameList,
  getGameCategories,
  getGameList,
  getPlatforms,
  sortingGameList,
  updateGameList,
  deleteGameList,
  getPlatformsForDropdown,
  getAllGameCategories,
  updateMaintenanceGameItem
} from '../../../helps/contexts/Api/api';
import SelectBox from '../../../components/HelperComponents/SelectBox';
import SearchSelectBox from '../../../components/HelperComponents/SearchSelectBox';
import CustomModal from '../../../components/HelperComponents/CustomModal';
import CreateModal from './CreateModal/CreateModal';
import Action from '../GameCategories/Action';
import CustomBadge from '../../../components/HelperComponents/CustomBadge';
import DragTableComp from '../../../components/HelperComponents/DragTableComp';
import MiniText from '../../../components/HelperComponents/MiniText';
import DeleteModal from './DeleteModal';
import { useNavigate } from 'react-router-dom';
import LangContext from '../../../helps/contexts/lang-context';
import React, { useEffect, useState, useContext } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined, SyncOutlined } from '@ant-design/icons';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import isValidUrl from '../../../helps/urlValidator';
import { convertLocalTimeToUTC, convertUTCToLocalTime } from '../../../helps/HelperFunctions';
import Maintenece from './Maintenece';
import { CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import { type } from '@testing-library/user-event/dist/type';

const GameList = () => {
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();

  const lang = ctx.lang;

  const Navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterActive, setIsFilterActive] = useState(true);
  const [perPage, setPerPage] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isPageChange, setIsPageChange] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');
  const [item, setItem] = useState({});
  const [platForms, setPlatForms] = useState([]);
  const [createPlatforms, setCreatePlatforms] = useState([]);
  const [createCategories, setCreateCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [gameId, setGameId] = useState(null);
  const [maintenanceModalVisible, setMaintenanceModalVisible] = useState(false);
  const [maintenanceUntil, setMaintenanceUntil] = useState();
  const [formData, setFormData] = useState({
    englishName: '',
    vietnamName: '',
    game_platform_id: 0,
    game_category_id: [],
    icon: '',
    status: true,
    game_id: '',
    type:'',
    iconsquare: null,
    iconrectangle: null,
    currency: [],
    property: []
  });
  const [filterData, setFilterData] = useState({
    game_platform_id: 0,
    game_category_id: 0,
    name: '',
    game_code: ''
  });
  const STATUS_ACTIVE = 1;
  const STATUS_INACTIVE = 2;
  // const STATUS_UNDER_MAINTENANCE = 3;

  const handleSwitchChange = (checked) => {
    setFormData({
      ...formData,
      status: checked ? STATUS_ACTIVE : STATUS_INACTIVE
    });
  };

  useEffect(() => {
    if (platForms.length >= 1) {
      setCreatePlatforms(platForms);
    }
  }, [platForms]);

  useEffect(() => {
    if (categories.length >= 1) {
      const convertedArray = categories.map((item) => ({
        value: item.id,
        label: item.value
      }));
      setCreateCategories(convertedArray);
    }
  }, [categories]);

  const handleInputChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  const titleStyles = {
    fontFamily: 'Roboto ',
    fontWeight: 'medium ',
    fontStyle: 'normal ',
    fontSize: '16px',
    lineheight: '26px ',
    letterSpacing: '0px ',
    color: '#004A7F ',
    margin: '0 ',
    padding: '0 ',
    height: 'auto',
    width: 'auto',
    fontWeight: '600'
  };
  const iconStyle = { color: '#004A7F' };
  const datePickerStyle = {
    width: '200px',
    height: '35px',
    background: ' #FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const inputStyle = {
    width: '200px',
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const selectStyle = {
    width: '200px',
    height: '35px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '3px'
  };
  const resetFunc = () => {
    // setFormData({
    //   englishName: "",
    //   vietnamName: "",
    //   game_platform_id: 0,
    //   game_category_id: 0,
    //   icon: "",
    //   status: true,
    //   gameId: "",
    //   iconsquare: "",
    //   iconrectangle: "",
    // });
    setFilterData({
      game_platform_id: 0,
      game_category_id: 0,
      name: '',
      game_code: ''
    });
    setCurrentPage(1);
    setIsFilterActive(true);
  };

  const searchFunc = () => {
    if (
      (filterData.game_platform_id !== 0 && filterData.game_platform_id) ||
      (filterData.game_category_id !== 0 && filterData.game_category_id) ||
      (filterData.name !== '' && filterData.name) ||
      (filterData.game_code !== '' && filterData.game_code)
    ) {
      setIsFilterActive(true);
      setCurrentPage(1);
    }
  };
  const onChangePage = (page) => {
    if (
      (filterData.game_platform_id !== 0 && filterData.game_platform_id) ||
      (filterData.game_category_id !== 0 && filterData.game_category_id) ||
      (filterData.name !== '' && filterData.name) ||
      (filterData.game_code !== '' && filterData.game_code)
    ) {
      setIsFilterActive(true);
    }
    setCurrentPage(page);
    setIsPageChange(true);
  };
  const columns = [
    // {
    //   title: lang.label_sort,
    //   dataIndex: "sort",
    //   key: "sort",
    // },
    {
      title: lang.label_platformname,
      dataIndex: 'platformName',
      key: 'platformName'
    },
    {
      title: lang.label_category,
      dataIndex: 'categoryName',
      key: 'categoryName'
    },
    {
      title: 'Currencies',
      dataIndex: 'currencies',
      key: 'currencies'
    },
    {
      title: 'Properties',
      dataIndex: 'properties',
      key: 'properties'
    },
    {
      title: lang.label_gameid,
      dataIndex: 'gameId',
      key: 'gameId'
    },
    {
      title: lang.label_gamename,
      dataIndex: 'gameName',
      key: 'gameName'
    },
    {
      title: lang.label_iconsq,
      dataIndex: 'iconsquare',
      key: 'iconsquare'
    },
    {
      title: lang.label_iconrec,
      dataIndex: 'iconrectangle',
      key: 'iconrectangle'
    },
    // {
    //   title: lang.label_icon,
    //   dataIndex: "icon",
    //   key: "icon",
    // },
    {
      title: lang.label_status,
      dataIndex: 'status',
      key: 'status'
    },
    ...(hasPermission('Create Game Item')
      ? [
          {
            title: lang.label_actions,
            dataIndex: 'actions',
            key: 'actions'
          },
          {
            title: lang.label_maintenance_from,
            dataIndex: 'maintenanceFrom',
            key: 'maintenanceFrom'
          },
          {
            title: lang.label_maintenance_until,
            dataIndex: 'maintenanceUntil',
            key: 'maintenanceUntil'
          }
        ]
      : [])
  ];

  const getAllGameList = async () => {
    try {
      const payload = {
        token: localStorage.getItem('token'),
        game_category_id: filterData?.game_category_id,
        game_platform_id: filterData?.game_platform_id,
        name: filterData?.name,
        game_code: filterData?.game_code,
        isFilterActive: isFilterActive,
        page: currentPage
      };

      if (isFilterActive || isPageChange) {
        setLoading(true);
        var res = await getGameList(payload);
      }

      if (res && res?.status === 200) {
        setLoading(false);

        setTotalLength(res.data.total);
        setPerPage(res.data.per_page);

        const temp = res?.data;
        const newArray = temp?.data.map((item, index) => {
          const {
            game_categories,
            game_platform,
            icon_image,
            icon_square,
            icon_rectangle,
            metadata,
            name,
            status,
            sort_order,
            game_id,
            properties_array,
            supported_currencies_array
          } = item;
          if (game_categories.length) {
            const categoryString = JSON.parse(game_categories[0]?.name);
          }
          const jsonString = JSON.parse(name);
          const englishName = jsonString['en'];
          const vietnameseName = jsonString['vn'];

          return {
            key: item?.id,
            type:item?.type,
            platformName: game_platform.name,
            categoryName: game_categories.map((category) => {
              const name = JSON.parse(category?.name);
              return <div key={name.en}>{name?.en}</div>;
            }),
            currencies: supported_currencies_array.map((item, index) => {
              // Check if the value of the first property is true
              if (Object.values(item)[0]) {
                // Get the key of the first property
                const keyName = Object.keys(item)[0];
                return <div key={index}>{keyName}</div>;
              }
              return null; // Do not render anything if the value is false
            }),
            properties: properties_array.map((item, index) => {
              // Check if the value of the first property is true
              if (Object.values(item)[0]) {
                // Get the key of the first property
                const keyName = Object.keys(item)[0];
                return <div key={index}>{keyName}</div>;
              }
              return null; // Do not render anything if the value is false
            }),
            game_categories,
            //  categoryString.en,
            gameId: game_id,
            gameName: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                <MiniText>{englishName}</MiniText>
                {/* <MiniText>{vietnameseName}</MiniText> */}
              </div>
            ),
            icon: (
              <div
                style={{
                  height: '30px',
                  width: '35px',
                  objectFit: 'contain'
                }}>
                <img
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
                  src={icon_image}
                  alt="icon"
                />{' '}
              </div>
            ),
            iconsquare: (
              <div
                style={{
                  height: '30px',
                  width: '35px',
                  objectFit: 'contain'
                }}>
                <img
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
                  src={icon_square}
                  alt="icon"
                />{' '}
              </div>
            ),
            iconrectangle: (
              <div
                style={{
                  height: '30px',
                  width: '35px',
                  objectFit: 'contain'
                }}>
                <img
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
                  src={icon_rectangle}
                  alt="icon"
                />{' '}
              </div>
            ),

            status: (
              <div
              // style={{
              //   display: "flex",
              //   justifyContent: "center",
              //   alignItems: "center",
              //   width: "100%",
              // }}
              >
                <CustomBadge
                  icon={
                    item?.status === 'approved' ? (
                      <CheckCircleOutlined />
                    ) : item?.status === 'pending' ? (
                      <ClockCircleOutlined />
                    ) : item?.status === 'rejected' ? (
                      <CloseCircleOutlined />
                    ) : (
                      item.status === 'processing' && <SyncOutlined />
                    )
                  }
                  text={item?.status === 1 ? lang.label_active : lang.label_inactive}
                  bgColor={status === 1 ? '#E7F9E7' : status === 0 && '#FFE9E9'}
                  color={status === 1 ? '#148F14' : status === 0 && '#FF0000'}
                />
              </div>
            ),
            sort: sort_order,
            actions: <Action handleOpenModal={handleOpenModal} item={item} deleteState={false} />,
            maintenanceFrom:
              metadata && Object.keys(metadata).length ? (
                <div>
                  <CustomBadge
                    text={metadata?.maintenance_from ? convertUTCToLocalTime(metadata?.maintenance_from) : "-"}
                    bgColor={'#E7F9E7'}
                    color={'#148F14'}
                  />
                </div>
              ) : (
                // <Maintenece handleOpenModal={handleOpenMaintenanceModal} item={item} />
                ""
              ),
              maintenanceUntil:
              metadata && Object.keys(metadata).length ? (
                <div>
                  {metadata?.maintenance_until ? 
                  <CustomBadge
                    text={convertUTCToLocalTime(metadata?.maintenance_until)}
                    bgColor={'#E7F9E7'}
                    color={'#148F14'}
                  /> : "-"}
                </div>
              ) : (
                <Maintenece handleOpenModal={handleOpenMaintenanceModal} item={item} />
              ),
            currency: Object.entries(supported_currencies_array).map(([key, value]) => {
              return {
                label: key,
                value: value
              };
            }),
            property: Object.entries(properties_array).map(([key, value]) => {
              return {
                label: key,
                value: value
              };
            })
          };
        });

        setData(newArray);
        setIsFilterActive(false);
        setIsPageChange(false);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
      message.error(error.response.data.message);
    }
  };
  useEffect(() => {
    getAllGameList();
  }, [currentPage, isFilterActive]);
  const statusArray = [
    {
      id: '5',
      value: 'All'
    },
    {
      id: '1',
      value: 'Approved'
    },
    {
      id: '2',
      value: 'Rejected'
    },
    {
      id: '3',
      value: 'Pending'
    },
    {
      id: '4',
      value: 'processing'
    }
  ];
  const statusData = statusArray.map(({ value, id }) => ({ value, id }));
  const handleSelectChange = (value, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    // setItem((prevFormData) => ({
    //   ...prevFormData,
    //   [name]: value,
    // }));
  };

  const handleFilterSelectChange = (value, name) => {
    setFilterData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));

    setItem((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleCloseModal = () => {
    setFormData({
      englishName: '',
      vietnamName: '',
      game_platform_id: 0,
      game_category_id: [],
      icon: '',
      status: true,
      game_id: '',
      type:'',
      iconsquare: null,
      iconrectangle: null,
      currency: [],
      property: []
    });
    setItem();
    setModalVisible(false);
    setErrors({});
  };

  const handleOpenModal = (type, item) => {
    setModalType(type);
    setModalVisible(true);
    if (item) {
      const name = item?.hasOwnProperty('name') ? JSON.parse(item?.name) : {};
      const englishName = name['en'];
      const vietnamName = name['vn'];
      item
        ? setItem({
            ...item,
            englishName: englishName,
            vietnamName: vietnamName
          })
        : setItem();
    }
  };

  const handleOpenMaintenanceModal = (item) => {
    setGameId(item?.id);
    setMaintenanceModalVisible(true);
  };
  const handleOpenMaintenanceModalClose = () => {
    getAllGameList();
    setMaintenanceUntil(null);
    setMaintenanceModalVisible(false);
  };

  const getAllPlatformsFunc = async () => {
    try {
      const payload = {
        token: localStorage.getItem('token')
      };
      const res = await getPlatformsForDropdown(payload);
      if (res.status === 200) {
        const newObject = { id: 0, value: 'Select Platform' };

        const data = res.data.map(({ name, id }) => ({ id, value: name }));

        const newData = [newObject, ...data];

        setPlatForms(newData);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
      message.error(error.response.data.message);
    }
  };
  const getAllCategoriesFunc = async () => {
    try {
      const payload = {
        token: localStorage.getItem('token')
      };
      const res = await getAllGameCategories(payload);
      if (res?.status === 200) {
        const newObject = { id: 0, value: 'Select Category' };
        const data = res?.data?.map(({ name, id }) => {
          const jsonString = JSON.parse(name);
          return { value: jsonString['en'], id };
        });
        data.unshift(newObject);
        setCategories(data);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getAllPlatformsFunc();
    getAllCategoriesFunc();
  }, []);

  const getURLPath = (url) => {
    const newURL = new URL(url);
    return newURL.pathname;
  };
  const submitFunc = async () => {
    const payload = {
      game_category_ids: formData?.game_category_id.map((item) => {
        return { label: item.label, id: item.value };
      }),
      game_platform_id: formData?.game_platform_id,
      game_id: formData?.game_id,
      type:formData?.type,
      en: formData?.englishName,
      vn: formData?.vietnamName,
      icon_image: formData?.icon,
      icon_square: formData?.iconsquare,
      icon_rectangle: formData?.iconrectangle,
      status: formData?.status,
      currencies: formData?.currency?.map((item) => item?.value),
      properties: formData?.property?.map((item) => item?.value)
    };
    try {
      if (payload?.en?.trim() === '') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameEnError: 'Game name in english is required *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameEnError: ''
        }));
      }
      if (payload?.game_id?.trim() === '') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          gameId: 'Game Id is required *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          gameId: ''
        }));
      }
      if (!payload?.icon_image) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          imageError: 'Please upload an image *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          imageError: ''
        }));
      }

      if (!payload?.icon_square) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image1Error: 'Please upload an image *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image1Error: ''
        }));
      }

      if (!payload?.icon_rectangle) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image2Error: 'Please upload an image *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image2Error: ''
        }));
      }
      if (payload?.game_category_ids?.length === 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          gameCatError: 'Select one game category *'
          // platformError
          // gameCatError
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          gameCatError: ''
        }));
      }
      if (payload?.currencies?.length === 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          currencies: 'Select at least one category *'
          // platformError
          // gameCatError
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          currencies: ''
        }));
      }
      // if (payload?.properties?.length === 0) {
      //   setErrors((prevErrors) => ({
      //     ...prevErrors,
      //     properties: 'Select at least one property *'
      //     // platformError
      //     // gameCatError
      //   }));
      // } else {
      //   setErrors((prevErrors) => ({
      //     ...prevErrors,
      //     properties: ''
      //   }));
      // }

      if (payload?.game_platform_id === 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          platformError: 'Select one game platform *'
          // platformError
          // gameCatError
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          platformError: ''
        }));
      }
      if (!payload?.type) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          typeError: 'Select one game type *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          typeError: ''
        }));
      }
     

      if (
        payload?.en.trim() !== '' &&
        payload?.icon_square !== '' &&
        payload?.icon_square &&
        payload?.icon_rectangle !== '' &&
        payload?.icon_rectangle &&
        payload?.game_category_ids?.length > 0 &&
        payload?.currencies?.length > 0 &&
        // payload?.properties?.length > 0 &&
        payload?.game_platform_id !== 0 &&
        payload?.type !=='' &&
        payload?.game_id?.trim() !== ''
      ) {
        const token = localStorage.getItem('token');
        setSubmitLoading(true);
        const res = await createGameList(token, payload);
        if (res.status === 200) {
          message.success(lang.label_gameaddsuccess);
          setItem();
          setSubmitLoading(false);
          handleCloseModal();
          setFormData({
            englishName: '',
            vietnamName: '',
            game_platform_id: 0,
            game_category_id: [],
            icon: '',
            status: true,
            game_id: '',
            type:'',
            iconsquare: null,
            iconrectangle: null,
            currency: [],
            property: []
          });
          setIsFilterActive(true);
        }
        setErrors({});
      }
    } catch (error) {
      message.error(error.response.data.message.game_id[0]);
      setSubmitLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
  };
  const deleteFunc = async () => {
    try {
      const token = localStorage.getItem('token');
      setSubmitLoading(true);
      const res = await deleteGameList(token, item?.id);
      if (res.status === 200) {
        message.success(lang.label_gamedelsuccess);
        setItem();

        setSubmitLoading(false);
        handleCloseModal();
        setFormData({
          englishName: '',
          vietnamName: '',
          game_platform_id: 0,
          game_category_id: [],
          icon: '',
          status: true,
          game_id: '',
          type:'',
          iconsquare: null,
          iconrectangle: null,
          currency: [],
          property: []
        });
        setIsFilterActive(true);
      }
    } catch (error) {
      setSubmitLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
      message.error(error.response.data.message);
    }
  };
  const updateFunc = async () => {
    const payload = {
      id: item?.id,
      game_id: formData?.game_id,
      en: formData?.englishName,
      vn: formData?.vietnamName,
      icon_image: formData?.icon,
      icon_square: isValidUrl(formData?.iconsquare) ? getURLPath(formData?.iconsquare) : formData?.iconsquare,
      icon_rectangle: isValidUrl(formData?.iconrectangle)
        ? getURLPath(formData?.iconrectangle)
        : formData?.iconrectangle,
      game_category_ids: formData?.game_category_id.map((item) => {
        return { label: item.label, id: item.value };
      }),
      type:formData?.type,
      game_platform_id: formData?.game_platform_id,
      status: formData?.status,
      currencies: formData?.currency?.map((item) => item?.value),
      properties: formData?.property?.map((item) => item?.value)
    };
    try {
      if (payload?.en?.trim() === '') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameEnError: 'Game name in english is required *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          nameEnError: ''
        }));
      }
      if (payload?.game_id?.trim() === '') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          gameId: 'Game Id is required *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          gameId: ''
        }));
      }
      if (!payload?.icon_square) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image1Error: 'Please upload an image *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image1Error: ''
        }));
      }

      if (!payload?.icon_rectangle) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image2Error: 'Please upload an image *'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image2Error: ''
        }));
      }
      if (payload?.game_category_ids?.length === 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          gameCatError: 'Select one game category *'
          // platformError
          // gameCatError
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          gameCatError: ''
        }));
      }

      if (payload?.currencies?.length === 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          currencies: 'Select at least one category *'
          // platformError
          // gameCatError
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          currencies: ''
        }));
      }
      // if (payload?.properties?.length === 0) {
      //   setErrors((prevErrors) => ({
      //     ...prevErrors,
      //     properties: 'Select at least one property *'
      //     // platformError
      //     // gameCatError
      //   }));
      // } else {
      //   setErrors((prevErrors) => ({
      //     ...prevErrors,
      //     properties: ''
      //   }));
      // }

      if (payload?.game_platform_id === 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          platformError: 'Select one game platform *'
          // platformError
          // gameCatError
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          platformError: ''
        }));
      }
      if (!payload?.type) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          typeError: 'Select one game type*'
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          typeError: ''
        }));
      }

      if (
        payload?.en.trim() !== '' &&
        payload?.icon_square !== '' &&
        payload?.icon_square &&
        payload?.icon_rectangle !== '' &&
        payload?.icon_rectangle &&
        payload?.game_category_ids?.length > 0 &&
        payload?.currencies?.length > 0 &&
        // payload?.properties?.length > 0 &&
        payload?.game_platform_id !== 0 &&
        payload?.type !=='' &&
        payload?.game_id?.trim() !== ''
      ) {
        const token = localStorage.getItem('token');

        setSubmitLoading(true);
        const res = await updateGameList(token, payload);
        if (res.status === 200) {
          message.success(lang.label_gameeditsuccess);
          setItem();
          setSubmitLoading(false);
          handleCloseModal();
          setFormData({
            englishName: '',
            vietnamName: '',
            game_platform_id: 0,
            game_category_id: [],
            icon: '',
            status: true,
            game_id: '',
            type:'',
            iconsquare: null,
            iconrectangle: null,
            currency: [],
            property: []
          });
          setIsFilterActive(true);
        }
        setErrors({});
      }
    } catch (error) {
      setSubmitLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
      message.error(error.response.data.message);
    }
  };

  const onChange = (value) => {
    setMaintenanceUntil(value);
  };

  const maintenanceFunc = async () => {
    try {
      const payload = {
        maintenance_until: convertLocalTimeToUTC(maintenanceUntil)
      };
      const game_item = gameId;

      setLoading(true);
      const token = localStorage.getItem('token');
      const res = await updateMaintenanceGameItem(token, payload, game_item);
      if (res.status) {
        setLoading(false);
        handleOpenMaintenanceModalClose();
        setGameId(null);
        message.success('Game Item Under Maintenence Updated');
      }
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        Navigate('/login');
      }
    }
    setLoading(false);
    handleOpenMaintenanceModalClose();
  };
  return (
    <>
      <HeaderCardDetails />

      <div style={{ padding: '20px' }}>
        <div className={styles.container}>
          <div className={styles.filterUpperContainer}>
            <Heading>{lang.label_filters}</Heading>
            {hasPermission('Create Game Item') && (
              <div
                style={{
                  float: 'right'
                }}>
                <div
                  style={{
                    paddingRight: '15px',
                    paddingTop: '0'
                  }}>
                  <Button
                    onClick={() => handleOpenModal('new')}
                    className="btnStyle"
                    style={{
                      borderRadius: '3px',
                      padding: '6px',
                      width: '150px'
                    }}>
                    {lang.label_addgameitem}
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className={styles.filterLowerContainer}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                paddingRight: '14px',
                flexWrap: 'wrap'
              }}>
              <div className={styles.flexStyle}>
                <SubHeading
                  className="filterTest"
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500,
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}>
                  {lang.label_gameplatform}
                </SubHeading>

                <SearchSelectBox
                  isSearch={true}
                  name="game_platform_id"
                  value={filterData.game_platform_id}
                  onChange={handleFilterSelectChange}
                  data={platForms}
                />
              </div>
              <div className={styles.flexStyle}>
                <div
                  style={{
                    minWidth: '50px'
                  }}>
                  <SubHeading
                    className="filterTest"
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500,
                      display: 'flex',
                      justifyContent: 'flex-start'
                    }}>
                    {lang.label_gamecategory}
                  </SubHeading>
                </div>
                <SelectBox
                  name="game_category_id"
                  value={filterData.game_category_id}
                  onChange={handleFilterSelectChange}
                  data={categories}
                />
              </div>
              <div className={styles.flexStyle}>
                <div
                  style={{
                    minWidth: '50px'
                  }}>
                  <SubHeading
                    className="filterTest"
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500,
                      display: 'flex',
                      justifyContent: 'flex-start'
                    }}>
                    {lang.label_name}
                  </SubHeading>
                </div>
                <Input
                 style={inputStyle}
                  placeHolder="Enter Game Name "
                  value={filterData?.name}
                  onChange={(e) => setFilterData((prevData) => ({ ...prevData, name: e.target.value }))}
                />
              </div>
              <div className={styles.flexStyle}>
                <div
                  style={{
                    minWidth: '90px'
                  }}>
                  <SubHeading
                    className="filterTest"
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500,
                      display: 'flex',
                      justifyContent: 'flex-start'
                    }}>
                    {lang.label_game_code}
                  </SubHeading>
                </div>
                <Input
                   style={inputStyle}
                  placeHolder="Enter Game Code "
                  value={filterData?.game_code}
                  onChange={(e) => setFilterData((prevData) => ({ ...prevData, game_code: e.target.value }))}
                />
              </div>
              <div style={{  display : 'flex' , marginTop: '18px' }}>
                <Button
                  className="btnStyle"
                  style={{
                    display : 'flex',
                    alignItems : 'center',
                    justifyContent : 'space-evenly',
                    backgroundColor: '#004A7F',
                    color: 'white',
                    width: '125px',
                    height: '35px',
                    background: '#004A7F 0% 0% no-repeat padding-box',
                    borderRadius: '3px',
                    opacity: '1',
                    margin: '0px 10px'
                  }}
                  onClick={searchFunc}
                  text={lang.label_search}>
                  <img src={SearchIcon} alt='search' />
                  {lang.label_search}
                </Button>
                <Button
                  style={{
                    display : 'flex',
                    alignItems : 'center',
                    justifyContent : 'space-evenly',
                    borderRadius: '3px',
                    padding: '6px',
                    border: '1px solid grey',
                    width: '84px'
                  }}
                  className="btnReset"
                  onClick={resetFunc}
                  text={lang.label_reset}>
                     <img src={ResetIcon} alt='reset' />
                  {lang.label_reset}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Spacer height="30px" />
        {loading ? (
          <Loader />
        ) : (
          //  <TableComp dataSource={data} columns={columns} totalLength={totalLength} perPage={perPage} currentPage={currentPage} onChangePage={onChangePage} />
          <DragTableComp
            dataSource={data}
            columns={columns}
            setData={setData}
            sortingfunc={sortingGameList}
            totalLength={totalLength}
            perPage={perPage}
            onChangePage={onChangePage}
            currentPage={currentPage}
            draggable={false}
          />
        )}

        <CustomModal
          visible={modalVisible}
          onCancel={handleCloseModal}
          title={
            modalType === 'new'
              ? lang.label_addgameitem
              : modalType === 'update'
              ? lang.label_updategameitem
              : modalType === 'delete' && lang.label_delete
          }
          subTitle={
            modalType === 'new'
              ? lang.label_addgameinfo
              : modalType === 'update'
              ? lang.label_updategameinfo
              : modalType === 'delete' && lang.label_deletegame
          }
          content={
            modalType === 'new' || modalType === 'update' ? (
              <CreateModal
                errors={errors}
                setItem={setItem}
                updateFunc={updateFunc}
                item={item}
                type={modalType}
                formData={formData}
                handleCloseModal={handleCloseModal}
                submitFunc={submitFunc}
                submitLoading={submitLoading}
                setFormData={setFormData}
                handleInputChange={handleInputChange}
                handleSelectChange={handleSelectChange}
                // status={status}
                platFormData={createPlatforms}
                categoryData={createCategories}
                handleSwitchChange={handleSwitchChange}
              />
            ) : (
              modalType === 'delete' && (
                <DeleteModal
                  handleCloseModal={handleCloseModal}
                  submitLoading={submitLoading}
                  deleteFunc={deleteFunc}
                />
              )
            )
          }
          footer={<></>}
          modalStyle={{ width: '600px' }}
          titleStyle={
            modalType === 'delete' && {
              color: '#D40000',
              border: '1px solid red'
            }
          }
          contentStyle={{}}
          footerStyle={{}}
        />

        <Modal
          centered
          item={item}
          open={maintenanceModalVisible}
          onCancel={handleOpenMaintenanceModalClose}
          onOk={maintenanceFunc}
          okText="Submit"
          footer={false}
          width={400}
          title={
            <div>
              <p style={titleStyles}>{lang.label_maintenance_until}</p>
              <MiniText>Select the maintenece until time</MiniText>
            </div>
          }>
          <DatePicker
            placeholder="Select Time"
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            onChange={onChange}
            style={datePickerStyle}
            value={maintenanceUntil}
            suffixIcon={<CalendarOutlined style={iconStyle} />}
            disabledDate={disabledDate}
          />
          <div
            style={{
              marginTop: '2%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Button
              style={{
                backgroundColor: '#004A7F',
                color: 'white',
                width: '80px',
                height: '35px',
                background: '#004A7F 0% 0% no-repeat padding-box',
                borderRadius: '3px',
                opacity: '1',
                margin: '0px 10px'
              }}
              onClick={maintenanceFunc}>
              {lang.label_submit}
            </Button>

            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                width: '80px',
                height: '35px'
              }}
              onClick={() => {
                handleOpenMaintenanceModalClose();
              }}>
              {lang.label_cancel}
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default GameList;
