import React, { useCallback } from "react";

import LangContext from "../../../helps/contexts/lang-context";
import { useContext, useState, useEffect } from "react";
import PutApi from "../../../helps/contexts/Api/PutApi";
import { Select, Input, Row, Col, Button, message, Spin } from "antd";
import GetApi from "../../../helps/contexts/Api/GetApi";
import PostApi from "../../../helps/contexts/Api/PostApi";
import { useNavigate } from "react-router-dom";
import SubHeading from "../../../components/HelperComponents/SubHeading";
import { constants } from "../../../helps/contexts/Api/constants";
import axios from 'axios';

const { Option } = Select;
const AddAgentNotification = ({ onCancel, rowdata, getNotification, type = "new" }) => {
  const nav = useNavigate();
  const ctx = useContext(LangContext);
  const [notification, setnotification] = useState(rowdata && rowdata?.notification ? rowdata?.notification : "");
  const [notificationTitle, setNotificationTitle] = useState(rowdata && rowdata?.notificationTitle ? rowdata?.notificationTitle : "");
  const [agents, setAgents] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastpage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAgentIds, setSelectedAgentIds] = useState([]);
  const lang = ctx.lang;
  const id = rowdata?.id;
  const { TextArea } = Input;
  const onSubmit = async (e) => {
    const agentIds=selectedAgentIds.map(item=>item?.value);
    try {
      e.preventDefault();
      const path = `/admin/agent/notification`;
      // sending request
      const res = await PostApi.PostApiRequest(path, {
        title: notificationTitle,
        description: notification,
        agent_id: agentIds,
      });
      //if success
      if (res && res.status === 200) {
        message.success("Agent Notification Created Successfuly");
        setnotification("");
        setNotificationTitle("");
        selectedAgentIds([]);
        setAgents([]);
        setPage(1);
      } else if (!res) {
        message.error("Agent Notification Creation Failed");
      }
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav("/login");
      }

    } finally {
      onCancel();
      getNotification();
    }
  };

  const updateNofitication = async (e) => {

    try {
      e.preventDefault();

      const path = `/admin/agent/notification/${id}`;
      // sending request
      const res = await PutApi.PutApiRequest(path, {
        title: notificationTitle,
        description: notification,
      });
      //if success
      if (res && res.status === true) {
        message.success("Agent Notification Updated Successfuly");
        setnotification("");
        setNotificationTitle("");
        onCancel();
        getNotification();

      } else if (!res) {
        message.error("Agent Notification Creation Failed");
      }
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav("/login");
      }

    }
  };

  useEffect(() => {
    fetchAgents(page);
  }, [page]);

  useEffect(() => {
    if (rowdata) {
      const newPlayerList = rowdata?.agent?.map((agent) => agent?.agent?.user?.user_name);
      setSelectedAgentIds(newPlayerList);
    }
  }, [rowdata])

  const fetchAgents = async (page, searchTerm) => {
    setLoading(true);
    try {
      const path = `/admin/agent/get/all`;
      const params = {};
      params["page"] = page;
      if (searchTerm) params["search"] = searchTerm;

      const response = await GetApi.sendApiRequest(path, {}, params);
      // setAgents(prevPlayers => [...prevPlayers, ...response.data]);
      if(response?.data?.length){
      setAgents(prevPlayers => {
        // Create a set of existing player IDs for quick lookup
        const existingPlayerIds = new Set(prevPlayers.map(player => player.id));
        // Filter out agents that are already in the state
        const newPlayers = response.data.filter(player => !existingPlayerIds.has(player.id));
        // Append the new, unique agents to the existing state
        setLastpage(response?.last_page);
        return [...prevPlayers, ...newPlayers];
      });
    }
    } catch (error) {
      console.error('Failed to fetch agents', error);
    } finally {
      setLoading(false);
      setSearchTerm("");
    }
  };

   // Debounced function to call fetchAgents (optional)
   const debounceFetchAgents = useCallback(
    (searchTerm) => {
      const timeoutId = setTimeout(() => fetchAgents(page, searchTerm), 300);
      return () => clearTimeout(timeoutId);
    },
   [fetchAgents,searchTerm]
  );

  useEffect(() => {
    if (!searchTerm) {
      fetchAgents(page,'');
    }
     else {
      debounceFetchAgents(searchTerm);
    }
  }, [page, searchTerm]);

  const handlePopupScroll = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if(page < lastPage){
        setPage(prevPage => prevPage + 1);
      }
    }
  };
  const handleChange = (value) => {
    debounceFetchAgents("");
    setSelectedAgentIds(value);
  };


  return (
    <div
      style={{
        width: "97%",
        minHeight: "350px",
        height: "100%",
        justifyContent: "space-evenly",
      }}
    >
      <form onSubmit={type === "edit" ? updateNofitication : onSubmit}>
        <div
          style={{
            width: "97%",
            height: "100%",
            justifyContent: "space-around",
          }}
        >
          <Row>
            <Col span={24}>
              <label style={{}}>Notification</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <span style={{ height: "15px" }}>{""}</span>
              <Input
                //getting input value for search
                placeholder="Enter title here"
                value={notificationTitle}
                required
                style={{ width: "100%", borderRadius: "3px", marginTop: "10px" }}
                onChange={(e) => {
                  setNotificationTitle(e.target.value);
                }}
              />
              <TextArea
                type="text"
                style={{ width: "100%", borderRadius: "3px", marginTop: "10px", height: "70%" }}
                value={notification}
                required
                onChange={(e) => {
                  setnotification(e.target.value);
                }}
              />

              {/* <Select
                style={{ width: "100%", borderRadius: "3px", marginTop: "10px" }}
                allowClear
                mode="multiple"
                value={formData.status}
                onChange={(val) => setFormData((prevData) => ({ ...prevData, status: val }))}
                onClear={() => setFormData((prevData) => ({ ...prevData, status: null }))}
                options={[]}
              /> */}
              <div className="custom-select">
              <Select
                mode="multiple"
                size="middle"
                style={{ width: "100%", borderRadius: "3px", marginTop: "10px",marginBottom:'10px' }}
                placeholder="Select agents"
                disabled={type==="edit" ? true : false}
                notFoundContent={loading ? <Spin size="small" /> : null}
                onChange={handleChange}
                labelInValue
                value={selectedAgentIds}
                onSearch={(value) => {
                  setSearchTerm(value);
                  setPage(1);
                  debounceFetchAgents(value);
                }}
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onPopupScroll={handlePopupScroll}
                dropdownRender={(menu) => (
                  <div>
                    {loading ? (
                      <div style={{ padding: '8px', textAlign: 'center' }}>
                        <Spin size="small" />
                      </div>
                    ) : null}
                    {menu}
                  </div>
                )}
              >
                {agents.map((agent) => (
                  <Option key={agent.id} value={agent.id} label={agent?.user?.user_name}>
                    {agent?.user?.user_name}
                  </Option>
                ))}
              </Select>
              </div>
            </Col>
          </Row>

          <br />
          <br />{" "}
          <Button
            style={{
              borderRadius: "3px",
              backgroundColor: "#004A7F",
              color: "white",
              position: "absolute",
              right: "30%",
              // top: "80%",
              bottom: '15px',
              margin: "-1%",
            }}
            htmlType="submit"
          >
            {type === "edit" ? lang.label_update : lang.label_submit}
          </Button>
          <Button
            style={{
              borderRadius: "3px",
              color: "#004A7F",
              position: "absolute",
              right: "8%",
              // top: "80%",
              bottom: '15px',
              margin: "-1%",
            }}
            onClick={() => {
              setnotification("")
              setSelectedAgentIds([]);
              setNotificationTitle("");
              onCancel()
              setPage(1);
            }}
          >
            {lang.label_cancel}
          </Button>
        </div>
      </form>
    </div>
  );
};
export default AddAgentNotification;
