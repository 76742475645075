import React, { useContext, useState } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import styles from './AffiliateList.module.css';
import Heading from '../../../components/HelperComponents/Heading';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import SearchIcon from "../../../images/Search.png";
import ResetIcon from "../../../images/Reset.png";
import { Button, Input, Select } from 'antd';

export default function AffiliateFilters({ setIsModalOpen, categoryList, setFilters, onRefresh }) {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [formData, setFormData] = useState();

  const onReset = () => {
    onRefresh();
    setFormData();
  };

  const onSubmit = () => {
    setFilters(formData);
  };
  const inputStyle = {
    width: '200px',
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const selectStyle = {
    width: '200px',
    height: '35px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '3px'
  };

  return (
    <div className={styles.container}>
      <div className={styles.filterUpperContainer}>
        <Heading>{lang.label_filters}</Heading>
      </div>
      <div className={styles.filterLowerContainer}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            paddingRight: '14px',
            flexWrap: 'wrap'
          }}>
          <div>
            <div className={styles.flexStyle}>
              <div
                style={{
                  // minWidth: '50px'
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <SubHeading
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500
                  }}>
                  {lang.label_search_by}
                </SubHeading>
                <Select
                  defaultValue={'--Select--'}
                  style={{ width: 150, ...selectStyle }}
                  placeholder="Select type"
                  value={formData?.key}
                  onChange={(val) => setFormData((prevData) => ({ ...prevData, key: val, value: '' }))}
                  options={[
                    {
                      label: 'Affiliate ID',
                      value: 'affiliate_id'
                    },
                    {
                      label: 'Player Username',
                      value: 'user_name'
                    }
                  ]}
                />
              </div>
              <Input
                style={{
                  marginTop: '18px',
                  width: '150px',
                  ...inputStyle
                }}
                placeHolder="Search"
                value={formData?.value}
                onChange={(e) => setFormData((prevData) => ({ ...prevData, value: e.currentTarget.value }))}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginTop: '18px', display: 'flex', flexDirection: 'row' }}>
              <Button
                className="btnStyle"
                style={{
                  display : 'flex',
                alignItems : 'center',
                justifyContent : 'space-evenly',
                  backgroundColor: '#004A7F',
                  color: 'white',
                  width: '125px',
                  height: '35px',
                  background: '#004A7F 0% 0% no-repeat padding-box',
                  borderRadius: '3px',
                  opacity: '1',
                  marginRight: '10px'
                }}
                onClick={onSubmit}>
                  <img src={SearchIcon} alt='search' />
                {lang.label_search}
              </Button>
            </div>
            <div style={{ marginTop: '18px' }}>
              <Button
                onClick={onReset}
                style={{
                  display : 'flex',
                alignItems : 'center',
                justifyContent : 'space-evenly',
                  borderRadius: '3px',
                  padding: '6px',
                  border: '1px solid grey',
                  width: '84px'
                }}
                className="btnReset">
                   <img src={ResetIcon} alt='reset' />
                {lang.label_reset}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
