import { Button, Flex, Modal, Table } from 'antd';
import React from 'react';

export default function ReviewPopup({
  reviewOpen,
  reviewHandleCancel,
  reviewHandleOk,
  final,
  inputs,
  tableList,
  lang
}) {
  console.log('ssssss', reviewOpen);
  const inputColumns = [
    {
      title: lang.label_rebate_title,
      dataIndex: 'rebate_title',
      key: 'rebate_title',
      render: (_, record) => <>{record?.title}</>
    },
    {
      title: lang.label_description,
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => <>{record?.title}</>
    },
    {
      title: lang.label_date_range,
      dataIndex: 'date_range',
      key: 'date_range',
      render: (_, record) => (
        <>
          {record?.start_date?.split(' ')[0]} <br /> {record?.end_date?.split(' ')[0]}
        </>
      )
    },
    {
      title: <div style={{ width: '50px' }}>{lang.label_rebate_cycle}</div>,
      dataIndex: 'rebate_title',
      key: 'rebate_title',
      render: (_, record) => <>{record?.rebate_cycle}</>
    },
    {
      title: lang.label_category,
      dataIndex: 'category',
      key: 'category',
      render: (_, record) => <>{record?.category_name}</>
    },
    {
      title: <div style={{ width: '120px' }}>{lang.label_max_rebate}</div>,
      dataIndex: 'max_rebate',
      key: 'max_rebate',
      render: (_, record) => <>{record?.max_group_rebate}</>
    },
    {
      title: <div style={{ width: '100px' }}>{lang.label_conditional_multiplayer}</div>,
      dataIndex: 'conditional_multiplayer',
      key: 'conditional_multiplayer',
      render: (_, record) => <>{record?.conditional_multiplier}</>
    },
    {
      title: lang.label_timezone,
      dataIndex: 'timezone',
      key: 'timezone',
      render: (_, record) => <>{record?.timezone_name}</>
    }
  ];

  const gameColumns = [
    {
      title: lang.label_game_provider,
      dataIndex: 'game_provider',
      key: 'game_provider',
      render: (_, record) => <>{record?.game_provider_name}</>
    },
    {
      title: lang.label_valid_bet,
      dataIndex: 'valid_bet',
      key: 'valid_bet',
      render: (_, record) => <>{record?.valid_bet}</>
    },
    {
      title: lang.label_rebate_recommended,
      dataIndex: 'rebate_recommended',
      key: 'rebate_recommended',
      render: (_, record) => <>{record?.rebate_recommended}</>
    },
    {
      title: lang.label_maxrebate,
      dataIndex: 'max_rebate',
      key: 'max_rebate',
      render: (_, record) => <>{record?.max_rebate}</>
    }
  ];

  return (
    <Modal
      width="fit-content"
      centered
      //   title="Add New Rabate"
      open={reviewOpen}
      onOk={reviewHandleOk}
      onCancel={reviewHandleCancel}
      footer={false}>
      <Flex style={{ flexDirection: 'column', width: '100%' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <p
            style={{
              color: '#004A7F',
              fontWeight: 600,
              fontSize: '16px',
              width: 'fit-content'
            }}>
            Are you sure?
          </p>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          Are you sure you want to add the new Rebate with these details?
        </div>
        <Table
          // scroll={{
          //   x: 'max-content'
          // }}
          style={{
            border: '1px solid #E7E9F1',
            borderRadius: '5px',
            marginTop: '20px'
          }}
          columns={inputColumns}
          // loading={loading}
          dataSource={[inputs]}
          bordered
          pagination={false}
          rowKey={'id'}
        />{' '}
        <Table
          // scroll={{
          //   x: 'max-content'
          // }}
          style={{
            border: '1px solid #E7E9F1',
            borderRadius: '5px',
            marginTop: '20px'
          }}
          columns={gameColumns}
          // loading={loading}
          dataSource={tableList}
          bordered
          pagination={false}
          rowKey={'id'}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            paddingTop: '20px'
          }}>
          <Button
            onClick={final}
            style={{
              borderRadius: '3px',
              backgroundColor: '#004A7F',
              color: 'white',
              width: '100px'
            }}>
            {lang.label_submit}
          </Button>
          <Button
            onClick={reviewHandleCancel}
            style={{
              borderRadius: '3px',
              color: '#004A7F',
              width: '100px'
            }}>
            {lang.label_cancel}
          </Button>
        </div>
      </Flex>
    </Modal>
  );
}
