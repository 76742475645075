import { useEffect, useState } from 'react';
import addCommasToNumber from '../../../helps/AddCommasToNumber';
import styles from './PlayerBalanceHistory.module.css';

import { CalendarOutlined, CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Card, Row, Col, DatePicker, Button, Input, Pagination, Select, Tag, message, Tooltip, Spin } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext } from 'react';
import GetApi from '../../../helps/contexts/Api/GetApi';
import '../../../styles/ButtonStyle.css';
import PlayerBalanceHistoryTable from './PlayerBalanceHistoryTable';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import { useNavigate } from 'react-router-dom';
import { convertLocalTimeToUTC, toFixedFunc } from '../../../helps/HelperFunctions';
import InputField from '../../../components/HelperComponents/InputField';
import { TabContext } from '../../../helps/contexts/TabContext';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import Heading from '../../../components/HelperComponents/Heading';
import SelectBox from '../../../components/HelperComponents/SelectBox';
import { exportReportPlayerBalance1, exportReportPlayerBalance2 } from '../../../helps/contexts/Api/downloadFile';
import SearchIcon from '../../../images/Search.png';
import ResetIcon from '../../../images/Reset.png';
import dayjs from 'dayjs';
import Loader from '../../../components/HelperComponents/Loader';
import { LoadingOutlined } from '@ant-design/icons';

const datePickerStyle = {
  width: '190px',
  height: '35px',
  background: ' #FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #D9D9D9',
  borderRadius: '3px'
};
const iconStyle = { color: '#004A7F' };

const PlayerBalanceHistory = () => {
  const nav = useNavigate();
  //States
  const [playerBalanceHistoryData, setPlayerBalanceHistoryData] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startTimeParam, setStartTimeParam] = useState('');
  const [endTimeParam, setendTimeParam] = useState('');
  const [minimum, setMinimum] = useState('');
  const [maximum, setMaximum] = useState('');
  const [username, setUsername] = useState('');
  const [type, setType] = useState(null);
  const [typesArray, setTypesArray] = useState([]);
  const [ignoreTestAccount, setIgnoreTestAccount] = useState(null);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage] = useState(1);
  const [currentpage, setCurrentpage] = useState(0);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [lebel, setLabel] = useState('All');
  const { path } = useContext(TabContext);
  //Language translation
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [filteredParams, setFilteredParams] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0);
  const [downloadedFiles, setDownloadedFiles] = useState(0);
  const downloadedFileIds = new Set(); 

  const getPlayerBalanceHistory = async (customPage = null) => {
    try {
      const path = '/admin/players/balance/history';
      const params = {};
      setLoading(true);

      //setting parameters to send for search results
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      if (startDate) {
        params['from_date'] = convertLocalTimeToUTC(startTimeParam);
      }
      if (endDate) params['to_date'] = convertLocalTimeToUTC(endTimeParam);
      if (minimum) params['min_amount'] = minimum;
      if (maximum) params['max_amount'] = maximum;
      if (username) params['user_name'] = username;
      if (type) params['type'] = type;
      if (ignoreTestAccount) params['ignore_test_account'] = ignoreTestAccount;
      setFilteredParams(params);
      // console.log(params);
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);

      if (res && res.player_balance_history.data && res.player_balance_history.data.length) {
        const finalArray = res.player_balance_history.data.map((d) => {
          return {
            key: d.id ? d.id : '',
            id: d?.player_id ? d?.player_id : '',
            previousbalance: d.previous_balance ? d.previous_balance : '0',
            currency_name: d.currency_name,
            is_deduction: d.is_deduction,

            newbalance: d.new_balance ? d.new_balance : '0',
            amount: d.amount ? d.amount : '0',
            type: d?.type_name ? (
              <Tag color="processing">{d?.type_name}</Tag>
            ) : d.transaction?.isWithdraw === 1 ? (
              <Tag color="error">{lang.label_withdrawal}</Tag>
            ) : d.transaction?.isWithdraw === 0 ? (
              <Tag color="success">{lang.label_deposit}</Tag>
            ) : (
              <>-</>
            ),
            is_turnover_check: d.is_turnover_check ? d.is_turnover_check : '-',
            turnover_multiplier: d.withdraw_requirement?.turnover_multiplier
             
              ? parseFloat(d.withdraw_requirement?.turnover_multiplier).toFixed(2)
             
              : '-',
            actionby: d.action_by ? d.action_by.user_name : '',
            playername: d.player.user ? d.player.user.user_name : '',
            transactionid: d.transaction_id ? d.transaction_id : '',
            remark: d.remark ? d.remark : '',
            gametransactionid: d?.game_transaction_history_id,
            status: (
              <Tag
                color={
                  d.status == 1 ? 'success' : d.status == 2 ? 'error' : d.status == 'pending' ? 'processing' : null
                }
                icon={
                  d.status == 1 ? (
                    <CheckCircleOutlined />
                  ) : d.status == 2 ? (
                    <CloseCircleOutlined />
                  ) : d.status == 'pending' ? (
                    <SyncOutlined spin />
                  ) : null
                }>
                {d?.status === 1
                  ? lang.label_completed
                  : d?.status === 2
                  ? lang.label_failed
                  : d?.status == 'pending'
                  ? lang.label_pending
                  : null}
              </Tag>
            ),
            time: d.created_at ? d.created_at : ''
          };
        });
        setPlayerBalanceHistoryData(finalArray);
        setTotal(res.player_balance_history.total);
        setCurrentpage(res.player_balance_history.current_page);
        setPerpage(res.player_balance_history.per_page);
        setNextPage(res.player_balance_history.next_page_url);
        setTotalDeposit(res.deposit_total);
        setTotalWithdraw(res.withdraw_total);
        setTotalAmount(res.total);
      } else {
        setPlayerBalanceHistoryData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
      message.error('error is', e.data.message);
    }
  };

  // Fetching login history api
  const getPlayerBalanceHistoryWithoutParams = async () => {
    setActivepage(1);
    const params = {};
    const path = '/admin/players/balance/history';
    setLoading(true);
    params['page'] = activepage;
    //sending api request
    const res = await GetApi.sendApiRequest(path, null, params);
    if (res && res.player_balance_history.data && res.player_balance_history.data.length) {
      const finalArray = res.player_balance_history.data.map((d) => ({
        key: d.id ? d.id : '',
        id: d?.player_id ? d?.player_id : '',
        previousbalance: d.previous_balance ? d.previous_balance : '0',
        currency_name: d.currency_name,
        newbalance: d.new_balance ? d.new_balance : '0',
        amount: d.amount ? d.amount : '0',
        type: d?.type_name ? (
          <Tag color="processing">{d?.type_name}</Tag>
        ) : d.transaction?.isWithdraw === 1 ? (
          <Tag color="error">{lang.label_withdrawal}</Tag>
        ) : d.transaction?.isWithdraw === 0 ? (
          <Tag color="success">{lang.label_deposit}</Tag>
        ) : (
          <>-</>
        ),
        is_turnover_check: d.is_turnover_check ? d.is_turnover_check : '-',
        turnover_multiplier: d.turnover_multiplier ? parseFloat(d.turnover_multiplier).toFixed(2) : '-',
        actionby: d.action_by ? d.action_by.user_name : '',
        playername: d.player.user ? d.player.user.user_name : '',
        transactionid: d.transaction_id ? d.transaction_id : '',
        remark: d.remark ? d.remark : '',
        gametransactionid: d.game_transaction_history_id ? d.game_transaction_history_id : '',
        status: (
          <Tag
            color={d.status == 1 ? 'success' : d.status == 2 ? 'error' : d.status == 'pending' ? 'processing' : null}
            icon={
              d.status == 1 ? (
                <CheckCircleOutlined />
              ) : d.status == 2 ? (
                <CloseCircleOutlined />
              ) : d.status == 'pending' ? (
                <SyncOutlined spin />
              ) : null
            }>
            {d?.status === 1
              ? lang.label_completed
              : d?.status === 2
              ? lang.label_failed
              : d?.status == 'pending'
              ? lang.label_pending
              : null}
          </Tag>
        ),
        time: d.created_at ? d.created_at : ''
      }));
      setPlayerBalanceHistoryData(finalArray);
      setTotal(res.player_balance_history.total);
      setCurrentpage(res.player_balance_history.current_page);
      setPerpage(res.player_balance_history.per_page);
      setNextPage(res.player_balance_history.next_page_url);
      setTotalDeposit(res.deposit_total);
      setTotalWithdraw(res.withdraw_total);
      setTotalAmount(res.total);
    } else {
      setPlayerBalanceHistoryData([]);
      setTotal(0);
    }
    setLoading(false);
  };
  // handling submit for the form to get search results
  const sendSearchFilters = async (e) => {
    e.preventDefault();
    getPlayerBalanceHistory();
    setActivepage(1);
  };
  useEffect(() => {
    getPlayerBalanceHistory();
  }, []);

  useEffect(() => {
    if (path === '/player-management/player-balance-history') {
      getPlayerBalanceHistory();
    }
  }, [path]);

  const resetSelection = () => {
    setType(null);
    setLabel(lang.label_all);
  };

  const exportData = async (payload) => {
    // console.log(payload)
    const params = {
      export_type: 0,
      export: 1,
      from_date: payload.from_date,
      to_date: payload.to_date,
      user_name: payload.user_name,
      game_platform_id: payload.game_platform_id,
      game_name: payload.game_name,
      round_reference: payload.round_reference,
      max_amount: payload.max_amount,
      min_amount: payload.min_amount,
      type: payload.type,
      ignore_test_account:payload.ignore_test_account
    };

    try {
      setLoadingData(true);
      setDownloadedFiles(0); 
      const response = await exportReportPlayerBalance1(params);

      if (response?.data?.job_id) {
        const jobId = response.data.job_id;
        const totalFileCount = response.data.progress.total_file_count;
        setTotalFiles(totalFileCount);

        const pollExportStatus = async (jobId) => {
          try {
            const statusResponse = await exportReportPlayerBalance2(jobId);
            const files = statusResponse?.data?.file || [];

            files.forEach((file) => {
              if (!downloadedFileIds.has(file.file_id)) {
                const base64String = file.file_content;
                const fileName = file.file_name + (file.file_type.includes('csv') ? '.csv' : '.xlsx');
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: file.file_type });

                const link = document.createElement('a');
                const blobUrl = URL.createObjectURL(blob);
                link.href = blobUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);

                // Mark the file as downloaded
                downloadedFileIds.add(file.file_id);
                setDownloadedFiles((prev) => prev + 1);
              }
            });

            if (statusResponse?.data?.progress?.is_all_file_generated) {
              setLoadingData(false);
              message.success(`Export completed. ${totalFileCount} files downloaded.`);
            } else {
              setTimeout(() => pollExportStatus(jobId), 2000);
            }
          } catch (error) {
            setLoadingData(false);
            message.error('Failed to check export status.');
          }
        };
        pollExportStatus(jobId);
      } else {
        setLoadingData(false);
        message.error('Failed to initiate export.');
      }
    } catch (error) {
      setLoadingData(false);
      message.error('Failed to initiate export.');
    }
  };

  return (
    <>
      <HeaderCardDetails />
      <form
        style={{ padding: '20px' }}
        onSubmit={(e) => {
          e.preventDefault();
          getPlayerBalanceHistory();
        }}>
        <div>
          <div className={styles.container}>
            <div
              className={styles.filterUpperContainer}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: '3px'
              }}>
              <Heading>{lang.label_filters}</Heading>
              <div>
                {loadingData ? (
                  <div style={{ paddingRight: '40px', display: 'flex', alignItems: 'center' }}>
                    <Spin indicator={<LoadingOutlined spin />} size="large" />
                    {/* <div style={{ marginLeft: '10px', fontWeight: '500', fontSize: '16px' }}>
                      {downloadedFiles} of {totalFiles} Files Downloaded
                    </div> */}
                  </div>
                ) : (
                  <div>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="btnStyle"
                      style={{
                        backgroundColor: '#004A7F',
                        color: 'white',
                        width: '125px',
                        height: '35px',
                        background: '#004A7F 0% 0% no-repeat padding-box',
                        borderRadius: '3px',
                        opacity: '1',
                        marginRight: '15px'
                      }}
                      onClick={() => exportData(filteredParams)}>
                      Export
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.filterLowerContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  width: '100%',
                  paddingRight: '14px',
                  flexWrap: 'wrap'
                }}>
                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_time}{' '}
                  </SubHeading>
                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-around',
                      alignItems: 'center'
                    }}>
                    <DatePicker
                      //getting input value for start date
                      placeholder={lang.label_selectdate}
                      showTime={{
                        defaultValue: dayjs('00:00:00', 'HH:mm:ss')
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      onChange={(value, dateString) => {
                        setStartDate(value);
                        setStartTimeParam(dateString);
                      }}
                      value={startDate}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    />
                    <span
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        marginLeft: '1%',
                        marginRight: '1%'
                      }}>
                      -
                    </span>
                    <DatePicker
                      //getting input value for start date
                      placeholder={lang.label_selectdate}
                      showTime={{
                        defaultValue: dayjs('23:59:59', 'HH:mm:ss')
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      // onChange={onChange1}
                      // value={endDate}
                      value={endDate}
                      onChange={(value, dateString) => {
                        setEndDate(value);
                        setendTimeParam(dateString);
                      }}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    />
                  </div>
                </div>

                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_amount}
                  </SubHeading>
                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-around',
                      alignItems: 'center',
                      width: '300px'
                    }}>
                    <InputField
                      placeHolder="Minimum"
                      type="number"
                      name="minAmmount"
                      value={minimum}
                      onChange={(name, value) => {
                        setMinimum(value);
                      }}
                    />
                    <span
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        marginLeft: '1%',
                        marginRight: '1%'
                      }}>
                      -
                    </span>
                    <InputField
                      placeHolder="Maximun"
                      type="number"
                      name="maxAmmount"
                      value={maximum}
                      onChange={(name, value) => {
                        setMaximum(value);
                      }}
                    />
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_username}
                  </SubHeading>

                  <Input
                    // style={inputStyle}
                    name="search"
                    type="text"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </div>

                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_type}
                  </SubHeading>
                  <div>
                    <Select
                      value={type}
                      defaultValue="All"
                      // style={selectStyle}
                      onChange={(value, label) => {
                        setType(value);
                        setLabel(label);
                      }}
                      style={{ width: '200px' }}
                      customWidth="200px"
                      extraStyle={{ height: '35px' }}
                      options={[
                        {
                          value: null,
                          label: lang.label_all
                        },
                        {
                          value: '1',
                          label: lang.label_deposit
                        },
                        {
                          value: '2',
                          label: lang.label_withdraw
                        },
                        {
                          value: '3',
                          label: 'Bet'
                        },
                        {
                          value: '4',
                          label: 'Promotion'
                        },
                        // {
                        //   value: '5',
                        //   label: 'Manual Adding'
                        // },
                        {
                          value: '5',
                          label: 'Manual Adjustment '
                        },
                        // {
                        //   value: '6',
                        //   label: 'Manual Deducting'
                        // },
                        {
                          value: '7',
                          label: 'Event Reward'
                        },
                        {
                          value: '8',
                          label: lang.label_rebate
                        },
                        {
                          value: '9',
                          label: 'Affiliate'
                          // label: lang.label_affiliate
                        }
                      ]}
                    />
                  </div>
                </div>

                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_ignoretestaccount}
                  </SubHeading>
                  <div>
                    <Select
                      // value={type}
                      defaultValue="Yes"
                      // style={selectStyle}
                      style={{ width: '200px' }}
                      onChange={(value) => {
                        setIgnoreTestAccount(value);
                      }}
                      customWidth="200px"
                      extraStyle={{ height: '35px' }}
                      options={[
                        {
                          value: 1,
                          label: lang.label_yes
                        },
                        {
                          value: '0',
                          label: lang.label_no
                        }
                      ]}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    marginTop: '20px',
                    marginLeft: '10px',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                  }}>
                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1'
                    }}
                    htmlType="submit"
                    className="btnStyle"
                    // type="primary"
                  >
                    <img src={SearchIcon} alt="search" />
                    {lang.label_search}
                  </Button>

                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      borderRadius: '3px',
                      padding: '6px',
                      border: '1px solid grey',
                      width: '84px'
                    }}
                    className="btnReset"
                    htmlType="reset"
                    onClick={() => {
                      setFilteredParams("")
                      setStartDate('');
                      setEndDate('');
                      setMinimum('');
                      setMaximum('');
                      setIgnoreTestAccount('');
                      setType('');
                      setUsername('');
                      getPlayerBalanceHistoryWithoutParams();
                      resetSelection();
                    }}>
                    <img src={ResetIcon} alt="reset" />
                    {lang.label_reset}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <br />
          <PlayerBalanceHistoryTable data={playerBalanceHistoryData} loading={loading} />

          {/* <table style={{ width: "100%", background: "white" }}>
          <tfoot style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "70%" }} />
              <td style={{ minWidth: "120px", textAlign: "left",color:"black" }}>
                {lang.label_totaldepositamount}
              </td>
              <td style={{ width: "5%" }}>{addCommasToNumber(totalDeposit)}</td>
              <td style={{ width: "10%" }} />
            </tr>
            <tr>
              <td />
              <td style={{textAlign: "left",color:"black"}}>
                {lang.label_totalwithdrawalamount}
              </td>
              <td>{addCommasToNumber(totalWithdraw)}</td>
              <td style={{ width: "10%" }} />
            </tr>
            <tr>
              <td />
              <td style={{ textAlign: "left",color:"black" }}>
                {lang.label_totalamount}
              </td>
              <td>{addCommasToNumber(totalAmount)}</td>
            </tr>
          </tfoot>
        </table> */}
          {/* <table style={{ width: '100%', background: 'white', marginTop: '30px' }}>
            <tfoot style={{ width: '100%' }}>
              <tr>
                <td style={{ width: '63%' }} />
                <td style={{ textAlign: 'left', color: 'black', fontWeight: 500 }}>{lang.label_totalamount}</td>
                <td style={{ color: 'black', fontWeight: 500 }}>
                  {toFixedFunc(totalAmount, process.env.REACT_APP_Decimel_Points)}
                </td>
                <td style={{ width: '15%' }} />
              </tr>
            </tfoot>
          </table> */}
          <Pagination
            showSizeChanger={false}
            defaultCurrent={1}
            pageSize={perpage}
            total={total}
            defaultPage={1}
            current={activepage}
            onChange={(page) => {
              setActivepage(page);
              getPlayerBalanceHistory(page);
            }}
          />
        </div>
      </form>
    </>
  );
};

export default PlayerBalanceHistory;
