import SubHeading from '../../../../components/HelperComponents/SubHeading';
import InputField from '../../../../components/HelperComponents/InputField';
import styles from './CreateModal.module.css';
import FileUpload from '../../../../components/HelperComponents/FileUpload';
import CustomButton from '../../../../components/HelperComponents/CustomButton';
import SelectBox from '../../../../components/HelperComponents/SelectBox';
import { Select, Switch } from 'antd';
import Loader from '../../../../components/HelperComponents/Loader';
import LangContext from '../../../../helps/contexts/lang-context';
import React, { useContext, useEffect, useState } from 'react';
import { Input, Button, message, Upload } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import MultiSelectComp from '../../../../components/HelperComponents/MultiSelect';
import GetApi from '../../../../helps/contexts/Api/GetApi';
import UploadWithLabel from '../../../../components/HelperComponents/UploadWithLabel';

const CreateModal = ({
  submitFunc,
  type,
  setFormData,
  setItem,
  formData,
  handleSelectChange,
  handleInputChange,
  item,
  handleCloseModal,
  platFormData,
  categoryData,
  errors,
  handleSwitchChange,
  submitLoading,
  updateFunc
}) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  //file upload square
  const [allCurrency, setAllCurrency] = useState([]);
  const [allProperties, setAllProperties] = useState([]);
  const [gameTypeList, setGameTypeList] = useState([]);
  const handleMulltiSelectChange = (value, prop2, name) => {
    setFormData({
      ...formData,
      [name]: prop2
    });
  };
  useEffect(() => {
    if (item?.game_categories?.length > 0) {
      const editCategoriesArray = item?.game_categories?.map((item) => {
        const jsonString = item?.name && JSON.parse(item?.name);
        return {
          id: item.id,
          value: jsonString && jsonString['en']
        };
      });
      setItem({
        ...item,
        game_categories: editCategoriesArray
      });
    }
  }, []);

  const getCurrencyList = async () => {
    try {
      const res = await GetApi.sendApiRequest('/player/currencies');
      if (res) {
        // const object1 = {
        //   key: "Please Select currency",
        //   value: "Please Select currency",
        //   label: "Please Select currency",
        // }
        const finalArray = Object.keys(res).map((key, index) => {
          return {
            key: Number(key),
            value: Number(key),
            label: res[key]
          };
        });
        // finalArray.unshift(object1)
        setAllCurrency(finalArray);
      } else {
        return null;
      }
    } catch (e) {}
  };

  const getGameTypeList = async () => {
    try {
      const res = await GetApi.sendApiRequest('/game_items/types');
      if (res) {
        const finalArray = Object.keys(res).map((key, index) => {
          return {
            // key: Number(key),
            value: Number(key),
            label: res[key]
          };
        });
        // finalArray.unshift(object1)
        setGameTypeList(finalArray);
      } else {
        return null;
      }
    } catch (e) {}
  };
  const getGameProperties = async () => {
    try {
      const res = await GetApi.sendApiRequest('/game_items/properties');
      if (res) {
        // const object1 = {
        //   key: "Please Select properties",
        //   value: "Please Select properties",
        //   label: "Please Select properties",
        // }
        const finalArray = Object.keys(res).map((key, index) => {
          return {
            // key: Number(key),
            value: Number(key),
            label: res[key]
          };
        });
        // finalArray.unshift(object1)
        setAllProperties(finalArray);
      } else {
        return null;
      }
    } catch (e) {}
  };
  useEffect(() => {
    getCurrencyList();
    getGameTypeList();
    getGameProperties();
  }, []);
  useEffect(() => {
    if (item) {
      const newArray = {
        englishName: item?.englishName,
        vietnamName: item?.vietnamName,
        game_platform_id: item?.game_platform_id,
        game_category_id: item?.game_categories?.map((item) => {
          const key = Object.keys(item).value;
          const id = item.id;

          return { label: key, value: id };
        }),
        icon: '',
        type: item?.type,
        status: item?.status,
        game_id: item?.game_id,
        iconsquare: item?.icon_square,
        iconrectangle: item?.icon_rectangle,
        currency: item?.supported_currencies_array
          ?.filter((item) => Object.values(item)[0] === true)
          .map((item) => {
            const key = Object.keys(item)[0];
            const id = item.id;

            return { label: key, value: id };
          }),
        property: item?.properties_array
          ?.filter((item) => Object.values(item)[0] === true)
          .map((item) => {
            const key = Object.keys(item)[0];
            const id = item.id;

            return { label: key, value: id };
          })
      };

      setFormData(newArray);
    }
  }, [item]);

  const onChangeGameType = (value) => {
    setFormData({ ...formData, type: value })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div className={styles.wrapper}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '350px', padding: '0 10px' }}>
          <div className={styles.flexColumn} style={{ marginTop: '20px' }}>
            <SubHeading className={styles.heading}>
              {lang.label_platforms}
              <span className={styles.redSteric}> *</span>
            </SubHeading>
            <SelectBox
              name="game_platform_id"
              data={platFormData}
              extraStyle={{
                width: '100%',
                height: '40px'
              }}
              type={type === 'update' ? 'update' : ''}
              value={formData?.game_platform_id}
              onChange={handleSelectChange}
              //  value={formData.bank} onChange={handleSelectChange} data={banksData}
            />
            {errors?.platformError && <p className="error">{errors?.platformError}</p>}
          </div>

          <div className={styles.flexColumn} style={{ marginTop: '20px' }}>
            <SubHeading className={styles.heading}>
              {lang.label_game_type}
              <span className={styles.redSteric}> *</span>
            </SubHeading>
            <Select
                required
                onChange={onChangeGameType}
                placeholder="Select Game type"
                value={(formData && formData?.type) || undefined}
                extraStyle={{
                  width: '100%',
                  height: '40px'
                }}
                options={gameTypeList}
              />
            {/* {errors?.typeError && <p className="error">{errors?.typeError}</p>} */}
          </div>

          <div className={styles.flexColumn} style={{ marginTop: '20px' }}>
            <SubHeading className={styles.heading}>
              {lang.label_gamecategory}
              <span className={styles.redSteric}> *</span>
            </SubHeading>
          
            <MultiSelectComp
              name="game_category_id"
              options={categoryData?.filter((category) => category.id !== 0)}
              extraStyle={{
                width: '100%',
                height: '40px'
              }}
              type={type === 'update' ? 'update' : ''}
              value={formData?.game_category_id}
              handleChange={handleMulltiSelectChange}
            />
            {errors?.gameCatError && <p className="error">{errors?.gameCatError}</p>}
          </div>

          <div className={styles.flexColumn} style={{ marginTop: '20px' }}>
            <SubHeading className={styles.heading}>
              Currency
              <span className={styles.redSteric}> *</span>
            </SubHeading>
            <MultiSelectComp
              name="currency"
              options={allCurrency.filter((currency) => currency.value !== 0)}
              extraStyle={{
                width: '100%',
                height: '40px'
              }}
              type={type === 'update' ? 'update' : ''}
              value={formData?.currency}
              handleChange={handleMulltiSelectChange}
            />

            {errors?.currencies && <p className="error">{errors?.currencies}</p>}
          </div>
          <div className={styles.flexColumn} style={{ marginTop: '20px' }}>
            <SubHeading className={styles.heading}>
              Properties
              {/* <span className={styles.redSteric}> *</span> */}
            </SubHeading>
            {/* <SelectWithLabel label={lang.label_currency} options={allCurrency} onChange={val => setFormData({ ...formData, currency: val })}
             value={formData.currency} isRequired={true} /> */}

            <MultiSelectComp
              name="property"
              options={allProperties.filter((property) => property.value !== 0)}
              extraStyle={{
                width: '100%',
                height: '40px'
              }}
              type={type === 'update' ? 'update' : ''}
              value={formData?.property}
              handleChange={handleMulltiSelectChange}
            />

            {/* {errors?.properties && <p className="error">{errors?.properties}</p>} */}
          </div>

          <div className={styles.flexColumn} style={{ marginTop: '20px' }}>
            <SubHeading className={styles.heading}>
              {lang.label_gameid}
              <span className={styles.redSteric}> *</span>
            </SubHeading>
            <InputField
              extraStyle={{
                width: '100%'
              }}
              value={formData?.game_id}
              onChange={handleInputChange}
              name="game_id"
              type="text"
            />

            {errors?.gameId && <p className="error">{errors?.gameId}</p>}
          </div>
          <div className={styles.flexColumn} style={{ marginTop: '20px' }}>
            <SubHeading className={styles.heading}>
              {lang.label_gamename}
              {lang.label_en}
              <span className={styles.redSteric}> *</span>
            </SubHeading>
            <InputField
              extraStyle={{
                width: '100%'
              }}
              value={formData?.englishName}
              onChange={handleInputChange}
              name="englishName"
              type="text"
            />
            {errors?.nameEnError && <p className="error">{errors?.nameEnError}</p>}
          </div>
          <div className={styles.flexColumn} style={{ marginTop: '20px' }}>
            <SubHeading className={styles.heading}>
              {lang.label_gamename}
              {lang.label_vn}
              <span className={styles.redSteric}> </span>
            </SubHeading>
            <InputField
              extraStyle={{
                width: '100%'
              }}
              value={formData?.vietnamName}
              onChange={handleInputChange}
              name="vietnamName"
              type="text"
            />
            {/* {errors?.nameVnError && <p className="error">{errors?.nameVnError}</p>} */}
          </div>
          <div className={styles.flexStyle} style={{ marginTop: '15px' }}>
            <SubHeading className={styles.heading}>
              {lang.label_status}
              <span className={styles.redSteric}> *</span>
            </SubHeading>

            <div style={{ marginLeft: '50px' }}>
              <Switch checked={formData?.status === 1 ? true : false} onChange={handleSwitchChange} />
            </div>
          </div>
        </div>

        {/* <div className={styles.flexColumn} style={{ marginTop: "15px" }}>
        <SubHeading className={styles.heading}>
          {lang.label_icon}
          <span className={styles.redSteric}> *</span>
        </SubHeading>
        <FileUpload
          placeHolder="Upload Icon..."
          formData={formData}
          value={formData?.icon}
          setFormData={setFormData}
          item={item}
          setItem={setItem}
          name="image"
        />
       
      </div> */}
        <div style={{ display: 'flex', flexDirection: 'column', padding: '5px 20px', width: '400px' }}>
          <div className={styles.flexColumn} style={{ marginTop: '15px' }}>
            <UploadWithLabel
              name="game_items"
              label={
                <SubHeading className={styles.heading}>
                  {lang.label_iconsq}
                  <span className={styles.redSteric}> *</span>
                </SubHeading>
              }
              initialImageUrl={formData?.iconsquare || null}
              onChange={(val) => setFormData({ ...formData, iconsquare: val })}
            />
            {errors?.image1Error && <p className="error">{errors?.image1Error}</p>}
          </div>
          <div className={styles.flexColumn} style={{ marginTop: '15px' }}>
            <UploadWithLabel
              name="game_items"
              label={
                <SubHeading className={styles.heading}>
                  {lang.label_iconrec}
                  <span className={styles.redSteric}> *</span>
                </SubHeading>
              }
              initialImageUrl={formData?.iconrectangle || null}
              onChange={(val) => setFormData({ ...formData, iconrectangle: val })}
            />
            {errors?.image2Error && <p className="error">{errors?.image2Error}</p>}
          </div>
        </div>
      </div>
      <div>
        <div
          className={styles.flexStyle}
          style={{
            marginTop: '30px',
            justifyContent: 'flex-end'
          }}>
          {submitLoading ? (
            <Loader />
          ) : (
            <CustomButton
              type={styles.mainBg}
              onClick={type === 'update' ? updateFunc : submitFunc}
              text={type === 'update' ? lang.label_update : lang.label_submit}
            />
          )}
          <CustomButton type={styles.whiteBg} onClick={handleCloseModal} text={lang.label_cancel} />
        </div>
      </div>
    </div>
  );
};

export default CreateModal;
