import {
  Input,
  Button,
  Row,
  Col,
  message,
  Select
} from "antd";
import LangContext from "../../../helps/contexts/lang-context";
import { useContext, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PostApi from "../../../helps/contexts/Api/PostApi";

import GetApi from "../../../helps/contexts/Api/GetApi";

const AddSuperiorAgentForm = ({ handleCancel ,fetchSeniorgents}) => {
  const nav=useNavigate()

  // form states
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [uniquecode, setUniquecode] = useState("");
  const[countrycode,setcountrycode]=useState()
  const[codedata,setcodedata]=useState([])
  const { Option } = Select;
  useEffect(() => {
    
    fetchCountryCode();
  }, []);
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const fetchCountryCode = async () => {
    const path = "/user/phone/codes";
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path, {}, params);
   
    if (res && res.length) {
      const finalArray = res.map((cc, index) => ({
        key: index,
        code: cc.code,
      }));
      setcodedata(finalArray);
    } else {
      setcodedata([]);
    }
  };
  const oncodechange = (value) => {
    setcountrycode(value);
  };
  //form submit to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
    

      const finalphone = countrycode + phone;

    
      const path = `/agent/create`;
      // sending request
      const res = await PostApi.PostApiRequest(path, {
        user_name: username,
        password: password,
        name: name,
        phone: countrycode ? finalphone : phone,
        unique_code: uniquecode,
      });

      //if success
      if (res?.status===200) {
        message.success(lang.label_superagentaddsuccess);
        setName("");
        setPassword("");
        setPhone("");

        setUniquecode("");
        setUserName("");

        handleCancel();
        fetchSeniorgents()
      }
    } catch (e) {
      if(e?.status===422){
      
      message.error( e.data.message);

      }
    }
  };
  //username input change and validation
 const onInputChange = (e) => {
  const { value } = e.target;

  const re = /^[a-z0-9_-]*$/;
  if (value === "" || re.test(value)) {
    setUserName(value);
  } else {
    message.error("Please Enter Only Numbers and Lowercase Alphabets");
  }
};
const onCodeChange = (e) => {
  const { value } = e.target;

  const re = /^[a-z0-9_-]*$/;
  if (value === "" || re.test(value)) {
    setUniquecode(value);
  } else {
    message.error("Please Enter Only Numbers and Lowercase Alphabets");
  }
};
  return (
    <div
      style={{
        width: "97%",
        height: "85%",
        justifyContent: "space-evenly",
      }}
    >
      <p
        style={{
          color: "#A5A5A5",
          fontSize: "14px",
          marginTop: "-1%",
          marginBottom: "3%",
        }}
      >
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: "3%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            marginRight: "-6%",
          }}
        >
          <Row>
            <Col span={12}>
              <label style={{ paddingBottom: "3px" }}>{lang.label_name}</label>
              <br />

              <Input
                defaultValue="Player Name"
                style={{
                  width: "85%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                type="text"
                placeholder="Player Name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col span={12}>
              <label>
                {lang.label_username}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />

              <Input
                defaultValue="Player Username"
                type="text"
                placeholder="Player Name"
                name="username"
                required
                value={username}
                onChange={onInputChange}
                style={{
                  width: "85%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>
                {lang.label_password}
                <span style={{ color: "red" }}>*</span>
              </label>{" "}
              <br />
              <Input.Password
                style={{
                  width: "85%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                required
                type="password"
                placeholder="Enter Password"
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                maxLength={18}
                minLength={8}
              />
            </Col>
            <Col span={12}>
              <label>
                {lang.label_uniquecode}
                <span style={{ color: "red" }}>*</span>
              </label>{" "}
              <br />
              <Input
                style={{
                  width: "85%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                required
                placeholder="Enter Password"
                name="uniquecode"
                value={uniquecode}
                onChange={onCodeChange}
                maxLength={5}
              />
            </Col>
          </Row>

          <Row>
          <Col span={3}>
              <label>code </label>
              <br />
              <Select
                style={{ width: "90%", marginTop: "5px" }}
                onChange={oncodechange}
                defaultValue={countrycode}
              >
                <Option>{}</Option>
                {codedata &&
                  codedata.map((cc, index) => {
                    return (
                      <Option key={index} value={cc.code}>
                        {cc.code}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
            <Col span={21}>
              <label>{lang.label_phone} </label>
              <br />

              <Input
                defaultValue="5123455633"
                style={{
                  width: "93%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                type="text"
                placeholder="Enter Phone Number"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Col>
          </Row>

          <br />

          <Row></Row>
          <br />

          <div style={{}}>
            {" "}
            <Button
              style={{
                borderRadius: "3px",
                backgroundColor: "#004A7F",
                color: "white",
                position: "absolute",
                right: "20%",
                top: "87%",
              }}
              htmlType="submit"
            >
              {lang.label_submit}
            </Button>
            <Button
              style={{
                borderRadius: "3px",
                color: "#004A7F",
                position: "absolute",
                right: "8%",
                top: "87%",
              }}
              onClick={() => {
                setName("");
                setPassword("");
                setPhone("");

                setUniquecode("");
                setUserName("");
                handleCancel();
              }}
            >
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddSuperiorAgentForm;
