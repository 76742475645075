import React, { useContext, useState } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import styles from './RebateFilter.module.css';
import Heading from '../../../components/HelperComponents/Heading';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import SearchIcon from "../../../images/Search.png";
import ResetIcon from "../../../images/Reset.png";
import { Button, Input, InputNumber, Select } from 'antd';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';

export default function RebateFilters({ setIsModalOpen, categoryList, setFilters, onRefresh }) {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [formData, setFormData] = useState();
  const { hasPermission } = useCheckPermission();

  const onReset = () => {
    onRefresh();
    setFormData();
    // setFormData({ status: [transactionStatuses.filter((s) => !['1', '2', '6'].includes(s.value))] });
  };

  const onSubmit = () => {
    setFilters(formData);

    // const finalData = JSON.parse(JSON.stringify(formData));
    // Object.keys(finalData).forEach((key) => {
    //   if (finalData[key] === null || key === 'dates') {
    //     delete finalData[key];
    //   }
    // });
    // setData(formData);
  };

  //   const getStatuses = async () => {
  //     try {
  //       const path = '/admin/transactions/statuses';
  //       //sending api request
  //       const res = await GetApi.sendApiRequest(path, {}, {});
  //       if (res) {
  //         const x = Object.keys(res);
  //         if (x && x.length) {
  //           const finalArr = [];
  //           x.forEach((val) => {
  //             finalArr.push({ value: val, label: res[val] });
  //           });
  //           setTransactionStatuses(finalArr);
  //         }
  //       }
  //     } catch (error) {
  //       if (error?.response?.status === 401) {
  //         Navigate('/login');
  //       }
  //     }
  //   };

  const inputStyle = {
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px',
    width: '200px'
  };
  const selectStyle = {
    height: '33px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '3px',
    width: '200px'
  };

  //   useEffect(() => {
  //     getStatuses();
  //   }, []);

  return (
    <div className={styles.container}>
      <div
        style={{
          borderBottom: '1px solid #d9d9d9',
          height: '48px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '0px 13px'
        }}>
        <div>
          <Heading>{lang.label_filters}</Heading>
        </div>
        <div>
          {hasPermission('Create Rebate') && (
            <Button
            className="btnStyle"
            style={{
              borderRadius: '3px',
              padding: '6px',
              width: '150px'
            }}
              onClick={() => setIsModalOpen(true)}>
              {lang.label_add_rebate}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.filterLowerContainer}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            paddingRight: '14px',
            flexWrap: 'wrap'
          }}>
          <div>
            <div className={styles.flexStyle}>
              <div
                style={{
                  minWidth: '100%'
                }}>
                <SubHeading
                  className="filterTest"
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500,
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}>
                  {lang.label_category_title}
                </SubHeading>
              </div>
              <Select
                style={{ width: 150, ...selectStyle }}
                allowClear
                value={formData?.category_id}
                onChange={(val) => setFormData((prevData) => ({ ...prevData, category_id: val }))}
                onClear={() => setFormData((prevData) => ({ ...prevData, category_id: null }))}
                options={categoryList && categoryList.map((cat) => ({ value: cat.id, label: cat.name }))}
              />
            </div>
          </div>

          <div>
            <div className={styles.flexStyle}>
              <div
                style={{
                  minWidth: '100%'
                }}>
                <SubHeading
                  className="filterTest"
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500,
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}>
                  {lang.label_currency}
                </SubHeading>
              </div>
              <Select
                style={{ width: 150, ...selectStyle }}
                // allowClear
                value={formData?.currency}
                onChange={(val) => setFormData((prevData) => ({ ...prevData, currency: val }))}
                // onChange={(val) => setFormData((prevData) => ({ ...prevData, payment_method_id: val }))}
                // onClear={() => setFormData((prevData) => ({ ...prevData, payment_method_id: null }))}
                options={[
                  {
                    label: 'PHP',
                    value: 2
                  }
                ]}
              />
            </div>
          </div>
          <div>
            <div className={styles.flexStyle}>
              <div
                style={{
                  minWidth: '100%'
                }}>
                <SubHeading
                  className="filterTest"
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500,
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}>
                  {lang.label_rebate_cycle}
                </SubHeading>
              </div>
              <Select
                style={{ width: 150, ...selectStyle }}
                allowClear
                value={formData?.rebate_cycle}
                onChange={(val) => setFormData((prevData) => ({ ...prevData, rebate_cycle: val }))}
                onClear={() => setFormData((prevData) => ({ ...prevData, rebate_cycle: null }))}
                options={[
                  {
                    label: 'Daily',
                    value: 'daily'
                  },
                  {
                    label: 'Weekly',
                    value: 'weekly'
                  },
                  {
                    label: 'Monthly',
                    value: 'monthly'
                  }
                ]}
              />
            </div>
          </div>
          <div>
            <div className={styles.flexStyle}>
              <div
                style={{
                  minWidth: '100%'
                }}>
                <SubHeading
                  className="filterTest"
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500,
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}>
                  {lang.label_rebate_title}
                </SubHeading>
              </div>
              <Input
                style={{
                  width: '150px',
                  ...inputStyle
                }}
                placeHolder="Enter Rebate Title"
                value={formData?.title}
                onChange={(e) => setFormData((prevData) => ({ ...prevData, title: e.currentTarget.value }))}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '19px' }}>
            <div className={styles.flexStyle}>
              <Button
                className="btnStyle"
                style={{
                  display : 'flex',
                alignItems : 'center',
                justifyContent : 'space-evenly',
                  backgroundColor: '#004A7F',
                  color: 'white',
                  width: '125px',
                  height: '35px',
                  background: '#004A7F 0% 0% no-repeat padding-box',
                  borderRadius: '3px',
                  opacity: '1',
                  margin: '0px 10px'
                }}
                onClick={onSubmit}>
                  <img src={SearchIcon} alt='search' />
                {lang.label_search}
              </Button>
            </div>
            <div className={styles.flexStyle}>
              <Button
                onClick={onReset}
                style={{
                  display : 'flex',
                alignItems : 'center',
                justifyContent : 'space-evenly',
                  borderRadius: '3px',
                  padding: '6px',
                  border: '1px solid grey',
                  width: '84px'
                }}
                className="btnReset">
                   <img src={ResetIcon} alt='reset' />
                {lang.label_reset}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
