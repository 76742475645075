import { CalendarOutlined } from '@ant-design/icons';
import { Card, Row, Col, DatePicker, Button, Input, Select, Modal, Pagination, message, Spin } from 'antd';
import PlayerListTable from './PlayerListTable';
import { useContext, useState, useEffect } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import AddPlayerForm from './AddPlayerForm';
import '../../../styles/ButtonStyle.css';
import GetApi from '../../../helps/contexts/Api/GetApi';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import PaginationComp from '../../../components/HelperComponents/PaginationComp';
import { convertLocalTimeToUTC, convertUTCToLocalTime } from '../../../helps/HelperFunctions';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import { TabContext } from '../../../helps/contexts/TabContext';
import { useLocation } from 'react-router-dom';
import styles from './PlayerList.module.css';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import SelectBox from '../../../components/HelperComponents/SelectBox';
import Heading from '../../../components/HelperComponents/Heading';
import SearchIcon from '../../../images/Search.png';
import ResetIcon from '../../../images/Reset.png';
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';
import { exportPlayerList, exportPlayerList1 } from '../../../helps/contexts/Api/downloadFile';

const datePickerStyle = {
  width: '185px',
  height: '35px',
  background: ' #FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #D9D9D9',
  borderRadius: '3px'
};
const iconStyle = { color: '#004A7F' };
const inputStyle = {
  width: '190px',
  height: '35px',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #D9D9D9',
  borderRadius: '3px'
};
const selectStyle = {
  width: '188px',
  height: '33px',
  /* UI Properties */
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  borderRadius: '3px'
};

const PlayerList = ({ data, pageTotal }) => {
  const nav = useNavigate();
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { hasPermission } = useCheckPermission();
  //states
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [status, setStatus] = useState(0);
  const [playerData, setPlayerData] = useState([]);
  const [currentpage, setCurrentpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchtext, setSearchtext] = useState('');
  const [visible, setVisible] = useState(false);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [startTimeParam, setStartTimeParam] = useState('');
  const [endTimeParam, setEndTimeParam] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const { path } = useContext(TabContext);

  // get id or state from tag management
  const location = useLocation();
  const { state } = location;
  // const agentId = state && state.agent_id;
  const [tagname, setTagName] = useState(state ? state.name : null);
  const [agentId, setagentId] = useState(state ? state.agent_id : null);
  const [loadingData, setLoadingData] = useState(false);
  const [progress, setProgress] = useState(0);
  const [filteredParams, setFilteredParams] = useState();

  const downloadedFileIds = new Set();

  // SET TAG NAME AFTER FILTER
  useEffect(() => {
    if (state && state?.name) {
      setTagName(state?.name);
    } else {
      setTagName('');
    }
  }, [state]);

  // SET agent_id & active page
  useEffect(() => {
    if (state && state?.agent_id) {
      setagentId(state?.agent_id);
      setActivepage1(1);
    } else {
      setagentId(null);
    }
  }, [state]);

  useEffect(() => {
    getPlayers(activepage);
    setActivepage1(1);
  }, [agentId]);

  useEffect(() => {
    if (path === '/player-management/player-list') {
      getPlayers();
    }
  }, [path]);
  //date picker startdate
  const onChange = (value, dateString) => {
    setStartDate(value);
    setStartTimeParam(dateString);
  };
  //date picker start date completed
  //date picker end date

  const onChange1 = (value, dateString) => {
    setEndDate(value);
    setEndTimeParam(dateString);
  };

  //datepicker end date completed
  //status dropdown
  const handleChange = (value) => {
    setStatus(value);
  };
  //status dropdown

  // Add player popup
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const handleOk = () => {
    setVisible(false);
  };

  //add player popup

  // on reset of filters on table values
  const onReset = () => {
    setLoading(true);
    setStartDate('');
    setEndDate('');
    setSearchtext(null);
    setStatus('');
    getPlayers();
    setLoading(false);
  };

  //on submit of filters on table values
  const onSubmit = (e) => {
    e.preventDefault();
    setActivepage1(1);
    getPlayers();
  };

  const getPlayers = async (customPage = null) => {
    try {
      const path = `/admin/players`;
      const params = {};

      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      if (startDate) params['start_date'] = convertLocalTimeToUTC(startTimeParam);
      if (endDate) params['end_date'] = convertLocalTimeToUTC(endTimeParam);
      if (status) params['status'] = status;
      if (searchtext) params['search'] = searchtext;

      if (agentId) {
        params['tag_id'] = agentId;
      }

      setLoading(true);
      setFilteredParams(params);

      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.data && res.data.length) {
        const finalArray = res.data.map((player, index) => {
          return {
            key: index,
            id: player.id ? player.id : '',
            username: player.user.user_name ? player.user.user_name : '',
            name: player?.user?.name,
            phone: player.user.phone && hasPermission('View Player Phone Number') ? player.user.phone : '',
            agent: player?.agent?.user?.user_name,
            playerCurrency: player?.wallet?.currency_name,
            lastSeen: player?.user?.last_seen ? player?.user?.last_seen : '',
            baseBalance: player?.wallet?.base_balance,
            locked_balance: player?.wallet?.locked_balance,
            locked_base_balance: player?.wallet?.locked_base_balance,
            balance: player.wallet.balance ? player.wallet.balance : 0,
            currency: player.wallet.currency_name ? player.wallet.currency_name : '',
            status: player.active ? player.active : 0,
            created_by_admin: player?.user?.latest_login_history?.created_by_admin,
            registerip: player.user.signup_history ? player.user.signup_history.ip : '',
            registertime: player.user.signup_history ? (
              <span>
                {
                  //new Date(player.user.signup_history.created_at).toLocaleString()
                  /* moment.utc(player.user.signup_history.created_at, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss") */
                  convertUTCToLocalTime(player.user.signup_history.created_at)
                }
              </span>
            ) : (
              ''
            ),
            loginip: player.user.latest_login_history ? player.user.latest_login_history.ip : '',
            logintime: player.user.latest_login_history ? (
              <span>
                {
                  // new Date( player.user.latest_login_history.created_at).toLocaleString()
                  //moment.utc(player.user.latest_login_history.created_at, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")
                  convertUTCToLocalTime(player.user.latest_login_history.created_at)
                }
              </span>
            ) : (
              ''
            ),
            password: player.user ? player.user.password : '',
            birthday: player.user.birthday ? player.user.birthday : '',
            remark: player.user.remark ? player.user.remark : '',
            userid: player.user ? player.user_id : '',
            phone_verified: player.user ? player?.user?.is_contact_verified : ''
          };
        });
        setPlayerData(finalArray);

        setCurrentpage(res.current_page);
        setTotal(res.total);
        setPerpage(res.per_page);
        setNextPage(res.next_page_url);
      } else {
        setPlayerData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };

  const getPlayersWithouparams = async (customPage = null) => {
    const path = `/admin/players`;
    const res = await GetApi.sendApiRequest(path, null, null);
    setLoading(true);
    if (res && res.data && res.data.length) {
      const finalArray = res.data.map((player) => {
        return {
          key: player.user ? player.user.id : '',
          id: player.id ? player.id : '',
          username: player.user.user_name ? player.user.user_name : '',
          name: player.user.name ? player.user.name : '',
          phone: player.user.phone ? player.user.phone : '',
          agent: player?.agent?.user?.user_name,
          lastSeen: player?.user?.last_seen ? player?.user?.last_seen : '',
          playerCurrency: player?.wallet?.currency_name,
          locked_balance: player?.wallet?.locked_balance,
          locked_base_balance: player?.wallet?.locked_base_balance,
          baseBalance: player?.wallet?.base_balance,
          balance: player.wallet.balance ? player.wallet.balance : 0,
          created_by_admin: player?.user?.latest_login_history?.created_by_admin,
          status: player.active ? player.active : 0,
          registerip: player.user.signup_history ? player.user.signup_history.ip : '',
          registertime: player.user.signup_history ? (
            <span>
              {
                // new Date(player.user.signup_history.created_at).toLocaleString()
                //  moment.utc(player.user.signup_history.created_at, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")
                convertUTCToLocalTime(player.user.signup_history.created_at)
              }
            </span>
          ) : (
            ''
          ),
          loginip: player.user.latest_login_history ? player.user.latest_login_history.ip : '',
          logintime: player.user.latest_login_history ? (
            <span>
              {
                // new Date( player.user.latest_login_history.created_at).toLocaleString()
                //moment.utc(player.user.latest_login_history.created_at, "YYYY-MM-DD HH:mm:ss").local().format("YYYY-MM-DD HH:mm:ss")
                convertUTCToLocalTime(player.user.latest_login_history.created_at)
              }
            </span>
          ) : (
            ''
          ),
          password: player.user ? player.user.password : '',
          birthday: player.user.birthday ? player.user.birthday : '',
          remark: player.user.remark ? player.user.remark : '',
          userid: player.user ? player.user_id : '',
          phone_verified: player.user ? player?.user?.is_contact_verified : ''
        };
      });
      setPlayerData(finalArray);
      setCurrentpage(res.current_page);
      setTotal(res.total);
      setPerpage(res.per_page);
      setNextPage(res.next_page_url);
    } else {
      setPlayerData([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const onChangepage = (page) => {
    setActivepage1(page);
    getPlayers(page);
  };
  const paginationConfig = {
    current: currentpage,
    pageSize: perpage ? perpage : 0,
    total: total ? total : 0,
    showSizeChanger: false,
    showQuickJumper: false,
    showTotal: (total) => `Total ${total} items`,
    onChange: onChangepage
  };

  // SET TAGNAME AFTER FILTER
  const cardTitle = tagname ? `${lang.label_filters} / ${tagname}` : lang.label_filters;
  // console.log(filteredParams);

  const exportData = async (payload, agentId) => {
    const params = {
      export_type: 0,
      export: 1,
      end_date: payload.end_date,
      start_date: payload.start_date,
      search: payload.search,
      status: payload.status,
      ...(agentId && { tag_id: agentId })
    };

    try {
      setLoadingData(true);
      const response = await exportPlayerList(params);

      if (response?.data?.job_id) {
        const jobId = response.data.job_id;
        const totalFileCount = response.data.progress.total_file_count;

        const pollExportStatus = async (jobId) => {
          try {
            const statusResponse = await exportPlayerList1(jobId);

            const files = statusResponse?.data?.file || [];
            files.forEach((file) => {
              if (!downloadedFileIds.has(file.file_id)) {
                const base64String = file.file_content;
                const fileName = file.file_name + (file.file_type.includes('csv') ? '.csv' : '.xlsx');
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: file.file_type });

                const link = document.createElement('a');
                const blobUrl = URL.createObjectURL(blob);
                link.href = blobUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);

                // Mark the file as downloaded
                downloadedFileIds.add(file.file_id);
                // Optionally, you can keep track of downloaded files count
                // setDownloadedFiles((prev) => prev + 1);
              }
            });

            if (statusResponse?.data?.progress?.is_all_file_generated) {
              setLoadingData(false);
              message.success(`Export completed. ${totalFileCount} files downloaded.`);
            } else {
              setTimeout(() => pollExportStatus(jobId), 2000);
            }
          } catch (error) {
            setLoadingData(false);
            message.error('Failed to check export status.');
          }
        };

        pollExportStatus(jobId);
      } else {
        setLoadingData(false);
        message.error('Failed to initiate export.');
      }
    } catch (error) {
      setLoadingData(false);
      message.error('Failed to initiate export.');
    }
  };

  return (
    <>
      <HeaderCardDetails />
      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div>
          <div className={styles.container}>
            <div
              className={styles.filterUpperContainer}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: '3px'
              }}>
              <Heading>{cardTitle}</Heading>
              <div style={{ display: 'flex' }}>
                {hasPermission('Create Player') && (
                  <div>
                    <Button
                      style={{
                        backgroundColor: '#004A7F',
                        color: 'white',
                        width: '125px',
                        height: '35px',
                        background: '#004A7F 0% 0% no-repeat padding-box',
                        borderRadius: '3px',
                        opacity: '1',
                        marginRight: '15px'
                      }}
                      className="btnStyle"
                      onClick={showModal}>
                      {lang.label_addplayer}
                    </Button>
                  </div>
                )}
                <div>
                  {loadingData ? (
                    <div style={{ paddingRight: '60px' }}>
                      <Spin indicator={<LoadingOutlined spin />} size="large" />
                    </div>
                  ) : (
                    <div>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="btnStyle"
                        style={{
                          backgroundColor: '#004A7F',
                          color: 'white',
                          width: '125px',
                          height: '35px',
                          background: '#004A7F 0% 0% no-repeat padding-box',
                          borderRadius: '3px',
                          opacity: '1',
                          marginRight: '15px'
                        }}
                        onClick={() => exportData(filteredParams, agentId)}>
                        Export
                      </Button>
                    </div>
                  )}{' '}
                </div>

                <div>
                  {visible ? (
                    <Modal centered title="Add Player" open={visible} footer={null} width={700} closable={false}>
                      <AddPlayerForm handleSubmit={handleOk} handleCancel={handleCancel} getPlayers={getPlayers} />
                    </Modal>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className={styles.filterLowerContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  width: '100%',
                  paddingRight: '14px',
                  flexWrap: 'wrap'
                }}>
                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_time}
                  </SubHeading>
                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-around',
                      alignItems: 'center'
                    }}>
                    <DatePicker
                      //getting input value for start date
                      placeholder="Select Time"
                      showTime={{
                        defaultValue: dayjs('00:00:00', 'HH:mm:ss')
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      onChange={onChange}
                      value={startDate}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    />
                    <span
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        marginLeft: '1%',
                        marginRight: '1%'
                      }}>
                      -
                    </span>
                    <DatePicker
                      //getting input value for start date
                      placeholder="Select Time"
                      showTime={{
                        defaultValue: dayjs('23:59:59', 'HH:mm:ss')
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      onChange={onChange1}
                      value={endDate}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    />
                  </div>
                </div>

                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_status}
                  </SubHeading>
                  <div>
                    <Select
                      defaultValue="All"
                      style={selectStyle}
                      onChange={handleChange}
                      customWidth="200px"
                      extraStyle={{ height: '35px' }}
                      options={[
                        {
                          value: 1,
                          label: 'Active'
                        },
                        {
                          value: '0',
                          label: 'Inactive'
                        },
                        // for online
                        {
                          value: 2,
                          label: 'Online'
                        }
                      ]}
                    />
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_search}
                  </SubHeading>

                  <Input
                    style={inputStyle}
                    name="search"
                    type="text"
                    value={searchtext}
                    onChange={(e) => {
                      setSearchtext(e.target.value);
                    }}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    marginTop: '20px',
                    marginLeft: '10px',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                  }}>
                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1'
                    }}
                    htmlType="submit"
                    className="btnStyle"
                    onClick={() => {
                      setActivepage1(1);
                      setLoading(true);
                    }}>
                    <img src={SearchIcon} alt="search" />
                    {lang.label_search}
                  </Button>

                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      borderRadius: '3px',
                      padding: '6px',
                      border: '1px solid grey',
                      width: '84px'
                    }}
                    className="btnReset"
                    htmlType="reset"
                    onClick={() => {
                      setStartDate('');
                      setEndDate('');
                      setSearchtext(null);
                      setStatus('');
                      getPlayersWithouparams();
                      setTagName('');
                      setagentId('');
                    }}>
                    <img src={ResetIcon} alt="reset" />
                    {lang.label_reset}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <PlayerListTable
            data={playerData}
            loading={loading}
            showModal={showModal}
            handleSubmit={handleOk}
            handleCancel={handleCancel}
            visible={visible}
            getPlayers={getPlayers}
          />
          <br />
          {/* <Pagination
            defaultCurrent={1}
            currentPage={activepage}
            onChange={onChangepage}
            total={total}
            responsive={true}
            pageSize={perpage}
          /> */}
          <PaginationComp paginationConfig={paginationConfig} />
        </div>
      </form>
    </>
  );
};

export default PlayerList;
