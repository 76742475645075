import React, { useEffect, useState } from 'react';
import { Input, Row, Col, Button, Tooltip, Radio, Select, Spin, message } from 'antd';

import GetApi from '../../../../helps/contexts/Api/GetApi';
import PostApi from '../../../../helps/contexts/Api/PostApi';
import Loader from '../../../../components/HelperComponents/Loader';

const { Option } = Select;

const AddDeposite = ({ onClose, paymentMethods, onRefresh }) => {
  const [formData, setFormData] = useState({
    user_id: '',
    userName: '',
    // referenceId: '',
    deposit_transaction_no: '',
    amount: '',
    currency: '',
    payment_method_id: '',
    status: ''
  });

  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingForm, setLoadingForm] = useState(false);

  // Fetching api

  const createTag = async (tagData) => {
    try {
      const path = `/admin/usdt/deposit`;
      const res = await PostApi.PostApiRequest5(path, tagData);
      // console.log(res);

      if (res && res.data.status === true) {
        message.success('Added Deposit Record successfully');
        setFormData({
          user_id: '',
          userName: '',
          deposit_transaction_no: '',
          amount: '',
          currency: '',
          payment_method_id: '',
          status: ''
        });

        onClose();
        onRefresh();
      } else {
        message.error('Failed to add Deposit Record');
      }
    } catch (error) {
      // console.log(error); // Log the entire error object for debugging

      const errorMsg = error?.data?.message;

      if (typeof errorMsg === 'string') {
        message.error(errorMsg);
      } else if (typeof errorMsg === 'object' && errorMsg !== null) {
        if (errorMsg.payment_method_id) {
          message.error(errorMsg.payment_method_id[0] || 'Failed to add Deposit Record');
        }
        if (errorMsg.deposit_transaction_no) {
          message.error(errorMsg.deposit_transaction_no[0] || 'Failed to add Deposit Record');
        }
        if (errorMsg.user_id) {
          message.error(errorMsg.user_id[0] || 'Failed to add Deposit Record');
        }
        if (errorMsg.userName) {
          message.error(errorMsg.userName[0] || 'Failed to add Deposit Record');
        }
        if (errorMsg.referenceId) {
          message.error(errorMsg.referenceId[0] || 'Failed to add Deposit Record');
        }
        if (errorMsg.amount) {
          message.error(errorMsg.amount[0] || 'Failed to add Deposit Record');
        }
        if (errorMsg.currency) {
          message.error(errorMsg.currency[0] || 'Failed to add Deposit Record');
        }
        if (errorMsg.status) {
          message.error(errorMsg.status[0] || 'Failed to add Deposit Record');
        }
      } else {
        message.error('Failed to add Deposit Record');
      }
    }
  };

  const fetchUserData = async (page, searchTerm) => {
    setLoading(true);
    try {
      const res = await GetApi.sendApiRequest(`/admin/user/search?page=${page}&username=${searchTerm}`);
      const newUsers = res?.data?.data?.map((v) => ({
        id: v?.player?.user_id,
        userName: v?.user_name,
        currency: v.player?.wallet?.currency_name,
        value: v?.user_name,
        label: v?.user_name
      }));
      setUserList((prev) => (page === 1 ? newUsers : [...prev, ...newUsers]));
      setHasMore(res?.next_page_url !== null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setHasMore(false);
    }
  };

  useEffect(() => {
    fetchUserData(page, searchTerm);
  }, [page, searchTerm]);

  const handleScroll = (event) => {
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (bottom && hasMore && !loading) {
      setPage((prev) => prev + 1);
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    setPage(1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChangeUserName = (value) => {
    const selectedUser = userList.find((user) => user.value === value);
    if (selectedUser) {
      setFormData({
        ...formData,
        user_id: selectedUser.id,
        userName: selectedUser.userName,
        currency: selectedUser.currency
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingForm(true);

    try {
      await createTag(formData);
    } catch (error) {
      message.error('Failed to add Deposit Record');
    } finally {
      setLoadingForm(false);
    }
  };
  const handelClose = () => {
    setFormData({
      user_id: '',
      userName: '',
      // referenceId: '',
      deposit_transaction_no: '',
      amount: '',
      currency: '',
      payment_method_id: '',
      status: ''
    });
    onClose();
  };

  // const paymentMethod = paymentMethods?.filter((el, i) => el.account_name == 'USDT');
  return (
    <div>
      <div
        style={{
          width: '100%',
          height: '100%',
          justifyContent: 'space-evenly'
        }}>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'space-around',
              flexDirection: 'row'
            }}>
            <div style={{ width: '50%', marginRight: '20px' }}>
              <Col span={24}>
                <label>User Name</label>
                <span style={{ color: 'red', marginRight: '2px' }}>*</span>
                <Select
                  showSearch
                  placeholder="Search and select a user"
                  allowClear
                  value={formData.userName}
                  style={{ width: '100%', borderRadius: '3px', margin: '5px 0px' }}
                  onSearch={handleSearch}
                  onPopupScroll={handleScroll}
                  onChange={handleSelectChangeUserName}
                  filterOption={false}
                  notFoundContent={loading ? <Spin size="small" /> : null}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      {loading && (
                        <div style={{ padding: '8px', textAlign: 'center' }}>
                          <Spin size="small" />
                        </div>
                      )}
                    </div>
                  )}>
                  {userList?.map((user) => (
                    <Option key={user.value} value={user.value}>
                      {user.label}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={24}>
                <label>User ID</label>
                <span style={{ color: 'red', marginRight: '2px' }}>*</span>
                <Input
                  required
                  name="id"
                  value={formData.user_id}
                  readOnly
                  style={{ width: '100%', borderRadius: '3px', margin: '5px 0px' }}
                />
              </Col>
              <Col span={24}>
                <label>Amount</label>
                <span style={{ color: 'red', marginRight: '2px' }}>*</span>
                <Input
                  name="amount"
                  type="number"
                  required
                  value={formData.amount}
                  onChange={handleInputChange}
                  style={{ width: '100%', borderRadius: '3px', margin: '5px 0px' }}
                />
              </Col>
              <Col span={24}>
                <label>Transfer No</label>
                <span style={{ color: 'red', marginRight: '2px' }}>*</span>
                <Input
                  name="deposit_transaction_no"
                  required
                  value={formData.deposit_transaction_no}
                  onChange={handleInputChange}
                  style={{ width: '100%', borderRadius: '3px', margin: '5px 0px' }}
                />
              </Col>
            </div>
            <div style={{ width: '50%' }}>
              <Col span={24}>
                <label>Currency</label>
                <Input
                  name="currency"
                  value={formData.currency}
                  readOnly
                  style={{ width: '100%', borderRadius: '3px', margin: '5px 0px' }}
                />
              </Col>
              <Col span={24}>
                <label>Company Payment Method</label>
                <span style={{ color: 'red', marginRight: '2px' }}>*</span>
                <Select
                  name="payment_method_id"
                  required
                  value={formData.payment_method_id}
                  onChange={(value) => handleSelectChange('payment_method_id', value)}
                  style={{ width: '100%', borderRadius: '3px', margin: '5px 0px' }}>
                  {paymentMethods &&
                    paymentMethods.map((item, index) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.account_name}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
              <Col span={24}>
                <label>Status</label>
                <span style={{ color: 'red', marginRight: '2px' }}>*</span>
                <Select
                  name="status"
                  value={formData.status}
                  required
                  onChange={(value) => handleSelectChange('status', value)}
                  style={{ width: '100%', borderRadius: '3px', margin: '5px 0px' }}>
                  <Option value="1">Approved</Option>
                  <Option value="2">Rejected</Option>
                  <Option value="3">Pending</Option>
                </Select>
              </Col>
              {/* <Col span={24}>
                <label>Reference ID</label>
                <Input
                  name="referenceId"
                  value={formData.referenceId}
                  onChange={handleInputChange}
                  style={{ width: '100%', borderRadius: '3px', margin: '5px 0px' }}
                />
              </Col> */}
            </div>
          </div>
          <br />
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
            {!loadingForm ? (
              <Button
                style={{
                  borderRadius: '3px',
                  backgroundColor: '#004A7F',
                  color: 'white'
                }}
                htmlType="submit">
                Submit
              </Button>
            ) : (
              <div style={{ paddingLeft: '30px', width: '100%' }}>
                <Loader />
              </div>
            )}
            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F'
              }}
              onClick={handelClose}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddDeposite;
