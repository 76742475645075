import { useEffect, useState } from 'react';
import styles from './QuickFilter.module.css';
import { Col, Card, Button, Spin, DatePicker, message } from 'antd';
import { getFilterdData, getFilterdData2 } from '../../../helps/contexts/Api/api';
import TotalRecord from '../TotalRecord/TotalRecord';
import { TfiReload } from 'react-icons/tfi';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import timezone from 'dayjs/plugin/timezone';

const filterOptions = [
  { name: 'Today', url: 'today' },
  { name: 'Yesterday', url: 'yesterday' },
  { name: 'This Week', url: 'this_week' },
  { name: 'Last Week', url: 'last_week' },
  { name: 'This Month', url: 'this_month' },
  { name: 'Last Month', url: 'last_month' }
];

const QuickFilter = () => {
  const [data, setData] = useState();
  const [activeFilter, setActiveFilter] = useState('today');
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    from_date: null,
    to_date: null,
    dates: []
  });

  dayjs.extend(utc);

  // Get Data API
  const getData = async () => {
    setLoader(true);
    const token = localStorage.getItem('token');
    const apiRes = await getFilterdData(token, activeFilter);

    if (apiRes?.data?.status) {
      setLoader(false);
      setData(apiRes?.data);
    } else {
    }
  };

  const formatDate = (dayjsObj) => {
    return dayjs(dayjsObj).local().format('YYYY-MM-DD HH:mm:ss');
  };

  // Call API to get Data
  useEffect(() => {
    getData();
  }, [activeFilter]);

  // add comma
  const addCommasToNumber = (val) => {
    return val?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // loading component
  const Loading = () => {
    return (
      loader && (
        <div className={styles.loadingWrapper}>
          <Spin color="red" />
        </div>
      )
    );
  };

  const { RangePicker } = DatePicker;

  const handleDateChange = async (dates) => {
    setLoader(true);
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;

      const token = localStorage.getItem('token');
      const payload = {
        start_date: formatDate(startDate),
        end_date: formatDate(endDate)
      };

      setFormData((prevData) => ({
        ...prevData,
        from_date: payload.start_date,
        to_date: payload.end_date,
        dates
      }));

      // Reset the active filter since a custom date range is selected
      setActiveFilter(null);

      try {
        const response = await getFilterdData2(token, payload);
        setData(response.data);
        message.success('Data fetched successfully');
      } catch (error) {
        message.error('Failed to fetch data');
      } finally {
        setLoader(false);
      }
    } else {
      // If dates are cleared, also clear the formData and activeFilter
      setFormData({
        from_date: null,
        to_date: null,
        dates: []
      });
      setActiveFilter('today'); // Or set to a default filter
      setLoader(false);
    }
  };



  dayjs.extend(utc);
  dayjs.extend(timezone);

  return (
    <Col span={16}>
      <Card style={{ minHeight: '300px' }}>
        <Col className={styles.filterOverlay}>
          <div className={styles.quickFilterTitle}>Quick Filter</div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div className={styles.filterButtonsWrapper}>
              {filterOptions.map((item) => (
                <Button
                  key={item.url}
                  className={activeFilter === item.url ? styles.activeFilter : ''}
                  onClick={() => setActiveFilter(item.url)}>
                  {item.name}
                </Button>
              ))}
            <RangePicker
                style={{
                  width: '200px',
                  height: '35px'
                }}
                value={formData.dates}
                showTime={{ defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')] }}
                onChange={handleDateChange}
                onOk={handleDateChange}
              />
            </div>
            <TfiReload style={{ fontSize: '20px', lineHeight: '23px', color: '#003049' }} onClick={getData} />
          </div>
        </Col>
        {!loader && <TotalRecord data={data} addCommasToNumber={addCommasToNumber} />}
        <Loading />
      </Card>
    </Col>
  );
};

export default QuickFilter;
