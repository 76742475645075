import React from 'react';
import { Row, Col, Card, Input, Button, Radio } from 'antd';

const CommissionSetting = ({ formData, handleInputChange, nextTab, prevTab }) => {
  return (
    <div style={{ marginRight: '40px' }}>
      <Row gutter={16} style={{ marginTop: '20px' }}>
        {/* Settle Cycle */}
        <Col xs={24} sm={12} lg={12}>
          <Card className="custom-card" style={{ marginBottom: '20px' }} title="Settle Cycle">
            <Radio.Group onChange={handleInputChange} value={formData.settleCycle} name="settleCycle">
              {['Weekly (Mon 00:00 ~ Sun 23:59 GMT+8)', 'Monthly'].map((cycle) => (
                <Radio
                  key={cycle}
                  value={cycle}
                  style={{
                    marginTop: '15px',
                    color: '#BABABA',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 500
                  }}>
                  {cycle}
                </Radio>
              ))}
            </Radio.Group>
          </Card>
        </Col>

        {/* Active Players Requirements */}
        <Col xs={24} sm={12} lg={12}>
          <Card className="custom-card" style={{ marginBottom: '20px' }} title="Active Players Requirements">
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <label style={{ fontWeight: 500 }}>Min Valid Bets</label>
                <Input
                  name="minValidBets"
                  value={formData.minValidBets || ''}
                  onChange={handleInputChange}
                  placeholder="Min Valid Bets"
                />
              </Col>
              <Col xs={24} sm={12}>
                <label style={{ fontWeight: 500 }}>Min Deposit</label>
                <Input
                  name="minDeposit"
                  value={formData.minDeposit || ''}
                  onChange={handleInputChange}
                  placeholder="Min Deposit"
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '20px' }}>
        {/* Settle Requirements */}
        <Col xs={24} sm={12} lg={12}>
          <Card className="custom-card" style={{ marginBottom: '20px' }} title="Settle Requirements">
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <label style={{ fontWeight: 500 }}>Min Total Revenue</label>
                <Input
                  name="minTotalRevenue"
                  value={formData.minTotalRevenue || ''}
                  onChange={handleInputChange}
                  placeholder="Min Total Revenue"
                />
              </Col>
              <Col xs={24} sm={12}>
                <label style={{ fontWeight: 500 }}>Active Players</label>
                <Input
                  name="activePlayers"
                  value={formData.activePlayers || ''}
                  onChange={handleInputChange}
                  placeholder="Active Players"
                />
              </Col>
            </Row>
          </Card>
        </Col>

        {/* Commission & Credit Line */}
        <Col xs={24} sm={12} lg={6}>
          <Card className="custom-card" style={{ marginBottom: '20px' }} title="Commission">
            <label style={{ fontWeight: 500 }}>Max.(100)%</label>
            <Input
              name="commission"
              value={formData.commission || ''}
              onChange={handleInputChange}
              placeholder="Commission %"
            />
          </Card>
        </Col>

        <Col xs={24} sm={12} lg={6}>
          <Card className="custom-card" style={{ marginBottom: '20px' }} title="Credit Line">
            <label style={{ fontWeight: 500 }}>Max Credit Line</label>
            <Input
              name="creditLine"
              value={formData.creditLine || ''}
              onChange={handleInputChange}
              placeholder="Credit Line"
            />
          </Card>
        </Col>
      </Row>

      {/* Navigation Buttons */}
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <Button onClick={prevTab} style={{ marginRight: '10px', width: '80px', height: '35px', borderRadius: '3px' }}>
          Previous
        </Button>
        <Button
          type="primary"
          onClick={nextTab}
          style={{
            backgroundColor: '#004A7F',
            color: 'white',
            width: '70px',
            height: '35px',
            borderRadius: '3px',
            marginRight: '15px'
          }}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default CommissionSetting;
