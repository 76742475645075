import {
  Input,
  Button,
  Row,
  Col,
  Switch,
  Select,
  DatePicker,
  message,
} from "antd";
import LangContext from "../../../helps/contexts/lang-context";
import { useContext, useState, useEffect } from "react";
import PostApi from "../../../helps/contexts/Api/PostApi";
import GetApi from "../../../helps/contexts/Api/GetApi";
import { useNavigate } from "react-router-dom";

const AddPlayerForm = ({ handleCancel, getPlayers }) => {
  const nav = useNavigate();
  // form states
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [remark, setRemark] = useState("");
  const [gender, setGender] = useState(1);
  const [birthday, setBirthday] = useState("2023-05-13");
  const[currencyList,setCurrencyList]=useState([])
  const [type, setType] = useState(1);
  const [status, setStatus] = useState(1);
  const [allowbetting, setAllowBetting] = useState(0);
  const [withdrawl, setWithdrawl] = useState(0);
  const [dateparam, setDateparam] = useState("");
  const [countrycode, setcountrycode] = useState();
  const [currency, setcurrency] = useState("");
  const [codedata, setcodedata] = useState([]);
  const [selectValue, setSelectValue] = useState(null);
  const { Option } = Select;
  const { TextArea } = Input;
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const dateFormat = "";

  useEffect(() => {
    fetchCountryCode();
  }, []);
  
  const fetchCountryCode = async () => {
    const path = "/user/phone/codes";
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path, {}, params);

    if (res && res.length) {
      const finalArray = res.map((cc, index) => ({
        key: index,
        code: cc.code,
      }));
      setcodedata(finalArray);
    } else {
      setcodedata([]);
    }
  };
  const oncodechange = (value) => {
    setcountrycode(value);
  };
  //remark
  const onChangeRemark = (e) => {
    setRemark(e.target.value);
  };

  //date picker
  const onChange = (value, dateString) => {
    setBirthday(dateString);
    setDateparam(dateString);
  };

  //switch allowbetting
  const onChangeSwitch1 = (checked) => {
    setAllowBetting(checked);
    if (checked === true) {
      setAllowBetting(1);
    } else if (checked === false) {
      setAllowBetting(0);
    }
  };
  //switch withdrawl
  const onChangeSwitch2 = (checked) => {
    setWithdrawl(checked);

    if (checked === true) {
      setWithdrawl(1);
    } else if (checked === false) {
      setWithdrawl(0);
    }
  };
  //gender dropdown
  const handleChange = (value) => {
    setGender(value);
  };

  //type dropdown
  const handleChange1 = (value) => {
    setType(value);
  };

  const handleCurrency=(value)=>{
    setcurrency(value)
      }

  //status dropdown
  const handleChangeStatus = (value) => {
    setStatus(value);
  };
  //username input change and validation
  const onInputChange = (e) => {
    const { value } = e.target;

    const re = /^[a-z0-9_-]*$/;
    if (value === "" || re.test(value)) {
      setUserName(value);
    } else {
      message.error(lang.label_uppercaseonly);
    }
  };
  useEffect(() => {
    fetchCurrency();
  }, []);
  // Fetching api

  const fetchCurrency = async () => {
    const path = "/player/currencies";
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path);
   console.log(res);
   const curr = Object.values(res);
   if (curr && curr.length) {
     const finalArray = curr.map((item, index) => ({
       key: index,
       curren: item
     }));
    setCurrencyList(finalArray);
   } else {
    setCurrencyList([]);
   }
  };




  
  //form submit to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      // console.log(countrycode);

      const finalphone = countrycode + phone;

      // console.log(finalphone);
      const path = `/admin/player/store`;
      // sending request
      const res = await PostApi.PostApiRequest(path, {
        user_name: username,
        password: password,
        name: name,
        phone: countrycode ? finalphone : phone,
        gender: gender,
        birthday: birthday,
        type: type,
        active: status,
        remark: remark,
        allow_betting: allowbetting,
        allow_deposit: withdrawl,
        currency:currency
      });
      //if success
      if (res && res.status === 200) {
        message.success(lang.label_playercreatsuccess);
        setcurrency()
        setName("");
        setUserName("");
        setPhone("");
        setPassword("")
        setBirthday("");
        setGender("");
        setType("");
        setStatus("");
        setAllowBetting(false);
        setWithdrawl(false);
        setRemark("");
        setcountrycode("")
        handleCancel();
        getPlayers();
      } else if (!res) {
        message.error(lang.label_playercreatfail);
      }
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav("/login");
      }
      console.log("error is", e.data.message);
    }
  };

  return (
    <div
      style={{
        width: "97%",
        height: "85%",
        justifyContent: "space-evenly",
      }}
    >
      <p
        style={{
          color: "#A5A5A5",
          fontSize: "14px",
          marginTop: "-1%",
          marginBottom: "3%",
        }}
      >
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: "3%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            marginRight: "-6%",
          }}
        >
          <Row>
            <Col span={12}>
              <label style={{ paddingBottom: "3px" }}>{lang.label_name}</label>
              <br />

              <Input
                defaultValue="Player Name"
                style={{
                  width: "85%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                type="text"
                placeholder="Player Name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col span={12}>
              <label>
                {lang.label_username}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />

              <Input
                defaultValue="Player Username"
                type="text"
                placeholder="Player Name"
                name="username"
                required
                value={username}
                onChange={onInputChange}
                style={{
                  width: "85%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                minLength={8}
                maxLength={18}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>
                {lang.label_password}
                <span style={{ color: "red" }}>*</span>
              </label>{" "}
              <br />
              <Input.Password
                style={{
                  width: "186%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                required
                type="password"
                placeholder="Enter Password"
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                maxLength={18}
                minLength={8}
              />
            </Col>
          </Row>
          <Row>
          <Col span={3}>
              <label>code </label>
              <br />
              <Select
                style={{ width: "90%", marginTop: "5px" }}
                onChange={oncodechange}
                defaultValue={""}
                value={countrycode}
              >
                <Option>{}</Option>
                {codedata &&
                  codedata.map((cc, index) => {
                    return (
                      <Option key={index} value={cc.code}>
                        {cc.code}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
            <Col span={9}>
              <label>{lang.label_phone} </label>
              <br />

              <Input
                defaultValue="5123455633"
                style={{
                  width: "215%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                type="text"
                placeholder="Enter Phone Number"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>{lang.label_gender}</label>
              <br />

              <Select
                defaultValue="1"
                value={gender}
                style={{
                  width: "85%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                onChange={handleChange}
                options={[
                  {
                    value: 1,
                    label: "Male",
                  },
                  {
                    value: 2,
                    label: "Female",
                  },
                  {
                    value: 3,
                    label: "Other",
                  },
                ]}
              />
            </Col>
            <Col span={12}>
              <label>{lang.label_bday}</label>
              <br />

              <DatePicker
                onChange={onChange}
                style={{
                  height: "30px",
                  width: "85%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>{lang.label_type}</label> <br />
              <Select
                defaultValue="1"
                value={type}
                style={{
                  width: "186%",
                  borderRadius: "3px",
                  color: "green",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                onChange={handleChange1}
                options={[
                  {
                    value: 1,
                    label: "Normal Account",
                  },
                  {
                    value: 2,
                    label: "Test Account",
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <label>{lang.label_currency}</label>
              <span style={{ color: "red" }}>*</span>
              <br />
              <Select
                    style={{
                      width: "93%",
                      borderRadius: "3px",
                      marginBottom: "5px",
                      marginTop: "5px",
                    }}
                    value={selectValue}
                    
                    onChange={(value) => {
                      handleCurrency(value);
                      setSelectValue(value); // Update the selectValue state
                    }}
                  >
                    <Option key={1000} value=""></Option>
                    {currencyList &&
                      currencyList.map((item, index) => {
                        return (
                          <Option key={index} value={index+1}>
                            {item.curren}
                          </Option>
                        );
                      })}
                  </Select>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>{lang.label_status}</label> <br />
              <Select
                defaultValue="1"
                value={status}
                style={{
                  width: "186%",
                  borderRadius: "3px",
                  color: "green",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                onChange={handleChangeStatus}
                options={[
                  {
                    value: 1,
                    label: "Active",
                  },
                  {
                    value: 0,
                    label: "Inactive",
                  },
                ]}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <label>{lang.label_allowbetting} </label>

              <Switch
                style={{ marginLeft: "10px" }}
                defaultChecked
                onChange={onChangeSwitch1}
                checked={allowbetting}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <label>{lang.label_allowdeposit}</label>

              <Switch
                style={{ marginLeft: "10px" }}
                defaultChecked
                onChange={onChangeSwitch2}
                checked={withdrawl}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={12}>
              <label>{lang.label_remark}</label>
              <br />

              <>
                <TextArea
                  showCount
                  maxLength={100}
                  onChange={onChangeRemark}
                  style={{
                    width: "186%",
                    height: "100px",
                    borderRadius: "3px",
                    marginBottom: "5px",
                    marginTop: "5px",
                  }}
                  value={remark}
                />
              </>
            </Col>
          </Row>

          <br />
          
          <br />
          <br />

          <div style={{}}>
            {" "}
            <Button
              style={{
                borderRadius: "3px",
                backgroundColor: "#004A7F",
                color: "white",
                position: "absolute",
                right: "20%",
                top: "93%",
              }}
              htmlType="submit"
            >
              {lang.label_submit}
            </Button>
            <Button
              style={{
                borderRadius: "3px",
                color: "#004A7F",
                position: "absolute",
                right: "8%",
                top: "93%",
              }}
              onClick={() => {
                setcurrency("")
                setcountrycode("")
                setName("");
                setPassword("")
                setUserName("");
                setPhone("");
                setBirthday("");
                setGender("");
                setType("");
                setStatus("");
                setAllowBetting(false);
                setWithdrawl(false);
                setRemark("");
                handleCancel();
              }}
            >
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddPlayerForm;
