import React, { useContext, useState } from 'react';
import HeaderCardDetails from '../../components/HeaderDetailCard';

import { Card, Row, Col, Button, Input, Pagination, Modal, message, DatePicker } from 'antd';
import LangContext from '../../helps/contexts/lang-context';
import Spacer from '../../components/HelperComponents/Spacer';
import styles from './ExportList.module.css';
import Heading from '../../components/HelperComponents/Heading';
import useCheckPermission from '../../helps/hooks/useCheckPermission';
import SubHeading from '../../components/HelperComponents/SubHeading';
import dayjs from 'dayjs';
import PaginationComp from '../../components/HelperComponents/PaginationComp';
import ExportListTable from './ExportListTable';

const ExportList = () => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const inputStyle = {
    width: '250px',
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };

  const [searchtext, setSearchtext] = useState('');
  const { hasPermission } = useCheckPermission();
  const datePickerStyle = {
    width: '185px',
    height: '35px',
    background: ' #FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // setActivepage1(1);
    // getPlayers();
  };
  return (
    <div>
      <HeaderCardDetails />
      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div>
          <div className={styles.container}>
            <div
              className={styles.filterUpperContainer}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: '3px'
              }}>
              <Heading>Filters</Heading>
              <div style={{ display: 'flex' }}>
                {/* {hasPermission('Create Player') && (
                  <div>
                    <Button
                      style={{
                        backgroundColor: '#004A7F',
                        color: 'white',
                        width: '125px',
                        height: '35px',
                        background: '#004A7F 0% 0% no-repeat padding-box',
                        borderRadius: '3px',
                        opacity: '1',
                        marginRight: '15px'
                      }}
                      className="btnStyle"
                    //   onClick={showModal}
                    >
                      {lang.label_addplayer}
                    </Button>
                  </div>
                )} */}
                {/* <div> */}
                {/* {
                  loadingData ? 
                  (
                    <div style={{ paddingRight: '60px' }}>
                      <Spin indicator={<LoadingOutlined spin />} size="large" />
                    </div>
                  ) : ( */}
                {/* <div>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="btnStyle"
                        style={{
                          backgroundColor: '#004A7F',
                          color: 'white',
                          width: '125px',
                          height: '35px',
                          background: '#004A7F 0% 0% no-repeat padding-box',
                          borderRadius: '3px',
                          opacity: '1',
                          marginRight: '15px'
                        }}
                        // onClick={() => exportData(filteredParams, agentId)}
                        >
                        Export
                      </Button>
                    </div> */}
                {/* )}{' '} */}
                {/* </div> */}

                <div>
                  {/* {visible ? (
                    <Modal centered title="Add Player" open={visible} footer={null} width={700} closable={false}>
                      <AddPlayerForm handleSubmit={handleOk} handleCancel={handleCancel} getPlayers={getPlayers} />
                    </Modal>
                  ) : (
                    ''
                  )} */}
                </div>
              </div>
            </div>
            <div className={styles.filterLowerContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  width: '100%',
                  paddingRight: '14px',
                  flexWrap: 'wrap'
                }}>
                <div className={styles.flexStyle}>
                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-around',
                      alignItems: 'center'
                    }}>
                    {/* <DatePicker
                      //getting input value for start date
                      placeholder="Select Time"
                      showTime={{
                        defaultValue: dayjs('00:00:00', 'HH:mm:ss')
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                    //   onChange={onChange}
                      value={startDate}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    /> */}
                  </div>
                </div>

                <div className={styles.flexStyle}>
                  <div>
                    {/* <Select
                      defaultValue="All"
                      style={selectStyle}
                      onChange={handleChange}
                      customWidth="200px"
                      extraStyle={{ height: '35px' }}
                      options={[
                        {
                          value: 1,
                          label: 'Active'
                        },
                        {
                          value: '0',
                          label: 'Inactive'
                        },
                        // for online
                        {
                          value: 2,
                          label: 'Online'
                        }
                      ]}
                    /> */}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    marginTop: '20px',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                  <input
                    style={{ ...inputStyle, flex: '1 1 200px', minWidth: '200px',       padding: '10px',  }}
                    type="text"
                    id="uniqueId"
                    placeholder="Unique ID"
                    
                    // value={searchtext}
                    onChange={(e) => setSearchtext(e.target.value)}
                  />
                  <input
                    style={{ ...inputStyle, flex: '1 1 200px', minWidth: '200px' ,     padding: '10px',  }}
                    type="text"
                    id="userName"
                    placeholder="User Name"
                    // value={searchtext}
                    onChange={(e) => setSearchtext(e.target.value)}
                  />
                  <input
                    style={{ ...inputStyle, flex: '1 1 200px', minWidth: '200px',    padding: '10px',  }}
                    type="text"
                    id="name"
                    placeholder="Name"
                    // value={searchtext}
                    onChange={(e) => setSearchtext(e.target.value)}
                  />
                  <input
                    style={{ ...inputStyle, flex: '1 1 200px', minWidth: '200px',    padding: '10px',  }}
                    type="text"
                    id="all"
                    placeholder="All"
                    // value={searchtext}
                    onChange={(e) => setSearchtext(e.target.value)}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    marginTop: '20px',
                    marginLeft: '10px',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                  }}>
                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1'
                    }}
                    htmlType="submit"
                    className="btnStyle"
                    // onClick={() => {
                    //   setActivepage1(1);
                    //   setLoading(true);
                    // }}
                  >
                    {/* <img src={SearchIcon} alt="search" /> */}
                    Filter
                  </Button>

                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      borderRadius: '3px',
                      padding: '6px',
                      border: '1px solid grey',
                      width: '84px'
                    }}
                    className="btnReset"
                    htmlType="reset"
                    // onClick={() => {
                    //   setStartDate('');
                    //   setEndDate('');
                    //   setSearchtext(null);
                    //   setStatus('');
                    //   getPlayersWithouparams();
                    //   setTagName('');
                    //   setagentId('');
                    // }}
                  >
                    {/* <img src={ResetIcon} alt="reset" /> */}
                    Refresh
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <ExportListTable />
          {/* <PlayerListTable
            data={playerData}
            loading={loading}
            showModal={showModal}
            handleSubmit={handleOk}
            handleCancel={handleCancel}
            // visible={visible}
            getPlayers={getPlayers}
          /> */}
          <br />
          {/* <Pagination
            defaultCurrent={1}
            currentPage={activepage}
            onChange={onChangepage}
            total={total}
            responsive={true}
            pageSize={perpage}
          /> */}
          <PaginationComp
          //   paginationConfig={paginationConfig}
          />
        </div>
      </form>
    </div>
  );
};

export default ExportList;
