import React from 'react'
import Edit from '../../../images/Edit.png';
import Delete from '../../../images/Delete.png';
import { Button } from 'antd'
const Action = ({handleOpenModal , item , deleteState = true}) => {
  return (
   <div style={{
    display:"flex",
    gap:"10px"
   }}>
   <div className="custom-div" onClick={()=>handleOpenModal("update",item)} >
    <Button
  
      type="text"
      icon={<img style={{
        height:'13px',
        width:"13px" ,
        objectFit:"contain" ,
      }} src={Edit} alt="Edit Icon" />}
      className="custom-button"
    />
    </div>

      {deleteState && <div className="custom-div" onClick={() => handleOpenModal("delete", item)}><Button
        type="text"
        icon={<img style={{
          height: '13px',
          width: "13px",
          objectFit: "contain",
        }} src={Delete} alt="Delete Icon" />}
        className="custom-button"
      />
      </div>}


        {/* <div className="divSize">

     <Button
     onClick={onClick}
      type="text"
      icon={<img  style={{
        height:'100%',
        width:"100%" ,
        objectFit:"contain" ,
      }} src={activeUp} alt="Delete Icon" />}
      className="custom-button"
    />
  </div>
  <div className="divSize">

<Button
 type="text"
 onClick={onClick}

 icon={<img  style={{
    height:'100%',
    width:"100%" ,
   objectFit:"contain" ,
 }} src={activeDown} alt="Delete Icon" />}
 className="custom-button"
/>
</div>
<div className="divSize" >

<Button
     onClick={onClick}

 type="text"
 icon={<img  style={{
   height:'100%',
   width:"100%" ,
   objectFit:"contain" ,
 }} src={pressDown} alt="Delete Icon" />}
 className="custom-button"
/>
</div> */}
  </div>
  )
}

export default Action