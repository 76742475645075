import React, { useState } from 'react'
import TabNameSection from '../TabNameSection'
import TableComp from '../../../../components/HelperComponents/TableComp'
import Loader from '../../../../components/HelperComponents/Loader'
import Spacer from '../../../../components/HelperComponents/Spacer'
import Action from '../../GameCategories/Action'

const Platform1 = ({name ,handleInputChange ,formData}) => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
  const [isFilterActive, setIsFilterActive] = useState(true)
  const [perPage, setPerPage] = useState(0)
  const [totalLength, setTotalLength] = useState(0)
  const [isPageChange, setIsPageChange] = useState(true)
  const [transactionTableData ,setTransactionTableData] = useState([])

    const data =[  {
        key: '1',
        sort: 0,
platform:"name",
        status: '1',
        actions:<Action />,
      },
      {
        key: '2',
        sort: 1,
platform:"name 2",
        status: '2',
        actions:<Action/>,
      },{
        key: '3',
        sort: 2,
platform:"name 3",
        status: '3',
        actions:<Action/>,
      },]
    const columns = [
      
        {
          title: 'Sort',
          dataIndex: 'sort',
          key: 'sort',
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
          },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
        },
    
        {
          title: 'Actions',
          dataIndex: 'actions',
          key: 'actions',
        },
       
      ];
    const onChangePage = (page) => {
        if (formData.search) {
          setIsFilterActive(true)
        }
        setCurrentPage(page);
        setIsPageChange(true)
      };
  return (
    <>
    <TabNameSection name={name} handleInputChange={handleInputChange} /> 
    <Spacer height="30px" />
          {loading ? <Loader /> : <TableComp transactionTableData={transactionTableData}
        setTransactionTableData={setTransactionTableData} dataSource={data} columns={columns} totalLength={totalLength} perPage={perPage} currentPage={currentPage} onChangePage={onChangePage} />}

    </>
  )
}

export default Platform1