import React, { useContext, useState } from 'react';
import { Input, Button, Row, Col, Select, message } from 'antd';
import LangContext from '../../helps/contexts/lang-context';
import PostApi from '../../helps/contexts/Api/PostApi';
import { constants } from '../../helps/contexts/Api/constants';
import axios from 'axios';

const EditPromotionCategoryForm = ({ rowdata, onCancel }) => {
  const ctx = useContext(LangContext);
  const token1 = localStorage.getItem('token');
  const lang = ctx.lang;
  const { id } = rowdata;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoryName, setCategoryName] = useState(rowdata && rowdata.categoryName ? rowdata.categoryName : '');
  const [status, setStatus] = useState(rowdata && rowdata.status === 1 ? 1 : 0);
  const [formErrors, setFormErrors] = useState({
    categoryName: false,
    status: false
  });

  const validateForm = () => {
    const errors = {
      categoryName: !categoryName,
      status: status === null
    };

    setFormErrors(errors);

    return Object.values(errors).every((error) => !error);
  };

  const onChangeStatus = (value) => {
    setStatus(value);
    setFormErrors({ ...formErrors, status: false });
  };
  //form submit to backend
  const onSubmit = async (e) => {
    if (isSubmitting) {
      return;
    }

    const payload = {
      en: categoryName,
      is_active: status
    };

    const path = `/promotion_categories/${id}/update`;
    const url = `${constants.BASE_URL1}` + `${path}`;

    try {
      e.preventDefault();

      setIsSubmitting(true);

      if (!validateForm()) {
        // message.error(lang.label_formvalidationerror);
        return;
      }

      const response = await axios.put(url, payload, {
        headers: {
          Authorization: `Bearer ${token1}`,
          'Content-Type': 'application/json'
        }
      });

      //if success
      if (response?.status === 200) {
        message.success(lang.label_editpromocategorysucess);

        onCancel();
      }
    } catch (e) {
      if (e?.status === 422) {
        message.error(lang.label_editpromocategoryfail);
        Object.values(e.data.message).map((errors) => message.error(errors));
      }
    } finally {
      // Reset the submitting state after API call is complete
      setIsSubmitting(false);
    }
  };

  return (
    <div
      style={{
        width: '97%',
        height: '85%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: '3%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '-6%'
          }}>
          <Row>
            <Col span={22}>
              <label style={{ paddingBottom: '3px' }}>
                {lang.label_category}
                {lang.label_en}
              </label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <Input
                required
                style={{
                  width: '100%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                onChange={(e) => setCategoryName(e.target.value)}
                name="categoryName"
                value={categoryName}
              />
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <label>{lang.label_status}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <Select
                // defaultValue={rowdata && rowdata.status}
                value={status}
                onChange={onChangeStatus}
                style={{
                  width: '184%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                options={[
                  {
                    key: 1,
                    value: 1,
                    label: 'Visible'
                  },
                  {
                    key: 0,
                    value: 0,
                    label: 'Hidden'
                  }
                ]}
              />
            </Col>
          </Row>

          <br />
          <br />
          <br />

          <div style={{}}>
            {' '}
            <Button
              style={{
                borderRadius: '3px',
                backgroundColor: '#004A7F',
                color: 'white',
                position: 'absolute',
                right: '25%',
                top: '80%'
              }}
              htmlType="submit">
              {lang.label_submit}
            </Button>
            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                position: 'absolute',
                right: '8%',
                top: '80%'
              }}
              onClick={onCancel}>
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditPromotionCategoryForm;
