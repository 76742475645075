import { Card, Row, Col, DatePicker, Button, Input, Select, Pagination } from 'antd';
import styles from './CompanyBanks.module.css';
import { useContext, useState, useEffect } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import CompanyBanksHistoryTable from './CompanyBanksHistoryTable';
import GetApi from '../../../helps/contexts/Api/GetApi';
import { CalendarOutlined } from '@ant-design/icons';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import { useNavigate } from 'react-router-dom';
import { getBanks } from '../../../helps/contexts/Api/api';
import SelectBox from '../../../components/HelperComponents/SelectBox';
import { convertLocalTimeToUTC } from '../../../helps/HelperFunctions';
import { TabContext } from '../../../helps/contexts/TabContext';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import Heading from '../../../components/HelperComponents/Heading';
import SearchIcon from "../../../images/Search.png";
import ResetIcon from "../../../images/Reset.png"
import dayjs from 'dayjs';

const CompanyBanksHistory = () => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const nav = useNavigate();

  //styles
  const datePickerStyle = {
    width: '150px',
    height: '35px',
    background: ' #FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const iconStyle = { color: '#004A7F' };
  const inputStyle = {
    width: '150px',
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const selectStyle = {
    width: '200px',
    height: '35px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '3px'
  };

  //states
  const [banks, setBanks] = useState([]);
  const [detailBankArray, setDetailBanksArrays] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [bankname, setbankname] = useState('Select Option');
  const [startTimeParam, setStartTimeParam] = useState('');
  const [endTimeParam, setEndTimeParam] = useState('');
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(10);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [Data, setData] = useState([]);
  const [startamount, setstartamount] = useState();
  const [endamount, setendamount] = useState();
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const { path } = useContext(TabContext);
  const { Option } = Select;
  const Navigate = useNavigate();

  useEffect(() => {
    if (path === '/transaction-management/payment-method-history') {
      fetchCompanyBankshistory();
    }
  }, [path]);

  //date picker startdate
  const onChange = (value, dateString) => {
    setStartDate(value);
    setStartTimeParam(dateString);
  };
  //date picker start date completed
  //date picker end date

  const onChange1 = (value, dateString) => {
    setEndDate(value);
    setEndTimeParam(dateString);
  };
  //onreset
  const onReset = async () => {
    setActivepage1(1);
    setLoading(true);
    setType('');
    setStartDate('');
    setEndDate('');
    setbankname('');
    setstartamount('');
    setendamount('');
    const path = '/admin/company/banks/history';
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path, null, null);

    if (
      res &&
      res.payment_method_histories &&
      res.payment_method_histories.data &&
      res.payment_method_histories.data.length
    ) {
      const finalArray = res.payment_method_histories.data.map((history, index) => ({
        key: index,
        id: history?.id,
        bankid: history?.payment_method?.bank_code_id,
        amount: history?.amount,
        type: history?.isWithdraw,
        bankname: history?.payment_method?.account_name,
        actionby: history?.transaction?.action_by?.name,
        transactionid: history?.transaction ? history.transaction.id : '-',
        createddate: history?.created_at,
        status: history?.status,
        note: history?.remark ? history.remark : '-',
        newbalance: history?.new_balance,
        prevbalance: history?.previous_balance
      }));
      setData(finalArray);
      setCurrentpage(res.payment_method_histories.current_page);
      setTotal(res.payment_method_histories.total);
      setPerpage(res.payment_method_histories.per_page);
      setNextPage(res.payment_method_histories.next_page_url);
      setTotalDeposit(res.total_deposit);
      setTotalWithdraw(res.total_withdraw);
      setTotalAmount(res.total_amount);
    } else {
      setData([]);
      setTotal(0);
    }
    setLoading(false);
  };
  //form value role select
  function handleChange(e) {
    setbankname(e);
  }
  useEffect(() => {
    fetchCompanyBankshistory();
  }, [currentpage]);
  const addCommasToNumber = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  // Fetching api
  const fetchCompanyBankshistory = async (customPage = null) => {
    try {
      const path = '/admin/company/banks/history';
      const params = {};
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      if (startDate) params['from_date'] = convertLocalTimeToUTC(startTimeParam);
      if (endDate) params['to_date'] = convertLocalTimeToUTC(endTimeParam);
      if (bankname !== 'Select Option') params['payment_method_id'] = bankname;
      if (startamount) params['min_amount'] = startamount;
      if (endamount) params['max_amount'] = endamount;
      if (type) params['type'] = type;
      setLoading(true);

      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      if (
        res &&
        res.payment_method_histories &&
        res.payment_method_histories.data &&
        res.payment_method_histories.data.length
      ) {
        const finalArray = res.payment_method_histories.data.map((history, index) => ({
          key: index,
          id: history?.id,
          bankid: history?.payment_method.bank_code_id,
          amount: history?.amount,
          type: history?.isWithdraw,
          bankname: history?.payment_method?.account_name,
          actionby: history?.transaction?.action_by?.name || '-',
          transactionid: history?.transaction ? history.transaction.id : '-',
          createddate: history?.created_at,
          status: history?.status,
          note: history?.remark ? history.remark : '-',
          newbalance: history?.new_balance,
          prevbalance: history?.previous_balance
        }));

        setData(finalArray);
        setCurrentpage(res.payment_method_histories.current_page);
        setTotal(res.payment_method_histories.total);
        setPerpage(res.payment_method_histories.per_page);
        setNextPage(res.payment_method_histories.next_page_url);
        setTotalDeposit(res.total_deposit);
        setTotalWithdraw(res.total_withdraw);
        setTotalAmount(res.total_amount);
      } else {
        setData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setActivepage1(1);
    fetchCompanyBankshistory();
  };
  const onChangepage = (page) => {
    setActivepage1(page);
    fetchCompanyBankshistory(page);
  };
  const getPaymentMethods = async () => {
    try {
      setLoading(true);
      const path = '/payment/method/drop/down';
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, {});
      if (res && res.length) {
        setPaymentMethods(
          // res.map((pm) => ({ ...pm, label: pm.public_name?.en_public_name || pm.account_name, value: pm.id }))
          res.map((pm) => ({ ...pm, label: pm.internal_name || pm.public_name?.en_public_name, value: pm.id }))
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
    setLoading(false);
  };

  // const getAllBanks = async () => {
  //   const payload = {
  //     token: localStorage.getItem('token')
  //   };
  //   try {
  //     const res = await getBanks(payload);
  //     if (res.status === 200) {
  //       const newArray = [...res.data];

  //       const newObject = { id: 0, value: 'Select Option.....' };

  //       const data = newArray.map((item) => {
  //         return {
  //           ...item,
  //           key: item.id,
  //           value: `${item.bank_code}-${item?.bank_holder_name}`
  //         };
  //       });

  //       data.unshift(newObject);
  //       setBanks(data);
  //     }
  //   } catch (error) {
  //     if (error?.response?.status === 401) {
  //       Navigate('/login');
  //     }
  //   }
  // };
  useEffect(() => {
    const newObject = { id: 0, value: 'Select Option.....' };

    const copyBanks = [...banks];
    const newBanks = copyBanks
      .filter((bank) => {
        return Number(bank.allow_withdraw) === Number(1);
      })
      ?.map(({ id, value, bank_holder_name, currency_name }) => {
        return { id, value: `${value} - ${bank_holder_name}`, currency_name };
      });
    setDetailBanksArrays([newObject, ...newBanks]);
  }, [banks]);
  useEffect(() => {
    // getAllBanks();
    getPaymentMethods();
  }, []);
  return (
    <>
      <HeaderCardDetails />
      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div>
          <div className={styles.container}>
            <div className={styles.filterUpperContainer}>
              <Heading>{lang.label_filters}</Heading>
            </div>

            <div className={styles.filterLowerContainer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  width: '100%',
                  paddingRight: '14px',
                  flexWrap: 'wrap'
                }}>
                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_time}
                  </SubHeading>

                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-around',
                      alignItems: 'center'
                    }}>
                    <DatePicker
                      //getting input value for start date
                      placeholder="Select Time"
                      showTime={{
                        defaultValue: dayjs('00:00:00', 'HH:mm:ss')
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      onChange={onChange}
                      value={startDate}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    />
                    <span
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        marginLeft: '1%',
                        marginRight: '1%'
                      }}>
                      -
                    </span>
                    <DatePicker
                      //getting input value for start date
                      placeholder="Select Time"
                      showTime={{
                        defaultValue: dayjs('23:59:59', 'HH:mm:ss')
                      }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={datePickerStyle}
                      onChange={onChange1}
                      value={endDate}
                      suffixIcon={<CalendarOutlined style={iconStyle} />}
                    />
                  </div>
                </div>

                <div className={styles.flexStyle} style={{ display: 'flex', gap: '0px' }}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_amount}
                  </SubHeading>
                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-around'
                      alignItems: 'center'
                    }}>
                    <Input
                      style={inputStyle}
                      placeholder="Input Amount Range"
                      onChange={(e) => {
                        setstartamount(e.target.value);
                      }}
                      value={startamount}
                    />
                    <span
                      style={{
                        color: 'black',
                        // fontSize: '33px',
                        fontWeight: 'bold',
                        marginLeft: '1%',
                        marginRight: '1%'
                      }}>
                      -
                    </span>
                    <Input
                      style={inputStyle}
                      placeholder="Input Amount Range"
                      onChange={(e) => {
                        setendamount(e.target.value);
                      }}
                      value={endamount}
                    />
                  </div>
                </div>

                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {/* {lang.label_bankname} */}
                    {lang.label_company_payment_method}
                  </SubHeading>
                  <div>
                    <Select
                      customWidth="200px"
                      extraStyle={{ height: '35px' }}
                      placeholder="Select company payment"
                      style={{ width: 200, ...selectStyle }}
                      allowClear
                      value={bankname}
                      onChange={handleChange}
                      // onClear={() => setFormData((prevData) => ({ ...prevData, payment_method_id: null }))}
                      options={paymentMethods}
                    />
                    {/* <SelectBox
                      name="bank"
                      value={bankname}
                      onChange={handleChange}
                      data={paymentMethods}
                      customWidth="200px"
                      extraStyle={{ height: '35px' }}
                    /> */}
                  </div>
                  {/* <Select style={{width:"30%"}} onChange={handleChange}>
                    {Data &&
                      Data.map((bank, index) => (
                        <Option key={index} value={bank.bankid}>
                          {bank.bankname}
                        </Option>
                      ))}
                  </Select> */}
                </div>

                <div className={styles.flexStyle}>
                  <SubHeading
                    extraStyle={{
                      fontSize: '14px',
                      fontWeight: 500
                    }}>
                    {lang.label_type}
                  </SubHeading>
                  <Select
                    defaultValue={null}
                    value={type}
                    style={selectStyle}
                    onChange={(value) => {
                      setType(value);
                    }}
                    options={[
                      {
                        value: null,
                        label: 'All'
                      },
                      {
                        value: 1,
                        label: lang.label_withdraw
                      },
                      {
                        value: '0',
                        label: lang.label_deposit
                      }
                    ]}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '6px',
                    marginTop: '20px',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                  }}>
                  <Button
                    htmlType="submit"
                    style={{
                      display : 'flex',
                      alignItems : 'center',
                      justifyContent : 'space-evenly',
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1'
                    }}
                    className="btnStyle">
                      <img src={SearchIcon} alt='search'/>
                    {lang.label_search}
                  </Button>

                  <Button
                    style={{
                      display : 'flex',
                      alignItems : 'center',
                      justifyContent : 'space-evenly',
                      borderRadius: '3px',
                      padding: '6px',
                      border: '1px solid grey',
                      // width: '84px'
                    }}
                    className="btnReset"
                    //handling reset
                    onClick={onReset}>
                     <img src={ResetIcon} alt='reset' />
                    <label>{lang.label_reset}</label>
                  </Button>
                </div>
              </div>
            </div>
            {/* <br /> */}
          </div>
          {/* </Card> */}
          <br />
          <CompanyBanksHistoryTable data={Data} loading={loading} fetchCompanyBankshistory={fetchCompanyBankshistory} />

          {/* <table style={{ width: "100%", background: "white" }}>
            <tfoot style={{ width: "100%" }}>
              <tr>
                <td style={{ width: "70%" }} />
                <td
                  style={{
                    minWidth: "120px",
                    textAlign: "left",
                    color: "black",
                  }}
                >
                  {lang.label_totaldepositamount}
                </td>
                <td style={{ width: "5%" }}>
                  {addCommasToNumber(totalDeposit)}
                </td>
                <td style={{ width: "10%" }} />
              </tr>
              <tr>
                <td />
                <td style={{ textAlign: "left", color: "black" }}>
                  {lang.label_totalwithdrawalamount}
                </td>
                <td>{addCommasToNumber(totalWithdraw)}</td>
                <td style={{ width: "10%" }} />
              </tr>
              <tr>
                <td />
                <td style={{ textAlign: "left", color: "black" }}>
                  {lang.label_totalamount}
                </td>
                <td>{addCommasToNumber(totalAmount)}</td>
              </tr>
            </tfoot>

          </table> */}
          {/* <table style={{ width: "100%", background: "white" , marginTop:'30px' }}>
          <tfoot style={{ width: "100%" }}>
            <tr>
              <td style={{width:'70%'}} />
              <td style={{ textAlign: "left",color:"black"  , fontWeight:500}}>
                {lang.label_totalamount}
              </td>
              <td style={{color:"black" ,fontWeight:500}}>{addCommasToNumber(totalAmount)}</td>
              <td style={{width:'15%'}} />
            </tr>
          </tfoot>
        </table> */}
          <Pagination
            showSizeChanger={false}
            current={activepage}
            onChange={onChangepage}
            total={total}
            responsive={true}
            pageSize={10}
          />
        </div>
      </form>
    </>
  );
};
export default CompanyBanksHistory;
