import { Menu } from 'antd';
import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TabContext } from '../helps/contexts/TabContext';
import LangContext from '../helps/contexts/lang-context';
import useCheckPermission from '../helps/hooks/useCheckPermission';
import AdminManagementOrange from '../images/AdminManagementOrange.png';
import AdminManagementWhite from '../images/AdminManagementWhite.png';
import AgentManagementWhite from '../images/Agent ManagementWhite.png';
import AgentManagementOrange from '../images/AgentManagementOrange.png';
import DashboardOrange from '../images/DashboardOrange.png';
import DashboardWhite from '../images/DashboardWhite.png';
import GameManagementOrange from '../images/Game Management - Orange.png';
import GameManagementWhite from '../images/Game Management - White.png';
import PlayerManagementOrange from '../images/PlayerManagementOrange.png';
import PlayerManagementWhite from '../images/PlayerManagementWhite.png';
import PromotionsOrange from '../images/PromotionsOrange.png';
import PromotionsWhite from '../images/PromotionsWhite.png';
import TransactionManagementOrange from '../images/TransactionManagementOrange.png';
import TransactionManagementWhite from '../images/TransactionManagementWhite.png';
import reportOrange from '../images/report-orange.png';
import report from '../images/report.png';
import affiliateOrange from '../images/affiliate-orange.png';
import affiliate from '../images/affiliate.png';
import settingsImage from '../images/Settings.png';
import SubHeading from './HelperComponents/SubHeading';
import styles from './SidebarMenu.module.css';

const SidebarMenu = ({ collapsed }) => {
  const ctx = useContext(LangContext);
  const { activeTab, setPath } = useContext(TabContext);
  const { hasPermission } = useCheckPermission();
  const location = useLocation();
  const { pathname } = location;
  const lang = ctx.lang;
  const handleLinkClick = (link) => {
    setPath(link);
    // Any other logic you want to execute on link click
  };
  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label
    };
  }

  const getMenuIcon = (isActive, activeIcon, defaultIcon) => {
    if (isActive) {
      return activeIcon;
    }
    return defaultIcon;
  };
  const items = [
    getItem(
      <Link to="/dashboard" onClick={() => handleLinkClick('/dashboard')}>
        {lang.label_dashboard}
      </Link>,
      '/dashboard',
      <img
        className={styles.sideBarMenuIcon}
        src={getMenuIcon(activeTab === '/dashboard', DashboardOrange, DashboardWhite)}
        alt=""
      />
    ),

    hasPermission('View Deposit') ||
    hasPermission('View Withdraw') ||
    hasPermission('View Company Banks') ||
    hasPermission('View Payment Method History') ||
    hasPermission('View Payment Methods') ||
    hasPermission('View Payment Category') ||
    hasPermission('View Bank Code')
      ? getItem(
          'Transaction Management',
          '/transaction-management',
          <img
            className={styles.sideBarMenuIcon}
            src={getMenuIcon(
              activeTab === '/transaction-management',
              TransactionManagementOrange,
              TransactionManagementWhite
            )}
            alt=""
          />,
          [
            ...(hasPermission('View Deposit')
              ? [
                  getItem(
                    <Link
                      to="/transaction-management/deposit"
                      onClick={() => handleLinkClick('/transaction-management/deposit')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_deposit}</SubHeading>
                      </div>
                    </Link>,
                    '/transaction-management/deposit'
                  )
                ]
              : []),
            ...(hasPermission('View Withdraw')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/transaction-management/withdraw"
                      onClick={() => handleLinkClick('/transaction-management/withdraw')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_withdraw}</SubHeading>
                      </div>
                    </Link>,
                    '/transaction-management/withdraw'
                  )
                ]
              : []),
            ...(hasPermission('View Company Banks')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/transaction-management/company-banks"
                      onClick={() => handleLinkClick('/transaction-management/company-banks')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_companybanks}</SubHeading>
                      </div>
                    </Link>,
                    '/transaction-management/company-banks'
                  )
                ]
              : []),
            ...(hasPermission('View Payment Method History')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/transaction-management/payment-method-history"
                      onClick={() => handleLinkClick('/transaction-management/payment-method-history')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_companybankshistory}</SubHeading>
                      </div>
                    </Link>,
                    '/transaction-management/payment-method-history'
                  )
                ]
              : []),
            ...(hasPermission('View Payment Methods')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/transaction-management/payment-methods"
                      onClick={() => handleLinkClick('/transaction-management/payment-methods')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_paymentmethods}</SubHeading>
                      </div>
                    </Link>,
                    '/transaction-management/payment-methods'
                  )
                ]
              : []),

            ...(hasPermission('View Payment Category')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/transaction-management/payment-categories"
                      onClick={() => handleLinkClick('/transaction-management/payment-categories')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_payment_categories}</SubHeading>
                      </div>
                    </Link>,
                    '/transaction-management/payment-categories'
                  )
                ]
              : []),

            ...(hasPermission('View Bank Code')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/transaction-management/bank-codes"
                      onClick={() => handleLinkClick('/transaction-management/bank-codes')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_bankcodes}</SubHeading>
                      </div>
                    </Link>,
                    '/transaction-management/bank-codes'
                  )
                ]
              : [])
          ]
        )
      : null,

    hasPermission('View Player List') ||
    hasPermission('View Player Balance History') ||
    hasPermission('View Player Login History') ||
    hasPermission('View Game Transaction Histories') ||
    hasPermission('View Player Notification') ||
    hasPermission('View Payment Category') ||
    hasPermission('View Tags') ||
    hasPermission('View Adjust Tag History')
      ? getItem(
          'Player Management',
          '/player-management',
          <img
            className={styles.sideBarMenuIcon}
            alt=""
            src={getMenuIcon(activeTab === '/player-management', PlayerManagementOrange, PlayerManagementWhite)}
          />,
          [
            ...(hasPermission('View Player List')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/player-management/player-list"
                      onClick={() => handleLinkClick('/player-management/player-list')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_playerlist}</SubHeading>
                      </div>
                    </Link>,
                    '/player-management/player-list'
                  )
                ]
              : []),

            ...(hasPermission('View Player Balance History')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/player-management/player-balance-history"
                      onClick={() => handleLinkClick('/player-management/player-balance-history')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_playerbalancehistory}</SubHeading>
                      </div>
                    </Link>,
                    '/player-management/player-balance-history'
                  )
                ]
              : []),

            ...(hasPermission('View Player Login History')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/player-management/login-history"
                      onClick={() => handleLinkClick('/player-management/login-history')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_loginhistory}</SubHeading>
                      </div>
                    </Link>,

                    '/player-management/login-history'
                  )
                ]
              : []),

            ...(hasPermission('View Game Transaction Histories')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/player-management/game-transaction-history"
                      onClick={() => handleLinkClick('/player-management/game-transaction-history')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_gametransactionhistory}</SubHeading>
                      </div>
                    </Link>,
                    '/player-management/game-transaction-history'
                  )
                ]
              : []),

            ...(hasPermission('View Player Notification')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/player-management/player-notification"
                      onClick={() => handleLinkClick('/player-management/player-notification')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>
                        <SubHeading>{lang.label_playernotification}</SubHeading>
                      </div>
                    </Link>,
                    '/player-management/player-notification'
                  )
                ]
              : []),
            // view Management Starts from here
            ...(hasPermission('View Tags')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/player-management/tag-management"
                      onClick={() => handleLinkClick('/player-management/tag-management')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>
                        <SubHeading>{lang.label_tag_management}</SubHeading>
                      </div>
                    </Link>,
                    '/player-management/tag-management'
                  )
                ]
              : []),

            ...(hasPermission('View Adjust Tag History')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/player-management/tag-management/tag-adjustment-history"
                      onClick={() => handleLinkClick('/player-management/tag-management/tag-adjustment-history')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>
                        <SubHeading>{lang.label_tags_adjustment_history}</SubHeading>
                      </div>
                    </Link>,
                    '/player-management/tag-management/tag-adjustment-history'
                  )
                ]
              : [])
          ]
        )
      : null,

    hasPermission('View Promotions') ||
    hasPermission('View Promotion Categories') ||
    hasPermission('View Player Balance History')
      ? getItem(
          'Promotions',
          '/promotions',
          <img
            className={styles.sideBarMenuIcon}
            src={getMenuIcon(activeTab === '/promotions', PromotionsOrange, PromotionsWhite)}
            alt=""
          />,
          [
            ...(hasPermission('View Promotions')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/promotions/promotions-list"
                      onClick={() => handleLinkClick('/promotions/promotions-list')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>
                        <SubHeading>{lang.label_promotions}</SubHeading>
                      </div>
                    </Link>,
                    '/promotions/promotions-list'
                  )
                ]
              : []),

            ...(hasPermission('View Promotion Categories')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/promotions/promotion-category"
                      onClick={() => handleLinkClick('/promotions/promotion-category')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_promotionsCategory}</SubHeading>
                      </div>
                    </Link>,
                    '/promotions/promotion-category'
                  )
                ]
              : []),

            ...(hasPermission('View Player Balance History')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/promotions/promotion-history"
                      onClick={() => handleLinkClick('/promotions/promotion-history')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>
                        <SubHeading>{lang.label_promotion_history}</SubHeading>
                      </div>
                    </Link>,
                    '/promotions/promotion-history'
                  )
                ]
              : [])
          ]
        )
      : null,

    hasPermission('View Agents') ||
    hasPermission('View Agent Notification') ||
    hasPermission('View Agents Change Histories')
      ? getItem(
          'Agent Management',
          '/agent-management',
          <img
            className={styles.sideBarMenuIcon}
            src={getMenuIcon(activeTab === '/agent-management', AgentManagementOrange, AgentManagementWhite)}
            alt=""
          />,
          [
            ...(hasPermission('View Agents')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/agent-management/agent-list"
                      onClick={() => handleLinkClick('/agent-management/agent-list')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_agentlist}</SubHeading>
                      </div>
                    </Link>,
                    '/agent-management/agent-list'
                  )
                ]
              : []),

            // Report

            // ...(hasPermission('View Agents')
            // ? [
            //     getItem(
            //       <Link
            //         style={{ color: '#FFFFFF' }}
            //         to="/agent-management/agent-report"
            //         onClick={() => handleLinkClick('/agent-management/agent-report')}>
            //         <div className={styles.menuNameDiv}>
            //           <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
            //           <SubHeading>Agent Report</SubHeading>

            //         </div>
            //       </Link>,
            //       '/agent-management/agent-report'
            //     )
            //   ]
            // : []),
            //

            ...(hasPermission('View Agent Notification')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/agent-management/agent-notification"
                      onClick={() => handleLinkClick('/agent-management/agent-notification')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>
                        <SubHeading>{lang.label_agentNotification}</SubHeading>
                      </div>
                    </Link>,
                    '/agent-management/agent-notification'
                  )
                ]
              : []),
            // getItem(
            //   <Link
            //     style={{ color: "#FFFFFF" }}
            //     to="/agent-management/superior-agent-list"
            //   >
            //     <div className={styles.menuNameDiv}>
            //       <div className={`${styles.whiteDash} whiteDash`}></div>{" "}
            //       <SubHeading>{lang.label_superioragent}</SubHeading>
            //     </div>
            //   </Link>,
            //   "/agent-management/superior-agent-list"
            // ),
            ...(hasPermission('View Agents Change Histories')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/agent-management/change-agent-history"
                      onClick={() => handleLinkClick('/agent-management/change-agent-history')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_changeagenthistory}</SubHeading>
                      </div>
                    </Link>,
                    '/agent-management/change-agent-history'
                  )
                ]
              : []),

            // ...(hasPermission('View Agents')
            //   ? [
            //       getItem(
            //         <Link
            //           style={{ color: '#FFFFFF' }}
            //           to="/agent-management/agent-commission-report"
            //           onClick={() => handleLinkClick('/agent-management/agent-commission-report')}>
            //           <div className={styles.menuNameDiv}>
            //             <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
            //             <SubHeading>{lang.lable_commission_report}</SubHeading>
            //           </div>
            //         </Link>,
            //         '/agent-management/agent-commission-report'
            //       )
            //     ]
            //   : []),
            // ...(hasPermission('View Agents')
            //   ? [
            //       getItem(
            //         <Link
            //           style={{ color: '#FFFFFF' }}
            //           to="/agent-management/agent-position-report"
            //           onClick={() => handleLinkClick('/agent-management/agent-position-report')}>
            //           <div className={styles.menuNameDiv}>
            //             <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
            //             <SubHeading>{lang.lable_position_report}</SubHeading>
            //           </div>
            //         </Link>,
            //         '/agent-management/agent-position-report'
            //       )
            //     ]
            //   : [])
          ]
        )
      : null,

    hasPermission('View Users') ||
    hasPermission('View Admin Login History') ||
    hasPermission('View Permission') ||
    hasPermission('View Admin Logs') ||
    hasPermission('View IP Whitelist')
      ? getItem(
          'Admin Management',
          '/admin-management',
          <img
            className={styles.sideBarMenuIcon}
            src={getMenuIcon(activeTab === '/admin-management', AdminManagementOrange, AdminManagementWhite)}
            alt=""
          />,
          [
            ...(hasPermission('View Users')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/admin-management/user-list"
                      onClick={() => handleLinkClick('/admin-management/user-list')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_userlist}</SubHeading>
                      </div>
                    </Link>,
                    '/admin-management/user-list'
                  )
                ]
              : []),

            ...(hasPermission('View Admin Login History')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/admin-management/admin-login-history"
                      onClick={() => handleLinkClick('/admin-management/admin-login-history')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_adminloginhistory}</SubHeading>
                      </div>
                    </Link>,
                    '/admin-management/admin-login-history'
                  )
                ]
              : []),
            ...(hasPermission('View Permission')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/admin-management/permissions"
                      onClick={() => handleLinkClick('/admin-management/permissions')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_permissions}</SubHeading>
                      </div>
                    </Link>,
                    '/admin-management/permissions'
                  )
                ]
              : []),

            ...(hasPermission('View Admin Logs')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/admin-management/admin-logs"
                      onClick={() => handleLinkClick('/admin-management/admin-logs')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div> <SubHeading>Admin Logs</SubHeading>
                      </div>
                    </Link>,
                    '/admin-management/admin-logs'
                  )
                ]
              : []),

            ...(hasPermission('View IP Whitelist')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/admin-management/ip-white-list"
                      onClick={() => handleLinkClick('/admin-management/ip-white-list')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div> <SubHeading>IP White List</SubHeading>
                      </div>
                    </Link>,
                    '/admin-management/ip-white-list'
                  )
                ]
              : [])
          ]
        )
      : null,

    hasPermission('View Game Platform') ||
    hasPermission('View Game Categories') ||
    hasPermission('View Game Lists') ||
    hasPermission('View Game Access History') ||
    hasPermission('View Rebates')
      ? getItem(
          'Game Management',
          '/game-management',
          <img
            className={styles.sideBarMenuIcon}
            src={getMenuIcon(activeTab === '/game-management', GameManagementOrange, GameManagementWhite)}
            alt=""
          />,
          [
            ...(hasPermission('View Game Platform')
              ? [
                  getItem(
                    <Link to="/game-management/platforms" onClick={() => handleLinkClick('/game-management/platforms')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div> <SubHeading> Game Platforms</SubHeading>
                        {/* {lang.label_platforms} */}
                      </div>
                    </Link>,
                    '/game-management/platforms'
                  )
                ]
              : []),

            ...(hasPermission('View Game Categories')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/game-management/game-categories"
                      onClick={() => handleLinkClick('/game-management/game-categories')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_gamecategories}</SubHeading>
                      </div>
                    </Link>,
                    '/game-management/game-categories'
                  )
                ]
              : []),

            ...(hasPermission('View Game Lists')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/game-management/game-list"
                      onClick={() => handleLinkClick('/game-management/game-list')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>{' '}
                        <SubHeading>{lang.label_gamelist}</SubHeading>
                      </div>
                    </Link>,
                    '/game-management/game-list'
                  )
                ]
              : []),

            ...(hasPermission('View Game Access History')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/player-management/game-access-history"
                      onClick={() => handleLinkClick('/player-management/game-access-history')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>
                        <SubHeading>{lang.label_gameaccesshistory}</SubHeading>
                      </div>
                    </Link>,
                    '/player-management/game-access-history'
                  )
                ]
              : []),

            ...(hasPermission('View Rebates')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/game-management/rebate"
                      onClick={() => handleLinkClick('/game-management/rebate')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>
                        <SubHeading>{lang.label_rebate}</SubHeading>
                      </div>
                    </Link>,
                    '/game-management/rebate'
                  )
                ]
              : [])

            // getItem(
            //   <Link
            //     style={{ color: "#FFFFFF" }}
            //     to="/game-management/game-category-platform-items"
            //   >
            //     <div className={styles.menuNameDiv}>
            //       <div className={`${styles.whiteDash} whiteDash`}></div>{" "}
            //       <SubHeading>{lang.label_gameplatformitems}</SubHeading>
            //     </div>
            //   </Link>,
            //   "/game-management/game-category-platform-items"
            // ),
          ]
        )
      : null,

    hasPermission('View Affiliates')
      ? getItem(
          'Affiliate Management',
          '/affiliate-management',
          <img
            className={styles.sideBarMenuIcon}
            src={getMenuIcon(activeTab === '/affiliate-management', affiliateOrange, affiliate)}
            alt=""
          />,
          [
            ...(hasPermission('View Affiliates')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/affiliate-management/affiliate-list"
                      onClick={() => handleLinkClick('/affiliate-management/affiliate-list')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>
                        <SubHeading>{lang.label_affiliate_list}</SubHeading>
                      </div>
                    </Link>,
                    '/affiliate-management/affiliate-list'
                  )
                ]
              : [])
          ]
        )
      : null,

    hasPermission('View Bet History')
      ? getItem(
          'Reports',
          '/reports',
          <img
            className={styles.sideBarMenuIcon}
            src={getMenuIcon(activeTab === '/reports', reportOrange, report)}
            alt=""
          />,
          [
            ...(hasPermission('View Bet History')
              ? [
                  getItem(
                    <Link
                      style={{ color: '#FFFFFF' }}
                      to="/reports/bet-history"
                      onClick={() => handleLinkClick('/reports/bet-history')}>
                      <div className={styles.menuNameDiv}>
                        <div className={`${styles.whiteDash} whiteDash`}></div>
                        <SubHeading>{lang.label_bethistory}</SubHeading>
                      </div>
                    </Link>,
                    '/reports/bet-history'
                  )
                ]
              : [])
          ]
        )
      : null,
    // export list
    // hasPermission('View Bet History')
    //   ? getItem(
    //       <Link style={{ color: '#FFFFFF' }} to="/export-list" onClick={() => handleLinkClick('/export-list')}>
    //         {' '}
    //         Export List
    //       </Link>,

    //       '/export-list',
    //       <img
    //         className={styles.sideBarMenuIcon}
    //         src={getMenuIcon(activeTab === '/export-list', reportOrange, report)}
    //         alt=""
    //       />

    //       // [
    //       //   ...(hasPermission('View Bet History')  ? [
    //       //         getItem(
    //       //           <Link
    //       //             style={{ color: '#FFFFFF' }}
    //       //             to="/export-list"
    //       //             onClick={() => handleLinkClick('/export-list')}></Link>,
    //       //           '/export-list'
    //       //         )
    //       //       ]
    //       //     : [])
    //       // ]
    //     )
    //   : null,
    ...(hasPermission('View Settings')
      ? [
          getItem(
            <Link to="/settings" onClick={() => handleLinkClick('/settings')}>
              {lang.label_settings}
            </Link>,
            '/settings',
            <img className={styles.sideBarMenuIcon} src={settingsImage} alt="" />
          )
        ]
      : [])
  ];

  // Set Active Tabs value
  const checkActiveTab = (activeTab) => {
    if (pathname.includes('bet-details')) {
      return '/reports/bet-history';
    } else {
      return activeTab;
    }
  };

  return (
    <Menu
      style={{
        backgroundColor: '#004A7F',
        color: 'white',
        width: `${collapsed ? `80px` : `250px`}`,
        paddingBottom: '70px'
      }}
      theme={{ items: { backgroundColor: '#004A7F' } }}
      mode="inline"
      defaultSelectedKeys={[activeTab]}
      selectedKeys={[checkActiveTab(activeTab)]}
      defaultOpenKeys={[`/${pathname?.split('/')[1]}`]}
      items={items}
    />
  );
};

export default SidebarMenu;
