import React from 'react'

const ErrorsHandlingComp = ({ errors ,name}) => {
  return (
    <>
      {typeof errors[name] === 'object' && errors[name]?.length > 0 ? errors[name]?.map(error =>
            <div style={{ color: 'red' }}>{error}</div>
        ) : <>
            <div style={{ color: 'red' }}>{errors[name]}</div>
        </>} 
      </>
  )
}

export default ErrorsHandlingComp
