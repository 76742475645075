import { useContext, useState, useEffect } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import { CalendarOutlined } from '@ant-design/icons';
import { Navigate, useNavigate } from 'react-router-dom';
import { Card, Row, Col, DatePicker, Button, Input, Pagination, message } from 'antd';
import GetApi from '../../../helps/contexts/Api/GetApi';
import GameAccessHistoryTable from './GameAccessHistoryTable';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import Spacer from '../../../components/HelperComponents/Spacer';
import PaginationComp from '../../../components/HelperComponents/PaginationComp';
import { convertLocalTimeToUTC } from '../../../helps/HelperFunctions';
import { getGameCategories, getPlatforms } from '../../../helps/contexts/Api/api';
import SelectBox from '../../../components/HelperComponents/SelectBox';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import SearchIcon from "../../../images/Search.png";
import ResetIcon from "../../../images/Reset.png";
import  dayjs  from 'dayjs';

const GameAccessHistory = () => {
  const nav = useNavigate();
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  //styles
  const datePickerStyle = {
    width: '140px',
    height: '35px',
    background: ' #FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const iconStyle = { color: '#004A7F' };
  const inputStyle = {
    width: '190px',
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };
  const selectStyle = {
    width: '190px',
    height: '33px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '5px !important'
  };
  //states

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startTimeParam, setStartTimeParam] = useState('');
  const [endTimeParam, setEndTimeParam] = useState('');
  const [playername, setPlayername] = useState('');
  // const [platformname,setPlatformname]=useState("");
  const [filterData, setFilterData] = useState({
    game_platform_id: 0,
    game_category_id: 0
  });
  const [gamename, setGamename] = useState('');
  // const [gamecategoryname,setGamecategoryname]=useState("");
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(0);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [Data, setData] = useState([]);
  const [platForms, setPlatForms] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchhistory();
  }, []);
  const onChangepage = (page) => {
    setActivepage1(page);
    fetchhistory(page);
  };
  const paginationConfig = {
    current: currentpage,
    pageSize: perpage ? perpage : 0,
    total: total ? total : 0,
    showSizeChanger: false,
    showQuickJumper: false,
    showTotal: (total) => `Total ${total} items`,
    onChange: onChangepage
  };
  const onReset = async () => {
    try {
      setActivepage1(1);
      setLoading(true);
      setPlayername('');
      // setPlatformname("")
      setGamename('');
      // setGamecategoryname("")
      setFilterData({
        game_platform_id: 0,
        game_category_id: 0
      });
      const path = '/admin/players/game/access/history';
      const params = {};
      //sending api request
      const res = await GetApi.sendApiRequest(path, null, null);

      if (res && res.data && res.data.length) {
        const finalArray = res.data.map((history, index) => ({
          key: index,
          playername: history.player.user.user_name,
          platformname: history.game_item.game_platform.name,
          gamename: history.game_item.name,
          gamecategoryname: history.game_item.game_category.name,
          remark: history.remark,
          status: history.status,
          date: history.created_at
        }));
        setData(finalArray);
        setCurrentpage(res.current_page);
        setTotal(res.total);
        setPerpage(res.per_page);
        setNextPage(res.next_page_url);
      } else {
        setData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/');
      }
    }
  };

  //date picker startdate
  const onChange = (value, dateString) => {
    setStartDate(value);
    setStartTimeParam(dateString);
  };
  //date picker start date completed
  //date picker end date

  const onChange1 = (value, dateString) => {
    setEndDate(value);
    setEndTimeParam(dateString);
  };
  //on submit of filters on table values
  const onSubmit = (e) => {
    e.preventDefault();
    setActivepage1(1);
    fetchhistory();
  };
  // Fetching api
  const fetchhistory = async (customPage = null) => {
    try {
      const path = '/admin/players/game/access/history';
      const params = {};
      setLoading(true);

      //setting parameters to send for search results

      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      if (startDate) params['from_date'] = convertLocalTimeToUTC(startTimeParam);
      if (endDate) params['to_date'] = convertLocalTimeToUTC(endTimeParam);
      if (playername) params['player_name'] = playername;
      if (filterData?.game_platform_id) params['game_platform_id'] = filterData?.game_platform_id;
      if (gamename) params['game_name'] = gamename;
      if (filterData?.game_category_id) params['game_category_id'] = filterData?.game_category_id;

      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);

      if (res.data) {
        const finalArray = res.data.map((history, index) => {
          return {
            key: index,
            playername: history?.player?.user?.user_name,
            platformname: history?.game_item?.game_platform?.name,
            gamename: history?.game_item?.name,
            gamecategoryname: history.game_item.game_categories,
            remark: history.remark,
            status: history.status,
            date: history.created_at
          };
        });

        setData(finalArray);
        setCurrentpage(res.current_page);
        setTotal(res.total);
        setPerpage(res.per_page);
        setNextPage(res.next_page_url);
      } else {
        setData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };

  const handleFilterSelectChange = (value, name) => {
    setFilterData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    // setItem((prevFormData) => ({
    //   ...prevFormData,
    //   [name]: value,
    // }));
  };

  const getAllPlatformsFunc = async () => {
    try {
      const payload = {
        token: localStorage.getItem('token')
      };
      const res = await getPlatforms(payload);
      if (res.status === 200) {
        const newObject = { id: 0, value: 'Select Platform' };

        const data = res.data.data.map(({ name, id }) => ({ id, value: name }));

        const newData = [newObject, ...data];

        setPlatForms(newData);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
      message.error(error.response.data.message);
    }
  };
  const getAllCategoriesFunc = async (payload) => {
    try {
      const payload = {
        token: localStorage.getItem('token')
      };
      const res = await getGameCategories(payload);
      if (res.status === 200) {
        // console.log("datas : ", res);
        const newObject = { id: 0, value: 'Select Category' };
        const data = res.data.data.map(({ name, id }) => {
          const jsonString = JSON.parse(name);
          return { value: jsonString['en'], id };
        });
        data.unshift(newObject);
        setCategories(data);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
      message.error(error.response.data.message);
    }
  };
  useEffect(() => {
    getAllPlatformsFunc();
    getAllCategoriesFunc();
  }, []);
  return (
    <>
      <HeaderCardDetails />

      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div>
          <Card
            title="Filters"
            style={{
              borderRadius: '3px'
            }}>
            <div>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}>
                <Col
                  span={29}
                  style={{
                    color: '#636363',
                    fontSize: '14px',
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px',paddingTop:"10px" }}>
                    <div>
                      <SubHeading
                        className="filterTest"
                        extraStyle={{
                          fontSize: '14px',
                          fontWeight: 500,
                          display: 'flex',
                          justifyContent: 'flex-start',
                          gap: '10px'
                        }}>
                        {lang.label_time}{' '}
                      </SubHeading>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <DatePicker
                        //getting input value for start date
                        placeholder="Select Time"
                        showTime ={{
                          defaultValue : dayjs('00:00:00', 'HH:mm:ss'),
                        }}
                        format="YYYY-MM-DD HH:mm:ss"
                        style={datePickerStyle}
                        onChange={onChange}
                        value={startDate}
                        suffixIcon={<CalendarOutlined style={iconStyle} />}
                      />
                      <span
                        style={{
                          color: 'black',
                          fontSize: '33px',
                          marginLeft: '0.3%',
                          marginRight: '0.3%',
                          display: 'flex',
                          alignItems: 'center',
                          height: '30px'
                        }}>
                        -
                      </span>
                      <DatePicker
                        placeholder="Select Time"
                        showTime ={{
                          defaultValue : dayjs('23:59:59', 'HH:mm:ss'),
                        }}
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={onChange1}
                        style={datePickerStyle}
                        //getting input value for end date
                        value={endDate}
                        suffixIcon={<CalendarOutlined style={iconStyle} />}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px',paddingTop:"10px" }}>
                    <SubHeading
                      className="filterTest"
                      extraStyle={{
                        fontSize: '14px',
                        fontWeight: 500,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: '10px'
                      }}>
                      {lang.label_playername}
                    </SubHeading>
                    <Input
                      style={inputStyle}
                      placeholder="Enter Player Name"
                      onChange={(e) => {
                        setPlayername(e.target.value);
                      }}
                      value={playername}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px',paddingTop:"10px" }}>
                    <SubHeading
                      className="filterTest"
                      extraStyle={{
                        fontSize: '14px',
                        fontWeight: 500,
                        display: 'flex',
                        justifyContent: 'flex-start',
                            gap:"10px"
                      }}>
                      {lang.label_platformname}
                    </SubHeading>
                    {/* <Input
                    style={inputStyle}
                    placeholder="Enter Platform Name"
                    onChange={(e) => {
                      setPlatformname(e.target.value);
                    }}
                    value={platformname}
                  /> */}

                    <SelectBox
                      name="game_platform_id"
                      value={filterData.game_platform_id}
                      onChange={handleFilterSelectChange}
                      data={platForms}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px',paddingTop:"10px" }}>
                    <SubHeading
                      className="filterTest"
                      extraStyle={{
                        fontSize: '14px',
                        fontWeight: 500,
                        display: 'flex',
                        justifyContent: 'flex-start',
                            gap:"10px"
                      }}>
                      {lang.label_gamename}
                    </SubHeading>
                    <Input
                      style={inputStyle}
                      placeholder="Enter Game Name"
                      onChange={(e) => {
                        setGamename(e.target.value);
                      }}
                      value={gamename}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px',paddingTop:"10px" }}>
                    <SubHeading
                      className="filterTest"
                      extraStyle={{
                        fontSize: '14px',
                        fontWeight: 500,
                        display: 'flex',
                        justifyContent: 'flex-start',
                      }}>
                      {lang.label_gamecategoryname}
                    </SubHeading>
                    {/* <Input
                    style={inputStyle}
                    placeholder="Enter Game Category Name"
                    onChange={(e) => {
                      setGamecategoryname(e.target.value);
                    }}
                    value={gamecategoryname}
                  /> */}
                    <SelectBox
                      name="game_category_id"
                      value={filterData.game_category_id}
                      onChange={handleFilterSelectChange}
                      data={categories}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '24px' }}>
                    <div  style={{display : 'flex'}}>
                      <Button
                        htmlType="submit"
                        className="btnStyle"
                        style={{
                          display : 'flex',
                          alignItems : 'center',
                          justifyContent : 'space-evenly',
                          backgroundColor: '#004A7F',
                          color: 'white',
                          width: '125px',
                          height: '35px',
                          background: '#004A7F 0% 0% no-repeat padding-box',
                          borderRadius: '3px',
                          opacity: '1',
                          margin: '0px 10px'
                        }}>
                        <img src={SearchIcon} alt='search' />
                        {lang.label_search}
                      </Button>

                      <Button
                        style={{
                          display : 'flex',
                          alignItems : 'center',
                          justifyContent : 'space-evenly',
                          borderRadius: '3px',
                          padding: '6px',
                          border: '1px solid grey',
                          width: '84px'
                        }}
                        className="btnReset"
                        //handling reset
                        onClick={onReset}>
                           <img src={ResetIcon} alt='reset' />
                        {lang.label_reset}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <Spacer />
          <Spacer />
          <GameAccessHistoryTable data={Data} loading={loading} />
          <Spacer />
          <PaginationComp paginationConfig={paginationConfig} />
        </div>
      </form>
    </>
  );
};
export default GameAccessHistory;
