import { Fragment, useContext, useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import styles from './BetDetails.module.css';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import BackImg from '../../../images/Back.png';
import { getRoundDetailsAPI } from '../../../helps/contexts/Api/api';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../../components/HelperComponents/Loader';
import BetDetailComponent from './BetDetailComponent/BetDetailComponent';
import BetDetailsTable from './BetDetailsTable/BetDetailsTable';
import LangContext from '../../../helps/contexts/lang-context';

const BetDetails = () => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const navigate = useNavigate();

  const pathname = useLocation().pathname;
  const match = pathname.match(/\/reports\/bet-history\/bet-details\/([^/]+)/);
  const id = match && match[1];

  const [roundData, setRoundData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRoundDetails();
  }, []);

  const getRoundDetails = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const res = await getRoundDetailsAPI(token, id);
    if (res?.data) {
      setRoundData(res?.data);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <HeaderCardDetails />
      <div style={{ padding: '20px' }}>
        <Row className={styles.betDetailsOverlay}>
          <Col span={5} className={styles.betDetailsSection}>
            <Typography>
              <img src={BackImg} alt="Back" onClick={() => navigate('/reports/bet-history')} />
              Bet Details
            </Typography>
            {loading ? <Loader size={22} /> : <BetDetailComponent data={roundData} />}
          </Col>
          <Col span={19} className={styles.allBetsOverlay}>
            <div className={styles.allBetsHeading}>{lang.label_bets}</div>
            <BetDetailsTable data={roundData?.bets} loading={loading} />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default BetDetails;
