import React from 'react'
const styled = {
    padding:0,
    margin:0,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'Roboto',
            letterSpacing: "0px",
    color: "#858585",
    width:'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',


  };
const MiniText = ({children,extraStyle}) => {
    return (
        <span  style={{...styled , ...extraStyle}}>{children}</span>
      )
}

export default MiniText
