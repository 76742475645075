import React, { useState } from 'react';
import LangContext from './lang-context';
// import Lang from '../../messages';
import LocalizedStrings from 'react-localization';
import strings from '../../strings';


const LangProvider = (props) => {
    const [locale, setLoc] = useState('en');
    let t = new LocalizedStrings(strings);
    const [lang, setLang] = useState(t);

    const setLocale = (language) => {
        setLoc(language);
        lang.setLanguage(language);
    }

    const langContext = {
        locale: locale,
        lang: lang,
        setLocale: setLocale
    };

    return (
        <LangContext.Provider value={langContext}>
            {props.children}
        </LangContext.Provider>
    )
}

export default LangProvider;