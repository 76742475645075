import React, { useState } from 'react';
import { Button, Input, Modal, Table } from 'antd';

const WalletPopUp = ({ visible, onClose, record }) => {
  const [creditLine, setCreditLine] = useState('');

  // Handle input change
  const handleInputChange = (e) => {
    setCreditLine(e.target.value);
  };

  // Handle Confirm button click
  const handleConfirm = () => {
    console.log('Credit Line Value:', creditLine);
    // API CONNECTION
    onClose();
  };
  const columns1 = [
    {
      title: 'Changed By',
      dataIndex: 'downline',
      key: 'downline'
    },
    {
      title: 'Credit Line before',
      dataIndex: 'enable',
      key: 'enable'
    },
    {
      title: 'Credit Line after',
      dataIndex: 'disable',
      key: 'disable'
    },
    {
      title: 'Change time',
      dataIndex: 'disable',
      key: 'disable'
    }
  ];
  const dataSource1 = [
    {
      key: '1',
      downline: 'Agent Number',
      enable: '1',
      disable: '0',
      disable: '0'
    }
  ];
  return (
    <div>
      <Modal title={`Agent: ${record?.name}`} visible={visible} onCancel={onClose} footer={null} width={700}>
        <div style={{ marginBottom: '15px', width: '40%', display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
            <label style={{}}>Credit line</label>
          </div>
          <div style={{ width: '60%' }}>
            <Input placeholder="Enter credit line" value={creditLine} onChange={handleInputChange} />
          </div>
        </div>

        <Table dataSource={dataSource1} columns={columns1} pagination={false} />

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
          <Button key="cancel" onClick={onClose} style={{ marginRight: '10px' }}>
            Cancel
          </Button>
          <Button
            key="confirm"
            type="primary"
            onClick={handleConfirm}
            style={{ backgroundColor: '#004A7F', color: 'white' }}>
            Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default WalletPopUp;
