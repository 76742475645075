import { Button, Card, Col, Input, Modal, Row, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import GetApi from '../../../helps/contexts/Api/GetApi';
import LangContext from '../../../helps/contexts/lang-context';
import PaymentMethodTable from './PaymentMethodTable';
// import AddCompanyBankPopup from "./AddCompanyBankPopup";
// import AdjustCompanyBankPopup from "./AdjustCompanyBankPopup";
import { useNavigate } from 'react-router-dom';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import { toFixedWithoutCommas } from '../../../helps/HelperFunctions';
import PostApi from '../../../helps/contexts/Api/PostApi';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import '../../../styles/ButtonStyle.css';
import AddPaymentMethodPopup from './AddPaymentMethodPopup';
import { TabContext } from '../../../helps/contexts/TabContext';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import SearchIcon from "../../../images/Search.png"
import ResetIcon from "../../../images/Reset.png"
const PaymentMethod = () => {
  //Language translation
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();
  const [showEditBankPopup, setShowEditBankPopup] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [editMode, setEditMode] = useState(false);

  const [formLoading, setFormLoading] = useState(false);

  const lang = ctx.lang;
  const nav = useNavigate();

  const inputStyle = {
    width: '300px',
    height: '33px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
    // left: '5px'
  };

  //States
  const [companyTableData, setcompanyTableData] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [isaddbank, setIsaddbank] = useState(false);
  const [isaddadj, setIsaddadj] = useState(false);
  const [visible, setVisible] = useState(false);
  const { path } = useContext(TabContext);

  useEffect(() => {
    if (path === '/transaction-management/payment-methods') {
      fetchPaymentMethods();
    }
  }, [path]);
  //to close the popups opened

  const handleCancel = () => {
    setVisible(!visible);
    setIsaddbank(false);
    setIsaddadj(false);
  };

  // Fetching api
  const fetchPaymentMethods = async (customPage = null) => {
    try {
      const path = '/payment/method';
      const params = {};

      //setting parameters to send for search results

      if (inputSearchValue) params['search'] = inputSearchValue;
      setLoading(true);
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.length) {
        const x = res.map((item) => {
          return {
            ...item,
            balance: toFixedWithoutCommas(item.balance),
            max_daily_amount: toFixedWithoutCommas(item.max_daily_amount),
            max_total_amount: toFixedWithoutCommas(item.max_total_amount),
            min_deposit_amount: toFixedWithoutCommas(item.min_deposit_amount),
            min_withdraw_amount: toFixedWithoutCommas(item.min_withdraw_amount),
            max_withdraw_amount: toFixedWithoutCommas(item.max_withdraw_amount),
            max_deposit_amount: toFixedWithoutCommas(item.max_deposit_amount)
          };
        });
        setcompanyTableData(x);
        setFiltered(x);
      } else {
        setcompanyTableData([]);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (inputSearchValue) {
      let result = [];
      result = companyTableData.filter((data) => {
        return data.account_name.toLowerCase().search(inputSearchValue.toLowerCase()) != -1;
      });
      setFiltered(result);
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (editMode) {
      handleUpdate();
    } else {
      try {
        setFormLoading(true);
        const res = await PostApi.PostApiRequest('/payment/method/store', formData);
        if (res?.status === 200) {
          message.success(lang.label_add_payment_method_success);
          setShowEditBankPopup(false);
          fetchPaymentMethods();
          setFormData({});
        }
      } catch (e) {
        if (e?.status == 422) {
          const errorObj = {};
          const errorArr = e?.data?.message;
          if (errorArr) {
            Object.keys(errorArr).map((item) => {
              message.error(errorArr[item][0]);
            });
          }
        }
      }
      setFormLoading(false);
    }
  };

  const handleUpdate = async () => {
    setFormLoading(true);
    const path = '/payment/method/' + formData.id + '/update';
    try {
      const res = await PostApi.PostApiRequest(path, formData);
      if (res?.status === 200) {
        message.success(lang.label_addbanksuccess);
        setShowEditBankPopup(false);
        fetchPaymentMethods();
        setFormData({});
        setEditMode(false);
      }
    } catch (e) {
      if (e?.status == 422) {
        const errorObj = {};
        const errorArr = e?.data?.message;
        if (errorArr) {
          Object.keys(errorArr).map((item) => {
            message.error(errorArr[item][0]);
          });
        }
      }
    }
    setFormLoading(false);
  };

  const onEditClicked = (row) => {
    setShowEditBankPopup(true);
    setEditMode(true);
    setFormData(row);
  };

  return (
    <>
      <HeaderCardDetails />
      <div style={{ padding: '20px' }}>
        <div>
          <Card
            title="Filters"
            extra={
              <>
                {hasPermission('Create Payment Method') && (
                  <Button
                    style={{
                      backgroundColor: '#004A7F',
                      color: 'white',
                      width: '125px',
                      height: '35px',
                      background: '#004A7F 0% 0% no-repeat padding-box',
                      borderRadius: '3px',
                      opacity: '1'
                    }}
                    onClick={() => {
                      setFormData({ allow_deposit: 0, allow_withdraw: 0, under_maintenance: 0 });
                      setShowEditBankPopup(true);
                    }}
                    className="btnStyle">
                    {lang.label_addbank}
                  </Button>
                )}
              </>
            }
            style={{
              borderRadius: '3px'
            }}>
            <form onSubmit={onSubmit}>
              <div>
                <Row
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center'
                  }}>
                  <Col
                    span={18}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexWrap: 'wrap'
                    }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <SubHeading
                        extraStyle={{
                          fontSize: '14px',
                          fontWeight: 500
                        }}>
                        {lang.label_bankname} / {lang.label_bankaccountname}
                      </SubHeading>

                      <Input
                        style={inputStyle}
                        value={inputSearchValue}
                        type="text"
                        onChange={(e) => {
                          setInputSearchValue(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '6px',
                        marginTop: '20px',
                        marginLeft: '10px',
                        alignItems: 'center',
                        justifyContent: 'space-around'
                      }}>
                      <Button
                        htmlType="submit"
                        style={{
                          display : 'flex',
                          alignItems : 'center',
                          justifyContent : 'space-evenly',
                          backgroundColor: '#004A7F',
                          color: 'white',
                          width: '125px',
                          height: '35px',
                          background: '#004A7F 0% 0% no-repeat padding-box',
                          borderRadius: '3px',
                          opacity: '1'
                        }}
                        className="btnStyle">
                         <img src={SearchIcon} alt='search'/>
                        {lang.label_search}
                      </Button>

                      <Button
                        onClick={() => {
                          setInputSearchValue('');
                          fetchPaymentMethods();
                        }}
                        style={{
                          display : 'flex',
                          alignItems : 'center',
                          justifyContent : 'space-evenly',
                          borderRadius: '3px',
                          padding: '6px',
                          border: '1px solid grey',
                          width: '84px'
                        }}
                        className="btnReset">
                          <img src={ResetIcon} alt='reset'/>
                        <label>{lang.label_reset}</label>
                      </Button>
                    </div>
                  </Col>
                  {/* <Col
                    span={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexWrap: 'wrap'
                    }}>
                    {hasPermission('Create Payment Method') && (
                      <Button
                        onClick={() => {
                          setFormData({ allow_deposit: 0, allow_withdraw: 0, under_maintenance: 0 });
                          setShowEditBankPopup(true);
                        }}
                        className="btnStyle">
                        {lang.label_addbank}
                      </Button>
                    )} */}

                  {/* <Button
                      onClick={() => {
                       console.log("hello")
                      }}
                      style={{ left: "20px" }}
                      className="btnStyle"
                    >
                      {lang.label_adjustment}
                    </Button> */}
                  {/* </Col> */}
                </Row>
              </div>
            </form>
          </Card>
          <br />
          <PaymentMethodTable
            companyTableData={filtered}
            loading={loading}
            fetchCompanyBanks={fetchPaymentMethods}
            onEditClicked={onEditClicked}
            setCompanyTableData={setcompanyTableData}
            fetchPaymentMethods={fetchPaymentMethods}
          />
        </div>

        <div>
          <Modal
            width={1000}
            open={showEditBankPopup}
            title={lang.label_addbank}
            onCancel={() => {
              setShowEditBankPopup(false);
              setEditMode(false);
            }}
            footer={null}
            // maskClosable={false}
            // keyboard={false}
            // okText={lang.label_submit}
            // cancelText={lang.label_cancel}
            // onOk={handleFormSubmit}
            // confirmLoading={formLoading}
          >
            <AddPaymentMethodPopup
              formData={formData}
              formErrors={formErrors}
              setFormData={setFormData}
              formLoading={formLoading}
              setShowEditBankPopup={setShowEditBankPopup}
              setEditMode={setEditMode}
              handleFormSubmit={handleFormSubmit}
            />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
