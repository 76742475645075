import { Button, Divider, Flex, Modal, Popover, Table, Tabs, Tag, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styles from './DepositPopup.module.css';
import LangContext from '../../../../../helps/contexts/lang-context';
import { FaCopy } from 'react-icons/fa';
import { Navigate } from 'react-router-dom';
import GetApi from '../../../../../helps/contexts/Api/GetApi';
import PaginationComp from '../../../../../components/HelperComponents/PaginationComp';
import { convertUTCToLocalTime, toFixedFunc } from '../../../../../helps/HelperFunctions';
import Loader from '../../../../../components/HelperComponents/Loader';

export default function DepositPopup({ isModalOpen, handleOk, handleCancel, popupId }) {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const [loading, setLoading] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [details, setDetails] = useState();
  const [balanceHistoryData, setBalanceHistoryData] = useState([]);
  const [methodHistoryData, setMethodHistoryData] = useState([]);

  const [totalLength, setTotalLength] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [flag, setFlag] = useState(1);

  const getData = async ({ flag }) => {
    setLoading(true);
    try {
      const path = `/admin/transactions/deposit/${popupId}/details`;

      const params = { flag: flag == 1 ? 'balance_history' : '', page: currentPage };

      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      console.log(res);
      if (res) {
        setLoading(false);
        setDetails(res);
        if (flag === 1) {
          setBalanceHistoryData(res.player_balance_history.data);
          setTotalLength(res.player_balance_history.total);
          setPageSize(res.player_balance_history.per_page);
        }
        if (flag === 2) {
          setMethodHistoryData(res.payment_method_histories.data);
          setTotalLength(res.payment_method_histories.total);
          setPageSize(res.payment_method_histories.per_page);
        }
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
    setLoading(false);
  };

  const onChangePage = (page) => {
    setCurrentPage(page);
    // setIsPageChange(true);
  };

  const paginationConfig = {
    pageSize: pageSize ? pageSize : 0,
    total: totalLength ? totalLength : 0,
    showSizeChanger: false,
    showQuickJumper: false,
    current: currentPage,
    showTotal: (total) => `Total ${total} items`,
    onChange: onChangePage,
    itemRender: (current, type, originalElement) => {
      if (type === 'prev') {
        return <span className="pagination-item">Previous</span>;
      }
      if (type === 'next') {
        return <span className="pagination-item">Next</span>;
      }
      return originalElement;
    }
  };

  const handleOpenChange = (value) => {
    const textToCopy = value;

    navigator.clipboard.writeText(textToCopy);

    setPopoverOpen(true);
    setTimeout(() => {
      setPopoverOpen(false);
    }, 2000);
  };

  const hide = () => {
    setPopoverOpen(false);
  };

  const onChange = (key) => {
    // getData({ flag: key });
    setFlag(key);
    setCurrentPage(1);
  };

  const balanceHistoryColumns = [
    {
      title: lang.label_previousbalance,
      dataIndex: 'previous_balance',
      key: 'previous_balance',
      width: 150,
      render: (text) => <span>{text ? toFixedFunc(text, process.env.REACT_APP_Decimal_Points) : ''}</span>,
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_points,
      dataIndex: 'points',
      key: 'points',
      width: 100,
      render: (_, record) => (
        <span>{record?.amount ? toFixedFunc(record?.amount, process.env.REACT_APP_Decimal_Points) : ''}</span>
      ),
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_newbalance,
      dataIndex: 'new_balance',
      key: 'new_balance',
      width: 150,
      render: (text) => <span>{text ? toFixedFunc(text, process.env.REACT_APP_Decimal_Points) : ''}</span>,
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_Currency,
      dataIndex: 'currency',
      key: 'currency',
      width: 100,
      render: (_, record) => <span>{record?.currency_name ?? `-`}</span>,
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_type,
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (_, record) => (
        <span>
          <Tag
            style={{
              borderRadius: '12px'
            }}
            color={record?.transaction?.isWithdraw ? 'success' : 'error'}>
            {record?.transaction?.isWithdraw ? `Withdraw` : `Deposit`}
          </Tag>
        </span>
      ),
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_actionby,
      dataIndex: 'action_by',
      key: 'action_by',
      width: 150,
      render: (_, record) => <span>{record?.transaction?.action_by?.name ?? `-`}</span>,
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_remark,
      dataIndex: 'remark',
      key: 'remark',
      width: 200,
      render: (text) => <span>{text}</span>,
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_gametransactionid,
      dataIndex: 'gametransaction_id',
      key: 'gametransaction_id',
      width: 200,
      render: (_, record) => <span>{record?.game_transaction_history_id ?? `-`}</span>,
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_status,
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text) => (
        <span>
          <Tag
            style={{
              borderRadius: '12px'
            }}
            color={text === 1 ? 'success' : text === 3 || text === 3 || text === 3 ? 'yellow' : 'error'}>
            {text === 1 ? `Approved` : ``}
            {text === 2 ? `Rejected` : ``}
            {text === 3 ? `Pending` : ``}
            {text === 4 ? `Processing` : ``}
            {text === 5 ? `Waiting For Payment` : ``}
            {text === 6 ? `Payment Failed` : ``}
          </Tag>
        </span>
      ),
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_time,
      dataIndex: 'time',
      key: 'time',
      width: 200,
      render: (_, record) => <span>{record?.created_at ? convertUTCToLocalTime(record?.created_at) : '-'}</span>,
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    }
  ];

  const methodHistoryColumns = [
    {
      title: lang.label_id,
      dataIndex: 'id',
      key: 'id',
      width: 150,
      render: (text) => <span>{text}</span>,
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_previousbalance,
      dataIndex: 'previous_balance',
      key: 'previous_balance',
      width: 100,
      render: (_, record) => (
        <span>
          {record?.transaction?.player_balance_histories[0]?.previous_balance
            ? toFixedFunc(
                record?.transaction?.player_balance_histories[0]?.previous_balance,
                process.env.REACT_APP_Decimal_Points
              )
            : '-'}
        </span>
      ),
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_amount,
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      render: (text) => <span>{text ? toFixedFunc(text, process.env.REACT_APP_Decimal_Points) : ''}</span>,
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_newbalance,
      dataIndex: 'new_balance',
      key: 'new_balance',
      width: 100,
      render: (_, record) => (
        <span>
          {record?.transaction?.player_balance_histories[0]?.new_balance
            ? toFixedFunc(
                record?.transaction?.player_balance_histories[0]?.new_balance,
                process.env.REACT_APP_Decimal_Points
              )
            : '-'}
        </span>
      ),
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_type,
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (_, record) => (
        <span>
          <Tag
            style={{
              borderRadius: '12px'
            }}
            color={record?.transaction?.isWithdraw ? 'error' : 'success'}>
            {record?.transaction?.isWithdraw ? `Withdraw` : `Deposit`}
          </Tag>
        </span>
      ),
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_accountname,
      dataIndex: 'account_name',
      key: 'account_name',
      width: 150,
      render: (_, record) => <span>{record?.payment_method?.account_name ?? `-`}</span>,
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_actionby,
      dataIndex: 'action_by',
      key: 'action_by',
      width: 200,
      render: (_, record) => <span>{record?.transaction?.action_by?.name ?? `-`}</span>,
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_createddate,
      dataIndex: 'created_date',
      key: 'created_date',
      width: 200,
      render: (_, record) => <span>{record?.created_at ? convertUTCToLocalTime(record?.created_at) : '-'}</span>,
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_status,
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text) => (
        <span>
          <Tag
            style={{
              borderRadius: '12px'
            }}
            color={
              text === 1
                ? 'success'
                : text === 3 || text === 4 || text === 5
                ? 'warning'
                : text === 2 || text === 6
                ? 'error'
                : 'default'
            }>
            {text === 1 && `Approved`}
            {text === 2 && `Rejected`}
            {text === 3 && `Pending`}
            {text === 4 && `Processing`}
            {text === 5 && `Waiting For Payment`}
            {text === 6 && `Payment Failed`}
          </Tag>
        </span>
      ),
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    },
    {
      title: lang.label_note,
      dataIndex: 'note',
      key: 'note',
      width: 200,
      render: (_, record) => <span>{record?.payment_method?.remark ?? `-`}</span>,
      onHeaderCell: () => ({
        style: { backgroundColor: '#f2f6f9', fontWeight: 500, fontSize: '14px' }
      })
    }
  ];

  const items = [
    {
      key: 1,
      label: 'Player Balance History',
      children: (
        <Flex
          style={{
            maxHeight: '200px',
            overflowY: 'auto',
            width: '100%'
          }}>
          <Table
            scroll={{ x: 'max-content' }}
            style={{ marginTop: '10px', border: '1px solid #d5d5d5', borderRadius: '10px', width: '100%' }}
            columns={balanceHistoryColumns}
            loading={loading}
            dataSource={balanceHistoryData?.length > 0 ? balanceHistoryData : []}
            bordered
            pagination={false}
            rowKey={'id'}
          />
        </Flex>
      )
    },
    {
      key: 2,
      label: 'Payment Method History',
      children: (
        <Flex
          style={{
            maxHeight: '200px',
            overflowY: 'auto',
            width: '100%'
          }}>
          <Table
            scroll={{ x: 'max-content' }}
            style={{ marginTop: '10px', border: '1px solid #d5d5d5', borderRadius: '10px', width: '100%' }}
            columns={methodHistoryColumns}
            loading={loading}
            dataSource={methodHistoryData}
            bordered
            pagination={false}
            rowKey={'id'}
          />
        </Flex>
      )
    }
  ];

  useEffect(() => {
    if (isModalOpen) {
      if (flag) {
        getData({ flag: flag });
      }
    }
  }, [currentPage, isModalOpen, flag]);

  return (
    <Modal
      centered
      width="1200px"
      title="Deposit Request Details"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
      afterClose={() => setFlag(1)}
      style={{ position: 'relative' }}>
      {loading && (
        <div style={{ position: 'absolute', height: '100%', width: '100%', top: 0, left: '-10', paddingTop: '20%' }}>
          <Loader size="large" />
        </div>
      )}
      <Flex className={styles.divStyle}>
        <Flex className={styles.headerStyle}>Player Information</Flex>
        <Divider
          style={{
            margin: '0px 0px',
            backgroundColor: '#d5d5d5'
          }}
        />
        <Flex className={styles.contentDiv}>
          <Flex
            className={styles.childDiv}
            style={{
              borderRight: '1px solid #d5d5d5'
            }}>
            <Flex>
              <Typography className={styles.typographyStyles}>{lang.label_username}</Typography>
              <Typography className={styles.typographyStylesOther}>{details?.user_name}</Typography>
            </Flex>
            <Divider
              style={{
                margin: '0px 0px',
                backgroundColor: '#d5d5d5'
              }}
            />
            <Flex>
              <Typography className={styles.typographyStyles}>{lang.label_first_deposit_date}</Typography>
              <Typography className={styles.typographyStylesOther}>
                {details?.first_deposit_date ? convertUTCToLocalTime(details?.first_deposit_date) : '-'}
              </Typography>
            </Flex>
            <Divider
              style={{
                margin: '0px 0px',
                backgroundColor: '#d5d5d5'
              }}
            />
            <Flex>
              <Typography
                style={{
                  borderBottomLeftRadius: '10px'
                }}
                className={styles.typographyStyles}>
                {lang.label_group_level}
              </Typography>
              <Typography className={styles.typographyStylesOther}>{details?.group_level}</Typography>
            </Flex>
          </Flex>
          <Flex className={styles.childDiv}>
            <Flex>
              <Typography className={styles.typographyStyles}>{lang.label_real_name}</Typography>
              <Typography className={styles.typographyStylesOther}>{details?.name}</Typography>
            </Flex>
            <Divider
              style={{
                margin: '0px 0px',
                backgroundColor: '#d5d5d5'
              }}
            />
            <Flex>
              <Typography className={styles.typographyStyles}>{lang.label_agent}</Typography>
              <Typography className={styles.typographyStylesOther}>{details?.agent?.unique_code ?? `-`}</Typography>
            </Flex>
            <Divider
              style={{
                margin: '0px 0px',
                backgroundColor: '#d5d5d5'
              }}
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex className={styles.divStyle} style={{ marginTop: '20px' }}>
        <Flex className={styles.headerStyle}>Deposit Details</Flex>
        <Divider
          style={{
            margin: '0px 0px',
            backgroundColor: '#d5d5d5'
          }}
        />
        <Flex className={styles.contentDiv}>
          <Flex
            className={styles.childDiv}
            style={{
              borderRight: '1px solid #d5d5d5'
            }}>
            <Flex>
              <Typography className={styles.typographyStyles}>{lang.label_id}</Typography>
              <Typography className={styles.typographyStylesOther}>{details?.deposit_id}</Typography>
            </Flex>
            <Divider
              style={{
                margin: '0px 0px',
                backgroundColor: '#d5d5d5'
              }}
            />
            <Flex>
              <Typography className={styles.typographyStyles}>{lang.label_amount}</Typography>
              <Typography className={styles.typographyStylesOther}>
                {details?.deposit_amount
                  ? toFixedFunc(details?.deposit_amount, process.env.REACT_APP_Decimal_Points)
                  : `0.00`}
              </Typography>
            </Flex>
            <Divider
              style={{
                margin: '0px 0px',
                backgroundColor: '#d5d5d5'
              }}
            />
            <Flex>
              <Typography className={styles.typographyStyles}>{lang.label_company_payment_method}</Typography>
              <Typography className={styles.typographyStylesOther}>{details?.payment_method}</Typography>
            </Flex>
            <Divider
              style={{
                margin: '0px 0px',
                backgroundColor: '#d5d5d5'
              }}
            />
            <Flex>
              <Typography className={styles.typographyStyles}>{lang.label_status}</Typography>
              <Typography
                className={styles.typographyStylesOther}
                style={{
                  color:
                    details?.deposit_status === 1
                      ? 'green'
                      : details?.deposit_status === 3 || details?.deposit_status === 4 || details?.deposit_status === 5
                      ? '#FFC36A'
                      : details?.deposit_status === 2 || details?.deposit_status === 6
                      ? 'red'
                      : 'default'
                }}>
                {details?.deposit_status === 1 && `Approved`}
                {details?.deposit_status === 2 && `Rejected`}
                {details?.deposit_status === 3 && `Pending`}
                {details?.deposit_status === 4 && `Processing`}
                {details?.deposit_status === 5 && `Waiting For Payment`}
                {details?.deposit_status === 6 && `Payment Failed`}
              </Typography>
            </Flex>
            <Divider
              style={{
                margin: '0px 0px',
                backgroundColor: '#d5d5d5'
              }}
            />
            <Flex>
              <Typography className={styles.typographyStyles}>{lang.label_customer_message}</Typography>
              <Typography className={styles.typographyStylesOther}>{details?.customer_msg ?? `-`}</Typography>
            </Flex>
            <Divider
              style={{
                margin: '0px 0px',
                backgroundColor: '#d5d5d5'
              }}
            />
            <Flex>
              <Typography
                style={{
                  borderBottomLeftRadius: '10px'
                }}
                className={styles.typographyStyles}>
                {lang.label_deposit_ip}
              </Typography>
              <Typography className={styles.typographyStylesOther}>{details?.deposit_ip}</Typography>
            </Flex>
          </Flex>
          <Flex className={styles.childDiv}>
            <Flex>
              <Typography className={styles.typographyStyles}>{lang.label_referenceid}</Typography>
              <Typography className={styles.typographyStylesOther}>
                {details?.deposit_reference ?? `-`}
                {details?.deposit_reference && (
                  <Popover
                    content={
                      <div style={{ cursor: 'default' }} onClick={hide}>
                        Copied Successfully!
                      </div>
                    }
                    // title="Copied Successfully!"
                    trigger="click"
                    open={popoverOpen}
                    onOpenChange={() => handleOpenChange(details?.deposit_reference ?? `-`)}>
                    <FaCopy style={{ cursor: 'pointer', marginLeft: '4px' }} color="gray" />
                  </Popover>
                )}
              </Typography>
            </Flex>
            <Divider
              style={{
                margin: '0px 0px',
                backgroundColor: '#d5d5d5'
              }}
            />
            <Flex>
              <Typography className={styles.typographyStyles}>{lang.label_currency}</Typography>
              <Typography className={styles.typographyStylesOther}>{details?.deposit_currency}</Typography>
            </Flex>
            <Divider
              style={{
                margin: '0px 0px',
                backgroundColor: '#d5d5d5'
              }}
            />
            <Flex>
              <Typography className={styles.typographyStyles}>{lang.label_createdtime}</Typography>
              <Typography className={styles.typographyStylesOther}>
                {details?.deposit_created_time ? convertUTCToLocalTime(details?.deposit_created_time) : '-'}
              </Typography>
            </Flex>
            <Divider
              style={{
                margin: '0px 0px',
                backgroundColor: '#d5d5d5'
              }}
            />
            <Flex>
              <Typography className={styles.typographyStyles}>{lang.label_finance_remark}</Typography>
              <Typography className={styles.typographyStylesOther}>{details?.deposit_remark}</Typography>
            </Flex>
            <Divider
              style={{
                margin: '0px 0px',
                backgroundColor: '#d5d5d5'
              }}
            />
            <Flex>
              <Typography className={styles.typographyStyles}>{lang.label_transactiontype}</Typography>
              <Typography className={styles.typographyStylesOther}>{details?.transaction_type}</Typography>
            </Flex>
            <Divider
              style={{
                margin: '0px 0px',
                backgroundColor: '#d5d5d5'
              }}
            />
          </Flex>
        </Flex>
      </Flex>

      <Tabs activeKey={flag} style={{ marginTop: '20px' }} defaultActiveKey={flag} items={items} onChange={onChange} />

      {flag === 1 ? (
        balanceHistoryData?.length > 0 ? (
          <PaginationComp paginationConfig={paginationConfig} />
        ) : null
      ) : flag === 2 ? (
        methodHistoryData?.length > 0 ? (
          <PaginationComp paginationConfig={paginationConfig} />
        ) : null
      ) : null}
    </Modal>
  );
}
