import React from 'react'

const StatusChip = ({color, chipBkg, text}) => {
  
  const chipStyle = {
    display: 'flex', 
    alignItems: 'center', 
    gap:'5px', 
    fontSize: '12px', 
    width: 'fit-content', 
    padding: '2px 8px', 
    borderRadius: '12px',
    color: color,
    backgroundColor: chipBkg 
  }

  const chipDot = {
    height: '6px',
    width: '6px',
    borderRadius: '20px',
    backgroundColor: color
  }

  return (
    <div style={chipStyle}>
      <div style={chipDot}></div>
      {text ? text : 'Status Chip'}
    </div>
  )
}

export default StatusChip