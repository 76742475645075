import React from "react";
import { Modal } from "antd";
import MiniText from "./MiniText";

const CustomModal = ({
  visible,
  onCancel,
  title,
  subTitle,
  content,
  footer,
  modalStyle,
  titleStyle,
  contentStyle,
  footerStyle,
  customWidth
}) => {
  const modalStyles = {
    // Default modal styles
    // You can adjust these as per your project's design system
    // width: '500px',
    ...modalStyle,
  };

  const titleStyles = {
    fontFamily: "Roboto ",
    fontWeight: "medium ",
    fontStyle: "normal ",
    fontSize: "14px",
    lineheight: "26px ",
    letterSpacing: "0px ",
    color: "#004A7F ",
    margin: "0 ",
    padding: "0 ",
    height: "auto",
    width: "auto",
    fontWeight:"900",
    ...titleStyle,
  };
  const titleStylesMain = {
    fontFamily: "Roboto ",
    fontWeight: "medium ",
    fontStyle: "normal ",
    fontSize: "20px",
    lineheight: "26px ",
    letterSpacing: "0px ",
    color: "#004A7F ",
    margin: "0 ",
    padding: "0 ",
    height: "auto",
    width: "auto",
  };

  const contentStyles = {
    // Default content styles
    // You can adjust these as per your project's design system
    // padding: '20px',
    ...contentStyle,
  };

  const footerStyles = {
    // Default footer styles
    // You can adjust these as per your project's design system
    // textAlign: 'right',
    ...footerStyle,
  };

  return (
    <>
    { visible && <Modal
      width={customWidth ? customWidth : 850}
      visible={visible}
      onCancel={onCancel}
      title={
        <div>
          <p style={titleStyles}> {title}</p>
          <MiniText>{subTitle}</MiniText>
        </div>
      }
      footer={<div style={footerStyles}>{footer}</div>}
    // style={modalStyles}
    centered
    >
      <div style={contentStyles}>{content}</div>
    </Modal>}
    </>
  );
};

export default CustomModal;
