import React, { useContext, useState } from 'react';
import { Input, Button, Row, Col, message } from 'antd';
import LangContext from '../../../../helps/contexts/lang-context';
import TextArea from 'antd/es/input/TextArea';

const EditCommission = ({ rowData, id, editCommissionVisible, onCancel, setEditCommissionVisible }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [amount, setAmount] = useState();
  const [remarks, setRemarks] = useState();

  // Function to handle form submission
  const handleConfirm = () => {
    // Add your form submission logic here
    setEditCommissionVisible(false);
  };

  //on submit form for sending request
  const onSubmit = async (e) => {
    // try {
    //   e.preventDefault();
    //   const path = `/user/${userid}/role/assign`;
    //   // sending request
    //   const res = await PostApi.PostApiRequest(path, {
    //     role_id: roleselect
    //   });
    //   //if success
    //   if (res.status === 200) {
    //     message.success(lang.label_assignnewrolesuccess);
    //     onCancel();
    //     getUsers();
    //     setRoleselect(null);
    //   } else {
    //     message.error(lang.label_assignnewrolefail);
    //   }
    // } catch (e) {
    //   message.error(e.mesage);
    // }
  };

  return (
    <div
      style={{
        width: '99%',
        height: '230px',
        borderRadius: '3px',
        justifyContent: 'space-evenly'
      }}>
      <form onSubmit={onSubmit}>
        <Row>
          <Col span={24}>
            <label style={{ paddingBottom: '3px' }}>
              Amount
              <span style={{ color: 'red' }}>*</span>
            </label>{' '}
            <br />
            <Input
              defaultValue="0"
              style={{
                width: '94%',
                borderRadius: '3px',
                marginBottom: '5px',
                marginTop: '5px'
              }}
              type="number"
              placeholder="0"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <label style={{ paddingBottom: '3px' }}>
              Remarks
              <span style={{ color: 'red' }}>*</span>
            </label>{' '}
            <br />
            <TextArea
              // defaultValue="0"
              style={{
                width: '94%',
                borderRadius: '3px',
                marginBottom: '5px',
                marginTop: '5px'
              }}
              type="text"
              placeholder="Characters length must be in valid range (1-50)"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Col>
        </Row>

        <div
          style={{
            marginTop: '20px'
          }}>
          {' '}
          <Button
            style={{
              borderRadius: '3px',
              backgroundColor: '#004A7F',
              color: 'white',
              position: 'absolute',
              right: '20%',
              top: '80%'
            }}
            htmlType="submit">
            {lang.label_confirm}
          </Button>
          <Button
            style={{
              borderRadius: '3px',
              color: '#004A7F',
              position: 'absolute',
              right: '8%',
              top: '80%'
            }}
            onClick={onCancel}>
            {lang.label_cancel}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditCommission;
