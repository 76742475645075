import AgentListTable from './AgentListTable';
import { Card, Row, Col, Button, Input, Pagination, Modal, message, Select } from 'antd';

import { useContext, useState, useEffect } from 'react';
import LangContext from '../../../helps/contexts/lang-context';

import GetApi from '../../../helps/contexts/Api/GetApi';
import '../../../styles/ButtonStyle.css';
import AddAgentForm from './AddAgentForm';
import { useNavigate } from 'react-router-dom';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import Spacer from '../../../components/HelperComponents/Spacer';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';
import { TabContext } from '../../../helps/contexts/TabContext';
import SearchIcon from '../../../images/Search.png';
import ResetIcon from '../../../images/Reset.png';
import AgentListTableNew from './AgentListTableNew';
import SubHeading from '../../../components/HelperComponents/SubHeading';
const { Option } = Select;

const AgentListNew = () => {
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();

  const lang = ctx.lang;
  const nav = useNavigate();
  //styles

  const inputStyle = {
    width: '250px',
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };

  //states

  const [searchtext, setSearchtext] = useState('');
  const [searchSup, setSearchSup] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [Data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isadd, setIsadd] = useState(false);
  const { path } = useContext(TabContext);

  // new filter
  const [searchValue, setSearchValue] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const [agentStatus, setAgentStatus] = useState('');
  const [settleCycle, setSettleCycle] = useState('');
  const [agentLevel, setAgentLevel] = useState('');

  const handleSearch = () => {
    setActivepage1(1);
    setLoading(true);
    // Perform search action here
  };

  const timeZoneOptions = [
    { label: 'UTC-5:00', value: 'utc-5' },
    { label: 'UTC+2:00', value: 'utc+2' },
    { label: 'UTC+5:30', value: 'utc+5:30' }
    // Add more time zones
  ];

  const agentStatusOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' }
  ];

  const settleCycleOptions = [
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' }
  ];

  const optionsSearchBy = [
    { label: 'Agent ID', value: 'agentId' },
    { label: 'Update By', value: 'updateBy' },
    { label: 'Real Name', value: 'realName' },
    { label: 'Mobile', value: 'mobile' }
  ];

  //onreset
  const onReset = async () => {
    setActivepage1(1);
    setLoading(true);
    setSearchSup('');
    setSearchtext('');
    const path = '/admin/agent/get/all';
    const params = { page: 1 };
    setLoading(true);
    //sending api request
    const res = await GetApi.sendApiRequest(path, null, params);
    if (res?.data && res?.data?.length) {
      const finalArray = res?.data?.map((agent, index) => ({
        key: index,
        id: agent.id,
        currency: agent && agent?.currency && agent?.currency,
        name: agent && agent.user && agent.user.name ? agent.user.name : '',
        username: agent && agent.user && agent.user.user_name ? agent.user.user_name : '',
        phone: agent && agent.user && agent.user.phone ? agent.user.phone : '',
        created_by_admin: agent?.user?.latest_login_history?.created_by_admin,
        superioragent:
          agent && agent.senior_agent && agent.senior_agent.user && agent.senior_agent.user.user_name
            ? agent.senior_agent.user.user_name
            : '',
        uniquecode: agent && agent.unique_code ? agent.unique_code : '',
        loginip:
          agent && agent.user && agent.user.latest_login_history && agent.user.latest_login_history.ip
            ? agent.user.latest_login_history.ip
            : '-',
        logintime: agent && agent.user && agent.user.latest_login_history && agent.user.latest_login_history.created_at,
        registerip:
          agent && agent.user && agent.user.signup_history && agent.user.signup_history.ip
            ? agent.user.signup_history.ip
            : '-',
        registertime: agent && agent.user && agent.user.signup_history && agent.user.signup_history.created_at
      }));
      setData(finalArray);
      setCurrentpage(res?.current_page);
      setTotal(res?.total);
      setPerpage(res?.per_page);
      setNextPage(res?.next_page_url);
    } else {
      setData([]);
      setTotal(0);
    }

    setLoading(false);
  };

  //status dropdown
  //on submit of filters on table values
  const onSubmit = (e) => {
    e.preventDefault();
    setActivepage1(1);
    fetchAgents();
  };
  // on click of a page in pagination component

  const onChangepage = (page) => {
    setActivepage1(page);
    fetchAgents(page);
  };
  const showModal = () => {
    setIsadd(true);
    setVisible(true);
  };

  const handleCancel = (e) => {
    setIsadd(false);
    setVisible(false);
  };

  // Fetching api
  const fetchAgents = async (customPage = null) => {
    try {
      const path = '/admin/agent/get/all';
      const params = {};
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      if (searchtext) params['search'] = searchtext;
      if (searchSup) params['senior_agent'] = searchSup;

      setLoading(true);
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      // console.log(res)
      if (res?.data && res?.data?.length) {
        const finalArray = res?.data?.map((agent, index) => ({
          key: index,
          id: agent.id,
          user_id: agent.user_id,
          currency: agent && agent?.currency && agent?.currency,
          name: agent && agent.user && agent.user.name ? agent.user.name : '',
          username: agent && agent.user && agent.user.user_name ? agent.user.user_name : '',
          phone: agent && agent.user && agent.user.phone ? agent.user.phone : '',
          created_by_admin: agent?.user?.latest_login_history?.created_by_admin,
          superioragent:
            agent && agent.senior_agent && agent.senior_agent.user && agent.senior_agent.user.user_name
              ? agent.senior_agent.user.user_name
              : '',
          uniquecode: agent && agent.unique_code ? agent.unique_code : '',
          loginip:
            agent && agent.user && agent.user.latest_login_history && agent.user.latest_login_history.ip
              ? agent.user.latest_login_history.ip
              : '-',
          logintime:
            agent && agent.user && agent.user.latest_login_history && agent.user.latest_login_history.created_at,
          registerip:
            agent && agent.user && agent.user.signup_history && agent.user.signup_history.ip
              ? agent.user.signup_history.ip
              : '-',
          registertime: agent && agent.user && agent.user.signup_history && agent.user.signup_history.created_at,
          agent_type: agent && agent.agent_type ? agent.agent_type : '',
          agent_type_name: agent && agent.agent_type_name ? agent.agent_type_name : '',
          balance: agent && agent.wallet ? agent.wallet.balance : '',
          credit_line: agent && agent.credit_wallet ? agent.credit_wallet.credit_balance : '',
          max_credit: agent && agent.credit_wallet ? agent.credit_wallet.max_limit : ''
        }));
        setData(finalArray);
        setCurrentpage(res?.current_page);
        setTotal(res?.total);
        setPerpage(res?.per_page);
        setNextPage(res?.next_page_url);
      } else {
        setData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }

      Object.values(e.data.message).map((errors) => message.error(errors));
    }
  };
  useEffect(() => {
    fetchAgents();
  }, []);

  useEffect(() => {
    if (path === '/agent-management/agent-list-new') {
      fetchAgents();
    }
  }, [path]);
  return (
    <div>
      <HeaderCardDetails />

      <form onSubmit={onSubmit} style={{ padding: '20px' }}>
        <div>
          <Card
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ color: '#4F5057' }}>Filters</span>
                {hasPermission('Create Agent') && (
                  <Button type="secondary" className="btnStyle" onClick={showModal}>
                    {lang.label_createagent}
                  </Button>
                )}
              </div>
            }
            style={{
              borderRadius: '3px'
            }}>
            <div>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center'
                }}>
                {/* <Col
                  span={18}
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <label style={{ marginRight: '1rem' }}>
                    {lang.label_username}/ {lang.label_uniquecode}
                  </label>
                  <Input
                    style={inputStyle}
                    onChange={(e) => {
                      setSearchtext(e.target.value);
                    }}
                    name="search"
                    type="text"
                    value={searchtext}
                  />
                  <label style={{ marginLeft: '0.5rem', marginRight: '1rem' }}>Superior Agent</label>
                  <Input
                    style={inputStyle}
                    onChange={(e) => {
                      setSearchSup(e.target.value);
                    }}
                    name="search"
                    type="text"
                    value={searchSup}
                  />

                  <Button
                    type="secondary"
                    style={{
                      margin: '0.6rem',
                      width: '15%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly'
                    }}
                    htmlType="submit"
                    className="btnStyle"
                    onClick={() => {
                      setActivepage1(1);
                      setLoading(true);
                    }}>
                    <img src={SearchIcon} alt="search" />
                    {lang.label_search}
                  </Button>

                  <Button
                    className="btnReset"
                    htmlType="reset"
                    onClick={onReset}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly'
                    }}>
                    <img src={ResetIcon} alt="reset" />
                    {lang.label_reset}
                  </Button>
                </Col> */}

                {/* <Card title={lang.label_filters} style={{ borderRadius: '3px' }}> */}
                <Row gutter={[16, 16]} style={{ width: '100%' }}>
                  {/* Label and Dropdown for "Searched by" */}
                  <Col xs={24} sm={6} lg={4}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <SubHeading extraStyle={{ fontSize: '14px', fontWeight: 500 }}>Searched by</SubHeading>
                      <Select
                        // showSearch
                        placeholder="Select Option"
                        // value={searchValue}
                        onChange={(value) => setSearchValue(value)}
                        style={{ width: '100%' }}
                        optionFilterProp="children">
                        {optionsSearchBy.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Col>

                  {/* Input Field */}
                  <Col xs={24} sm={6} lg={4}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <SubHeading extraStyle={{ fontSize: '14px', fontWeight: 500 }}>Search Input</SubHeading>
                      <Input
                        placeholder="Enter search value"
                        // value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        style={{ width: '100%' }}
                      />
                    </div>
                  </Col>

                  {/* Timezone Dropdown */}
                  <Col xs={24} sm={6} lg={4}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <SubHeading extraStyle={{ fontSize: '14px', fontWeight: 500 }}>Timezone</SubHeading>
                      <Select
                        placeholder="Select Timezone"
                        // value={timeZone}
                        onChange={(value) => setTimeZone(value)}
                        style={{ width: '100%' }}>
                        {timeZoneOptions.map((tz) => (
                          <Option key={tz.value} value={tz.value}>
                            {tz.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Col>

                  {/* Agent Level Dropdown */}
                  <Col xs={24} sm={6} lg={4}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <SubHeading extraStyle={{ fontSize: '14px', fontWeight: 500 }}>Agent Level</SubHeading>
                      <Select
                        placeholder="Select Agent Level"
                        style={{ width: '100%' }}
                        onChange={(value) => setAgentLevel(value)} 
                      >
                 
                        <Option value="level1">Level 1</Option>
                        <Option value="level2">Level 2</Option>
                        <Option value="level3">Level 3</Option>
                      </Select>
                    </div>
                  </Col>

                  {/* Agent Status Dropdown */}
                  <Col xs={24} sm={6} lg={4}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <SubHeading extraStyle={{ fontSize: '14px', fontWeight: 500 }}>Agent Status</SubHeading>
                      <Select
                        placeholder="Agent Status"
                        // value={agentStatus}
                        onChange={(value) => setAgentStatus(value)}
                        style={{ width: '100%' }}>
                        {agentStatusOptions.map((status) => (
                          <Option key={status.value} value={status.value}>
                            {status.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Col>

                  {/* Settle Cycle Dropdown */}
                  <Col xs={24} sm={6} lg={4}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <SubHeading extraStyle={{ fontSize: '14px', fontWeight: 500 }}>Settle Cycle</SubHeading>
                      <Select
                        placeholder="Settle Cycle"
                        // value={settleCycle}
                        onChange={(value) => setSettleCycle(value)}
                        style={{ width: '100%' }}>
                        {settleCycleOptions.map((cycle) => (
                          <Option key={cycle.value} value={cycle.value}>
                            {cycle.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Col>
                </Row>

                {/* Buttons */}
                <Row style={{ marginTop: '1rem', justifyContent: 'flex-start' }}>
                  <Button
                    type="secondary"
                    style={{
                      margin: '0.6rem',
                      width: '15%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly'
                    }}
                    htmlType="submit"
                    className="btnStyle"
                    onClick={handleSearch}>
                    <img src={SearchIcon} alt="search" />
                    {lang.label_search}
                  </Button>

                  <Button
                    className="btnReset"
                    htmlType="reset"
                    onClick={onReset}
                    style={{
                      display: 'flex',
                      margin: '0.6rem',
                      alignItems: 'center',
                      justifyContent: 'space-evenly'
                    }}>
                    <img src={ResetIcon} alt="reset" />
                    {lang.label_reset}
                  </Button>
                </Row>
                <Col
                  span={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                  }}>
                  <div>
                    {isadd && isadd ? (
                      <Modal
                        title="Add Agent"
                        open={visible}
                        footer={null}
                        width={700}
                        closable={true}
                        onCancel={handleCancel}>
                        <AddAgentForm handleCancel={handleCancel} data={Data} fetchAgents={fetchAgents} />
                      </Modal>
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>

          <Spacer />
          <AgentListTableNew data={Data} fetchAgents={fetchAgents} loading={loading} />
          <Spacer />
          {/* Pagination */}
          {Data?.length > 0 && (
            <Pagination
              defaultCurrent={1}
              current={activepage}
              onChange={onChangepage}
              total={total}
              responsive={true}
              pageSize={perpage}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default AgentListNew;
