import axios from 'axios';
import {constants} from './constants';
const login = async (username, password,otp) => {
   const  path="/admin/login"
    const url = `${constants.BASE_URL1}`+`${path}`;
    return (
        axios.post(url, {
      user_name: username,
      password: password,
      otp:otp
    })
    .then((response) => {
   
      return response;
    }).catch((e) => Promise.reject(e.response.data))
    )
  };

  export default {login};
  //'http://18.139.206.13/api/admin/login';