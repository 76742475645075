import { Button, Input, message, Modal, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import TextArea from 'antd/es/input/TextArea';
import PutApi from '../../../helps/contexts/Api/PutApi';

export default function UpdateCreditForm({ updateCreditOpen, setUpdateCreditOpen, id, fetchAgents }) {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [tabIndex, setTabIndex] = useState('1');
  const [amount, setAmount] = useState('');
  const [maxCredit, setMaxCredit] = useState('');
  const [balanceRemark, setBalanceRemark] = useState('');

  const [loading, setLoading] = useState(false);

  const onChange = (key) => {
    setTabIndex(key);
  };

  const items = [
    {
      key: '1',
      label: 'Add Credit',
      children: (
        <div>
          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_credit_line}
          </div>
          <Input
            style={{
              marginBottom: '10px'
            }}
            value={amount}
            // defaultValue={Number(balance).toFixed(2)}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            type="number"
          />

          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_max_credit}
          </div>
          <Input
            style={{
              marginBottom: '10px'
            }}
            value={maxCredit}
            // defaultValue={Number(balance).toFixed(2)}
            onChange={(e) => {
              setMaxCredit(e.target.value);
            }}
            type="number"
          />

          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_remark}
          </div>
          <TextArea
            value={balanceRemark}
            style={{ width: '100%', marginBottom: '2rem' }}
            onChange={(e) => {
              setBalanceRemark(e.target.value);
            }}
          />
        </div>
      )
    },
    {
      key: '2',
      label: 'Remove Credit',
      children: (
        <div>
          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_credit_line}
          </div>
          <Input
            style={{
              marginBottom: '10px'
            }}
            value={amount}
            // defaultValue={Number(balance).toFixed(2)}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            type="number"
          />
          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_max_credit}
          </div>
          <Input
            style={{
              marginBottom: '10px'
            }}
            value={maxCredit}
            // defaultValue={Number(balance).toFixed(2)}
            onChange={(e) => {
              setMaxCredit(e.target.value);
            }}
            type="number"
          />
          <div
            style={{
              fontSize: '12px',
              marginBottom: '6px'
            }}>
            {lang.label_remark}
          </div>
          <TextArea
            value={balanceRemark}
            style={{ width: '100%', marginBottom: '2rem' }}
            onChange={(e) => {
              setBalanceRemark(e.target.value);
            }}
          />
        </div>
      )
    }
  ];

  const updateBalance = async () => {
    setLoading(true);
    const path = `/admin/agent/credit`;
    try {
      const res = await PutApi.PutApiRequest(path, {
        agent_id: id,
        credit_amount: amount,
        is_withdraw: tabIndex === '1' ? false : true,
        remark: balanceRemark,
        max_limit: maxCredit
      });

      if (res && res?.status === true) {
        message.success(lang.label_sucessupdatebalance);

        setTabIndex('1');
        setAmount('');
        setMaxCredit('');
        setBalanceRemark('');
        setUpdateCreditOpen(false);

        fetchAgents();
      } else {
        message.error(lang.label_updatebalanceerror);
      }
    } catch (error) {
      console.error('Failed to update balance:', error);
      message.error(lang.label_updatebalanceerror);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={lang.label_update_credit}
      open={updateCreditOpen}
      onOk={() => {
        setTabIndex('1');
        setAmount('');
        setMaxCredit('');
        setBalanceRemark('');
        setUpdateCreditOpen(false);
      }}
      onCancel={() => {
        setTabIndex('1');
        setAmount('');
        setMaxCredit('');
        setBalanceRemark('');
        setUpdateCreditOpen(false);
      }}
      footer={null}
      width={500}>
      <Tabs defaultActiveKey={tabIndex} items={items} activeKey={tabIndex} onChange={onChange} centered />
      <Button
        loading={loading}
        disabled={amount?.length > 0 ? false : true}
        onClick={updateBalance}
        style={{
          float: 'right',
          backgroundColor: '#004A7F',
          color: 'white',
          marginTop: '-1.2rem'
        }}>
        {lang.label_submit}
      </Button>
    </Modal>
  );
}
