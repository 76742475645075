import React from 'react';
import { Input, Button, Row, Col, Card } from 'antd';

const CostInfo = ({ formData, handleInputChange, nextTab, prevTab }) => {
  return (
    <>
      <Card
        title="Cost Info"
        style={{
          borderRadius: '3px',
          marginTop: '30px',
          marginRight: '10px',
          marginRight: '40px'
        }}>
        <div style={{ padding: '20px' }}>
          {/* First row with five inputs */}
          <Row gutter={[16, 16]} style={{ paddingBottom: '10px' }}>
            <Col xs={24} sm={12} md={8} lg={4}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ fontWeight: 500 }}>Deposit Bonus %</label>
                <Input
                  name="depositBonus"
                  placeholder="Deposit Bonus %"
                  value={formData.depositBonus || ''}
                  onChange={handleInputChange}
                />
              </div>
            </Col>

            <Col xs={24} sm={12} md={8} lg={4}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ fontWeight: 500 }}>Other Bonus %</label>
                <Input
                  name="otherBonus"
                  placeholder="Other Bonus %"
                  value={formData.otherBonus || ''}
                  onChange={handleInputChange}
                />
              </div>
            </Col>

            <Col xs={24} sm={12} md={8} lg={4}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ fontWeight: 500 }}>Rebate %</label>
                <Input
                  name="rebate"
                  placeholder="Rebate %"
                  value={formData.rebate || ''}
                  onChange={handleInputChange}
                />
              </div>
            </Col>

            <Col xs={24} sm={12} md={8} lg={4}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ fontWeight: 500 }}>Deposit Fee %</label>
                <Input
                  name="depositFee"
                  placeholder="Deposit Fee %"
                  value={formData.depositFee || ''}
                  onChange={handleInputChange}
                />
              </div>
            </Col>

            <Col xs={24} sm={12} md={8} lg={4}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ fontWeight: 500 }}>Withdraw Fee %</label>
                <Input
                  name="withdrawFee"
                  placeholder="Withdraw Fee %"
                  value={formData.withdrawFee || ''}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>

          {/* Second row for Win/Loss Fee Rate */}
          <Row gutter={[16, 16]} style={{ marginTop: '10px' }}>
            <Col xs={24} sm={12} md={8} lg={4}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ fontWeight: 500 }}>Win/Loss Fee Rate</label>
                <Input
                  name="winLossFeeRate"
                  placeholder="Win/Loss Fee Rate"
                  value={formData.winLossFeeRate || ''}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>
        </div>
        {/* Navigation buttons */}
      </Card>
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <Button onClick={prevTab} style={{ marginRight: '10px', width: '80px', height: '35px', borderRadius: '3px' }}>
          Previous
        </Button>
        <Button
          type="primary"
          onClick={nextTab}
          style={{
            backgroundColor: '#004A7F',
            color: 'white',
            width: '70px',
            height: '35px',
            background: '#004A7F 0% 0% no-repeat padding-box',
            borderRadius: '3px',
            opacity: '1',
            marginRight: '15px'
          }}>
          Next
        </Button>
      </div>
    </>
  );
};

export default CostInfo;
