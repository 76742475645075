import { Table } from "antd";

const WithdrawSumTable = ({ data, loading }) => {

    const columns = [
        {
          title: 'Currency',
          dataIndex: 'currencyName',
          key: 'currencyName',
        },
        {
          title: 'Total Withdraw',
          dataIndex: 'totalWithdraw',
          key: 'totalWithdraw',
        },
    ]

    return (
      <Table
        loading={loading}
        columns={columns}
        bordered
        dataSource={data}
        pagination={false}
        size="small"
      />
    );
}

export default WithdrawSumTable