import { Input, Button, Row, Col, message, Checkbox } from 'antd';
import LangContext from '../../helps/contexts/lang-context';
import { useContext, useEffect, useState } from 'react';
import PostApi from '../../helps/contexts/Api/PostApi';
import Search from 'antd/es/transfer/search';

const AddRoleForm = ({ handleCancel, checklist, getRoles }) => {
  const [checklistData, setChecklistData] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { Search } = Input;

  // form states

  const [name, setName] = useState('');
  const [checked, setChecked] = useState([]);

  // let firstList = [...checklist];
  // let secondList = [...checklist];
  // firstList.splice(0, checklist.length / 2);
  // secondList.splice(checklist.length / 2, checklist.length - 1);

  const onSearch = (value) => {
    setIsSearch(true);
    if (value) {
      let result = [];

      result = checklist.filter((data) => {
        return data.name.toLowerCase().search(value.toLowerCase()) !== -1;
      });
      setChecklistData(result);
    }
  };
  // checkbox on check an item

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };
  var checkedItems = checked;

  //form submit to backend

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      const path = `/roles/store`;
      // sending request
      const res = await PostApi.PostApiRequest(path, {
        name: name,
        array_of_permissions: checkedItems
      });

      //if success
      if (res.status === 200) {
        message.success(lang.label_roleadsuccess);
        handleCancel();
        getRoles();
      } else {
        message.error(lang.label_roleadfail);
      }
    } catch (e) {
      Object.values(e.data.message).map((errors) => message.error(errors));
    }
  };

  return (
    <div
      style={{
        width: '97%',
        height: '85%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: '3%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '-6%'
          }}>
          <Row>
            <Col span={12}>
              <label style={{ paddingBottom: '5px' }}>{lang.label_name}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <Input
                defaultValue="Role Name"
                style={{
                  width: '188%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                placeholder="Name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
          </Row>
          <br />
          <label style={{ paddingBottom: '5px' }}>
            {lang.label_permissions}
            <span style={{ color: 'red' }}>*</span>
          </label>

          <div
            style={{
              padding: '15px',
              dispaly: 'flex',
              backgroundColor: '#FAFAFA',
              width: '90%',
              height: 'auto'
            }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                padding: '20px 0'
              }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  gap: '10px'
                }}>
                <Search
                  placeholder="input search text"
                  onSearch={onSearch}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Button
                  style={{
                    borderRadius: '8px',
                    backgroundColor: 'white',
                    color: '#004A7F',
                    width: '100px'
                  }}
                  onClick={() => {
                    setIsSearch(false);
                    setSearchText('');
                  }}>
                  {lang.label_reset}
                </Button>
              </div>
            </div>{' '}
            <div style={{ textAlign: 'right', marginBottom: '20px' }}>
              <Button
                style={{
                  borderRadius: '8px',
                  backgroundColor: 'white',
                  color: '#004A7F',
                  width: '100px',
                  marginRight: '5px'
                }}
                onClick={() => {
                  setChecked(checklist.map((item) => item.name));
                }}>
                {lang.label_select_all}
              </Button>
              <Button
                style={{
                  borderRadius: '8px',
                  backgroundColor: 'white',
                  color: '#004A7F',
                  width: '100px',
                  marginLeft: '5px'
                }}
                onClick={() => {
                  setChecked([]);
                }}>
                {lang.label_deselect_all}
              </Button>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '50% 50%'
              }}>
              {!isSearch &&
                checklist.map((item, index) => (
                  <div style={{ marginRight: '2%' }}>
                    <Checkbox value={item.name} checked={checked.indexOf(item.name) > -1} onChange={handleCheck}>
                      <span key={index}>{item.name}</span>
                    </Checkbox>
                  </div>
                ))}

              {isSearch &&
                checklistData.map((item, index) => (
                  <div style={{ marginRight: '2%' }}>
                    <Checkbox value={item.name} checked={checked.indexOf(item.name) > -1} onChange={handleCheck}>
                      <span key={index}>{item.name}</span>
                    </Checkbox>
                  </div>
                ))}
            </div>
          </div>

          <br />
          <br />
          <br />

          <div>
            {' '}
            <Button
              style={{
                borderRadius: '3px',
                backgroundColor: '#004A7F',
                color: 'white',
                position: 'absolute',
                right: '20%',
                top: '92%',
                width: '100px'
              }}
              htmlType="submit">
              {lang.label_save}
            </Button>
            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                position: 'absolute',
                right: '6.5%',
                top: '92%',
                width: '100px'
              }}
              onClick={() => {
                setName('');
                setChecked([]);
                handleCancel();
              }}>
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddRoleForm;
