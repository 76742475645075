import { createContext, useEffect, useRef, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  
  const [user, setUser] = useState(null);
  const [isDepositSoundAllowed, setIsDepositSoundAllowed] = useState(() => {
    const storedValue = localStorage.getItem('isDepositSound');
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });

  const [isWithdrawSoundAllowed, setIsWithdrawSoundAllowed] = useState(() => {
    const storedValue = localStorage.getItem('isWithdrawSound');
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });
  const isDepositSoundAllowedRef = useRef(isDepositSoundAllowed);
  const isWithdrawSoundAllowedRef = useRef(isWithdrawSoundAllowed);

  // Function to toggle sound setting
  const updateSound = (type) => {
    if (type === 'deposit') {
      setIsDepositSoundAllowed(prev => {
        const newValue = !prev;
        localStorage.setItem('isDepositSound', JSON.stringify(newValue));
        return newValue;
      });
    } else {
      setIsWithdrawSoundAllowed(prev => {
        const newValue = !prev;
        localStorage.setItem('isWithdrawSound', JSON.stringify(newValue));
        return newValue;
      });
    }
  };
  // Update refs whenever state changes
  useEffect(() => {
    isDepositSoundAllowedRef.current = isDepositSoundAllowed;
    isWithdrawSoundAllowedRef.current = isWithdrawSoundAllowed;
  }, [isDepositSoundAllowed, isWithdrawSoundAllowed]);


  const updateUser = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, updateUser, updateSound, isDepositSoundAllowed, isWithdrawSoundAllowed, isWithdrawSoundAllowedRef, isDepositSoundAllowedRef }}>
      {children}
    </UserContext.Provider>
  );
};