import React, { useContext, useState, useEffect } from 'react';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import BasicInformation  from './BasicInformation';
import { CostAndPt } from './CostAndPt';
import { PlayerAnalysis } from './PlayerAnalysis';
import styles from "../agentProfile/agentprofile.module.css"
import { Card, Row, Col, Button, Input, Pagination, Modal, message } from 'antd';

const Agentprofile = () => {

    const [tabValue, setTableValue] = useState(1);

    const TabData = [
        { id: 1, name: 'Basic Information' },
        { id: 2, name: 'Cost and PT' },
        { id: 3, name: 'Player Analysis' },
      ];
  return (
    <>
      <HeaderCardDetails />

      <div style={{ padding: '20px' }}>
        <div>
        <Card 
          style={{
            width : "100%",
            height: "50px",
            borderRadius: '3px'
          }}>
          <Row
             style={{
                height: '100%',
                paddingLeft : '0px'
              }}>
            <Col
              span={24}
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%'
              }}>
              <span
                style={{
                  width: '100%',
                  textAlign: 'left',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '19px',
                  lineHeight: '23px',
                  fontFamily: 'Roboto',
                  letterSpacing: '0px',
                  color: '#003049',
                  opacity: 1
                }}>
                {`Agent Profile : ${'UserName'}`}
              </span>
            </Col>
          </Row>
        </Card>
        </div>
        <div
          className="ProfileInfo"
          style={{
            // marginTop : '20px',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '1rem 0rem',
            // backgroundColor: 'white',
            // overflow: 'auto',
            // gap: 30,
            borderRadius: '3px',
            position: 'relative'
          }}>
         <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', gap: 20, borderBottom: '1px solid #DDDDDD', position: 'relative' }}>
              {TabData?.map((child, index) => (
                <div
                  key={child.id}
                  style={{
                    borderBottom: child.id === tabValue ? '3px solid #003049' : 'none',
                    cursor: 'pointer',
                    position: 'relative',
                    zIndex: 1,
                    top: 2,
                    color: child.id === tabValue ? '#003049' : '#AAAAAA'
                  }}
                  onClick={() => setTableValue(child.id)}
                  className={styles.textStyling}>
                  {child?.name}
                </div>
              ))}
            </div>
            <div>
              {tabValue === 1 && <BasicInformation/>}
              {tabValue === 2 && <CostAndPt/>}
              {tabValue === 3 && <PlayerAnalysis/>}
            </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Agentprofile;
