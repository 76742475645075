import Input from 'antd/es/input/Input'
import React from 'react'

const InputWithLabel = ({label, value, onChange, isRequired, type }) => {
  return (
    <div>
        <label>
            {label}
            {isRequired ? <span style={{ color: "red" }}>*</span> : ""}
        </label>
        <Input
            required={isRequired}
            value={value}
            type={type || "text"}
            onChange={(e) => onChange(e.currentTarget.value) }
        />
    </div>
  )
}

export default InputWithLabel


