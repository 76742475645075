import { Spin } from 'antd'
import React from 'react'

const Loader = ({size}) => {
  return (
    <div style={{
        width:"100%",
        display: "flex",
        justifyContent:"center",
        alignItems : "center"

        }}>
      <Spin tip="Loading..." size={size ? size : "large"} style={{ color: '#004A7F' }} />
    </div>
  )
}

export default Loader