import { Table } from "antd";

const DepositSumTable = ({ data, loading }) => {
    const columns = [
        {
          title: 'Currency',
          dataIndex: 'currencyName',
          key: 'currencyName',
        },
        {
          title: 'Total Deposit',
          dataIndex: 'totalDeposit',
          key: 'totalDeposit',
        },
    ]

    return (
      <Table
        loading={loading}
        columns={columns}
        bordered
        dataSource={data}
        pagination={false}
        size="small"
      />
    );
}

export default DepositSumTable