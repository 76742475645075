import react from "react";
import {
  Input,
  Button,
  Row,
  Col,
  Space,
  Switch,
  Select,
  DatePicker,
  message,
} from "antd";
import LangContext from "../../../helps/contexts/lang-context";
import { useContext, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import PutApi from "../../../helps/contexts/Api/PutApi";

import GetApi from "../../../helps/contexts/Api/GetApi";

const EditAgentForm = ({
  rowdata,
  handleSubmit,
  onCancel,
  data,
  fetchAgents,
}) => {
  const nav = useNavigate();

  const id = rowdata.id;
  const phoneprev = rowdata.phone;
  const phonewithoutcode = phoneprev.slice(3);
  const phonecode = phoneprev.substring(0, 3);
  const oldphone = phonecode + phoneprev;
  // form states
  const [username, setUserName] = useState(
    rowdata && rowdata.username ? rowdata.username : ""
  );
  const [password, setPassword] = useState();
  const [countrycode, setcountrycode] = useState(
    phonecode && phonecode ? phonecode : ""
  );
  const [codedata, setcodedata] = useState([]);
  const [seniorid, setid] = useState("");
  const [name, setName] = useState(rowdata && rowdata.name ? rowdata.name : "");
  const [phone, setPhone] = useState(
    rowdata.phone && rowdata.phone ? rowdata.phone : ""
  );
  const [uniquecode, setUniquecode] = useState(
    rowdata && rowdata.uniquecode ? rowdata.uniquecode : ""
  );
  const [senioragentid, setSenioragentid] = useState();
  const [seniordata, setseniordata] = useState([]);
  const [currencyId, setCurrencyId] = useState(rowdata && rowdata.currency ? rowdata.currency : "");
  const [currencyData, setCurrencyData] = useState(null);
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { Option } = Select;

  const onSelect = (value) => {
    setSenioragentid(value);
  };

  const onSelectCurrency = (value)=>{
    setCurrencyId(value);
  }

  // Fetching api
  const fetchCurrency = async (customPage = null) => {
    const path = '/admin/agent/currency';
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path, {}, params);
    if (res) {
      const finalArray = Object.keys(res).map((key, index) => {
        return {
          key: Number(key),
          value: Number(key),
          label: res[key]
        };
      });
      setCurrencyData(finalArray);
    } else {
      setCurrencyData([]);
    }
  };

  useEffect(() => {
    fetchSeniorgents();
    fetchCurrency();
    fetchCountryCode();
  }, []);

  useEffect(() => {
    var selectedsenioragent = seniordata.find(function (sup) {
      return sup.name === rowdata.superioragent;
    });
    setid(selectedsenioragent?.id);
  });

  // Fetching api
  const fetchSeniorgents = async (customPage = null) => {
    const path = "/admin/agents/superior";
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path, {}, params);

    if (res && res.data && res.data.length) {
      const finalArray = res.data.map((agent, index) => ({
        key: index,
        id: agent.id,
        name: agent.user.user_name,
      }));
      setseniordata(finalArray);
    } else {
      setseniordata([]);
    }
  };

  const fetchCountryCode = async () => {
    const path = "/user/phone/codes";
    const params = {};

    //sending api request
    const res = await GetApi.sendApiRequest(path, {}, params);

    if (res && res.length) {
      const finalArray = res.map((cc, index) => ({
        key: index,
        code: cc.code,
      }));
      setcodedata(finalArray);
    } else {
      setcodedata([]);
    }
  };

  const oncodechange = (value) => {
    setcountrycode(value);
  };

  //form submit to backend
  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      const finalphone = countrycode + phone;
      const path = `/admin/agent/${id}/update`;
      // sending request
      const res = await PutApi.PutApiRequest(path, {
        user_name: username,
        name: name,
        // phone: countrycode ? finalphone : oldphone,
        phone: phone,
        unique_code: uniquecode,
        senior_agent_id: seniorid ? seniorid : senioragentid,
      });

      //if success
      if (res?.status === true) {
        message.success(lang.label_agenteditsuccess);
        setName("");

        setPhone("");
        setSenioragentid(seniorid);
        setUniquecode("");
        setUserName("");
        onCancel();
        fetchAgents();
      }
    } catch (e) {
      if(e.request.status === 422){
        Object.values(e.data.errors).map((errors) => message.error(errors));
      }
    }
  };

  //username input change and validation
  const onInputChange = (e) => {
    const { value } = e.target;

    const re = /^[a-z0-9_-]*$/;
    if (value === "" || re.test(value)) {
      setUserName(value);
    } else {
      message.error("Please Enter Only Numbers and Lowercase Alphabets");
    }
  };

  const onCodeChange = (e) => {
    const { value } = e.target;

    const re = /^[a-z0-9_-]*$/;
    if (value === "" || re.test(value)) {
      setUniquecode(value);
    } else {
      message.error("Please Enter Only Numbers and Lowercase Alphabets");
    }
  };

  return (
    <div
      style={{
        width: "97%",
        height: "85%",
        justifyContent: "space-evenly",
      }}
    >
      <p
        style={{
          color: "#A5A5A5",
          fontSize: "14px",
          marginTop: "-1%",
          marginBottom: "3%",
        }}
      >
        {lang.label_fillinfo}
      </p>
      <form onSubmit={onSubmit}>
        <div
          style={{
            marginLeft: "3%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            marginRight: "-6%",
          }}
        >
          <Row>
            <Col span={12}>
              <label style={{ paddingBottom: "3px" }}>{lang.label_name}</label>
              <br />

              <Input
                defaultValue="Agent Name"
                style={{
                  width: "85%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                type="text"
                placeholder="Agent Name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col span={12}>
              <label>
                {lang.label_username}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />

              <Input
                defaultValue="Agent Username"
                type="text"
                placeholder="Agent Name"
                name="username"
                required
                value={username}
                onChange={onInputChange}
                style={{
                  width: "85%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                minLength={8}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label>
                {lang.label_password}
                <span style={{ color: "red" }}>*</span>
              </label>{" "}
              <br />
              <Input.Password
                style={{
                  width: "85%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                disabled
                type="password"
                placeholder="Enter Password"
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                maxLength="18"
                minLength="8"
              />
            </Col>
            <Col span={12}>
              <label>
                {lang.label_uniquecode}
                <span style={{ color: "red" }}>*</span>
              </label>{" "}
              <br />
              <Input
                style={{
                  width: "85%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                required
                placeholder="Enter Unique code"
                name="uniquecode"
                value={uniquecode}
                onChange={onCodeChange}
                maxLength={10}
              />
            </Col>
          </Row>

          <Row>
            {/* <Col span={3}>
              <label>code </label>
              <br />
              <Select
                style={{ width: "90%", marginTop: "5px" }}
                onChange={oncodechange}
                defaultValue={countrycode}
              >
                <Option>{}</Option>
                {codedata &&
                  codedata.map((cc, index) => {
                    return (
                      <Option key={index} value={cc.code}>
                        {cc.code}
                      </Option>
                    );
                  })}
              </Select>
            </Col> */}
            <Col span={12}>
              <label>{lang.label_phone} </label>
              <br />

              <Input
                defaultValue="5123455633"
                style={{
                  width: "85%",
                  borderRadius: "3px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
                type="text"
                placeholder="Enter Phone Number"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Col>
            <Col span={12}>
              <label>{lang.label_superioragent}</label>
              <br />
              <Select
                style={{ width: "85%" }}
                onChange={onSelect}
                defaultValue={rowdata?.superioragent}
                value={senioragentid}
              >
                <Option>{ }</Option>
                {seniordata &&
                  seniordata.map((agent, index) => {
                    return (
                      <Option key={index} value={agent.id}>
                        {agent.name}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
            <Col span={12}>
              <label>{lang.label_currency}</label>
              <br />
              <Select
                style={{ width: "85%" }}
                onChange={onSelectCurrency}
                defaultValue={rowdata?.currency}
                value={currencyId}
              >
                <Option>{ }</Option>
                {currencyData &&
                  currencyData.map((currency, index) => {
                    return (
                      <Option key={currency?.key} value={currency.value}>
                        {currency.label}
                      </Option>
                    );
                  })}
              </Select>
            </Col>
          </Row>

          <br />

          <Row></Row>
          <br />

          <div style={{}}>
            {" "}
            <Button
              style={{
                borderRadius: "3px",
                backgroundColor: "#004A7F",
                color: "white",
                position: "absolute",
                right: "20%",
                top: "87%",
              }}
              htmlType="submit"
            >
              {lang.label_submit}
            </Button>
            <Button
              style={{
                borderRadius: "3px",
                color: "#004A7F",
                position: "absolute",
                right: "8%",
                top: "87%",
              }}
              onClick={() => {
                setName("");
                setPassword("");
                setPhone("");
                setSenioragentid("--select a superior agent--");
                setUniquecode("");
                setUserName("");
                onCancel();
              }}
            >
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditAgentForm;
