import React from 'react';
import { Input, Button, Row, Col, Select, DatePicker, message, Upload } from 'antd';
import LangContext from '../../helps/contexts/lang-context';
import Loader from '../HelperComponents/Loader';
import { useContext } from 'react';
import { notification } from 'antd';

export const AddSettingsForm = ({
  formData,
  setFormData,
  handleCloseModal,
  createSettings,
  loading,
  isEdit,
  editSetting
}) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const onSubmit = () => {
    if (formData.key === '' || formData.value === '') {
      message.error('Please fill in both key and value');
      return;
    }
    if (isEdit) {
      editSetting(formData.id);
    } else {
      createSettings();
    }

    if (!loading) {
      handleCloseModal();
    }
  };
  return (
    <div
      style={{
        width: '98%',
        // height: '85%',
        justifyContent: 'space-evenly'
      }}>
      <p
        style={{
          color: '#A5A5A5',
          fontSize: '14px',
          marginTop: '-1%',
          marginBottom: '3%'
        }}>
        {lang.label_fillinfo}
      </p>
      <form>
        <div
          style={{
            marginLeft: '3%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '-6%'
          }}>
          <Row>
            <Col span={22}>
              <label style={{ paddingBottom: '3px' }}>{'Key'}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <Input
                required
                style={{
                  width: '100%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                onChange={(e) => setFormData({ ...formData, key: e.target.value })}
                // onChange={(e) => console.log(e.target.value)}
                name="key"
                value={formData.key}
              />
            </Col>

            <Col span={22}>
              <label style={{ paddingBottom: '3px' }}>{'Value'}</label>
              <span style={{ color: 'red' }}>*</span>
              <br />

              <Input
                required
                style={{
                  width: '100%',
                  borderRadius: '3px',
                  marginBottom: '5px',
                  marginTop: '5px'
                }}
                type="text"
                onChange={(e) => setFormData({ ...formData, value: e.target.value })}
                // onChange={(e) => console.log(e.target.value)}
                name="value"
                value={formData.value}
              />
            </Col>
          </Row>
          <div
            style={{
              marginTop: '2%',
              display: 'flex',
              justifyContent: 'flex-end',
              // gap: '50px',
              width: '92%'
            }}>
            {!loading ? (
              <Button
                style={{
                  backgroundColor: '#004A7F',
                  color: 'white',
                  width: '80px',
                  height: '35px',
                  background: '#004A7F 0% 0% no-repeat padding-box',
                  borderRadius: '3px',
                  opacity: '1',
                  margin: '0px 10px'
                }}
                onClick={onSubmit}>
                {lang.label_submit}
              </Button>
            ) : (
              <Loader size={'small'} />
            )}
            <Button
              style={{
                borderRadius: '3px',
                color: '#004A7F',
                width: '80px',
                height: '35px'
              }}
              onClick={() => {
                handleCloseModal();
              }}>
              {lang.label_cancel}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddSettingsForm;
