import { Button, Table, Modal, Switch, message } from 'antd';
import { MdEdit } from 'react-icons/md';
import { AiOutlineDelete } from 'react-icons/ai';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState } from 'react';
import EditPromotionForm from '../../../components/forms/EditPromotionForm';
import DeletePromotionForm from '../../../components/forms/DeletePromotionForm';
import ChangeStatusModal from '../../../components/ChangeStatusModal/ChangeStatusModal';
import EditIPForm from './EditIPForm';
import PutApi from '../../../helps/contexts/Api/PutApi';
import styles from './IpWhiteList.module.css';
import DeleteIPForm from './DeleteIPForm';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';

const IpWhiteListTable = ({ data, loading, getPromotions }) => {
  const ctx = useContext(LangContext);
  const { hasPermission } = useCheckPermission();

  const lang = ctx.lang;

  //states

  const [isEdit, setIsedit] = useState(false);
  const [isdelete, setDelete] = useState(false);
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState({});

  const id = rowData.id;
  // Edit promotion popup

  const handleCancel1 = (e) => {
    setVisible(false);
  };
  const handleCancel = (e) => {
    setVisible(false);
    setIsedit(false);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },

    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: lang.label_action,
      key: 'action',
      render: (text, record) => (
        <span style={{ display: 'flex', flexDirection: 'row', columnGap: '5px' }}>
          {hasPermission('Update IP Whitelist') && (
            <Button
              onClick={() => {
                setVisible(!visible);
                setIsedit(true);
                setRowData({ ...record });
              }}
              className={styles.editButton}>
              <MdEdit size={15} style={{ marginLeft: '-6px' }} />
            </Button>
          )}
          {hasPermission('Delete IP Whitelist') && (
            <Button
              onClick={() => {
                setVisible(true);
                setIsedit(false);
                setDelete(true);
              }}
              className={styles.deleteButton}>
              <AiOutlineDelete size={15} style={{ marginLeft: '-8px' }} />
            </Button>
          )}
        </span>
      )
    }
  ];

  return (
    <div>
      <Table
        style={{ width: '100%' }}
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        bordered
        onRow={(record) => {
          return {
            onClick: () => {
              setRowData(record);
            }
          };
        }}
      />
      <div>
        {(isEdit || isdelete) && (
          <Modal
            closeIcon={null}
            title={isEdit ? 'Edit IP' : isdelete ? 'Delete IP' : ''}
            open={visible}
            onCancel={handleCancel}
            footer={null}
            width={isEdit ? 500 : 300}
            closable={false}>
            {isEdit ? (
              <EditIPForm rowdata={rowData} onCancel={handleCancel} getPromotions={getPromotions} />
            ) : isdelete ? (
              <DeleteIPForm rowdata={rowData} onCancel={handleCancel} getPromotions={getPromotions} />
            ) : (
              ''
            )}
          </Modal>
        )}
      </div>
    </div>
  );
};
export default IpWhiteListTable;
