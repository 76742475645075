import { Table, Modal, Dropdown, Button } from 'antd';

import LangContext from '../../../helps/contexts/lang-context';
import { useContext, useState, useEffect } from 'react';
import EditAgentForm from './EditAgentForm';
import ResetPwdForm from '../../../components/forms/ResetPwdForm';
import { Menu } from 'antd';
import { IoMenu } from 'react-icons/io5';
import DeleteAgentForm from './DeleteAgentForm';
import { convertUTCToLocalTime } from '../../../helps/HelperFunctions';
import { MdEdit } from 'react-icons/md';
import UpdateBalanceForm from './UpdateBalanceForm';
import UpdateCreditForm from './UpdateCreditForm';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';

const AgentListTable = ({ data, loading, fetchAgents }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { hasPermission } = useCheckPermission();
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState({});
  const [isEdit, setIsedit] = useState(false);
  const [deleteAgent, setDeleteAgent] = useState(false);
  const [reset, setReset] = useState(false);

  const [agentID, setAgentID] = useState('');
  const [updateBalanceOpen, setUpdateBalanceOpen] = useState(false);
  const [updateCreditOpen, setUpdateCreditOpen] = useState(false);

  // Edit Agent popup

  const handleCancel = (e) => {
    //  getPlayers();
    setIsedit(false);
    setDeleteAgent(false);
    setVisible(!visible);

    // setVisible(false);
  };

  //for the edit option in table
  const handleEditAgent = (record) => {
    setRowData(record);
    setIsedit(true);
    setReset(false);
    setDeleteAgent(false);
    setVisible(true);
  };
  //for the resetpwd option in table
  const handleResetPwd = () => {
    setIsedit(false);
    setReset(true);
    setDeleteAgent(false);
    setVisible(!visible);
  };
  const handleDelAgent = () => {
    setIsedit(false);
    setReset(false);
    setDeleteAgent(true);
    setVisible(true);
  };

  //menu items in table

  const items = [
    {
      key: 1,
      label: 'Edit Agent'
    },
    {
      key: 2,
      label: 'Reset Password'
    },
    {
      key: 3,
      label: 'Delete Agent'
    }
  ];

  useEffect(() => {
    if (isEdit) {
      handleEditAgent(rowData);
    }
  }, [isEdit]);
  //onclick of option in table
  const onClick = ({ key }) => {
    if (key == 1) {
      handleEditAgent();
    }
    if (key == 2) {
      handleResetPwd();
    }
    if (key == 3) {
      handleDelAgent();
    }
  };

  const columns = [
    {
      title: lang.label_name,
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: lang.label_username,
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: lang.label_phone,
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: lang.label_agent_type,
      dataIndex: 'agent_type_name',
      key: 'agent_type_name',
      render: (text, row) => <>{text ?? `-`}</>
    },
    {
      title: lang.label_superioragent,
      dataIndex: 'superioragent',
      key: 'superioragent'
    },
    {
      title: lang.label_uniquecode,
      dataIndex: 'uniquecode',
      key: 'uniquecode'
    },
    {
      title: lang.label_balance,
      dataIndex: 'balance',
      key: 'balance',
      render: (text, row) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px'
          }}>
          {text ? Number(text).toFixed(2) : `0.00` ?? ``}
          {hasPermission('Create Agent Wallet') && (
            <Button
              type="link"
              style={{ border: 'none', padding: '0px' }}
              onClick={() => {
                setAgentID(row?.id);
                setUpdateBalanceOpen(true);
              }}>
              <MdEdit />
            </Button>
          )}
        </div>
      )
    },
    {
      title: lang.label_credit_line,
      dataIndex: 'credit_line',
      key: 'credit_line',
      render: (text, row) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px'
          }}>
          {text ? Number(text).toFixed(2) : `0.00` ?? `-`}
          {hasPermission('Create Agent Credit') && (
            <Button
              type="link"
              style={{ border: 'none', padding: '0px' }}
              onClick={() => {
                setAgentID(row?.id);
                setUpdateCreditOpen(true);
              }}>
              <MdEdit />
            </Button>
          )}
        </div>
      )
    },
    {
      title: lang.label_max_credit,
      dataIndex: 'max_credit',
      key: 'max_credit',
      render: (text, row) => <>{text ? Number(text).toFixed(2) : `-` ?? `-`}</>
    },
    {
      title: `${lang.label_registerip}/${lang.label_registertime}`,
      dataIndex: ['registerip', 'registertime'],
      key: 'registerip',
      render: (time, row) => (
        <span style={{ color: '#2A93F0' }} title={row['registertime']}>
          <span title={row['registertime']} style={{}}>
            {row['registerip']}
            <br />
            <span style={{ color: 'black' }}>
              {row['registertime'] ? convertUTCToLocalTime(row['registertime']) : '-'}
            </span>
          </span>
        </span>
      )
    },

    {
      title: `${lang.label_loginip}/${lang.label_logintime}`,
      dataIndex: ['loginip', 'logintime'],
      key: 'loginip',
      render: (time, row) => (
        <>
          {row?.created_by_admin ? (
            '-'
          ) : (
            <span style={{ color: '#2A93F0' }} title={row['logintime']}>
              {row['loginip']}
              {row['logintime'] && <br />}
              <span style={{ color: 'black' }}>{row['logintime'] && convertUTCToLocalTime(row['logintime'])}</span>
            </span>
          )}
        </>
      )
    }
  ];

  if (hasPermission('Create Agent')) {
    columns.push({
      title: lang.label_action,
      key: 'action',
      render: (_, record) => {
        return (
          <Dropdown
            placement="bottom"
            menu={{
              items,
              onClick
            }}>
            <a href="#" style={{ color: '#004a7f' }} onClick={(e) => e.preventDefault()}>
              <IoMenu />
            </a>
          </Dropdown>
        );
      }
    });
  }

  return (
    <div>
      <Table
        style={{ width: '100%' }}
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        bordered
        onRow={(record) => {
          return {
            onClick: () => {
              setRowData(record);
            }
          };
        }}
      />
      <div>
        {(isEdit || reset || deleteAgent) && (
          <Modal
            closeIcon={null}
            title={isEdit ? lang.label_editagent : reset ? lang.label_resetpwd : 'Delete Agent'}
            open={visible}
            onCancel={handleCancel}
            footer={null}
            width={isEdit ? 700 : reset ? 700 : 450}
            closable={true}>
            {isEdit ? (
              <EditAgentForm rowdata={rowData} onCancel={handleCancel} fetchAgents={fetchAgents} />
            ) : reset ? (
              <ResetPwdForm rowdata={rowData} onCancel={handleCancel} fetchAgents={fetchAgents} />
            ) : deleteAgent ? (
              <DeleteAgentForm rowdata={rowData} onCancel={handleCancel} fetchAgents={fetchAgents} />
            ) : (
              ''
            )}
          </Modal>
        )}

        <UpdateBalanceForm
          id={agentID}
          setUpdateBalanceOpen={setUpdateBalanceOpen}
          updateBalanceOpen={updateBalanceOpen}
          fetchAgents={fetchAgents}
        />
        <UpdateCreditForm
          id={agentID}
          setUpdateCreditOpen={setUpdateCreditOpen}
          updateCreditOpen={updateCreditOpen}
          fetchAgents={fetchAgents}
        />
      </div>
    </div>
  );
};
export default AgentListTable;
