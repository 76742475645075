import React, { useContext, useEffect, useState } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Pagination, Tag, Table } from 'antd';
import LangContext from '../../../../helps/contexts/lang-context';
import GetApi from '../../../../helps/contexts/Api/GetApi';
import { useNavigate } from 'react-router-dom';
import { convertUTCToLocalTime, toFixedFunc } from '../../../../helps/HelperFunctions';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const PlayerWithdrawHistory = ({ id }) => {
  const [currentpage, setCurrentpage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage1] = useState(1);
  const [Data, setData] = useState([]);

  const nav = useNavigate();

  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  function convertToTwoDecimalPlaces(numberString) {
    let number = parseFloat(numberString);
    let formattedNumber = number.toFixed(2);
    return formattedNumber;
  }

  const getPlayerWithdrawHistory = async (customPage = null, id) => {
    try {
      const path = `/admin/player/transaction/${id}?isWithdraw=1`;
      const params = {};
      setLoading(true);

      //setting parameters to send for search results
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;

      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      console.log('response withdraw', res);
      if (res && res?.data && res?.data.length) {
        console.log('res.data,', res.data);
        const finalArray = res?.data?.map((user, index) => ({
          key: index,
          id: user?.id, //ID
          //round_reference: user?.round_reference ? user?.round_reference : '-',
          // openedOn: convertUTCToLocalTime(user?.approved_time),
          reference_no: user?.is_deposit_transaction?.reference_no || "-", //reference id
          // transferNo:  user?.is_deposit_transaction?.deposit_transaction_no || "-", //transfer no
          transferNo: user?.is_deposit_transaction?.deposit_transaction_no || '-',
          amount: user ? convertToTwoDecimalPlaces(user?.amount) : 0,
          //Amount  item ? convertToTwoDecimalPlaces(item?.amount) : 0
          currency: user ? user?.currency_name : '-', //currency
          account_name: user?.payment_method?.account_name || '-',
          createdTime: convertUTCToLocalTime(user?.created_at), //Created
          approvedTime: user?.approvedTime ? convertUTCToLocalTime(user?.approved_time) : '-', //Approved Time
          status_name: user?.status_name,
          user_payment_method: user?.user_payment_method || '-',
          // risk_status:user?.
          status: (
            <Tag
              color={
                user?.status == 1
                  ? 'success'
                  : user?.status == 2
                  ? 'error'
                  : user?.status == 'pending'
                  ? 'processing'
                  : null
              }
              icon={
                user?.status == 1 ? (
                  <CheckCircleOutlined />
                ) : user?.status == 2 ? (
                  <CloseCircleOutlined />
                ) : user?.status == 'pending' ? (
                  <SyncOutlined spin />
                ) : null
              }>
              {user?.status === 1
                ? lang.label_completed
                : user?.status === 2
                ? lang.label_failed
                : user?.status == 'pending'
                ? lang.label_pending
                : null}
            </Tag>
          )
        }));
        setData(finalArray);
        setCurrentpage(res?.current_page);
        setTotal(res?.total);
        setPerpage(res?.per_page);
        setNextPage(res?.next_page_url);
      } else {
        setData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
    }
  };

  useEffect(() => {
    getPlayerWithdrawHistory(activepage, id);
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: 'Reference ID',
      dataIndex: 'reference_no',
      key: 'reference_no'
      // render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: 'Transfer No',
      dataIndex: 'transferNo',
      key: 'transferNo'
      // render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount'
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency'
      // render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: 'Company Payment',
      dataIndex: 'account_name',
      key: 'account_name',
      // render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: 'Costomer Payment',
      dataIndex: 'user_payment_method',
      key: 'user_payment_method',
      render: (item, row) => {
        return (
          <div>
            <div>{row.user_payment_method ? row.user_payment_method?.bank_code?.public_name?.en_public_name : ''}</div>
            <div>{item?.account_name}</div>
            <div>{item?.account_number}</div>
          </div>
        );
      }

      // render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: 'Risk Verification',

      key: 'risk_status',
      render: (item, row) => makeRiskBadge(row),
      dataIndex: 'risk_status',
      align: 'center'
      // render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },

    {
      title: 'Created Time',
      dataIndex: 'createdTime',
      key: 'createdTime'
    },
    {
      title: 'Approved Time',
      dataIndex: 'approvedTime',
      key: 'approvedTime'
    },
    {
      title: 'Finance Verification',
      key: 'status_name',
      render: (item, row) => makeFABadge(row),
      align: 'center'
    }
  ];

  const makeFABadge = (transaction) => {
    console.log('transaction', transaction);
    var tagColor = 'green';
    var tagIcon = <CheckOutlined />;
    console.log('transaction', transaction);

    if (
      transaction.status_name == 'processing' ||
      transaction.status_name == 'pending' ||
      transaction.status_name == 'Waiting For Payment' ||
      transaction.status_name == 'Failed'
    ) {
      return '-';
    } else if (transaction.status_name == 'rejected') {
      tagColor = 'red';
      tagIcon = <CloseOutlined />;
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Tag icon={tagIcon} color={tagColor}>
          {transaction.status_name.toUpperCase()} {'by'}{' '}
          {!transaction.action_by ? 'System' : transaction.action_by.user_name}
        </Tag>
        <span
          style={{
            paddingTop: '7.5px',
            fontSize: '12px'
          }}>
          {transaction.remark}
        </span>
      </div>
    );
  };
  const makeRiskBadge = (transaction) => {
    const withdrawData = transaction?.is_withdraw_transaction;
    const s = withdrawData?.risk_action_status;

    if (!withdrawData || s === 3 || s === 4 || s === 5) {
      return '-';
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Tag
          icon={s == 1 ? <CheckOutlined /> : <CloseOutlined />}
          color={s == 1 ? 'green' : 'red'}
          style={{
            width: 'min-content'
          }}>
          {s == 1 ? lang.label_approvedby : lang.label_rejectedby}{' '}
          {!withdrawData?.risk_action_by && withdrawData?.risk_action_status
            ? 'System'
            : withdrawData?.risk_action_by?.user_name}
        </Tag>
        <span
          style={{
            paddingTop: '7.5px',
            fontSize: '12px'
          }}>
          {withdrawData?.risk_action_note}
        </span>
      </div>
    );
  };

  const onChangepage = (page) => {
    setActivepage1(page);
    getPlayerWithdrawHistory(page, id);
  };
  return (
    <>
      <Table
        pagination={false}
        style={{
          width: '100%',
          marginTop: '30px',
          overflowX: 'auto',
          overflowY: 'scroll'
        }}
        size="small"
        columns={columns}
        bordered
        scroll={{
          x: 'max-content'
        }}
        dataSource={Data}
        loading={loading}
      />
      <Pagination
        defaultCurrent={1}
        current={activepage}
        onChange={onChangepage}
        total={total}
        responsive={true}
        pageSize={perpage}
      />
    </>
  );
};

export default PlayerWithdrawHistory;
