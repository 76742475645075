import { useEffect, useState } from 'react';
import addCommasToNumber from '../../../helps/AddCommasToNumber';
import styles from './PromotionHistory.module.css';

import { CalendarOutlined, CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Card, Row, Col, DatePicker, Button, Input, Pagination, Select, Tag, message, Spin } from 'antd';
import LangContext from '../../../helps/contexts/lang-context';
import { useContext } from 'react';
import GetApi from '../../../helps/contexts/Api/GetApi';
import '../../../styles/ButtonStyle.css';
import PromotionHistoryTable from './PromotionHistoryTable';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import { useNavigate } from 'react-router-dom';
import { convertLocalTimeToUTC, toFixedFunc } from '../../../helps/HelperFunctions';
import SubHeading from '../../../components/HelperComponents/SubHeading';
import InputField from '../../../components/HelperComponents/InputField';
import { TabContext } from '../../../helps/contexts/TabContext';
import SearchIcon from '../../../images/Search.png';
import ResetIcon from '../../../images/Reset.png';
import dayjs from 'dayjs';
import { exportReportPromotionHistory, exportReportPromotionHistory2 } from '../../../helps/contexts/Api/downloadFile';
import { LoadingOutlined } from '@ant-design/icons';

const datePickerStyle = {
  width: '190px',
  height: '35px',
  background: ' #FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #D9D9D9',
  borderRadius: '3px'
};
const iconStyle = { color: '#004A7F' };
const PromotionHistory = () => {
  const nav = useNavigate();
  //States
  const [promotionHistoryData, setPromotionHistoryData] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startTimeParam, setStartTimeParam] = useState('');
  const [endTimeParam, setendTimeParam] = useState('');
  const [minimum, setMinimum] = useState('');
  const [maximum, setMaximum] = useState('');
  const [username, setUsername] = useState('');
  const [type, setType] = useState(null);
  const [typesArray, setTypesArray] = useState([]);
  const [ignoreTestAccount, setIgnoreTestAccount] = useState(null);
  const [perpage, setPerpage] = useState(0);
  const [nextPage, setNextPage] = useState();
  const [activepage, setActivepage] = useState(1);
  const [currentpage, setCurrentpage] = useState(0);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [filteredParams, setFilteredParams] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lebel, setLabel] = useState('All');
  const { path } = useContext(TabContext);
  //Language translation
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const downloadedFileIds = new Set();

  const getPromotionHistory = async (customPage = null) => {
    try {
      const path = '/admin/players/balance/history';
      const params = {};
      setLoading(true);

      //setting parameters to send for search results
      if (customPage) params['page'] = customPage;
      else params['page'] = activepage;
      if (startDate) {
        params['from_date'] = convertLocalTimeToUTC(startTimeParam);
      }
      if (endDate) params['to_date'] = convertLocalTimeToUTC(endTimeParam);
      if (minimum) params['min_amount'] = minimum;
      if (maximum) params['max_amount'] = maximum;
      if (username) params['user_name'] = username;
      // if (type) params['type'] = type;
      params['type'] = 4;
      if (ignoreTestAccount) params['ignore_test_account'] = ignoreTestAccount;
      //sending api request
      setFilteredParams(params);
      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.player_balance_history.data && res.player_balance_history.data.length) {
        const finalArray = res.player_balance_history.data.map((d) => {
          return {
            key: d.id ? d.id : '',
            id: d?.player_id ? d?.player_id : '',
            previousbalance: d.previous_balance ? d.previous_balance : '0',
            currency_name: d.currency_name,

            newbalance: d.new_balance ? d.new_balance : '0',
            amount: d.amount ? d.amount : '0',
            type: d?.type_name ? (
              <Tag color="processing">{d?.type_name}</Tag>
            ) : d.transaction?.isWithdraw === 1 ? (
              <Tag color="error">{lang.label_withdrawal}</Tag>
            ) : d.transaction?.isWithdraw === 0 ? (
              <Tag color="success">{lang.label_deposit}</Tag>
            ) : (
              <>-</>
            ),
            is_turnover_check: d.is_turnover_check ? d.is_turnover_check : '-',
            turnover_multiplier:
              d.withdraw_requirement && d?.withdraw_requirement?.turnover_multiplier
                ? parseFloat(d?.withdraw_requirement?.turnover_multiplier).toFixed(2)
                : '-',
            game_category:
              d.withdraw_requirement && d?.withdraw_requirement?.game_category
                ? JSON.parse(d.withdraw_requirement.game_category.name)?.en || '-'
                : '-',
            game_types:
              d.withdraw_requirement && d?.withdraw_requirement?.game_type_name
                ? d.withdraw_requirement.game_type_name
                : '-',
            actionby: d.action_by ? d.action_by.user_name : '',
            playername: d.player.user ? d.player.user.user_name : '',
            transactionid: d.transaction_id ? d.transaction_id : '',
            remark: d.remark ? d.remark : '',
            gametransactionid: d?.game_transaction_history_id,
            status: (
              <Tag
                color={
                  d.status == 1 ? 'success' : d.status == 2 ? 'error' : d.status == 'pending' ? 'processing' : null
                }
                icon={
                  d.status == 1 ? (
                    <CheckCircleOutlined />
                  ) : d.status == 2 ? (
                    <CloseCircleOutlined />
                  ) : d.status == 'pending' ? (
                    <SyncOutlined spin />
                  ) : null
                }>
                {d?.status === 1
                  ? lang.label_completed
                  : d?.status === 2
                  ? lang.label_failed
                  : d?.status == 'pending'
                  ? lang.label_pending
                  : null}
              </Tag>
            ),
            time: d.created_at ? d.created_at : ''
          };
        });
        setPromotionHistoryData(finalArray);
        setTotal(res.player_balance_history.total);
        setCurrentpage(res.player_balance_history.current_page);
        setPerpage(res.player_balance_history.per_page);
        setNextPage(res.player_balance_history.next_page_url);
        setTotalDeposit(res.deposit_total);
        setTotalWithdraw(res.withdraw_total);
        setTotalAmount(res.total);
      } else {
        setPromotionHistoryData([]);
        setTotal(0);
      }
      setLoading(false);
    } catch (e) {
      if (e?.status === 401) {
        localStorage.clear();
        nav('/login');
      }
      message.error('error is', e.data.message);
    }
  };

  // Fetching login history api
  const getPlayerBalanceHistoryWithoutParams = async () => {
    setActivepage(1);
    const params = {};
    const path = '/admin/players/balance/history';
    setLoading(true);
    params['page'] = activepage;
    params['type'] = 4;
    //sending api request
    const res = await GetApi.sendApiRequest(path, null, params);
    if (res && res.player_balance_history.data && res.player_balance_history.data.length) {
      const finalArray = res.player_balance_history.data.map((d) => ({
        key: d.id ? d.id : '',
        id: d?.player_id ? d?.player_id : '',
        previousbalance: d.previous_balance ? d.previous_balance : '0',
        currency_name: d.currency_name,
        newbalance: d.new_balance ? d.new_balance : '0',
        amount: d.amount ? d.amount : '0',
        type: d?.type_name ? (
          <Tag color="processing">{d?.type_name}</Tag>
        ) : d.transaction?.isWithdraw === 1 ? (
          <Tag color="error">{lang.label_withdrawal}</Tag>
        ) : d.transaction?.isWithdraw === 0 ? (
          <Tag color="success">{lang.label_deposit}</Tag>
        ) : (
          <>-</>
        ),
        is_turnover_check: d.is_turnover_check ? d.is_turnover_check : '-',
        turnover_multiplier: d.turnover_multiplier ? parseFloat(d.turnover_multiplier).toFixed(2) : '-',
        actionby: d.action_by ? d.action_by.user_name : '',
        playername: d.player.user ? d.player.user.user_name : '',
        transactionid: d.transaction_id ? d.transaction_id : '',
        remark: d.remark ? d.remark : '',
        gametransactionid: d.game_transaction_history_id ? d.game_transaction_history_id : '',
        status: (
          <Tag
            color={d.status == 1 ? 'success' : d.status == 2 ? 'error' : d.status == 'pending' ? 'processing' : null}
            icon={
              d.status == 1 ? (
                <CheckCircleOutlined />
              ) : d.status == 2 ? (
                <CloseCircleOutlined />
              ) : d.status == 'pending' ? (
                <SyncOutlined spin />
              ) : null
            }>
            {d?.status === 1
              ? lang.label_completed
              : d?.status === 2
              ? lang.label_failed
              : d?.status == 'pending'
              ? lang.label_pending
              : null}
          </Tag>
        ),
        time: d.created_at ? d.created_at : ''
      }));
      setPromotionHistoryData(finalArray);
      setTotal(res.player_balance_history.total);
      setCurrentpage(res.player_balance_history.current_page);
      setPerpage(res.player_balance_history.per_page);
      setNextPage(res.player_balance_history.next_page_url);
      setTotalDeposit(res.deposit_total);
      setTotalWithdraw(res.withdraw_total);
      setTotalAmount(res.total);
    } else {
      setPromotionHistoryData([]);
      setTotal(0);
    }
    setLoading(false);
  };
  // handling submit for the form to get search results
  const sendSearchFilters = async (e) => {
    e.preventDefault();
    getPromotionHistory();
    setActivepage(1);
  };
  useEffect(() => {
    getPromotionHistory();
  }, []);

  useEffect(() => {
    if (path === '/player-management/player-balance-history') {
      getPromotionHistory();
    }
  }, [path]);

  const resetSelection = () => {
    setType(null);
    setLabel(lang.label_all);
  };

  const exportData = async (payload) => {
    const params = {
      export_type: 0,
      export: 1,
      promotion: 1,
      from_date: payload.from_date,
      to_date: payload.to_date,
      ignore_test_account: payload.ignore_test_account,
      max_amount: payload.max_amount,
      min_amount: payload.min_amount,
      user_name: payload.user_name,
      type: payload.type
    };

    try {
      setLoadingData(true);
      const response = await exportReportPromotionHistory(params);

      if (response?.data?.job_id) {
        const jobId = response.data.job_id;
        const totalFileCount = response.data.progress.total_file_count;

        const pollExportStatus = async (jobId) => {
          try {
            const statusResponse = await exportReportPromotionHistory2(jobId);

            const files = statusResponse?.data?.file || [];
            files.forEach((file) => {
              if (!downloadedFileIds.has(file.file_id)) {
                const base64String = file.file_content;
                const fileName = 'promotion-history' + (file.file_type.includes('csv') ? '.csv' : '.xlsx');
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: file.file_type });

                const link = document.createElement('a');
                const blobUrl = URL.createObjectURL(blob);
                link.href = blobUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);

                // Mark the file as downloaded
                downloadedFileIds.add(file.file_id);
                // Optionally, you can keep track of downloaded files count
                // setDownloadedFiles((prev) => prev + 1);
              }
            });

            if (statusResponse?.data?.progress?.is_all_file_generated) {
              setLoadingData(false);
              message.success(`Export completed. ${totalFileCount} files downloaded.`);
            } else {
              setTimeout(() => pollExportStatus(jobId), 2000);
            }
          } catch (error) {
            setLoadingData(false);
            message.error('Failed to check export status.');
          }
        };

        pollExportStatus(jobId);
      } else {
        setLoadingData(false);
        message.error('Failed to initiate export.');
      }
    } catch (error) {
      setLoadingData(false);
      message.error('Failed to initiate export.');
    }
  };

  return (
    <>
      <HeaderCardDetails />
      <form
        style={{ padding: '20px' }}
        onSubmit={(e) => {
          e.preventDefault();
          getPromotionHistory();
        }}>
        <div>
          <Card
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ color: '#4F5057' }}>Filters</span>
                {loadingData ? (
                  <div style={{ paddingRight: '60px' }}>
                    <Spin indicator={<LoadingOutlined spin />} size="large" />
                  </div>
                ) : (
                  <div>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="btnStyle"
                      style={{
                        backgroundColor: '#004A7F',
                        color: 'white',
                        width: '125px',
                        height: '35px',
                        background: '#004A7F 0% 0% no-repeat padding-box',
                        borderRadius: '3px',
                        opacity: '1',
                        marginRight: '15px'
                      }}
                      onClick={() => exportData(filteredParams)}>
                      Export
                    </Button>
                  </div>
                )}
              </div>
            }
            style={{
              borderRadius: '3px',
              color: '#4F5057'
            }}>
            <div>
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center'
                }}>
                <Col
                  span={8}
                  style={{
                    color: '#636363',
                    fontSize: '14px',
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'start',
                    alignItems: 'center'
                  }}>
                  <label className="filterTest" style={{ marginRight: '3px' }}>
                    {lang.label_time}{' '}
                  </label>
                  <DatePicker
                    placeholder={lang.label_selectdate}
                    showTime={{
                      defaultValue: dayjs('00:00:00', 'HH:mm:ss')
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                    style={datePickerStyle}
                    suffixIcon={<CalendarOutlined style={iconStyle} />}
                    //getting input value for start date
                    value={startDate}
                    onChange={(value, dateString) => {
                      setStartDate(value);
                      setStartTimeParam(dateString);
                    }}
                  />
                  <span
                    style={{
                      color: 'black',
                      fontSize: '33px'
                    }}>
                    -
                  </span>
                  <DatePicker
                    placeholder={lang.label_selectdate}
                    showTime={{
                      defaultValue: dayjs('23:59:59', 'HH:mm:ss')
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                    suffixIcon={<CalendarOutlined style={iconStyle} />}
                    style={datePickerStyle}
                    value={endDate}
                    //getting input value for end date
                    onChange={(value, dateString) => {
                      setEndDate(value);
                      setendTimeParam(dateString);
                    }}
                  />
                </Col>

                <Col xs={24} sm={24} md={24} lg={7}>
                  <div className={styles.flexStyle}>
                    <SubHeading>{lang.label_amount}</SubHeading>
                    <InputField
                      placeHolder="Minimum"
                      type="number"
                      name="minAmmount"
                      value={minimum}
                      onChange={(name, value) => {
                        setMinimum(value);
                      }}
                    />
                    <div className={styles.dash}></div>
                    <InputField
                      placeHolder="Maximun"
                      type="number"
                      name="maxAmmount"
                      value={maximum}
                      onChange={(name, value) => {
                        setMaximum(value);
                      }}
                    />
                  </div>
                </Col>
                <Col
                  span={8}
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                  }}>
                  <label className="filterTest" style={{ marginLeft: '1rem', marginRight: '3px' }}>
                    {lang.label_username}
                  </label>
                  <Input
                    //getting input value
                    value={username}
                    className="inputStyle"
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                  {/* <label className="filterTest" style={{ marginLeft: '1rem', marginRight: '3px' }}>
                                        {lang.label_type}
                                    </label> */}
                  {/* <Select
                    value={type}
                    defaultValue="All"
                    options={[
                      {
                        value: null,
                        label: lang.label_all
                      },
                      {
                        value: '1',
                        label: lang.label_deposit
                      },
                      {
                        value: '2',
                        label: lang.label_withdraw
                      },
                      {
                        value: '3',
                        label: 'Bet'
                      },
                      {
                        value: '4',
                        label: 'Promotion'
                      },
                      {
                        value: '5',
                        label: 'Manual Adding'
                      },
                      {
                        value: '6',
                        label: 'Manual Deducting'
                      },
                      {
                        value: '7',
                        label: 'Event Reward'
                      },
                      {
                        value: '8',
                        label: lang.label_rebate
                      },
                      {
                        value: '9',
                        label: 'Affiliate'
                        // label: lang.label_affiliate
                      }
                    ]}
                    //getting input value
                    style={{ width: '200px' }}
                    onChange={(value, label) => {
                      setType(value);
                      setLabel(label);
                    }}
                  /> */}
                </Col>
              </Row>
              <Row>
                <Col
                  span={18}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}>
                  <label className="filterTest" style={{ marginRight: '3px' }}>
                    {lang.label_ignoretestaccount}
                  </label>
                  <Select
                    defaultValue="Yes"
                    options={[
                      {
                        value: 1,
                        label: lang.label_yes
                      },
                      {
                        value: '0',
                        label: lang.label_no
                      }
                    ]}
                    //getting input value
                    style={{ width: '200px' }}
                    onChange={(value) => {
                      setIgnoreTestAccount(value);
                    }}
                  />
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="btnStyle"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      margin: '0.5rem'
                    }}>
                    <img src={SearchIcon} alt="search" />
                    {lang.label_search}
                  </Button>
                  <Button
                    className="btnReset"
                    //handling reset
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly'
                    }}
                    onClick={() => {
                      setFilteredParams({ type: 4 });
                      setStartDate('');
                      setEndDate('');
                      setMinimum('');
                      setMaximum('');
                      setIgnoreTestAccount('');
                      setType('');
                      setUsername('');
                      getPlayerBalanceHistoryWithoutParams();
                      resetSelection();
                    }}>
                    <img src={ResetIcon} alt="reset" />
                    {lang.label_reset}
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
          <br />
          <PromotionHistoryTable data={promotionHistoryData} loading={loading} />

          <Pagination
            showSizeChanger={false}
            defaultCurrent={1}
            pageSize={perpage}
            total={total}
            defaultPage={1}
            current={activepage}
            onChange={(page) => {
              setActivepage(page);
              getPromotionHistory(page);
            }}
          />
        </div>
      </form>
    </>
  );
};

export default PromotionHistory;
