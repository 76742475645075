import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import PostApi from '../../helps/contexts/Api/PostApi';
import LangContext from '../../helps/contexts/lang-context';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const UploadWithLabel = ({ onChange, label, initialImageUrl, name }) => {

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(initialImageUrl || null);
  const [progress, setProgress] = useState(0);
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const imageTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp', 'image/gif'];
  
  useEffect(() => {
    if (initialImageUrl) {
      try {
        const parsedUrl = new URL(initialImageUrl);
        const imagePath = parsedUrl.pathname;
        if (imagePath) {
          onChange(imagePath);
        }
        setImageUrl(initialImageUrl);
      } catch (error) {
        console.error('Invalid URL:', initialImageUrl);
        // Handle the invalid URL case here
      }
    }
  
    // }, [initialImageUrl, onChange]); // This will not work
  }, [initialImageUrl]); // This will work

  const beforeUpload = (file) => {
    const isValidFormat = imageTypes.includes(file.type);
    if (!isValidFormat) {
      message.error(lang.message_file_format_invalid);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(lang.message_file_size_exceeded);
    }
    return isValidFormat && isLt2M;
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none'
      }}
      type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8
        }}>
        {lang.label_upload}
      </div>
    </button>
  );

  const uploadFile = async (data) => {
    const { file, onProgress } = data;
    setLoading(true);
    const config = {
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    };
    const fmData = new FormData();

    fmData.append('file', file);
    if (name) {
      fmData.append('name', name);
    }
    try {
      const res = await PostApi.PostApiRequest('/admin/upload/file', fmData, null, config);
      if ( res?.data) {
        onChange(res.data.path);
        setImageUrl(res.data.link);
        setLoading(false);
        return true;
      }
    } catch (err) {
      console.log('Error: ', err);
    }
    setLoading(false);
  };

  return (
    <div>
      <label style={{ marginBottom: '5px', display: 'block' }}>{label}:</label>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={true}
        customRequest={uploadFile}
        beforeUpload={beforeUpload}
        onRemove={() => {
          setImageUrl(null);
          onChange(null);
        }}
        fileList={
          imageUrl
            ? [
              {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: imageUrl
              }
            ]
            : []
        }>
        {imageUrl ? null : uploadButton}
      </Upload>
    </div>
  );
};

export default UploadWithLabel;
