import { Table ,Tag} from "antd";

import LangContext from "../../../helps/contexts/lang-context";
import { useContext, useState } from "react";
import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import moment from "moment";
import addCommasToNumber from "../../../helps/AddCommasToNumber";
import { convertUTCToLocalTime, toFixedFunc } from "../../../helps/HelperFunctions";
import { Link } from "react-router-dom";

const GameTransactionHistoryTable = ({data,loading}) => {
 
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const[rowdata,setRowdata]=useState({})

  // Edit player popup

 
  const columns = [
    {
      title: lang.label_playername,
      dataIndex: "playername",
      key: "playername",
      render: (_, record) => {
        return (
          <Link
            style={{ color: "#1677ff" }}
            state={{ name: record.playername }}
            to={`/player-management/player/${record?.id}/view`}
          >
            {record.playername}
          </Link>
        );
      },
    },
    {
      title: lang.label_beforepoints,
      dataIndex: "beforepoints",
      key: "beforepoints",
      render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: lang.label_points,
      dataIndex: "points",
      key: "points",
      render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    {
      title: lang.label_afterpoints,
      dataIndex: "afterpoints",
      key: "afterpoints",
      render: (number) => <span>{toFixedFunc(number, process.env.REACT_APP_Decimel_Points)}</span>
    },
    
    {
      title: lang.label_gamename,
      dataIndex: "gamename",
      key: "gamename",
      render: (item, row) => {
       
        return item
    },
    },
    {
        title: lang.label_remark,
        dataIndex: "remark",
        key: "remark",
        
      },
   
    {
        title: lang.label_type,
        dataIndex: "type",
        key: "type",
        render: (_, record) =>
   
        record.type === 1 ? 
        (<Tag color="error" bordered={false} >
        {lang.label_withdraw}
      </Tag>)
         : record.type=== 0 ? 
         (<Tag color="success" bordered={false}>
         {lang.label_deposit}
       </Tag>)
             : null,
        
        
      },
   
    {
      title: lang.label_status,
      dataIndex: "status",
      key: "status",
      render: (_, record) =>
      record.status === 1 ? 
      (<Tag color="success" icon={<CheckCircleOutlined />}>
    Success
    </Tag>)
       : record.status=== 2 ? 
       (<Tag color="error">
      Rejected
     </Tag>) : record.status=== 3 ? 
     (<Tag color="processing" icon={<SyncOutlined spin />}  bordered={false}>
    Pending
   </Tag>
     ): record.status=== 4 ? 
     (<Tag color="red">
   Processing
   </Tag>):""
           
      
      
    },
    {
      title: lang.label_date,
      dataIndex: "date",
      key: "date",
      render: (record) => <span>{convertUTCToLocalTime(record)}</span>
    },
    {
        title: lang.label_actionby,
        dataIndex: "actionby",
        key: "actionby",
      },

    
  ]

  return (
    <div>
      <Table
       style={{width:"100%",height:"auto"}}
       size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        bordered
        onRow={(record) => {
          return {
            onClick: () => {
              setRowdata(record);
             
            },
          };
        }}
      />
      
    </div>
  );
};
export default GameTransactionHistoryTable;
