import { Button, Switch, Table, message } from 'antd';
import React, { useContext } from 'react';
import { MdEdit } from 'react-icons/md';
import NoPermissionLock from '../../../components/HelperComponents/NoPermissionLock';
import PutApi from '../../../helps/contexts/Api/PutApi';
import LangContext from '../../../helps/contexts/lang-context';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';

const PaymentMethodTable = ({
  companyTableData,
  setCompanyTableData,
  loading,
  onEditClicked,
  handleSwitchMessage,
  fetchPaymentMethods
}) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const { hasPermission } = useCheckPermission();

  const toggleStatus = async (id, field) => {
    setCompanyTableData((prevData) =>
      prevData.map((record) => {
        if (record.id === id) {
          record[field] = record[field] ? 0 : 1;
        }
        return record;
      })
    );
    let url = '/payment/method/' + id + '/deposit/toggle';
    if (field === 'allow_withdraw') {
      url = '/payment/method/' + id + '/withdraw/toggle';
    } else if (field === 'under_maintenance') {
      url = '/payment/method/' + id + '/maintenance/toggle';
    } else if (field === 'is_default') {
      url = '/payment/method/' + id + '/update/toggle';
    }

    PutApi.PutApiRequest(url, {}, {})
      .then((res) => {
        if (!res || !res.status) {
          handleCancelForToggle(id, field, true);
        } else {
          message.success('Action has been updated');
        }
        fetchPaymentMethods();
      })
      .catch((error) => {
        console.log(error);

        if (error?.data?.message == 'ALEAST_ONE_DEFAULT_IS_REQUIRED') {
          message.error('At least one default is required. You cannot disable all options.');
        }

        if (error?.data?.message == 'PAYMENT_METHOD_UNDER_MAINTENANCE') {
          message.error('Cannot set as default if the payment method is under maintenance.');
        }

        if (error?.data?.message == 'CANNOT_MAKE_DEFAULT_PAYMENT_UNDER_MAINTENANCE') {
          message.error('This payment method is set as default and cannot be enable maintenance.');
        }

        fetchPaymentMethods();
        handleCancelForToggle(id, field, false);
      });
  };

  const handleCancelForToggle = (id, field, check) => {
    check && message.error('server error');
    setCompanyTableData((prevData) =>
      prevData.map((record) => {
        if (record.key === id) {
          record[field] = !record[field];
        }
        return record;
      })
    );
  };

  const columns = [
    {
      title: lang.label_bankaccountname,
      dataIndex: 'account_name',
      key: 'bankaccountname'
    },
    {
      title: lang.label_bankaccountnumber,
      dataIndex: 'account_number',
      key: 'bankaccountnumber'
    },
    {
      title: lang.label_bankCurrencyname,
      dataIndex: 'currency_name',
      key: 'currencyName'
    },
    {
      title: lang.label_balance,
      dataIndex: 'balance',
      key: 'balance',
      render: (record) => record
    },
    {
      title: lang.label_bank_max_limit_today,
      dataIndex: 'max_daily_amount',
      key: 'maxlimittoday',
      render: (record) => record
    },
    {
      title: lang.label_max_total_amt,
      dataIndex: 'max_total_amount',
      key: 'maxlimitalltime',
      render: (record) => record
    },
    {
      title: lang.label_deposit,
      dataIndex: 'allow_deposit',
      key: 'deposit',
      render: (record, row) => {
        if (!hasPermission('Adjust Payment Method Settings')) {
          return <NoPermissionLock />;
        }
        return (
          <div onClick={() => toggleStatus(row.id, 'allow_deposit')}>
            <Switch size="small" checked={record} />
          </div>
        );
      }
    },
    {
      title: lang.label_withdraw,
      dataIndex: 'allow_withdraw',
      key: 'withdraw',
      render: (record, row) => {
        if (!hasPermission('Adjust Payment Method Settings')) {
          return <NoPermissionLock />;
        }
        return (
          <div onClick={() => toggleStatus(row.id, 'allow_withdraw')}>
            <Switch size="small" checked={record} />
          </div>
        );
      }
    },
    {
      title: lang.label_maintainance,
      dataIndex: 'under_maintenance',
      key: 'under_maintenance',
      render: (record, row) => {
        if (!hasPermission('Adjust Payment Method Settings')) {
          return <NoPermissionLock />;
        }
        return (
          <div onClick={() => toggleStatus(row.id, 'under_maintenance')}>
            <Switch size="small" checked={record} />
          </div>
        );
      }
    },
    {
      title: lang.label_default,
      dataIndex: 'is_default',
      key: 'is_default',
      render: (record, row) => {
        if (!hasPermission('Adjust Payment Method Settings')) {
          return <NoPermissionLock />;
        }

        return (
          <div onClick={() => toggleStatus(row.id, 'is_default')}>
            <Switch size="small" checked={record} />
          </div>
        );
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (record, row) => {
        if (!hasPermission('Create Payment Method')) {
          return <NoPermissionLock />;
        }
        return <Button onClick={() => onEditClicked(row)} type="primary" icon={<MdEdit />} />;
      }
    }
  ];

  return (
    <>
      <Table rowKey={'id'} dataSource={companyTableData} columns={columns} loading={loading} />
    </>
  );
};

export default PaymentMethodTable;
