import React, { useContext, useEffect, useState } from 'react';
import styles from './TransactionFilters.module.css';
import LangContext from '../../../../helps/contexts/lang-context';
import Heading from '../../../../components/HelperComponents/Heading';
import { Button, Col, Input, InputNumber, Modal, Row, Select } from 'antd';
import SubHeading from '../../../../components/HelperComponents/SubHeading';
import InputField from '../../../../components/HelperComponents/InputField';
import CustomButton from '../../../../components/HelperComponents/CustomButton';
import SelectBox from '../../../../components/HelperComponents/SelectBox';
import SearchIcon from '../../../../images/Search.png';
import ResetIcon from '../../../../images/Reset.png';
import refreshIcon from '../../../../images/Refresh.png';
import dayjs from 'dayjs';

import { DatePicker } from 'antd';
import { getBanks } from '../../../../helps/contexts/Api/api';
import GetApi from '../../../../helps/contexts/Api/GetApi';
import { useNavigate } from 'react-router-dom';
import utc from 'dayjs/plugin/utc';
import AddDeposite from '../Popups/AddDeposite';

const { RangePicker } = DatePicker;
dayjs.extend(utc);

const TransactionFilters = ({ setData, paymentMethods, onRefresh, popUp, exportButton }) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;
  const [formData, setFormData] = useState({ status: ['3', '4', '5', '6'] });
  const [transactionStatuses, setTransactionStatuses] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const Navigate = useNavigate();
  const formatDate = (dayjsObj) => {
    return dayjs.utc(dayjsObj).format('YYYY-MM-DD HH:mm:ss');
  };

  const onReset = () => {
    // setFormData({ status: [transactionStatuses.filter((s) => !['1', '2', '6'].includes(s.value))] });
    setFormData({ status: ['3', '4', '5', '6'] });
    setData({ status: ['3', '4', '5', '6'] });
  };

  const onSubmit = () => {
    const finalData = JSON.parse(JSON.stringify(formData));
    Object.keys(finalData).forEach((key) => {
      if (finalData[key] === null || key === 'dates') {
        delete finalData[key];
      }
    });
    setData(formData);
  };

  const getStatuses = async () => {
    try {
      const path = '/admin/transactions/statuses';
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, {});
      if (res) {
        const x = Object.keys(res);
        if (x && x.length) {
          const finalArr = [];
          x.forEach((val) => {
            finalArr.push({ value: val, label: res[val] });
          });
          setTransactionStatuses(finalArr);
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
  };
  const { Group: InputGroup } = Input;

  const inputStyle = {
    height: '35px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #D9D9D9',
    borderRadius: '3px'
  };

  const selectStyle = {
    height: '33px',
    /* UI Properties */
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '3px'
  };

  useEffect(() => {
    getStatuses();
  }, []);

  const searchFunc = () => {};

  const resetFunc = () => {};

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModalEdit = () => {
    setOpenModal(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.filterUpperContainer}>
        <div style={{ paddingTop: '6px' }}>
          <Heading>{lang.label_filters}</Heading>
        </div>
        <div style={{display:"flex",}}>
          {popUp && (
            <Button
              style={{
                backgroundColor: '#004A7F',
                color: 'white',
                width: '150px',
                height: '35px',
                background: '#004A7F 0% 0% no-repeat padding-box',
                borderRadius: '3px',
                opacity: '1',
                marginRight: '10px',
                marginBottom: '10px'
              }}
              // className="btnStyle"
              onClick={handleOpenModal}>
              Add Deposit Record
            </Button>
          )}
          <div>{exportButton && exportButton}</div>
        </div>
      </div>
      <div className={styles.filterLowerContainer}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            paddingRight: '14px',
            flexWrap: 'wrap'
          }}>
          <div>
            <div className={styles.flexStyle}>
              <div
                style={{
                  minWidth: '50px'
                }}>
                <SubHeading
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500
                  }}>
                  {lang.label_time}
                </SubHeading>
              </div>
              <RangePicker
                style={{
                  width: '200px',
                  height: '35px'
                }}
                value={formData ? formData.dates : []}
                showTime={{ defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')] }}
                onChange={(x) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    from_date: x ? formatDate(x[0]) : null,
                    to_date: x ? formatDate(x[1]) : null,
                    dates: x
                  }));
                }}
              />
            </div>
          </div>
          <div>
            <div className={styles.flexStyle}>
              <div
                style={{
                  minWidth: '50px'
                }}>
                <SubHeading
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500
                  }}>
                  {lang.label_amount}
                </SubHeading>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <InputGroup compact>
                  <InputNumber
                    min={0}
                    style={{
                      width: 100,
                      height: '35px',
                      background: '#FFFFFF 0% 0% no-repeat padding-box',
                      border: '1px solid #D9D9D9'
                    }}
                    placeholder="Max"
                    value={formData.min_amount}
                    onChange={(val) => setFormData((prevData) => ({ ...prevData, min_amount: val }))}
                  />
                  <InputNumber
                    min={0}
                    style={{
                      width: 100,
                      height: '35px',
                      background: '#FFFFFF 0% 0% no-repeat padding-box',
                      border: '1px solid #D9D9D9'
                    }}
                    placeholder="Min"
                    value={formData.max_amount}
                    onChange={(val) => setFormData((prevData) => ({ ...prevData, max_amount: val }))}
                  />
                </InputGroup>
                {/* <InputNumber
                  min={0}
                  style={{ width: 100, ...inputStyle }}
                  placeholder="Max"
                  value={formData.min_amount}
                  onChange={(val) => setFormData((prevData) => ({ ...prevData, min_amount: val }))}
                /> */}
                {/* <InputField placeHolder="Minimum" type='number' name="minAmmount" value={formData.minAmmount} onChange={val => setFormData(prevData => ({...prevData, minAmmount: val}))}  /> */}
                {/* <div className={styles.dash}></div> */}
                {/* <InputNumber
                  min={0}
                  style={{ width: 100, ...inputStyle }}
                  placeholder="Min"
                  value={formData.max_amount}
                  onChange={(val) => setFormData((prevData) => ({ ...prevData, max_amount: val }))}
                /> */}
              </div>
              {/* <InputField placeHolder="Maximun" type='number' name="maxAmmount" value={formData.maxAmmount} onChange={val => setFormData(prevData => ({...prevData, maxAmmount: val}))}  /> */}
            </div>
          </div>
          <div>
            <div className={styles.flexStyle}>
              <div
                style={{
                  minWidth: '50px'
                }}>
                <SubHeading
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500
                  }}>
                  {lang.label_username}
                </SubHeading>
              </div>
              <Input
                style={{
                  width: '200px',
                  ...inputStyle
                }}
                placeHolder="Enter User Name "
                value={formData.user_name}
                onChange={(e) => setFormData((prevData) => ({ ...prevData, user_name: e.currentTarget.value }))}
              />
            </div>
          </div>
          <div>
            <div className={styles.flexStyle}>
              <div
                style={{
                  minWidth: '50px'
                }}>
                <SubHeading
                  extraStyle={{
                    fontSize: '14px',
                    fontWeight: 500
                  }}>
                  {lang.label_company_payment_method}
                </SubHeading>
              </div>
              <Select
                placeholder="Select company payment"
                style={{ width: 200, ...selectStyle }}
                allowClear
                value={formData.payment_method_id}
                onChange={(val) => setFormData((prevData) => ({ ...prevData, payment_method_id: val }))}
                onClear={() => setFormData((prevData) => ({ ...prevData, payment_method_id: null }))}
                options={paymentMethods}
              />
            </div>
          </div>
          {/* <div className={styles.flexStyle}>
            <div
              style={{
                minWidth: '50px'
              }}>
              <SubHeading
                extraStyle={{
                  fontSize: '12px',
                  fontWeight: 500
                }}>
                {lang.label_status}
              </SubHeading>
            </div>
            <Select
              style={{ maxWidth: 600, background: '#FFFFFF 0% 0% no-repeat padding-box', borderRadius: '3px' }}
              allowClear
              mode="multiple"
              value={formData.status}
              onChange={(val) => setFormData((prevData) => ({ ...prevData, status: val }))}
              onClear={() => setFormData((prevData) => ({ ...prevData, status: null }))}
              options={transactionStatuses}
            />
          </div> */}
          <div className={styles.flexStyle}>
            <div
              style={{
                minWidth: '50px'
              }}>
              <SubHeading
                extraStyle={{
                  fontSize: '14px',
                  fontWeight: 500
                }}>
                {lang.label_transactionid}
              </SubHeading>
            </div>
            <Input
              min={0}
              style={{ width: 200, ...inputStyle }}
              placeHolder="Enter Transaction ID"
              value={formData.transaction_id}
              onChange={(e) => {
                const val = e.target.value;
                if (/^\d*$/.test(val)) {
                  setFormData((prevData) => ({ ...prevData, transaction_id: val }));
                } else {
                  setFormData((prevData) => ({ ...prevData, transaction_id: '' }));
                }
              }}
            />
          </div>
          <div className={styles.flexStyle}>
            <div
              style={{
                minWidth: '100px'
              }}>
              <SubHeading
                extraStyle={{
                  fontSize: '14px',
                  fontWeight: 500
                }}>
                {lang.label_agentid}
              </SubHeading>
            </div>
            <Input
              style={{ width: 200, ...inputStyle }}
              placeHolder="Enter Agent ID"
              value={formData.agent_code}
              onChange={(e) => setFormData((prevData) => ({ ...prevData, agent_code: e.currentTarget.value }))}
            />
          </div>
          <div className={styles.flexStyle}>
            <div
              style={{
                minWidth: '70px'
              }}>
              <SubHeading
                extraStyle={{
                  fontSize: '14px',
                  fontWeight: 500
                }}>
                {lang.label_referenceid}
              </SubHeading>
            </div>
            <Input
              style={{ width: 200, ...inputStyle }}
              placeHolder="Enter Reference ID"
              value={formData?.reference_no}
              onChange={(e) => setFormData((prevData) => ({ ...prevData, reference_no: e.currentTarget.value }))}
            />
          </div>
          <div className={styles.flexStyle}>
            <div
              style={{
                minWidth: '50px'
              }}>
              <SubHeading
                extraStyle={{
                  fontSize: '14px',
                  fontWeight: 500
                }}>
                {lang.label_status}
              </SubHeading>
            </div>
            <Select
              style={{
                minWidth: 200,
                maxWidth: 600,
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: '3px'
              }}
              allowClear
              mode="multiple"
              value={formData.status}
              onChange={(val) => setFormData((prevData) => ({ ...prevData, status: val }))}
              onClear={() => setFormData((prevData) => ({ ...prevData, status: null }))}
              options={transactionStatuses}
            />
          </div>
          <div
            style={{
              display: 'flex',
              gap: '6px',
              marginTop: '25px',
              alignItems: 'center',
              justifyContent: 'space-around'
            }}>
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                backgroundColor: '#004A7F',
                color: 'white',
                width: '125px',
                height: '35px',
                background: '#004A7F 0% 0% no-repeat padding-box',
                borderRadius: '3px',
                opacity: '1'
              }}
              className="btnStyle"
              onClick={onSubmit}>
              <img src={SearchIcon} alt="search" />
              {lang.label_search}
            </Button>
            <Button
              onClick={onReset}
              // type="secondary"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                borderRadius: '3px',
                padding: '6px',
                border: '1px solid grey',
                width: '84px'
              }}
              className="btnReset">
              <img src={ResetIcon} alt="reset" />
              {lang.label_reset}
            </Button>
            <Button
              onClick={onRefresh}
              // type="secondary"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                borderRadius: '3px',
                padding: '6px',
                border: '1px solid grey',
                width: '84px'
              }}
              className="btnReset">
              <img src={refreshIcon} alt="refresh" />
              {lang.label_refresh}
            </Button>
          </div>
        </div>
        <Row>
          <Col lg={24}>
            <div className={styles.scndRow}></div>
          </Col>
        </Row>
      </div>
      <Modal
        centered
        title={'Add Deposit Record'}
        open={openModal}
        onCancel={handleCloseModalEdit}
        footer={null}
        width={741}
        closable={false}>
        <AddDeposite onClose={handleCloseModalEdit} paymentMethods={paymentMethods} onRefresh={onRefresh} />
      </Modal>
    </div>
  );
};

export default TransactionFilters;
