import React from 'react'
import {Tag} from 'antd';

const CustomBadge = ({ text ,color ,bgColor ,icon}) => {
  const badgeStyle = {
    backgroundColor: bgColor,
    color: color,
  
  };
  return (
 
  <Tag icon={icon}  style={badgeStyle}>
  {text}
</Tag>

  )
}

export default CustomBadge     
