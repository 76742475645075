import React, { useContext } from 'react'
import DeleteApi from '../../helps/contexts/Api/DeleteApi';
import LangContext from '../../helps/contexts/lang-context';
import {
  Button,
  Row,
  Col,
  message
} from "antd";

const DeletePromotionCategoryForm = ({ onCancel, rowdata}) => {
  const ctx = useContext(LangContext);
    const lang = ctx.lang;
    var id = rowdata.id;

    const onSubmit = async (e) => {
      try {
     e.preventDefault();
 
     const path = `/promotion_categories/${id}/delete`;
     // sending request
     const res = await DeleteApi.DeleteApiRequest(path);
 
     //if success
     if (res.status == true) {
 
      message.success(lang.label_promocategorydeletesucess)
      onCancel();
     }
     else{
       message.error(lang.label_promocategorydeletefail)
     }
   } catch (e) {
     
     message.error(e.message)
   }
   };

  return (
    <div
        style={{
          width: "97%",
          height: "100%",
          justifyContent: "space-evenly",
        }}
      >
        
        <form onSubmit={onSubmit}>
          <div
            style={{
              marginLeft: "3%",
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              marginRight: "-6%",
            }}
          >
            <Row>
              <Col span={24}>
              <h4 style={{color:"black"}}>{lang.label_delpromocategoryconfirm}</h4> 
              </Col>
            </Row>
  
            <div style={{ marginBottom: "10%", backgroundColor: "yellow" }}>
             
              <Button
                style={{
                  borderRadius: "3px",
                  color: "#405088",
                  position: "absolute",
                  right: "8%",
                  top: "70%",
                  bottom:"3%"
                }}
                onClick={onCancel}
              >
               {lang.label_cancel}
              </Button>
              <Button
                style={{
                  borderRadius: "3px",
                  backgroundColor: "red",
                  color: "white",
                  position: "absolute",
                  right: "35%",
                  top: "70%",
                  bottom:"3%"
                }}
                htmlType="submit"
              >
               {lang.label_delete}
              </Button>
            </div>
          </div>
        </form>
      </div>
  )
}

export default DeletePromotionCategoryForm