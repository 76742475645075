import { Row, Space } from 'antd';
import Deposit from '../../../images/HomePage/Total Deposit.png';
import Withdraw from '../../../images/HomePage/Total Withdraw.png';
import Profits from '../../../images/HomePage/Total Profits.png';
import FirstDeposit from '../../../images/HomePage/Total First Deposit.png';
import SignedUp from '../../../images/HomePage/Players Signed Up.png';
import Players from '../../../images/HomePage/Total Players.png';
import Online from "../../../images/HomePage/Players online.png";
import ActivePlayerIcon  from "../../../images/HomePage/Active Players.png"
import SecondDeposite from "../../../images/HomePage/Total Second deposite.png"
import CardComp from '../Card/CardComp';

const TotalRecord = ({ data }) => {
  let totalProfits = data?.deposits - data?.withdraws;

  const totalRecordList = [
    { name: 'Total Deposit', value: data?.deposits ? data?.deposits : 0, variation : data?.avg_deposit ? data.avg_deposit : 0,  img: Deposit },
    { name: 'Total Withdraw', value: data?.withdraws ? data?.withdraws : 0, variation : data?.avg_withdraw ? data.avg_withdraw : 0, img: Withdraw },
    { name: 'Total Revenue', value: totalProfits ? totalProfits : 0, img: Profits },
    { name: 'Total First Deposit', value: data?.first_deposits, img: FirstDeposit },
    { name: 'Total Second Deposit', value: data?.second_deposits, img: SecondDeposite },
    { name: 'Players Signed Up', value: data?.total_signups, img: SignedUp },
    { name: 'Current Online Players', value: data?.online_users, img: Online },
    { name: 'Active Players', value: data?.active_users, img: ActivePlayerIcon},
    { name: 'Total Players', value: data?.total_players ? data?.total_players : 0, img: Players }
  ];

  return (
    <Row
      gutter={[
        { sm: 8, lg: 15 },
        { sm: 8, lg: 15 }
      ]}
      style={{ minHeight: '165px' }}>
      {totalRecordList.map((item, index) => (
        <CardComp key={index} item={item ? item : ''} index={index} />
      ))}
    </Row>
  );
};

export default TotalRecord;
