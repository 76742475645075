import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Input, Typography, Button, Table, message } from 'antd';
import { DownloadOutlined, CopyOutlined } from '@ant-design/icons';
// import { saveAs } from 'file-saver';
import styles from './BasicInformation.module.css';

const { Title, Text } = Typography;

const BasicInformation = () => {
  const roles = [
    { role: 'CM', color: '#f45b5b', lightColor: 'rgba(244, 91, 91, 0.1)' },
    { role: 'EA', color: '#ffadad', lightColor: 'rgba(255, 173, 173, 0.1)' },
    { role: 'MA', color: '#e6ccff', lightColor: 'rgba(230, 204, 255, 0.1)' },
    { role: 'SA', color: '#347a2a', lightColor: 'rgba(52, 122, 42, 0.1)' },
    { role: 'JA', color: '#b5e6b5', lightColor: 'rgba(181, 230, 181, 0.1)' },
    { role: 'AG', color: '#c0c0c0', lightColor: 'rgba(192, 192, 192, 0.1)' }
  ];

  const currencySymbols = [
    { country: 'India', currency: 'Indian Rupee', symbol: '₹', code: 'INR' },
    {
      country: 'Vietnam',
      currency: 'Vietnamese Dong',
      symbol: '₫',
      code: 'VND'
    },
    {
      country: 'Philippines',
      currency: 'Philippine Peso',
      symbol: '₱',
      code: 'PHP'
    },
    {
      country: 'Bangladesh',
      currency: 'Bangladeshi Taka',
      symbol: '৳',
      code: 'BDT'
    },
    { country: 'USA', currency: 'US Dollar', symbol: '$', code: 'USD' }
  ];

  const getRoleColor = (role) => {
    const roleItem = roles.find((item) => item.role === role);
    return roleItem
      ? { color: roleItem.color, lightColor: roleItem.lightColor }
      : { color: '#000000', lightColor: 'rgba(0, 0, 0, 0.1)' }; // Default to black if role not found
  };
  const roleColor = getRoleColor('CM');

  const getCurrencySymbol = (code) => {
    const currencyItem = currencySymbols.find((item) => item.code === code);
    return currencyItem ? currencyItem.symbol : '$'; // Default to $ if code not found
  };

  function formatDate(apiDateString) {
    const date = new Date(apiDateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const columns1 = [
    {
      title: 'Downline',
      dataIndex: 'downline',
      key: 'downline'
    },
    {
      title: 'Enable',
      dataIndex: 'enable',
      key: 'enable'
    },
    {
      title: 'Disable',
      dataIndex: 'disable',
      key: 'disable'
    }
  ];

  const dataSource1 = [
    {
      key: '1',
      downline: 'Agent Number',
      enable: '1',
      disable: '0'
    },
    {
      key: '2',
      downline: 'Own Player',
      enable: '1',
      disable: '0'
    },
    {
      key: '3',
      downline: 'Total Underline Player',
      enable: '1',
      disable: '0'
    }
  ];

  const dataSource2 = [
    {
      key: '1',
      promo: 'Promote link (SignupPage)',
      link: 'https://www.bw9.com/?uagt-trade&path=signup'
    },
    {
      key: '2',
      promo: 'Promote link (Home)',
      link: 'https://www.bw9.com/?uagt-trade&path=root'
    },
    {
      key: '3',
      promo: 'Promote link (PromotionPage)',
      link: 'https://www.bw9.com/?uagt-trade&path=promotions'
    }
  ];

  const columns2 = [
    {
      title: 'Promo page',
      dataIndex: 'promo',
      key: 'promo'
    },
    {
      title: 'link',
      dataIndex: 'link',
      key: 'link'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button
            icon={<CopyOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleCopy(record.link)}
          />
          <Button
            icon={<DownloadOutlined />}
            // onClick={() => handleDownloadQR(record.link)}
          />
        </>
      )
    }
  ];

  // Function to handle copying link to clipboard
const handleCopy = (link) => {
  navigator.clipboard.writeText(link).then(() => {
    message.success('Link copied to clipboard!');
  }).catch(err => {
    message.error('Failed to copy the link.');
  });
};

// Function to handle QR code download
// const handleDownloadQR = (link) => {
//   const canvas = document.getElementById(`qr-${link}`);
//   canvas.toBlob((blob) => {
//     saveAs(blob, `${link}.png`);
//   });
// };

  return (
    <div className={styles.dashboardContainer}>
      <Card
        style={{
          borderRadius: '10px'
        }}>
        <Row gutter={16}>
          <Col span={12}>
            <Card
              style={{
                borderRadius: '10px'
              }}
              className={styles.customCard}
              title="Account detail">
              <div
                style={{
                  // marginTop : "10px",
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  // height : '100%',
                  columnGap: '20px',
                  flexWrap: 'wrap'
                }}>
                <div className={styles.ContentInput}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Agent ID
                  </span>
                  <Input
                    placeholder="trade"
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>
                <div className={styles.ContentInput}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    * Username
                  </span>
                  <Input
                    placeholder="Trade"
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div className={styles.ContentInput}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Email
                  </span>
                  <Input
                    placeholder="itea001@gmail.com"
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div className={styles.ContentInput}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Skype
                  </span>
                  <Input
                    placeholder=""
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div className={styles.ContentInput}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Status
                  </span>
                  {/* <Input
                    placeholder="Active"
                    style={{
                     boxSizing : 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height : "30px",
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  /> */}
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px'
                        // border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      }}>
                      <div
                        style={{
                          backgroundColor: '#70C79A',
                          width: '7px',
                          height: '7px',
                          borderRadius: '100%',
                          marginRight: '5px'
                        }}></div>
                      <Text
                        style={{
                          fontFamily: 'Roboto, sans-serif',
                          width: '100%',
                          fontSize: '11.8px',
                          fontWeight: 420,
                          boxShadow: 'none',
                          outline: 'none'
                        }}>
                        {/* {t(`Active`)} */}
                        Active
                      </Text>
                    </div>
                  </>
                  {/* ) : (
                          <>
                            div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              div
                                style={{
                                  backgroundColor: "red",
                                  width: "7px",
                                  height: "7px",
                                  borderRadius: "100%",
                                  marginRight: "5px",
                                }}
                              ></div>
                              <Text
                                style={{
                                  fontSize: "11.5px",
                                  fontWeight: 420,
                                }}
                              >
                                {t(`InActive`)}
                              </Text>
                            </div>
                          </> */}
                </div>

                <div className={styles.ContentInput}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Role
                  </span>
                  {/* <Input
                    placeholder="Agent"
                    style={{
                     boxSizing : 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height : "30px",
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  /> */}
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: '10px'
                    }}>
                    <text
                      style={{
                        // width: "120px",
                        fontSize: '12px',
                        fontWeight: 500,
                        color: roleColor.color,
                        backgroundColor: roleColor.lightColor,
                        borderRadius: '5px',
                        padding: '2px'
                      }}>
                      {/* {userInfo?.agent?.role ? userInfo.agent.role : 'CM' } */}
                      {`CM`}
                    </text>
                    <text
                      style={{
                        // width: "50%",
                        color: '#3C4043',
                        fontSize: '12px',
                        fontWeight: 500,
                        marginRight: '150px'
                      }}>
                      Agent
                    </text>
                  </div>
                </div>

                <div className={styles.ContentInput}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Currency
                  </span>
                  <Input
                    placeholder="PHP"
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div className={styles.ContentInput}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Last Login Date
                  </span>
                  <Input
                    placeholder="2024-08-18 10:00:00"
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div className={styles.ContentInput}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Register Date
                  </span>
                  <Input
                    placeholder="2023-08-28 16:00:00"
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div className={styles.ContentInput}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Direct Players
                  </span>
                  <Input
                    placeholder="23"
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div className={styles.ContentInput}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Registration IP
                  </span>
                  <Input
                    placeholder="111.111.111.11"
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div className={styles.ContentInput}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Telegram
                  </span>
                  <Input
                    placeholder=""
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>
              </div>
            </Card>
            <Card
              style={{
                borderRadius: '10px',
                marginTop: 24
              }}
              className="custom-card"
              title="Downline Information">
              <Table dataSource={dataSource1} columns={columns1} pagination={false} />
            </Card>
          </Col>

          <Col span={8}>
            <Card
              style={{
                borderRadius: '10px'
              }}
              className="custom-card"
              title="Personal info">
              <div
                className={styles.detailsCollection}
                style={{
                  // marginTop : "10px",
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  columnGap: '20px',
                  flexWrap: 'wrap'
                }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '220px', marginTop: '8px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    * First Name
                  </span>
                  <Input
                    placeholder=""
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '220px', marginTop: '8px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Second Name
                  </span>
                  <Input
                    placeholder=""
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '220px', marginTop: '8px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    * Last Name
                  </span>
                  <Input
                    placeholder=""
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '220px', marginTop: '8px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Second Last Name
                  </span>
                  <Input
                    placeholder=""
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '220px', marginTop: '8px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Code
                  </span>
                  <Input
                    placeholder=""
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '220px', marginTop: '8px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Gender
                  </span>
                  <Input
                    placeholder=""
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '220px', marginTop: '8px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    City
                  </span>
                  <Input
                    placeholder=""
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '220px', marginTop: '8px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Countries
                  </span>
                  <Input
                    placeholder=""
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '220px', marginTop: '8px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Zip Code
                  </span>
                  <Input
                    placeholder=""
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '220px', marginTop: '8px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Address
                  </span>
                  <Input
                    placeholder=""
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '220px', marginTop: '8px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Birth Date
                  </span>
                  <Input
                    placeholder=""
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '220px', marginTop: '8px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0, 0, 0, 0.75)',
                      width: '100%',
                      fontWeight: 500
                    }}>
                    Phone Number
                  </span>
                  <Input
                    placeholder=""
                    style={{
                      boxSizing: 'border-box',
                      fontFamily: 'Roboto, sans-serif',
                      width: '100%',
                      height: '30px',
                      fontSize: '11.8px',
                      fontWeight: 420,
                      border: '0.5px solid rgba(0, 0, 0, 0.05)',
                      boxShadow: 'none',
                      outline: 'none'
                    }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        {/* <Row gutter={16} style={{ marginTop: 24 }}>
          <Col span={12}>
            <Card  className='custom-card' title="Downline Information">
              <Table dataSource={dataSource1} columns={columns1} pagination={false} />
            </Card>
          </Col>
        </Row> */}
      </Card>
      {/* <Row gutter={16} style={{ marginTop: 24 }}>
        <Col span={24}>
          <Card
            style={{
              borderRadius: '10px'
            }}
            className="custom-card"
            title="The player register url">
            <Table dataSource={dataSource2} columns={columns2} pagination={false} />
          </Card>
        </Col>
      </Row> */}
      <Row gutter={16} style={{ marginTop: 24 }}>
        <Col span={24}>
          <Card
            style={{
              borderRadius: '10px'
            }}
            className="custom-card"
            title="The player register url">
            <Table dataSource={dataSource2} columns={columns2} pagination={false} />
            {/* {dataSource2.map((item) => (
              <div key={item.key} style={{ display: 'none' }}>
                <QRCode id={`qr-${item.link}`} value={item.link} size={128} />
              </div>
            ))} */}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BasicInformation;
