import { Button, Col, Input, Row, message } from 'antd';
import React, { useContext } from 'react';
import styles from './TwofaModal.module.css';
import PostApi from '../../helps/contexts/Api/PostApi';
import { BsCheckLg } from 'react-icons/bs';
import { useState } from 'react';
import { UserContext } from '../../helps/contexts/UserContext';

const TwofaModal = ({ handleCancel, data, enable, disable, setEnable, setDisable }) => {
  const { updateUser, user } = useContext(UserContext);
  const [Otp, setOTP] = useState('');
  const [password, setPassword] = useState('');
  const enableTwofa = async (e) => {
    try {
      e.preventDefault();

      const path = `/admin/2fa/first/check`;
      // sending request
      const res = await PostApi.PostApiRequest2(path, Otp);
      //if success
      if (res && res.data.status == true) {
        message.success('Two factor authentication enabled Successfully');
        const userData = {
          username: user?.username,
          role: user?.role,
          status: user?.status,
          is_2fa_enabled: 1
        };
        updateUser({ ...user, ...userData });
        setOTP('');
        setEnable(false);
        handleCancel();
      }
    } catch (error) {
      if (error?.response?.data?.message === 'OTP_INCORRECT') {
        message.error('Entered OTP is incorrect');
      } else {
        message.error(' Error ');
      }
    }
  };
  const disableTwofa = async (e) => {
    try {
      e.preventDefault();
      const path = `/admin/disable/2fa`;

      // sending request
      const res = await PostApi.PostApiRequest3(path, password);

      //if success
      if (res && res.data.status == true) {
        message.success('Two factor authentication disabled Successfully');
        const userData = {
          username: user?.username,
          role: user?.role,
          status: user?.status,
          is_2fa_enabled: 0
        };
        updateUser({ ...user, ...userData });
        setPassword('');
        setDisable(false);
        handleCancel();
      }
    } catch (e) {
      if (e.request.status === 403) {
        message.error('Incorrect Password');
        setPassword('');
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        borderRadius: '3px',
        justifyContent: 'space-evenly'
      }}>
      {enable && (
        <>
          <img src={data?.qr_code_url} alt="qr code" />
          <form onSubmit={enableTwofa}>
            <div style={{ display: 'flex', gap: '15px', flexDirection: 'column' }}>
              <div style={{ display: 'flex', gap: '3px' }}>
                <label>OTP :</label>
                <span style={{ color: 'red' }}>*</span>
                <input
                  type="text"
                  className={styles.inputOTP}
                  value={Otp}
                  onChange={(e) => {
                    setOTP(e.target.value);
                  }}
                />
              </div>

              <Button
                style={{
                  borderRadius: '3px',
                  backgroundColor: 'green',
                  color: 'white'
                  // position: "absolute",
                  // right: "25%",
                  // top: "70%",
                  // bottom: "10%",
                }}
                htmlType="submit">
                Enable 2fa
              </Button>
            </div>
          </form>
        </>
      )}
      {disable && (
        <form onSubmit={disableTwofa}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '300px'
            }}>
            <Row>
              <Col span={12}>
                <label style={{ marginBottom: '70px' }}>
                  Password
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <br />

                <Input.Password
                  type="password"
                  style={{ width: '350px' }}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <br />

            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', width: '348px' }}>
              <Button
                style={{
                  borderRadius: '3px',
                  backgroundColor: '#004A7F',
                  color: 'white'
                  // position: "absolute",
                  // right: "20%",
                  // top: "86%",
                }}
                htmlType="submit">
                Disable 2FA
              </Button>
              <Button
                style={{
                  borderRadius: '3px',
                  color: '#004A7F'
                  // position: "absolute",
                  // right: "8%",
                  // top: "86%",
                }}
                onClick={() => {
                  setPassword('');
                  setDisable(false);
                }}>
                Cancel
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default TwofaModal;
