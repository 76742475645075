import React, { useState } from 'react';
import BasicInfo from './BasicInfo';
import CostInfo from './CostInfo';
import CommissionSetting from './CommissionSetting';
import GameSetting from './GameSetting';
import styles from './newAgent.module.css';

import HeaderCardDetails from '../../../../components/HeaderDetailCard';
import { Radio } from 'antd';

const NewAgent = () => {
  const [selectedType, setSelectedType] = useState('agent'); 
  const [agentFormData, setAgentFormData] = useState({
    settleCycle: 'Weekly (Mon 00:00 ~ Sun 23:59 GMT+8)',
    depositBonus: 100,
    otherBonus: 100,
    rebate: 100,
    gameSettings: {
      sportData: [
        { key: '1', sport: 'UG Sports', status: 1 },
        { key: '2', sport: 'PINNACLE', status: 1 },
        { key: '3', sport: 'CMD Sports', status: 1 }
      ],
      casinoData: [
        { key: '1', casino: 'Evolution', status: 1 },
        { key: '2', casino: 'ViA', status: 1 },
        { key: '3', casino: 'Ae Sexy', status: 1 },
        { key: '4', casino: 'EZUGI', status: 1 }
      ],
      slotsData: [
        { key: '1', slots: 'JILI', status: 1 },
        { key: '2', slots: 'Kingmaker', status: 1 },
        { key: '3', slots: 'RelaxGaming', status: 1 },
        { key: '4', slots: 'PG Soft', status: 1 }
      ],
      sabongData: [{ key: '1', sabong: 'DS88', status: 1 }]
    }
  });
  const [playerFormData, setPlayerFormData] = useState({
 
  });

  const [tabValue, setTabValue] = useState(1);

  const TabData = {
    agent: [
      { id: 1, name: 'BasicInfo' },
      { id: 2, name: 'CostInfo' },
      { id: 3, name: 'CommissionSetting' },
      { id: 4, name: 'GameSetting' }
    ],
    player: [
      { id: 1, name: 'BasicInfo' } 
    ]
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (selectedType === 'agent') {
      setAgentFormData({
        ...agentFormData,
        [name]: value
      });
    } else {
      setPlayerFormData({
        ...playerFormData,
        [name]: value
      });
    }
  };

  const handleGameSettingChange = (category, key, checked) => {
    setAgentFormData((prevState) => ({
      ...prevState,
      gameSettings: {
        ...prevState.gameSettings,
        [category]: prevState.gameSettings[category].map((game) =>
          game.key === key ? { ...game, status: checked ? 1 : 0 } : game
        )
      }
    }));
  };

  const nextTab = () => {
    setTabValue((prevKey) => {
      const nextTab = prevKey + 1;
      return nextTab <= TabData[selectedType].length ? nextTab : prevKey;
    });
  };

  const prevTab = () => {
    setTabValue((prevKey) => {
      const prevTab = prevKey - 1;
      return prevTab >= 1 ? prevTab : prevKey;
    });
  };

  return (
    <>
      <HeaderCardDetails />
      <div
        className="ProfileInfo"
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          borderRadius: '3px',
          position: 'relative',
          marginTop: '50px',
          marginLeft: '20px'
        }}>
        {/* Type Selection */}
        <div style={{ marginBottom: '20px' }}>
          <Radio.Group
            onChange={(e) => {
              setSelectedType(e.target.value);
              setTabValue(1); 
            }}
            value={selectedType}
          >
            <Radio value="agent">Agent</Radio>
            <Radio value="player">Player</Radio>
          </Radio.Group>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', gap: 15, borderBottom: '1px solid #DDDDDD', position: 'relative' }}>
            {TabData[selectedType]?.map((child) => (
              <div
                key={child.id}
                style={{
                  borderBottom: child.id === tabValue ? '3px solid #003049' : 'none',
                  cursor: 'pointer',
                  position: 'relative',
                  zIndex: 1,
                  top: 2,
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  color: child.id === tabValue ? '#003049' : '#AAAAAA'
                }}
                onClick={() => setTabValue(child.id)}
                className={styles.textStyling}>
                {child?.name}
              </div>
            ))}
          </div>
          <div>
            {tabValue === 1 && (
              <BasicInfo
                formData={selectedType === 'agent' ? agentFormData : playerFormData}
                handleInputChange={handleInputChange}
                nextTab={nextTab}
                type={selectedType} 
                confirm={() => console.log('Confirmed:', playerFormData)}
              />
            )}
            {tabValue === 2 && selectedType === 'agent' && (
              <CostInfo formData={agentFormData} handleInputChange={handleInputChange} nextTab={nextTab} prevTab={prevTab} />
            )}
            {tabValue === 3 && selectedType === 'agent' && (
              <CommissionSetting
                formData={agentFormData}
                handleInputChange={handleInputChange}
                nextTab={nextTab}
                prevTab={prevTab}
              />
            )}
            {tabValue === 4 && selectedType === 'agent' && (
              <GameSetting
                formData={agentFormData}
                handleInputChange={handleInputChange}
                handleGameSettingChange={handleGameSettingChange}
                prevTab={prevTab}
                confirm={() => console.log('Confirmed:', agentFormData)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewAgent;

