import {Table,} from "antd";
import LangContext from "../../../helps/contexts/lang-context";
import { useContext } from "react";
import moment from "moment";
import { convertUTCToLocalTime } from "../../../helps/HelperFunctions";

const ChangeAgentHistoryTable = ({ data, loading}) => {
  const ctx = useContext(LangContext);
  const lang = ctx.lang;

  const columns = [
    {
      title: lang.label_player,
      dataIndex: "player",
      key: "player",
    },
    {
      title: lang.label_previousagent,
      dataIndex: "previousagent",
      key: "previousagent",
    },
    {
      title: lang.label_newagent,
      dataIndex: "newagent",
      key: "newagent",
    },
    {
      title: lang.label_changedby,
      dataIndex: "changedby",
      key: "changedby",
    },
    {
      title: lang.label_time,
      dataIndex: "time",
      key: "time",
      render: (time) => <span>{convertUTCToLocalTime(time)}</span>
    },
  ];

  return (
    <Table
    style={{width:"100%"}}
      loading={loading}
      columns={columns}
      bordered
      dataSource={data}
      pagination={false}
      size="small"
    />
  );
};

export default ChangeAgentHistoryTable;
