import React from 'react'
import Edit from '../../../images/Edit.png';
import Delete from '../../../images/Delete.png';
import { Button } from 'antd'
const Maintenece = ({handleOpenModal, item}) => {
  return (
   <div style={{
    display:"flex",
    gap:"10px"
   }}>
   <div className="custom-div" onClick={()=>handleOpenModal(item)}>
    <Button
  
      type="text"
      icon={<img style={{
        height:'13px',
        width:"13px" ,
        objectFit:"contain" ,
      }} src={Edit} alt="Edit Icon" />}
      className="custom-button"
    />
    </div>
  </div>
  )
}

export default Maintenece