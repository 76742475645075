import React, { useContext, useEffect, useState } from 'react';
import LangContext from '../../../helps/contexts/lang-context';
import { TabContext } from '../../../helps/contexts/TabContext';
import { useNavigate } from 'react-router-dom';
import PaginationComp from '../../../components/HelperComponents/PaginationComp';
import GetApi from '../../../helps/contexts/Api/GetApi';
import Spacer from '../../../components/HelperComponents/Spacer';
import HeaderCardDetails from '../../../components/HeaderDetailCard';
import { Switch, Table, Button } from 'antd';
import RebateFilters from './RebateFilters';
import AddRebatePopup from './AddRebatePopup';
import PutApi from '../../../helps/contexts/Api/PutApi';
import RebateDetailPopUp from './RebateDetailPopUp';
import useCheckPermission from '../../../helps/hooks/useCheckPermission';

export default function Rebate() {
  const ctx = useContext(LangContext);
  const Navigate = useNavigate();
  const { hasPermission } = useCheckPermission();
  const [loading, setLoading] = useState(false);
  const [rebateList, setRebateList] = useState([]);
  // const [filterData, setFilterData] = useState();
  const [pageSize, setPageSize] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [filters, setFilters] = useState(null);

  const [categoryList, setCategoryList] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailPopUp, setDetailPopUp] = useState(false);
  const { path } = useContext(TabContext);
  const [popupId, setPopUpId] = useState();
  const lang = ctx.lang;

  const showDetailModal = (id) => {
    // console.log("popupId :",id)
    setPopUpId(id);
    setDetailPopUp(true);
  };

  const handleCancelDetail = () => {
    setDetailPopUp(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    getRebates({ page: currentPage });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [reviewOpen, setReviewOpen] = useState(false);
  const reviewHandleOk = () => {
    setReviewOpen(false);
  };
  const reviewHandleCancel = () => {
    console.log('a');
    setReviewOpen(false);
  };

  const getRebates = async ({ page, filter }) => {
    setLoading(true);
    try {
      const path = '/admin/rebate/list';
      const finalData = filter ?? null;

      const params = { ...finalData, page: page };
      //sending api request
      const res = await GetApi.sendApiRequest(path, {}, params);
      if (res && res.data && Array.isArray(res.data)) {
        setLoading(false);
        const modifiedData = res.data.map((item) => {
          const updatedAt = item.updated_at?.split('.')[0]; // Remove the microseconds part
          return {
            ...item,
            updated_at: updatedAt
          };
        });
        setRebateList(modifiedData);
        setTotalLength(res.total);
        setPageSize(res.per_page);
        setReviewOpen(false);
        setIsModalOpen(false);
        setDetailPopUp(false);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
    setLoading(false);
  };

  const handleToggle = async (id) => {
    setLoading(true);
    try {
      const path = `/admin/rebate/${id}/toggle-status`;

      const res = await PutApi.PutApiRequest(path, {}, {});

      // setLoading(false);
      // console.log(res);
      if (res) {
        // setLoading(false);
        getRebates({ page: currentPage });
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
  };

  const getCategories = async () => {
    setLoading(true);
    setCategoryList([]);
    try {
      const path = '/game/category';

      const res = await GetApi.sendApiRequest(path, {}, {});

      setLoading(false);
      // console.log(res);
      if (res && res?.length > 0) {
        // setLoading(false);
        setCategoryList(res);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 401) {
        Navigate('/login');
      }
    }
    setLoading(false);
  };

  const onRefresh = () => {
    setFilters(null);
    getRebates({ page: currentPage });
  };

  useEffect(() => {
    getRebates({ page: currentPage, filter: filters ?? '' });
  }, [filters, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    getRebates({ page: 1, filter: filters ?? '' });
  }, [filters]);

  const onChangePage = (page) => {
    setCurrentPage(page);
    // setIsPageChange(true);
  };

  const paginationConfig = {
    pageSize: pageSize ? pageSize : 0,
    total: totalLength ? totalLength : 0,
    showSizeChanger: false,
    showQuickJumper: false,
    current: currentPage,
    showTotal: (total) => `Total ${total} items`,
    onChange: onChangePage,
    itemRender: (current, type, originalElement) => {
      if (type === 'prev') {
        return <span className="pagination-item">Previous</span>;
      }
      if (type === 'next') {
        return <span className="pagination-item">Next</span>;
      }
      return originalElement;
    }
  };

  const columns = [
    {
      title: lang.label_rebate_title,
      dataIndex: 'title',
      key: 'title',
      fixed: 'left',
      render: (_, record) => {
        return (
          <Button
            style={{
              padding: '0px 0px'
            }}
            type="link"
            onClick={() => showDetailModal(record?.id)}>
            {record?.title}
          </Button>
        );
      }
    },
    {
      title: lang.label_category_title,
      dataIndex: 'category',
      key: 'category',
      fixed: 'left'
    },
    {
      title: lang.label_start_at,
      dataIndex: 'start_date',
      key: 'start_date'
    },
    {
      title: lang.label_end_at,
      dataIndex: 'end_date',
      key: 'end_date'
    },
    {
      title: lang.label_rebate_cycle,
      dataIndex: 'rebate_cycle',
      key: 'rebate_cycle'
    },

    {
      title: lang.label_max_group_rebate,
      dataIndex: 'max_group_rebate',
      key: 'max_group_rebate'
    },
    {
      title: lang.label_status,
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        return (
          <Switch
            onClick={() => handleToggle(record?.id)}
            size="small"
            disabled={!hasPermission('Create Rebate')}
            checked={record?.status === 'Active' ? true : false}
          />
        );
      }
    },
    {
      title: lang.label_updated_at,
      dataIndex: 'updated_at',
      key: 'updated_at'
    },
    {
      title: lang.label_createdby,
      dataIndex: 'created_by',
      key: 'created_by',
      render: (_, record) => {
        return <>{record?.created_by ?? `-`}</>;
      }
    }
    // {
    //   title: lang.label_actions,
    //   key: 'actions',
    //   dataIndex: 'actions',

    //   fixed: 'right'
    // }
  ];

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div>
      <HeaderCardDetails />
      <div style={{ padding: '20px' }}>
        <RebateFilters
          onRefresh={onRefresh}
          setIsModalOpen={setIsModalOpen}
          categoryList={categoryList}
          setFilters={setFilters}
        />
        <br />
        <Table
          scroll={{
            x: 'max-content'
          }}
          columns={columns}
          loading={loading}
          dataSource={rebateList}
          bordered
          pagination={false}
          rowKey={'id'}
        />
        {rebateList?.length > 0 && <PaginationComp paginationConfig={paginationConfig} />}
        <Spacer />
        <Spacer />
        <AddRebatePopup
          handleCancel={handleCancel}
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          setIsModalOpen={setIsModalOpen}
          reviewHandleCancel={reviewHandleCancel}
          reviewHandleOk={reviewHandleOk}
          reviewOpen={reviewOpen}
          setReviewOpen={setReviewOpen}
        />
        <RebateDetailPopUp
          handleCancelDetail={handleCancelDetail}
          isDetailPopUp={isDetailPopUp}
          setDetailPopUp={setDetailPopUp}
          popupID={popupId}
        />
      </div>
    </div>
  );
}
