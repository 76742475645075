import { useContext, useEffect, useState } from "react";
import { Dropdown } from "antd";
import ENImage from "../../images/united-kingdom.jpg";
import VNImage from "../../images/vietnam.jpg";
import LangContext from "../../helps/contexts/lang-context";

const LanguageSwitcher = () => {
  // getting language from local to set initial value of switch
  const lang = localStorage.getItem("language");
  const [locale, setLocale] = useState(lang ? lang : "en");
  const ctx = useContext(LangContext);

  const onLocaleChanged = (val) => {
    setLocale(val);
  };

  useEffect(() => {
    ctx.setLocale(locale);
  }, [locale]);

  const items = [
    {
      key: "en",
      label: <span onClick={() => onLocaleChanged("en")}>English</span>,
    },
    {
      key: "vn",
      label: <span onClick={() => onLocaleChanged("vn")}>Tiếng Việt</span>,
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottom">
      <img
        src={locale === "vn" ? VNImage : ENImage}
      />
    </Dropdown>
    // <SegmentedControl
    //   color="blue"
    //   size="xs"
    //   fullWidth
    //   data={[
    //     { label: 'English', value: 'en' },
    //     { label: 'Tiếng Việt', value: 'vn' },
    //   ]}
    //   value={locale}
    //   onChange={onLocaleChanged}
    // />
  );
};

export default LanguageSwitcher;
