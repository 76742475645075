import React, { useContext, useState } from 'react';
import './Table.css';
import { Table } from 'antd';
import PaginationComp from './PaginationComp';
import { useEffect } from 'react';
import { TabContext } from '../../helps/contexts/TabContext';
import NotificationContext from '../../helps/contexts/Notification-Context';

const TableComp = ({
  financeWithdrawLoading,
  riskWithdrawLoading,
  financeLoading,
  setTransactionTableData,
  transactionTableData = [],
  dataSource,
  columns,
  totalLength,
  perPage,
  onChangePage,
  currentPage
}) => {
  const [extra, setExtra] = useState(window?.location?.href?.split('/')?.pop());

  const { transactionObject } = useContext(NotificationContext);

  // const [path,setPath] = useState()
  const pageSize = perPage && perPage;
  useEffect(() => {
    // console.log('use effect called transactionObject');
    // console.log('transactionObject', transactionObject);
    // console.log('transactionTableData', transactionTableData);

    if (transactionObject) {
      // console.log("11111")
      if (transactionObject.isWithdraw === (window?.location?.href?.split('/')?.pop() === 'withdraw' ? 1 : 0)) {
        // console.log("22222")
        if (transactionObject?.withdrawLock) {
          // console.log('33333');
          // withdraw lock event comes here
          const newArray = transactionTableData?.filter((obj) => {
            if (obj?.is_withdraw_transaction?.id === transactionObject?.is_withdraw_transaction?.id) {
              obj.is_withdraw_transaction = transactionObject?.is_withdraw_transaction;
            }

            return obj;
          });
          setTransactionTableData(newArray);
        } else if (transactionObject?.depositLock) {
          // console.log('44444');
          // deposit lock event comes here
          const newArray = transactionTableData?.filter((obj) => {
            // console.log('obj.is_deposit_transaction', obj.is_deposit_transaction);
            // console.log('transactionObject.is_deposit_transaction', transactionObject.is_deposit_transaction);

            if (obj && obj?.is_deposit_transaction?.id === transactionObject?.is_deposit_transaction?.id) {
              obj.is_deposit_transaction = transactionObject?.is_deposit_transaction;
            }
            // console.log('obj depositLock', obj);
            return obj;
          });
          setTransactionTableData(newArray);
        } else if (
          transactionObject?.is_withdraw_transaction?.risk_action_by?.user_name &&
          (transactionObject?.is_withdraw_transaction?.risk_action_status_string === 'approved' ||
            transactionObject?.is_withdraw_transaction?.risk_action_status_string === 'rejected') &&
          (transactionObject?.status === 'approved' || transactionObject?.status === 'rejected')
        ) {
          // console.log('5555');

          // widthdraw risk approve or reject and after risk finance approve or reject event comes here
          const newArray = transactionTableData?.filter((obj) => {
            if (obj?.id === transactionObject?.id) {
              obj.status = transactionObject?.status;
              obj.is_withdraw_transaction = transactionObject?.is_withdraw_transaction;
            }
            return obj;
          });
          setTransactionTableData(newArray);
        } else if (transactionObject?.is_withdraw_transaction?.risk_action_by?.user_name) {
          // console.log('66666');

          // withdraw risk approve / reject event comes here
          if (transactionObject?.status === 'pending') {
            const newArray = transactionTableData?.filter((obj) => {
              if (obj?.is_withdraw_transaction?.id === transactionObject?.is_withdraw_transaction?.id) {
                obj.is_withdraw_transaction.risk_action_by = transactionObject?.is_withdraw_transaction?.risk_action_by;
                obj.is_withdraw_transaction.risk_action_status_string =
                  transactionObject?.is_withdraw_transaction?.risk_action_status_string;
                obj.is_withdraw_transaction.risk_action_note =
                  transactionObject?.is_withdraw_transaction?.risk_action_note;
                obj.status =
                  transactionObject.risk_action_status_string === 'rejected' ? 'rejected' : transactionObject?.status;
              }
              return obj;
            });
            setTransactionTableData(newArray);
          }
          // else{
          //   const newArray  =   transactionTableData?.filter((obj)=>{
          //     return obj?.id !== transactionObject.id
          //     })
          //     setTransactionTableData(newArray)
          // }
        } else if (
          transactionObject?.isWithdraw === 0 &&
          (transactionObject?.status === 'approved' || transactionObject?.status === 'rejected')
        ) {
          // console.log('77777');

          const newArray = transactionTableData?.filter((obj) => {
            //deposit finance approve event come here
            if (obj?.is_withdraw_transaction?.id === transactionObject?.is_withdraw_transaction?.id) {
              obj.status = transactionObject?.status;
            }
            return obj;
          });
          setTransactionTableData(newArray);
        } else {
          // console.log('88888');
          // withdraw new transaction created through pusher comes here
          setTransactionTableData([transactionObject, ...transactionTableData]);
        }
      } else {
        // deposit new transaction created through pusher comes here
        // console.log('9999');
        setTransactionTableData([transactionObject, ...transactionTableData]);
      }
    } else {
      // console.log('1000000000000000000', transactionTableData);
      //transactionObject is null
      setTransactionTableData([...transactionTableData]);
    }
  }, [transactionObject, financeLoading, financeWithdrawLoading, riskWithdrawLoading]);

  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize ? pageSize : 0,
    total: totalLength ? totalLength : 0,
    showSizeChanger: false,
    showQuickJumper: false,
    showTotal: (total) => `Total ${total} items`,
    onChange: onChangePage,
    itemRender: (current, type, originalElement) => {
      if (type === 'prev') {
        return <span className="pagination-item">Previous</span>;
      }
      if (type === 'next') {
        return <span className="pagination-item">Next</span>;
      }
      return originalElement;
    }
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  return (
    <div className="table-container">
      <div className="scrollable-table">
        <Table pagination={false} dataSource={dataSource} columns={columns} bordered />
        {dataSource?.length > 0 && <PaginationComp paginationConfig={paginationConfig} />}
      </div>
    </div>
  );
};

export default TableComp;
